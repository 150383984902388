import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import DraggableItem from "./DraggableItem";
import Dustbin from "./Dustbin";
import SelectableItem from "./SelectableItem";
import Checkbox from "../Checkbox/Checkbox";
import crossIconGreen from "../../assets/images/crossIconGreen.svg";

const gridContainerCN = (select, grid, containerCN) => cntl`
  DragAndDropGridContainer
  ${
    !select &&
    grid &&
    "grid lg:grid-cols-3 md:grid-cols-2 gap-x-9 gap-y-9 pb-16"
  }
  ${select && !grid && "flex gap-x-9"}
  ${containerCN}
  pt-3
`;

// react-dnd setup inspired by https://github.com/viniciusgerevini/react-responsive-mosaic
const DragAndDropGrid = ({
  items,
  itemType,
  saveNewOrder,
  setSelected,
  gridView,
  containerClassName,
}) => {
  const reorderItems = useCallback(
    (fromIndex, toIndex) => {
      const newOrder = [...items];

      // take the moved item out of the array
      const movedItem = newOrder.splice(fromIndex, 1);

      // add the moved item to the new position
      newOrder.splice(toIndex, 0, movedItem[0]);

      if (saveNewOrder) {
        saveNewOrder(newOrder);
      }
    },
    [items, saveNewOrder]
  );

  const [activeBin, setActiveBin] = useState(items?.active);
  const [inactiveBin, setInactiveBin] = useState(items?.inactive);
  const activeElements = useMemo(
    () =>
      activeBin?.map((item, index) =>
        setSelected ? (
          <SelectableItem
            item={item}
            index={index}
            itemType={itemType}
            key={item.id}
          />
        ) : (
          <DraggableItem
            key={item.id}
            id={item.id}
            itemIndex={index}
            item={item}
            itemType={itemType}
            className={item.className}
            style={item.style}
            onReorder={reorderItems}
          >
            {item.content}
          </DraggableItem>
        )
      ),
    [activeBin, itemType, reorderItems, setSelected]
  );

  const inactiveElements = useMemo(
    () =>
      inactiveBin?.map((item, index) => (
        <SelectableItem
          key={item.id}
          item={item}
          index={index}
          itemType={itemType}
        />
      )),
    [inactiveBin, itemType]
  );

  const handleDrop = useCallback(
    (item, active = false) => {
      const { id, item: raw } = item;

      if (!active) {
        if (!inactiveBin.find((x) => x.id === id)) {
          setActiveBin((prev) => {
            const selected = prev.filter((el) => el.id !== id);
            return selected;
          });
          setInactiveBin((prev) => [...prev, raw]);
        }
      } else if (!activeBin.find((x) => x.id === id)) {
        setInactiveBin((prev) => prev.filter((el) => el.id !== id));
        setActiveBin((prev) => {
          const selected = [...prev, raw];
          return selected;
        });
      }
    },
    [activeBin, inactiveBin]
  );

  const handleSelectAll = useCallback(
    (value) => {
      const bin = [];
      activeBin.forEach((item) => bin.push(item));
      inactiveBin.forEach((item) => bin.push(item));
      if (value) {
        setActiveBin(bin);
        setInactiveBin([]);
      } else {
        setActiveBin([]);
        setInactiveBin(bin);
      }
    },
    [activeBin, inactiveBin]
  );

  useEffect(() => {
    if (setSelected) {
      setSelected(activeBin?.map((val) => val.value));
    }
    return () => {};
  }, [activeBin, setSelected]);

  return (
    <div className="flex flex-col gap-4">
      {setSelected && (
        <div
          className="flex items-center self-end"
          onClick={() => handleSelectAll(false)}
          onKeyDown={() => handleSelectAll(false)}
          role="button"
          tabIndex={0}
        >
          <img className="h-4 mr-3" src={crossIconGreen} alt="Clear" />
          <p className="font-semibold text-sm text-darkenedGreen">Clear</p>
        </div>
      )}
      <div
        className={gridContainerCN(setSelected, gridView, containerClassName)}
      >
        {setSelected ? (
          <>
            <Dustbin
              accept={[itemType]}
              onDrop={(item) => handleDrop(item, true)}
              dropped={activeElements}
              title="Active"
            />
            <Dustbin
              accept={[itemType]}
              onDrop={(item) => handleDrop(item)}
              dropped={inactiveElements}
              title="Inactive"
            />
          </>
        ) : (
          <>
            {items.map((item, index) => (
              <DraggableItem
                key={item.id}
                itemIndex={index}
                itemType={itemType}
                className={item.className}
                style={item.style}
                onReorder={reorderItems}
              >
                {item.content}
              </DraggableItem>
            ))}
          </>
        )}
      </div>
      {setSelected && (
        <Checkbox
          label="Select All"
          checked={inactiveBin?.length === 0}
          disabled={inactiveBin?.length === 0}
          onChange={handleSelectAll}
        />
      )}
    </div>
  );
};

DragAndDropGrid.propTypes = {
  /**
   * array of re-orderable components
   */
  items: PropTypes.oneOf([
    PropTypes.shape({
      active: PropTypes.arrayOf(
        PropTypes.shape({
          // currently used for the key prop, should be unique among items
          id: PropTypes.string,

          // optionally style the draggable container,
          // helpful for setting grid item styles like col-span-full
          className: PropTypes.string,

          // the JSX to render inside DraggableItem
          content: PropTypes.element,

          // Inline style
          style: PropTypes.shape({}),
        })
      ),
      inactive: PropTypes.arrayOf(
        PropTypes.shape({
          // currently used for the key prop, should be unique among items
          id: PropTypes.string,

          // optionally style the draggable container,
          // helpful for setting grid item styles like col-span-full
          className: PropTypes.string,

          // the JSX to render inside DraggableItem
          content: PropTypes.element,

          // Inline style
          style: PropTypes.shape({}),
        })
      ),
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        // currently used for the key prop, should be unique among items
        id: PropTypes.string,

        // optionally style the draggable container,
        // helpful for setting grid item styles like col-span-full
        className: PropTypes.string,

        // the JSX to render inside DraggableItem
        content: PropTypes.element,

        // Inline style
        style: PropTypes.shape({}),
      })
    ),
  ]),
  /**
   * type of draggable objects to support
   */
  itemType: PropTypes.string,

  /**
   * method for storing new order in the backend
   */
  saveNewOrder: PropTypes.func,
  /**
   * Opens selection bins for drag and drop
   * Notes: Items need value property
   */
  setSelected: PropTypes.func,
  gridView: PropTypes.bool,
  containerClassName: PropTypes.string,
};

DragAndDropGrid.defaultProps = {
  items: [],
  itemType: undefined,
  saveNewOrder: undefined,
  setSelected: undefined,
  gridView: true,
  containerClassName: undefined,
};

export default DragAndDropGrid;

import { useCallback } from "react";

const useUnitOfMeasureCellData = ({
  unitsOfMeasure,
  setEditedRow,
  editedRow,
}) => {
  const getDropdownValue = useCallback(
    (val) => {
      let rv = {};
      rv = unitsOfMeasure.find((u) => u.value === val);

      return rv;
    },
    [unitsOfMeasure]
  );

  const onChange = (newVal) => {
    setEditedRow({
      ...editedRow,
      unitOfMeasure: newVal?.value,
      highRange: {
        ...editedRow?.highRange,
        unitOfMeasure: editedRow?.isCostRange ? newVal?.value : undefined,
      },
    });
  };

  return {
    getDropdownValue,
    onChange,
  };
};

export default useUnitOfMeasureCellData;

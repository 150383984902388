import React from "react";
import cntl from "cntl";
import useContactList from "./useContactList";
import CardGrid from "../../../components/ContactCardGrid";
import SiteHeader from "../../../stories/Components/SiteHeader/SiteHeader";
import PrimaryButton from "../../../stories/Components/Buttons/PrimaryButton";
import SiteHeaderSwitchView from "../../../stories/Components/SiteHeader/SiteHeaderSwitchView";
import Table from "../../../stories/Components/Table/Table";
import ImportContactsModal from "../../../stories/Components/ContactView/ImportContactsModal";
import { SHOW_COLUMNS_SETTING_RIGHT } from "../../../constants";
import TableActionsIconsGroup from "../../../stories/Components/Table/TableActionsIconsGroup";
import fadeInClassName from "../../../helpers/Utilities/fadeInClassName";

const contactHeaderCN = (isFetching) => cntl`
flex
flex-col
gap-4
w-full
items-center
justify-between
${fadeInClassName(!isFetching)}
`;

const contactHeader = (
  { isShowingTable, setIsShowingTable, isFetching },
  props
) => (
  <div className={contactHeaderCN(isFetching)}>
    <div className="flex flex-row gap-3 w-full items-center h-10">
      {props.search}
      {props.viewOptions}
      <SiteHeaderSwitchView
        noZindex
        isShowingTable={isShowingTable}
        setIsShowingTable={setIsShowingTable}
      />
    </div>
    <div className="flex flex-row justify-between gap-6 w-full">
      {isShowingTable && (
        <div className="flex self-justify-start">{props.filter}</div>
      )}
      {props.fileActionsIcons}
    </div>
  </div>
);

const ContactList = () => {
  const {
    currentUser,
    selectedRows,
    isShowingTable,
    setIsShowingTable,
    isFetching,
    history,
    showImportModal,
    setShowImportModal,
    onImport,
    handleRowSelect,
    handleRemoveUser,
    buttonActions,
    contacts,
    columns,
    canDeleteUsers,
    allowSelection,
    templateSettings,
  } = useContactList();
  return (
    <>
      <SiteHeader
        title="Contacts"
        buttons={
          <PrimaryButton
            large
            title="Actions"
            className="dropdown-btn"
            dropdownItems={buttonActions}
          />
        }
      />
      <div
        style={{
          borderRadius: "10px",
          height: "1098px", // figma specific
        }}
        className={`${
          isFetching && "loading relative"
        } border border-es-light-grey shadow-lg p-8 w-full justify-between bg-es-white`}
      >
        <Table
          showConstantRowIndex
          disableCreateTemplate
          templateSettings={templateSettings}
          showScrollShadow
          pagination={isShowingTable && 20}
          isLoading={isFetching}
          resourceName="contacts"
          data={contacts}
          columns={columns}
          onRowClick={(row) => history.push(`/contacts/${row.id}/0`)}
          allowSelection={allowSelection}
          onSelectedRowChange={handleRowSelect}
          onDeleteRowClick={handleRemoveUser}
          fileActionsIcons={
            <TableActionsIconsGroup
              style={{
                height: "100%",
              }}
              handleShowDelete={handleRemoveUser}
              canDelete={isShowingTable && canDeleteUsers}
              disabled={selectedRows?.length === 0}
              selectedRows={selectedRows}
              showColumnSettingsRight={
                SHOW_COLUMNS_SETTING_RIGHT && isShowingTable
              }
              disabledColumnSettings={!isShowingTable}
            />
          }
          cardGrid={!isShowingTable}
          cardGridComponent={(row) => (
            <CardGrid currentUser={currentUser} row={row} />
          )}
          customSiteHeader={(props) =>
            contactHeader(
              { isShowingTable, setIsShowingTable, isFetching },
              props
            )
          }
        />
      </div>
      {showImportModal && (
        <ImportContactsModal
          onClose={() => setShowImportModal(false)}
          onCSVUpload={onImport}
        />
      )}
    </>
  );
};

export default ContactList;

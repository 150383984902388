/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";

const containerCN = (center, verticalCenter) => cntl`
  flex
  ${center && "justify-center"}
  ${verticalCenter && "items-center"}
`;

const footerCellCN = (center) => cntl`
  py-2
  px-1
  ${!center && "ml-4"}
`;

const TableFooter = ({ column, center, verticalCenter }) => {
  return (
    <div
      className={containerCN(center, verticalCenter)}
      {...column.getFooterProps()}
    >
      <div className={footerCellCN(center)}>{column.render("Footer")}</div>
    </div>
  );
};

TableFooter.propTypes = {
  /**
   * react table column
   */
  // eslint-disable-next-line react/forbid-prop-types
  column: PropTypes.object.isRequired,
  center: PropTypes.bool,
  verticalCenter: PropTypes.bool,
};

TableFooter.defaultProps = {
  center: false,
  verticalCenter: false,
};

export default TableFooter;

import Api from "@griffingroupglobal/eslib-api";

import useAuthenticatedQuery from "../useAuthenticatedQuery";
import { rsvpKeys } from "../../config/reactQuery/queryKeyFactory";

const getRsvp = async (association, instanceStartDate, signal) => {
  const params = { association };
  if (instanceStartDate) params.instanceStartDate = instanceStartDate;
  const { data } = await Api.get("/api/Rsvp/", {
    params,
    signal,
  });

  return data;
};

const useRsvp = (ref, instanceStartDate) =>
  useAuthenticatedQuery({
    queryKey: rsvpKeys.byRefAndInstanceStartDate(ref, instanceStartDate),
    queryFn: ({ signal }) => getRsvp(ref, instanceStartDate, signal),
    enabled: !!ref,
    onError: () => {
      console.error(`Unable to load rsvp for ${ref}`);
    },
  });

export default useRsvp;

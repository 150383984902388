import React from "react";
import useRealTimeConnectionStatusData from "./useRealTimeConnectionStatusData";

const RealTimeConnectionStatus = () => {
  const { isConnected } = useRealTimeConnectionStatusData();

  return (
    <div className="fixed bottom-10 right-6 z-100">
      {isConnected ? (
        <div className="relative flex items-center justify-center">
          <div className="absolute w-3 h-3 bg-es-green opacity-75 rounded-full animate-ping" />
          <div className="w-2 h-2 bg-es-dark-green rounded-full animate-pulse" />
        </div>
      ) : (
        <div className="w-2 h-2 rounded-full bg-es-red animate-bounce" />
      )}
    </div>
  );
};

export default RealTimeConnectionStatus;

import { useCallback, useEffect, useState } from "react";

const useCsiCodeCellData = ({
  csiCodes,
  groupBy,
  row,
  editedRow,
  setEditedRow,
}) => {
  const [originalFilteredCsiCodes, setOriginalFilteredCsiCodes] =
    useState(csiCodes);

  const getDropdownValue = useCallback(
    (val) => {
      let rv = {};
      rv = originalFilteredCsiCodes.find(
        (code) => code.value === `${val?.division} ${val?.code} ${val?.subcode}`
      );

      return rv;
    },
    [originalFilteredCsiCodes]
  );

  // set initial list of codes available for that row
  useEffect(() => {
    if (
      groupBy?.length &&
      groupBy.includes("csiCodeDivision") &&
      row?.original?.csiCodeDivision !== "" &&
      row?.original?.csiCodeDivision !== undefined
    ) {
      const divCode = row?.original?.csiCodeDivision.split(" - ")[0];
      setOriginalFilteredCsiCodes(
        csiCodes.filter((code) => code.value.split(" ")[0] === divCode)
      );
    }
  }, [csiCodes, groupBy, row?.original?.csiCodeDivision]);

  const onChange = (newVal) => {
    const codes = newVal?.value?.split(" ");

    setEditedRow({
      ...editedRow,
      csiCode: newVal?.value,
      financialCode: {
        ...editedRow.financialCode,
        division: codes?.[0],
        code: codes?.[1],
        subcode: codes?.[2],
      },
    });
  };

  return { originalFilteredCsiCodes, onChange, getDropdownValue };
};

export default useCsiCodeCellData;

import useAuthenticatedQuery from "../../useAuthenticatedQuery";
import fetchSpaceHandler from "./handlers/fetchSpaceHandler";
import spacesKeys from "./spacesKeys";

const useSingleSpace = (id) => {
  const authenticatedResponse = useAuthenticatedQuery({
    queryKey: spacesKeys.byId(id),
    enabled: !!id,
    queryFn: ({ signal }) => fetchSpaceHandler(id, signal),
    onError: () => {
      console.error("useSingleSpace : there was a problem getting the space");
    },
  });

  const {
    data: space,
    isLoading: spaceLoading,
    error: spaceError,
  } = authenticatedResponse;

  return {
    space,
    spaceLoading,
    spaceError,
  };
};

export default useSingleSpace;

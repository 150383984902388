import React from "react";
import PropTypes from "prop-types";
import TableCellInput from "../../../Table/TableCellInput";
import CurrencyView from "../../../CurrencyView/CurrrencyView";
import useCostPerCellData from "./useCostPerCellData";

const CostPerCell = ({
  row,
  isEditingTable,
  setEditedRow,
  editedRow,
  isHighRangeField,
}) => {
  const { value, onChange } = useCostPerCellData({
    setEditedRow,
    editedRow,
    isHighRangeField,
  });

  if (!isEditingTable) {
    return (
      <CurrencyView
        value={
          isHighRangeField
            ? row?.original?.highRange?.costPerUnit
            : row?.original?.costPerUnit
        }
      />
    );
  }

  const hide = isHighRangeField && !editedRow?.isCostRange;
  return (
    <div className={`flex w-full ${hide && "invisible"}`}>
      <TableCellInput
        value={value}
        onChange={onChange}
        isDisabled={editedRow?.rate}
      />
    </div>
  );
};

CostPerCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      costPerUnit: PropTypes.string,
      highRange: PropTypes.shape({
        costPerUnit: PropTypes.string,
      }),
    }),
  }),
  setEditedRow: PropTypes.func,
  editedRow: PropTypes.shape({
    description: PropTypes.string,
    rate: PropTypes.string,
    isCostRange: PropTypes.bool,
  }),
  isEditingTable: PropTypes.bool,
  isHighRangeField: PropTypes.bool,
};

CostPerCell.defaultProps = {
  row: undefined,
  setEditedRow: () => {},
  editedRow: {},
  isEditingTable: false,
  isHighRangeField: false,
};

export default CostPerCell;

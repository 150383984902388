import React from "react";
import { RealTimeResourceProvider } from "@griffingroupglobal/eslib-ably/react";
import useSpaceSinglePageData from "./useSpaceSinglePageData";
import SpaceView from "../../Overviews/Locations/SpaceView";

const SpaceSinglePage = () => {
  const { reference, spaceId, propertyId, projectId } =
    useSpaceSinglePageData();

  return (
    <RealTimeResourceProvider reference={reference}>
      <SpaceView
        spaceId={spaceId}
        projectId={projectId}
        propertyId={propertyId}
        reference={reference}
      />
    </RealTimeResourceProvider>
  );
};

export default SpaceSinglePage;

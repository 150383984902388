import Api from "@griffingroupglobal/eslib-api";
import { sopKeys } from "../config/reactQuery/queryKeyFactory";
import useAuthenticatedInfiniteQuery from "./useAuthenticatedInfiniteQuery";

/**
 * Fetch the history for a given SOP
 * @param {string} association - Sop reference
 */
const fetchActivity = async (association, pageParam) => {
  const { data } = await Api.get("/api/History", {
    paging: false,
    params: {
      association,
      limit: 10,
      page: pageParam,
    },
  });

  return {
    data: data.entries.map(({ resource }) => resource),
    page: data.page,
    pages: data.pages,
  };
};

/**
 * Infinity Query hook to return sop history
 * @param {string} association - Sop reference
 */
const useSopActivity = (association) => {
  const id = association?.split("/")[1];

  const queryKey = sopKeys.activity(id);

  return useAuthenticatedInfiniteQuery({
    queryKey,
    enabled: !!association,
    queryFn: ({ pageParam = 1 }) => fetchActivity(association, pageParam),
    getNextPageParam: (lastPageData) => {
      let nextPage;

      if (lastPageData.page < lastPageData.pages) {
        nextPage = lastPageData.page + 1;
      }

      return nextPage;
    },
    onError: (error) => {
      console.error("useSopActivity", error);
    },
  });
};

export default useSopActivity;

import { useModalState } from "../../../state/modalState";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useURLQueryParameter from "../../../hooks/useURLQueryParameter";
import {
  ADD_OPEN_MODAL,
  REPORT,
  REPORT_CREATE_MODAL,
} from "../../../constants";
import { hasWritePermission } from "../../../helpers/Permissions";

const useCreateReportButton = () => {
  const selectedAssociations = useURLQueryParameter("associations");

  const { data: currentUser } = useCurrentUser();
  const [{ modals }, modalDispatch] = useModalState();
  const isDisabledButton = modals?.find(
    (item) => item.modalType === REPORT_CREATE_MODAL
  );

  const canCreate =
    currentUser?.isAdmin || hasWritePermission(REPORT, currentUser);

  const handleOpenModal = () => {
    const multiAssociations = selectedAssociations.split(",") ?? [];

    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: "new-report-modal" },
      modalData: {
        multiAssociations,
      },
      modalType: REPORT_CREATE_MODAL,
    });
  };

  return {
    canCreate,
    shouldDisableButton: isDisabledButton ? true : !selectedAssociations,
    handleOpenModal,
  };
};

export default useCreateReportButton;

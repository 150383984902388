import React from "react";
import PropTypes from "prop-types";
import dragIcon from "../../assets/images/drag_icon.svg";
import DraggableItem from "./DraggableItem";

const SelectableItem = ({ item, index, itemType }) => {
  return (
    <DraggableItem
      id={item.id}
      itemIndex={index}
      item={item}
      itemType={itemType}
      className={item.className}
      style={item.style}
    >
      <div
        className="flex text-gray-450 border p-2 justify-start items-center rounded-md truncate"
        title={item.value}
      >
        <img
          className="h-4 cursor-move mt-1 select-none mr-4"
          src={dragIcon}
          alt="drag icon"
          draggable={false}
        />
        {item.content}
      </div>
    </DraggableItem>
  );
};

SelectableItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.shape({}),
    content: PropTypes.element,
    value: PropTypes.string,
  }),
  index: PropTypes.number,
  itemType: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape({}),
};

SelectableItem.defaultProps = {
  item: undefined,
  index: undefined,
  itemType: undefined,
  className: undefined,
  style: undefined,
};

export default SelectableItem;

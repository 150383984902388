import fetchAssetsOverview from "../../helpers/Assets/fetchAssetsOverview";
import useAuthenticatedQuery from "../useAuthenticatedQuery";
import assetKeys from "./assetsKeys";

/**
 * Query hook to fetch assets overview
 */
const useAssetsOverview = ({ enabled = true, select } = {}) => {
  const queryInfo = useAuthenticatedQuery({
    queryKey: assetKeys.overview(),
    enabled,
    select,
    queryFn: ({ signal }) => fetchAssetsOverview(signal),
    onError: (error) => {
      console.error("useAssetsOverview: Error fetching assets overview", error);
    },
  });

  return {
    queryInfo,
    assetsDict: queryInfo?.data?.assetsDict,
    assetsDD: queryInfo?.data?.assetsDD,
  };
};

export default useAssetsOverview;

import { AssetAPI } from "@griffingroupglobal/eslib-api";
import { useCallback } from "react";
import useAuthenticatedQuery from "../useAuthenticatedQuery";
import assetKeys from "./assetsKeys";
import { DEFAULT_QUERIES_STALE_TIME } from "../../constants";

/**
 * Query hook that returns a single asset by id
 * @param {string} id - asset id
 */
const useAssetById = (id, refetch) => {
  /**
   * Fetch single asset
   */
  const fetchSingleAsset = useCallback(
    async (signal) => {
      const { data } = await AssetAPI.getById(id, { signal });

      return data;
    },
    [id]
  );

  const enabled = !!id;

  return useAuthenticatedQuery({
    queryKey: assetKeys.byId(id),
    queryFn: ({ signal }) => fetchSingleAsset(signal),
    enabled,
    staleTime: refetch ? 0 : DEFAULT_QUERIES_STALE_TIME,
  });
};

export default useAssetById;

import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import ResourceCard from "../ResourceCard/ResourceCard";

const buttonCN = () => cntl`
  w-30 
  h-30
  text-center
  rounded-md 
  my-auto 
  px-5 
  py-5
`;

const LocationOrSpaceSelectCard = ({
  item,
  setSelectedLocationOrSpace,
  active,
}) => {
  return (
    <div className="flex flex-col items-center justify-Center rounded-sm mx-2 mt-4">
      <button
        className={buttonCN()}
        style={{ transform: active ? "scale(1.025)" : "" }}
        onClick={() => {
          setSelectedLocationOrSpace(item?.id);
        }}
        type="button"
        aria-label="select location or space"
      >
        <ResourceCard
          title={item?.name}
          imageReference={item?.primaryImage}
          detail={item?.levelType}
          className={`${!active && "bg-es-extra-light-grey"}`}
        />
      </button>
    </div>
  );
};

LocationOrSpaceSelectCard.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    area: PropTypes.number,
    primaryImage: PropTypes.string,
    id: PropTypes.string,
    levelType: PropTypes.string,
  }),
  setSelectedLocationOrSpace: PropTypes.func,
  active: PropTypes.bool,
};
LocationOrSpaceSelectCard.defaultProps = {
  item: {},
  setSelectedLocationOrSpace: undefined,
  active: false,
};

export default LocationOrSpaceSelectCard;

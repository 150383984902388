import React from "react";
import useParentTask from "./useParentTask";
import taskIcon from "../../../assets/images/taskIcon.svg";

const ParentTask = ({ parentTaskRef }) => {
  const { parentTaskName, onClick } = useParentTask({
    parentTaskRef,
  });
  return (
    <button
      className="flex flex-row items-start gap-2"
      key={parentTaskName}
      type="button"
      onClick={() => onClick()}
    >
      <img
        src={taskIcon}
        className="self-start"
        alt={`Navigate to Task, ${parentTaskName}`}
        style={{ height: "20px", width: "20px" }}
      />
      <p className="text-es-normal text-es-medium-grey flex-wrap text-left">
        {parentTaskName}
      </p>
    </button>
  );
};

export default ParentTask;

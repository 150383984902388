/* eslint-disable prefer-destructuring */
import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import { useInView } from "react-intersection-observer";
import { truncate } from "lodash";
import crossIcon from "../../assets/images/crossWhiteBackgroundGreyShapeCircle.svg";
import ContactCardImage from "./ContactCardImage";
import "./index.css";
import fadeInClassName from "../../../helpers/Utilities/fadeInClassName";

const contactCardCN = (isInactive, inView) => cntl`
flex
border-es-light-grey
border
rounded-lg
cursor-pointer
p-4
hover:shadow-medium-lift
${isInactive && "bg-es-extra-light-grey"}
${fadeInClassName(inView)}
`;

function ContactCard({
  id,
  title,
  detail,
  tag,
  isInactive,
  resourceName,
  imageReference,
  onClick,
}) {
  const { inView, ref } = useInView(); // smooths out scrolling cards into view via tailwinds opacity transition
  return (
    <div
      ref={ref}
      id={id}
      style={{ height: 118, width: 357 }}
      className={contactCardCN(isInactive, inView)}
      onClick={onClick}
      role="presentation"
    >
      <ContactCardImage
        imageReference={imageReference}
        title={title}
        resourceName={resourceName}
        isInactive={isInactive}
      />
      <div className="flex w-full flex-col pl-3 pt-1">
        <div className="flex justify-between">
          <div
            className={`w-min rounded-md px-1.5 py-1 ${
              isInactive ? "bg-es-white" : "bg-es-extra-light-grey"
            }`}
          >
            <p className="text-es-dark-grey font-es-medium text-es-xs tracking-es-normal">
              {tag}
            </p>
          </div>
          {isInactive && (
            <div
              className={`flex flex-row rounded-md px-1.5 py-1 ${
                isInactive ? "bg-es-white" : "bg-es-extra-light-grey"
              }`}
            >
              <img src={crossIcon} alt="close" />
              <div className="flex pl-1">
                <p className="text-es-medium-grey font-es-medium text-es-xs tracking-es-normal">
                  Inactive
                </p>
              </div>
            </div>
          )}
        </div>

        <div className="pt-1 font-es-semibold text-es-normal text-es-dark-grey tracking-es-wide capitalize">
          {truncate(title, { length: 20 })}
        </div>
        <div className="font-es-normal text-es-normal text-es-dark-grey pt-1">
          {truncate(detail, { length: 25 })}
        </div>
      </div>
    </div>
  );
}

ContactCard.propTypes = {
  /**
   * ID Of Resource
   */
  id: PropTypes.string,
  /**
   * Title Of Resource
   */
  title: PropTypes.string,
  /**
   * Sub title of resource
   */
  detail: PropTypes.string,
  // used for tags e.g. Project budget type
  tag: PropTypes.string,
  /**
   * Contact Resource
   * ie. { name: {firstName: "", lastName: ""},
   */
  contact: PropTypes.shape({
    name: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    id: PropTypes.string,
    kind: PropTypes.string,
    contactPoint: PropTypes.shape([]),
  }),
  /**
   * Name Of Resource
   */
  resourceName: PropTypes.string,
  imageReference: PropTypes.string,
  onClick: PropTypes.func,
  isInactive: PropTypes.bool,
};

ContactCard.defaultProps = {
  id: "",
  title: "",
  detail: "",
  tag: undefined,
  contact: undefined,
  resourceName: "[Resource]",
  imageReference: undefined,
  onClick: () => {},
  isInactive: false,
};

export default ContactCard;

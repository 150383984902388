import React from "react";
import PropTypes from "prop-types";
import TableCellDropdown from "../../../Table/TableCellDropdown";

// TODO: creating/editing/viewing budget groups needs to be handled. Check with design
const GroupCell = ({
  isEditingTable,
  budgetGroups,
  setEditedRow,
  editedRow,
  row,
}) => {
  const onChange = (newVal) => {
    setEditedRow({
      ...editedRow,
      group: newVal?.value,
    });
  };

  // View mode, if not editing
  if (!isEditingTable) {
    return row?.original?.groupName;
  }

  return (
    <>
      <div className="flex w-full">
        <TableCellDropdown
          value={editedRow?.group}
          options={budgetGroups}
          onChange={onChange}
        />
      </div>
    </>
  );
};

GroupCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      groupName: PropTypes.string,
    }),
  }),
  editedRow: PropTypes.shape({
    group: PropTypes.string,
  }),
  isEditingTable: PropTypes.bool,
  setEditedRow: PropTypes.func,
  budgetGroups: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

GroupCell.defaultProps = {
  row: undefined,
  editedRow: {},
  isEditingTable: false,
  setEditedRow: undefined,
  budgetGroups: [],
};

export default GroupCell;

import React, { useState } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";

import Input from "../Input/Input";
import Dropdown from "../Dropdown/Dropdown";
import DatePicker from "../DatePicker/DatePicker";
import IconButton from "../Buttons/IconButton";
import TertiaryButton from "../Buttons/TertiaryButton";

import collapseIcon from "../../assets/images/collapseIcon.svg";
import eventIcon from "../../assets/images/eventIcon.png";
import plusIcon from "../../assets/images/plusIcon.svg";
import RadioButton from "../Buttons/RadioButton";
import { typeOfDurationOptions } from "../../../constants";
import BillingAddress from "../AddressInput/BillingAddress";
import BaseAddressInput from "../AddressInput/BaseAddressInput";
import PlusCircleButton from "../Buttons/PlusCircleButton/PlusCircleButton";
import { simpleUrl } from "../../../helpers/FormValidations";
import TagsContainer from "../Tags/TagsContainer";
import useAttributes from "../../../hooks/useAttributes";

const eventIconElement = (
  <img className="w-4 h-4" src={eventIcon} alt="event icon" />
);

const wrapperCN = (className, type) => cntl`
  flex 
  items-center
  w-full
  h-full 
  ${className}
  ${type === "TEXTAREA" ? "" : ""}
`;
const valueContCN = (type) => cntl`
  flex 
  flex-row
  w-full
  ${type !== "TEXTAREA" ? "" : ""}
`;

const EditField = ({
  className,
  children,
  type,
  value,
  editValue,
  options,
  onChange,
  onDelete,
  onBlur,
  isEditing,
  handleAddClick,
  addItemText,
  handleBuyLink,
  additionalInfo,
  fullWidth,
  handleForwardRef,
  overviewItems,
  setEdited,
  getAssetCategoryValue,
  disableDropdownSort,
  formatOptionLabel,
  dispatch,
  allowDateClear,
}) => {
  const [hovering, setHovering] = useState(false);
  const [, , selectedMeasurements] = useAttributes();

  const handleHover = (linkId) => {
    if (linkId) {
      setHovering(linkId);
    } else {
      setHovering();
    }
  };

  const handleBlur = () => {
    onBlur();
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleBlur();
    }
  };

  const circleCN = cntl`
  w-3
  h-3
  rounded-full
  ${editValue?.endDate ? "bg-brandGreen" : "bg-gray-200"}
`;
  /* Commented Out in-case we want to use this code later in a separate input specifically dealing with currency */
  /*   const getNumberValue = () => {
    let rv = value;
    if (editValue !== undefined) {
      rv = editValue;
    }
    return activeCurrencyFormatter(rv);
  }; */

  const getNumericOnly = (evt) => {
    if (evt.which === 8 || evt.which === 9 || evt.which === 13) return true;
    if (evt.which < 48 || evt.which > 57) {
      evt.preventDefault();
      return false;
    }
    return true;
  };

  const getBuyLink = () => {
    const attribute = selectedMeasurements.find(
      (x) => x.detail === "Buy It Again"
    );
    return additionalInfo.find((el) => el?.id === attribute.id);
  };

  return (
    <div
      className={`relative flex flex-row w-1/2 ${fullWidth ? "flex-1" : ""}`}
    >
      <div className={wrapperCN(className, type)}>
        {!isEditing && <div className={valueContCN(type)}>{children}</div>}

        {isEditing && (
          <>
            {type === "DROPDOWN" && (
              <Dropdown
                options={options}
                value={options?.find(
                  (item) => item.value === value || item.value === editValue
                )}
                onChange={onChange}
                onKeyDown={handleKeyDown}
                disableClear
                disableSort={disableDropdownSort}
                formatOptionLabel={formatOptionLabel}
              />
            )}
            {type === "ALT_DROPDOWN" && (
              <Dropdown
                options={options}
                value={value}
                onChange={onChange}
                onKeyDown={handleKeyDown}
                disableClear
              />
            )}
            {type === "DATE" && (
              <DatePicker
                value={value}
                onChange={onChange}
                allowClear={allowDateClear}
              />
            )}
            {type === "DATERANGE" && (
              <div className="flex items-center">
                <div className="flex-1 mr-8">
                  <div className="-mt-10 mb-4 text-red-400 invisible">
                    Invalid start date
                  </div>
                  <div className="flex">
                    <DatePicker
                      className="-mt-4"
                      name="startDate"
                      label="Start Date"
                      placeholder="MM-DD-YYYY"
                      value={editValue?.startDate}
                      onChange={(val) => onChange("startDate", val)}
                      iconLeft={eventIconElement}
                    />
                    <div className="relative top-7 left-0 z-10 w-0 h-px">
                      <div className="flex w-6 items-center">
                        <div className="w-3 h-px bg-gray-200" />
                        <div className={circleCN} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex-1">
                  <div className="-mt-10 mb-4 text-red-400 invisible">
                    Invalid end date
                  </div>
                  <DatePicker
                    className="-mt-4"
                    name="endDate"
                    label="End Date"
                    placeholder="MM-DD-YYYY"
                    value={editValue?.endDate}
                    onChange={(val) => onChange("endDate", val)}
                    iconLeft={eventIconElement}
                  />
                </div>
              </div>
            )}
            {type === "TEXTAREA" && (
              <Input
                placeholder="Value"
                mainClassName="w-full"
                value={value}
                onChange={onChange}
                onKeyDown={handleKeyDown}
                isTextarea
                disableClear
              />
            )}
            {type === "NUMBER" && (
              <Input
                placeholder="Value"
                mainClassName="w-full"
                type="number"
                value={editValue !== undefined ? editValue : value}
                onChange={onChange}
                onKeyDown={(e) => {
                  if (getNumericOnly(e)) handleKeyDown(e);
                }}
                minValue="0"
                disableClear
              />
            )}
            {type === "CURRENCY" && (
              <div className="flex w-full items-center">
                <div className="block text-gray-200 absolute mr-2 -left-4">
                  <span>$</span>
                </div>
                <Input
                  placeholder="Value"
                  mainClassName="min-w-0 flex-auto"
                  value={value}
                  onChange={onChange}
                  onKeyDown={handleKeyDown}
                  disableClear
                />
              </div>
            )}
            {type === "LINKS" && (
              <div className="flex flex-col w-full">
                <Input
                  placeholder="Link"
                  className="w-full mb-2"
                  label="Buy It Again URL"
                  value={getBuyLink()?.value}
                  onChange={(val) => handleBuyLink({ ...getBuyLink(), val })}
                  disableClear
                />
                {value.map((link) => (
                  <div
                    key={link.id}
                    className="flex items-center w-full mb-4"
                    onMouseOver={() => handleHover(link.id)}
                    onMouseOut={() => handleHover()}
                    onFocus={() => handleHover(link.id)}
                    onBlur={() => handleHover()}
                  >
                    <div className="flex flex-col w-full">
                      <Input
                        placeholder="Title"
                        className="w-full mb-2"
                        label="Title"
                        value={link.name}
                        onChange={(name) => onChange({ ...link, name })}
                        disableClear
                      />
                      <Input
                        placeholder="Url"
                        className="w-full"
                        label="URL"
                        value={link.url}
                        onChange={(url) => onChange({ ...link, url })}
                        disableClear
                      />
                    </div>
                    <div className="mt-7 ml-4">
                      <IconButton
                        wrapperClassName={
                          hovering === link.id ? "" : "invisible"
                        }
                        imgClassName="w-6 h-6"
                        iconClassName="w-6 h-6"
                        onClick={() => onDelete(link.id)}
                        icon={collapseIcon}
                      />
                    </div>
                  </div>
                ))}
                <TertiaryButton
                  title={addItemText ?? "Add Link"}
                  iconLeft={
                    <img
                      style={{ marginBottom: "2px" }}
                      src={plusIcon}
                      alt="plus"
                    />
                  }
                  onClick={handleAddClick}
                />
              </div>
            )}
            {type === "ADDRESS" && (
              <BaseAddressInput
                address={value}
                onChange={onChange}
                onForwardRef={handleForwardRef}
              />
            )}
            {type === "OVERVIEW" && (
              <div className="flex flex-row w-full">
                <div
                  className={`flex flex-col mt-2 ${
                    overviewItems?.propertyTypes || overviewItems?.projectTypes
                      ? "w-1/3 pr-4"
                      : "w-3/5 pr-10"
                  }`}
                >
                  {overviewItems?.assetTypes && (
                    <div className="flex mb-2">
                      <Input
                        placeholder="Name"
                        mainClassName="w-full"
                        value={value?.name}
                        onChange={(val) => onChange("name", val)}
                        onKeyDown={handleKeyDown}
                        disableClear
                        label="Asset Name"
                      />
                    </div>
                  )}
                  {overviewItems?.propertyTypes && (
                    <div className="flex mb-2">
                      <Input
                        placeholder="Name"
                        value={value?.title}
                        onChange={(val) => onChange("title", val)}
                        onKeyDown={handleKeyDown}
                        disableClear
                        label="Property Name"
                      />
                    </div>
                  )}
                  <div className="flex mb-2">
                    <Dropdown
                      options={
                        (overviewItems?.propertyTypes ||
                          overviewItems?.projectTypes) ??
                        overviewItems?.assetTypes
                      }
                      value={
                        // eslint-disable-next-line no-nested-ternary
                        overviewItems?.propertyTypes
                          ? overviewItems?.propertyTypes?.find(
                              (item) => item.value === value?.propertyType
                            )
                          : overviewItems?.projectTypes
                          ? overviewItems?.projectTypes?.find(
                              (item) => item.value === value?.projectType
                            )
                          : getAssetCategoryValue()
                      }
                      onChange={(val) =>
                        onChange(
                          // eslint-disable-next-line no-nested-ternary
                          overviewItems?.propertyTypes
                            ? "propertyType"
                            : overviewItems?.projectTypes
                            ? "projectType"
                            : "assetType",
                          // eslint-disable-next-line no-nested-ternary
                          overviewItems?.propertyTypes
                            ? val.value
                            : overviewItems?.projectTypes
                            ? val.value
                            : val
                        )
                      }
                      onKeyDown={handleKeyDown}
                      disableClear
                      label={
                        // eslint-disable-next-line no-nested-ternary
                        overviewItems?.propertyTypes
                          ? "Type"
                          : overviewItems?.projectTypes
                          ? "Project Type"
                          : "Asset Type"
                      }
                    />
                  </div>

                  <Input
                    placeholder="Description"
                    value={value?.description}
                    onChange={(val) => onChange("description", val)}
                    onKeyDown={handleKeyDown}
                    isTextarea
                    label={
                      // eslint-disable-next-line no-nested-ternary
                      overviewItems?.propertyTypes
                        ? "Description"
                        : overviewItems?.projectTypes
                        ? "Project Description"
                        : "Description"
                    }
                  />
                  {overviewItems?.projectTypes && (
                    <>
                      <Input
                        placeholder="Goals"
                        value={value?.goals}
                        onChange={(val) => onChange("goals", val)}
                        onKeyDown={handleKeyDown}
                        isTextarea
                        disableClear
                        label="Project Goals"
                      />
                      <Input
                        placeholder="Challenges"
                        value={value?.challenges}
                        onChange={(val) => onChange("challenges", val)}
                        onKeyDown={handleKeyDown}
                        isTextarea
                        disableClear
                        label="Project Challenges"
                      />{" "}
                    </>
                  )}
                </div>
                {(overviewItems?.propertyTypes ||
                  overviewItems?.projectTypes) && (
                  <div className="flex flex-col mt-2 w-1/3 pr-4">
                    <BillingAddress
                      address={value?.address}
                      mailingSameAsPhysical={value?.mailingSameAsPhysical}
                      onChange={overviewItems?.handleAddressInput}
                      handleMailingSameAsPhysicalInput={
                        overviewItems?.handleMailingSameAsPhysicalInput
                      }
                      setEdited={setEdited}
                    />
                  </div>
                )}
                <div
                  className={`flex flex-col mt-2 ${
                    overviewItems?.propertyTypes || overviewItems?.projectTypes
                      ? "w-1/3 pr-10"
                      : "w-2/5"
                  }`}
                >
                  {overviewItems?.assetTypes && (
                    <div className="flex w-full mb-2">
                      <Input
                        placeholder="Link"
                        mainClassName="w-full"
                        label="Buy It Again URL"
                        value={getBuyLink()?.value}
                        onChange={(val) =>
                          handleBuyLink({ ...getBuyLink(), val })
                        }
                        disableClear
                      />
                      <div className="mt-7 ml-4 w-6 mr-8 overflow-hidden">
                        <p className="w-full"> </p>
                      </div>
                    </div>
                  )}
                  {value?.links?.map((link) => (
                    <div
                      key={link.id}
                      className="flex items-center mb-2"
                      onMouseOver={() => handleHover(link.id)}
                      onMouseOut={() => handleHover()}
                      onFocus={() => handleHover(link.id)}
                      onBlur={() => handleHover()}
                    >
                      <div className="flex flex-col w-full">
                        <Input
                          placeholder="Title"
                          label="Title"
                          className="mb-3"
                          value={link.name}
                          onChange={(name) => {
                            setEdited(true);
                            overviewItems?.handleChangeLink({
                              ...link,
                              name,
                            });
                          }}
                          disableClear
                          autoComplete="off"
                        />
                        <Input
                          placeholder="Url"
                          label="URL"
                          value={link.url}
                          onChange={(url) => {
                            setEdited(true);
                            overviewItems?.handleChangeLink({ ...link, url });
                          }}
                          disableClear
                          validation={simpleUrl}
                          errorMessageClassName="text-xs"
                        />
                      </div>
                      <div className="mt-7 ml-4 mr-8">
                        <IconButton
                          wrapperClassName={
                            hovering === link.id ? "" : "invisible"
                          }
                          imgClassName="w-6 h-6"
                          iconClassName="w-6 h-6"
                          onClick={() => {
                            setEdited(true);
                            overviewItems?.handleDeleteLink(link.id);
                          }}
                          icon={collapseIcon}
                        />
                      </div>
                    </div>
                  ))}
                  <PlusCircleButton
                    title={addItemText ?? "Add Link"}
                    onClick={() => {
                      setEdited(true);
                      overviewItems?.handleAddLink();
                    }}
                  />
                </div>
              </div>
            )}
            {type === "OVERVIEW_PROJECT" && (
              <div className="flex flex-row w-full">
                <div className="flex flex-col w-2/3 pr-4 mt-2">
                  <div className="flex w-full">
                    <div className="flex flex-col pr-4 w-1/2">
                      {overviewItems?.projectTypes && (
                        <div className="flex mb-2">
                          <Input
                            placeholder="Name"
                            mainClassName="w-full"
                            label="Project Name"
                            value={value?.name}
                            onChange={(val) => onChange("name", val)}
                            onKeyDown={handleKeyDown}
                            disableClear
                          />
                        </div>
                      )}
                      <div className="flex mb-2 pt-1">
                        <Dropdown
                          label="Project Type"
                          options={overviewItems?.projectTypes}
                          value={overviewItems?.projectTypes?.find(
                            (item) => item.value === value?.projectType
                          )}
                          onChange={(val) => onChange("projectType", val.value)}
                          onKeyDown={handleKeyDown}
                          disableClear
                        />
                      </div>
                      <div className="flex mb-2 pt-1">
                        <Input
                          placeholder="Description"
                          mainClassName="w-full"
                          label="Project Description"
                          value={value?.description}
                          onChange={(val) => onChange("description", val)}
                          onKeyDown={handleKeyDown}
                          isTextarea
                        />
                      </div>
                    </div>
                    <div className="flex flex-col pr-4 w-1/2">
                      <div className="flex mb-2">
                        <Input
                          placeholder="Goals"
                          mainClassName="w-full"
                          label="Project Goals"
                          value={value?.goals}
                          onChange={(val) => onChange("goals", val)}
                          onKeyDown={handleKeyDown}
                          isTextarea
                        />
                      </div>
                      <div className="flex mb-2">
                        <Input
                          placeholder="Challenges"
                          mainClassName="w-full"
                          value={value?.challenges}
                          onChange={(val) => onChange("challenges", val)}
                          onKeyDown={handleKeyDown}
                          isTextarea
                          label="Project Challenges"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex pt-4">
                    <TagsContainer
                      isEditing
                      tagsWrapperClassName="mt-1"
                      dispatch={dispatch}
                      resource={value}
                    />
                  </div>
                </div>
                {(overviewItems?.propertyTypes ||
                  overviewItems?.projectTypes) && (
                  <div className="flex flex-col mt-2 w-1/3">
                    <BillingAddress
                      address={value?.address}
                      mailingSameAsPhysical={value?.mailingSameAsPhysical}
                      onChange={overviewItems?.handleAddressInput}
                      handleMailingSameAsPhysicalInput={
                        overviewItems?.handleMailingSameAsPhysicalInput
                      }
                      setEdited={setEdited}
                    />
                  </div>
                )}
              </div>
            )}
            {(type === "INPUT" || !type) && (
              <Input
                placeholder="Value"
                mainClassName="w-full"
                value={value}
                onChange={onChange}
                onKeyDown={handleKeyDown}
                disableClear
              />
            )}
            {type === "DROPDOWN_WITH_INPUT" && (
              <>
                <div className="w-4/6">
                  <Dropdown
                    className="text-sm"
                    options={typeOfDurationOptions ?? []}
                    value={typeOfDurationOptions?.find(
                      (item) => item.value === value?.typeOfDuration
                    )}
                    onChange={(val) => {
                      onChange("typeOfDuration", val.value);
                    }}
                    onKeyDown={handleKeyDown}
                    disableClear
                  />
                </div>
                <div className="w-2/6 mx-3">
                  <Input
                    placeholder="Value"
                    mainClassName="w-full"
                    value={value?.value}
                    onChange={(val) => {
                      onChange("value", val);
                    }}
                    onKeyDown={handleKeyDown}
                    disableClear
                  />
                </div>
              </>
            )}
            {type === "BOOLEAN" && (
              <div className="flex items-center">
                <RadioButton
                  className=""
                  label="Yes"
                  isChecked={value}
                  onChange={() => onChange(true)}
                />
                <RadioButton
                  className=""
                  label="No"
                  isChecked={!value}
                  onChange={() => onChange(false)}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

EditField.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  children: PropTypes.any,
  type: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  // value: PropTypes.oneOf(PropTypes.string, PropTypes.array, PropTypes.object),
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  editValue: PropTypes.any,
  options: PropTypes.arrayOf(PropTypes.shape({ label: PropTypes.string })),
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onBlur: PropTypes.func,
  isEditing: PropTypes.bool,
  fullWidth: PropTypes.bool,
  handleAddClick: PropTypes.func,
  addItemText: PropTypes.string,
  handleBuyLink: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  additionalInfo: PropTypes.array,
  handleForwardRef: PropTypes.func,
  overviewItems: PropTypes.shape({
    handleDeleteLink: PropTypes.func,
    handleAddLink: PropTypes.func,
    handleChangeLink: PropTypes.func,
    handleAddressInput: PropTypes.func,
    handleMailingSameAsPhysicalInput: PropTypes.func,
    propertyTypes: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    projectTypes: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
    assetTypes: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
  setEdited: PropTypes.func,
  getAssetCategoryValue: PropTypes.func,
  disableDropdownSort: PropTypes.bool,
  formatOptionLabel: PropTypes.element,
  dispatch: PropTypes.func,
  allowDateClear: PropTypes.bool,
};

EditField.defaultProps = {
  className: undefined,
  children: undefined,
  type: undefined,
  value: undefined,
  editValue: undefined,
  options: undefined,
  onChange: undefined,
  onDelete: undefined,
  onBlur: undefined,
  isEditing: undefined,
  fullWidth: undefined,
  handleAddClick: undefined,
  addItemText: undefined,
  handleBuyLink: undefined,
  additionalInfo: [],
  handleForwardRef: undefined,
  overviewItems: undefined,
  setEdited: undefined,
  getAssetCategoryValue: undefined,
  disableDropdownSort: false,
  formatOptionLabel: undefined,
  dispatch: undefined,
  allowDateClear: false,
};
export default EditField;

import { fetchAssets } from "../../helpers/Assets";
import useAuthenticatedQuery from "../useAuthenticatedQuery";
import assetKeys from "./assetsKeys";

/**
 * Query hook to fetch assets
 */
const useAssets = ({ reference, select } = {}) => {
  return useAuthenticatedQuery({
    queryKey: assetKeys.byAssociation(reference).filter(Boolean),
    queryFn: ({ signal }) => fetchAssets(reference, signal),
    select,
    onError: (error) => {
      console.error("useAssets: Error fetching assets", error);
    },
  });
};

export default useAssets;

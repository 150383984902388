import spacesKeys from "../../hooks/api/spaces/spacesKeys";

const deleteAssociationSpacesListStore = ({ queryClient, association, id }) => {
  const queryKey = spacesKeys.byAssociation(association);
  const spacesArr = queryClient.getQueryData(queryKey) ?? [];

  const index = spacesArr.findIndex((space) => space.id === id);

  if (index !== -1) {
    const newSpacesArr = [
      ...spacesArr.slice(0, index),
      ...spacesArr.slice(index + 1),
    ];

    queryClient.setQueryData(queryKey, newSpacesArr);
  }
};

export default deleteAssociationSpacesListStore;

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import WidgetContainer from "../../Widget/WidgetContainer";
import useAccountAndAdminContentData from "./useAccountAndAdminContentData";
import SiteHeader from "../../SiteHeader/SiteHeader";
import PrimaryButton from "../../Buttons/PrimaryButton";
import SettingsAndConfigurationList from "../../../../Pages/Admin/SettingsAndConfigurationList";
import MemberManagement from "../../../../Pages/Admin/MemberManagement";
import RolesAndPermissions from "../../../../Pages/Admin/RolesAndPermissions";
import BillingDashboard from "../../BillingDashboard";
import { ACCOUNTANDADMIN_TABS } from "../../../../constants";

const AccountAndAdminContent = ({
  dropDownItems,
  isSaving,
  setIsSaving,
  isCancel,
  setIsCancel,
  onSave,
  onCancel,
  isEditing,
  setIsEditing,
  setIsExportingMemberList,
  isExportingMemberList,
}) => {
  const {
    managementSettings,
    attributeSettings,
    systemSettings,
    financialsSettings,
    documentsSettings,
    tagsSettings,
    isValid,
    noChangesMade,
    settingDetails,
    billingFormData,
    isLoadingData,
    isLoadingBilling,
    canSaveBilling,
    subscriptionDeactivated,
    activeTab,
    setActiveTab,
    navigateToHolidaysSection,
    dispatchBilling,
    handleEditClick,
    widgetContainerSave,
    dispatchManagementSettings,
    dispatchAttributeSettings,
    dispatchSystemSettings,
    dispatchFinancialsSettings,
    dispatchDocumentsSettings,
    dispatchTagsSettings,
    setSettingDetails,
    setIsValid,
    resetSystemSettings,
  } = useAccountAndAdminContentData({
    isSaving,
    setIsSaving,
    setIsCancel,
    isCancel,
    isEditing,
    setIsEditing,
  });

  const tabs = useMemo(() => {
    return [
      {
        id: ACCOUNTANDADMIN_TABS.SETTINGSANDCONFIG_ID,
        title: ACCOUNTANDADMIN_TABS.SETTINGSANDCONFIG_TITLE,
        content: (
          // TODO: When updating designs for other tabs pls remove prop drilling and call the hook/reducer dispatch in the component
          <SettingsAndConfigurationList
            managementSettings={managementSettings}
            dispatchManagementSettings={dispatchManagementSettings}
            attributeSettings={attributeSettings}
            dispatchAttributeSettings={dispatchAttributeSettings}
            systemSettings={systemSettings}
            dispatchSystemSettings={dispatchSystemSettings}
            financialsSettings={financialsSettings}
            dispatchFinancialsSettings={dispatchFinancialsSettings}
            documentsSettings={documentsSettings}
            dispatchDocumentsSettings={dispatchDocumentsSettings}
            tagsSettings={tagsSettings}
            dispatchTagsSettings={dispatchTagsSettings}
            navigateToHolidaysSection={navigateToHolidaysSection}
            onSave={onSave}
            onCancel={onCancel}
            isSaving={isSaving}
            isLoading={isLoadingData}
            settingDetails={settingDetails}
            setSettingDetails={setSettingDetails}
            noChangesMade={noChangesMade}
            isValid={isValid}
            setIsValid={setIsValid}
            resetSystemSettings={resetSystemSettings}
            isEditing={isEditing}
          />
        ),
      },
      {
        id: ACCOUNTANDADMIN_TABS.MEMBERS_ID,
        title: ACCOUNTANDADMIN_TABS.MEMBERS_TITLE,
        content: (
          <MemberManagement
            setIsExportingMemberList={setIsExportingMemberList}
            isExportingMemberList={isExportingMemberList}
            noChangesMade={noChangesMade}
          />
        ),
      },
      {
        id: ACCOUNTANDADMIN_TABS.PERMISSIONS_ID,
        title: ACCOUNTANDADMIN_TABS.PERMISSIONS_TITLE,
        content: <RolesAndPermissions />,
      },
      {
        id: ACCOUNTANDADMIN_TABS.BILLING_ID,
        title: ACCOUNTANDADMIN_TABS.BILLING_TITLE,
        content: (
          <BillingDashboard
            isEditing={isEditing}
            dispatchBilling={dispatchBilling}
            billingFormData={billingFormData}
            isLoading={isLoadingBilling}
          />
        ),
      },
    ];
  }, [
    attributeSettings,
    billingFormData,
    dispatchAttributeSettings,
    dispatchBilling,
    dispatchDocumentsSettings,
    dispatchFinancialsSettings,
    dispatchManagementSettings,
    dispatchSystemSettings,
    dispatchTagsSettings,
    documentsSettings,
    financialsSettings,
    isEditing,
    isExportingMemberList,
    isLoadingBilling,
    isLoadingData,
    isSaving,
    isValid,
    managementSettings,
    navigateToHolidaysSection,
    noChangesMade,
    onCancel,
    onSave,
    resetSystemSettings,
    setIsExportingMemberList,
    setIsValid,
    setSettingDetails,
    settingDetails,
    systemSettings,
    tagsSettings,
  ]);

  return (
    <>
      <SiteHeader
        title="Account &amp; Admin"
        showPrimaryButton
        primaryButtonText="Save Changes"
        isPrimaryButtonDisabled={isSaving}
        showTertiaryButton
        tertiaryButtonText="Cancel"
        buttons={
          dropDownItems(activeTab).length ? (
            <PrimaryButton
              title="Actions"
              className="dropdown-btn"
              large
              dropdownItems={dropDownItems(activeTab)}
            />
          ) : null
        }
      />
      <WidgetContainer
        className="p-4 border-es-light-grey shadow-lg border rounded-md"
        loading={isSaving || isLoadingBilling} // Reference: {isSaving} is used to run the onSave() for non billing windows
        isEditing={isEditing}
        resetResourceState={() => setIsCancel(true)} // Reference: {isCancel} is used to run the reset() for all screens
        handleEditClick={handleEditClick}
        onFinishEditing={widgetContainerSave}
        disableSaveBtn={!canSaveBilling}
        tabs={tabs}
        activeTab={activeTab}
        // Currently only Billing supports container controlled editing
        disableEditing={
          activeTab !== ACCOUNTANDADMIN_TABS.BILLING_ID ||
          (activeTab === ACCOUNTANDADMIN_TABS.BILLING_ID &&
            subscriptionDeactivated)
        }
        onTabClick={(t) => setActiveTab(t)}
        hideBottomCancelSaveButtons
        customStyle={{ minWidth: "1200px" }}
      />
    </>
  );
};

AccountAndAdminContent.propTypes = {
  dropDownItems: PropTypes.func,
  isSaving: PropTypes.bool,
  setIsSaving: PropTypes.func,
  isCancel: PropTypes.bool,
  setIsCancel: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  setIsEditing: PropTypes.func,
  isEditing: PropTypes.bool,
  isExportingMemberList: PropTypes.bool,
  setIsExportingMemberList: PropTypes.func,
};

AccountAndAdminContent.defaultProps = {
  dropDownItems: () => [],
  isSaving: false,
  setIsSaving: undefined,
  isCancel: false,
  setIsCancel: undefined,
  onSave: undefined,
  onCancel: undefined,
  isExportingMemberList: false,
  setIsExportingMemberList: undefined,
  isEditing: undefined,
  setIsEditing: () => {},
};
export default AccountAndAdminContent;

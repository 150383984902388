import { useCallback, useEffect, useRef, useState } from "react";
import useOutsideAlerter from "../../../../hooks/useOutsideAlerter";

const useTableCellDropdownData = ({ value, options, onChange, isGrouped }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [searchState, setSearchState] = useState("");
  const [originalOptions, setOriginalOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, () => setShowPopup(false));

  const getDropdownValue = useCallback(
    (val) => {
      return options.find((item) => item.value === val?.value);
    },
    [options]
  );

  const getGroupedDropdownValue = useCallback(
    (val) => {
      const allOptions = options.reduce((acc, item) => {
        // eslint-disable-next-line no-param-reassign
        acc = [...acc, ...item.options];
        return acc;
      }, []);

      return allOptions.find((item) => {
        return item.value === val?.value;
      });
    },
    [options]
  );

  useEffect(() => {
    if (isGrouped) {
      setSearchState(getGroupedDropdownValue(value)?.label);
    } else {
      setSearchState(getDropdownValue(value)?.label);
    }
  }, [getDropdownValue, getGroupedDropdownValue, isGrouped, value]);

  useEffect(() => {
    setOriginalOptions(options);
    setFilteredOptions(options);
  }, [options]);

  // updated list as user types in the search field
  const handleSearch = useCallback((arr, val) => {
    setFilteredOptions(arr);
    setSearchState(val);
  }, []);

  // function to open/close the list
  const toggleList = (e) => {
    e?.stopPropagation();
    setShowPopup((prev) => !prev);
  };

  const handleFilter = useCallback(
    (val) => {
      if (!val) {
        handleSearch(originalOptions, "");
      } else if (!isGrouped) {
        const filteredList = [];
        const exists = [];

        originalOptions.forEach((item) => {
          if (
            item?.label?.toLowerCase()?.includes(val?.toLowerCase()) &&
            !exists.includes(item.value)
          ) {
            filteredList.push(item);
            exists.push(item.value);
          }
        });

        handleSearch(filteredList, val);
      } else {
        const filteredList = [];
        const exists = [];
        const groupExists = [];

        originalOptions.forEach((item) => {
          item.options.forEach((subItem) => {
            if (
              subItem?.label?.toLowerCase()?.includes(val?.toLowerCase()) &&
              !exists.includes(subItem.value)
            ) {
              if (!groupExists.includes(item.label)) {
                const newGroup = {
                  label: item.label,
                  options: [
                    {
                      label: subItem.label,
                      value: subItem.value,
                    },
                  ],
                };
                filteredList.push(newGroup);
                exists.push(item.value);
                groupExists.push(item.label);
              } else {
                const foundIndex = filteredList.findIndex(
                  (list) => list.label === item.label
                );

                const found = filteredList[foundIndex];
                found.options = [
                  ...found.options,
                  {
                    label: subItem.label,
                    value: subItem.value,
                  },
                ];
                filteredList[foundIndex] = found;
              }
            }
          });
        });

        handleSearch(filteredList, val);
      }
    },
    [handleSearch, isGrouped, originalOptions]
  );

  const handleInput = useCallback(
    (e) => {
      if (!showPopup) {
        setShowPopup(true);
      }

      handleFilter(e.target.value);
    },
    [handleFilter, showPopup, setShowPopup]
  );

  const handleClick = (newVal) => {
    onChange(newVal);
    setSearchState(getDropdownValue(newVal)?.label);
    setShowPopup(false);
  };

  const onClear = () => {
    onChange(undefined);
    setSearchState("");
    setShowPopup(false);
    handleFilter(undefined);
  };

  const handleClickGrouped = (newVal) => {
    onChange(newVal);
    setSearchState(getGroupedDropdownValue(newVal)?.label);
    setShowPopup(false);
  };

  return {
    wrapperRef,
    showPopup,
    searchState,
    filteredOptions,
    handleClick,
    handleClickGrouped,
    handleInput,
    toggleList,
    onClear,
  };
};

export default useTableCellDropdownData;

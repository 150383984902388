import { useQueryClient } from "react-query";
import { notificationCodeKeys } from "../config/reactQuery/queryKeyFactory";
import getNotifications from "../helpers/notifications";
import useAuthenticatedQuery from "./useAuthenticatedQuery";

const useNotifications = () => {
  const queryClient = useQueryClient();

  const queryKey = notificationCodeKeys;
  const previousNotifications = queryClient.getQueryData(queryKey) ?? [];
  const lastUpdated = previousNotifications[0]?.lastUpdated;

  return useAuthenticatedQuery({
    queryKey,
    queryFn: () => getNotifications(previousNotifications, lastUpdated),
    refetchInterval: 30 * 1000, // 30 seconds
    onError: (error) => {
      console.error("useNotifications", error);
    },
  });
};

export default useNotifications;

import { AssetAPI } from "@griffingroupglobal/eslib-api";

/**
 * Fetch all assets
 * @param signal an abort controller signal
 * @returns - @returns {Promise<Object>} An object containing assets, dictionary and dropdown options.
 */
const fetchAssetsOverview = async (signal) => {
  const { data } = await AssetAPI.get({ signal });

  const assetsDict = {};

  const assetsDD = data?.entries?.map((entry) => {
    const asset = entry?.resource;
    assetsDict[asset?.reference] = asset;

    return {
      label: asset?.name,
      value: asset?.reference,
      id: asset?.id,
    };
  });

  return {
    assetsDict,
    assetsDD,
  };
};

export default fetchAssetsOverview;

// Framework Tools
import { useReducer } from "react";

// Libraries
import moment from "moment";

const defaultState = {
  isTemplate: false,
  isDefaultTemplate: false,
  customName: "",
  association: "",
  type: undefined,
  description: "",
  startDate: {
    actual: moment().utc().format(),
  },
  duration: {
    numDays: 0,
    projected: "",
  },
  vendor: [],
  associatedArtifacts: [],
  ballInCourt: [],
  distribution: [],
  private: false,
  requestWorkflow: [{ steps: [] }],
  links: [],
  attachments: [],
  artifactFor: {},
  impacts: {},
  schedule: {
    description: "",
  },
  budget: {
    description: "",
  },
};

const reducer = (request, action) => {
  switch (action.type) {
    case "reset":
      return defaultState;
    case "restoreWorkflow":
      return action.payload;
    case "association":
      return { ...request, association: action.payload, distribution: [] };
    case "initiated":
      return {
        ...request,
        initiated: { ...request.initiated, user: action.payload },
      };
    case "type": {
      return { ...request, type: action.payload };
    }
    case "ballInCourt": {
      return { ...request, ballInCourt: action.payload };
    }
    case "customName":
      return { ...request, customName: action.payload };
    case "resourceName":
      return { ...request, resourceName: action.payload };
    case "description":
      return { ...request, description: action.payload };
    case "addDistroMember":
      return {
        ...request,
        distribution: [...request.distribution, action.payload],
      };
    case "startDate":
      return {
        ...request,
        startDate: {
          actual: action.payload.data,
        },
        requestWorkflow: action.payload.workflowData ?? [{ steps: [] }],
      };
    case "editmodeStartDate":
      return {
        ...request,
        startDate: { actual: action.payload },
      };
    case "editmodeCompletionTargetDate":
      return {
        ...request,
        completion: { ...request?.completion, target: action.payload },
      };
    case "editmodeCompletionProjectedDate":
      return {
        ...request,
        completion: { ...request?.completion, projected: action.payload },
      };
    case "private":
      return {
        ...request,
        private: action.payload,
      };
    case "addLinkCompleteLink":
      return {
        ...request,
        links: [...(request.links ?? []), action.link],
      };
    case "addLink":
      return {
        ...request,
        links: [...request?.links, action.payload],
      };
    case "deleteLink":
      return {
        ...request,
        links:
          request?.links?.filter((link) => link.id !== action.payload) ?? [],
      };
    case "changeLink":
      return {
        ...request,
        links: request?.links?.map((link) => {
          if (link.id === action.link?.id) {
            return action.link;
          }
          return link;
        }),
      };
    case "requestWorkflow":
      return {
        ...request,
        requestWorkflow: action.payload,
      };
    case "distribution":
      return {
        ...request,
        distribution: action.payload,
      };
    case "associatedArtifacts":
      return {
        ...request,
        associatedArtifacts: action.payload,
      };
    case "editModeDistribution":
      return {
        ...request,
        distribution: [...request.distribution, action.payload],
      };
    case "editModeArtifacts":
      return {
        ...request,
        associatedArtifacts: [...request?.associatedArtifacts, action.payload],
      };
    case "edit": {
      return action.payload;
    }
    case "attachments":
      return { ...request, attachments: action.payload };
    case "documents":
      return { ...request, documents: action.documents };
    case "scheduleRef":
      return {
        ...request,
        impacts: {
          ...request.impacts,
          schedule: { status: action.payload },
        },
      };
    case "budgetRef":
      return {
        ...request,
        impacts: {
          ...request.impacts,
          budget: { status: action.payload },
        },
      };
    case "editModeWorkflow":
      return {
        ...request,
        requestWorkflow: action.payload,
      };
    case "removeDistroMember": {
      const filtered = request.distribution.filter(
        (item) => item !== action.payload
      );
      return {
        ...request,
        distribution: filtered,
      };
    }
    case "resetDistroList": {
      return {
        ...request,
        distribution: action.payload ?? [],
      };
    }
    case "budgetDescription":
      return { ...request, budget: { description: action.payload } };
    case "scheduleDescription":
      return { ...request, schedule: { description: action.payload } };
    default:
      return defaultState;
  }
};

export default (initialState) => {
  return useReducer(reducer, initialState ?? defaultState);
};

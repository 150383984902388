import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

import useManagementConfiguration from "../../../hooks/useManagementConfiguration";
import ProjectDetails from "./ProjectDetails";

import {
  PROJECT_UPDATE_RATE,
  PROJECT_HOURS_OF_OPERATION,
} from "../../../constants";
import useFinancialsConfiguration from "../../../hooks/useFinancialsConfiguration";
import { SpinnerInline } from "../Spinner/Spinner";
import { useProjectsPost } from "../../../hooks/projects";
import useSystemConfiguration from "../../../hooks/useSystemConfiguration";
import useCurrentUser from "../../../hooks/useCurrentUser";

/**
 * Renders the ProjectForm component, which is used exclusively within the Project Creation Modal
 */
const ProjectForm = ({
  project,
  dispatch,
  property,
  tmpAvatarImg,
  setTmpAvatarImg,
  nameExists,
  checkNameExists,
}) => {
  const { data: currentUser } = useCurrentUser();

  const { data: systemConfiguration } = useSystemConfiguration();
  const { data: managementConfiguration } = useManagementConfiguration();
  const { data: financialsConfiguration } = useFinancialsConfiguration();

  const { isLoading } = useProjectsPost();

  const [sameAddress, setSameAddress] = useState(false);

  const inputRefs = useRef({});

  useEffect(() => {
    if (financialsConfiguration?.financials) {
      dispatch({
        type: PROJECT_UPDATE_RATE,
        value: financialsConfiguration.financials?.rateSheet,
      });
    }
  }, [dispatch, financialsConfiguration]);

  useEffect(() => {
    if (systemConfiguration?.system) {
      dispatch({
        type: PROJECT_HOURS_OF_OPERATION,
        value: systemConfiguration?.system?.hoursOfOperation,
      });
    }
  }, [dispatch, systemConfiguration?.system]);

  /**
   * Automatically moves cursor to next input field on pressing Enter
   */
  const handleEnter = (event) => {
    // checks if the Enter/Tab key was pressed
    if (event.keyCode === 13 || event.keyCode === 9) {
      const inputCategories = Object.keys(inputRefs?.current);
      const currentInputCategoryIdx = inputCategories.indexOf(
        event.target.id || event.target.name
      );

      const currentInputCategory = inputCategories[currentInputCategoryIdx];
      if (currentInputCategory?.includes("address1")) {
        document.getElementById("address-option-0")?.click();
      }
      if (currentInputCategory === "zipCode") {
        inputRefs?.current.focus();
      }
      if (currentInputCategory === "checked" && sameAddress) {
        const next = inputRefs?.current?.cancelButton;
        next.focus();
      } else if (currentInputCategory === "checked" && !sameAddress) {
        const next = inputRefs?.current?.address2;
        next.focus();
      } else if (currentInputCategory === "cancelButton") {
        const next = inputRefs?.current?.createButton;
        next.focus();
      } else {
        const nextInputCategory =
          inputRefs?.current[inputCategories[currentInputCategoryIdx + 1]];
        event.preventDefault();
        nextInputCategory.focus();
      }
    }
  };

  const handleForwardRef = (key, val) => {
    if (inputRefs?.current) inputRefs.current[key] = val;
  };

  return (
    <>
      {isLoading && (
        <div className="fixed flex items-center justify-center w-full z-10 h-full confirm-dialog">
          <div className="absolute mt-12">Creating Project...</div>
          <div className="absolute w-full z-10 h-full bottom-14">
            <SpinnerInline />
          </div>
        </div>
      )}
      <ProjectDetails
        project={project}
        dispatch={dispatch}
        currentUser={currentUser}
        configs={managementConfiguration}
        handleEnter={handleEnter}
        handleForwardRef={handleForwardRef}
        inputRefs={inputRefs}
        setSameAddress={setSameAddress}
        property={property}
        isExistingProjectName={nameExists}
        tmpAvatarImg={tmpAvatarImg}
        setTmpAvatarImg={setTmpAvatarImg}
        checkNameExists={checkNameExists}
      />
    </>
  );
};

ProjectForm.propTypes = {
  project: PropTypes.shape({
    name: PropTypes.string,
  }),
  dispatch: PropTypes.func,
  property: PropTypes.shape({
    reference: PropTypes.string,
    timezone: PropTypes.string,
  }),
  tmpAvatarImg: PropTypes.shape({
    image: PropTypes.string,
    isLoading: PropTypes.bool,
  }),
  setTmpAvatarImg: PropTypes.func,
  nameExists: PropTypes.bool,
  checkNameExists: PropTypes.func,
};

ProjectForm.defaultProps = {
  project: undefined,
  dispatch: undefined,
  property: undefined,
  tmpAvatarImg: { isLoading: false },
  setTmpAvatarImg: undefined,
  nameExists: false,
  checkNameExists: undefined,
};

export default ProjectForm;

import moment from "moment";
import { convertArrayToObjectSameKeyValue } from "../Utilities";

const getSiteHeaderFilterDisplay = (id, filterValue) => {
  const valueLabels = convertArrayToObjectSameKeyValue(
    filterValue?.customLabels || filterValue?.values
  );

  if (valueLabels && Object.values(valueLabels)?.length) {
    return `${id}: ${Object.values(valueLabels).join(",")}`;
  }
  if (filterValue?.isTrue !== undefined) {
    return `${id}: ${filterValue?.isTrue ? "Yes" : "No"}`;
  }

  const isSameDay =
    moment(filterValue?.from).format("DD MMMM, YYYY") ===
    moment(filterValue?.to).format("DD MMMM, YYYY");

  if (isSameDay) {
    return `${id}: ${moment(filterValue?.from).format("DD MMMM, YYYY")}`;
  }
  return `${id}: ${moment(filterValue?.from).format(
    "DD MMMM, YYYY"
  )} - ${moment(filterValue?.to).format("DD MMMM, YYYY")}`;
};

export default getSiteHeaderFilterDisplay;

import { useParams } from "react-router";

const useSpaceSinglePageData = () => {
  const { propertyId, projectId, spaceId } = useParams();

  const reference = `Space/${spaceId}`;

  return { reference, spaceId, propertyId, projectId };
};

export default useSpaceSinglePageData;

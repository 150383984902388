import { useReducer } from "react";

const defaultState = (initial = []) => ({
  address: [
    {
      attentionTo: "",
      use: "Physical",
      address1: "",
      street2: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
    },
    {
      attentionTo: "",
      use: "Mailing",
      address1: "",
      street2: "",
      city: "",
      state: "",
      country: "",
      zipCode: "",
    },
  ],
  invite: false,
  name: { firstName: "", lastName: "" },
  contactPoint: [],
  company: {
    valueType: "string",
    label: "",
    value: "",
  },
  isNewCompany: undefined,
  kind: "company",
  new: true,
  companyName: "",
  mailingSameAsPhysical: false,
  ...initial,
});

const reducer = (contact, action) => {
  switch (action.type) {
    case "reset":
      return action.initial ? defaultState(action.initial) : contact;
    case "setOriginalResource": {
      return { ...contact, originalResource: action.resource };
    }
    case "setCurrentTags": {
      return { ...contact, currentTags: action.currentTags };
    }
    case "addLinkCompleteLink":
      return {
        ...contact,
        links: [...(contact.links ?? []), action.link],
      };
    case "addLink":
      return {
        ...contact,
        links: [
          ...(contact.links ?? []),
          { id: action.id, name: action.name, url: action.url },
        ],
      };
    case "deleteLink":
      return {
        ...contact,
        links: contact.links?.filter((link) => link.id !== action.linkId) ?? [],
      };
    case "setLinks":
      return {
        ...contact,
        links: action?.links,
      };
    case "firstName":
      return { ...contact, name: { ...contact.name, firstName: action.value } };
    case "lastName":
      return { ...contact, name: { ...contact.name, lastName: action.value } };
    case "title":
      return { ...contact, title: action.value };
    case "company":
      return {
        ...contact,
        company: {
          valueType: action?.company ? "string" : "reference",
          label: action.value,
          value: action.value,
        },
        isNewCompany: action?.isNewCompany,
        companyName: action.value,
      };
    case "companyName":
      return {
        ...contact,
        companyName: action.value,
      };
    case "phone":
      return {
        ...contact,
        contactPoint: action.value
          ? [
              ...contact?.contactPoint?.filter(
                (item) => item?.system !== "Phone"
              ),
              {
                system: "Phone",
                use: "Mobile",
                display: action.display,
                value: action.value,
                rank: 1,
              },
            ]
          : [
              ...contact?.contactPoint?.filter(
                (item) => item?.system !== "Phone"
              ),
            ],
      };
    case "email":
      return {
        ...contact,
        contactPoint: action.value
          ? [
              ...contact?.contactPoint?.filter(
                (item) => item?.system !== "Email"
              ),
              {
                system: "Email",
                use: "Home",
                value: action.value,
                rank: 1,
              },
            ]
          : [
              ...contact?.contactPoint?.filter(
                (item) => item?.system !== "Email"
              ),
            ],
      };
    case "mailingSameAsPhysical":
      return {
        ...contact,
        mailingSameAsPhysical: action.mailingSameAsPhysical,
      };
    case "address":
      return {
        ...contact,
        address: action.address,
      };
    case "clearAddress":
      return { ...contact, address: [] };
    case "website":
      return {
        ...contact,
        contactPoint: action.value
          ? [
              ...contact?.contactPoint?.filter(
                (item) => item?.system !== "Internet"
              ),
              {
                system: "Internet",
                use: "Home",
                value: action.value,
                rank: 1,
              },
            ]
          : [
              ...contact?.contactPoint?.filter(
                (item) => item?.system !== "Internet"
              ),
            ],
      };
    case "note":
      return { ...contact, notes: action.value };
    case "invite":
      return { ...contact, invite: action.value };
    case "success":
      return { ...contact, id: action.id, reference: action.reference };
    default:
      return contact;
  }
};

export default (initialState) => {
  return useReducer(
    reducer,
    initialState
      ? {
          ...defaultState({}),
          ...initialState,
        }
      : defaultState({})
  );
};

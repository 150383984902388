import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import BaseButton from "../Buttons/BaseButton";
import crossIcon from "../../assets/images/Close.svg";
import useOutsideAlerter from "../../../hooks/useOutsideAlerter";
import TableCellInput from "./TableCellInput";

const TableDescriptionCell = ({
  row,
  isEditingTable,
  setEditedRow,
  editedRow,
}) => {
  const [showPopup, setShowPopup] = useState(false);
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, () => setShowPopup(false));

  if (!isEditingTable) {
    return (
      <div className="flex flex-row items-center" ref={wrapperRef}>
        <div
          role="button"
          onKeyDown={() => {}}
          tabIndex="0"
          onClick={(e) => {
            e.stopPropagation();
            setShowPopup((prev) => !prev);
          }}
        >
          {row?.original?.description}
        </div>
        {showPopup && (
          <div className="flex flex-col rounded border border-gray-150 z-20 bg-white absolute p-2 w-72 top-8 max-h-24 overflow-hidden overflow-y-scroll">
            <div className="flex justify-between">
              <div className="flex pb-2">
                <p className="font-semibold text-sm">Description</p>
              </div>
              <BaseButton
                onClick={() => {
                  setShowPopup(false);
                }}
                iconLeft={
                  <img
                    src={crossIcon}
                    alt="crossIcon"
                    style={{ height: 8, width: 10 }}
                  />
                }
              />
            </div>
            <p className="break-words whitespace-normal text-sm pr-2">
              {row?.original?.description}
            </p>
          </div>
        )}
      </div>
    );
  }

  const onChange = (newVal) => {
    setEditedRow({
      ...editedRow,
      description: newVal,
    });
  };

  return (
    <div className="flex w-full">
      <TableCellInput value={editedRow?.description} onChange={onChange} />
    </div>
  );
};

TableDescriptionCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      description: PropTypes.string,
    }),
  }),
  setEditedRow: PropTypes.func,
  editedRow: PropTypes.shape({
    description: PropTypes.string,
  }),
  isEditingTable: PropTypes.bool,
};

TableDescriptionCell.defaultProps = {
  row: undefined,
  setEditedRow: () => {},
  editedRow: {},
  isEditingTable: false,
};

export default TableDescriptionCell;

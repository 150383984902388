import cntl from "cntl";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { getInitials } from "../../../helpers/Chat";
import { getFullName } from "../../../helpers/Formatters";
import { useGetFile } from "../../../hooks/useGetFile";
import companyGrayIcon from "../../assets/images/companyGrayIcon.svg";
import editIcon from "../../assets/images/editIcon.svg";
import userAvatarIcon from "../../assets/images/userAvatarIcon.svg";
import projectDefault from "../../assets/images/projectIcon.svg";

const avatarCN = (className) => cntl`
  bg-gray-150
  ${!!className && className?.includes("w-") ? "" : "w-7 h-7"}
  flex
  items-center
  justify-center
  mr-1
  flex-shrink-0
  shadow-medium-lift
  ${className}
  ${
    !!className && className?.includes("rounded")
      ? ""
      : cntl`
    rounded-full
    `
  }
  `;
const fontCN = (fontClassName) => cntl`
  font-medium
  text-xs
  text-gray-400
  uppercase
  overflow-hidden
  leading-none
  ${fontClassName}
`;
const lengthCN = cntl`
  absolute
  flex
  justify-center
  items-center
  font-bold
  bg-gray-300
  rounded-sm
  text-white
  text-center
`;

const editCN = cntl`
  absolute
  top-0
  -left-0
  z-10
  flex
  justify-center
  items-center
  w-full
  h-full
`;

const Avatar = React.memo(
  ({
    name,
    length,
    isOnline,
    channelName,
    className,
    fontClassName,
    id,
    isUser,
    isExternalUser,
    avatar,
    addPhoto,
    kind,
    disableTooltip,
    tooltipClassName,
    showRightTooltip,
    showBottomTooltip,
    isProject,
  }) => {
    const pointerSide = () => {
      let pointer = "pointer";
      if (showRightTooltip) {
        pointer = "pointerRight";
        return pointer;
      }
      if (showBottomTooltip) {
        pointer = "pointerBottom";
        return pointer;
      }
      return pointer;
    };
    const { data } = useGetFile(avatar);

    //  Tool Tip
    const [showTooltip, setShowTooltip] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const handleMouseEnter = () => {
      if (!addPhoto) setShowTooltip(true);
      else setShowEdit(true);
    };
    const handleMouseLeave = () => {
      if (!addPhoto) setShowTooltip(false);
      else setShowEdit(false);
    };

    return (
      <div
        className={avatarCN(className)}
        onMouseOver={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onFocus={handleMouseEnter}
        onBlur={handleMouseLeave}
        id={id}
      >
        {showTooltip && name && isExternalUser && !disableTooltip && (
          <span
            className={`${pointerSide()} ${tooltipClassName} absolute top-0 whitespace-nowrap text-es-sm p-1 rounded shadow-lg bg-gray-700 text-white capitalize`}
            style={{
              opacity: "1",
              transform: showBottomTooltip
                ? "translateY(130%)"
                : "translateY(-125%)",
              zIndex: 50,
            }}
          >
            {typeof name === "string" ? name : getFullName(name)}
          </span>
        )}
        {isOnline && !addPhoto && (
          <div
            className="absolute top-0 left-0 bg-brandGreen rounded-full text-es-sm"
            style={{
              width: "25%",
              height: "25%",
              border: "0.1225em solid white",
            }}
          />
        )}
        {length > 1 && (
          <p
            className={lengthCN}
            style={{
              width: "10px",
              height: "10px",
              fontSize: "8px",
              bottom: "-2px",
              right: "-2px",
            }}
          >
            {length}
          </p>
        )}
        {!isUser && !isExternalUser && (
          <p className={fontCN(fontClassName)}>
            {getInitials(name, channelName)}
          </p>
        )}

        {(isUser || isExternalUser) && (
          <div className="flex justify-center items-center w-full h-full">
            {data?.contentsUrl && (
              <img
                src={data?.contentsUrl}
                style={{ objectFit: "cover" }}
                className="w-full h-full rounded-full"
                alt="Avatar Icon"
                loading="eager"
              />
            )}

            {!data?.thumbnailUrl &&
              name &&
              kind !== "company" &&
              kind !== "role" &&
              !isProject && (
                <p className={fontCN(fontClassName)}>
                  {getInitials(name, channelName)}
                </p>
              )}
            {!data?.thumbnailUrl && isProject && (
              <img
                className="w-full h-full"
                src={projectDefault}
                alt="company avatar"
              />
            )}
            {kind && kind === "company" && !data?.thumbnailUrl && (
              <img
                className="w-1/2 h-1/2"
                src={companyGrayIcon}
                alt="company avatar"
              />
            )}
            {kind && kind === "role" && !data?.thumbnailUrl && (
              <img
                className="w-1/2 h-1/2"
                src={userAvatarIcon}
                alt="user avatar"
              />
            )}
          </div>
        )}
        {addPhoto && showEdit && (
          <div className={editCN}>
            <button
              className="flex justify-center items-center w-full h-full rounded-full bg-black bg-opacity-10"
              onClick={addPhoto}
              type="button"
            >
              <img src={editIcon} className="h-12" alt="editIcon" />
            </button>
          </div>
        )}
      </div>
    );
  }
);

Avatar.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  length: PropTypes.number,
  isOnline: PropTypes.bool,
  channelName: PropTypes.string,
  className: PropTypes.string,
  fontClassName: PropTypes.string,
  id: PropTypes.string,
  /**
   * isUser opt to render user avatar
   * Conditionally changes tool tip
   * to display "Me" onHover
   */
  isUser: PropTypes.bool,
  /**
   * isExternalUser opt to render user avatar
   * Conditionally changes tool tip
   * to display "firstName lastName" onHover
   */
  isExternalUser: PropTypes.bool,
  /**
   * if isStream
   * the behavior of getUserAvatar
   * is adjusted to retrieve avatar
   * with streamId
   */
  // eslint-disable-next-line react/forbid-prop-types
  avatar: PropTypes.string,
  addPhoto: PropTypes.func,
  kind: PropTypes.string,
  disableTooltip: PropTypes.bool,
  /**
   * Ignore Avatar Fetch
   */
  tooltipClassName: PropTypes.string,
  showRightTooltip: PropTypes.bool,
  isProject: PropTypes.bool,
  showBottomTooltip: PropTypes.bool,
};

Avatar.defaultProps = {
  name: undefined,
  length: 1,
  isOnline: false,
  channelName: undefined,
  className: "",
  fontClassName: undefined,
  id: undefined,
  isUser: false,
  isExternalUser: false,
  avatar: undefined,
  addPhoto: undefined,
  kind: undefined,
  disableTooltip: false,
  tooltipClassName: "",
  showRightTooltip: false,
  isProject: undefined,
  showBottomTooltip: undefined,
};

export default Avatar;

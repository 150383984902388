import React from "react";
import PropTypes from "prop-types";
import TableCellInput from "../../../Table/TableCellInput";
import CurrencyView from "../../../CurrencyView/CurrrencyView";
import ToggleTextButton from "../../../Buttons/ToggleTextButton";
import useAdjustmentCellData from "./useAdjustmentCellData";

const AdjustmentCell = ({
  row,
  isEditingTable,
  setEditedRow,
  editedRow,
  isHighRangeField,
}) => {
  const {
    currencyValue,
    currencyPercentageValue,
    isArithmeticUnitAddActive,
    isArithmeticUnitSubtractActive,
    adjustmentAmountValue,
    arithmeticToggleUnits,
    isAdjustmentUnitDollarActive,
    isAdjustmentUnitPercentActive,
    currencyToggleUnits,
    onAdjustmentChange,
    onArithmeticUnitChange,
    onAdjustmentUnitChange,
  } = useAdjustmentCellData({ row, editedRow, setEditedRow, isHighRangeField });

  if (!isEditingTable) {
    return (
      <CurrencyView
        value={currencyValue}
        isPercentage={currencyPercentageValue}
      />
    );
  }

  const hide = isHighRangeField && !editedRow?.isCostRange;

  return (
    <div className={`flex items-center ${hide && "invisible"}`}>
      <div className="mr-4">
        <ToggleTextButton
          setIsLeftActive={(isTrue) => {
            if (isTrue) {
              onArithmeticUnitChange(arithmeticToggleUnits?.add);
            }
          }}
          setIsRightActive={(isTrue) => {
            if (isTrue) {
              onArithmeticUnitChange(arithmeticToggleUnits?.subtract);
            }
          }}
          isLeftActive={isArithmeticUnitAddActive}
          isRightActive={isArithmeticUnitSubtractActive}
          leftText=" + "
          rightText=" - "
          borderLeftPill="rounded-l-md border-es-light-grey border"
          borderRightPill="rounded-r-md border-es-light-grey border"
          textClassName="text-sm"
          padding="py-0.5 px-2"
        />
      </div>
      <TableCellInput
        value={adjustmentAmountValue}
        onChange={onAdjustmentChange}
      />
      <div className="mr-4">
        <ToggleTextButton
          setIsLeftActive={(isTrue) => {
            if (isTrue) {
              onAdjustmentUnitChange(currencyToggleUnits?.dollar);
            }
          }}
          setIsRightActive={(isTrue) => {
            if (isTrue) {
              onAdjustmentUnitChange(currencyToggleUnits?.percent);
            }
          }}
          isLeftActive={isAdjustmentUnitDollarActive}
          isRightActive={isAdjustmentUnitPercentActive}
          leftText={` ${currencyToggleUnits.dollar}`}
          rightText={` ${currencyToggleUnits.percent}`}
          textClassName="text-sm"
          borderLeftPill="rounded-l-md border-es-light-grey border"
          borderRightPill="rounded-r-md border-es-light-grey border"
          padding="py-0.5 px-2"
        />
      </div>
    </div>
  );
};

AdjustmentCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      description: PropTypes.string,
    }),
  }),
  setEditedRow: PropTypes.func,
  editedRow: PropTypes.shape({
    description: PropTypes.string,
    isCostRange: PropTypes.bool,
  }),
  isEditingTable: PropTypes.bool,
  isHighRangeField: PropTypes.bool,
};

AdjustmentCell.defaultProps = {
  row: undefined,
  setEditedRow: () => {},
  editedRow: {},
  isEditingTable: false,
  isHighRangeField: false,
};

export default AdjustmentCell;

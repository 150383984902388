import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import useReviewProfileForm from "./useReviewProfileForm";
import PrimaryButton from "../../Buttons/PrimaryButton";
import ProfileDetailsForm from "../ProfileDetailsForm";
import UserAgreement from "../UserAgreement";
import ProfileDetailsHeader from "../ProfileDetailsHeader";

const pageContainer = (activeIndex, isLoaded, pageIndex) => cntl`
  transition-opacity 
  ease-in 
  duration-300
  ${activeIndex !== pageIndex && "hidden"}
  ${isLoaded === pageIndex ? "opacity-100" : "opacity-0"}
`;

const primaryButtonStyle = {
  width: "82px",
  height: "44px",
  backgroundColor: "white",
  borderWidth: "2px",
  borderColor: "#1CC39D",
  color: "black",
};
const buttonRow = "flex flex-row gap-4 justify-end";
const buttonCN = "flex tracking-es-wide text-es-normal";
const buttonStyle = { width: "82px", height: "44px" };

const ReviewProfileForm = ({
  editableUser,
  setEditableUser,
  setIsTNCCheck,
  isTNCChecked,
  onFinish,
  isLoading,
  isFormValid,
  tmpAvatarImg,
  setTmpAvatarImg,
}) => {
  const { activeIndex, isLoaded, onTabClick, canFinish } = useReviewProfileForm(
    { isTNCChecked, isLoading }
  );
  return (
    <div style={{ width: "1268px" }} className={isLoading && " loading"}>
      {/* Header Component */}
      <ProfileDetailsHeader activeIndex={activeIndex} />
      <div className="flex flex-col p-8 border rounded-lg w-fit shadow-lg">
        {/* Profile Settings Flow */}
        <div className={pageContainer(activeIndex, isLoaded, 0)}>
          <ProfileDetailsForm
            editableUser={editableUser}
            setEditableUser={setEditableUser}
            tmpAvatarImg={tmpAvatarImg}
            setTmpAvatarImg={setTmpAvatarImg}
          />
        </div>
        <div className={pageContainer(activeIndex, isLoaded, 1)}>
          <UserAgreement setIsTNCCheck={setIsTNCCheck} />
        </div>

        {/* Buttons guiding profile flow */}
        <div className={buttonRow}>
          {activeIndex !== 0 && (
            <PrimaryButton
              className={buttonCN}
              style={primaryButtonStyle}
              noMaxWidth
              title="Back"
              onClick={() => onTabClick(activeIndex - 1)}
              name="back"
            />
          )}
          {activeIndex !== 1 ? (
            <PrimaryButton
              className={buttonCN}
              style={buttonStyle}
              noMaxWidth
              title="Next"
              onClick={() => onTabClick(activeIndex + 1)}
              name="login"
              disabled={!isFormValid}
            />
          ) : (
            <PrimaryButton
              className={buttonCN}
              style={buttonStyle}
              noMaxWidth
              title="Finish"
              onClick={() => onFinish()}
              name="Finish"
              disabled={!canFinish}
            />
          )}
        </div>
      </div>
    </div>
  );
};

ReviewProfileForm.propTypes = {
  editableUser: PropTypes.shape({}),
  setEditableUser: PropTypes.func,
  onFinish: PropTypes.func,
  setIsTNCCheck: PropTypes.func,
  isTNCChecked: PropTypes.bool,

  isLoading: PropTypes.bool,
  isFormValid: PropTypes.bool,
  tmpAvatarImg: PropTypes.shape({
    image: PropTypes.string,
    isLoading: PropTypes.bool,
  }),
  setTmpAvatarImg: PropTypes.func,
};

ReviewProfileForm.defaultProps = {
  editableUser: undefined,
  setEditableUser: undefined,
  setIsTNCCheck: () => {},
  isTNCChecked: undefined,
  onFinish: () => {},
  isLoading: undefined,
  isFormValid: undefined,
  tmpAvatarImg: { isLoading: false },
  setTmpAvatarImg: undefined,
};

export default ReviewProfileForm;

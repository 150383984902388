import { useCallback, useRef } from "react";
import { toast } from "react-toastify";

const useProgressBar = () => {
  const toastId = useRef(null);

  /**
   * Create and shows a new toast progress bar
   */
  const showProgress = () => {
    toastId.current = toast("Please wait while we upload your files...", {
      isLoading: true,
      closeButton: false,
      position: "top-center",
    });
  };

  /**
   * Update toast with updated progress values
   */
  const updateProgress = useCallback((loaded, total) => {
    if (toastId.current) {
      const progress = loaded / total;
      toast.update(toastId.current, { progress });
    }
  }, []);

  /**
   * Dissmises toast
   */
  const dissmissProgress = () => {
    if (toastId.current) {
      toast.dismiss(toastId.current);
    }
  };

  return {
    progressBarId: toastId.current,
    showProgress,
    updateProgress,
    dismissProgress: dissmissProgress,
  };
};

export default useProgressBar;

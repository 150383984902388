import { useEffect, useMemo, useState } from "react";
import { usePropertiesOverview } from "../../../hooks/properties";
import { useProjectsOverview } from "../../../hooks/projects";
import { useAssetsOverview } from "../../../hooks/assets";

const useAssociationDropdownData = (
  association,
  onChangeAssociation,
  disabledOptions,
  setInitialValue
) => {
  // App state hook
  const { assetsDict, assetsDD } = useAssetsOverview();
  const { propertiesDict, propertiesDD } = usePropertiesOverview();
  const { projectDict, projectsDD: projectDD } = useProjectsOverview();

  // State to handle association selected from dropdown
  const [value, setValue] = useState();

  // Option to be displayed in the dropdown
  /**
   * disabledOptions allows dropdown to include only the requested assets
   * it must be an array of strings, that match Assets, Projects, Properties i.e. ["Assets"]
   * @param {Array}
   */
  const options = useMemo(() => {
    if (disabledOptions && disabledOptions.length >= 1) {
      return [
        { label: "Assets", options: assetsDD },
        { label: "Projects", options: projectDD },
        { label: "Properties", options: propertiesDD },
      ].filter((item) => !disabledOptions.includes(item.label));
    }
    return [
      { label: "Assets", options: assetsDD },
      { label: "Projects", options: projectDD },
      { label: "Properties", options: propertiesDD },
    ];
  }, [assetsDD, projectDD, propertiesDD, disabledOptions]);

  /**
   * Sets association internally in the component and in the callback function
   * it is set with the value (string)
   * @param {Object} associationSelected
   */
  const handleChangeAssociation = (associationSelected) => {
    if (associationSelected) {
      setValue(associationSelected);
      setInitialValue(associationSelected);
      onChangeAssociation(associationSelected.value);
    }
  };

  /**
   * This effect set the dropdown with the current association.
   * This is done the request service modal is opened from P/P/A details page
   * Also disable the dropdown to avoid changes
   */
  useEffect(() => {
    if (association) {
      setValue(() => {
        const associationObj =
          propertiesDict?.[association] ||
          projectDict?.[association] ||
          assetsDict?.[association];

        return {
          label: associationObj?.name ?? associationObj?.title,
          value: association,
          id: associationObj?.id,
        };
      });
    } else {
      setValue(undefined);
    }
  }, [association, propertiesDict, projectDict, assetsDict]);

  return { options, value, handleChangeAssociation };
};

export default useAssociationDropdownData;

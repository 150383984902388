import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import cntl from "cntl";
import * as yup from "yup";
import AttributeDetailListCard from "./AttributeDetailListCard";
import Input from "../Input/Input";
import DatePicker from "../DatePicker/DatePicker";
import Dropdown from "../Dropdown/Dropdown";
import Checkbox from "../Checkbox/Checkbox";

const splitRowCN = cntl`
  flex
  justify-between
  items-center
  mt-1
  font-medium
  text-gray-700
  text-xs
`;

/**
 * @deprecated
 */

const AttributeAddInformation = ({
  title,
  resource,
  dispatch,
  onFinishEditing,
  disableEditing,
  setNewAttributes,
  newAttributes,
  measurements,
  editing,
}) => {
  const [measurementFields, setMeasurementFields] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [selectedMeasurements, setSelectedMeasurements] = useState([]);

  useEffect(() => {
    setSelectedMeasurements(measurements?.filter((item) => item.selected));
  }, [measurements]);

  useEffect(() => {
    let mf = [];

    if (selectedMeasurements?.length || newAttributes?.length) {
      const allMeasurements = [...selectedMeasurements, ...newAttributes];
      const detailList = resource?.additionalInfo?.map((item) => item.detail);
      detailList?.forEach((detail) => {
        const field = allMeasurements?.find((type) => type.detail === detail);
        if (field) {
          mf.push(field);
        }
      });
      mf = mf.sort(({ detail: a }, { detail: b }) => a.localeCompare(b));
    }

    const tempItems = resource?.additionalInfo
      ?.map((m) => {
        const field = mf?.find((item) => item.detail === m.detail);

        const value =
          field?.fieldType === "date-picker"
            ? moment(m.value).format("MM/DD/YYYY")
            : m.value;
        const unit = m.unit && m.unit !== "" ? `${m.unit}` : "";

        return {
          title: m.detail,
          value: (
            <p
              className={splitRowCN}
              title={`${value}${unit && ` ${unit}`}`}
              aria-label={`${value}${unit && ` ${unit}`}`}
            >{`${value} ${unit}`}</p>
          ),
          field: field?.fieldType,
        };
      })
      .filter((item) => !!item.title)
      .sort(({ title: a }, { title: b }) => a.localeCompare(b));

    setMeasurementFields(mf);
    setFilteredItems(tempItems);
  }, [selectedMeasurements, newAttributes, resource?.additionalInfo]);

  const getMeasurementInput = (detail, fieldType, options, units) => {
    if (detail === "Types") {
      return null;
    }

    const onChange = (value, unit) => {
      dispatch({
        type: "measurement",
        detail,
        value,
        unit,
      });
    };

    const unitOptions = [];
    if (units?.options?.length) {
      units.options.forEach(({ id, name }) =>
        unitOptions.push({ label: name, value: id })
      );
    }

    const { value = "", unit: valueUnit = "" } =
      resource?.additionalInfo?.find((m) => m.detail === detail) ?? {};

    let returnValue;

    switch (fieldType) {
      case "number-entry":
        returnValue = (
          <div key={detail} className="flex flex-1 justify-between">
            <Input
              className="flex-1"
              label={detail}
              placeholder={detail}
              onChange={(val) => onChange(val, valueUnit)}
              value={value}
              validation={yup
                .number()
                .transform((v, o) => (o === "" ? undefined : v))}
              disableClear
            />
            {!!unitOptions.length && (
              <Dropdown
                className="w-1/3 ml-4"
                label="Unit"
                placeholder="Select"
                options={unitOptions}
                onChange={({ value: changeValue }) =>
                  onChange(value, changeValue)
                }
                value={unitOptions?.find((opt) => opt.value === valueUnit)}
                disableClear
              />
            )}
          </div>
        );
        break;
      case "date-picker":
        returnValue = (
          <DatePicker
            key={detail}
            value={value}
            onChange={onChange}
            label={detail}
            validation={yup.date()}
          />
        );
        break;
      case "ddl":
        {
          const optionsDDL = options?.map(({ name }) => ({
            label: name,
            value: name,
          }));
          returnValue = (
            <Dropdown
              key={detail}
              label={detail}
              placeholder="Select"
              options={optionsDDL}
              onChange={({ value: changeValue }) => onChange(changeValue)}
              value={optionsDDL?.find((opt) => opt.value === value)}
            />
          );
        }
        break;
      case "check-box":
        returnValue = (
          <Checkbox
            key={detail}
            className="flex items-center"
            label={detail}
            checked={value === "Yes"}
            onChange={(isChecked) => onChange(isChecked ? "Yes" : "No")}
          />
        );
        break;
      case "text-entry":
      default:
        returnValue = (
          <div key={detail} className="flex flex-1 justify-between">
            <Input
              className="flex-1"
              label={detail}
              placeholder={detail}
              onChange={(val) => onChange(val, valueUnit)}
              value={value}
              validation={yup.string()}
              disableClear
            />
            {!!unitOptions.length && (
              <Dropdown
                className="w-1/3 ml-4"
                label="Unit"
                placeholder="Select"
                options={unitOptions}
                onChange={({ value: changeValue }) =>
                  onChange(value, changeValue)
                }
                value={unitOptions?.find((opt) => opt.value === valueUnit)}
                disableClear
              />
            )}
          </div>
        );
    }

    return (
      <div key={detail} className="flex items-center w-full">
        <div className="flex items-center w-full">{returnValue}</div>
      </div>
    );
  };

  const handleAddElements = (elements, attributes) => {
    elements.forEach(({ detail, id, fieldType, data: { value, unit } }) => {
      let finalValue = value;
      if (!finalValue) {
        finalValue = fieldType === "check-box" ? "No" : "";
      }
      const attribute = {
        type: "measurement",
        detail,
        value: finalValue,
        unit,
        id,
      };
      dispatch(attribute);
    });

    setNewAttributes(attributes);
  };

  const handleDeleteElement = (id) => {
    dispatch({
      type: "measurement",
      operation: "remove",
      detail: id,
    });
  };

  return (
    <>
      <div className="flex">
        <AttributeDetailListCard
          newAttributes={newAttributes}
          setNewAttributes={setNewAttributes}
          resource={resource}
          title={title}
          values={measurementFields
            ?.map(({ detail, fieldType, options, unit: units }) => {
              return {
                input: getMeasurementInput(detail, fieldType, options, units),
                display: filteredItems.find((item) => item.title === detail),
              };
            })
            .filter((el) => !!el)}
          onAdd={handleAddElements}
          onDelete={handleDeleteElement}
          onFinishEditing={onFinishEditing}
          showAddButton
          disableEditing={disableEditing}
          measurements={selectedMeasurements}
          isEditing={editing}
        />
      </div>
    </>
  );
};

AttributeAddInformation.propTypes = {
  title: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  resource: PropTypes.object,
  dispatch: PropTypes.func,
  onFinishEditing: PropTypes.func,
  disableEditing: PropTypes.bool,
  measurements: PropTypes.arrayOf(PropTypes.shape({})),
  setNewAttributes: PropTypes.func,
  newAttributes: PropTypes.arrayOf(PropTypes.shape({})),
  editing: PropTypes.bool,
};

AttributeAddInformation.defaultProps = {
  title: undefined,
  resource: undefined,
  dispatch: undefined,
  onFinishEditing: undefined,
  disableEditing: false,
  measurements: [],
  setNewAttributes: undefined,
  newAttributes: [],
  editing: false,
};

export default AttributeAddInformation;

import { useCallback } from "react";

const useVendorCellData = ({ vendors, editedRow, setEditedRow }) => {
  const getDropdownValue = useCallback(
    (val) => {
      let rv = {};
      rv = vendors.find((v) => v.value === val);

      return rv;
    },
    [vendors]
  );

  const onChange = (newVal) => {
    setEditedRow({
      ...editedRow,
      vendor: newVal?.value,
      vendorName: vendors?.find((vendor) => vendor?.value === newVal?.value)
        ?.label,
    });
  };
  return { onChange, getDropdownValue };
};

export default useVendorCellData;

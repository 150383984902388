// Framework Tools
import cntl from "cntl";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";

// Hooks
import { useAppState } from "../../../state/appState";

// Components
import DescriptionField from "../../../stories/Components/DescriptionField";
import Dropdown from "../../../stories/Components/Dropdown/Dropdown";

import LinksView from "../../../stories/Components/Links/LinksView";
import SopStepsContainer from "../../../stories/Components/SopInfoForm/SopStepsContainer";
import TagsContainer from "../../../stories/Components/Tags/TagsContainer";
import Widget from "../../../stories/Components/Widget/Widget";

import InlineInput from "../../../stories/Components/Input/InlineInput";
import "./styles.css";

const boldCN = (isFirst, className) => cntl`
  font-bold 
  text-gray-450
  text-md
  w-1/2
  self-start
  ${className}
`;

const textCN = cntl`
  text-gray-450 
  text-sm
  font-normal
  flex
`;

const SopDetailsView = ({
  sop,
  sopDispatch,
  isEditing,
  stepData,
  categoryOptionsList,
  loading,
  setStepFocus,
  stepFocus,
}) => {
  const [{ systemConfiguration }] = useAppState();
  const [stepsReordered, setStepsReordered] = useState(false);

  const category = React.useMemo(() => {
    return systemConfiguration?.system?.sop?.category?.find(
      (cat) => cat.id === sop?.category
    );
  }, [sop?.category, systemConfiguration?.system?.sop?.category]);

  useEffect(() => {
    if (stepsReordered) {
      sopDispatch({
        trpe: "updateStep",
        steps: stepData,
      });
      setStepsReordered(false);
    }
  }, [sopDispatch, stepData, stepsReordered]);

  const onFieldChange = useCallback(
    (field, val) => {
      if (field === "category") {
        sopDispatch({
          type: "editCategory",
          payload: val?.value,
        });
      } else if (field === "description") {
        sopDispatch({
          type: "editDescription",
          payload: val,
        });
      }
    },
    [sopDispatch]
  );

  return (
    <div className="flex flex-col w-full">
      <Widget
        overflow
        draggable={false}
        title="SOP Information"
        className="mb-4"
      >
        <div className="relative grid grid-cols-2 gap-6 pt-6">
          <div className="col-span-1">
            <div className="flex w-full border-b border-gray-450 border-opacity-50 pb-4">
              <h3 className={boldCN(true)}>Type</h3>
              {!isEditing && <p className={textCN}>{category?.display}</p>}
              {isEditing && (
                <Dropdown
                  className="w-full flex-auto"
                  options={categoryOptionsList}
                  value={{ label: category?.display, value: category?.id }}
                  onChange={(val) => onFieldChange("category", val)}
                  disableClear
                />
              )}
            </div>
          </div>

          <div className="col-span-1">
            <div className="flex w-full border-b border-gray-450 border-opacity-50 pb-4">
              <h3 className={boldCN(true)}>Version</h3>
              <p className={textCN}>{sop?.version}</p>
            </div>
          </div>

          <div className="col-span-1">
            <div className="flex w-full border-b border-gray-450 border-opacity-50 pb-4">
              <h3 className={boldCN(true)}>Date modified</h3>
              <p className={textCN}>
                {moment(sop?.metadata?.lastUpdated).format("lll")}
              </p>
            </div>
          </div>

          <div className="col-span-1">
            <div className="flex w-full border-b border-gray-450 border-opacity-50 pb-4">
              <h3 className={boldCN(true)}>Date created</h3>
              <p className={textCN}>
                {moment(sop?.metadata?.createdAt).format("lll")}
              </p>
            </div>
          </div>

          <div className="col-span-1">
            <div className="flex w-full border-gray-450 border-opacity-50 pb-4">
              <h3 className={boldCN(true)}>Description</h3>
              {!isEditing ? (
                <DescriptionField
                  className="text-gray-450 text-sm font-normal max-h-24 overflow-y-auto flex w-1/2"
                  text={sop?.description}
                />
              ) : (
                <InlineInput
                  inputStyle={{ padding: "0px" }}
                  editing
                  textarea
                  width="w-full"
                  value={sop?.description}
                  rows={3}
                  placeholder="Sop Description"
                  onConfirm={(val) => onFieldChange("description", val)}
                  onChangeCallback={(val) => onFieldChange("description", val)}
                  className="text-gray-450 text-sm font-normal flex"
                  hidePencil
                />
              )}
            </div>
          </div>

          <div className="col-span-1">
            <h3 className={boldCN(true)}>Tags</h3>
            <TagsContainer
              isEditing={isEditing}
              className="w-full"
              tagsWrapperClassName="mt-6"
              dispatch={sopDispatch}
              resource={sop}
              isSop
            />
          </div>
        </div>
      </Widget>

      <div className="flex gap-4">
        <Widget overflow draggable={false} title="Steps">
          <SopStepsContainer
            stepData={sop?.steps}
            sopDispatch={sopDispatch}
            editing={isEditing}
            setStepFocus={setStepFocus}
            stepFocus={stepFocus}
          />
        </Widget>
        <LinksView
          resource={sop}
          dispatch={sopDispatch}
          editing={isEditing}
          loading={loading}
          widgetWidth="1/2"
        />
      </div>
    </div>
  );
};

SopDetailsView.propTypes = {
  sop: PropTypes.shape(
    PropTypes.shape({
      steps: PropTypes.arrayOf(PropTypes.string),
      links: PropTypes.arrayOf(PropTypes.string),
    })
  ),
  sopDispatch: PropTypes.func,
  isEditing: PropTypes.bool,
  stepData: PropTypes.arrayOf(PropTypes.shape({})),
  categoryOptionsList: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  setStepFocus: PropTypes.func,
  stepFocus: PropTypes.bool,
};

SopDetailsView.defaultProps = {
  sop: {},
  sopDispatch: undefined,
  isEditing: false,
  stepData: [],
  categoryOptionsList: [],
  loading: true,
  setStepFocus: undefined,
  stepFocus: false,
};

export default SopDetailsView;

/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import { useDrag, useDrop } from "react-dnd";
import dragIcon from "../../assets/images/drag_icon_white.svg";
import Chevron from "../Chevron/Chevron";

const containerCN = cntl`
  flex
`;

const headerCellCN = (isSelectionHeader, isPrimary, isLast) => cntl`
  flex
  flex-1
  h-full
  border-es-light-grey
  ${!isLast && "border-r"}
  max-h-full
  max-w-full
  py-2
  items-center
  select-none
  bg-es-medium-grey
  ${isSelectionHeader ? cntl`justify-center` : cntl`justify-between px-1`}
`;

const sortIndicatorCN = (className) => cntl`
  w-3
  h-3
  mx-1
  transform
  ${className}
`;

const headerText = (addLeftMargin) => cntl`
  truncate
  font-es-semibold
  text-es-sm
  text-es-white
  tracking-es-wide
  leading-6
  ${addLeftMargin && cntl`ml-4`}
`;

const getStyles = (props, { id }) => {
  if (id === "selection") {
    return [
      props,
      {
        style: {
          flex: "none",
          width: "60px",
          zIndex: "20",
        },
      },
    ];
  }

  if (id === "_lineNumber") {
    return [
      props,
      {
        style: {
          zIndex: "20",
        },
      },
    ];
  }

  return [props, {}];
};

const headerProps = (props, { column }) => getStyles(props, column);

const TableHeader = ({
  id,
  column,
  index,
  moveColumn,
  isPrimary,
  isLast,
  disableSortBy,
  disableSorting,
  center,
  disableDragging,
}) => {
  const [isShown, setIsShown] = useState(false);

  const ItemTypes = {
    CARD: "card",
  };

  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    drop(item) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const dropIndex = index;
      // Don't replace items with themselves
      if (dragIndex === dropIndex) {
        return;
      }
      moveColumn(dragIndex, dropIndex);
    },
  });
  const [, drag, preview] = useDrag({
    item: { type: ItemTypes.CARD, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  if (!isPrimary) {
    drop(preview(ref));
  }

  const getSortIndicator = () => {
    if (column.id?.includes("selection")) {
      return null;
    }
    return (
      (column.isSorted &&
        (column.isSortedDesc ? (
          <Chevron className={sortIndicatorCN("rotate-90")} />
        ) : (
          <Chevron className={sortIndicatorCN("-rotate-90")} />
        ))) || (
        <div style={{ opacity: isShown ? "100" : "0" }}>
          <Chevron className={sortIndicatorCN("-rotate-90")} />
          <Chevron className={sortIndicatorCN("rotate-90")} />
        </div>
      )
    );
  };

  const isSelectionHeader = column.id === "selection";

  const onMouseEnter = () => {
    setIsShown(true);
  };

  const onMouseLeave = () => {
    setIsShown(false);
  };

  return (
    <>
      {!column.isGrouped && (
        <div
          className={containerCN}
          {...column.getHeaderProps(headerProps)}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          <div
            ref={ref}
            className={headerCellCN(isSelectionHeader, isPrimary, isLast)}
            {...column.getSortByToggleProps()}
            style={
              center
                ? {
                    ...column.getSortByToggleProps()?.style,
                    justifyContent: "center",
                  }
                : { ...column.getSortByToggleProps()?.style }
            }
          >
            <div className="flex items-center">
              {!isSelectionHeader && !isPrimary && !disableDragging && (
                <img
                  ref={drag}
                  style={{ opacity: isShown ? "100" : "0" }}
                  className="mr-1 ml-0.5 cursor-move"
                  src={dragIcon}
                  alt="drag icon"
                />
              )}
              <div
                className={headerText(
                  isPrimary || (disableDragging && !isSelectionHeader)
                )}
              >
                {column.render("Header")}
              </div>
            </div>
            {!disableSortBy && !disableSorting && getSortIndicator(column)}
          </div>
          {!isSelectionHeader && column.getResizerProps && (
            <div
              {...column.getResizerProps()}
              style={{ minWidth: "1px", cursor: "ew-resize" }}
              className="h-full z-10 bg-es-medium-grey"
            />
          )}
        </div>
      )}
    </>
  );
};

TableHeader.propTypes = {
  id: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  column: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  moveColumn: PropTypes.func.isRequired,
  /**
   * if true, disables the checkbox to hide and disables drag and drop
   */
  isPrimary: PropTypes.bool,
  /**
   * Is last header in group
   */
  isLast: PropTypes.bool,
  /**
   * if true, disables the ability to sort on the column
   */
  disableSortBy: PropTypes.bool,
  disableSorting: PropTypes.bool,
  /**
   * Center Table Headers
   */
  center: PropTypes.bool,
  disableDragging: PropTypes.bool,
};

TableHeader.defaultProps = {
  isPrimary: false,
  isLast: false,
  disableSortBy: false,
  disableSorting: false,
  center: false,
  disableDragging: undefined,
};

export default TableHeader;

import moment from "moment-timezone";

/**
 * Formats a task's start time based on its timezone.
 *
 * @param {{allDay: string, startDate: string, timezone: string}} task The task object containing allDay startDate and timezone properties.
 * @returns {string} - A formatted string representing the task's start time or "All Day" if it is an all-day event.
 */

const formatHistoryTimeValue = (task) => {
  const cancelCriteria =
    task?.allDay === undefined ||
    task.allDay === null ||
    !task?.startDate ||
    !task?.timezone;

  if (cancelCriteria) {
    return "Error: Contact Support";
  }

  const formattedTimeString = `${moment
    .tz(task.startDate, task.timezone)
    .format("h:mm A")} ${moment.tz(task.timezone).format("z")}`;

  return task.allDay ? "All Day" : formattedTimeString;
};

export default formatHistoryTimeValue;

import { useCallback } from "react";

const arithmeticToggleUnits = {
  add: "+",
  subtract: "-",
};

const currencyToggleUnits = {
  dollar: "$",
  percent: "%",
};

const useAdjustmentCellData = ({
  isHighRangeField,
  editedRow,
  setEditedRow,
  row,
}) => {
  const currencyValue = isHighRangeField
    ? row?.original?.highRange?.adjustment?.amount
    : row?.original?.adjustment?.amount;

  const currencyPercentageValue = isHighRangeField
    ? row?.original?.highRange?.adjustment?.adjustmentUnit === "%"
    : row?.original?.adjustment?.adjustmentUnit === "%";

  const isArithmeticUnitAddActive = isHighRangeField
    ? editedRow?.highRange?.adjustment?.arithmeticUnit ===
      arithmeticToggleUnits.add
    : editedRow?.adjustment?.arithmeticUnit === arithmeticToggleUnits.add;

  const isArithmeticUnitSubtractActive = isHighRangeField
    ? editedRow?.highRange?.adjustment?.arithmeticUnit ===
      arithmeticToggleUnits.subtract
    : editedRow?.adjustment?.arithmeticUnit === arithmeticToggleUnits.subtract;

  const adjustmentAmountValue = isHighRangeField
    ? editedRow?.highRange?.adjustment?.amount
    : editedRow?.adjustment?.amount;

  const isAdjustmentUnitDollarActive = isHighRangeField
    ? editedRow?.highRange?.adjustment?.adjustmentUnit ===
      currencyToggleUnits.dollar
    : editedRow?.adjustment?.adjustmentUnit === currencyToggleUnits.dollar;

  const isAdjustmentUnitPercentActive = isHighRangeField
    ? editedRow?.highRange?.adjustment?.adjustmentUnit ===
      currencyToggleUnits.percent
    : editedRow?.adjustment?.adjustmentUnit === currencyToggleUnits.percent;

  const onAdjustmentChange = useCallback(
    (newVal) => {
      if (isHighRangeField) {
        setEditedRow({
          ...editedRow,
          highRange: {
            ...editedRow?.highRange,
            adjustment: {
              ...editedRow.highRange.adjustment,
              amount: newVal,
            },
          },
        });
      } else {
        setEditedRow({
          ...editedRow,
          adjustment: {
            ...editedRow.adjustment,
            amount: newVal,
          },
        });
      }
    },
    [editedRow, isHighRangeField, setEditedRow]
  );

  const onArithmeticUnitChange = useCallback(
    (newVal) => {
      if (isHighRangeField) {
        setEditedRow({
          ...editedRow,
          highRange: {
            ...editedRow?.highRange,
            adjustment: {
              ...editedRow.highRange.adjustment,
              arithmeticUnit: newVal,
            },
          },
        });
      } else {
        setEditedRow({
          ...editedRow,
          adjustment: {
            ...editedRow.adjustment,
            arithmeticUnit: newVal,
          },
        });
      }
    },
    [editedRow, isHighRangeField, setEditedRow]
  );

  const onAdjustmentUnitChange = useCallback(
    (newVal) => {
      if (isHighRangeField) {
        setEditedRow({
          ...editedRow,
          highRange: {
            ...editedRow?.highRange,
            adjustment: {
              ...editedRow.highRange.adjustment,
              adjustmentUnit: newVal,
            },
          },
        });
      } else {
        setEditedRow({
          ...editedRow,
          adjustment: {
            ...editedRow.adjustment,
            adjustmentUnit: newVal,
          },
        });
      }
    },
    [editedRow, isHighRangeField, setEditedRow]
  );
  return {
    currencyValue,
    currencyPercentageValue,
    isArithmeticUnitAddActive,
    isArithmeticUnitSubtractActive,
    adjustmentAmountValue,
    isAdjustmentUnitDollarActive,
    isAdjustmentUnitPercentActive,
    arithmeticToggleUnits,
    currencyToggleUnits,
    onAdjustmentChange,
    onArithmeticUnitChange,
    onAdjustmentUnitChange,
  };
};

export default useAdjustmentCellData;

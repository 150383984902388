/**
 * Modifies the settings of a specific page.
 *
 * @param {Object} params - The parameters.
 * @param {string} params.page - The page to modify.
 * @param {Object} params.k - The new settings for the page.
 * @param {Object} params.userSettings - The current user settings.
 * @param {Function} params.editSettings - The function to edit the settings.
 * @returns {Function} - A promise that resolves when the settings have been edited.
 */

const removePageSettings = async ({
  page,
  keys,
  userSettings,
  editSettings,
}) => {
  const newSettings = {
    ...userSettings,
  };

  keys.forEach((key) => {
    delete newSettings?.web?.[page]?.[key];
  });

  return editSettings(newSettings);
};

export default removePageSettings;

import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useServiceRequestDelete from "../../../hooks/useServiceRequestDelete";
import useTemplatesConfiguration from "../../../hooks/useTemplatesConfiguration.new";
import useTemplatesConfigurationPatch from "../../../hooks/useTemplatesConfigurationPatch";
import useTemplatesConfigurationDelete from "../../../hooks/useTemplatesConfigurationDelete";
import { getSingleResourcePath } from "../../../helpers/Navigation";
import useRemoveTaskFromCache from "../../../helpers/Workflow/removeTasksFromWorkflow";

const useServiceRequestTableViewData = (
  association,
  data,
  onRequestClicked
) => {
  const history = useHistory();
  // Query hook with current user
  const { data: currentUser } = useCurrentUser();
  // Query hook to select request templates
  const { data: templateConfiguration } = useTemplatesConfiguration();

  const [ticketTemplates, setTicketTemplates] = useState([]);
  const [hideSwitchView, setHideSwitchView] = useState(false);
  const [hideColumnSettingsLeft, setHideColumnSettingsLeft] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [commentsData, setCommentsData] = useState({
    isOpen: false,
    ticketReference: undefined,
    association: undefined,
  });

  // State to hold ids of selected rows
  const [selectedRows, setSelectedRows] = useState([]);

  // State to hold the number of selected rows
  const [totalSelectedRows, setTotalSelectedRows] = useState(0);

  // Mutation hook to delete tickets
  const { mutateAsync: deleteServiceRequest } = useServiceRequestDelete();
  // Mutation hook to patch templates
  const { mutate: patchTemplates } = useTemplatesConfigurationPatch();
  // Mutation hook to delete templates
  const { mutate: deleteTemplate } = useTemplatesConfigurationDelete();

  // Check if current user can delete tickets
  const canDelete = !!currentUser?.permissions?.ticket?.can_delete;

  useLayoutEffect(() => {
    // Hide switch view (List View) on table if user comes from P/P/A single page
    setHideSwitchView(!!association);

    // Hide "Edit columns" on table if user comer from P/P/A single page
    setHideColumnSettingsLeft(!association);
  }, [association]);

  useEffect(() => {
    setTotalSelectedRows(selectedRows.length);
  }, [selectedRows]);

  // Set templates for tickets (Quick View)
  useEffect(() => {
    if (templateConfiguration?.templates?.ticket) {
      setTicketTemplates(templateConfiguration.templates.ticket);
    }
  }, [templateConfiguration?.templates?.ticket]);

  /**
   * Set tickets ids for selected rows in state
   */
  const handleSelectRows = useCallback((rows) => {
    setSelectedRows(
      rows.map(({ id, tasks }) => {
        return {
          id,
          tasks,
        };
      })
    );
  }, []);

  const { removeTasksFromCache } = useRemoveTaskFromCache();
  /**
   * Deletes selected tickets
   */
  const handleDeleteTickets = useCallback(() => {
    const tasksRefs = [];
    const serviceRequestIds = [];
    selectedRows?.forEach((row) => {
      tasksRefs.push(...row?.tasks);
      serviceRequestIds.push(row?.id);
      return { ...row };
    });

    try {
      deleteServiceRequest(serviceRequestIds);

      if (tasksRefs?.length > 0) {
        const tasksIds = tasksRefs.map((ref) => ref?.split("/")?.[1]);
        removeTasksFromCache(tasksIds);
      }
    } catch (e) {
      console.error(
        e,
        "Error deleting tickets from table view, useServiceRequestTableViewData"
      );
    }

    setShowDeleteDialog(false);
  }, [deleteServiceRequest, selectedRows, removeTasksFromCache]);

  /**
   * Open comments with ticket reference and association
   * @param {Object} ticketSelected - ticket selected
   */
  const handleOpenComments = useCallback((ticketSelected) => {
    setCommentsData({
      ticketReference: ticketSelected.original.reference,
      association: ticketSelected.original.association,
      isOpen: true,
    });
  }, []);

  const handleCloseComments = useCallback(() => {
    setCommentsData({
      ticketReference: undefined,
      association: undefined,
      isOpen: false,
    });
  }, []);

  /**
   * Navigates to single ticket page
   * @param {Number} index position from table to return selected data
   */
  const handleTicketSelected = (index) => {
    if (onRequestClicked && typeof onRequestClicked === "function") {
      const ticketId = data[index]?.id;
      onRequestClicked(ticketId);
    } else {
      const ticketRef = data[index]?.reference;
      const path = getSingleResourcePath(ticketRef);
      history.push(path);
    }
  };

  const handleSaveTemplates = useCallback(
    (template) => {
      patchTemplates({
        resource: "ticket",
        updatedTemplates: [
          ...ticketTemplates.filter((view) => !view.custom),
          ...template.map((view) => ({
            ...view,
            custom: true,
          })),
        ],
      });
    },
    [patchTemplates, ticketTemplates]
  );

  const handleDeleteTemplate = useCallback(
    async (template) => {
      deleteTemplate({
        resource: "ticket",
        id: template.id,
      });
    },
    [deleteTemplate]
  );

  return {
    canDelete,
    hideSwitchView,
    hideColumnSettingsLeft,
    selectedRows,
    totalSelectedRows,
    showDeleteDialog,
    commentsData,
    ticketTemplates,
    setShowDeleteDialog,
    handleSelectRows,
    handleDeleteTickets,
    handleOpenComments,
    handleCloseComments,
    handleTicketSelected,
    handleSaveTemplates,
    handleDeleteTemplate,
  };
};

export default useServiceRequestTableViewData;

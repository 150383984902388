import React from "react";
import PropTypes from "prop-types";
import TableCellDropdown from "../../../Table/TableCellDropdown";
import useVendorCellData from "./useVendorCellData";

// TODO: creating/editing/viewing budget groups needs to be handled. Check with design
const VendorCell = ({
  isEditingTable,
  vendors,
  setEditedRow,
  editedRow,
  row,
}) => {
  const { getDropdownValue, onChange } = useVendorCellData({
    vendors,
    editedRow,
    setEditedRow,
  });

  // View mode, if not editing
  if (!isEditingTable) {
    return row?.original?.vendorName;
  }

  return (
    <>
      <div className="flex w-full">
        <TableCellDropdown
          value={getDropdownValue(editedRow?.vendor)}
          options={vendors}
          onChange={onChange}
        />
      </div>
    </>
  );
};

VendorCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      vendorName: PropTypes.string,
    }),
  }),
  editedRow: PropTypes.shape({
    vendor: PropTypes.string,
  }),
  setEditedRow: PropTypes.func,
  isEditingTable: PropTypes.bool,
  vendors: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

VendorCell.defaultProps = {
  row: undefined,
  setEditedRow: undefined,
  isEditingTable: false,
  editedRow: undefined,
  vendors: [],
};

export default VendorCell;

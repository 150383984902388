import React from "react";
import cntl from "cntl";

const listItemCN = (isInView) => cntl`
transition-all duration-400 ease-in-out
hover:bg-es-mint hover:bg-opacity-10
w-full justify-center flex
${isInView ? " bg-es-mint bg-opacity-10" : " bg-es-white bg-opacity-100"}
`;

const AlphabetIndex = ({
  showIndex,
  sortedElements,
  flexDirection,
  isActive,
}) => {
  return (
    <>
      {showIndex && (
        <div
          className={`flex flex-${flexDirection} gap-2 items-center justify-start`}
          style={{ width: "30px" }}
        >
          {Object.keys(sortedElements)?.map((element) => {
            return (
              <div
                key={element}
                className={listItemCN(
                  isActive?.target?.id === `content-${element}`
                )}
              >
                <a
                  href={`#content-${element}`}
                  className="text-es-normal font-es-semibold text-es-green"
                >
                  {element}
                </a>
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};

export default AlphabetIndex;

import { useMemo, useReducer, useState } from "react";

import useManagementConfiguration from "../../../../hooks/useManagementConfiguration";

import {
  CHANGE_SPACE_AREA,
  CHANGE_SPACE_ASSOCIATION,
  CHANGE_SPACE_LEVEL,
  CHANGE_SPACE_NAME,
  CHANGE_SPACE_TYPE,
} from "../../../../constants";
import useFilesPost from "../../../../hooks/useFilesPost";
import uploadAvatar from "../../../../helpers/uploadAvatar";
import { useAddSpace } from "../../../../hooks/api/spaces";

/* initialization */
const defaultState = {
  name: null,
  association: null,
  level: null,
  type: null,
  area: null,
  imageInfo: { fileRef: null, blob: null },
};

/* Reducer */
const modalReducer = (state, action) => {
  switch (action.type) {
    case CHANGE_SPACE_NAME:
      return { ...state, name: action.payload };
    case CHANGE_SPACE_ASSOCIATION:
      return { ...state, association: action.payload };
    case CHANGE_SPACE_LEVEL:
      return { ...state, level: action.payload };
    case CHANGE_SPACE_TYPE:
      return { ...state, type: action.payload };
    case CHANGE_SPACE_AREA:
      return { ...state, area: action.payload };
    default:
      return state;
  }
};

const useAddSpaceModal = ({ modalData }) => {
  const { addSpace, isAddingSpace } = useAddSpace();

  /* Mutation hook to post files */
  const { mutateAsync: postFiles } = useFilesPost();

  const dataForReducer = {
    ...modalData?.formData,
  };

  delete dataForReducer?.initialValue;
  delete dataForReducer?.tmpAvatarImg;

  /* Primary Reducer Hook */
  const [state, dispatch] = useReducer(
    modalReducer,
    dataForReducer
      ? {
          ...defaultState,
          ...dataForReducer,
        }
      : defaultState
  );

  /* ManagementConfig Hook fetches Types & Levels */
  const { data: managementConfiguration } = useManagementConfiguration();

  /* set initial association value */
  const [initialValue, setInitialValue] = useState(
    modalData?.formData?.initialValue
  );

  /* Temporary Avatar Image State */
  const [tmpAvatarImg, setTmpAvatarImg] = useState(
    modalData?.formData?.tmpAvatarImg ?? {}
  );

  /* Dropdown Lists */
  const levelsDropdown = useMemo(() => {
    return managementConfiguration?.management?.propertyLevel?.types?.map(
      (type) => {
        return { label: type.display, value: type.id };
      }
    );
  }, [managementConfiguration?.management?.propertyLevel?.types]);
  const typesDropdown = useMemo(() => {
    return managementConfiguration?.management?.propertySpace?.types?.map(
      (type) => {
        return { label: type.display, value: type.id };
      }
    );
  }, [managementConfiguration?.management?.propertySpace?.types]);

  /* Form Actions */
  const handleSave = async (postRequestData) => {
    /* data is state object passed in from modal component to maintain immutability of state */

    const newPostRequestData = await uploadAvatar({
      tmpAvatarImg,
      resourceState: postRequestData,
      postFiles,
    });

    const payload = {
      name: newPostRequestData.name,
      /* if area is null, send 0 instead */
      area: {
        value: Number.isInteger(parseFloat(newPostRequestData.area, 10))
          ? parseFloat(newPostRequestData.area, 10)
          : 0,
      },
      type: newPostRequestData?.type?.value ?? "",
      level: newPostRequestData?.level?.value ?? "",
      primaryImage: newPostRequestData?.primaryImage,
      files: newPostRequestData?.files,
      association: newPostRequestData.association,
    };

    try {
      await addSpace(payload);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return {
    state,
    tmpAvatarImg,
    isAddingSpace,
    levelsDropdown,
    typesDropdown,
    initialValue,
    setInitialValue,
    dispatch,
    handleSave,
    setTmpAvatarImg,
  };
};

export default useAddSpaceModal;

import { useCallback, useEffect, useMemo, useState } from "react";
import { REMOVE_MODAL_INSTANCE } from "../../../constants";
import { useAssetsOverview } from "../../../hooks/assets";
import useModal from "../../../hooks/useModal";
import { useModalState } from "../../../state/modalState";
import { useProjectsOverview } from "../../../hooks/projects";

const useEsModalData = (containerRef, modalData, customButtons) => {
  // Get modal data from the app state
  const [, modalDispatch] = useModalState();
  const [activeItem, setActiveItem] = useState(null);

  const { projectDict } = useProjectsOverview();

  const { assetsDict } = useAssetsOverview();

  /**
   * Legacy Modal Hook/Functions
   */
  const { minimize, maximize, expand, contract, setState, state } = useModal(
    modalData?.id
  );

  const [{ modals }] = useModalState();

  const [containerHeight, setContainerHeight] = useState(null);
  const [containerWidth, setContainerWidth] = useState(null);

  useEffect(() => {
    // get the height and width of the container
    const wrapperDivHeight = containerRef?.current?.offsetHeight;
    const wrapperDivWidth = containerRef?.current?.offsetWidth;
    // set the height and width of the container
    setContainerHeight(wrapperDivHeight);
    setContainerWidth(wrapperDivWidth);
  }, [containerRef, containerRef?.current?.offsetHeight, modalData]);

  // Extract x and y coordinates from the clicked item
  const positionX = modalData?.position?.x;
  const positionY = modalData?.position?.y;

  // Get the width and height of the clicked item (parent)
  const widthOfParent = modalData?.modalData?.width;
  const heightOfParent = modalData?.modalData?.height || 0; // Assuming you have a height property, set to 0 as a default if it's not available

  // Set constants for the modal so positioning calculations are easier to read
  const modalWidth = containerWidth;
  const modalHeight = containerHeight;

  let leftPosition;
  let topPosition = `${positionY}px`;

  // Calculate the left position for the modal.
  if (positionX - modalWidth >= 0) {
    // Enough space to display on the left of the clicked item
    leftPosition = `${positionX - modalWidth}px`;
  } else if (
    window.innerWidth - (positionX + widthOfParent + modalWidth) >=
    0
  ) {
    // Not enough space on the left, but there's enough space on the right
    leftPosition = `${positionX + widthOfParent}px`;
  } else {
    // No room on the right either; position the modal on top
    leftPosition = `${positionX}px`;
    const topInPixel = positionY - modalHeight - heightOfParent;
    // Stop Modal from spawning off-screen
    topPosition = `${topInPixel < 0 ? 25 : topInPixel}px`;
  }

  // Check if modal goes out of the viewport at the bottom
  if (parseInt(topPosition, 10) + modalHeight > window.innerHeight - 100) {
    const topInPixel = window.innerHeight - modalHeight - 100;
    topPosition = `${topInPixel < 0 ? 25 : topInPixel}px`;
  }

  // Check if modal goes out of the viewport on the right
  if (parseInt(leftPosition, 10) + modalWidth > window.innerWidth) {
    leftPosition = `${window.innerWidth - modalWidth}px`;
  }

  // Define styles for the modal wrapper
  const modalWrapperStyles = {
    position: "fixed",
    left: leftPosition,
    top: topPosition,
    border: "1px solid #ccc",
    backgroundColor: "white",
    width: "400px",
    zIndex: 9999, // Ensure the popup is on top of other content
  };

  const closeModal = useCallback(() => {
    modalDispatch({
      type: REMOVE_MODAL_INSTANCE,
      id: modalData.id,
    });
  }, [modalData?.id, modalDispatch]);

  useEffect(() => {
    const association = modalData?.modalData?.item.association;

    // split the association  string by "/" and determine if the first item is "Project" or "Asset"
    const associationType = association?.split("/")[0];

    if (associationType === "Project") {
      setActiveItem(projectDict[association]);
    }

    if (associationType === "Asset") {
      setActiveItem(assetsDict[association]);
    }
  }, [projectDict, modalData, assetsDict]);

  // User Defined Buttons that need access to parent functions pass function
  const buttons = useMemo(() => {
    if (customButtons) {
      if (typeof customButtons === "function") {
        return customButtons({ closeModal });
      }
      return customButtons;
    }
    return null;
  }, [customButtons, closeModal]);

  // Return the styles and modal data for use in the component
  return {
    modalWrapperStyles,
    modalData,
    activeItem,
    closeModal,
    minimize,
    maximize,
    expand,
    contract,
    setState,
    state,
    modals,
    buttons,
  };
};

export default useEsModalData;

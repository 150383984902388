import React from "react";
import PropTypes from "prop-types";
import TableCellInput from "../../../Table/TableCellInput";

const QuantityCell = ({ row, isEditingTable, editedRow, isHighRangeField }) => {
  if (!isEditingTable) {
    return isHighRangeField
      ? row?.original?.highRange?.quantity
      : row?.original?.quantity;
  }

  const hide = isHighRangeField && !editedRow?.isCostRange;
  return (
    <div className={`flex w-full ${hide && "invisible"}`}>
      <TableCellInput
        value={
          isHighRangeField
            ? editedRow?.highRange?.quantity
            : editedRow?.quantity
        }
        isDisabled
      />
    </div>
  );
};

QuantityCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      quantity: PropTypes.string,
      highRange: PropTypes.shape({
        quantity: PropTypes.string,
      }),
    }),
  }),
  editedRow: PropTypes.shape({
    isCostRange: PropTypes.bool,
    quantity: PropTypes.string,
    highRange: PropTypes.shape({
      quantity: PropTypes.string,
    }),
  }),
  isEditingTable: PropTypes.bool,
  isHighRangeField: PropTypes.bool,
};

QuantityCell.defaultProps = {
  row: undefined,
  editedRow: {},
  isEditingTable: false,
  isHighRangeField: false,
};

export default QuantityCell;

import React, { useState } from "react";
import { useHistory } from "react-router";
import taskIconGrey from "../../../assets/images/taskIconGrey.svg";
import { useSingleTask } from "../../../../hooks/api/tasks";
import { renderStatusImage } from "../../../../helpers/User";
import useRsvp from "../../../../hooks/rsvp/useRsvp";
import useCurrentUser from "../../../../hooks/useCurrentUser";

const ChildTask = ({ id }) => {
  const history = useHistory();
  const [hover, setHover] = useState(false);
  const { singleTask: childTask } = useSingleTask(id);
  const instanceStartDate = childTask?.instanceStartDate || null;
  const { data: rsvpData } = useRsvp(childTask?.reference, instanceStartDate);
  const { data: currentUser } = useCurrentUser();
  const childName = childTask?.name;

  const onClick = () => {
    const taskInstanceStartDate = childTask?.instanceStartDate;

    const taskPath = taskInstanceStartDate
      ? `/tasks/${childTask?.id}?instanceStartDate=${taskInstanceStartDate}`
      : `/tasks/${childTask?.id}`;

    history.push(taskPath);
  };

  const imageSrc = renderStatusImage(rsvpData?.[currentUser?.reference]);

  return (
    <button
      key={childName}
      type="button"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className="flex flex-row items-start gap-2 justify-start w-full h-full"
      onClick={() => onClick()}
    >
      <div className="flex flex-col mt-1 relative">
        <img
          src={taskIconGrey}
          className="flex"
          alt={`Navigate to Task, ${childName}`}
          style={{ minHeight: "20px", minWidth: "20px" }}
          color="es-medium-grey"
        />
        {!!imageSrc && (
          <img
            alt="status"
            src={imageSrc}
            className="absolute flex top-3 left-3"
            style={{ zIndex: 1, maxWidth: "10px", maxHeight: "10px" }}
          />
        )}
      </div>
      <p
        className={`${
          hover && "text-es-green underline"
        } text-es-normal text-es-medium-grey text-left`}
      >
        {childName}
      </p>
    </button>
  );
};

export default ChildTask;

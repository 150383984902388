import cntl from "cntl";
import PropTypes from "prop-types";
import React from "react";
import RoleMembersAvatarList from "../../../Pages/Admin/RoleMembersAvatarList";
import { useAppState } from "../../../state/appState";

const associatedUserCN = cntl`
  bg-backgroundGrey
  border-gray-100
  z-40
  rounded-full
  w-full
  h-full
  flex
  items-center
  justify-center
`;

const avatarCN = cntl`
  w-7
  h-7
`;

const ViewAllButton = ({ members }) => {
  return (
    <div className={associatedUserCN}>
      <p className="uppercase text-primaryGreen  text-xs font-semibold truncate">
        +{members?.length - 5}
      </p>
    </div>
  );
};

const TableDirectReportCell = ({ value, showBottomTooltip }) => {
  const [{ userDict, rolesDict }] = useAppState();
  const values = Object.values(value);
  let dr = [];

  // eslint-disable-next-line no-return-assign
  values?.forEach((item) => (dr = [...dr, ...item]));
  const members = dr?.map((item) =>
    item?.startsWith("User/")
      ? userDict?.[item]
      : {
          id: item?.split("/")[1],
          name: { firstName: rolesDict?.[item]?.name },
          kind: "role",
        }
  );

  return (
    // (Parker) Hardcoded width for cell here as the tooltip gets cut-off otherwise
    <div style={{ width: "400px" }} className="pl-0.5 py-0.5">
      <RoleMembersAvatarList
        className="items-center pl-1"
        members={members}
        viewAllClassName={`text-xs ${avatarCN}`}
        viewAllButton={<ViewAllButton members={members} />}
        showBottomTooltip={showBottomTooltip}
        avatarContainerClassname={avatarCN}
        isWrapperRelative={false}
      />
    </div>
  );
};

TableDirectReportCell.propTypes = {
  value: PropTypes.shape({
    original: PropTypes.shape({
      description: PropTypes.string,
    }),
  }),
  showBottomTooltip: PropTypes.bool,
};

TableDirectReportCell.defaultProps = {
  value: undefined,
  showBottomTooltip: undefined,
};

export default TableDirectReportCell;

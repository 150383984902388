import { useQueryClient } from "react-query";
import { useCallback } from "react";
import { fetchAssetsOverview } from "../../helpers/Assets";
import assetKeys from "./assetsKeys";

/**
 * Query hook to prefetch assets overview
 */
const useAssetsPrefetch = () => {
  const queryClient = useQueryClient();

  const prefetchAssetsOverview = useCallback(() => {
    queryClient.prefetchQuery({
      queryKey: assetKeys.overview(),
      queryFn: ({ signal }) => fetchAssetsOverview(signal),
    });
  }, [queryClient]);

  return { prefetchAssetsOverview };
};

export default useAssetsPrefetch;

import { WorkflowAPI } from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import { useAppState } from "../state/appState";
import {
  vendorinvoiceKeys,
  workflowKeys,
} from "../config/reactQuery/queryKeyFactory";
import { toastError, toastMessage } from "../stories/Components/Toast/Toast";
import { SET_WORKFLOW, SET_WORKFLOW_MODAL_STATUS } from "../constants";
import { usePatchWorkflow } from "./useWorkflowById.new";

const postWorkflow = async (workflow) => {
  const { data } = await WorkflowAPI.post(workflow);
  return data;
};
/**
 * Mutation hook to post new workflows
 * @param {string} mutationKey key to track mutation (optional)
 */
const usePostWorkflow = (modalData, mutationKey) => {
  const [{ workflowsNew, editingResource }, dispatch] = useAppState();
  const queryClient = useQueryClient();

  /* patch aasociation workflow's associated artifacts list */
  const { mutateAsync: patchWorkflow } = usePatchWorkflow(
    modalData?.artifactFor?.id
  );

  return useMutation({
    mutationKey,
    mutationFn: postWorkflow,
    onSuccess: (newWorkflow) => {
      queryClient.getQueryData(workflowKeys.workflows, (current) => ({
        ...current,
        workflows: [...(current?.workflows ?? [], newWorkflow)],
        workflowDict: {
          ...current?.workflowDict,
          [newWorkflow.reference]: newWorkflow,
        },
        workflowDD: [
          ...(current?.workflowDD ?? []),
          {
            id: newWorkflow?.id,
            isTemplate: newWorkflow?.isTemplate,
            isDefaultTemplate: newWorkflow?.isDefaultTemplate,
          },
        ],
      }));

      dispatch({
        type: SET_WORKFLOW,
        workflows: [...workflowsNew, newWorkflow],
      });

      try {
        // if WF is association, then patch the associated WF associated artifacts list
        // Always set tabIndex to 0 after creating a new submittal
        if (modalData?.artifactFor?.reference) {
          const updatedWf = {
            ...modalData?.artifactFor,
            associatedArtifacts: [
              ...modalData?.artifactFor?.associatedArtifacts,
              `Workflow/${newWorkflow?.id}`,
            ],
          };
          // patch WF
          patchWorkflow({
            updatedResource: updatedWf,
            originalResource: modalData?.artifactFor,
          });
        }
      } catch (error) {
        toastError("Unable to update associated artifact list");
        console.error("Patch WF associated artifacts list", error);
      } finally {
        // update app state and navigate to new WF
        dispatch({
          type: SET_WORKFLOW_MODAL_STATUS,
          activeTabIndex: 0,
        });
      }
      // prepare and send success toast message
      const messageAndLinkProps = {
        resource: newWorkflow,
        editingResource,
      };
      toastMessage(undefined, messageAndLinkProps);
    },
    onError: (error) => {
      toastError("Error creating workflow");
      console.error("usePostWorkflow", error);
    },
    onSettled: () => {
      queryClient.invalidateQueries(workflowKeys.workflows);
      queryClient.invalidateQueries(vendorinvoiceKeys);
    },
  });
};

export default usePostWorkflow;

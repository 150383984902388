import { ProjectAPI } from "@griffingroupglobal/eslib-api";
import { useState } from "react";

const useCheckDuplicatedTitle = () => {
  // State to make sure new Project and edited Project have a unique name
  const [nameExists, setNameExists] = useState(false);

  /**  make an API call to fetch a project by name
   * @param {name} project name is of string type
   * @return:
   * - null if name is undefined
   * - API call succesfull returns reponse.data =
   * {
   *   name: <project name>,
   *   reference: <project reference>
   *  }
   * - API call returns {statusCode, errorMessage} if no project found
   */
  const fetchProjectByName = async (name) => {
    if (!name) return null;

    try {
      const extras = {
        params: {
          name,
        },
      };
      const { data } = await ProjectAPI.getWOP("$lookup", extras);
      setNameExists(true);
      return data;
    } catch (error) {
      setNameExists(false);
      return { statusCode: error.status, errorMessage: error.message };
    }
  };

  return {
    fetchProjectByName,
    setNameExists,
    nameExists,
  };
};

export default useCheckDuplicatedTitle;

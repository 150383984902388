import { FinanceAPI } from "@griffingroupglobal/eslib-api";

const editFinanceHandler = async (props) => {
  const { originalItem, editedItem } = props;
  const { id } = originalItem;
  const response = await FinanceAPI.patch(id, editedItem, originalItem);

  return response.data;
};

export default editFinanceHandler;

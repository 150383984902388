/**
 * Modifies the active tab of a page
 *
 * @param {Object} params - The parameters.
 * @param {string} params.page - The page where the tab is located.
 * @param {string} params.tab - The tab to modify.
 * @param {boolean} params.resourceId - Resource id
 * @param {Object} params.userSettings - The current user settings.
 * @param {Function} params.editSettings - The function to edit the user settings.
 * @returns {Promise} A promise that resolves when the active tab has been changed */

const modifyActiveTab = ({
  page,
  tab,
  resourceId,
  userSettings,
  editSettings,
}) => {
  // Create a new copy of userSettings to avoid mutating the original object
  const newSettings = {
    ...userSettings,
    web: {
      ...userSettings?.web,
      [page]: {
        ...userSettings?.web?.[page],
      },
    },
  };

  const pageSettings = newSettings?.web?.[page];

  // If resourceId exists, modify resource tab settings
  if (resourceId) {
    pageSettings.resource = {
      ...pageSettings.resource,
      id: resourceId,
      tab: {
        ...pageSettings.resource?.tab,
        activeTab: tab,
      },
    };
  } else {
    // Modify non-resource tab settings
    pageSettings.tab = {
      ...pageSettings.tab,
      activeTab: tab,
    };
  }

  return editSettings(newSettings);
};

export default modifyActiveTab;

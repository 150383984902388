import cntl from "cntl";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import ReactModal from "react-modal";
import { v4 as uuidv4 } from "uuid";
import useModal from "../../../hooks/useModal";
import deleteIcon from "../../assets/images/deleteWhiteIcon.svg";
import editIcon from "../../assets/images/editIconWhite.svg";
import IconButton from "../Buttons/IconButton";
import PrimaryButton from "../Buttons/PrimaryButton";
import TertiaryButton from "../Buttons/TertiaryButton";
import CloseButton from "./Buttons/CloseButton";
import ExpandButton from "./Buttons/ExpandButton";
import HideButton from "./Buttons/HideButton";
import "./ModalStyles.css";
import { useModalState } from "../../../state/modalState";

const titleCN = (className, minimized) => cntl`
  font-medium
  text-lg
  text-white
  ${className}
  ${minimized && "truncate"}
`;
const childContainerCN = (className, confirm) => cntl`
  flex-1
  pt-5
  pr-7
  pl-7
  leading-tight
  text-gray-700
  ${!confirm ? "overflow-y-scroll" : "disable-y-scroll"}
  relative
  ${className}
`;

const buttonCN = (className) => cntl`
  flex
  flex-row-reverse
  justify-end
  items-center
  mx-6
  gap-4
  ${className}
  ${!className?.includes("pt") && "pt-6"}
`;

const primaryButtonCN = (className) => cntl`
  rounded-md
  ${className ?? "ml-3"}
`;

const teritiaryButtonCN = (className) => cntl`
  rounded-md
  capitalize
  ${className}
`;

const containerCN = (expanded) => cntl`
  flex 
  flex-col
  relative
  ${expanded ? "rounded-md" : "rounded-t-md"}
`;

const contentContainerClass = (minimized) => cntl`
  modal-shown
  ${!minimized ? "" : "modal-hide"}
`;

const modalOverlayClass = (expanded, alert) => cntl`
  modal-overlay-class
  ${(expanded || alert) && "modal-overlay-expanded"}
`;

const modalContentClass = (minimized, expanded, expandable, loading) => cntl`
  modal-content-class
  ${expanded && expandable && "w-4/5"}
  ${!minimized && "modal-content-class-open"}
  ${loading && "loading"}
`;

/**
 * @deprecated - Use ModalWrapper under EsModal folder
 * @returns - Modal Element
 * Notes: showConfirm for Confirm Dialog, expand for semi-full screen modals
 */
const Modal = ({
  isOpen,
  onRequestModalClose,
  afterModalClose,
  title,
  titleClassName,
  children,
  footer,
  hideFooter,
  shouldCloseOnEsc,
  hideHeader,
  childContainerClassName,
  childContainerStyle,
  primaryButtonTitle,
  primaryButtonClass,
  primaryButtonStyle,
  primaryButtonOnClick,
  secondaryButtonTitle,
  trashCan,
  secondaryButtonClass,
  secondaryButtonDisabled,
  secondaryButtonOnClick,
  secondaryButtonRef,
  tertiaryButtonTitle,
  tertiaryButtonClass,
  tertiaryButtonOnClick,
  buttonCnClass,
  buttonCnStyle,
  isDestructive,
  disabled,
  deleting,
  hideHeaderBorder,
  tertiaryButtonRef,
  primaryButtonRef,
  expandable,
  alert,
  showConfirm,
  modalAction,
  onEditClick,
  onDeleteClick,
  isRecurringTask,
  tertiaryDelete,
  hideFooterborder,
  loading,
}) => {
  const modalId = useMemo(() => uuidv4(), []);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [confirmFadeOut, setConfirmFadeOut] = useState(false);
  const [{ modals }] = useModalState();
  const {
    openModal,
    minimizeAll,
    minimize,
    maximize,
    expand,
    contract,
    removeInstance,
    state,
  } = useModal(`modal-content-container-${modalId}`);
  const modalRef = useRef();

  useEffect(() => {
    /**
     * On Modal Expand set input classes to large
     */
    let x = document.getElementById(`modal-container-${modalId}`);
    x = x?.getElementsByClassName("ESInputContainer-md");
    for (let i = 0; i < x?.length; i += 1) {
      if (state?.expanded) {
        x[i].classList.add("ESInputContainer-lg");
      } else {
        x[i].classList.remove("ESInputContainer-lg");
      }
    }
  }, [modalId, state?.expanded]);

  const handleRef = useCallback(() => {
    openModal(modalRef.current);
  }, [openModal]);

  const onMinimizeClick = useCallback(
    (value) => {
      // If minimizing shrink modal
      if (value) {
        minimize(`modal-content-container-${modalId}`);
      } else {
        maximize(`modal-content-container-${modalId}`);
      }
    },
    [maximize, minimize, modalId]
  );

  const onExpandClick = useCallback(
    (value) => {
      // if expanding de-tab modal
      if (value) {
        expand(`modal-content-container-${modalId}`);
      } else {
        contract(`modal-content-container-${modalId}`);
      }
    },
    [contract, expand, modalId]
  );

  /**
   * @summary reset modal state needs timeout to finish animations
   */
  const resetModal = useCallback(() => {
    if (!alert) {
      removeInstance(`modal-content-container-${modalId}`);
      afterModalClose();
    }
    setConfirmDialog(false);
    setDeleteDialog(false);
  }, [afterModalClose, alert, modalId, removeInstance]);

  const minimizeExpanded = () => {
    minimize(`modal-content-container-${modalId}`);
    setConfirmDialog(false);
  };

  /**
   * Close modal - if showConfirm = true display prompt
   */

  const confirmDeleteTimer = useRef(null);
  const confirmCloseTimer = useRef(null);
  const cancelConfirmCloseTimer = useRef(null);

  const closeModal = useCallback(() => {
    if (!showConfirm) {
      resetModal();
      onRequestModalClose();
    } else {
      maximize(`modal-content-container-${modalId}`);
      setConfirmDialog(true);
    }
  }, [maximize, modalId, onRequestModalClose, resetModal, showConfirm]);

  const handleDeleteClick = useCallback(() => {
    setDeleteDialog(true);
  }, []);

  const cancelDelete = () => {
    setDeleteDialog(false);
  };

  const handleEditClick = useCallback(() => {
    onEditClick();
  }, [onEditClick]);

  const confirmDelete = useCallback(() => {
    onRequestModalClose();
    confirmDeleteTimer.current = setTimeout(() => resetModal(), 300);
    onDeleteClick();
  }, [onDeleteClick, onRequestModalClose, resetModal]);

  useEffect(() => {
    // Effect to clear timer set within the component when component unmounts
    return () => {
      if (confirmDeleteTimer.current) clearTimeout(confirmDeleteTimer.current);

      if (confirmCloseTimer.current) clearTimeout(confirmCloseTimer.current);
      if (cancelConfirmCloseTimer.current)
        clearTimeout(cancelConfirmCloseTimer);
    };
  }, []);
  /**
   * @summary Close modal if showConfirm flag is provided
   */
  const confirmCloseModal = useCallback(() => {
    onRequestModalClose();
    confirmCloseTimer.current = setTimeout(() => resetModal(), 300);
  }, [onRequestModalClose, resetModal]);

  /**
   * @summary Cancel close if confirm is provided
   */
  const cancelConfirmClose = useCallback(() => {
    setConfirmFadeOut(true);
    cancelConfirmCloseTimer.current = setTimeout(() => {
      setConfirmDialog(false);
      setConfirmFadeOut(false);
    }, 300);
  }, []);

  const tabPosition = useMemo(() => {
    const overlap =
      250 * modals?.length > window.innerWidth * 0.5 && state?.index !== 0
        ? (250 * modals?.length - window.innerWidth * 0.5) / modals?.length
        : 0;
    return {
      right: `${250 * state?.index - overlap * state?.index}px`,
      zIndex: `${50 + state?.index}`,
    };
  }, [modals?.length, state?.index]);

  const modalWindowActions = useMemo(() => {
    const modalActions = [];
    if (!alert) {
      modalActions.push(
        <HideButton
          className="h-5 p-1"
          animated
          onClick={onMinimizeClick}
          value={state?.minimized}
          color="rgb(255, 255, 255)"
        />
      );
      modalActions.push(
        <ExpandButton
          className="ml-1 h-5 p-1"
          animated
          onClick={onExpandClick}
          value={state?.expanded}
          color="rgb(255, 255, 255)"
        />
      );
    }
    modalActions.push(
      <CloseButton
        onClick={closeModal}
        className="ml-1 h-5 p-1"
        animated
        color="rgb(255, 255, 255)"
      />
    );
    return modalActions;
  }, [
    alert,
    closeModal,
    onExpandClick,
    onMinimizeClick,
    state?.expanded,
    state?.minimized,
  ]);

  return (
    <ReactModal
      appElement={document.getElementById("app")}
      portalClassName="absolute"
      closeTimeoutMS={250}
      onAfterOpen={!alert ? handleRef : minimizeAll}
      overlayClassName={modalOverlayClass(state?.expanded, alert)}
      style={{
        overlay: !alert
          ? {
              ...tabPosition,
            }
          : {},
      }}
      className={modalContentClass(
        state?.minimized,
        state?.expanded,
        expandable,
        loading
      )}
      isOpen={isOpen}
      onRequestClose={!alert ? minimizeExpanded : closeModal}
      shouldCloseOnOverlayClick={state?.expanded}
      shouldCloseOnEsc={shouldCloseOnEsc}
    >
      {isOpen && (
        <div
          id={`modal-container-${modalId}`}
          className={containerCN(state?.expanded)}
        >
          {!hideHeader && (
            <div className="h-13" id="modal-insides">
              <div
                className={`flex items-center justify-between px-4 py-3 ${
                  !hideHeaderBorder && "border-b border-gray-150"
                } ${
                  title?.includes("Task")
                    ? "modal-task-tab-header"
                    : "modal-tab-header"
                }`}
                style={
                  state?.minimized
                    ? {
                        maxWidth: "250px",
                        transition: "all 500ms ease-in-out",
                      }
                    : {
                        transition: "all 500ms ease-in-out",
                        maxWidth: "9999px",
                      }
                }
              >
                <p
                  className={titleCN(titleClassName, state?.minimized)}
                  title={title}
                >
                  {!state?.minimized && (onEditClick || onDeleteClick) ? (
                    <div className="flex pr-1">
                      {onDeleteClick && !tertiaryDelete && (
                        <IconButton
                          imgClassName="w-7 h-7 pr-2"
                          iconClassName="flex items-center w-7 h-7"
                          onClick={() => {
                            if (isRecurringTask) onDeleteClick();
                            else handleDeleteClick();
                          }}
                          icon={deleteIcon}
                        />
                      )}

                      {onEditClick && (
                        <IconButton
                          imgClassName="w-7 h-7 pr-2"
                          iconClassName="flex items-center w-7 h-7"
                          onClick={handleEditClick}
                          icon={editIcon}
                        />
                      )}
                      {title}
                    </div>
                  ) : (
                    title
                  )}
                </p>
                <div className="flex ml-8">
                  {modalWindowActions?.map((item) => item)}
                </div>
              </div>
            </div>
          )}
          <div
            className={contentContainerClass(state?.minimized)}
            id={`modal-content-container-${modalId}`}
            ref={modalRef}
          >
            <div
              className={childContainerCN(
                childContainerClassName,
                showConfirm && confirmDialog && !alert && !state?.minimized
              )}
              style={{ ...childContainerStyle, maxHeight: "75vh" }}
            >
              {showConfirm && confirmDialog && !alert && !state?.minimized && (
                <div
                  id="confirm dialog"
                  className={`z-100 fixed flex flex-col justify-center items-center w-full h-full top-0 left-0 confirm-dialog confirm-transition px-2 ${
                    confirmFadeOut && "confirm-fade-out"
                  }`}
                >
                  <p className="text-base mb-2 w-3/4 text-center text-gray-300">
                    Are you sure you want to cancel{" "}
                    {modalAction ?? "create test"}
                    ?
                    <br />
                    If Yes, all data entered will be lost.
                  </p>
                  <div className="flex">
                    <TertiaryButton
                      title="No"
                      onClick={cancelConfirmClose}
                      className={teritiaryButtonCN(tertiaryButtonClass)}
                      name="tertiaryButton"
                      onKeyUp={(event) => {
                        if (event.keyCode === 13) {
                          cancelConfirmClose();
                        }
                      }}
                    />
                    <PrimaryButton
                      className={primaryButtonCN(primaryButtonClass)}
                      title="Yes"
                      onClick={confirmCloseModal}
                      isDestructive={isDestructive}
                      onKeyUp={confirmCloseModal}
                    />
                  </div>
                </div>
              )}
              {children}
              {!hideFooter && (
                <div
                  className={`z-0 sticky ${
                    !hideFooterborder && "border-t"
                  } flex items-center justify-between`}
                >
                  {footer}
                </div>
              )}
              <div className="pb-4">
                {(primaryButtonTitle ||
                  tertiaryButtonTitle ||
                  (onDeleteClick && tertiaryDelete)) && (
                  <div
                    style={buttonCnStyle}
                    className={buttonCN(buttonCnClass)}
                  >
                    {onDeleteClick && tertiaryDelete && (
                      <IconButton
                        imgClassName="w-5 h-5 pr-2"
                        iconClassName="flex items-center w-4 h-4"
                        onClick={() => {
                          if (isRecurringTask) onDeleteClick();
                          else handleDeleteClick();
                        }}
                        icon={deleteIcon}
                      />
                    )}
                    {tertiaryButtonTitle && (
                      <TertiaryButton
                        title={tertiaryButtonTitle}
                        onClick={tertiaryButtonOnClick || closeModal}
                        className={teritiaryButtonCN(tertiaryButtonClass)}
                        forwardedRef={tertiaryButtonRef}
                        trashCan={trashCan}
                        magnolia={!trashCan}
                        purple={title?.includes("Task")}
                        name="tertiaryButton"
                        onKeyUp={(event) => {
                          if (event.keyCode === 13) {
                            closeModal();
                          } else if (event.keyCode === 9) {
                            primaryButtonRef?.current?.focus();
                          }
                        }}
                      />
                    )}
                    {secondaryButtonTitle && (
                      <PrimaryButton
                        disabled={secondaryButtonDisabled}
                        title={secondaryButtonTitle}
                        onClick={secondaryButtonOnClick}
                        className={primaryButtonCN(secondaryButtonClass)}
                        forwardedRef={secondaryButtonRef}
                        name="secondaryButton"
                        onKeyUp={(event) => {
                          if (event.keyCode === 13) {
                            secondaryButtonOnClick();
                          } else if (event.keyCode === 9) {
                            primaryButtonRef?.current?.focus();
                          }
                        }}
                      />
                    )}
                    {primaryButtonTitle && (
                      <PrimaryButton
                        style={primaryButtonStyle}
                        className={primaryButtonCN(primaryButtonClass)}
                        title={primaryButtonTitle}
                        background="primaryGreen"
                        onClick={() => {
                          primaryButtonOnClick();
                          removeInstance(`modal-content-container-${modalId}`);
                        }}
                        border="primaryGreen"
                        fontColor="white"
                        saveButton
                        saveButtonTitle={primaryButtonTitle}
                        isDestructive={isDestructive}
                        isLoading={deleting}
                        disabled={disabled}
                        forwardedRef={primaryButtonRef}
                        onKeyUp={() => {
                          primaryButtonOnClick();
                          removeInstance(`modal-content-container-${modalId}`);
                        }}
                        name="primaryButton"
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>

          {onDeleteClick &&
            deleteDialog &&
            !showConfirm &&
            !state?.minimized && (
              <div
                id="confirm dialog"
                className={`absolute flex flex-col justify-center items-center w-full h-full top-0 left-0 confirm-dialog confirm-transition px-2 ${
                  confirmFadeOut && "confirm-fade-out"
                }`}
              >
                <p className="text-base mb-2 w-3/4 text-center text-gray-300">
                  Are you sure you want to delete {modalAction || ""}? Once
                  deleted, it cannot be recovered.
                </p>
                <div className="flex">
                  <TertiaryButton
                    title="Cancel"
                    onClick={cancelDelete}
                    className={teritiaryButtonCN(tertiaryButtonClass)}
                    name="tertiaryButton"
                    onKeyUp={(event) => {
                      if (event.keyCode === 13) {
                        cancelDelete();
                      }
                    }}
                  />
                  <PrimaryButton
                    className={primaryButtonCN(primaryButtonClass)}
                    title="Yes"
                    onClick={confirmDelete}
                    isDestructive={isDestructive}
                    onKeyUp={confirmDelete}
                  />
                </div>
              </div>
            )}
        </div>
      )}
    </ReactModal>
  );
};

Modal.propTypes = {
  /**
   * Expandable
   * @summary - enables modal to be expanded to 80%
   */
  expandable: PropTypes.bool,
  /**
   * Alert
   * @summary disables expand and minimize sets styles to alert popup in middle
   */
  alert: PropTypes.bool,
  /**
   * Show Confirm
   * @summary - enables a confirm prompt when canceling or closing
   * Important: CANNOT BE USED WITH onDeleteClick (will cancel eachother out)
   */
  showConfirm: PropTypes.bool,
  /**
   * On Delete
   * @summary - enables a delete prompt when clicking delete icon
   * Important: CANNOT BE USED WITH showConfirm (will cancel eachother out)
   */
  onDeleteClick: PropTypes.func,
  /**
   * On Edit
   */
  onEditClick: PropTypes.func,
  /**
   * Modal Action
   * @summary - name of the action for confirm dialog
   */
  modalAction: PropTypes.string,

  /**
   * ModalId - uuid is provided by default(needs to be unique)
   */
  // modalId: PropTypes.string,
  /**
   * if true, displays the modal
   */
  isOpen: PropTypes.bool,
  /**
   * function called when the modal requests isOpen to be toggled to false
   */
  onRequestModalClose: PropTypes.func,
  /**
   * Function Called after modal is removed from modal state
   */
  afterModalClose: PropTypes.func,
  /**
   * the title to display on the top modal bar
   */
  title: PropTypes.string,
  /**
   * the children to display inside the modal
   */
  children: PropTypes.element,
  /**
   * JSX to display in the bottom cell next to the finish button
   */
  footer: PropTypes.element,
  /**
   * if true, hides the bottom footer
   */
  hideFooter: PropTypes.bool,
  /**
   * if true, close modal on esc
   */
  shouldCloseOnEsc: PropTypes.bool,
  /**
   * if true, hides title bar and close button
   */
  hideHeader: PropTypes.bool,
  titleClassName: PropTypes.string,
  /**
   * className for child container
   */
  childContainerClassName: PropTypes.string,
  /**
   * Styles for child container
   */
  childContainerStyle: PropTypes.shape({}),
  primaryButtonTitle: PropTypes.string,
  primaryButtonClass: PropTypes.string,
  primaryButtonStyle: PropTypes.shape({}),
  primaryButtonOnClick: PropTypes.func,
  tertiaryButtonTitle: PropTypes.string,
  tertiaryButtonClass: PropTypes.string,
  tertiaryButtonOnClick: PropTypes.func,
  secondaryButtonTitle: PropTypes.string,
  secondaryButtonClass: PropTypes.string,
  secondaryButtonOnClick: PropTypes.func,
  secondaryButtonDisabled: PropTypes.bool,
  secondaryButtonRef: PropTypes.shape({
    current: PropTypes.element,
  }),
  buttonCnClass: PropTypes.string,
  buttonCnStyle: PropTypes.shape({}),
  isDestructive: PropTypes.bool,
  disabled: PropTypes.bool,
  deleting: PropTypes.bool,
  hideHeaderBorder: PropTypes.bool,
  tertiaryButtonRef: PropTypes.shape({
    current: PropTypes.element,
  }),
  primaryButtonRef: PropTypes.shape({
    current: PropTypes.element,
  }),
  isRecurringTask: PropTypes.bool,
  /**
   * Boolean for Trash Can Magnolia Button
   */
  trashCan: PropTypes.bool,
  /**
   * Boolean for allowing trashCan delete actions as button
   */
  tertiaryDelete: PropTypes.bool,
  hideFooterborder: PropTypes.bool,
  loading: PropTypes.bool,
};

Modal.defaultProps = {
  secondaryButtonTitle: undefined,
  secondaryButtonClass: undefined,
  secondaryButtonOnClick: undefined,
  secondaryButtonDisabled: false,
  secondaryButtonRef: undefined,
  expandable: false,
  alert: false,
  showConfirm: false,
  onEditClick: undefined,
  onDeleteClick: undefined,
  modalAction: "[Action Name]",
  isOpen: false,
  onRequestModalClose: () => {},
  afterModalClose: () => {},
  title: undefined,
  children: undefined,
  footer: undefined,
  hideFooter: false,
  shouldCloseOnEsc: false,
  hideHeader: false,
  titleClassName: undefined,
  childContainerClassName: undefined,
  childContainerStyle: {},
  primaryButtonTitle: undefined,
  primaryButtonClass: undefined,
  primaryButtonStyle: {},
  primaryButtonOnClick: undefined,
  tertiaryButtonTitle: undefined,
  tertiaryButtonClass: undefined,
  tertiaryButtonOnClick: undefined,
  buttonCnClass: undefined,
  buttonCnStyle: {},
  isDestructive: false,
  disabled: false,
  deleting: false,
  hideHeaderBorder: false,
  tertiaryButtonRef: undefined,
  primaryButtonRef: {
    current: undefined,
  },
  isRecurringTask: false,
  trashCan: false,
  tertiaryDelete: false,
  hideFooterborder: false,
  loading: false,
};

export default Modal;

/* Framework Tools */
import { useCallback } from "react";

import { ADD_OPEN_MODAL, WORKFLOW_CREATE_MODAL } from "../../../constants";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { useModalState } from "../../../state/modalState";

const useWorkflowOverviewData = () => {
  const { data: currentUser } = useCurrentUser();
  const [{ modals }, modalDispatch] = useModalState();

  const isButtonDisabled = modals.find(
    (item) => item.modalType === WORKFLOW_CREATE_MODAL
  );

  const handleAddWorkflow = useCallback(() => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: "create-workflow-modal" },
      modalData: {
        id: "create-workflow-modal",
        isQuickAdd: false,
      },
      modalType: WORKFLOW_CREATE_MODAL,
    });
  }, [modalDispatch]);

  return { currentUser, isButtonDisabled, handleAddWorkflow };
};

export default useWorkflowOverviewData;

import React from "react";

import {
  ADMIN_PATH,
  ASSET_TABLE_PATH,
  CALENDAR_PATH,
  CONTACT_CREATION_PATH,
  CONTACT_US_PATH,
  CONTACTS_PATH,
  CREATE_DOCUMENTS_PATH,
  DOCUMENTS_PATH,
  ENTITIES_CREATION_PATH,
  ENTITIES_PATH,
  EXPENSES_PATH,
  FILES_OVERVIEW_PATH,
  FINANCIALS_ACTUAL_PATH,
  GET_ASSET_DOCUMENT_CREATE_PATH,
  GET_ASSET_DOCUMENT_PATH,
  GET_ASSET_DOCUMENTS_DOC_EDIT_PATH,
  GET_ASSET_DOCUMENTS_DOC_HISTORY_PATH,
  GET_ASSET_DOCUMENTS_DOC_PATH,
  GET_ASSET_EXPENSES_PATH,
  GET_ASSET_FINANCES_PATH,
  GET_ASSET_MAINTENANCE_PATH,
  GET_ASSET_VIEW_PATH,
  GET_CALENDAR_EVENT_PATH,
  GET_CONTACT_EDIT_PATH,
  GET_CONTACT_PATH,
  GET_DOCUMENT_EDIT_PATH,
  GET_DOCUMENT_FOLDER,
  GET_DOCUMENT_FOLDER_FILE_EDIT_PATH,
  GET_DOCUMENT_FOLDER_FILE_HISTORY_PATH,
  GET_DOCUMENT_FOLDER_FILE_PATH,
  GET_DOCUMENT_FOLDER_PATH,
  GET_DOCUMENT_HISTORY_PATH,
  GET_DOCUMENT_PATH,
  GET_DOCUMENT_VIEW,
  GET_LOCATION_ASSET_DOCUMENTS_PATH,
  GET_LOCATION_ASSET_EXPENSES_PATH,
  GET_LOCATION_ASSET_FINANCIALS_PATH,
  GET_LOCATION_ASSET_MAINTENANCE_PATH,
  GET_LOCATION_ASSET_VIEW_PATH,
  GET_LOCATION_ASSETS_PATH,
  GET_LOCATION_SPACE_ASSET_DOCUMENTS_PATH,
  GET_LOCATION_SPACE_ASSET_EXPENSES_PATH,
  GET_LOCATION_SPACE_ASSET_FINANCIALS_PATH,
  GET_LOCATION_SPACE_ASSET_MAINTENANCE_PATH,
  GET_LOCATION_SPACE_ASSET_VIEW_PATH,
  GET_LOCATION_SPACE_ASSETS_PATH,
  GET_MEMBER_PATH,
  GET_PROFILE_EDIT_PATH,
  GET_PROFILE_PATH,
  GET_PROJECT_ACTIVATE_PATH,
  GET_PROJECT_ASSET_DOCUMENTS_PATH,
  GET_PROJECT_ASSET_EXPENSES_PATH,
  GET_PROJECT_ASSET_FINANCIALS_PATH,
  GET_PROJECT_ASSET_MAINTENANCE_PATH,
  GET_PROJECT_ASSET_PATH,
  GET_PROJECT_BUDGET_CREATE_DOCUMENT_PATH,
  GET_PROJECT_BUDGET_PATH,
  GET_PROJECT_CREATE_BUDGET_PATH,
  GET_PROJECT_CREATE_DOCUMENT_PATH,
  GET_PROJECT_DETAILS_PATH,
  GET_PROJECT_DOCUMENTS_DOC_ADDITIONAL_DOCS_PATH,
  GET_PROJECT_DOCUMENTS_DOC_EDIT_PATH,
  GET_PROJECT_DOCUMENTS_DOC_HISTORY_PATH,
  GET_PROJECT_DOCUMENTS_DOC_PATH,
  GET_PROJECT_DOCUMENTS_PATH,
  GET_PROJECT_EXPENSES_PATH,
  GET_PROJECT_FINANCIALS_PATH,
  GET_PROJECT_INVENTORY_PATH,
  GET_PROJECT_MEDIA_PATH,
  GET_PROJECT_RFI_PATH,
  GET_PROJECT_SCHEDULE_PATH,
  GET_PROJECT_SUBMITTAL_PATH,
  GET_PROJECT_SUBMITTALS_PATH,
  GET_PROJECT_WORKFLOW_CREATE_PATH,
  GET_PROJECT_WORKFLOW_EDIT_PATH,
  GET_PROJECT_WORKFLOW_VIEW_PATH,
  GET_PROJECT_WORKFLOWS_DRAFT_PATH,
  GET_PROJECT_WORKFLOWS_PATH,
  GET_PROJECTS_PATH,
  GET_PROPERTY_ASSET_DOCUMENTS_PATH,
  GET_PROPERTY_ASSET_EXPENSES_PATH,
  GET_PROPERTY_ASSET_FINANCIALS_PATH,
  GET_PROPERTY_ASSET_MAINTENANCE_PATH,
  GET_PROPERTY_ASSET_PATH,
  GET_PROPERTY_ASSETS_PATH,
  GET_PROPERTY_DOCUMENT_CREATE_PATH,
  GET_PROPERTY_DOCUMENT_PATH,
  GET_PROPERTY_DOCUMENTS_DOC_EDIT_PATH,
  GET_PROPERTY_DOCUMENTS_DOC_HISTORY_PATH,
  GET_PROPERTY_DOCUMENTS_DOC_PATH,
  GET_PROPERTY_EXPENSES_PATH,
  GET_PROPERTY_MAINTENANCE_PATH,
  GET_PROPERTY_RFI_PATH,
  GET_PROPERTY_VIEW_PATH,
  GET_PROPERTY_WORKFLOW_CREATE_PATH,
  GET_PROPERTY_WORKFLOW_EDIT_PATH,
  GET_PROPERTY_WORKFLOW_VIEW_PATH,
  GET_PROPERTY_WORKFLOWS_DRAFT_PATH,
  GET_PROPERTY_WORKFLOWS_PATH,
  GET_RFI_DETAILS_PATH,
  GET_SOP_DETAILS_PATH,
  GET_SPACE_VIEW_PATH,
  GET_SUBMITTAL_DETAILS_PATH,
  GET_TIMESHEET_HISTORY_PATH,
  HELP_PATH,
  INVENTORIES_PATH,
  INVOICES_CREATE_PATH,
  MAINTENANCE_TASKS_PATH,
  MARKETPLACE_PATH,
  MEMBERS_INVITE_PATH,
  MY_PROFILE,
  PAGE_NOT_FOUND,
  PROFILE_PATH,
  PROJECT_CALENDAR_PATH,
  PROJECTS_PATH,
  PROPERTY_CALENDAR_PATH,
  PROPERTY_TABLE_PATH,
  REPORT_HOURS_PATH,
  REPORT_PATH,
  REPORT_SINGLE_PATH,
  RFI_PATH,
  ROLES_PERMISSIONS_CREATE_PATH,
  ROLES_PERMISSIONS_EDIT_PATH,
  ROLES_PERMISSIONS_VIEW_PATH,
  SOP_PATH,
  SOP_TABLE_PATH,
  SPACES_PATH,
  SUBMITTALS_TABLE_PATH,
  TASK_SINGLE_PATH,
  TASK_TABLE_PATH,
  TICKET_DETAILS_PATH,
  TICKETS_PATH,
  WORKFLOW_DETAILS_PATH,
  WORKFLOW_PATH,
  WORKFLOWS_ADD_NEW_PATH,
  WORKFLOWS_DRAFTS_PATH,
  WORKFLOWS_EDIT_PATH,
} from "../constants";

import CreateRole from "../Pages/Admin/CreateRole";
import EditRole from "../Pages/Admin/EditRole";
import AccountAndAdminView from "../Pages/Admin/AccountAndAdminView";
import ViewRole from "../Pages/Admin/ViewRole";
import CalendarView from "../Pages/Calendar/CalendarView";
import ContactList from "../Pages/Contact/ContactList";
import ContactView from "../Pages/Contact/ContactView";
import CreateContact from "../Pages/Contact/CreateContact";
import EditContact from "../Pages/Contact/EditContact";
import Documents from "../Pages/Document/Documents";
import HelpPage from "../Pages/HelpPage";
import EntitiesOverview from "../Pages/Home/Company/EntitiesOverview";
import EntityCreation from "../Pages/Home/Company/EntityCreation";
import AssetDetails from "../Pages/Overviews/Asset/AssetDetails";
import AssetDocuments from "../Pages/Overviews/Asset/AssetDocuments";
import AssetFinances from "../Pages/Overviews/Asset/AssetFinances";
import AssetList from "../Pages/Overviews/Asset/AssetList";
import AssetMaintenance from "../Pages/Overviews/Asset/AssetMaintenance";
import ProjectAssetDetails from "../Pages/Overviews/Asset/ProjectAssetDetails";
import ActualFinancials from "../Pages/Overviews/Financials/ActualFinancials";
import PropertyAssets from "../Pages/Overviews/Property/PropertyAssets";
import PropertyDocuments from "../Pages/Overviews/Property/PropertyDocuments";
import PropertyList from "../Pages/Overviews/Property/PropertyList";
import PropertySinglePage from "../Pages/properties/PropertySinglePage";
import PropertyWorkflows from "../Pages/Overviews/Property/PropertyWorkflows";
import EditWorkflowView from "../Pages/Overviews/Workflows/EditWorkflowView";
import WorkflowsOverview from "../Pages/Overviews/Workflows/WorkflowsOverview";
import Budget from "../Pages/Project/Budget";
import Inventory from "../Pages/Project/Inventory";
import ProjectDetails from "../Pages/Project/ProjectDetails";
import ProjectDocuments from "../Pages/Project/ProjectDocuments";
import ProjectMedia from "../Pages/Project/ProjectMedia";
import ProjectSchedule from "../Pages/Project/ProjectSchedule";
import ProjectView from "../Pages/Project/ProjectView";
import ProjectWorkflows from "../Pages/Project/ProjectWorkflows";
import UserProfileEdit from "../Pages/UserProfile/UserProfileEdit";
import CreateWorkflowForm from "../stories/Components/Workflows/CreateWorkflowForm";
import SopDetails from "../Pages/Overviews/Sop/SopDetails";
import SopList from "../Pages/Overviews/Sop/SopList";
import ProjectList from "../Pages/Project/ProjectList";
import useBuildingLocation from "../hooks/useBuildingLocation";
import useDocumentsConfiguration from "../hooks/useDocumentsConfiguration";
import { useProjectById } from "../hooks/projects";
import { usePropertyById } from "../hooks/properties";
import BudgetForm from "../stories/Components/BudgetForm/BudgetForm";
import DocumentForm from "../stories/Components/DocumentForm/DocumentForm";
import { DocumentViewContainer } from "../stories/Components/DocumentView/DocumentView";
import InvoiceForm from "../stories/Components/Invoices/InvoiceForm";
import InviteMember from "../stories/Components/MemberManagement/InviteMember";
import SiteHeader from "../stories/Components/SiteHeader/SiteHeader";
// import ReportTimeOff from "../stories/Components/TimeOff/ReportTimeOff";
import AssetExpences from "../Pages/Overviews/Asset/AssetExpenses";
import ExpenseList from "../Pages/Overviews/Expense/ExpenseList";
import PropertyExpences from "../Pages/Overviews/Property/PropertyExpenses";
import PropertyLocationAssets from "../Pages/Overviews/Property/PropertyLocationAssets";
import PropertyMaintenance from "../Pages/Overviews/Property/PropertyMaintenance";
import PropertySpaceAssets from "../Pages/Overviews/Property/PropertySpaceAssets";
import RfiList from "../Pages/Overviews/RFI/RfiList";
import ReportList from "../Pages/Reports/ReportList";
import TaskList from "../Pages/Overviews/Task/TaskList";
import ProjectExpences from "../Pages/Project/ProjectExpenses";
import { useAssetById } from "../hooks/assets";
import ActivateProjectForm from "../stories/Components/ActivateProjectForm/ActivateProjectForm";
import DocumentMgmtFolderContent from "../stories/Components/DocumentFolderContent/DocumentMgmtFolderContent";

import TimesheetOverview from "../Pages/TimeSheet/TimesheetOverview";
import MaintenanceListView from "../Pages/Maintenance/MaintenanceListView";
import FileOverview from "../Pages/Overviews/File/FileOverview";
import TimesheetHistory from "../Pages/Overviews/History/TimesheetHistory";
import PropertyRfi from "../Pages/Overviews/Property/PropertyRfi";
import SubmittalsOverview from "../Pages/Overviews/Submittals/SubmittalsOverview";
import TaskSingle from "../Pages/Overviews/TaskSingle";
import ProjectFinances from "../Pages/Project/ProjectFinances";
import ProjectRfi from "../Pages/Project/ProjectRfi";
import ProjectSubmittals from "../Pages/Project/ProjectSubmittals";
import RFIDetailView from "../Pages/RFI/RFIDetailView";
import ServiceRequest from "../Pages/ServiceRequest";
import ServiceRequestsList from "../Pages/ServiceRequestList";
import SubmittalsDetailView from "../Pages/Submittals/SubmittalsDetailView";
import SubmittalsCreateModal from "../stories/Components/Submittals/SubmittalsModal/SubmittalsCreateModal";
import SpacesMainList from "../Pages/Spaces/SpacesMainList";
import WorkflowOverview from "../Pages/Overviews/Workflow/WorkflowOverview";
import SingleWorkflowView from "../Pages/Workflows/WorkflowView";
import ReportSingle from "../Pages/Reports/ReportSingle";
import ContactUsPage from "../Pages/ContactUs";
import MyProfile from "../Pages/MyProfile";
import PageNotFound from "../Pages/Error/PageNotFound";
import SpaceSinglePage from "../Pages/Spaces/SpaceSinglePage";

/**
 * Dynamic breadcrumb component for a Asset to display the Asset's title
 */
function AssetTitleBreadcrumb({ match }) {
  const assetId = match?.params?.assetId;
  const { data: asset, isLoading } = useAssetById(assetId);

  if (assetId) {
    return isLoading ? "Loading..." : asset?.name ?? null;
  }

  return null;
}

/**
 * Dynamic breadcrumb component for a Property to display the Property's title
 */
function PropertyTitleBreadcrumb({ match }) {
  const propertyId = match?.params?.propertyId;
  const { data: property, isLoading } = usePropertyById(propertyId);

  if (propertyId) {
    return isLoading ? "Loading..." : property?.title ?? null;
  }

  return null;
}

/**
 * Dynamic breadcrumb component for a Location to display the Location's title
 */
function LocationTitleBreadcrumb({ match }) {
  const resourceKey = Object.keys(match?.params)[0];
  const resourceId = match?.params?.[resourceKey];
  const locationId = match?.params?.locationId;
  const { location, loading } = useBuildingLocation(
    resourceKey?.split("Id")[0],
    resourceId,
    locationId
  );
  if (resourceId && locationId) {
    return loading ? "Loading..." : location?.name ?? null;
  }

  return null;
}

/**
 * Dynamic breadcrumb component for a Space to display the Space's title
 */

// eslint-disable-next-line no-unused-vars
function SpaceTitleBreadcrumb({ match }) {
  const resourceKey = Object.keys(match?.params)[0];
  const resourceId = match?.params?.[resourceKey];
  const locationId = match?.params?.locationId;
  const spaceId = match?.params?.spaceId;
  const { space, loading } = useBuildingLocation(
    resourceKey?.split("Id")[0],
    resourceId,
    locationId,
    spaceId
  );
  if (resourceId && spaceId) {
    return loading ? "Loading..." : space?.name ?? null;
  }

  return null;
}

/**
 * Dynamic breadcrumb component for a Project to display the Project's title
 */
// eslint-disable-next-line no-unused-vars
function ProjectTitleBreadcrumb({ match }) {
  const projectId = match?.params?.projectId;
  const { data: project, isLoading } = useProjectById(projectId);
  if (projectId) {
    return isLoading ? "Loading..." : project?.name ?? null;
  }

  return null;
}

/**
 * Dynamic breadcrumb component for a Document folder to display the folder name
 */
// eslint-disable-next-line no-unused-vars
function DocumentFolderBreadcrumb({ match }) {
  const { data: docConfig } = useDocumentsConfiguration();
  const docTypeOptions =
    docConfig?.documents?.documentType.map((item) => {
      return {
        id: item.id,
        value: item.value,
        label: item.display,
      };
    }) ?? [];

  const folderName = match?.params?.folderName ?? {};
  if (folderName) {
    const foundValue = docTypeOptions.find((item) => item.value === folderName);
    if (folderName === "favorites") {
      return "Favorites";
    }
    if (folderName === "drafts") {
      return "Drafts";
    }
    if (foundValue) {
      return foundValue.label;
    }
    return (
      docTypeOptions.find((item) => item.id === folderName)?.label ?? folderName
    );
  }

  return null;
}

function DocumentViewTitleBreadcrumb({ match }) {
  const projectId = match?.params?.projectId;
  const { data: project, isLoading } = useProjectById(projectId, true);

  if (projectId) {
    return isLoading ? "Loading..." : project?.name ?? null;
  }

  return null;
}

// This file is responsible for establishing all the routing inside the mainContainer
// a route needs to be declared here in order to route to it

const homeRoutes = [
  {
    path: CALENDAR_PATH,
    Component: CalendarView,
    breadcrumb: "Calendar",
    exact: true,
  },
  {
    path: GET_CALENDAR_EVENT_PATH(),
    Component: CalendarView,
  },
  {
    path: MAINTENANCE_TASKS_PATH,
    Component: MaintenanceListView,
    breadcrumb: "Maintenance",
    exact: true,
  },
  {
    path: PROFILE_PATH(),
    Component: ContactView,
    exact: true,
  },
  {
    path: MY_PROFILE,
    Component: MyProfile,
    breadcrumb: "My Profile",
    exact: true,
  },
];

const accountAndAdminRoutes = [
  {
    path: MEMBERS_INVITE_PATH,
    Component: InviteMember,
    breadcrumb: "Invite Member",
  },
  {
    path: ROLES_PERMISSIONS_CREATE_PATH,
    Component: CreateRole,
    breadcrumb: "Create Role",
  },
  {
    path: ROLES_PERMISSIONS_EDIT_PATH(),
    Component: EditRole,
    breadcrumb: "Edit Role",
  },
  {
    path: ROLES_PERMISSIONS_VIEW_PATH(),
    Component: ViewRole,
    breadcrumb: "View Role",
  },
  {
    path: ADMIN_PATH,
    breadcrumb: null,
    Component: () => AccountAndAdminView(),
  },
];

const timesheetView = (user, financials) => {
  if (financials?.period?.start && financials?.payroll?.period)
    return [
      {
        path: REPORT_HOURS_PATH,
        Component: TimesheetOverview,
        breadcrumb: "Timesheets",
        exact: true,
      },
    ];
  return [];
};

const timesheetHistory = {
  path: GET_TIMESHEET_HISTORY_PATH(),
  Component: TimesheetHistory,
  breadcrumb: null,
};

const projectRoutes = [
  {
    path: PROJECTS_PATH,
    Component: () => <ProjectList />,
    breadcrumb: "Projects",
    exact: true,
  },
  {
    path: GET_PROJECTS_PATH(),
    Component: ProjectView,
    breadcrumb: ProjectTitleBreadcrumb,
    exact: true,
  },
  {
    path: GET_PROJECT_DETAILS_PATH(),
    Component: ProjectDetails,
    breadcrumb: null,
    exact: true,
  },
  {
    path: PROJECT_CALENDAR_PATH(),
    Component: CalendarView,
    breadcrumb: ProjectTitleBreadcrumb,
    exact: true,
  },
  {
    path: GET_PROJECT_BUDGET_CREATE_DOCUMENT_PATH(),
    Component: DocumentForm,
    breadcrumb: "Create",
    exact: true,
  },
  {
    path: GET_PROJECT_CREATE_DOCUMENT_PATH(),
    Component: DocumentForm,
    breadcrumb: "Create",
    exact: true,
  },
  {
    path: GET_PROJECT_SCHEDULE_PATH(),
    Component: ProjectSchedule,
    breadcrumb: "Schedule",
  },
  {
    path: GET_PROJECT_BUDGET_PATH(),
    Component: Budget,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_PROJECT_CREATE_BUDGET_PATH(),
    Component: BudgetForm,
    breadcrumb: "Budget",
    exact: true,
  },
  {
    path: GET_PROJECT_ACTIVATE_PATH(),
    Component: ActivateProjectForm,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_PROJECT_DOCUMENTS_PATH(),
    Component: ProjectDocuments,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_PROJECT_DOCUMENTS_DOC_PATH(),
    Component: DocumentViewContainer,
    breadcrumb: "Documents",
    exact: true,
  },
  {
    path: GET_PROJECT_DOCUMENTS_DOC_EDIT_PATH(),
    Component: DocumentViewContainer,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_PROJECT_DOCUMENTS_DOC_HISTORY_PATH(),
    Component: DocumentViewContainer,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_PROJECT_DOCUMENTS_DOC_ADDITIONAL_DOCS_PATH(),
    Component: DocumentViewContainer,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_PROJECT_EXPENSES_PATH(),
    Component: ProjectExpences,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_PROJECT_ASSET_EXPENSES_PATH(),
    Component: AssetExpences,
    breadcrumb: null,
    exact: true,
  },

  // For project workflows
  {
    path: GET_PROJECT_WORKFLOWS_PATH(),
    Component: ProjectWorkflows,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_PROJECT_WORKFLOWS_DRAFT_PATH(),
    Component: () => <ProjectWorkflows isDraftView />,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_PROJECT_WORKFLOW_VIEW_PATH(),
    Component: SingleWorkflowView,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_PROJECT_WORKFLOW_EDIT_PATH(),
    Component: EditWorkflowView,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_PROJECT_WORKFLOW_CREATE_PATH(),
    Component: CreateWorkflowForm,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_PROJECT_INVENTORY_PATH(),
    Component: Inventory,
    breadcrumb: "Inventory",
  },
  {
    path: GET_PROJECT_FINANCIALS_PATH(),
    Component: ProjectFinances,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_PROJECT_MEDIA_PATH(),
    Component: ProjectMedia,
    breadcrumb: "Media",
    exact: true,
  },
  /**
   * Location Asset specific paths
   */
  {
    path: GET_LOCATION_ASSET_VIEW_PATH.project(),
    Component: ProjectAssetDetails,
    breadcrumb: LocationTitleBreadcrumb,
    exact: true,
  },
  {
    path: GET_LOCATION_ASSET_DOCUMENTS_PATH.project(),
    Component: AssetDocuments,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_LOCATION_ASSET_EXPENSES_PATH.project(),
    Component: AssetExpences,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_LOCATION_ASSET_FINANCIALS_PATH.project(),
    Component: AssetFinances,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_LOCATION_ASSET_MAINTENANCE_PATH.project(),
    Component: AssetMaintenance,
    breadcrumb: null,
    exact: true,
  },

  {
    path: GET_SPACE_VIEW_PATH.project(),
    Component: SpaceSinglePage,
    breadcrumb: null,
    exact: true,
  },
  // {
  //   path: GET_PROJECT_SPACE_DOCUMENT_PATH(),
  //   Component: ProjectSpaceDocuments,
  //   breadcrumb: null,
  //   exact: true,
  // },
  /**
   * Location'n Space Asset specific paths
   */
  {
    path: GET_LOCATION_SPACE_ASSET_VIEW_PATH.project(),
    Component: ProjectAssetDetails,
    breadcrumb: SpaceTitleBreadcrumb,
    exact: true,
  },
  {
    path: GET_LOCATION_SPACE_ASSET_DOCUMENTS_PATH.project(),
    Component: AssetDocuments,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_LOCATION_SPACE_ASSET_EXPENSES_PATH.project(),
    Component: AssetExpences,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_LOCATION_SPACE_ASSET_FINANCIALS_PATH.project(),
    Component: AssetFinances,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_LOCATION_SPACE_ASSET_MAINTENANCE_PATH.project(),
    Component: AssetMaintenance,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_PROJECT_ASSET_PATH(),
    Component: ProjectAssetDetails,
    breadcrumb: "Asset Details",
    exact: true,
  },
  {
    path: GET_PROJECT_ASSET_MAINTENANCE_PATH(),
    Component: AssetMaintenance,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_PROJECT_ASSET_DOCUMENTS_PATH(),
    Component: AssetDocuments,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_PROJECT_ASSET_FINANCIALS_PATH(),
    Component: AssetFinances,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_PROJECT_SUBMITTALS_PATH(),
    Component: ProjectSubmittals,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_PROJECT_SUBMITTAL_PATH(),
    Component: SubmittalsDetailView,
    breadcrumb: "Submittal Details",
    exact: true,
  },
  // {
  //   path: SUBMITTALS_TABLE_PATH,
  //   Component: SubmittalsOverview,
  //   breadcrumb: "Submittals",
  //   exact: true,
  // },
  // {
  //   path: GET_SUBMITTAL_DETAILS_PATH(),
  //   Component: SubmittalsDetailView,
  //   breadcrumb: "Submittals",
  //   exact: true,
  // },
  // {
  //   path: "/submittals/create/new",
  //   Component: SubmittalsCreateModal,
  //   breadcrumb: null,
  //   exact: true,
  // },

  // Project RFI
  {
    path: GET_PROJECT_RFI_PATH(),
    Component: ProjectRfi,
    breadcrumb: "RFI",
    exact: true,
  },
];

const assetRoutes = [
  {
    path: ASSET_TABLE_PATH,
    Component: AssetList,
    breadcrumb: "Assets",
    exact: true,
  },
  {
    path: GET_ASSET_VIEW_PATH(),
    Component: AssetDetails,
    breadcrumb: AssetTitleBreadcrumb,
    exact: true,
  },
  {
    path: GET_ASSET_DOCUMENT_CREATE_PATH(),
    Component: DocumentForm,
    breadcrumb: "Create Document",
    exact: true,
  },
  {
    path: GET_ASSET_DOCUMENT_PATH(),
    Component: AssetDocuments,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_ASSET_DOCUMENTS_DOC_PATH(),
    Component: DocumentViewContainer,
    breadcrumb: "Documents",
    exact: true,
  },
  {
    path: GET_ASSET_DOCUMENTS_DOC_EDIT_PATH(),
    Component: DocumentViewContainer,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_ASSET_DOCUMENTS_DOC_HISTORY_PATH(),
    Component: DocumentViewContainer,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_ASSET_EXPENSES_PATH(),
    Component: AssetExpences,
    breadcrumb: null,
  },
  {
    path: GET_ASSET_FINANCES_PATH(),
    Component: AssetFinances,
    breadcrumb: null,
  },
  {
    path: GET_ASSET_MAINTENANCE_PATH(),
    Component: AssetMaintenance,
    breadcrumb: null,
  },
];

const propertyRoutes = [
  {
    path: PROPERTY_TABLE_PATH,
    Component: PropertyList,
    breadcrumb: "Properties",
    exact: true,
  },
  {
    path: PROPERTY_CALENDAR_PATH(),
    Component: CalendarView,
    breadcrumb: PropertyTitleBreadcrumb,
    exact: true,
  },

  // For property workflows
  {
    path: GET_PROPERTY_WORKFLOW_EDIT_PATH(),
    Component: EditWorkflowView,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_PROPERTY_WORKFLOW_VIEW_PATH(),
    Component: SingleWorkflowView,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_PROPERTY_WORKFLOWS_DRAFT_PATH(),
    Component: () => <PropertyWorkflows isDraftView />,
    breadcrumb: "Workflows",
    exact: true,
  },
  {
    path: GET_PROPERTY_WORKFLOW_CREATE_PATH(),
    Component: CreateWorkflowForm,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_PROPERTY_WORKFLOWS_PATH(),
    Component: PropertyWorkflows,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_PROPERTY_MAINTENANCE_PATH(),
    Component: PropertyMaintenance,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_PROPERTY_VIEW_PATH(),
    Component: PropertySinglePage,
    breadcrumb: PropertyTitleBreadcrumb,
    exact: true,
  },
  {
    path: GET_PROPERTY_DOCUMENT_CREATE_PATH(),
    Component: DocumentForm,
    breadcrumb: "Create Document",
    exact: true,
  },
  {
    path: GET_PROPERTY_DOCUMENT_PATH(),
    Component: PropertyDocuments,
    breadcrumb: null,
    exact: true,
  },

  {
    path: GET_PROPERTY_DOCUMENTS_DOC_PATH(),
    Component: DocumentViewContainer,
    breadcrumb: "Documents",
    exact: true,
  },
  {
    path: GET_PROPERTY_DOCUMENTS_DOC_EDIT_PATH(),
    Component: DocumentViewContainer,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_PROPERTY_DOCUMENTS_DOC_HISTORY_PATH(),
    Component: DocumentViewContainer,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_PROPERTY_ASSETS_PATH(),
    Component: PropertyAssets,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_LOCATION_ASSETS_PATH.property(),
    Component: PropertyLocationAssets,
    breadcrumb: null,
    exact: true,
  },

  /**
   * Location Asset specific paths
   */
  {
    path: GET_LOCATION_ASSET_VIEW_PATH.property(),
    Component: AssetDetails,
    breadcrumb: LocationTitleBreadcrumb,
    exact: true,
  },
  {
    path: GET_LOCATION_ASSET_DOCUMENTS_PATH.property(),
    Component: AssetDocuments,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_LOCATION_ASSET_EXPENSES_PATH.property(),
    Component: AssetExpences,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_LOCATION_ASSET_FINANCIALS_PATH.property(),
    Component: AssetFinances,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_LOCATION_ASSET_MAINTENANCE_PATH.property(),
    Component: AssetMaintenance,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_SPACE_VIEW_PATH.property(),
    Component: SpaceSinglePage,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_LOCATION_SPACE_ASSETS_PATH.property(),
    Component: PropertySpaceAssets,
    breadcrumb: SpaceTitleBreadcrumb,
    exact: true,
  },

  /**
   * Location'n Space Asset specific paths
   */
  {
    path: GET_LOCATION_SPACE_ASSET_VIEW_PATH.property(),
    Component: AssetDetails,
    breadcrumb: SpaceTitleBreadcrumb,
    exact: true,
  },
  {
    path: GET_LOCATION_SPACE_ASSET_DOCUMENTS_PATH.property(),
    Component: AssetDocuments,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_LOCATION_SPACE_ASSET_EXPENSES_PATH.property(),
    Component: AssetExpences,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_LOCATION_SPACE_ASSET_FINANCIALS_PATH.property(),
    Component: AssetFinances,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_LOCATION_SPACE_ASSET_MAINTENANCE_PATH.property(),
    Component: AssetMaintenance,
    breadcrumb: null,
    exact: true,
  },

  {
    path: GET_PROPERTY_ASSET_PATH(),
    Component: AssetDetails,
    breadcrumb: "Asset Details",
    exact: true,
  },

  {
    path: GET_PROPERTY_ASSET_DOCUMENTS_PATH(),
    Component: AssetDocuments,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_PROPERTY_ASSET_FINANCIALS_PATH(),
    Component: AssetFinances,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_PROPERTY_ASSET_MAINTENANCE_PATH(),
    Component: AssetMaintenance,
    breadcrumb: null,
    exact: true,
  },

  {
    path: GET_PROPERTY_ASSET_EXPENSES_PATH(),
    Component: AssetExpences,
    breadcrumb: null,
    exact: true,
  },

  // Property RFI
  {
    path: GET_PROPERTY_RFI_PATH(),
    Component: PropertyRfi,
    breadcrumb: "RFI",
    exact: true,
  },
];

const spaceRoutes = [
  {
    path: SPACES_PATH,
    Component: SpacesMainList,
    breadcrumb: "Space List",
    exact: true,
  },
];

const contactRoutes = [
  {
    path: GET_PROFILE_PATH(),
    Component: ContactView,
    exact: true,
  },
  {
    path: CONTACTS_PATH,
    Component: ContactList,
    breadcrumb: "Contacts",
    exact: true,
  },
  {
    path: CONTACT_CREATION_PATH,
    Component: CreateContact,
    breadcrumb: "Create Contact",
    exact: true,
  },
  {
    path: GET_CONTACT_PATH(),
    Component: ContactView,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_CONTACT_EDIT_PATH(),
    Component: EditContact,
    breadcrumb: "Edit Contact",
    exact: true,
  },
];

const memberRoutes = [
  {
    path: GET_MEMBER_PATH(),
    Component: ContactView,
    breadcrumb: "Member Management",
    exact: true,
  },
];

const documentRoutes = [
  {
    path: DOCUMENTS_PATH,
    Component: Documents,
    breadcrumb: "Documents",
    exact: true,
  },
  {
    path: CREATE_DOCUMENTS_PATH,
    Component: DocumentForm,
    breadcrumb: "Create Document",
    exact: true,
  },
  {
    path: GET_DOCUMENT_VIEW(),
    Component: Documents,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_DOCUMENT_PATH(),
    Component: DocumentViewContainer,
    breadcrumb: DocumentViewTitleBreadcrumb,
    exact: true,
  },
  {
    path: GET_DOCUMENT_EDIT_PATH(),
    Component: DocumentViewContainer,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_DOCUMENT_HISTORY_PATH(),
    Component: DocumentViewContainer,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_DOCUMENT_FOLDER(),
    Component: Documents,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_DOCUMENT_FOLDER_PATH(),
    Component: DocumentMgmtFolderContent,
    breadcrumb: DocumentFolderBreadcrumb,
    exact: true,
  },
  {
    path: GET_DOCUMENT_FOLDER_FILE_PATH(),
    Component: DocumentViewContainer,
    breadcrumb: "",
    exact: true,
  },
  {
    path: GET_DOCUMENT_FOLDER_FILE_EDIT_PATH(),
    Component: DocumentViewContainer,
    breadcrumb: null,
    exact: true,
  },
  {
    path: GET_DOCUMENT_FOLDER_FILE_HISTORY_PATH(),
    Component: DocumentViewContainer,
    breadcrumb: null,
    exact: true,
  },
];

const submittalRoutes = [
  {
    path: SUBMITTALS_TABLE_PATH,
    Component: SubmittalsOverview,
    breadcrumb: "Submittals",
    exact: true,
  },
  {
    path: GET_SUBMITTAL_DETAILS_PATH(),
    Component: SubmittalsDetailView,
    breadcrumb: "Submittals",
    exact: true,
  },
  {
    path: "/submittals/create/new",
    Component: SubmittalsCreateModal,
    breadcrumb: null,
    exact: true,
  },
];

const workflowRoutes = [
  // new wf path
  {
    path: WORKFLOW_PATH,
    Component: WorkflowOverview,
    breadcrumb: "Workflow",
    exact: true,
  },
  {
    path: WORKFLOWS_DRAFTS_PATH,
    Component: () => <WorkflowsOverview isDraftView />,
    breadcrumb: "Workflows",
    exact: true,
  },
  {
    path: WORKFLOWS_ADD_NEW_PATH,
    Component: CreateWorkflowForm,
    breadcrumb: "Create Workflow",
  },
  // new WF view details path
  {
    path: WORKFLOW_DETAILS_PATH,
    Component: SingleWorkflowView,
    breadcrumb: "Workflow",
    exact: true,
  },
  {
    path: WORKFLOWS_EDIT_PATH(),
    Component: EditWorkflowView,
    breadcrumb: "Edit Workflow",
    exact: true,
  },
];
const entitiesRoutes = [
  {
    path: ENTITIES_CREATION_PATH,
    Component: EntityCreation,
    breadcrumb: "Create Entity",
    exact: true,
  },
];

const profileRoutes = [
  {
    path: GET_PROFILE_EDIT_PATH(),
    Component: UserProfileEdit,
    breadcrumb: "Edit Profile",
    exact: true,
  },
];

const reportRoutes = [
  {
    path: REPORT_PATH,
    Component: ReportList,
    breadcrumb: "Reports",
    exact: true,
  },
  {
    path: REPORT_SINGLE_PATH,
    Component: ReportSingle,
    breadcrumb: "Reports",
    exact: true,
  },
];

const sopRoutes = [
  {
    path: SOP_TABLE_PATH,
    Component: SopList,
    breadcrumb: "SOPs",
    exact: true,
  },
  {
    path: GET_SOP_DETAILS_PATH(),
    Component: SopDetails,
    breadcrumb: "Sop Details",
    exact: true,
  },
];

const taskRoutes = [
  {
    path: TASK_TABLE_PATH,
    Component: TaskList,
    breadcrumb: "Tasks",
    exact: true,
  },
  {
    path: TASK_SINGLE_PATH,
    Component: TaskSingle,
    breadcrumb: null,
    exact: true,
  },
];

const serviceRequestRoutes = [
  {
    path: TICKETS_PATH,
    Component: ServiceRequestsList,
    breadcrumb: "Service Requests",
    exact: true,
  },
  {
    path: TICKET_DETAILS_PATH,
    Component: ServiceRequest,
    breadcrumb: null,
    exact: true,
  },
];

const rfiRoutes = [
  {
    path: RFI_PATH,
    Component: RfiList,
    breadcrumb: "RFI",
    exact: true,
  },
  {
    path: GET_RFI_DETAILS_PATH(),
    Component: RFIDetailView,
    breadcrumb: "RFI",
    exact: true,
  },
];

const expenseRoutes = (user) => {
  let permissionedRoutes = [];
  if (user?.hasPermission("expense", "can_read")) {
    permissionedRoutes = permissionedRoutes?.concat([
      {
        path: EXPENSES_PATH,
        Component: ExpenseList,
        breadcrumb: "Expenses",
        exact: true,
      },

      {
        path: GET_PROPERTY_EXPENSES_PATH(),
        Component: PropertyExpences,
        breadcrumb: "Expenses",
        exact: true,
      },
    ]);
  }
  return permissionedRoutes;
};

const invoiceRoutes = [
  {
    path: INVOICES_CREATE_PATH,
    Component: InvoiceForm,
    breadcrumb: "Invoices",
    exact: true,
  },
];

const fileRoutes = [
  {
    path: FILES_OVERVIEW_PATH,
    Component: FileOverview,
    breadcrumb: "Files",
    exact: true,
  },
];

const pageNotFoundRoutes = [
  {
    path: PAGE_NOT_FOUND,
    Component: PageNotFound,
  },
  {
    path: "*",
    Component: PageNotFound,
  },
];

export default (user, financials) => {
  const routes = [
    ...fileRoutes,
    ...submittalRoutes,
    ...homeRoutes,
    ...projectRoutes,
    ...assetRoutes,
    ...propertyRoutes,
    ...spaceRoutes,
    ...timesheetView(user, financials),
    timesheetHistory,
    ...contactRoutes,
    ...documentRoutes,
    ...accountAndAdminRoutes,
    ...workflowRoutes,
    ...entitiesRoutes,
    ...memberRoutes,
    ...profileRoutes,
    ...reportRoutes,
    ...sopRoutes,
    ...taskRoutes,
    ...rfiRoutes,
    ...invoiceRoutes,
    ...expenseRoutes(user),
    ...serviceRequestRoutes,
    {
      path: FINANCIALS_ACTUAL_PATH,
      Component: ActualFinancials,
      breadcrumb: "Financials",
    },
    {
      path: INVENTORIES_PATH,
      Component: Inventory,
      breadcrumb: "Inventory",
    },
    {
      path: SOP_PATH,
      Component: () => (
        <>
          <SiteHeader title="Standard operating procedures" />
          <p>SOPs go here</p>
        </>
      ),
      breadcrumb: "SOPs",
    },
    {
      path: ENTITIES_PATH,
      Component: EntitiesOverview,
      breadcrumb: "Entities",
    },
    {
      path: MARKETPLACE_PATH,
      Component: () => (
        <>
          <SiteHeader title="Marketplace" />
          <p>Marketplace goes here</p>
        </>
      ),
      breadcrumb: "Marketplace",
    },
    {
      path: CONTACT_US_PATH,
      Component: ContactUsPage,
      breadcrumb: "Contact us",
    },
    {
      path: HELP_PATH,
      Component: HelpPage,
      breadcrumb: "Help",
    },
    // Must be placed at the end
    ...pageNotFoundRoutes,
  ];

  return routes;
};

import React from "react";
import AssetInfoForm from "../AssetInfoForm/AssetInfoForm";
import useAssetModal from "./useAssetModal";
import AddButton from "../Buttons/AddButton";
import SaveButton from "../Buttons/SaveButton";
import ModalWrapper from "../EsModal/ModalWrapper";

const CreateAssetModal = ({ modalData }) => {
  const {
    handleRequestModalClose,
    handleCreate,
    isInputValid,
    isSaving,
    showConfirm,
    currentUser,
    asset,
    dispatch,
    catOptions,
    subCatOptionsMap,
    associatedResource,
    handleSetAssociation,
    handleKeyUp,
    inputRefs,
    filesToUpload,
    handleFilesAdded,
    handleFilesUpdated,
    handleFilesUploaded,
    removeAttachedFile,
    windowWidth,
    spacesDD,
    newAttributes,
    setNewAttributes,
    onRequestClose,
    disableAssociation,
    subAssociation,
    isFormReset,
    setIsFormReset,
    tmpAvatarImg,
    setTmpAvatarImg,
  } = useAssetModal({ modalData });
  return (
    <ModalWrapper
      width="963px"
      title="Create Asset"
      resourceName="Asset"
      modalData={modalData}
      formData={{ ...asset, tmpAvatarImg }}
      primaryButtonOnClick={handleCreate}
      className="px-8"
      buttons={() => (
        <div className="relative h-fit w-full mt-4 flex flex-row gap-4">
          <SaveButton
            onClick={handleCreate}
            disabled={isSaving || !isInputValid}
          />
          <AddButton
            buttonTitle="Create & Add Another"
            keepIcon
            onClick={() => handleCreate(true)}
            disabled={isSaving || !isInputValid}
          />
          <SaveButton
            saveButtonTitle="Close"
            onClick={handleRequestModalClose}
          />
        </div>
      )}
      onRequestClose={onRequestClose}
      showConfirm={showConfirm}
      loading={isSaving}
      disabled={isSaving}
    >
      <AssetInfoForm
        currentUser={currentUser}
        asset={asset}
        dispatch={dispatch}
        tmpAvatarImg={tmpAvatarImg}
        setTmpAvatarImg={setTmpAvatarImg}
        categoryOptionsList={catOptions}
        subCatOptionsMap={subCatOptionsMap}
        associatedResource={associatedResource}
        setAssociatedResource={handleSetAssociation}
        disableAssociation={disableAssociation}
        handleKeyUp={handleKeyUp}
        inputRefs={inputRefs}
        isFormReset={isFormReset}
        setIsFormReset={setIsFormReset}
        filesToUpload={filesToUpload}
        handleFilesAdded={handleFilesAdded}
        handleFilesUpdated={handleFilesUpdated}
        handleFilesUploaded={handleFilesUploaded}
        removeAttachedFile={removeAttachedFile}
        windowWidth={windowWidth}
        spacesDD={spacesDD}
        newAttributes={newAttributes}
        setNewAttributes={setNewAttributes}
        subAssociation={subAssociation}
      />
    </ModalWrapper>
  );
};

export default CreateAssetModal;

import React from "react";
import PrimaryButton from "../../stories/Components/Buttons/PrimaryButton";
import estateSpaceLogo from "../../stories/assets/images/estatespace-logo-new.png";

export class ErrorBoundary extends React.Component {
  static redirectToLogin() {
    window.location.assign("/");
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error) {
    // TODO (josymar) Change this for a propper logger
    // eslint-disable-next-line no-console
    console.log("📛 ErrorBoundary", error);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div className="flex items-center justify-center mt-72">
          <div className="flex flex-col max-w-md">
            <img
              alt="EstateSpace Logo"
              src={String(estateSpaceLogo)}
              className="w-full"
            />

            <p className="mt-8 text-base tracking-widest font-light mx-8 text-center">
              The app ran into a problem and could not continue. We apologise
              for any inconvenience this has caused! Press the button below to
              restart the app and sign back in.
            </p>

            <p className="mt-8 text-base tracking-widest font-light mx-8 text-center">
              Please contact us if this issue persists.
            </p>

            <div className="ml-auto mr-auto max-w-md">
              <div className="w-full px-6">
                <PrimaryButton
                  className="w-full mt-8 tracking-widest text-base"
                  title="REFRESH APP"
                  onClick={() => ErrorBoundary.redirectToLogin()}
                  noMaxWidth
                />
              </div>
            </div>
          </div>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;

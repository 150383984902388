import PropTypes from "prop-types";
import React from "react";
import { PRIMARY_DARK_GREEN } from "../../../constants";
import PlusCircleButton from "../Buttons/PlusCircleButton/PlusCircleButton";
import "../Spinner/styles.css";
import DistroListSelectMembers from "../DistroList/DistroListSelectMembers";
import EntityPillList from "../EntityPillList";
import capitalizeFirstLetter from "../../../helpers/Utilities/capitalizeFirstLetter";
import useAddSpaceAssetWidget from "./useAddSpaceAssetWidget";

const labelWidth = { minWidth: "12.5rem" };

const AddSpacesAssetsWidget = ({
  form,
  setForm,
  handleSelect,
  handleRemove,
  resource,
  isEditing,
  isModal,
  noTitle,
  checkbox,
  currentTask,
  setCurrentTask,
  disabled,
  disableSelectAll,
}) => {
  const {
    currentEntities,
    addButtonTitle,
    distroListTitle,
    searchPlaceHolder,
    formattedList,
    showSelect,
    setShowSelect,
    showAddButton,
    selectedMembersDDL,
    handleCompleteCheck,
  } = useAddSpaceAssetWidget({
    form,
    setForm,
    resource,
    isEditing,
    currentTask,
    setCurrentTask,
    disabled,
  });

  return (
    <div className="flex flex-col">
      <div
        className={`flex flex-${
          isModal || (!isEditing && currentEntities?.length !== 0)
            ? "col"
            : "row"
        }`}
      >
        {!noTitle && (
          <p style={labelWidth} className="text-gray-400 text-md font-semibold">
            {capitalizeFirstLetter(resource)}
          </p>
        )}
        {!isEditing && !currentEntities?.length && (
          <p className="text-sm text-gray-450">
            {`There are no ${resource} saved to this task`}
          </p>
        )}
        <div className="flex flex-col">
          <div>
            {currentEntities?.length !== 0 && (
              <EntityPillList
                list={currentEntities}
                handleClick={handleRemove}
                hideTitle
                isEditing={isEditing}
                isModal={isModal}
                checkbox={checkbox}
                disabled={disabled}
                handleCompleteCheck={handleCompleteCheck}
                resource={resource}
                disableSelectAll={disableSelectAll}
              />
            )}
            {showSelect && (
              <DistroListSelectMembers
                members={formattedList}
                setDistroSelectOpen={setShowSelect}
                selectedMembers={selectedMembersDDL}
                onAdd={(val) => handleSelect(val?.reference)}
                onRemove={(val) => handleRemove(val)}
                title={distroListTitle}
                placeHolder={searchPlaceHolder}
              />
            )}
          </div>
          <div>
            {showAddButton && (
              <PlusCircleButton
                title={addButtonTitle}
                className={`${
                  currentEntities?.length !== 0 || isModal ? "mt-4" : "mt-0"
                }`}
                onClick={() => setShowSelect(true)}
                style={{ color: PRIMARY_DARK_GREEN }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

AddSpacesAssetsWidget.propTypes = {
  form: PropTypes.shape({
    invitees: PropTypes.arrayOf(PropTypes.shape({})),
    association: PropTypes.string,
    spaces: PropTypes.arrayOf(PropTypes.string),
    assets: PropTypes.arrayOf(PropTypes.string),
  }),
  handleRemove: PropTypes.func,
  handleSelect: PropTypes.func,
  resource: PropTypes.string,
  isEditing: PropTypes.bool,
  isModal: PropTypes.bool,
  noTitle: PropTypes.bool,
  checkbox: PropTypes.bool,
  setCurrentTask: PropTypes.func,
  currentTask: PropTypes.shape({
    id: PropTypes.string,
  }),
  setForm: PropTypes.func,
  disabled: PropTypes.bool,
  disableSelectAll: PropTypes.bool,
};

AddSpacesAssetsWidget.defaultProps = {
  form: {},
  handleRemove: () => {},
  handleSelect: () => {},
  resource: "",
  isEditing: undefined,
  isModal: undefined,
  noTitle: undefined,
  checkbox: undefined,
  setCurrentTask: () => {},
  currentTask: {},
  setForm: PropTypes.func,
  disabled: false,
  disableSelectAll: undefined,
};

export default AddSpacesAssetsWidget;

import { PropertyAPI } from "@griffingroupglobal/eslib-api";

const editPropertyHandler = async (props) => {
  const { originalItem, editedItem } = props;
  const { id } = originalItem;

  const response = await PropertyAPI.patch(id, editedItem, originalItem);
  return response.data;
};

export default editPropertyHandler;

import { propertyKeys } from "../../hooks/properties";

/**
 * Add new property to the properties overview cache store
 * @param {Object} params
 * @param {QueryClient} params.queryClient - query client object
 * @param {Object} params.property - new property created
 */
const addToPropertyOverviewStore = ({ queryClient, property }) => {
  const queryKey = propertyKeys.overview();

  const propertiesCache = queryClient.getQueryData(queryKey);

  if (!propertiesCache) {
    return;
  }

  // Check if property already exists in the cache.
  const foundProperty = propertiesCache.propertiesDD.find(
    (existing) => existing.id === property.id
  );

  if (foundProperty) {
    return;
  }

  queryClient.setQueryData(queryKey, (oldData) => {
    const propertiesDict = oldData?.propertiesDict ?? {};
    const propertiesDD = oldData?.propertiesDD ?? [];

    const formattedPropertyForDD = {
      id: property.id,
      value: property.reference,
      label: property.title,
    };

    const formatttedPropertyForDict = {
      [property.reference]: property,
    };

    return {
      propertiesDict: { ...propertiesDict, formatttedPropertyForDict },
      propertiesDD: [...propertiesDD, formattedPropertyForDD],
    };
  });
};

export default addToPropertyOverviewStore;

import { useReducer } from "react";

/**
 * @deprecated
 */

const defaultState = {
  files: [],
  videos: [],
  name: undefined,
  area: {
    value: undefined,
  },
  images: [],
  spaces: [],
  additionalInfo: [],
};

const reducer = (location, action) => {
  switch (action.type) {
    case "reset": {
      return action.location ?? defaultState;
    }
    case "addImage": {
      return {
        ...location,
        images: [
          ...(location.images ?? []),
          {
            ...action.image,
            isPrimary: location?.images?.length < 1,
          },
        ],
      };
    }

    case "deleteImage":
      return {
        ...location,
        images: location.images?.filter(
          (image) => image.file !== action.imageRef
        ),
      };
    case "deleteImages": {
      const imageRefs = action.images?.map((ref) => ref?.file);
      return {
        ...location,
        images: location.images?.filter(
          (image) => imageRefs.indexOf(image?.file) < 0
        ),
      };
    }
    case "changeIsPrimary": {
      return {
        ...location,
        images: location.images?.map((image) => {
          if (image.file === action.id) {
            return {
              ...image,
              isPrimary: action.isPrimary,
            };
          }
          return {
            ...image,
            isPrimary: false,
          };
        }),
      };
    }
    case "overview":
      return { ...location, name: action.name, area: action.area };
    case "type": {
      const newInfo = location.additionalInfo?.filter(
        (m) => m.detail !== "Types"
      );
      if (action.value) {
        newInfo.push({
          detail: "Types",
          value: action.value,
          unit: action.unit,
        });
      }

      return {
        ...location,
        additionalInfo: newInfo,
        category: action.category,
        subcategory: action.subcategory,
      };
    }
    case "measurement": {
      if (action?.operation === "remove") {
        return {
          ...location,
          additionalInfo: location.additionalInfo?.filter(
            (item) => item.id !== action.id
          ),
        };
      }
      const elementIndex = location.additionalInfo.findIndex(
        (item) => item.id === action.id
      );
      if (elementIndex > -1) {
        return {
          ...location,
          additionalInfo: location.additionalInfo?.map((item, idx) => {
            if (idx === elementIndex) {
              return {
                id: action.id,
                ...(action.value && { value: action.value }),
                ...(action.optionId && { optionId: action.optionId }),
                ...(action.unitId && { unitId: action.unitId }),
              };
            }
            return item;
          }),
        };
      }
      return {
        ...location,
        additionalInfo: [
          ...location.additionalInfo,
          {
            id: action.id,
            ...(action.value && { value: action.value }),
            ...(action.optionId && { optionId: action.optionId }),
            ...(action.unitId && { unitId: action.unitId }),
          },
        ],
      };
    }
    default:
      return {};
  }
};

export default (initialState) => {
  return useReducer(reducer, initialState ?? defaultState);
};

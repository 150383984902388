import { SpaceAPI } from "@griffingroupglobal/eslib-api";
import sortByProperty from "../../../../helpers/Utilities/sortByProperty";

const fetchSpacesHandler = async (queryString = "", signal) => {
  const { data } = await SpaceAPI.getWOP(queryString, { signal });

  if (data?.entries) {
    const spacesArray = data.entries.map((item) => item.resource);
    // Sort properties by ES rule
    return sortByProperty(spacesArray, "name");
  }

  return [];
};

export default fetchSpacesHandler;

import { propertyKeys } from "../../hooks/properties";

const deletePropertyListStore = ({ queryClient, id }) => {
  const queryKey = propertyKeys.all;
  const propertiesArr = queryClient.getQueryData(queryKey) ?? [];

  const index = propertiesArr.findIndex((property) => property.id === id);

  if (index !== -1) {
    const newPropertiesArr = [
      ...propertiesArr.slice(0, index),
      ...propertiesArr.slice(index + 1),
    ];

    queryClient.setQueryData(queryKey, newPropertiesArr);
  }
};

export default deletePropertyListStore;

import React from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import cntl from "cntl";
import FormAvatar from "../Avatar/FormAvatar";
import Dropdown from "../Dropdown/Dropdown";
import Widget from "../Widget/Widget";
import Input from "../Input/Input";
import OwnedBy from "../OwnedBy";

const fieldTitleCN = cntl`
text-gray-450 
font-bold
text-sm
`;

const container = "flex flex-col gap-5";
const rowCN = "flex flex-row w-full gap-5";
const rowItemCN = "flex flex-row w-full gap-4";
const ResourceInformationForm = ({
  isResourceNameUnique,
  resource,
  resourceInformation,
  resourceTypes,
  handleInput,
  handleEnter,
  onForwardRef,
  timezoneOptions,
  tmpAvatarImg,
  setTmpAvatarImg,
}) => {
  return (
    <>
      <div className="flex items-center gap-4 mb-4">
        <FormAvatar
          isEditing
          image={resource?.primaryImage}
          company
          tmpAvatarImg={tmpAvatarImg}
          setTmpAvatarImg={setTmpAvatarImg}
        />
        <span className={fieldTitleCN}>Add Image</span>
      </div>

      <Widget overflow draggable={false} title={null} className="mb-4 pt-2">
        <p className="ESTitle mb-4">{resourceInformation}</p>

        <div className={container}>
          <div className={rowCN}>
            <div className={rowItemCN}>
              <Input
                label="Name"
                inputClassName="ESInput"
                labelClassName="ESInputLabel mb-2"
                mainWrapperClassName="w-full"
                placeholder="Name"
                id="property-name"
                value={resource.title}
                onChange={(val) => handleInput("title", val)}
                handleEnter={handleEnter}
                forwardedRef={(el) => onForwardRef(`property-name`, el)}
                validation={yup
                  .string()
                  .trim()
                  .required()
                  .test("", "Title must be unique", () => isResourceNameUnique)}
                disableClear
                showValidationErrorAtBottom
              />
            </div>
            <div className={rowItemCN}>
              <Dropdown
                label="Type"
                labelClassName="ESInputLabel mb-2"
                options={resourceTypes}
                value={resourceTypes?.find(
                  (opt) => opt.value === resource.propertyType
                )}
                onChange={({ value: val }) => handleInput("propertyType", val)}
                // space in the .required() to show error border but not large text that throws off styles
                validation={yup.mixed().required(" ")}
                showValidationErrorAtBottom
                name="property-type"
                forwardedRef={(el) => onForwardRef("property-type", el)}
                onKeyUp={handleEnter}
                placeholder="Select"
              />
            </div>
          </div>

          <div className={rowCN}>
            <div className={rowItemCN}>
              <Dropdown
                label="Timezone"
                labelClassName="ESInputLabel mb-2"
                options={timezoneOptions}
                value={resource?.timezone}
                onChange={(val) => handleInput("timezone", val?.label)}
                // space in the .required() to show error border but not large text that throws off styles
                validation={yup.mixed().required(" ")}
                name="property-timezone"
                forwardedRef={(el) => onForwardRef("property-type", el)}
                onKeyUp={handleEnter}
                placeholder={resource?.timezone ?? "Select"}
              />
            </div>
            <div className={rowItemCN}>
              <OwnedBy
                editing
                ownedBy={resource?.ownedBy}
                onChange={(val) => handleInput("ownedBy", val)}
                showLabel
              />
            </div>
          </div>

          <div className={rowCN}>
            <div className={rowItemCN}>
              <Input
                label="Description"
                labelClassName="ESInputLabel mb-2"
                inputClassName="ESInput"
                mainWrapperClassName="min-w-full"
                placeholder="Description"
                id="property-description"
                value={resource.description}
                onChange={(val) => handleInput("description", val)}
                handleEnter={handleEnter}
                forwardedRef={(el) => onForwardRef(`property-description`, el)}
                validation={yup.string()}
                disableClear
              />
            </div>
            <div className={rowItemCN} />
          </div>
        </div>
      </Widget>
    </>
  );
};

ResourceInformationForm.propertyTypes = {
  resource: PropTypes.shape({}),
  resourceInformation: PropTypes.string,
  resourceTypes: PropTypes.arrayOf(PropTypes.shape()),
  handleInput: PropTypes.func,
  handleEnter: PropTypes.func,
  changeAvatar: PropTypes.func,
  isResourceNameUnique: PropTypes.bool,
};

ResourceInformationForm.defaultProps = {
  resource: {},
  resourceInformation: "[Resource Information]",
  resourceTypes: [],
  handleInput: undefined,
  handleEnter: undefined,
  changeAvatar: undefined,
  isResourceNameUnique: true,
};

export default ResourceInformationForm;

import { useCallback, useEffect, useState } from "react";
import { USER_SETTINGS_KEYS } from "../../../constants";

const useAddViewAndFilterPopoverData = ({
  isOpen,
  isQuickView = false,
  resourceName,
  userSettings,
  updateSettings,
  allColumns,
}) => {
  const [templateName, setTemplateName] = useState("");
  const [checkedColumns, setCheckedColumns] = useState({});

  useEffect(() => {
    // Clean up checked columns when component unmounts or isOpen changes
    return () => {
      setCheckedColumns({});
    };
  }, [isOpen]);

  // Use useEffect to set default checked columns when the component mounts
  useEffect(() => {
    const defaultChecked = {};

    allColumns.forEach((column) => {
      if (column.getToggleHiddenProps().checked) {
        defaultChecked[column.id] = true;
      }
    });
    setCheckedColumns((prevChecked) => ({ ...prevChecked, ...defaultChecked }));
  }, [allColumns]);

  const disableSaveButton = isQuickView ? templateName.trim() === "" : false;

  const selectedColumns = allColumns.filter(
    (column) =>
      column.id !== "selection" &&
      column.Header !== "#" &&
      column.Header !== "Flag" &&
      column.columnId !== "_lineNumber" &&
      checkedColumns[column.id]
  );

  /**
   * Handle selected columns and save them into the state
   * @param {Object} event - Checkbox event
   * @param {Object} column - Selected column data
   */
  const handleSelectedColumns = (event, column) => {
    const updatedCheckedColumns = { ...checkedColumns };
    updatedCheckedColumns[column.id] = event.target.checked;
    setCheckedColumns(updatedCheckedColumns);
  };

  /**
   * Patch selected columns into Settings API
   */
  const updateConfiguration = useCallback(() => {
    if (!resourceName) return;

    const newSettings = {
      ...userSettings,
      web: {
        ...userSettings.web,
        [resourceName]: {
          ...userSettings.web[resourceName],
          [USER_SETTINGS_KEYS.RESOURCE_TABLE.SUBKEY_1]: selectedColumns.map(
            (column) => ({
              label: column.Header,
              value: column.id,
            })
          ),
        },
      },
    };

    updateSettings(newSettings);
  }, [updateSettings, resourceName, selectedColumns, userSettings]);

  return {
    selectedColumns,
    disableSaveButton,
    templateName,
    handleSelectedColumns,
    updateConfiguration,
    setTemplateName,
  };
};

export default useAddViewAndFilterPopoverData;

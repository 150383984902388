/**
 * Modifies the settings of a specific page.
 *
 * @param {Object} params - The parameters for modifying the tab settings.
 * @param {string} params.page - The page that contains the tab.
 * @param {string} params.tab - The tab whose settings are to be modified.
 * @param {Object} params.keys - The keys to be removed.
 * @param {Object} params.userSettings - The current settings of the user.
 * @param {Function} params.editSettings - The function to edit the settings.
 * @returns {Function} A promise that resolves to the result of the modifyTabSettings function.
 */

const removeTabSettings = async ({
  page,
  tab,
  resourceId,
  keys,
  editSettings,
  userSettings,
}) => {
  // Create a new copy of userSettings to avoid mutating the original object
  const newSettings = {
    ...userSettings,
    web: {
      ...userSettings?.web,
      [page]: {
        ...userSettings?.web?.[page],
      },
    },
  };

  const pageSettings = newSettings?.web[page];

  // If resourceId exists, delete resource tab settings
  if (resourceId) {
    keys.forEach((key) => {
      delete pageSettings.resource?.tab?.[tab]?.[key];
    });
  } else {
    // Delete non-resource tab settings
    keys.forEach((key) => {
      delete pageSettings?.tab?.[tab]?.[key];
    });
  }

  return editSettings(newSettings);
};

export default removeTabSettings;

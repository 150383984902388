import { useCallback, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import useCurrentUser from "../../hooks/useCurrentUser";
import useServiceRequestTableFormat from "../../hooks/useServiceRequestTableFormat";
import { ADD_OPEN_MODAL, SERVICE_REQUEST_CREATE_MODAL } from "../../constants";
import { useModalState } from "../../state/modalState";

const useServiceRequestListData = () => {
  const [{ modals }, modalDispatch] = useModalState();
  const { data: currentUser } = useCurrentUser();

  // Query selector to get all service requests
  const { data: serviceRequestTickets, isLoading } =
    useServiceRequestTableFormat({ association: false, asset: false });

  const isButtonDisabled = modals?.find(
    (item) => item.modalType === SERVICE_REQUEST_CREATE_MODAL
  );

  const canCreate = useMemo(
    () =>
      currentUser?.isAdmin ||
      currentUser?.hasPermission?.("ticket", "can_create"),
    [currentUser]
  );

  const handleOpenModal = useCallback(() => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      modalType: SERVICE_REQUEST_CREATE_MODAL,
      ref: { id: uuidv4() },
      modalData: {},
    });
  }, [modalDispatch]);

  return {
    isButtonDisabled,
    canCreate,
    serviceRequestTickets,
    isLoading,
    handleOpenModal,
  };
};

export default useServiceRequestListData;

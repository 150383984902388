import moment from "moment-timezone";
import React from "react";
import describeRruleFromString from "../../../../../helpers/Calendar/describeRruleFromString";
import formatTasks, {
  statusPillCN,
} from "../../../../../helpers/ServiceRequest/formatTasks";
import RoleMembersAvatarList from "../../../../../Pages/Admin/RoleMembersAvatarList";
import Pill from "../../../Pill/Pill";
import chevronRightIcon from "../../../../assets/images/chevronRightDarkGreen.svg";
import chevronLeftGreyIcon from "../../../../assets/images/chevronLeftGrey.svg";
import IconButton from "../../../Buttons/IconButton";
import formatHistoryTimeValue from "../../../../../helpers/Task/formatHistoryTimeValue";

const TaskHistoryRow = ({
  index,
  task,
  allInviteesInfo,
  onButtonClick,
  onRowClick,
  isExpanded,
  lastRowReferenceForInfiniteScroll,
  shouldBlockTaskNavigation,
}) => {
  return (
    <div
      className={`relative w-full rounded-t-lg ${
        isExpanded && "border-b bg-es-extra-light-grey"
      }`}
      ref={lastRowReferenceForInfiniteScroll}
      onClick={() => onRowClick(index)}
      role="button"
      tabIndex="0"
      onKeyUp={() => onRowClick(index)}
    >
      <table className="min-w-full table-fixed h-16">
        <tbody>
          <tr
            className={`h-16 align-middle text-gray-400 ${
              isExpanded && "font-semibold"
            }`}
          >
            <td className="w-14 p-4 border-r border-gray-150 font-semibold text-md  text-center">
              {index + 1}
            </td>
            <td className="text-md p-4 border-r border-gray-150 w-44">
              {moment.tz(task.startDate, task.timezone).format("ll")}
            </td>
            <td className="text-md p-4 border-r border-gray-150 w-44">
              {formatHistoryTimeValue(task)}
            </td>
            <td
              className="text-md p-4 border-r border-gray-150 capitalize"
              style={{ width: "400px" }} // Manually set Width here as task with different length recurrence strings were offsetting the table
            >
              {describeRruleFromString(task.recurrence, true)}
            </td>
            <td
              className="text-md p-4 border-r border-gray-150 capitalize w-44"
              aria-label="table-data"
            >
              {allInviteesInfo?.length > 0 && (
                <RoleMembersAvatarList
                  members={allInviteesInfo}
                  membersToShowCount="3"
                  showExtraCount
                />
              )}
            </td>
            <td
              className="p-4 border-r border-gray-150 flex justify-center w-44 h-full"
              aria-label="table-data"
            >
              <Pill
                value={formatTasks(task)?.formattedStatus}
                className={statusPillCN(formatTasks(task)?.formattedStatus)}
                background=""
              />
            </td>
            <td
              className="p-4 border-gray-150 w-44 justify-center"
              aria-label="table-data"
            >
              <IconButton
                icon={
                  shouldBlockTaskNavigation(task)
                    ? chevronLeftGreyIcon
                    : chevronRightIcon
                }
                isIconOnRight
                imgClassName={`w-3 h-3 ${
                  shouldBlockTaskNavigation(task) && "transform rotate-180"
                }`}
                className={
                  shouldBlockTaskNavigation(task)
                    ? "text-gray-200"
                    : "text-darkenedGreen"
                }
                onClick={() => onButtonClick(index, task)}
                title="Go To Task"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default TaskHistoryRow;

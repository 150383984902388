import { propertyKeys } from "../../hooks/properties";

const editPropertyOverviewStore = ({ queryClient, updatedData }) => {
  const queryKey = propertyKeys.overview();
  const oldData = queryClient.getQueryData(queryKey);

  // Note: Important, propertyOverview cache probably hasn't been initiated yet it will be an empty object
  const propertiesDict = oldData?.propertiesDict ?? {};
  const propertiesDD = oldData?.propertiesDD ?? [];

  const newPropertiesDict = { ...propertiesDict };

  const reference = `Property/${updatedData.id}`;

  // cpdate `newPropertiesDict` if the property exists
  if (newPropertiesDict[reference]) {
    newPropertiesDict[reference] = {
      ...newPropertiesDict[reference],
      ...updatedData.changes,
    };
  }

  // create a new `propertiesDD` array if title was updated
  let newPropertiesDD = propertiesDD;
  if (updatedData?.title) {
    const index = propertiesDD.findIndex(
      (property) => property.id === updatedData.id
    );

    if (index !== -1) {
      const oldPropertyValue = propertiesDD[index];

      const propertyValueUpdated = {
        id: oldPropertyValue.id,
        value: oldPropertyValue.reference,
        label: updatedData.title,
      };

      newPropertiesDD = [
        ...propertiesDD.slice(0, index),
        propertyValueUpdated,
        ...propertiesDD.slice(index + 1),
      ];
    }
  }

  queryClient.setQueryData(queryKey, {
    propertiesDict: newPropertiesDict,
    propertiesDD: newPropertiesDD,
  });
};

export default editPropertyOverviewStore;

import { fetchProjectsOverview } from "../../helpers/Projects";
import useAuthenticatedQuery from "../useAuthenticatedQuery";
import projectKeys from "./projectKeys";

/**
 * Query hook to fetch projects overview
 */
const useProjectsOverview = ({ enabled = true, select } = {}) => {
  const queryInfo = useAuthenticatedQuery({
    queryKey: projectKeys.overview(),
    enabled,
    select,
    queryFn: ({ signal }) => fetchProjectsOverview(signal),
    onError: (error) => {
      console.error(
        "useProjectsOverview: Error fetching projects overview",
        error
      );
    },
  });

  const {
    data,
    isLoading: isProjectsOverviewLoading,
    isError: isProjectsOverviewError,
    error: projectsOverviewError,
  } = queryInfo;

  return {
    projectDict: data?.projectDict,
    projectsDD: data?.projectsDD,
    isProjectsOverviewLoading,
    isProjectsOverviewError,
    projectsOverviewError,
  };
};

export default useProjectsOverview;

import React from "react";
import PropTypes from "prop-types";
import { useDrop } from "react-dnd";

const style = {
  height: "12rem",
  width: "12rem",
  color: "white",
  padding: "1rem",
  textAlign: "center",
  fontSize: "1rem",
  lineHeight: "normal",
  float: "left",
};

const Dustbin = ({ accept, onDrop, dropped, title }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept,
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });
  const isActive = isOver && canDrop;

  return (
    <div
      ref={drop}
      className={`flex flex-col w-20 h-20 border rounded-lg gap-2 ${
        isActive && "bg-borderGreen"
      }`}
      style={{ ...style }}
      data-testid="dustbin"
    >
      <p
        className={`${
          isActive ? "text-white" : "text-gray-500"
        } font-semibold border-b`}
      >
        {title}
      </p>
      <div className="flex flex-col flex-1 gap-2 overflow-y-scroll">
        {dropped?.map((item) => item)}
      </div>
    </div>
  );
};

Dustbin.propTypes = {
  accept: PropTypes.arrayOf(PropTypes.string),
  onDrop: PropTypes.func,
  dropped: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
};

Dustbin.defaultProps = {
  accept: [],
  onDrop: () => {},
  dropped: [],
  title: "[Dustbin]",
};

export default Dustbin;

import React from "react";
import cntl from "cntl";
import useAccountAndAdminViewData from "./useAccountAndAdminViewData";
import AccountAndAdminContent from "../../../stories/Components/AccountAndAdmin/AccountAndAdminContent";

const PageCN = cntl`
  lg:flex
  flex
  flex-initial
  flex-col
  mb-12
`;

const AccountAndAdminView = () => {
  const {
    isSaving,
    setIsSaving,
    isEditing,
    setIsEditing,
    isCancel,
    setIsCancel,
    onSave,
    onCancel,
    dropDownItems,
    isCancellingSubscription,
    setIsCancellingSubscription,
    isExportingMemberList,
    setIsExportingMemberList,
  } = useAccountAndAdminViewData();

  return (
    <div className={PageCN}>
      <AccountAndAdminContent
        isSaving={isSaving}
        setIsSaving={setIsSaving}
        isCancel={isCancel}
        setIsCancel={setIsCancel}
        onSave={onSave}
        onCancel={onCancel}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
        dropDownItems={dropDownItems}
        isCancellingSubscription={isCancellingSubscription}
        setIsCancellingSubscription={setIsCancellingSubscription}
        isExportingMemberList={isExportingMemberList}
        setIsExportingMemberList={setIsExportingMemberList}
      />
    </div>
  );
};

AccountAndAdminView.propTypes = {};

AccountAndAdminView.defaultProps = {};

export default AccountAndAdminView;

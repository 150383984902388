import React from "react";
import PropTypes from "prop-types";
import TableCellDropdown from "../../../Table/TableCellDropdown";
import useUnitOfMeasureCellData from "./useUnitOfMeasureCellData";

const UnitOfMeasureCell = ({
  isEditingTable,
  unitsOfMeasure,
  groupedUnitsOfMeasure,
  setEditedRow,
  editedRow,
  row,
  isHighRangeField,
}) => {
  const { getDropdownValue, onChange } = useUnitOfMeasureCellData({
    setEditedRow,
    editedRow,
    unitsOfMeasure,
  });

  // View mode, if not editing
  if (!isEditingTable) {
    return (
      <div className="flex">
        <p>
          {
            unitsOfMeasure?.find((unit) =>
              isHighRangeField
                ? unit.value === row?.original?.highRange?.unitOfMeasure
                : unit.value === row?.original?.unitOfMeasure
            )?.label
          }
        </p>
      </div>
    );
  }
  const hide = isHighRangeField && !editedRow?.isCostRange;
  return (
    <>
      <div className={`flex w-full ${hide && "invisible"}`}>
        <TableCellDropdown
          value={getDropdownValue(
            isHighRangeField
              ? editedRow?.highRange?.unitOfMeasure
              : editedRow?.unitOfMeasure
          )}
          options={groupedUnitsOfMeasure}
          onChange={onChange}
          isGrouped
          disbaleClear
        />
      </div>
    </>
  );
};

UnitOfMeasureCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      unitOfMeasure: PropTypes.string,
      highRange: PropTypes.shape({
        unitOfMeasure: PropTypes.string,
      }),
    }),
  }),
  setEditedRow: PropTypes.func,
  editedRow: PropTypes.shape({
    isCostRange: PropTypes.bool,
    unitOfMeasure: PropTypes.string,
    highRange: PropTypes.shape({
      unitOfMeasure: PropTypes.string,
    }),
  }),
  isEditingTable: PropTypes.bool,
  isHighRangeField: PropTypes.bool,
  unitsOfMeasure: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  groupedUnitsOfMeasure: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

UnitOfMeasureCell.defaultProps = {
  row: undefined,
  setEditedRow: () => {},
  editedRow: {},
  isEditingTable: false,
  isHighRangeField: false,
  unitsOfMeasure: [],
  groupedUnitsOfMeasure: [],
};

export default UnitOfMeasureCell;

import { useMutation, useQueryClient } from "react-query";

import Api from "@griffingroupglobal/eslib-api";

import { toastError, toastMessage } from "../../stories/Components/Toast/Toast";
import { rsvpKeys } from "../../config/reactQuery/queryKeyFactory";

const postRsvp = async ({ reference, op, status, instanceStartDate }) => {
  const params = {
    status,
  };
  if (instanceStartDate) params.instanceStartDate = instanceStartDate;
  const { data } = await Api.post(
    `/api/${reference}/rsvp/${op}`,
    {},
    {
      params,
    }
  );
  return data;
};

const useRsvpPost = (mutationKey) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ reference, op = "$all", status, instanceStartDate }) =>
      postRsvp({ reference, op, status, instanceStartDate }),
    mutationKey,
    onSuccess: () => {
      queryClient.invalidateQueries(rsvpKeys.all);
      toastMessage("Updated Successfully");
    },
    onError: (error) => {
      console.error(`useRsvpPost: ${error}`);
      toastError("Update Failed");
    },
  });
};

export default useRsvpPost;

import React from "react";
import Dropdown from "../../../Dropdown/Dropdown";
import useTagFilterData from "./useTagFilterData";
import removeIcon from "../../../../assets/images/crossWhiteBackgroundGreenShapeCircle.svg";

const TagsFilter = ({
  currentCol,
  stagedFilters,
  setStagedFilters,
  allColumns,
  data,
}) => {
  const {
    filterOptions,
    filterValue,
    tagsOptions,
    tagsSelected,
    handleChangeFilterOptions,
    handleSelectedTags,
    onRemoveFilter,
  } = useTagFilterData({
    allColumns,
    data,
    currentCol,
    stagedFilters,
    setStagedFilters,
  });

  return (
    <div className="flex min-w-0 pb-2">
      <div className="flex w-1/2">
        <Dropdown
          placeholder="Choose"
          options={filterOptions}
          value={filterValue}
          onChange={handleChangeFilterOptions}
          disableClear
        />
      </div>

      <div className="flex w-1/2 pl-2">
        <div className="flex w-5/6">
          <Dropdown
            menuPlacement="bottom"
            isMulti
            options={tagsOptions}
            value={tagsSelected}
            onChange={handleSelectedTags}
            disableClear
          />
        </div>
        <div className="flex pl-2">
          <button type="button" onClick={() => onRemoveFilter(currentCol.id)}>
            <img src={removeIcon} alt="delete" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TagsFilter;

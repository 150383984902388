import React from "react";
import PropTypes from "prop-types";
import CrossButton from "../../../stories/Components/CrossButton/CrossButton";
import closeDarkGreen from "../../../stories/assets/images/closeDarkGreen.svg";
import RealTimeAvatar from "../RealTimeAvatar";

const ExpandedView = ({ realtimeUsers = [], handleCloseViewer, lockData }) => {
  return (
    <div
      className="flex flex-col"
      style={{ minWidth: "319px", maxWidth: "390px" }}
    >
      <div className="bg-white flex-row rounded-lg border border-es-light-grey items-center p-4 shadow-medium-lift w-full">
        <div className="flex flex-row justify-between">
          <p className="font-es-semibold text-es-normal tracking-es-wide">
            Viewing
          </p>
          <CrossButton
            className="font-semibold"
            icon={closeDarkGreen}
            onClick={handleCloseViewer}
            style={{ height: 28, width: 28 }}
          />
        </div>

        <div className="flex flex-wrap flex-grow gap-3 items-center my-4">
          {realtimeUsers.map((user) => (
            <RealTimeAvatar key={user.id} user={user} lockData={lockData} />
          ))}
        </div>
      </div>
    </div>
  );
};

ExpandedView.propTypes = {
  realtimeUsers: PropTypes.arrayOf(PropTypes.shape({})),
  lockData: PropTypes.shape({
    userRef: PropTypes.string,
    user: PropTypes.shape({
      reference: PropTypes.string,
    }),
  }),
  handleCloseViewer: PropTypes.func,
};

ExpandedView.defaultProps = {
  realtimeUsers: [],
  lockData: {
    userRef: undefined,
    user: {
      reference: undefined,
    },
  },
  handleCloseViewer: () => {},
};

export default ExpandedView;

import { useState } from "react";

const useChildrenTask = ({ childrenTaskRefs }) => {
  const [isOpen, setIsOpen] = useState(false);
  const childTaskArray = childrenTaskRefs?.map(
    (item) => item?.split?.("/")?.[1]
  );
  return { childTaskArray, isOpen, setIsOpen };
};

export default useChildrenTask;

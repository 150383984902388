import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { truncate } from "lodash";
import assetDefault from "../../assets/images/placeholder_asset_large.svg";
import projectDefault from "../../assets/images/placeholder_project_large.svg";
import userDefault from "../../assets/images/userIcon.svg";
import propertyDefault from "../../assets/images/placeholder_property_large.svg";
import spaceDefault from "../../assets/images/placeholder_space.svg";
import companyDefault from "../../assets/images/companyWhiteIcon.svg";
import { useGetFile } from "../../../hooks/useGetFile";
import "./ResourceCard.css";

/**
 * Component to render image
 * @param {string | undefined} imageRef Deprecated: S3 url
 * @param {string | undefined} imageReference File reference for the image to render.
 *         This prop allow to display primary image using react-query.
 */
const ResourceImage = ({ imageRef, imageReference, title, resourceName }) => {
  const { data } = useGetFile(imageReference);

  const placeholder = () => {
    switch (resourceName) {
      case "Asset":
        return assetDefault;
      case "Contact":
        return userDefault;
      case "Property":
        return propertyDefault;
      case "Space":
        return spaceDefault;
      case "Company":
        return companyDefault;
      case "Project":
        return projectDefault;
      default:
        return assetDefault;
    }
  };

  const [image, setImage] = useState(null);

  // TODO (Josymar) This is temporal, until all resources are migrated to imageReference
  useEffect(() => {
    if (imageReference && data?.contentsUrl) {
      setImage(data?.contentsUrl);
    } else if (imageRef) {
      setImage(imageRef);
    } else setImage(null);
  }, [imageRef, imageReference, data]);

  return (
    <div
      className={`flex rounded-t-lg ${
        image ? "bg-es-white" : "bg-es-medium-grey"
      }`}
      style={{ height: 146, width: 281 }}
    >
      {image ? (
        <img
          src={image}
          alt="avatar"
          clipPath={`url(#clipCircle-${title})`}
          preserveAspectRatio="xMaxYMax slice"
          style={{ height: 146, width: 281 }}
          className="rounded-t-lg"
        />
      ) : (
        <div className="flex w-full justify-center items-center">
          <img
            src={placeholder()}
            alt="avatar"
            style={{ width: 60, height: 60 }}
          />
        </div>
      )}
    </div>
  );
};

function ResourceCard({
  id,
  title,
  detail,
  tag,
  resourceName,
  image,
  imageReference,
  onClick,
  disabled,
  className,
  valuePlaceholder,
}) {
  const handleClick = useCallback(() => {
    if (!disabled) onClick();
  }, [disabled, onClick]);

  return (
    <div
      id={id}
      style={{ height: 224, width: 283 }}
      className={`flex relative flex-col border-es-light-grey border rounded-lg cursor-pointer resourceCN ${className}`}
      onClick={handleClick}
      role="presentation"
    >
      <ResourceImage
        imageRef={image}
        imageReference={imageReference}
        title={title}
        resourceName={resourceName}
      />
      {tag && (
        <div className="absolute left-3.5 top-3.5 rounded-full px-2 py-1 opacity-70 bg-gray-650">
          <p className="opacity-100 text-es-white font-es-medium text-es-sm tracking-es-normal">
            {tag}
          </p>
        </div>
      )}
      <div
        className="flex flex-col w-full py-4 px-6 justify-center"
        style={{ height: 78 }}
      >
        <div className="font-es-semibold text-es-normal text-es-dark-grey capitalize">
          {truncate(title ?? valuePlaceholder, { length: 20 })}
        </div>
        <div className="font-es-normal text-es-sm text-es-black pt-1">
          {truncate(detail ?? valuePlaceholder, { length: 25 })}
        </div>
      </div>
    </div>
  );
}

ResourceCard.propTypes = {
  /**
   * ID Of Resource
   */
  id: PropTypes.string,
  /**
   * Title Of Resource
   */
  title: PropTypes.string,
  /**
   * currentUser
   */
  currentUser: PropTypes.shape({
    id: PropTypes.string,
    kind: PropTypes.string,
  }),
  /**
   * Sub title of resource
   */
  detail: PropTypes.string,
  // used for tags e.g. Project budget type
  tag: PropTypes.string,
  /**
   * Contact Resource
   * ie. { name: {firstName: "", lastName: ""},
   */
  contact: PropTypes.shape({
    name: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    id: PropTypes.string,
    kind: PropTypes.string,
    contactPoint: PropTypes.shape([]),
  }),
  /**
   * Company Resource
   * ie. { name: {firstName: "", lastName: ""},
   */
  company: PropTypes.shape({
    id: PropTypes.string,
    company: PropTypes.shape({
      value: PropTypes.string,
      valueType: PropTypes.string,
    }),
    reference: PropTypes.string,
  }),
  /**
   * Name Of Resource
   */
  resourceName: PropTypes.string,
  /**
   * Resource Image
   */
  image: PropTypes.string,
  imageReference: PropTypes.string,
  onClick: PropTypes.func,
  /**
   * Disabled button (configured for onAddClick only)
   */
  disabled: PropTypes.bool,
  className: PropTypes.string,
  valuePlaceholder: PropTypes.string,
};

ResourceCard.defaultProps = {
  id: "",
  title: "",
  detail: "",
  tag: undefined,
  contact: undefined,
  company: undefined,
  resourceName: "[Resource]",
  image: undefined,
  imageReference: undefined,
  onClick: () => {},
  disabled: false,
  className: "",
  currentUser: {},
  valuePlaceholder: "Not specified",
};

export default ResourceCard;

import React, { useCallback, useEffect } from "react";
import { isEmpty } from "lodash";
import PropTypes from "prop-types";
import FinancialOverview from "../../stories/Components/ProjectFinancials/FinancialOverview";
import useFinancialsConfigurationReducer from "../../hooks/useFinancialsConfigurationReducer";
import { formatFinancialConfigurationOriginalPayload } from "../../helpers/Settings";
import useFinancialsConfiguration from "../../hooks/useFinancialsConfiguration";
import useSystemConfiguration from "../../hooks/useSystemConfiguration";
import useDocumentsConfiguration from "../../hooks/useDocumentsConfiguration";
import { useAppState } from "../../state/appState";

const ProjectFinances = ({ editedProject, isEditing, dispatch }) => {
  const [{ currentUser }] = useAppState();

  //  system config
  const { data: systemConfiguration } = useSystemConfiguration();

  // financials
  const [financialsSettings, dispatchFinancialsSettings] =
    useFinancialsConfigurationReducer();
  const { data: financialsConfiguration } = useFinancialsConfiguration();

  //  documents settings
  const { data: documentsConfiguration } = useDocumentsConfiguration();

  const resetFinancialsSettings = useCallback(() => {
    dispatchFinancialsSettings({
      type: "reset",
      configuration: formatFinancialConfigurationOriginalPayload(
        financialsConfiguration
      ),
    });
  }, [dispatchFinancialsSettings, financialsConfiguration]);

  useEffect(() => {
    if (!isEmpty(financialsConfiguration)) {
      resetFinancialsSettings();
    }
  }, [financialsConfiguration, resetFinancialsSettings]);

  return (
    <>
      <FinancialOverview
        project={editedProject}
        dispatch={dispatch}
        financialsSettings={financialsSettings}
        documentsSettings={documentsConfiguration}
        isEditing={isEditing}
        editable={currentUser?.hasPermission(
          "administrative",
          "can_write_project"
        )}
        readable={currentUser?.hasPermission("project", "can_read_financials")}
        systemConfiguration={systemConfiguration}
      />
    </>
  );
};

ProjectFinances.propTypes = {
  editedProject: PropTypes.shape({
    retainage: PropTypes.arrayOf(
      PropTypes.shape({
        percentComplete: PropTypes.string,
        percentRetainage: PropTypes.string,
      })
    ),
  }),
  isEditing: PropTypes.bool,
  dispatch: PropTypes.func,
};

ProjectFinances.defaultProps = {
  editedProject: undefined,
  isEditing: false,
  dispatch: undefined,
};

export default ProjectFinances;

import { PropertyAPI } from "@griffingroupglobal/eslib-api";
import { useState } from "react";

const useCheckDuplicatedTitle = () => {
  // State to make sure new Property and edited Property have a unique title
  const [titleExists, setTitleExists] = useState(false);

  /**  make an API call to fetch a property by title
   * @param {title} property title is of string type
   * @return:
   * - null if name is undefined
   * - API call succesfull returns reponse.data =
   * {
   *   title: <property title>,
   *   reference: <property reference>
   *  }
   * - API call returns {statusCode, errorMessage} if no property found
   */
  const fetchPropertyByTitle = async (title) => {
    if (!title) return null;

    try {
      const extras = {
        params: {
          title,
        },
      };
      const { data } = await PropertyAPI.getWOP("$lookup", extras);
      setTitleExists(true);
      return data;
    } catch (error) {
      setTitleExists(false);
      return { statusCode: error.status, errorMessage: error.message };
    }
  };

  return {
    fetchPropertyByTitle,
    setTitleExists,
    titleExists,
  };
};

export default useCheckDuplicatedTitle;

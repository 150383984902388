import React from "react";
import { RealTimeResourceProvider } from "@griffingroupglobal/eslib-ably/react";
import PropertyView from "../../Overviews/Property/PropertyView";
import usePropertySinglePageData from "./usePropertySinglePageData";

const PropertySinglePage = () => {
  const { reference, propertyId } = usePropertySinglePageData();

  return (
    <RealTimeResourceProvider reference={reference}>
      <PropertyView propertyId={propertyId} />
    </RealTimeResourceProvider>
  );
};

export default PropertySinglePage;

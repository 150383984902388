import {
  AsYouTypeFormatter,
  PhoneNumberFormat,
  PhoneNumberUtil,
} from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();

const formatPhoneNumber = (value = "") => {
  const text = value.replace(/[a-zA-Z]/g, "");

  try {
    const hasPlus = text.startsWith("+");
    const isUS = text.startsWith("+1");
    const number = phoneUtil.parse(hasPlus ? text : `+1 ${text}`);
    if (phoneUtil.isPossibleNumber(number)) {
      return phoneUtil.format(
        number,
        !hasPlus || isUS
          ? PhoneNumberFormat.NATIONAL
          : PhoneNumberFormat.INTERNATIONAL
      );
    }
    const countryCode = phoneUtil.getRegionCodeForNumber(number);
    const formatter = new AsYouTypeFormatter(
      countryCode === "US"
        ? PhoneNumberFormat.NATIONAL
        : PhoneNumberFormat.INTERNATIONAL
    );
    let formatted;
    text?.split("").forEach((char) => {
      formatted = formatter.inputDigit(char);
    });
    return formatted;
  } catch {
    return text;
  }
};

export default formatPhoneNumber;

/* eslint-disable no-nested-ternary */
import cntl from "cntl";
import moment from "moment-timezone";
import React from "react";
import { recurrenceOptions } from "../../../../../constants";
import getDateTimeInTimezone from "../../../../../helpers/Calendar/getDateTimeInTimezone";
import convertTZ from "../../../../../helpers/Date/convertTimezone";
import getRecurrenceSelectValue from "../../../../../helpers/Date/getRecurrenceSelectValue";
import { getFullName } from "../../../../../helpers/Formatters";
import { guessTimeZone } from "../../../../../helpers/Time";
import RoleMembersAvatarList from "../../../../../Pages/Admin/RoleMembersAvatarList";
import AssociationLink from "../../../AssociationLink";
import PlusCircleButton from "../../../Buttons/PlusCircleButton/PlusCircleButton";
import Checkbox from "../../../Checkbox/Checkbox";
import DatePicker from "../../../DatePicker/DatePicker";
import TimePicker from "../../../DatePicker/TimePicker";
import DescriptionField from "../../../DescriptionField";
import DistroListSelectMembers from "../../../DistroList/DistroListSelectMembers";
import Dropdown from "../../../Dropdown/Dropdown";
import FormatedPriorityLevel from "../../../DropDownWithPriorityLevel/FormatedPriorityLevel";
import Input from "../../../Input/Input";
import TaskRelatedTo from "../../../RelatedTo/TaskRelatedTo";
import RequestGridViewDistroListCell from "../../../Requests/RequestGridView/RequestGridViewDistroListCell";
import useTaskGeneralInfoData from "./useTaskGeneralInfoData";

const rowCN = (className) => cntl`
flex
flex-row
w-full
h-fit
items-center
my-4
gap-10
${className}
`;
const leftContainer = "flex flex-row w-1/2 items-start pt-2";
const rightContainer = "flex flex-row w-1/2 items-start pt-2";
const labelWidth = { minWidth: "12.5rem", width: "10.5vw" };
const textCN = "text-es-medium-grey text-es-normal";
const labelCN = "text-es-medium-grey font-semibold text-es-normal self-start";

const Divider = ({ fullLength }) =>
  !fullLength ? (
    <div className="flex flex-row items-start gap-10">
      <div className="w-1/2 border-b my-2" />
      <div className="w-1/2 border-b my-2" />
    </div>
  ) : (
    <div className="flex flex-row w-full border-b py-2" />
  );

const TaskGeneralInfo = ({
  taskForm,
  allDayData,
  currentTask,
  editingMode,
  setFormDate,
  setFormTime,
  setFormEndDate,
  setFormEndTime,
  setFormRecurrence,
  dateForm,
  timeForm,
  endDateForm,
  endTimeForm,
  currentInvitees,
  completedBy,
  createdBy,
  inviteeArray,
  handleAddInvitee,
  handleRemoveInvitee,
  setInviteOpenPopup,
  inviteOpenPopup,
  allInviteesInfo,
  removeAssignee,
  handleDescriptionChange,
  handlePriorityChange,
  isOverdueTask,
  isSingleTask,
  recurrenceDropDownRef,
  recurrenceString,
}) => {
  const {
    association,
    type,
    priorityOptions,
    rsvpData,
    setDisableEditRecurrence,
    disableEditRecurrence,
  } = useTaskGeneralInfoData({
    currentTask,
    timeForm,
  });

  return (
    <>
      {/* Association and Date */}
      <div className={rowCN("mt-8")}>
        <div className={leftContainer}>
          <p style={labelWidth} className={labelCN}>
            {type}
          </p>
          <AssociationLink
            associationName={association}
            associationRef={currentTask?.association}
          />
        </div>
        <div className={rightContainer}>
          <p className={labelCN} style={labelWidth}>
            Timezone
          </p>
          <p className={textCN}>
            {currentTask.timezone || guessTimeZone()?.label}
          </p>
        </div>
      </div>

      <Divider />

      {/* Time and Recurrence Row */}
      <div className={rowCN()}>
        {editingMode ? (
          <div className={leftContainer}>
            <div className="flex flex-row w-full items-center justify-around">
              <p style={labelWidth} className={labelCN}>
                Start
              </p>
              <DatePicker
                className="w-full mr-2"
                onChange={setFormDate}
                value={convertTZ(
                  new Date(moment.tz(dateForm, currentTask.timezone).format()),
                  currentTask.timezone
                )}
              />
              {!allDayData.isAllDay && (
                <TimePicker
                  timeIntervals={5}
                  className="w-full flex flex-row mr-2"
                  onChange={setFormTime}
                  value={convertTZ(
                    new Date(
                      moment.tz(timeForm, currentTask.timezone).format()
                    ),
                    currentTask.timezone
                  )}
                />
              )}
              <Checkbox
                checked={allDayData.isAllDay}
                onChange={allDayData.toggleAllDay}
                inputClassName="calendar-form-tick"
                label="All Day"
                size="6"
              />
            </div>
          </div>
        ) : (
          <div className={leftContainer}>
            <p style={labelWidth} className={labelCN}>
              Start
            </p>
            <p className={textCN}>
              {getDateTimeInTimezone({
                date: currentTask.startDate,
                timezone: currentTask.timezone,
                format: allDayData.isAllDay
                  ? "MMMM DD, YYYY"
                  : "MMMM DD, YYYY hh:mmA",
              })}
            </p>
          </div>
        )}

        {editingMode ? (
          <div className={rightContainer}>
            <div className="flex flex-row w-full items-center justify-around">
              <p style={labelWidth} className={labelCN}>
                End
              </p>
              <DatePicker
                className="w-full mr-2"
                onChange={setFormEndDate}
                value={convertTZ(
                  new Date(
                    moment.tz(endDateForm, currentTask.timezone).format()
                  ),
                  currentTask.timezone
                )}
              />
              {!allDayData.isAllDay && (
                <TimePicker
                  timeIntervals={5}
                  className="w-full flex flex-row mr-2"
                  onChange={setFormEndTime}
                  value={convertTZ(
                    new Date(
                      moment.tz(endTimeForm, currentTask.timezone).format()
                    ),
                    currentTask.timezone
                  )}
                />
              )}
            </div>
          </div>
        ) : (
          <div className={rightContainer}>
            <p style={labelWidth} className={labelCN}>
              End
            </p>
            <p className={textCN}>
              {getDateTimeInTimezone({
                date: currentTask.endDate,
                timezone: currentTask.timezone,
                format: allDayData.isAllDay
                  ? "MMMM DD, YYYY"
                  : "MMMM DD, YYYY hh:mmA",
              })}
            </p>
          </div>
        )}
      </div>

      <Divider />

      {/* Assignees and Priority Row */}
      <div className={rowCN()}>
        <div className={leftContainer}>
          <p style={labelWidth} className={labelCN}>
            Repeat
          </p>
          {editingMode ? (
            <div className="flex-col w-full">
              <Dropdown
                tabIndex={0}
                containerRef={recurrenceDropDownRef}
                name="recurrence"
                placeholder="Select"
                className="w-full"
                options={recurrenceOptions}
                value={getRecurrenceSelectValue(taskForm?.recurrence)}
                onChange={setFormRecurrence}
                isDisabled={
                  disableEditRecurrence || (isOverdueTask && !isSingleTask)
                }
                disableSort
                disableClear
              />
              {!!recurrenceString && (
                <p className={`${textCN} mt-2`}>{recurrenceString}</p>
              )}
            </div>
          ) : (
            <p className={textCN}>{recurrenceString}</p>
          )}
        </div>

        <div className={rightContainer}>
          <p style={labelWidth} className={labelCN}>
            Priority
          </p>
          {editingMode ? (
            <Dropdown
              disableClear
              placeholder="None"
              disableSort
              disableSearch
              options={priorityOptions}
              value={{
                label: FormatedPriorityLevel(taskForm?.priority),
                value: taskForm.priority,
              }}
              onChange={({ value }) => handlePriorityChange(value)}
            />
          ) : (
            FormatedPriorityLevel(
              currentTask?.priority,
              "text-es-medium-grey text-es-normal"
            )
          )}
        </div>
      </div>

      <Divider />

      {/* CreatedBy and Completed By Row */}
      <div className={rowCN("mb-4")}>
        <div className={leftContainer}>
          <p style={labelWidth} className={labelCN}>
            Description
          </p>
          {editingMode ? (
            <Input
              placeholder="Description"
              className="bg-backgroundGreen rounded text-gray-450"
              inputClassName="bg-backgroundGreen p-2 w-full"
              mainWrapperClassName="w-full"
              value={taskForm.description}
              onChange={(value) => handleDescriptionChange(value)}
              isTextarea={taskForm?.description?.trim()?.length > 65}
            />
          ) : (
            <DescriptionField
              className={textCN}
              text={currentTask?.description}
            />
          )}
        </div>

        <div className={rightContainer}>
          <p style={labelWidth} className={labelCN}>
            Created By
          </p>
          <RoleMembersAvatarList
            members={[createdBy]}
            className="mr-2 relative"
          />
          <p className={textCN}>{getFullName(createdBy?.name)}</p>
        </div>
      </div>

      <Divider />
      <div className="float-right flex h-fit py-6 pl-5 flex-row items-start w-full max-w-1/2">
        {/* <div className={rightContainer}> */}
        <p style={labelWidth} className={labelCN}>
          Related To
        </p>
        <TaskRelatedTo
          taskRef={currentTask?.reference}
          parentTaskRef={currentTask?.relationships?.parent}
          childrenTaskRefs={currentTask?.relationships?.children}
          setDisableEditRecurrence={setDisableEditRecurrence}
        />
        {/* </div> */}
      </div>
      {/* Description Row */}
      <div className={rowCN("max-w-1/2 pb-6 border-b")}>
        <div className={leftContainer}>
          {!editingMode ? (
            <p style={labelWidth} className={labelCN}>
              Assigned To
            </p>
          ) : (
            <div style={labelWidth} className="flex items-center">
              <p className={labelCN}>Assign To</p>
              <PlusCircleButton
                className={labelCN}
                onClick={() => {
                  setInviteOpenPopup(!inviteOpenPopup);
                }}
              />
            </div>
          )}

          {allInviteesInfo.length === 0 && !editingMode && (
            <p className={textCN}>This task is not assigned to anyone.</p>
          )}

          {editingMode ? (
            <div>
              <RequestGridViewDistroListCell
                distroList={currentInvitees}
                hideTile
                handleDeleteFromDistro={removeAssignee}
                avatarClassName="w-8 h-8"
              />

              {inviteOpenPopup && (
                <DistroListSelectMembers
                  setDistroSelectOpen={setInviteOpenPopup}
                  selectedMembers={currentInvitees}
                  members={inviteeArray}
                  onAdd={handleAddInvitee}
                  onRemove={handleRemoveInvitee}
                  title="Assignees"
                  showMemberPill
                  placeHolder="Search Assignees"
                />
              )}
            </div>
          ) : (
            <div className="relative">
              <RoleMembersAvatarList
                viewAllButton={
                  <div className="flex justify-center items-center bg-backgroundGrey rounded-3xl w-8 h-8">
                    <p className="flex justify-center text-primaryGreen text-xs font-normal w-5">
                      {`+${allInviteesInfo?.length - 5}`}
                    </p>
                  </div>
                }
                membersToShowCount={5}
                members={allInviteesInfo}
                showRsvpStatus
                rsvpData={rsvpData}
                viewAllClassName="pl-0"
              />
            </div>
          )}
        </div>
      </div>
      <div className={rowCN("max-w-1/2 py-4")}>
        <div className={leftContainer}>
          <p style={labelWidth} className={labelCN}>
            Completed By
          </p>
          {completedBy && (
            <div className="flex flex-row items-center relative">
              <RoleMembersAvatarList members={[completedBy]} className="mr-2" />
              <p className={textCN}>{getFullName(completedBy?.name)}</p>
            </div>
          )}
        </div>
      </div>
      <Divider fullLength />
    </>
  );
};

export default TaskGeneralInfo;

import { useMutation, useQueryClient } from "react-query";

import deleteSpaceHandler from "./handlers/deleteSpaceHandler";
import deleteSpaceResponder from "./responders/deleteSpaceResponder";
import deleteSpaceListStore from "../../../helpers/Spaces/deleteSpaceListStore";
import deleteAssociationSpacesListStore from "../../../helpers/Spaces/deleteAssociationSpacesListStore";
import spacesKeys from "./spacesKeys";

const useDeleteSpace = () => {
  const queryClient = useQueryClient();

  const deleteMutation = useMutation(
    deleteSpaceHandler,
    deleteSpaceResponder(queryClient)
  );

  /**
   * Delete space from a single space cache, space list and association spaces store
   * @param {string}
   */
  const deleteSpace = async (id, association, newRequest = true) => {
    if (!id) {
      console.error("id is required: pass a space id");
    }

    if (newRequest === false) {
      deleteSpaceListStore({ queryClient, id });
      deleteAssociationSpacesListStore({ queryClient, association, id });
      return queryClient.removeQueries({ queryKey: spacesKeys.byId(id) });
    }

    const props = {
      id,
      association,
    };

    const response = await deleteMutation.mutateAsync(props);
    return response;
  };

  const { isLoading: isDeleteSpaceLoading, isError: isDeleteSpaceError } =
    deleteMutation;

  return {
    isDeleteSpaceError,
    isDeleteSpaceLoading,
    deleteSpace,
  };
};

export default useDeleteSpace;

import { useESAbly } from "@griffingroupglobal/eslib-ably/react";
import { useEffect, useState } from "react";

const useRealTimeConnectionStatusData = () => {
  const { client } = useESAbly();

  const [isConnected, setConnected] = useState(
    client.connection.state === "connected"
  );

  useEffect(() => {
    const handleConnected = () => setConnected(true);
    const handleDisconnected = () => setConnected(false);

    client.connection.on("connected", handleConnected);
    client.connection.on("disconnected", handleDisconnected);
    client.connection.on("failed", handleDisconnected);
    client.connection.on("closed", handleDisconnected);

    return () => {
      client.connection.off("connected", handleConnected);
      client.connection.off("disconnected", handleDisconnected);
      client.connection.off("failed", handleDisconnected);
      client.connection.off("closed", handleDisconnected);
    };
  }, [client]);

  return { isConnected };
};

export default useRealTimeConnectionStatusData;

import { userKeys } from "../../../../config/reactQuery/queryKeyFactory";
import addToPropertyListStore from "../../../../helpers/Properties/addToPropertyListStore";
import addToPropertyOverviewStore from "../../../../helpers/Properties/addToPropertyOverviewStore";

const addPropertyResponder = (queryClient) => {
  return {
    onSuccess: (returnedData) => {
      addToPropertyListStore({
        queryClient,
        property: returnedData,
      });

      addToPropertyOverviewStore({
        queryClient,
        property: returnedData,
      });

      // invalidating as new users may have been created in the owned by field
      // TODO (add user manually to cache when working on RTD Users)
      queryClient.invalidateQueries(userKeys.users);
    },
    onError: (err) => {
      console.error("Failed to create property", err);
    },
  };
};

export default addPropertyResponder;

import { cloneDeep } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { selectSectionSchema } from "../../../../helpers/FormValidations";
import useDidMountEffect from "../../../../hooks/useDidMountEffect";

export default function useSelectSectionData({
  selectionValue,
  selectionItemValue,
  selectionSecondItemValue,
  settings,
  dispatchSettings,
  setIsValid,
}) {
  const [isSelectAll, setSelectAllSectionItems] = useState(false);
  // state used for wordclock editing

  const [isAdding, setIsAdding] = useState(false);

  const isWorldClock = selectionItemValue === "city";

  const onAddSectionItem = useCallback(() => {
    dispatchSettings({
      type: "addSectionItem",
      selectionValue,
      selectionItemValue,
    });
  }, [selectionValue, selectionItemValue, dispatchSettings]);

  const onRemoveSectionItem = useCallback(
    (index) => {
      dispatchSettings({
        type: "removeSectionItem",
        selectionValue,
        index,
      });
    },
    [selectionValue, dispatchSettings]
  );

  const onSectionItemChange = useCallback(
    (newValue, index, secondNewValue) => {
      dispatchSettings({
        type: "changeSectionItem",
        selectionValue,
        selectionItemValue,
        index,
        value: newValue,
        selectionSecondItemValue,
        secondValue: secondNewValue,
      });
    },
    [
      dispatchSettings,
      selectionValue,
      selectionItemValue,
      selectionSecondItemValue,
    ]
  );

  const hasDuplicate = useCallback(
    (value, idx) => {
      return settings?.[selectionValue].some((item, index) => {
        return (
          item?.[selectionItemValue].toLowerCase() === value?.toLowerCase() &&
          index !== idx
        );
      });
    },
    [settings, selectionValue, selectionItemValue]
  );

  const toggleSectionItemEditing = useCallback(
    (index) => {
      dispatchSettings({
        type: "changeSectionItemEditing",
        selectionValue,
        index,
      });
    },
    [selectionValue, dispatchSettings]
  );

  const handleEnter = useCallback(
    (event, value, idx) => {
      // checks if the Enter key was pressed
      if (event.keyCode === 13) {
        if (hasDuplicate(value, idx)) {
          event.stopPropagation();
          return false;
        }
        if (value === "") {
          onRemoveSectionItem(idx);
          return undefined;
        }

        if (isAdding) onAddSectionItem();
        toggleSectionItemEditing(idx);
      }
      return undefined;
    },
    [
      isAdding,
      hasDuplicate,
      onAddSectionItem,
      onRemoveSectionItem,
      toggleSectionItemEditing,
    ]
  );

  const handleBlur = (value, idx) => {
    if (window.event.keyCode === 13) return;
    toggleSectionItemEditing(idx);
    if (!value || hasDuplicate(value, idx)) {
      onRemoveSectionItem(idx);
    }

    // prevents new empty input field from being added inside handleEnter
    setIsAdding(false);
  };

  useEffect(() => {
    const isAnyUnselected = settings?.[selectionValue]?.some((item) => {
      if (!item.selected && !item.count) {
        return true;
      }

      return false;
    });

    setSelectAllSectionItems(!isAnyUnselected);
  }, [settings, selectionValue]);

  const selectAllSectionItems = useCallback(
    (bool) => {
      dispatchSettings({
        type: "selectAllSectionItems",
        selectionValue,
        value: bool,
      });

      setSelectAllSectionItems(bool);
    },
    [selectionValue, dispatchSettings]
  );

  const onSectionItemSelect = useCallback(
    (index) => {
      dispatchSettings({
        type: "selectSectionItem",
        selectionValue,
        index,
      });
    },
    [selectionValue, dispatchSettings]
  );

  useDidMountEffect(() => {
    const validationFields = [];
    settings?.[selectionValue]?.forEach((v) => {
      const clonedValue = cloneDeep(v);
      validationFields.push(
        selectSectionSchema({ selectionItemValue }).isValid(clonedValue)
      );
    });
    Promise.all(validationFields).then((values) => {
      setIsValid(!values.some((field) => field === false));
    });
  }, [settings?.[selectionValue], setIsValid]);

  return {
    isSelectAll,
    isWorldClock,
    isAdding,
    setIsAdding,
    onAddSectionItem,
    onRemoveSectionItem,
    onSectionItemChange,
    toggleSectionItemEditing,
    handleEnter,
    handleBlur,
    selectAllSectionItems,
    onSectionItemSelect,
  };
}

import React from "react";
import { Switch, Route } from "react-router-dom";
import {
  HEALTH_PATH,
  LOGIN_PATH,
  SIGNUP_LANDING_PATH,
  SIGNUP_PATH,
} from "../constants";
import Landing from "../Pages/Landing";
import Signup from "../Pages/SignUp/SignUp";
import AuthenticatedContainer from "../layout/AuthenticatedContainer";
import ProtectedRoute from "./ProtectedRoute";
import Login from "../Pages/Login";

const AppRoutes = () => {
  return (
    <Switch>
      <Route path={HEALTH_PATH}>
        <h3>The App is Healthy</h3>
      </Route>
      <ProtectedRoute path={SIGNUP_LANDING_PATH} component={Landing} />
      <ProtectedRoute path={SIGNUP_PATH} component={Signup} />
      <ProtectedRoute path={LOGIN_PATH} component={Login} />
      <ProtectedRoute path="/" component={AuthenticatedContainer} needAuth />
    </Switch>
  );
};

export default AppRoutes;

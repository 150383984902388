import set from "lodash/fp/set";
import { useReducer } from "react";

const financeReducer = (state, action) => {
  switch (action.type) {
    case "SET_FINANCE":
      return action.payload;
    case "UPDATE_FIELD":
      return set(action.payload.key, action.payload.value, state);
    default:
      return state;
  }
};

const useFinanceReducer = () => {
  const [financeState, dispatchFinance] = useReducer(financeReducer);

  return { financeState, dispatchFinance };
};

export default useFinanceReducer;

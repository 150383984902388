import { useMutation, useQueryClient } from "react-query";
import editFinanceHandler from "./handler/editFinanceHandler";
import editFinanceResponder from "./responders/editFinanceResponder";
import editSingleFinanceStore from "../../../helpers/Finances/editSingleFinanceStore";

const useEditFinance = () => {
  const queryClient = useQueryClient();

  const editMutation = useMutation(
    editFinanceHandler,
    editFinanceResponder(queryClient)
  );

  /**
   * Edit single finance cache. It can specify whether to make a new request or not
   * @param {Object} props.originalItem - original finance object
   * @param {Object} props.editedItem - finance object with new changes
   * @param {boolean} newRequest optional defaults to true, if false is sent an api request will not be attempted
   */
  const editFinance = async (props, newRequest = true) => {
    if (!props.originalItem) {
      return console.error(
        "editFinance | props.originalItem is required: populate with item you are updating"
      );
    }

    if (!props.editedItem) {
      return console.error(
        "editFinance | props.editedItem is required: populate with the original version of the item you are updating"
      );
    }

    const { originalItem, editedItem } = props;

    if (newRequest === false) {
      const updatedData = { ...originalItem, ...editedItem };
      editSingleFinanceStore({ queryClient, updatedData });
    }

    const response = await editMutation.mutateAsync(props);
    return response;
  };

  /**
   * Edit finance single cache from real time events
   * @param {Object} changes - changes made to the finance
   */
  const editFinanceRtd = (changes) => {
    editSingleFinanceStore({ queryClient, updatedData: changes });
  };

  const {
    isLoading: isEditingFinanceLoading,
    isError: isEditingFinanceError,
    error: editingFinanceError,
  } = editMutation;

  return {
    isEditingFinanceLoading,
    isEditingFinanceError,
    editingFinanceError,
    editFinance,
    editFinanceRtd,
  };
};

export default useEditFinance;

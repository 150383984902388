import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ACCOUNTANDADMIN_TABS, TIMESHEET_TABS } from "../constants";
import useAppPersistence from "./persistence/useAppPersistence";
import useCurrentUser from "./useCurrentUser";

/**
 * Hooks for managing tab navigation with URL query parameters
 * @param {String} page - Current page the user is on
 * @param {String} resourceId - Current (single view) resource
 */
const useWidgetTabNavigation = ({ page, resourceId }) => {
  const history = useHistory();
  const { data: currentUser } = useCurrentUser();

  const { getActiveTab, setActiveTab: setActiveTabPersistence } =
    useAppPersistence();

  const defaultTab = useCallback(
    (pge) => {
      switch (pge) {
        case "timesheet":
          return currentUser?.isEmployee
            ? TIMESHEET_TABS.MY_TIMESHEET_ID
            : TIMESHEET_TABS.PAYROLL_APPROVAL_ID;
        case "account-admin":
          return ACCOUNTANDADMIN_TABS.SETTINGSANDCONFIG_ID;
        default:
          return "details";
      }
    },
    [currentUser?.isEmployee]
  );

  const persistedActiveTab = getActiveTab({ page, resourceId });

  const activeTab = persistedActiveTab || defaultTab(page);

  const [localActiveTab, setLocalActiveTab] = useState(activeTab);

  const setActiveTab = useCallback(
    (tab) => {
      setActiveTabPersistence({ page, tab, resourceId });
      setLocalActiveTab(tab);
      history.push(`?tab=${tab}`);
    },
    [history, page, setActiveTabPersistence, resourceId]
  );

  // This useEffect handles copy/pasting a URL
  const { search } = useLocation();
  const params = useMemo(() => new URLSearchParams(search), [search]);
  useEffect(() => {
    if (localActiveTab !== params.get("tab")) {
      setActiveTabPersistence({
        page,
        tab: params.get("tab"),
        resourceId,
      });
      setLocalActiveTab(params.get("tab"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, resourceId, params, localActiveTab]);

  return {
    activeTab: localActiveTab,
    setActiveTab,
  };
};

export default useWidgetTabNavigation;

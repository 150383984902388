import { useCallback, useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { isUrlValid } from "../../../../helpers/Formatters";

const useLinksView = ({ dispatch }) => {
  const [addLinksIsOpen, setAddLinksIsOpen] = useState(false);
  const [linkName, setLinkName] = useState();
  const [linkUrl, setLinkUrl] = useState();
  const inputRef = useRef({});

  const validUrl = useMemo(() => {
    return !!linkUrl?.length && isUrlValid(linkUrl);
  }, [linkUrl]);

  const saveNewOrder = useCallback(
    (links = []) => {
      dispatch({
        type: "setLinks",
        links: links.map(({ link }) => link),
      });
    },
    [dispatch]
  );

  const addLink = () => {
    const link = { id: uuidv4(), name: linkName || linkUrl, url: linkUrl };
    dispatch({
      type: "addLinkCompleteLink",
      link,
    });
  };

  const handleDeleteClick = (link) => {
    dispatch({
      type: "deleteLink",
      linkId: link.id,
    });
  };

  const clearFields = () => {
    setLinkName();
    setLinkUrl();
  };

  const confirmEdit = () => {
    if (validUrl) {
      addLink();
      clearFields();
      inputRef.current?.title?.focus();
    }
  };

  const handleChange = (e) => {
    if (e.key === "Enter") {
      confirmEdit();
    }
  };

  return {
    addLinksIsOpen,
    setAddLinksIsOpen,
    linkName,
    setLinkName,
    linkUrl,
    setLinkUrl,
    validUrl,
    handleDeleteClick,
    confirmEdit,
    handleChange,
    saveNewOrder,
  };
};

export default useLinksView;

import { useCallback, useEffect, useMemo, useState } from "react";
import { useRealTimeResource } from "@griffingroupglobal/eslib-ably/react";
import { cloneDeep, isEqual } from "lodash";
import {
  useCheckDuplicatedTitle,
  usePropertyById,
} from "../../../hooks/properties";
import useQueryNotFoundNavigation from "../../../hooks/navigation/useQueryNotFoundNavigation";
import { hasWriteAdministrativePermission } from "../../../helpers/Permissions";
import { PROPERTY } from "../../../constants";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { useUsers } from "../../../hooks/useUsers.new";
import { useEditProperty } from "../../../hooks/api/properties";
import useFinanceReducer from "../../../hooks/finances/useFinanceReducer";
import { useEditFinance, useFinance } from "../../../hooks/api/finances";
import usePropertyFormReducer from "../../../hooks/usePropertyFormReducer";
import useWidgetTabNavigation from "../../../hooks/useWidgetTabNavigation";

const usePropertyViewData = (propertyId) => {
  const reference = `Property/${propertyId}`;

  const { data: property, isLoading, error } = usePropertyById(propertyId);

  const { data: currentUser } = useCurrentUser();

  const { data: usersData } = useUsers();
  const userDict = usersData?.userDict;

  const { activeTab, setActiveTab } = useWidgetTabNavigation({
    page: "properties",
    resourceId: propertyId,
  });

  const [editedProperty, dispatch] = usePropertyFormReducer();

  const [editing, setEditing] = useState(false);

  // Real time resource hook
  const {
    realtimeUsers,
    lockState,
    acquireLock,
    releaseLock,
    isTabLocked,
    getLockInfo,
  } = useRealTimeResource({ reference, isLoading, userDict });

  // Redirect to 404 page if resource is not found
  useQueryNotFoundNavigation({ error });

  const { editProperty } = useEditProperty();

  const canWriteProperty = hasWriteAdministrativePermission(
    PROPERTY,
    currentUser
  );

  const disableEditing = !canWriteProperty;

  const isDetailsLocked = isTabLocked("details");

  // hides Actions dropdown in "Details" tab if is being edited in RTD for someone else or
  // if user doesn't have permissions
  const hideActionsDropdown = disableEditing || isDetailsLocked;

  const isDetailsTab = activeTab === "details";
  const isFinanceTab = activeTab === "finances";

  const { finance } = useFinance({ association: reference });
  const { financeState, dispatchFinance } = useFinanceReducer();
  const { editFinance } = useEditFinance();

  /**
   * Reset finance state to current version
   */
  const resetFinanceState = useCallback(() => {
    if (finance) {
      dispatchFinance({ type: "SET_FINANCE", payload: finance });
    }
  }, [finance, dispatchFinance]);

  /**
   * Sets finance to current RQ version
   */
  useEffect(() => {
    resetFinanceState();
  }, [resetFinanceState]);

  /**
   * Patch Finances with new changes
   */
  const handleEditFinance = async () => {
    try {
      const props = {
        originalItem: finance,
        editedItem: financeState,
      };

      await editFinance(props);
    } catch (err) {
      console.error(err);
    }
  };

  /**
   * Handle edit, locking and releasing resource tab in real time
   */
  const handleEditClick = useCallback(async () => {
    setEditing((prev) => !prev);

    const isEditing = !editing;

    try {
      if (isEditing) {
        await acquireLock(activeTab);
      } else {
        await releaseLock(activeTab);
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }, [acquireLock, releaseLock, editing, activeTab]);

  // async function and state to check duplicated property title
  const { fetchPropertyByTitle, titleExists } = useCheckDuplicatedTitle();

  // take a copy of original edited Property to validate changes
  const origEditedProperty = useMemo(
    () =>
      cloneDeep({
        ...property,
        title: property?.title.toLowerCase(),
        currentTags: [],
        originalResource: { ...property },
      }),
    [property]
  );

  // check if editedProperty has been modified
  const isPropertyEdited = useMemo(() => {
    const sameProperty = isEqual(origEditedProperty, {
      ...editedProperty,
      title: editedProperty?.title?.toLowerCase(),
    });
    return !sameProperty;
  }, [editedProperty, origEditedProperty]);

  return {
    isPropertyEdited,
    titleExists,
    propertyId,
    property,
    isLoading,
    currentUser,
    editing,
    disableEditing,
    realtimeUsers,
    lockState,
    canWriteProperty,
    hideActionsDropdown,
    financeState,
    editedProperty,
    activeTab,
    isDetailsTab,
    isFinanceTab,
    setActiveTab,
    fetchPropertyByTitle,
    dispatch,
    dispatchFinance,
    handleEditClick,
    editProperty,
    isTabLocked,
    getLockInfo,
    handleEditFinance,
    resetFinanceState,
  };
};

export default usePropertyViewData;

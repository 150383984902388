import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import * as yup from "yup";
import moment from "moment";
import cntl from "cntl";
import Select from "../Select/Select";
import formatPhoneNumber from "../../../helpers/Utilities/formatPhoneNumber";
import Input from "../Input/Input";
import TertiaryButton from "../Buttons/TertiaryButton";
import TagsContainer from "../Tags/TagsContainer";
import { truncateLabel } from "../../../helpers/Tag";
import ShareIcon from "../../assets/images/shareIcon.svg";
import { handleLinkClick } from "../../../helpers/Utilities";
import DatePicker from "../DatePicker/DatePicker";
import SelectOne from "../SelectOne/SelectOne";
import TrashButton from "../Buttons/TrashButton";

const boldCN = (hasDropdown, className) => cntl`
${className && className}
  font-bold 
  text-gray-450
  text-md
  self-start
  ${hasDropdown ? "mr-2" : ""}
`;

const textCN = cntl`
  text-gray-450 
  text-sm
  font-normal
  flex
  ml-4
`;

const getAvailableOptions = (field, options) => {
  const traversedOptions = field.map((val) => val.type);

  return options.filter((opt) => traversedOptions.indexOf(opt) === -1);
};

const displayItem = ({ type, value }, isLink) => (
  <div
    style={{ width: "99%" }}
    className="flex border-b border-gray-450 h-20 max-h-fit border-opacity-50 items-center"
    key={`${type}-${value}`}
  >
    <div className="flex flex-row min-w-full">
      <h3 className={boldCN(false, "flex w-1/3")}>{type}</h3>
      {!isLink ? (
        <div className="flex flex-row w-2/3 justify-start mr-2 items-center">
          <p className={textCN}>{truncateLabel(value, "40", "...")}</p>
        </div>
      ) : (
        <>
          <button
            type="button"
            onClick={() => handleLinkClick(value)}
            className="flex flex-row w-2/3 justify-start items-center underline"
          >
            <p className={textCN}>{truncateLabel(value, "40", "...")}</p>
            <img
              className="ml-5"
              style={{ width: 12, height: 12 }}
              src={ShareIcon}
              alt="link open icon"
            />
          </button>
        </>
      )}
    </div>
  </div>
);

const Options = ["Home", "Work", "Physical", "Mailing", "Other"];
const EmailOptions = ["Home", "Work", "Personal"];
const PhoneOptions = ["Mobile", "Work", "Home", "Other"];
const DateOptions = ["Birthday", "Anniversary"];
const SocialOptions = ["LinkedIn", "Twitter", "Facebook"];

const ContactInfoSubComponents = ({
  contactInfo,
  setContactInfo,
  editingContact,
  loading,
  saving,
  contactResource,
  contactDispatch,
  onBoarding,
}) => {
  const [dateOptions, setDateOptions] = useState([]);
  const [phoneOptions, setPhoneOptions] = useState([]);
  const [socialOptions, setSocialOptions] = useState([]);

  useEffect(() => {
    if (contactInfo) {
      setDateOptions(
        _.uniq([
          ...DateOptions,
          ...contactInfo?.dates?.map((d) => d?.type?.label || d?.type),
        ])
      );

      setSocialOptions(
        _.uniq([
          ...SocialOptions,
          ...contactInfo?.socials?.map((s) => s?.type?.label || s?.type),
        ])
      );

      setPhoneOptions(
        _.uniq([
          ...PhoneOptions,
          ...contactInfo?.phoneNumbers?.map((s) => s?.type?.label || s?.type),
        ])
      );
    }
  }, [contactInfo, dateOptions.length]);

  const inputRefs = useRef({
    phoneNumbers: {},
    emails: {},
    addresses: {},
    websites: {},
    dates: {},
    socials: {},
  });

  const change = useCallback(
    (value, id, type, subType) => {
      setContactInfo((prev) => ({
        ...prev,
        [`${type}`]: prev[type]?.map((item, index) => {
          if (index === id) {
            if (subType) {
              return {
                ...item,
                [`${subType}`]: value,
              };
            }
            if (type === "phoneNumbers") {
              return { ...item, value: formatPhoneNumber(value, item?.value) };
            }
            return { ...item, value };
          }
          return item;
        }),
      }));
    },
    [setContactInfo]
  );

  const changeDate = useCallback(
    (val, index) => {
      setContactInfo((prev) => ({
        ...prev,
        dates: prev?.dates?.map((item, idx) => {
          if (idx === index) {
            const copy = { ...item };
            copy.value = val;
            return copy;
          }
          return item;
        }),
      }));
    },
    [setContactInfo]
  );

  const remove = useCallback(
    (id, type) => {
      setContactInfo((prev) => ({
        ...prev,
        [`${type}`]: prev[type]?.filter((item, index) => index !== id),
      }));
    },
    [setContactInfo]
  );

  const changeType = useCallback(
    (value, id, type) => {
      setContactInfo((prev) => ({
        ...prev,
        [`${type}`]: prev[type]?.map((item, index) => {
          if (typeof value !== "string" && index === id)
            return { ...item, type: value?.label, label: value?.label };
          if (index === id) {
            return { ...item, type: value, label: value };
          }
          return item;
        }),
      }));
    },
    [setContactInfo]
  );

  const add = useCallback(
    (type, option, defaultValue) => {
      setContactInfo((prev) => ({
        ...prev,
        [`${type}`]: [
          ...prev[type],
          {
            type:
              option ||
              `Custom ${
                type.charAt(0).toUpperCase() + type.slice(1, type.length - 1)
              }`,
            value: defaultValue,
          },
        ],
      }));
    },
    [setContactInfo]
  );

  /**
   * Automatically moves cursor to next input field on pressing Enter
   */
  const handleEnter = useCallback(
    (event, idx) => {
      // Checks if Enter key is pressed
      if (event.keyCode === 13) {
        event.preventDefault();
        const currentInputCategory = event.target.name;
        /** If a current form Category has multiple entries, move cursor to next entry field
         * within the same category (e.g. for a client that has multiple email fields open)
         */
        if (contactInfo[currentInputCategory].length > idx + 1) {
          const nextInputField =
            inputRefs.current[currentInputCategory][(idx + 1).toString()];
          nextInputField.focus();
        } else {
          const inputCategories = Object.keys(inputRefs.current);
          const currentInputCategoryIdx =
            inputCategories.indexOf(currentInputCategory);
          const nextCategory = inputCategories[currentInputCategoryIdx + 1];
          /* Checks if contactInfo is setup to receive data for next input field;
           * If not, it calls/simulates onClick by calling add() to open input field
           */
          if (contactInfo[nextCategory].length === 0) {
            if (nextCategory === "dates")
              add(nextCategory, "Birthday", new Date());
            else if (nextCategory !== "notes") add(nextCategory);
          }
          // setNextInputType(nextCategory);
        }
      }
    },
    [contactInfo, add]
  );
  const ifTags =
    contactResource?.currentTags || contactResource?.originalResource ? 1 : 0;
  const lengthOfElements =
    contactInfo?.phoneNumbers?.length +
    contactInfo?.emails?.length +
    contactInfo?.socials?.length +
    contactInfo?.websites?.length +
    contactInfo?.dates?.length +
    ifTags;

  return (
    <>
      <div
        className={`min-w-full grid relative ${loading && "loading"}`}
        style={{
          gridTemplateColumns: "repeat(2, 1fr)",
          columnGap: "3.5rem",
        }}
      >
        {contactInfo?.phoneNumbers?.map((item, index) =>
          !editingContact ? (
            displayItem(item)
          ) : (
            <div
              style={{ width: "99%" }}
              className="flex border-b border-gray-450 h-20 max-h-fit border-opacity-50 items-center"
              key={`${item?.type}-${item?.value}`}
            >
              <div className="flex h-24 flex-row min-w-full justify-between items-center">
                <Select
                  labelClassName={boldCN(true)}
                  options={getAvailableOptions(
                    contactInfo?.phoneNumbers,
                    phoneOptions
                  )}
                  value={contactInfo?.phoneNumbers[index].type}
                  onChange={(type) => changeType(type, index, "phoneNumbers")}
                  disabled={saving}
                />
                <div className="flex items-center pr-10 w-2/3">
                  <Input
                    placeholder="Phone Number"
                    mainClassName="flex"
                    mainWrapperClassName="w-full"
                    inputContainerClassName="w-full bg-brandGreen bg-opacity-10"
                    inputClassName="bg-transparent w-full text-gray-450 font-md p-2"
                    value={contactInfo?.phoneNumbers[index].value}
                    onChange={(val) => change(val, index, "phoneNumbers")}
                    onRemove={() => remove(index, "phoneNumbers")}
                    // eslint-disable-next-line no-return-assign
                    forwardedRef={(el) =>
                      (inputRefs.current.phoneNumbers[index.toString()] = el)
                    }
                    handleEnter={(event) => handleEnter(event, index)}
                    name="phoneNumbers"
                    autoFocus
                    isPhoneNumber
                    disabled={saving}
                  />
                </div>
              </div>
            </div>
          )
        )}

        {contactInfo?.emails?.map((item, index) =>
          !editingContact ? (
            displayItem(item)
          ) : (
            <div
              style={{ width: "99%" }}
              className="flex border-b border-gray-450 h-20 max-h-fit border-opacity-50 items-center"
              key={`${item?.type}-${item?.value}`}
            >
              <div className="flex h-24 flex-row min-w-full justify-between items-center">
                <Select
                  labelClassName={boldCN(true)}
                  options={getAvailableOptions(
                    contactInfo?.emails,
                    EmailOptions
                  )}
                  value={contactInfo?.emails[index].type}
                  onChange={(type) => changeType(type, index, "emails")}
                  disabled={saving}
                />
                <div className="flex items-center pr-10 w-2/3">
                  <Input
                    placeholder="Email"
                    mainClassName="flex"
                    mainWrapperClassName="w-full"
                    inputContainerClassName="w-full bg-brandGreen bg-opacity-10"
                    inputClassName="bg-transparent w-full text-gray-450 font-md p-2"
                    validation={yup.string().email().required()}
                    value={contactInfo?.emails[index].value}
                    onChange={(val) => change(val, index, "emails")}
                    showValidationErrorAtBottom
                    onRemove={() => remove(index, "emails")}
                    // eslint-disable-next-line no-return-assign
                    forwardedRef={(el) =>
                      (inputRefs.current.emails[index.toString()] = el)
                    }
                    handleEnter={(event) => handleEnter(event, index)}
                    name="emails"
                    autoFocus
                    disabled={saving}
                  />
                </div>
              </div>
            </div>
          )
        )}
        {contactInfo?.websites?.map((item, index) =>
          !editingContact ? (
            displayItem(item, true)
          ) : (
            <div
              style={{ width: "99%" }}
              className="flex border-b border-gray-450 h-20 max-h-fit border-opacity-50 items-center"
              key={`${item?.type}-${item?.value}`}
            >
              <div className="flex h-24 flex-row min-w-full justify-between items-center">
                <Select
                  labelClassName={boldCN(true)}
                  options={getAvailableOptions(
                    contactInfo?.websites,
                    EmailOptions
                  )}
                  value={contactInfo?.websites[index].type}
                  onChange={(type) => changeType(type, index, "websites")}
                  disabled={saving}
                />
                <div className="flex items-center pr-10 w-2/3">
                  <Input
                    placeholder="Website Url"
                    mainClassName="flex"
                    mainWrapperClassName="w-full"
                    inputContainerClassName="w-full bg-brandGreen bg-opacity-10"
                    inputClassName="bg-transparent w-full text-gray-450 font-md p-2"
                    value={contactInfo?.websites[index].value}
                    onChange={(val) => change(val, index, "websites")}
                    showValidationErrorAtBottom
                    onRemove={() => remove(index, "websites")}
                    // eslint-disable-next-line no-return-assign
                    forwardedRef={(el) =>
                      (inputRefs.current.websites[index.toString()] = el)
                    }
                    handleEnter={(event) => handleEnter(event, index)}
                    name="websites"
                    autoFocus
                    disabled={saving}
                  />
                </div>
              </div>
            </div>
          )
        )}
        {contactInfo?.socials?.map((item, index) =>
          !editingContact ? (
            displayItem(item, true)
          ) : (
            <div
              style={{ width: "99%" }}
              className="flex border-b border-gray-450 h-20 max-h-fit border-opacity-50 items-center"
              key={`${item?.type}-${item?.value}`}
            >
              <div className="flex h-24 flex-row min-w-full justify-between items-center">
                <Select
                  labelClassName={boldCN(true)}
                  options={getAvailableOptions(
                    contactInfo?.socials,
                    socialOptions
                  )}
                  value={contactInfo?.socials[index].type}
                  onChange={(type) => changeType(type, index, "socials")}
                  disabled={saving}
                />
                <div className="flex items-center pr-10 w-2/3">
                  <Input
                    placeholder="Socials"
                    mainClassName="flex"
                    mainWrapperClassName="w-full"
                    inputContainerClassName="w-full bg-brandGreen bg-opacity-10"
                    inputClassName="bg-transparent w-full text-gray-450 font-md p-2"
                    value={contactInfo?.socials[index].value}
                    onChange={(val) => change(val, index, "socials")}
                    showValidationErrorAtBottom
                    onRemove={() => remove(index, "socials")}
                    // eslint-disable-next-line no-return-assign
                    forwardedRef={(el) =>
                      (inputRefs.current.socials[index.toString()] = el)
                    }
                    handleEnter={(event) => handleEnter(event, index)}
                    name="socials"
                    autoFocus
                    disabled={saving}
                  />
                </div>
              </div>
            </div>
          )
        )}
        {contactInfo?.dates?.length > 0 &&
          (!editingContact ? (
            contactInfo?.dates?.map((date) =>
              displayItem({
                key: `${date.value}-${date.type}`,
                type: date.type,
                value: moment(date.value).format("MMM DD, YYYY"),
                isPill: false,
              })
            )
          ) : (
            <>
              {contactInfo?.dates?.map((date, index) => (
                <div
                  style={{ width: "99%" }}
                  className="flex border-b border-gray-450 h-20 max-h-fit border-opacity-50 items-center"
                  key={`${date?.value}-${date?.type}`}
                >
                  <div className="flex h-24 flex-row min-w-full justify-between items-center">
                    <SelectOne
                      className={boldCN(true)}
                      options={getAvailableOptions(
                        contactInfo?.dates,
                        dateOptions
                      ).map((opt) => ({
                        label: opt,
                        value: opt,
                      }))}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${date}-${index}`}
                      value={contactInfo?.dates[index].type}
                      onChange={(type) => changeType(type, index, "dates")}
                      hideText
                      type="date"
                      disabled={saving}
                      placeholder="+ Add Date"
                    />
                    <div className="flex flex-row items-center pr-10 w-2/3">
                      <DatePicker
                        className="w-full"
                        validation={yup.date()}
                        value={contactInfo?.dates[index].value}
                        onChange={(val) => changeDate(val, index)}
                        name="dates"
                        disabled={saving}
                      />
                      <TrashButton
                        onClick={() => !saving && remove(index, "dates")}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </>
          ))}
        {!onBoarding &&
        (contactResource?.originalResource?.tags?.length ||
          contactResource?.currentTags?.length ||
          editingContact) ? (
          <div className="flex flex-col min-w-full border-b justify-center border-gray-450 border-opacity-50 items-center">
            <div className="flex flex-row w-full h-full justify-between items-center">
              <h3 className={boldCN(false, "justify-center self-center")}>
                Tags
              </h3>
              <div className="flex flex-row w-2/3 justify-center items-center">
                <TagsContainer
                  className="flex flex-col justify-end mt-4 h-full w-full items-center pr-16 mr-3"
                  tagsWrapperClassName="min-w-full h-full mt-4"
                  dropdownClassName="min-w-full pt-4"
                  resource={contactResource}
                  dispatch={contactDispatch}
                  isEditing={editingContact}
                  fullWidth
                />
              </div>
            </div>
          </div>
        ) : null}
        <div className="bg-white absolute w-full h-1 bottom-0 right-0 pr-4" />
      </div>
      {/* Button row bottom left */}
      {lengthOfElements % 2 ? <div /> : null}
      {editingContact && (
        <div className="flex flex-row gap-4 mb-2">
          <TertiaryButton
            onHover={false}
            title="+ Add New Phone"
            className="whitespace-nowrap text-darkenedGreen border border-darkenedGreen mt-2"
            onClick={() =>
              add(
                "phoneNumbers",
                getAvailableOptions(contactInfo?.phoneNumbers, PhoneOptions)[0]
              )
            }
            disabled={saving || contactInfo?.phoneNumbers?.length === 4}
          />
          <TertiaryButton
            onHover={false}
            title="+ Add New Email"
            className="whitespace-nowrap text-darkenedGreen border border-darkenedGreen mt-2"
            onClick={() =>
              add(
                "emails",
                getAvailableOptions(contactInfo?.emails, Options)[0]
              )
            }
            disabled={saving || contactInfo?.emails?.length === 3}
          />
          <TertiaryButton
            onHover={false}
            title="+ Add New Website"
            className="whitespace-nowrap text-darkenedGreen border border-darkenedGreen mt-2"
            onClick={() =>
              add(
                "websites",
                getAvailableOptions(contactInfo?.websites, Options)[0]
              )
            }
            disabled={saving || contactInfo?.websites?.length === 3}
          />
          <TertiaryButton
            onHover={false}
            title="+ Add New Date"
            className="whitespace-nowrap text-darkenedGreen border border-darkenedGreen mt-2"
            onClick={() =>
              add(
                "dates",
                getAvailableOptions(contactInfo?.dates, DateOptions)[0]
              )
            }
            disabled={saving}
          />
        </div>
      )}
    </>
  );
};

const reviewItemShape = PropTypes.arrayOf(
  PropTypes.shape({
    type: PropTypes.string,
    value: PropTypes.string,
  })
);

ContactInfoSubComponents.propTypes = {
  /**
   * onboarding: Bool to show or not show tags as they currently do not work with profile create flow
   */
  onBoarding: PropTypes.bool,
  contactInfo: PropTypes.shape({
    phoneNumbers: reviewItemShape,
    emails: reviewItemShape,
    addresses: reviewItemShape,
    websites: reviewItemShape,
    notes: PropTypes.string,
    socials: reviewItemShape,
    dates: reviewItemShape,
    gender: PropTypes.string,
    kind: PropTypes.string,
    disciplines: PropTypes.arrayOf(PropTypes.string),
    territories: PropTypes.arrayOf(
      PropTypes.shape({
        area: PropTypes.string,
        cityCode: PropTypes.string,
      })
    ),
  }),
  setContactInfo: PropTypes.func,
  editingContact: PropTypes.bool,
  loading: PropTypes.bool,
  saving: PropTypes.bool,
  contactResource: PropTypes.shape({
    originalResource: PropTypes.shape({
      tags: PropTypes.arrayOf({}),
    }),
    currentTags: PropTypes.arrayOf({}),
  }),
  contactDispatch: PropTypes.func,
};

ContactInfoSubComponents.defaultProps = {
  onBoarding: undefined,
  contactInfo: undefined,
  editingContact: undefined,
  setContactInfo: () => {},
  loading: true,
  saving: false,
  contactResource: undefined,
  contactDispatch: undefined,
};

export default ContactInfoSubComponents;

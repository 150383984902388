import { useRef, useState } from "react";
import { truncate } from "lodash";
import { useHistory } from "react-router";
import useUserDict from "../../hooks/useUserDict";
import { CONTACT_TABS, GET_CONTACT_PATH } from "../../constants";
import { isNumeric, sortWithSpecialCharacters } from "../../helpers/Utilities";
import useAppPersistence from "../../hooks/persistence/useAppPersistence";

const useCardGrid = ({ row }) => {
  const history = useHistory();
  const { data: userDict } = useUserDict();
  const refs = useRef({});
  const [isActive, setIsActive] = useState();
  const { getActiveTab, getPageSettings } = useAppPersistence();
  const pageSettings = getPageSettings("contacts");

  const onContactClick = (id) => {
    const activeTab = getActiveTab({
      page: "contacts",
      resourceId: id,
    });
    const isCurrentPersistedResource = pageSettings?.resource?.id === id;
    const activeTabOrDefault = isCurrentPersistedResource
      ? activeTab
      : CONTACT_TABS.DETAILS_ID;
    history.push(`${GET_CONTACT_PATH(id)}?tab=${activeTabOrDefault}`);
  };

  const returnOrganized = () => {
    let alphaIndexMap = {};
    row?.forEach(({ original: contact }) => {
      const name =
        contact?.name?.firstName?.toUpperCase() ||
        // sort on last lame in case first name is not provided
        contact?.name?.lastName?.toUpperCase() ||
        contact?.company?.value?.toUpperCase();

      let firstLetter = name && name[0];

      if (isNumeric(firstLetter)) {
        firstLetter = "#";
      }

      if (typeof firstLetter === "undefined") {
        return;
      }
      if (typeof alphaIndexMap[firstLetter] === "undefined") {
        alphaIndexMap = {
          ...alphaIndexMap,
          [firstLetter]: [contact],
        };
        return;
      }

      alphaIndexMap = {
        ...alphaIndexMap,
        [firstLetter]: [...alphaIndexMap[firstLetter], contact],
      };
    });
    return alphaIndexMap;
  };

  const orderedObj = Object.keys(returnOrganized())
    .sort(sortWithSpecialCharacters)
    .reduce((obj, key) => {
      // eslint-disable-next-line no-param-reassign
      obj[key] = returnOrganized()?.[key];
      return obj;
    }, {});

  const getCompanyName = (companyRef) => {
    const companyName = userDict[companyRef]?.company?.value ?? "";
    return truncate(companyName, 17, "...");
  };

  return {
    refs,
    getCompanyName,
    orderedObj,
    onContactClick,
    isActive,
    setIsActive,
  };
};

export default useCardGrid;

import React from "react";
import PropTypes from "prop-types";
import TableCellInput from "../../../Table/TableCellInput";
import CurrencyView from "../../../CurrencyView/CurrrencyView";
import Checkbox from "../../../Checkbox/Checkbox";
import useLiveBudgetCellData from "./useLiveBudgetCellData";

const LiveBudgetCell = ({
  row,
  isEditingTable,
  editedRow,
  isActivateProject,
  toggleRange,
  isHighRangeField,
}) => {
  const { inputValue, isHidden } = useLiveBudgetCellData({
    editedRow,
    isHighRangeField,
  });

  if (!isEditingTable) {
    if (isActivateProject) {
      return (
        <div className="flex flex-row">
          <Checkbox
            className="mr-2"
            checked={
              isHighRangeField
                ? row?.original?.isHighRangeSelected
                : row?.original?.isLowRangeSelected
            }
            onChange={(isChecked) => toggleRange(row?.original?.id, isChecked)}
            stopEventPropagation
          />
          <CurrencyView
            value={
              isHighRangeField
                ? row?.original?.highRangeCalculated
                : row?.original?.liveBudget
            }
          />
        </div>
      );
    }
    return (
      <CurrencyView
        value={
          isHighRangeField
            ? row?.original?.highRangeCalculated
            : row?.original?.liveBudget
        }
      />
    );
  }

  return (
    <div className={`flex w-full ${isHidden && "invisible"}`}>
      <TableCellInput value={inputValue} isDisabled />
    </div>
  );
};

LiveBudgetCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      id: PropTypes.string,
      liveBudget: PropTypes.string,
      highRangeCalculated: PropTypes.string,
      isHighRangeSelected: PropTypes.bool,
      isLowRangeSelected: PropTypes.bool,
    }),
  }),
  editedRow: PropTypes.shape({
    isCostRange: PropTypes.bool,
    liveBudget: PropTypes.string,
    highRange: PropTypes.shape({
      liveBudget: PropTypes.string,
    }),
  }),
  isEditingTable: PropTypes.bool,
  isActivateProject: PropTypes.bool,
  toggleRange: PropTypes.func,
  isHighRangeField: PropTypes.bool,
};

LiveBudgetCell.defaultProps = {
  row: undefined,
  editedRow: {},
  isEditingTable: false,
  isActivateProject: false,
  toggleRange: undefined,
  isHighRangeField: false,
};

export default LiveBudgetCell;

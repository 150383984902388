import React from "react";
import TaskList from "./TaskList";
import PlusCircleButton from "../../Buttons/PlusCircleButton/PlusCircleButton";
import { PRIMARY_DARK_GREEN } from "../../../../constants";
import useTasksViewData from "./useTaskViewData";

const Tasks = ({
  canAddTasks,
  tasks,
  handleAddTasks,
  handleRemoveTasks,
  associationInfo,
  showAddAndDeleteTasks,
  setCurrentTask,
}) => {
  const { handleShowTaskModal, handleNavigateToTask } = useTasksViewData({
    canAddTasks,
    handleAddTasks,
    associationInfo,
    setCurrentTask,
  });

  return (
    <div className="gap-10 grid">
      <span className="font-semibold text-lg">Tasks</span>
      {tasks?.length > 0 && (
        <TaskList
          tasks={tasks}
          handleNavigateToTask={handleNavigateToTask}
          handleTasksToDelete={handleRemoveTasks}
          associationInfo={associationInfo}
          showAddAndDeleteTasks={showAddAndDeleteTasks}
        />
      )}

      {showAddAndDeleteTasks && (
        <div>
          <PlusCircleButton
            title="Add Task"
            className="-mt-5"
            style={{ color: PRIMARY_DARK_GREEN }}
            onClick={handleShowTaskModal}
          />
        </div>
      )}
    </div>
  );
};

export default Tasks;

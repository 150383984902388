/**
 * Sets the current page in the user settings.
 *
 * @param {Object} params - The parameters for setting the current page.
 * @param {string} params.url - URL used to determine overview screen
 * @param {Function} params.editSettings - The function to edit the user settings.
 * @param {Object} params.userSettings - The current user settings.
 * @returns {Promise} A promise that resolves when the current screen has been changed.
 */

const modifyCurrentScreen = async ({ url, editSettings, userSettings }) => {
  const newSettings = {
    ...userSettings,
    web: { ...userSettings?.web, currentScreen: url },
  };

  return editSettings(newSettings);
};

export default modifyCurrentScreen;

import React from "react";
import useChildrenTask from "./useChildrenTask";
import ChildTask from "./ChildTask";
import taskIcon from "../../../assets/images/taskIcon.svg";
import chevronRightDarkGreen from "../../../assets/images/chevronRightDarkGreen.svg";

const scrollCN = "flex flex-col gap-2 p-2 overflow-y-auto";

const ChildrenTask = ({ childrenTaskRefs }) => {
  const { childTaskArray, isOpen, setIsOpen } = useChildrenTask({
    childrenTaskRefs,
  });

  return (
    <button
      type="button"
      className="flex flex-col w-full gap-2"
      onClick={() => setIsOpen(!isOpen)}
    >
      <div className="flex flex-row gap-2 items-center">
        <img
          src={taskIcon}
          className="self-start pt-0.5 ml-1"
          alt="View child task list"
          style={{ minHeight: "20px", minWidth: "20px" }}
        />
        <p className="text-es-normal text-es-medium-grey">Child Tasks</p>
        <img
          src={chevronRightDarkGreen}
          className="self-start pt-0.5 ml-1"
          alt="View child task list"
          style={{
            height: "20px",
            width: "20px",
            rotate: isOpen ? "-90deg" : "90deg",
          }}
        />
      </div>
      {isOpen && (
        <div className="flex flex-col p-2 rounded-lg bg-es-extra-light-grey">
          <div
            style={{
              maxHeight: "140px",
            }}
            className={scrollCN}
          >
            {childTaskArray?.map((id) => (
              <div key={id}>
                <ChildTask id={id} />
              </div>
            ))}
          </div>
        </div>
      )}
    </button>
  );
};

export default ChildrenTask;

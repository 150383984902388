/**
 *
 *
 * Checks if a user has read permissions for a specific resource.
 * It considers both 'can_read flags' within the user's permissions.
 *
 * @param {string} resource - The resource for which delete permissions are being checked.
 * @param {Object} user - The user object, which should contain a 'permissions' property.
 * @returns {boolean} True if the user has read permissions for the resource, false otherwise.
 */

const hasReadPermission = (resource, user) => {
  const permissionResource = user?.permissions?.[resource];

  if (!permissionResource || !user) {
    return false;
  }

  const canReadCriteria = permissionResource?.can_read === true;

  return canReadCriteria;
};

export default hasReadPermission;

import cntl from "cntl";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router";
import {
  FILTER_TYPES,
  GET_CONTACT_PATH,
  PTO_CATEGORY,
} from "../../../constants";
import { extractNumbers } from "../../../helpers/Formatters";
import formatPhoneNumber from "../../../helpers/Utilities/formatPhoneNumber";
import Table from "../Table/Table";
import TableDirectReportCell from "../Table/TableDirectReportCell";
import activeIcon from "../../assets/images/es-round-checkmark-green-background.svg";
import inactiveIcon from "../../assets/images/es-round-cross-gray-background.svg";

const TableHeaderSpacing = cntl`
  mb-4
`;

const getDrTotalLength = (dpObj) => {
  const usersLength = dpObj.values.directReport?.user?.length || 0;
  const rolesLength = dpObj.values.directReport?.role?.length || 0;
  return usersLength + rolesLength;
};

const PureMemberTable = ({
  data,
  rowAssociationMenuList,
  ptoCategory,
  isLoading,
}) => {
  const history = useHistory();

  const tableColumns = React.useMemo(() => {
    return [
      {
        Header: "Name",
        accessor: "fullName",
        Cell: ({ row, value }) => {
          return (
            <div
              onClick={() => history.push(GET_CONTACT_PATH(row.id, "0"))}
              role="button"
              onKeyDown={() => {}}
              tabIndex="0"
              className="font-medium text-brandGreen"
            >
              {value}
            </div>
          );
        },
        sortType: (a, b) => {
          // sort by alphabetical order with fname and lname combined
          const aVal = a.values.fullName;
          const bVal = b.values.fullName;

          return aVal.localeCompare(bVal);
        },
        width: 200,
      },
      {
        Header: "Status",
        accessor: "active",
        Cell: ({ value }) => {
          return value ? (
            <div className="flex flex-row">
              <img src={activeIcon} alt="Active notice" className="h-4 w-4 " />
              <p className="pl-2">Active</p>
            </div>
          ) : (
            <div className="flex flex-row">
              <img
                src={inactiveIcon}
                alt="Inactive notice"
                className="h-4 w-4"
              />
              <p className="pl-2">Inactive</p>
            </div>
          );
        },
        sortType: (a, b) => {
          // sort by status (Active or Inactive) in alphabetical order
          const aVal = a.values.active ? "Active" : "Inactive";
          const bVal = b.values.active ? "Active" : "Inactive";
          return aVal.localeCompare(bVal);
        },
      },
      {
        Header: "Email",
        accessor: "email",
        sortType: (a, b) => {
          // sort by alphabetical order with email
          const aVal = a.values.email?.toLowerCase();
          const bVal = b.values.email?.toLowerCase();
          return aVal.localeCompare(bVal);
        },
        width: 300,
      },
      {
        Header: "Mobile",
        accessor: "contactPointValue",
        Cell: ({ value }) => {
          return value === "0" ? "" : formatPhoneNumber(value);
        },
        sortType: (a, b) => {
          // sort by mobile phone number in ascending order
          const contactPointsA = a.values.contactPointValue;
          const contactPointsB = b.values.contactPointValue;

          return (
            extractNumbers(contactPointsA) - extractNumbers(contactPointsB)
          );
        },
      },
      {
        Header: "Company",
        accessor: ({ company }) => {
          return company ?? "";
        },
        width: 300,
        sortType: (a, b) => {
          // Sort by company name in alphabetical order
          const aVal = a.values.company?.toLowerCase() || "";
          const bVal = b.values.company?.toLowerCase() || "";
          return aVal.localeCompare(bVal);
        },
      },
      {
        id: "Employee",
        Header: "Employee",
        accessor: "isEmployee",
        Cell: ({ value }) => {
          return <div>{value ? "Yes" : "No"}</div>;
        },
        sortType: (a, b) => {
          // sort by employee status (Yes or No) in alphabetical order
          const aVal = a.values.isEmployee ? "Yes" : "No";
          const bVal = b.values.isEmployee ? "Yes" : "No";
          return aVal.localeCompare(bVal);
        },
        filterOptions: {
          filterType: FILTER_TYPES.boolean,
        },
        width: 200,
      },
      {
        Header: "Employee ID",
        accessor: "employeeId",
        width: 200,
        sortBy: (a, b) => {
          // sort by employee id in alphabetical order
          const aVal = a.values.employeeId.toLowerCase() || "";
          const bVal = b.values.employeeId.toLowerCase() || "";
          return aVal.localeCompare(bVal);
        },
      },
      {
        Header: "Direct Reports",
        accessor: "directReport",
        Cell: ({ value, row }) => {
          return (
            <TableDirectReportCell
              showBottomTooltip={row?.index === 0}
              value={value}
            />
          );
        },
        width: 300,
        minWidth: 300,
        sortType: (a, b) => {
          // sort by number of direct reports in ascending order
          const aVal = getDrTotalLength(a);
          const bVal = getDrTotalLength(b);

          return aVal - bVal;
        },
      },
      ...(ptoCategory === PTO_CATEGORY.location
        ? [
            {
              Header: "State of Employment",
              accessor: "stateOfEmployment",
              width: 200,
            },
          ]
        : []),
      {
        Header: "Hire Date",
        accessor: "hireDate",
        width: 200,
        Cell: ({ value }) => {
          return value ? (
            <div>{moment.utc(value).format("MMMM D, YYYY")}</div>
          ) : (
            "N/A"
          );
        },
        sortBy: (a, b) => {
          return new Date(a.values.hireDate) - new Date(b.values.hireDate);
        },
      },
      {
        Header: "Role",
        accessor: ({ roleName }) => {
          return roleName ?? "";
        },
        Cell: ({ value }) => {
          return <div className="capitalize">{value}</div>;
        },
      },
      {
        Header: "Invitation",
        accessor: "registered",
        Cell: ({ value }) => {
          return <div>{value ? "Accepted" : "Not-accepted"}</div>;
        },
        sortType: (a, b) => {
          // sort by invitation status (Accepted or Not-accepted) in alphabetical order
          const aVal = a.values.registered ? "Accepted" : "Not-accepted";
          const bVal = b.values.registered ? "Accepted" : "Not-accepted";
          return aVal.localeCompare(bVal);
        },
      },
    ];
  }, [history, ptoCategory]);

  return (
    <Table
      isLoading={isLoading}
      resourceName="Members"
      data={data}
      columns={tableColumns}
      siteHeaderClassName={TableHeaderSpacing}
      rowAssociationMenuList={rowAssociationMenuList}
      showEditTableOptions
      enableEditing
      hideEditRowButton
      hideDeleteRowButton
      showConstantRowIndex
    />
  );
};

PureMemberTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      active: PropTypes.bool,
      avatar: PropTypes.string,
      name: PropTypes.shape({
        firstName: PropTypes.string,
        lastName: PropTypes.string,
      }),
      id: PropTypes.string,
      company: PropTypes.string,
      role: PropTypes.string,
    })
  ),
  rowAssociationMenuList: PropTypes.func,
  ptoCategory: PropTypes.string,
  isLoading: PropTypes.bool,
};

PureMemberTable.defaultProps = {
  data: undefined,
  rowAssociationMenuList: undefined,
  ptoCategory: undefined,
  isLoading: undefined,
};

export default PureMemberTable;

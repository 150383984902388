import { useEffect, useState } from "react";
import { useGetFile } from "../../../../hooks/useGetFile";
import contactDefault from "../../../assets/images/placeholder_contact.svg";
import companyDefault from "../../../assets/images/placeholder_company.svg";

const useContactCardImageData = ({ resourceName, imageReference }) => {
  const { data } = useGetFile(imageReference);

  const placeholder = () => {
    switch (resourceName) {
      case "Contact":
        return contactDefault;
      case "Company":
        return companyDefault;
      default:
        return contactDefault;
    }
  };

  const [image, setImage] = useState(null);

  // TODO (Josymar) This is temporal, until all resources are migrated to imageReference
  useEffect(() => {
    if (imageReference && data?.contentsUrl) {
      setImage(data?.contentsUrl);
    } else setImage(null);
  }, [imageReference, data]);

  return {
    placeholder,
    image,
  };
};

export default useContactCardImageData;

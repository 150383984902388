import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { cloneDeep } from "lodash";
import { PROPERTY_EMPTY } from "../../../../constants";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import { useAppState } from "../../../../state/appState";
import useSystemConfiguration from "../../../../hooks/useSystemConfiguration";
import useManagementConfiguration from "../../../../hooks/useManagementConfiguration";
import {
  useCheckDuplicatedTitle,
  usePropertiesOverview,
} from "../../../../hooks/properties";
import { getTimeZoneOptions } from "../../../../helpers/Time";
import useFilesPost from "../../../../hooks/useFilesPost";
import { formatServerErrorMessage } from "../../../../helpers/Formatters";
import { propertyInfoSchema } from "../../../../helpers/FormValidations";
import formatOwnedByForm from "../../../../helpers/Format/formatOwnedByForm";
import uploadAvatar from "../../../../helpers/uploadAvatar";
import { toastMessage } from "../../Toast/Toast";
import { useAddProperty } from "../../../../hooks/api/properties";

const usePropertyCreateModal = ({ modalData }) => {
  const { data: currentUser, isLoading: isLoadingUser } = useCurrentUser();
  const initialProperty = {
    ...PROPERTY_EMPTY,
    members: [{ user: currentUser?.reference }],
  };
  const [{ editingResource }] = useAppState();
  const { data: systemConfiguration, isLoading: isLoadingManagement } =
    useSystemConfiguration();
  const { data: config, isLoading: isLoadingConfig } =
    useManagementConfiguration();
  const {
    queryInfo: { isLoading: isLoadingProperties },
  } = usePropertiesOverview();

  // async function and state to check duplicated property title
  const { fetchPropertyByTitle, titleExists } = useCheckDuplicatedTitle();

  // Make Sure new Properties have a unique name
  const inputRefs = useRef({ current: {} });
  const [errorMessage, setErrorMessage] = useState();
  const [isInputValid, setIsInputValid] = useState(false);
  const [tmpAvatarImg, setTmpAvatarImg] = useState(
    modalData?.formData?.tmpAvatarImg ?? {}
  );
  const timezoneOptions = getTimeZoneOptions();

  const [isSaving, setIsSaving] = useState(false);
  const { mutateAsync: postFiles } = useFilesPost();
  const { addProperty } = useAddProperty();

  const isLoading =
    isLoadingConfig ||
    isLoadingManagement ||
    isLoadingProperties ||
    isLoadingUser;

  const dataForReducer = {
    ...modalData?.formData,
  };

  delete dataForReducer.tmpAvatarImg;

  const [property, setProperty] = useState({
    ...cloneDeep(initialProperty),
    ...dataForReducer,
  });

  const checkValidation = useCallback(async (formData, validationSchema) => {
    const isValid = await validationSchema.isValid(formData);
    setIsInputValid(isValid);
  }, []);

  useEffect(() => {
    const { title, propertyType, timezone } = property;
    checkValidation({ title, propertyType, timezone }, propertyInfoSchema);
  }, [property, checkValidation, setIsInputValid]);

  const handleCreate = useCallback(async () => {
    setIsSaving(true);
    setErrorMessage(undefined);

    // Assign current user as a member, add system hours of operations
    setProperty((prev) => ({
      ...prev,
      members: [...(prev?.members ?? []), { user: currentUser?.reference }],
      hoursOfOperation: systemConfiguration?.system?.hoursOfOperation,
    }));

    const propertyDataWithImages = {
      ...property,
      description: property?.description?.trim(),
      ownedBy: formatOwnedByForm(property),
    };

    const newPropertyDataWithImages = await uploadAvatar({
      tmpAvatarImg,
      resourceState: propertyDataWithImages,
      postFiles,
    });

    try {
      const data = await addProperty(newPropertyDataWithImages);
      const messageAndLinkProps = {
        resource: data,
        editingResource,
      };
      toastMessage(undefined, messageAndLinkProps);
    } catch (err) {
      const serverMsg = formatServerErrorMessage(err);
      setErrorMessage(serverMsg);
      console.error("handleCreate(), PropertyCreateModal", err);
    }
    setIsSaving(false);
  }, [
    property,
    tmpAvatarImg,
    postFiles,
    currentUser?.reference,
    systemConfiguration?.system?.hoursOfOperation,
    addProperty,
    editingResource,
  ]);

  const handleForwardRef = (key, val) => {
    if (inputRefs?.current) inputRefs.current[key] = val;
  };

  const propertyTypeOptions = useMemo(() => {
    if (!isLoadingConfig) {
      const optionList = [];
      config?.management?.property?.types?.forEach((type) => {
        if (type.selected) {
          optionList.push({ label: type.display, value: type.id });
        }
      });
      return optionList;
    }
    return [];
  }, [config, isLoadingConfig]);

  const timeoutId = useRef(null);

  const handleSimpleInput = async (key, val) => {
    setProperty((prev) => ({
      ...prev,
      [key]: val,
    }));

    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    // debounce check if property title is in use
    if (key === "title") {
      timeoutId.current = setTimeout(async () => {
        await fetchPropertyByTitle(val);
        clearTimeout(timeoutId.current);
      }, 200);
    }
  };

  const handleAddressInput = (val) => {
    setProperty((prev) => ({
      ...prev,
      address: val,
    }));
  };

  const handleMailingSameAsPhysicalInput = (val) => {
    setProperty((prev) => ({
      ...prev,
      mailingSameAsPhysical: val,
    }));

    if (val) {
      setProperty((prev) => ({
        ...prev,
        address: [
          property?.address?.[0],
          {
            attentionTo: "",
            use: "Mailing",
            street: "",
            street2: "",
            city: "",
            state: "",
            country: "",
            zip: "",
          },
        ],
      }));
    }
  };

  const disableSave =
    !isInputValid ||
    !currentUser?.reference ||
    titleExists ||
    isSaving ||
    isLoading;

  return {
    titleExists,
    property,
    propertyTypeOptions,
    isSaving,
    isLoading,
    handleSimpleInput,
    handleForwardRef,
    disableSave,
    handleCreate,
    timezoneOptions,
    tmpAvatarImg,
    setTmpAvatarImg,
    handleAddressInput,
    handleMailingSameAsPhysicalInput,
    inputRefs,
    errorMessage,
  };
};

export default usePropertyCreateModal;

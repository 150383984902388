import { useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";
import removeFromCalendarStore from "../../../helpers/Calendar/removeFromCalendarStore";
import { useAppState } from "../../../state/appState";
import { removeFromCalendarHandler } from "./handlers";
import removeFromCalendarResponder from "./responders/removeFromCalendarResponder";

const useRemoveFromCalendar = () => {
  const queryClient = useQueryClient();
  const [{ calendarData }] = useAppState();

  const {
    mutateAsync,
    isLoading: removeFromCalendarLoading,
    isError: hasRemoveFromCalendarError,
    error: removeFromCalendarError,
    isSuccess: removeFromCalendarSuccess,
  } = useMutation(
    removeFromCalendarHandler,
    removeFromCalendarResponder(queryClient, calendarData)
  );

  /**
   * Currently there is no use case to delete from a task list view, so no new requests need to be made. if that changes this file is set up to be modified
   *
   * @param {Props} Item can be Task or Event
   * @param {boolean} newRequest optional defaults to true
   */
  const removeFromCalendar = useCallback(
    async (props, newRequest = true) => {
      if (!props.args) {
        return console.error(
          "args are required: populate with item you are removing"
        );
      }

      if (!props.operation) {
        return console.error(
          "operation required: populate with $non-recurring, $all, $single or $future"
        );
      }

      if (newRequest === false) {
        // We set the data to props.args.item when a new request isn't needed (ex: task updated from single task view)
        // otherwise data is returned from the request in removeFromCalendarHandler
        return removeFromCalendarStore({
          queryClient,
          calendarData,
          variables: props,
          data: props.args.item,
        });
      }

      const response = await mutateAsync(props);
      return response;
    },
    [calendarData, queryClient, mutateAsync]
  );

  return {
    removeFromCalendar,
    removeFromCalendarLoading,
    hasRemoveFromCalendarError,
    removeFromCalendarSuccess,
    removeFromCalendarError,
  };
};

export default useRemoveFromCalendar;

/* eslint-disable react/jsx-filename-extension */
import { SettingAPI } from "@griffingroupglobal/eslib-api";
import { useCallback, useEffect } from "react";
import _ from "lodash";
import { SET_SETTINGS, USER_SETTINGS_KEYS } from "../constants";
import { useAppState } from "../state/appState";

const useSettings = (settingsKey) => {
  const [{ settings }, dispatch] = useAppState([]);

  const reload = useCallback(async () => {
    const {
      data: { web },
    } = await SettingAPI.get();

    dispatch({
      type: SET_SETTINGS,
      settings: web,
    });
  }, [dispatch]);

  useEffect(() => {
    reload();
  }, [reload]);

  /**
   * @function - update
   * @param {Object} - newSettings
   * @param {string} - newSettings.key
   * @param {*} - newSettings.key.value || newSettings.key.subKey.value
   * @param {string} - newSettings.key.subKey
   * @summary - updates settings appState || returns toastError
   */
  const update = async ({ key, value, subKey }) => {
    /**
     * SubValue
     * @summary - if !key create object with key
     * else update subKey value
     */
    const subValue = settings?.[key]
      ? { ...settings?.[key], [subKey]: value }
      : { [subKey]: value };

    /**
     * Allowed fields to prevent junk collection
     */
    const allowedFields = [
      USER_SETTINGS_KEYS.RESOURCE_TABLE.SUBKEY_1,
      USER_SETTINGS_KEYS.BILLING.SUBKEY_1,
    ];

    /**
     * newSettings
     * @summary - update settings with key & value || subValue
     */
    const newSettings = {
      ...settings,
      [key]: subKey ? _.pick(subValue, allowedFields) : value,
    };

    /**
     * SettingsUpdate
     * @summary - updates web settings + updates app state || returns error toast
     */
    await SettingAPI.update({ web: newSettings })
      .then((res) => {
        dispatch({
          type: SET_SETTINGS,
          settings: res,
        });
      })
      .catch((e) => {
        console.error("Error Updating User Settings", e);
      });
  };

  if (settingsKey && settings) {
    return [settings[settingsKey], update];
  }
  return [settings, update];
};

export default useSettings;

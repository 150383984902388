import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import "./MemberCard.css";
import deleteIcon from "../../assets/images/crossIconGreen.svg";
import userPlaceholderImage from "../../assets/images/userDefaultIcon.svg";
import companyPlaceholderImage from "../../assets/images/companyDefaultIcon.svg";
import { getFullName } from "../../../helpers/Formatters";
import { useGetFile } from "../../../hooks/useGetFile";

const pillStyle = `
flex
items-center
justify-between
gap-3
border
rounded-md
hover:shadow-medium-lift
p-2
m-1
`;

const MemberCard = ({
  user,
  onClick,
  isEditing,
  handleRemove,
  disableRemove,
}) => {
  const { data: avatar } = useGetFile(user?.avatar);

  const handleClick = useCallback(() => {
    if (!isEditing) {
      onClick(user);
    }
  }, [isEditing, onClick, user]);
  const name = useMemo(() => {
    return user?.kind === "company"
      ? user?.companyName
      : getFullName(user?.name);
  }, [user?.companyName, user?.kind, user?.name]);

  return (
    <div style={{ height: "38px" }} className={pillStyle}>
      <button
        type="button"
        className="flex items-center gap-3"
        style={{
          fontSize: "12px",
        }}
        onClick={handleClick}
      >
        <img
          src={
            avatar?.contentsUrl ||
            (user?.kind === "company"
              ? companyPlaceholderImage
              : userPlaceholderImage)
          }
          alt={name}
          style={{
            width: "26px",
            height: "26px",
            objectFit: "cover",
            borderRadius: "100%",
          }}
        />
        <p
          className="font-semibold text-primaryGreen shrink truncate"
          title={name}
        >
          {name}
        </p>
      </button>
      {isEditing && !disableRemove && (
        <button
          onClick={() => handleRemove(user)}
          className="w-4 h-4"
          type="button"
        >
          <img
            src={deleteIcon}
            className="font-semibold w-3 h-3"
            alt="delete-icon"
          />
        </button>
      )}
    </div>
  );
};

export default MemberCard;

MemberCard.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    avatar: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
    name: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
    role: PropTypes.string,
    kind: PropTypes.string,
    title: PropTypes.string,
    companyName: PropTypes.string,
  }),
  onClick: PropTypes.func,
  isEditing: PropTypes.bool,
  handleRemove: PropTypes.func,
  disableRemove: PropTypes.bool,
};

MemberCard.defaultProps = {
  user: {},
  onClick: () => {},
  isEditing: undefined,
  handleRemove: () => {},
  disableRemove: false,
};

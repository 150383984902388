import React from "react";
import PropTypes from "prop-types";
import TertiaryButton from "../../stories/Components/Buttons/TertiaryButton";
import PrimaryButton from "../../stories/Components/Buttons/PrimaryButton";
import useRealTimeInactivityAlertData from "./useRealTimeInactivityAlertData";

const RealTimeInactivityAlert = ({
  isEditing,
  inactivityTimeInSeconds,
  countdownInSeconds,
  onInactivity,
}) => {
  const { showAlert, message, handleContinue, handleExitSesion } =
    useRealTimeInactivityAlertData({
      isEditing,
      inactivityTimeInSeconds,
      countdownInSeconds,
      onInactivity,
    });

  return (
    <div
      className={`fixed inset-0 flex items-center justify-center bg-es-black bg-opacity-50 z-50 ${
        showAlert ? "" : "hidden"
      }`}
    >
      <div
        className="bg-white shadow-medium-lift rounded-md gap-12 relative"
        style={{
          width: "552px",
          padding: "36px 50px 50px 36px",
        }}
      >
        <div className="flex flex-col">
          <h1 className="text-es-dark-grey text-es-lg text-center">
            Still with us?
          </h1>

          <p className="mt-4 text-base font-es-normal text-es-dark-grey text-center">
            {message}
          </p>

          <div className="flex flex-row mt-12 justify-between w-full">
            <PrimaryButton title="Keep Working" onClick={handleContinue} />

            <TertiaryButton
              title="Exit Session"
              className="rounded-md text-es-dark-green"
              onClick={handleExitSesion}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

RealTimeInactivityAlert.defaultProps = {
  isEditing: false,
  inactivityTimeInSeconds: 120,
  countdownInSeconds: 120,
  onInactivity: () => {},
};

RealTimeInactivityAlert.propTypes = {
  isEditing: PropTypes.bool,
  inactivityTimeInSeconds: PropTypes.number,
  countdownInSeconds: PropTypes.number,
  onInactivity: PropTypes.func,
};

export default RealTimeInactivityAlert;

import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import Dropdown from "../Dropdown/Dropdown";
import Checkbox from "../Checkbox/Checkbox";
import { TimeOptions, defaultHoursOfOperation } from "../../../constants";
import Widget from "../Widget/Widget";

const staticSubTitle = cntl`
font-bold 
text-gray-450
text-sm
capitalize
w-2/6
`;

const dayCN = (last) => cntl`
flex 
w-full 
h-20 
items-center 
${!last && "border-b"}
`;

const HoursOfOperation = ({ dispatchFinance, isEditing, resource }) => {
  const hoursOfOperation = resource?.hoursOfOperation;

  const formatTime = (val) => {
    if (val) {
      const [hours, minutes] = val?.split(":");
      const formatedTime = `${hours > 12 ? hours - 12 : hours}:${minutes} ${
        hours > 11 ? "PM" : "AM"
      }`;

      return formatedTime === "00:00 AM" ? "12:00 AM" : formatedTime;
    }
    return "";
  };

  const getLastActiveDay = () => {
    const daysArray = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];

    let lastIndex = 0;

    daysArray?.forEach((item, i) => {
      if (hoursOfOperation?.[item]?.active) lastIndex = i;
    });

    return lastIndex;
  };

  const operationChangeHandler = (key, field, value) => {
    const currentHours = { ...hoursOfOperation };
    const newItem = { ...(currentHours[key] ?? {}) };
    if (field === "active" && value) {
      newItem[field] = value;
      newItem.from = defaultHoursOfOperation?.from?.value;
      newItem.to = defaultHoursOfOperation?.to?.value;
      currentHours[key] = newItem;
    } else {
      newItem[field] = value;
      currentHours[key] = newItem;
    }

    dispatchFinance({
      type: "UPDATE_FIELD",
      payload: {
        key: "hoursOfOperation",
        value: currentHours,
      },
    });
  };

  return (
    <Widget
      overflow
      draggable={false}
      title="Hours of Operation"
      className="mb-4 w-full flex flex-col"
      width="full"
    >
      <div className="flex flex-col w-full">
        <div className="flex w-full justify-end">
          <div className="flex w-4/6">
            {isEditing && (
              <>
                <p className="w-3/5 text-gray-200">From</p>
                <p className="w-2/5 text-gray-200">To</p>
              </>
            )}
          </div>
        </div>
        {Object.keys(hoursOfOperation ?? {}).map((day, i) => (
          <React.Fragment key={day}>
            {isEditing && (
              <div className="flex h-20 w-full">
                <div className="flex w-2/6 items-center">
                  <Checkbox
                    label={`${day[0].toUpperCase()}${day.substring(1)}`}
                    checked={hoursOfOperation[day]?.active}
                    onChange={(checked) =>
                      operationChangeHandler(day, "active", checked)
                    }
                  />
                </div>
                <div className="flex w-4/6 items-center">
                  <div className="flex w-2/5">
                    <Dropdown
                      options={TimeOptions}
                      value={
                        TimeOptions?.find(
                          (item) => item.value === hoursOfOperation[day]?.from
                        ) ||
                        (hoursOfOperation[day]?.active &&
                          defaultHoursOfOperation?.from)
                      }
                      placeholder="Select"
                      onChange={(val) =>
                        operationChangeHandler(day, "from", val.value)
                      }
                      disableClear
                      disableSearch
                      isDisabled={!hoursOfOperation[day]?.active}
                      disableSort
                    />
                  </div>
                  <div className="flex justify-start z-10 w-1/5 h-px">
                    <div className="flex w-full items-center">
                      <div className="w-4/6 h-px bg-gray-200" />
                      <div
                        className={`w-3 h-3 rounded-full ${
                          hoursOfOperation[day]?.active
                            ? "bg-brandGreen"
                            : "bg-gray-200"
                        }`}
                      />
                    </div>
                  </div>
                  <div className="w-2/5">
                    <Dropdown
                      options={TimeOptions}
                      value={
                        TimeOptions?.find(
                          (item) => item.value === hoursOfOperation[day]?.to
                        ) ||
                        (hoursOfOperation[day]?.active &&
                          defaultHoursOfOperation?.to)
                      }
                      placeholder="Select"
                      onChange={(val) => {
                        operationChangeHandler(day, "to", val.value);
                      }}
                      isDisabled={!hoursOfOperation[day]?.active}
                      disableClear
                      disableSearch
                      disableSort
                    />
                  </div>
                </div>
              </div>
            )}
            {!isEditing && hoursOfOperation[day]?.active && (
              <div className={dayCN(getLastActiveDay() === i)}>
                <p className={staticSubTitle}>{day}</p>
                <div className="flex w-4/6">
                  <p className="w-2/5 text-gray-450 text-sm font-normal">
                    {formatTime(hoursOfOperation[day]?.from)}
                  </p>
                  <p className="w-1/5 text-gray-450 text-sm font-normal">-</p>
                  <p className="2/5 text-gray-450 text-sm font-normal">
                    {formatTime(hoursOfOperation[day]?.to)}
                  </p>
                </div>
              </div>
            )}
          </React.Fragment>
        ))}
      </div>
    </Widget>
  );
};

HoursOfOperation.propTypes = {
  resource: PropTypes.shape({
    hoursOfOperation: PropTypes.shape({}),
  }),
  dispatchFinance: PropTypes.func,
  isEditing: PropTypes.bool,
};

HoursOfOperation.defaultProps = {
  resource: undefined,
  dispatchFinance: undefined,
  isEditing: false,
};

export default HoursOfOperation;

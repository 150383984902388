const useTableCellInputData = ({ value, onChange }) => {
  const handleInput = (newVal) => {
    onChange(newVal);
  };

  return {
    input: value,
    handleInput,
  };
};

export default useTableCellInputData;

/**
 * Returns the settings for a specific page.
 *
 * @param {Object} params - The user's settings.
 * @param {string} params.page - The page for which to return the settings.
 * @param {string} params.tab - The tab for which to return the settings.
 * @param {string} params.resourceId - The resourceId to know if this is within a resource
 * @param {Object} params.userSettings - The user's settings.
 * @returns {Object} The settings for the specified page tab.
 */

const returnTabSettings = ({ page, tab, resourceId, userSettings }) => {
  if (resourceId) {
    return userSettings?.web?.[page]?.resource?.tab?.[tab] || {};
  }
  return userSettings?.web?.[page]?.tab?.[tab] || {};
};

export default returnTabSettings;

import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { v4 as uuidv4 } from "uuid";
import { DEFAULT_MEDIA_EXTENSIONS } from "../../../constants";

const useProfileImageDropZone = ({ onChange }) => {
  const [isLoading, setIsLoading] = useState(false);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const files = acceptedFiles.map((file) => ({
        original: file,
        id: uuidv4(),
        path: file.path,
        name: file.name,
        type: file.type,
        size: file.size,
      }));

      onChange(files);
    },
    [onChange]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: DEFAULT_MEDIA_EXTENSIONS,
  });

  return {
    getRootProps,
    getInputProps,
    onDrop,
    onClick: open,
    isLoading,
    setIsLoading,
  };
};

export default useProfileImageDropZone;

/**
 * Upload and update temporary avatar to uploaded avatar
 * @param {object} tmpAvatarImg - state of temporary avatar image
 * @param {object} resourceState - state of resource (i.e. editedProject)
 * @param {Function} postFiles - useFilesPost mutation hook
 * @param {boolean} addToFiles - Add to resource state's files
 * @returns {Promise<object>} Updated resource state.
 */

const uploadAvatar = async ({
  tmpAvatarImg,
  resourceState,
  postFiles,
  addToFiles = true,
  primaryImageKey = "primaryImage",
}) => {
  const updatedResourceState = { ...resourceState };
  const newAvatar = tmpAvatarImg?.tempImageFile;
  if (newAvatar) {
    try {
      // Post file and receive resource reference
      const { reference, category } = (await postFiles([newAvatar]))[0];

      // Optionally add file to the resource state
      if (addToFiles) {
        updatedResourceState.files = [
          ...updatedResourceState?.files,
          { ref: reference, category },
        ];
      }

      // Update the primary image in the resource state
      updatedResourceState[primaryImageKey] = reference;
    } catch (err) {
      console.error("Error uploading avatar:", err);
    }
  }

  return updatedResourceState;
};

export default uploadAvatar;

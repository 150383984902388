import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { GET_SPACE_VIEW_PATH, SPACE_TABS } from "../../../constants";
import { getSpaceConfiguration } from "../../../helpers/Formatters";
import useAppPersistence from "../../../hooks/persistence/useAppPersistence";
import { useProjectsOverview } from "../../../hooks/projects";
import { usePropertiesOverview } from "../../../hooks/properties";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useManagementConfiguration from "../../../hooks/useManagementConfiguration";
import { useSpaceSwitchView } from "../../../hooks/useSwitchView";
import { useGetTags } from "../../../hooks/useTags";
import useTemplatesConfiguration from "../../../hooks/useTemplatesConfiguration";
import { useDeleteSpace, useSpacesList } from "../../../hooks/api/spaces";

const useSpacesListViewData = ({ setActions }) => {
  const history = useHistory();
  const { propertyId, projectId } = useParams();
  const { data: tagsData } = useGetTags();

  const { propertiesDict } = usePropertiesOverview();
  const { projectDict } = useProjectsOverview();
  const { getActiveTab, getPageSettings } = useAppPersistence();
  const pageSettings = getPageSettings("spaces");

  const { data: currentUser } = useCurrentUser();
  const [templatesConfiguration, update, , deleteTemplate] =
    useTemplatesConfiguration();
  const [isShowingSpacesTable] = useSpaceSwitchView();

  const [selectedRows, setSelectedRows] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [modals, setModals] = useState({
    create: false,
    edit: false,
    set: (key, value) => setModals((prev) => ({ ...prev, [key]: value })),
  });
  const { data: managementConfiguration } = useManagementConfiguration();

  const params = useMemo(() => {
    if (propertyId) {
      return {
        route: "property",
        id: propertyId,
        ref: `Property/${propertyId}`,
      };
    }
    return {
      route: "project",
      id: projectId,
      ref: `Project/${projectId}`,
    };
  }, [projectId, propertyId]);

  const { spacesList, spacesListLoading } = useSpacesList(params.ref);
  const { deleteSpace } = useDeleteSpace();

  const spaceConfiguration = useMemo(
    () => getSpaceConfiguration(managementConfiguration),
    [managementConfiguration]
  );

  const dicts = useCallback(() => {
    return {
      Property: propertiesDict,
      Project: projectDict,
    };
  }, [projectDict, propertiesDict]);

  const tableData = useMemo(() => {
    if (spacesList?.length && spaceConfiguration && managementConfiguration) {
      return spacesList?.map((space) => {
        const type = space?.association?.split("/")[0];
        const title = type?.includes("Project") ? "name" : "title";
        return {
          ...space,
          associatedResource: type,
          associatedName: dicts()?.[type]?.[space?.association]?.[title],
          type: spaceConfiguration?.spaces?.[space?.type]?.display,
          level: spaceConfiguration?.levels?.[space?.level]?.display,
          image: space?.primaryImage,
        };
      });
    }

    return [];
  }, [dicts, managementConfiguration, spaceConfiguration, spacesList]);

  const templateSettings = useMemo(() => {
    if (templatesConfiguration?.templates?.space) {
      return templatesConfiguration?.templates?.space?.map((template) => ({
        ...template,
        isAdmin: !template.custom,
      }));
    }

    return [];
  }, [templatesConfiguration]);

  const handleOpenCreate = useMemo(() => {
    if (modals.create || spacesListLoading) {
      return () => {};
    }
    return () => modals.set("create", true);
  }, [spacesListLoading, modals]);

  useEffect(() => {
    if (setActions) {
      setActions((prev) => [
        {
          title: "Add Space",
          onClick: handleOpenCreate,
          tabAction: true,
        },
        ...prev.filter((opt) => opt.title !== "Add Space"),
      ]);
    }
  }, [handleOpenCreate, spacesListLoading, modals, setActions]);

  const updateUserTemplateSettings = useCallback(
    async (customViews) => {
      update({
        key: "space",
        updatedTemplates: [
          ...templateSettings.filter((temp) => !temp.custom),
          ...customViews.map((view) => {
            return {
              ...view,
              custom: true,
            };
          }),
        ],
      });
    },
    [update, templateSettings]
  );

  const deleteUserTemplateSettings = useCallback(
    async (template) => {
      deleteTemplate({
        key: "space",
        id: template.id,
      });
    },
    [deleteTemplate]
  );

  const handleDeleteSpaces = async () => {
    // TODO (This is temporary while new api is created for bulk delete)
    // eslint-disable-next-line no-restricted-syntax
    for (const item of selectedRows) {
      try {
        // eslint-disable-next-line no-await-in-loop
        await deleteSpace(item.id, item.association);
      } catch (error) {
        console.error("error deleting space", item.reference);
      }
    }

    setShowDeleteModal(false);
  };

  const handleRowSelect = useCallback((val) => {
    setSelectedRows(val);
  }, []);

  const onRowClick = (id) => {
    const activeTab = getActiveTab({ page: "spaces", resourceId: id });

    const isCurrentPersistedResource = pageSettings?.resource?.id === id;
    const activeTabOrDefault = isCurrentPersistedResource
      ? activeTab
      : SPACE_TABS.DETAILS_ID;
    history.push(
      `${GET_SPACE_VIEW_PATH[params.route](
        params.id,
        id
      )}?tab=${activeTabOrDefault}`
    );
  };

  const hasWritePermission = useCallback(() => {
    if (propertyId) {
      return currentUser?.hasPermission?.(
        "administrative",
        "can_write_property"
      );
    }

    if (projectId) {
      return currentUser?.hasPermission?.(
        "administrative",
        "can_write_project"
      );
    }
    return false;
  }, [currentUser, projectId, propertyId]);

  // Perm for patching SnC for new type
  const canCreateType = currentUser?.isAdmin;

  return {
    selectedRows,
    onRowClick,
    handleRowSelect,
    handleDeleteSpaces,
    deleteUserTemplateSettings,
    updateUserTemplateSettings,
    tableData,
    modals,
    showDeleteModal,
    templateSettings,
    setShowDeleteModal,
    isShowingSpacesTable,
    hasWritePermission,
    spaceConfiguration,
    isLoading: spacesListLoading,
    tagsData,
    canCreateType,
  };
};

export default useSpacesListViewData;

import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";

import Input from "../Input/Input";
import Dropdown from "../Dropdown/Dropdown";
import EsAddressAutoComplete from "../EsAddressAutoComplete";
import getAddressValueFromKey from "../../../helpers/Utilities/getAddressValueFromKey";

/**
 * 
 * @param {Function} onChange 
 * @returns {Object} - { 
    "address1": {
        "label": {String},
        "value": {Object}
    },
    "street": {String},
    "city": {String},
    "country": {String},
    "state": {String},
    "zipCode": {String},
    "attentionTo": {String}
}}
 */
const BaseAddressInput = ({
  address,
  onChange,
  onKeyPress,
  onForwardRef,
  hideCountry,
  stateOptions,
  isDisabled,
  rowGap,
}) => {
  const [addr, setAddr] = useState(address);
  const inputId = uuidv4();

  const getValueFromKey = useCallback(
    (key) => getAddressValueFromKey({ key, address: addr }),
    [addr]
  );

  /**
   * @function handleFullChange
   * @summary - Sets local state to full google address & passes it to
   * onChange prop function call
   */
  const handleFullChange = useCallback(
    (val) => {
      const fullAddress = {
        ...val,
        // do not overwrite attentionTo field from google auto complete response
        attentionTo: getValueFromKey("attentionTo"),
      };
      setAddr(fullAddress);
      onChange(fullAddress);
    },
    [getValueFromKey, onChange]
  );

  /**
   * @function handleChange
   * @summary - Updates local state with key value pair & passes key value
   * pair to onChange prop function call
   */
  const handleChange = useCallback(
    (key, val) => {
      setAddr({ ...addr, [key]: val });
      onChange({ ...addr, [key]: val });
    },
    [addr, onChange]
  );

  const handleAddressSelect = (val) => {
    // When selecting custom address don't change other values
    if (!val || Object.values(val).length === 1) {
      handleChange("street", !!val && "street" in val ? val?.street : val);
      return;
    }
    handleFullChange(val);
  };

  return (
    <>
      <div className="flex flex-col w-full gap-5">
        <div
          className={`flex flex-row w-full ${rowGap} justify-evenly item-center`}
        >
          <Input
            inputClassName="ESInput"
            name="attentionTo"
            label="Attention To"
            labelClassName="ESInputLabel mb-2"
            mainWrapperClassName="w-full"
            placeholder="Attention To"
            id={`${inputId}attentionTo`}
            value={getValueFromKey("attentionTo")}
            onChange={(val) => handleChange("attentionTo", val)}
            handleEnter={onKeyPress}
            validation={
              getValueFromKey("attentionTo") === null ? false : yup.string()
            }
            forwardedRef={(el) => onForwardRef(`${inputId}attentionTo`, el)}
            disableClear
            disabled={isDisabled}
          />
          <div className="flex flex-col w-full gap-2">
            <p className="ESInputLabel">Street Address</p>
            <EsAddressAutoComplete
              value={getValueFromKey("street") ?? ""}
              id={`${inputId}address1`}
              onChange={handleAddressSelect}
              forwardedRef={(el) => onForwardRef(`${inputId}address1`, el)}
              isDisabled={isDisabled}
            />
          </div>
        </div>
        <div
          className={`flex flex-row w-full ${rowGap} justify-evenly item-center`}
        >
          <Input
            inputClassName="ESInput"
            name="street2"
            label="Apt, Suite, etc"
            labelClassName="ESInputLabel mb-2"
            mainWrapperClassName="w-full"
            placeholder="Apt, Suite..."
            id={`${inputId}street2`}
            value={getValueFromKey("street2")}
            onChange={(val) => handleChange("street2", val)}
            handleEnter={onKeyPress}
            validation={
              getValueFromKey("street2") === null ? false : yup.string()
            }
            forwardedRef={(el) => onForwardRef(`${inputId}street2`, el)}
            disableClear
            disabled={isDisabled}
          />
          <Input
            inputClassName="ESInput"
            label="City"
            labelClassName="ESInputLabel mb-2"
            mainWrapperClassName="w-full"
            name="city"
            placeholder="City"
            id={`${inputId}city`}
            value={getValueFromKey("city")}
            onChange={(val) => handleChange("city", val)}
            handleEnter={onKeyPress}
            validation={getValueFromKey("city") === null ? false : yup.string()}
            forwardedRef={(el) => onForwardRef(`${inputId}city`, el)}
            disableClear
            disabled={isDisabled}
          />
        </div>
        <div
          className={`flex flex-row w-full ${rowGap} justify-evenly item-center`}
        >
          <div className="flex items-center w-full">
            {!stateOptions ? (
              <Input
                mainWrapperClassName="w-full flex"
                label="State"
                labelClassName="ESInputLabel mb-2"
                inputClassName="ESInput"
                name="state"
                placeholder="State"
                id={`${inputId}state`}
                value={getValueFromKey("state")}
                onChange={(val) => handleChange("state", val)}
                handleEnter={onKeyPress}
                validation={
                  getValueFromKey("state") === null ? false : yup.string()
                }
                forwardedRef={(el) => onForwardRef(`${inputId}state`, el)}
                disableClear
                disabled={isDisabled}
              />
            ) : (
              <Dropdown
                label="State"
                labelClassName="ESInputLabel mb-2"
                placeholder="Select"
                options={stateOptions}
                value={stateOptions.find(
                  (item) => item.value === address?.state?.value
                )}
                onChange={(val) => handleChange("state", val)}
                className="ESInput"
                validation={
                  getValueFromKey("state") === null
                    ? false
                    : yup.mixed().required()
                }
                disableSort
                isDisabled={isDisabled}
              />
            )}
          </div>
          <Input
            label="Postal Code"
            labelClassName="ESInputLabel mb-2"
            inputClassName="ESInput"
            mainWrapperClassName="w-full flex"
            name="zipCode"
            placeholder="Postal code"
            id={`${inputId}zipCode`}
            value={getValueFromKey("zipCode")}
            onChange={(val) => handleChange("zipCode", val)}
            handleEnter={onKeyPress}
            validation={
              getValueFromKey("zipCode") === null ? false : yup.string()
            }
            forwardedRef={(el) => onForwardRef(`${inputId}zipCode`, el)}
            disableClear
            disabled={isDisabled}
          />
        </div>
        <div
          className={`flex flex-row w-full ${rowGap} justify-start item-center`}
        >
          {!hideCountry && (
            <Input
              label="Country"
              labelClassName="ESInputLabel mb-2"
              inputClassName="ESInput"
              mainWrapperClassName={`w-1/2 flex ${
                rowGap === "gap-12" ? "pr-6" : "pr-2"
              }`}
              name="country"
              placeholder="Country"
              value={
                getValueFromKey("country")?.label ?? getValueFromKey("country")
              }
              onChange={(val) => handleChange("country", val)}
              handleEnter={onKeyPress}
              validation={
                getValueFromKey("country") === null ? false : yup.string()
              }
              forwardedRef={(el) => onForwardRef(`${inputId}country`, el)}
              disableClear
              disabled={isDisabled}
            />
          )}
        </div>
      </div>
    </>
  );
};

BaseAddressInput.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  address: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  onKeyPress: PropTypes.func,
  onForwardRef: PropTypes.func,
  inputRefs: PropTypes.shape({ current: PropTypes.shape({}) }),
  hideCountry: PropTypes.bool,
  stateOptions: PropTypes.arrayOf(PropTypes.shape({})),
  isDisabled: PropTypes.bool,
  rowGap: PropTypes.string,
};

BaseAddressInput.defaultProps = {
  address: undefined,
  onChange: () => {},
  onKeyPress: () => {},
  onForwardRef: () => {},
  inputRefs: { current: {} },
  hideCountry: false,
  stateOptions: undefined,
  isDisabled: undefined,
  rowGap: "gap-12",
};

export default BaseAddressInput;

import useAuthenticatedQuery from "../../useAuthenticatedQuery";
import fetchSpacesHandler from "./handlers/fetchSpacesHandler";
import spacesKeys from "./spacesKeys";

const useSpacesList = (association) => {
  const queryKey = association
    ? spacesKeys.byAssociation(association)
    : spacesKeys.all;

  const queryString = association ? `?association=${association}` : "";

  const authenticatedResponse = useAuthenticatedQuery({
    queryKey,
    queryFn: ({ signal }) => fetchSpacesHandler(queryString, signal),
    onError: () => {
      console.error("useSpacesList : there was a problem getting spaces list");
    },
  });

  const {
    data: spacesList,
    isLoading: spacesListLoading,
    isError: isSpacesError,
    error: spacesError,
  } = authenticatedResponse;

  return {
    spacesList,
    spacesListLoading,
    isSpacesError,
    spacesError,
  };
};

export default useSpacesList;

import spacesKeys from "../../hooks/api/spaces/spacesKeys";
import sortByProperty from "../Utilities/sortByProperty";

const editSpaceAssociationListStore = ({ queryClient, updatedData }) => {
  const queryKey = spacesKeys.byAssociation(updatedData.association);
  const spacesArr = queryClient.getQueryData(queryKey) ?? [];

  const index = spacesArr.findIndex((space) => space.id === updatedData.id);

  let newSpacesArr;

  if (index !== -1) {
    const oldSpace = spacesArr[index];
    const spaceUpdated = { ...oldSpace, ...updatedData };

    newSpacesArr = [
      ...spacesArr.slice(0, index),
      spaceUpdated,
      ...spacesArr.slice(index + 1),
    ];

    const sortedSpaces = sortByProperty(newSpacesArr, "name");

    queryClient.setQueryData(queryKey, sortedSpaces);
  }
};

export default editSpaceAssociationListStore;

import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import PureWorkflowTable from "./PureWorkflowTable";
import {
  GET_PROJECT_WORKFLOW_VIEW_PATH,
  GET_PROPERTY_WORKFLOW_VIEW_PATH,
  GET_WORKFLOW_VIEW_PATH,
  WORKFLOW_TABS,
} from "../../../constants";
import useProperties from "../../../hooks/useProperties";
import useProjects from "../../../hooks/useProjects";
import useTemplatesConfiguration from "../../../hooks/useTemplatesConfiguration";
import WorkflowData from "../../../helpers/Workflow";
import useAppPersistence from "../../../hooks/persistence/useAppPersistence";

const WorkflowTable = ({
  data,
  handleAddWorkflow,
  sortActiveCardsByOverdue,
  warningCount,
  alertCount,
  warningToggle,
  alertToggle,
  setWarningToggle,
  setAlertToggle,
  bannerComponent,
  tabs,
  associatedResource,
}) => {
  const history = useHistory();
  const [properties] = useProperties();
  const { projects } = useProjects();
  const [templatesConfiguration, update, , deleteTemplate] =
    useTemplatesConfiguration();
  const { getActiveTab, getPageSettings } = useAppPersistence();
  const pageSettings = getPageSettings("workflow");

  const [tableData, setTableData] = useState([]);
  const [templateSettings, setTemplateSettings] = useState([]);

  useEffect(() => {
    const tempArr = data?.map((info) =>
      WorkflowData.formatPayloadForTable(info, properties, projects)
    );

    setTableData(tempArr);
  }, [data, projects, properties]);

  useEffect(() => {
    if (templatesConfiguration) {
      setTemplateSettings(
        templatesConfiguration?.templates?.workflow
          ?.map((template) => ({
            ...template,
            isAdmin: !template.custom,
          }))
          ?.filter((template) => template.name !== "last_updated_column_view")
      );
    }
  }, [templatesConfiguration]);

  const updateUserTemplateSettings = useCallback(
    async (customViews) => {
      update({
        key: "workflow",
        updatedTemplates: [
          ...templateSettings.filter((temp) => !temp.custom),
          ...customViews.map((view) => {
            // eslint-disable-next-line no-param-reassign
            view.custom = true;
            return view;
          }),
        ],
      });
    },
    [update, templateSettings]
  );

  const deleteUserTemplateSettings = useCallback(
    async (template) => {
      deleteTemplate({
        key: "workflow",
        id: template.id,
      });
    },
    [deleteTemplate]
  );

  const handleRowClick = (val) => {
    const activeTab = getActiveTab({
      page: "workflow",
      resourceId: val?.original?.workflowId,
    });
    const isCurrentPersistedResource =
      pageSettings?.resource?.id === val?.original?.workflowId;
    const activeTabOrDefault = isCurrentPersistedResource
      ? activeTab
      : WORKFLOW_TABS.DETAILS_ID;

    if (associatedResource) {
      const associatedResourceType = associatedResource.split("/")[0];

      if (associatedResourceType === "Property") {
        history.push(
          `${GET_PROPERTY_WORKFLOW_VIEW_PATH(
            val?.original?.associationResource.split("/")[1],
            val?.original?.workflowId
          )}?tab=${activeTabOrDefault}`
        );
      } else if (associatedResourceType === "Project") {
        history.push(
          `${GET_PROJECT_WORKFLOW_VIEW_PATH(
            val?.original?.associationResource.split("/")[1],
            val?.original?.workflowId
          )}?tab=${activeTabOrDefault}`
        );
      }
    } else {
      history.push(
        `${GET_WORKFLOW_VIEW_PATH(
          val?.original?.workflowId
        )}?tab=${activeTabOrDefault}`
      );
    }
  };

  return (
    <PureWorkflowTable
      tableData={tableData}
      handleRowClick={handleRowClick}
      templateSettings={templateSettings}
      updateUserTemplateSettings={updateUserTemplateSettings}
      deleteUserTemplateSettings={deleteUserTemplateSettings}
      handleAddWorkflow={handleAddWorkflow}
      sortActiveCardsByOverdue={sortActiveCardsByOverdue}
      warningCount={warningCount}
      alertCount={alertCount}
      warningToggle={warningToggle}
      alertToggle={alertToggle}
      setWarningToggle={setWarningToggle}
      setAlertToggle={setAlertToggle}
      bannerComponent={bannerComponent}
      tabs={tabs}
    />
  );
};

WorkflowTable.propTypes = {
  /**
   * data displayed on table
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      hasAssociatedWorkflows: PropTypes.string,
      changes: PropTypes.arrayOf(
        PropTypes.shape({
          details: PropTypes.string,
          task: PropTypes.string,
          users: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string,
            })
          ),
        })
      ),
      currentStep: PropTypes.number,
      dueDate: PropTypes.string,
      isFlagged: PropTypes.bool,
      initiationDate: PropTypes.string,
      initiatorName: PropTypes.string,
      resolved: PropTypes.bool,
      status: PropTypes.string,
      steps: PropTypes.string,
      totalStep: PropTypes.number,
      version: PropTypes.string,
      warning: PropTypes.bool,
      workflowName: PropTypes.string,
    })
  ),
  handleAddWorkflow: PropTypes.func,
  sortActiveCardsByOverdue: PropTypes.func,
  warningCount: PropTypes.number,
  alertCount: PropTypes.number,
  warningToggle: PropTypes.bool,
  alertToggle: PropTypes.bool,
  setWarningToggle: PropTypes.func,
  setAlertToggle: PropTypes.func,
  bannerComponent: PropTypes.node,
  tabs: PropTypes.arrayOf(PropTypes.node),
  associatedResource: PropTypes.string,
};

WorkflowTable.defaultProps = {
  data: [],
  handleAddWorkflow: undefined,
  sortActiveCardsByOverdue: undefined,
  warningCount: 0,
  alertCount: 0,
  warningToggle: false,
  alertToggle: false,
  setWarningToggle: undefined,
  setAlertToggle: undefined,
  bannerComponent: undefined,
  tabs: undefined,
  associatedResource: undefined,
};
export default WorkflowTable;

import { useDrag, useDrop } from "react-dnd";

export function useDragAndDrop(ref, itemType, payload) {
  const [{ isDragging }, drag] = useDrag({
    item: { type: itemType, ...payload },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: itemType,
    hover: payload.hover,
  });

  drag(drop(ref));

  return {
    isDragging,
  };
}

export default { useDragAndDrop };

import { ReportAPI } from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import { reportsKeys } from "../config/reactQuery/queryKeyFactory";
import { toastError, toastMessage } from "../stories/Components/Toast/Toast";
import mutateReportCache from "../helpers/Report/mutateReportCache";

const patchReport = async ({ id, reportUpdated, report }) => {
  const { data } = await ReportAPI.patch(id, reportUpdated, report);

  return data;
};

/**
 * Mutation hook to patch single report
 * @param {string} [mutationKey] (?) mutation key to track api call
 */
const useReportPatch = (mutationKey) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey,
    mutationFn: patchReport,
    onMutate: async (variables) => {
      const { id, reportUpdated } = variables ?? {};

      await queryClient.cancelQueries(reportsKeys.reports);

      // Save reference of all query data that matches the query key ["reports"]
      // This is going to be used in case of a possible error.
      const previousAllReports = queryClient.getQueriesData(
        reportsKeys.reports
      );

      // Update report in all possible queries that matches the queryKey ["reports"]
      if (previousAllReports?.length) {
        mutateReportCache(queryClient, id, reportUpdated);
      }

      // Returning context in case of any error
      return { previousAllReports };
    },
    onSuccess: (data, variables) => {
      const { id } = variables ?? {};

      // Update report with new data from a database in all possible queries
      mutateReportCache(queryClient, id, data);

      const message = "was updated successfully";

      if (data.isTemplate) {
        toastMessage(`Template ${message}`);
      } else {
        toastMessage(`Report ${message}`);
      }
    },
    onError: (error, _, context) => {
      const { previousAllReports } = context ?? {};

      // Rollback report to previous state in all possible queries
      previousAllReports.forEach(([key, value]) => {
        queryClient.setQueryData(key, value);
      });

      toastError("Report could not be updated. Please try again");
      console.error("useReportPatch", error);
    },
    onSettled: () => {
      (async () => {
        await queryClient.invalidateQueries(reportsKeys.reports);
      })();
    },
  });
};

export default useReportPatch;

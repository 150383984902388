import React from "react";
import PropTypes from "prop-types";
import chevronDown from "../../../assets/images/chevron-down-grey.svg";
import chevronUp from "../../../assets/images/chevron-up-grey.svg";
import useTableCellDropdownData from "./useTableCellDropdownData";
import crossIconGrey from "../../../assets/images/crossIconGrey.svg";

const TableCellDropdown = ({
  value,
  options,
  onChange,
  isGrouped,
  disbaleClear,
}) => {
  const {
    wrapperRef,
    showPopup,
    searchState,
    filteredOptions,
    handleClick,
    handleInput,
    handleClickGrouped,
    toggleList,
    onClear,
  } = useTableCellDropdownData({ value, options, onChange, isGrouped });

  return (
    <div
      ref={wrapperRef}
      className="w-full flex-col mr-4"
      style={{ height: "24px" }}
    >
      <div
        onClick={toggleList}
        role="button"
        onKeyDown={() => {}}
        tabIndex={0}
        aria-label="toggle"
      >
        <div className="flex w-full rounded bg-es-extra-light-grey pl-2">
          <input
            className="flex w-full overflow-clip bg-es-extra-light-grey tracking-es-normal font-es-semibold text-es-dark-grey text-es-normal"
            value={searchState}
            onInput={handleInput}
            onClick={(e) => {
              e?.stopPropagation();
            }}
          />
          <div
            className={`flex relative items-center ${
              (!searchState || disbaleClear) && "invisible"
            }`}
            onClick={onClear}
            role="button"
            onKeyDown={() => {}}
            tabIndex={0}
          >
            <img
              className="w-2 h-2 mx-2"
              src={crossIconGrey}
              alt="close Icon"
            />
          </div>
          <div
            className="flex relative pr-1"
            onClick={toggleList}
            role="button"
            onKeyDown={() => {}}
            tabIndex={0}
            style={{ width: 44 }}
          >
            <img
              style={{ height: 22, width: 22 }}
              src={showPopup ? chevronUp : chevronDown}
              alt="Close"
              className="absolute self-center left-2"
            />
          </div>
        </div>
      </div>
      {isGrouped && showPopup && (
        <div className="absolute w-64 left-0 -bottom-2 z-20 px-5">
          <div className="absolute w-full border shadow-lg flex flex-col justify-center bg-white rounded-lg p-2">
            <div className="flex w-full overflow-x-auto max-h-72">
              <div className="flex flex-col w-full h-full justify-start scrollbar">
                {filteredOptions?.map((option) => (
                  <div className="flex flex-col" key={option.label}>
                    <div className="flex pl-2 text-left tracking-es-normal font-es-medium uppercase text-es-light-grey text-es-sm">
                      {option.label}
                    </div>
                    <div>
                      {option.options.map((item) => (
                        <div
                          key={item?.value}
                          className="flex hover:bg-lightGreen py-2"
                        >
                          <button
                            type="button"
                            className="flex w-11/12 whitespace-normal items-center"
                            onClick={() => handleClickGrouped(item)}
                          >
                            <div className="flex w-full pl-2">
                              <p
                                className="text-left tracking-es-normal font-es-semibold text-es-dark-grey text-es-normal"
                                title={item?.label}
                              >
                                {item?.label}
                              </p>
                            </div>
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
                {!filteredOptions?.length && (
                  <div className="flex py-2">
                    <div className="flex w-11/12 whitespace-normal items-center">
                      <div className="flex w-full pl-2">
                        <p className="text-left tracking-es-normal text-es-light-grey text-es-normal">
                          No Options
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {!isGrouped && showPopup && (
        <div className="absolute w-64 left-0 -bottom-2 z-20 px-5">
          <div className="absolute w-full border shadow-lg flex flex-col justify-center bg-white rounded-lg p-2">
            <div className="flex w-full overflow-x-auto max-h-72">
              <div className="flex flex-col w-full h-full justify-start scrollbar">
                {filteredOptions?.map((item) => (
                  <div
                    key={item?.value}
                    className="flex hover:bg-lightGreen py-2"
                  >
                    <button
                      type="button"
                      className="flex w-11/12 whitespace-normal items-center"
                      onClick={() => handleClick(item)}
                    >
                      <div className="flex w-full pl-2">
                        <p
                          className="text-left tracking-es-normal font-es-semibold text-es-dark-grey text-es-normal"
                          title={item?.label}
                        >
                          {item?.label}
                        </p>
                      </div>
                    </button>
                  </div>
                ))}
                {!filteredOptions?.length && (
                  <div className="flex py-2">
                    <div className="flex w-11/12 whitespace-normal items-center">
                      <div className="flex w-full pl-2">
                        <p className="text-left tracking-es-normal text-es-light-grey text-es-normal">
                          No Options
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

TableCellDropdown.propTypes = {
  value: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  isGrouped: PropTypes.bool,
  disbaleClear: PropTypes.bool,
};

TableCellDropdown.defaultProps = {
  value: undefined,
  options: [],
  onChange: undefined,
  isGrouped: false,
  disbaleClear: false,
};

export default TableCellDropdown;

import React, { Fragment, useCallback, useMemo } from "react";
import TaskCard from "./TaskCard";
import GroupedHeader from "../Table/GroupedHeader";

const TaskCardGrid = ({ rows, onTaskClick, handleOpenComments }) => {
  const isGrouped = useMemo(() => {
    return rows?.[0]?.isGrouped;
  }, [rows]);

  const filteredRows = useCallback((rs) => {
    return rs;
  }, []);

  const card = useCallback(
    (row) => {
      return (
        <TaskCard
          key={row.id}
          onTaskClick={() => {
            onTaskClick(row);
          }}
          handleOpenComments={() =>
            handleOpenComments({
              reference: row?.original?.reference,
              association: row?.original?.association,
            })
          }
          {...row?.original}
        />
      );
    },
    [handleOpenComments, onTaskClick]
  );

  const getTableRow = useCallback(
    (row) => {
      return card(row);
    },
    [card]
  );
  const getTableGroups = useCallback(
    (tableRows, step = 0) => {
      return tableRows.map((row, index) => {
        if (row.isGrouped) {
          return (
            <Fragment key={row.id}>
              <GroupedHeader row={row} step={step} rowIndex={index} />
              <div
                className={`flex flex-wrap w-full ${
                  !row?.subRows[0]?.isGrouped && "gap-x-6 gap-y-9 mt-9 mb-7"
                }`}
              >
                {getTableGroups(filteredRows(row.subRows), step + 1)}
              </div>
            </Fragment>
          );
        }
        return getTableRow(row);
      });
    },
    [getTableRow, filteredRows]
  );

  return (
    <>
      {!isGrouped && (
        <div className="flex w-full gap-x-6 gap-y-9 flex-wrap">
          {rows?.map((row) => (
            <TaskCard
              key={row.id}
              onTaskClick={() => {
                onTaskClick(row);
              }}
              handleOpenComments={() =>
                handleOpenComments({
                  reference: row?.original?.reference,
                  association: row?.original?.association,
                })
              }
              {...row?.original}
            />
          ))}
        </div>
      )}
      {isGrouped && getTableGroups(rows)}
    </>
  );
};

export default TaskCardGrid;

import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import PropTypes from "prop-types";
import AddViewAndFilterPopover from "../AddViewAndFilterPopover/AddViewAndFilterPopover";
import FilterPopOver from "../Table/FilterPopOver";
import IconLabel from "../IconLabel/IconLabel";
import filterIconDarkenedGreen from "../../assets/images/filterIconDarkenedGreen.svg";
import { useAppState } from "../../../state/appState";
import SiteHeaderSelectedFiltersAndGroups from "./SiteHeaderSelectedFiltersAndGroups";

const SiteHeaderTableFilter = ({
  filterPopOverClassName,
  preFilteredRows,
  allColumns,
  groupOptions,
  setGroupBy,
  groups,
  groupDispatch,
  enableGrouping,
  setAllFilters,
  hideColumns,
  setHiddenColumns,
  enableFiltering,
  filters,
  data,
  isTemplateViewOpen,
  updateUserTemplateSettings,
  templateSettings,
  applyTemplate,
  deleteTemplate,
  selectedTemplateSetting,
  clearAllFiltersAndGroups,
  onFilterRequestClose,
}) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const ref = useRef(null);
  const btnRef = useRef(null);
  const [{ mainContainerRef }] = useAppState();

  const handleClose = () =>
    isTemplateViewOpen ? onFilterRequestClose() : setIsFilterOpen(false);

  /**
   * Track scroll position
   * @Summary - keep filter popover fixed under button
   */
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = useCallback(() => {
    const position = mainContainerRef?.scrollTop;
    setScrollPosition(position);
  }, [mainContainerRef?.scrollTop]);

  useEffect(() => {
    mainContainerRef?.addEventListener("scroll", handleScroll, {
      passive: true,
    });

    return () => {
      mainContainerRef?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll, mainContainerRef]);

  const popoverPos = useMemo(() => {
    try {
      const {
        x,
        y,
        height: btnHeight,
      } = btnRef?.current?.getBoundingClientRect();
      const { width, height } = document.body.getBoundingClientRect();
      const styles = {
        top: `${btnHeight + y}px`,
        maxHeight: `${height - (btnHeight + y) - 20}px`,
      };
      if (x > width / 2) {
        styles.right = `${width - x}px`;
      } else {
        styles.left = `${x}px`;
      }

      return { ...styles };
    } catch (e) {
      return {};
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollPosition, ref, btnRef?.current?.getBoundingClientRect()?.x]);

  /**
   * Track scroll position
   */

  return (
    <div
      style={{ height: "20px" }}
      className="flex justify-start items-center relative w-full"
    >
      <div className="flex relative w-full">
        <div ref={btnRef} className="flex flex-row w-full">
          {!selectedTemplateSetting?.id && !isTemplateViewOpen && (
            <div className="flex flex-row w-full">
              <IconLabel
                icon={filterIconDarkenedGreen}
                iconStyle={{
                  width: "16px",
                  height: "18px",
                  marginRight: "4px",
                }}
                text="Filter"
                onClick={() =>
                  btnRef.current ? setIsFilterOpen((prev) => !prev) : () => {}
                }
                textClassName="text-darkenedGreen text-base"
              />
              <SiteHeaderSelectedFiltersAndGroups
                filters={filters}
                groups={groups}
                groupDispatch={groupDispatch}
                setGroupBy={setGroupBy}
                setAllFilters={setAllFilters}
              />
            </div>
          )}
        </div>
        <div
          className="fixed flex z-20"
          ref={ref}
          style={{
            ...popoverPos,
            transitionProperty: "all",
            transitionTimingFunction: "cubic-bezier(0.4, 0, 0.2, 1)",
            transitionDuration: "15ms",
          }}
        >
          {isFilterOpen && (
            <FilterPopOver
              className={`${filterPopOverClassName}`}
              preFilteredRows={preFilteredRows}
              allColumns={allColumns}
              groupOptions={groupOptions}
              setGroupBy={setGroupBy}
              groups={groups}
              groupDispatch={groupDispatch}
              enableGrouping={enableGrouping}
              setAllFilters={setAllFilters}
              hideColumns={hideColumns}
              setHiddenColumns={setHiddenColumns}
              onRequestClose={handleClose}
              enableFiltering={enableFiltering}
              filters={filters}
              tableData={data}
              updateUserTemplateSettings={updateUserTemplateSettings}
              templateSettings={templateSettings}
              applyTemplate={applyTemplate}
              deleteTemplate={deleteTemplate}
              selectedTemplateSetting={selectedTemplateSetting}
              clearAllFiltersAndGroups={clearAllFiltersAndGroups}
              hidePreview
            />
          )}

          {/* Allow saving Templates with filters and groups in Quick View */}
          {isTemplateViewOpen && (
            <AddViewAndFilterPopover
              isOpen={isTemplateViewOpen}
              onRequestModalClose={handleClose}
              allColumns={allColumns}
              groupOptions={groupOptions}
              groups={groups}
              filters={filters}
              tableData={data}
              updateUserTemplateSettings={updateUserTemplateSettings}
              templateSettings={templateSettings}
              selectedTemplateSetting={selectedTemplateSetting}
              applyTemplate={applyTemplate}
              deleteTemplate={deleteTemplate}
              isQuickView
            />
          )}
        </div>
      </div>
    </div>
  );
};

const filtersPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.shape({
      isTrue: PropTypes.bool,
      value: PropTypes.string,
      type: PropTypes.string,
      from: PropTypes.string,
      to: PropTypes.string,
      values: PropTypes.arrayOf(PropTypes.string),
    }),
  })
);

SiteHeaderTableFilter.propTypes = {
  filterPopOverClassName: PropTypes.string,
  groupOptions: PropTypes.shape({
    hierarchicalOptions: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        })
      )
    ),
    nonHierarchicalOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
  enableGrouping: PropTypes.bool,
  setGroupBy: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  enableFiltering: PropTypes.bool,
  setAllFilters: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  allColumns: PropTypes.arrayOf(PropTypes.shape({})),
  isTemplateViewOpen: PropTypes.bool,
  updateUserTemplateSettings: PropTypes.func,
  templateSettings: PropTypes.arrayOf(PropTypes.shape({})),
  selectedTemplateSetting: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    filters: filtersPropType,
    groups: PropTypes.arrayOf(PropTypes.string),
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
  data: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])
    )
  ),
  filters: filtersPropType,
  hideColumns: PropTypes.func,
  applyTemplate: PropTypes.func,
  deleteTemplate: PropTypes.func,
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  groupDispatch: PropTypes.func,
  clearAllFiltersAndGroups: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  preFilteredRows: PropTypes.array,
  setHiddenColumns: PropTypes.func,
  onFilterRequestClose: PropTypes.func,
};

SiteHeaderTableFilter.defaultProps = {
  filterPopOverClassName: undefined,
  enableGrouping: false,
  groupOptions: undefined,
  setGroupBy: undefined,
  enableFiltering: false,
  setAllFilters: undefined,
  allColumns: [],
  isTemplateViewOpen: false,
  updateUserTemplateSettings: undefined,
  templateSettings: [],
  selectedTemplateSetting: undefined,
  data: [],
  filters: [],
  hideColumns: undefined,
  applyTemplate: undefined,
  deleteTemplate: undefined,
  groups: [],
  groupDispatch: undefined,
  clearAllFiltersAndGroups: () => {},
  preFilteredRows: [],
  setHiddenColumns: undefined,
  onFilterRequestClose: undefined,
};

export default SiteHeaderTableFilter;

import { HistoryAPI } from "@griffingroupglobal/eslib-api";
import { useAppState } from "../state/appState";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import {
  historyKeys,
  submittalKeys,
  taskKeys,
  workflowKeys,
} from "../config/reactQuery/queryKeyFactory";

const fetchHistory = async (association) => {
  const { data } = await HistoryAPI.get({
    params: {
      association,
    },
  });
  return data?.entries.map(({ resource }) => resource);
};

export const useHistory = (association, select) => {
  const [{ currentUser }] = useAppState();

  const associationPart = association?.split("/")[0];

  const queryKeyLookup = {
    Submittal: (value) => submittalKeys.submittalHistory(value),
    Workflow: (value) => workflowKeys.workflowHistory(value),
    Task: (value) => taskKeys.taskHistory(value),
  };

  const queryKey =
    queryKeyLookup[associationPart]?.(association) ||
    historyKeys.historyByAssociation(association);

  return useAuthenticatedQuery({
    queryKey,
    enabled: !!currentUser && !!association,
    queryFn: () => fetchHistory(association),
    select,
    onError: (error) => {
      console.error("useHistory", error);
    },
  });
};

/**
 * Query selector hook to format comments with user/author information
 * @param {string} association reference for any resource: Ex: Submital/111-222-333
 * @param {Object} userDict users object
 */
export const useSubmittalHistory = (association) => {
  const selector = (entries) => {
    // create an object that aggregates actions by round
    const roundsObj = entries.reduce((accumulator, currentVal) => {
      const currentRound = currentVal.context.submittal.round; // get round
      // if the accumulator doesnt have an entry for the currentRound, create an empty array to push values into
      if (!accumulator[currentRound]) {
        accumulator[currentRound] = [];
      }
      // push current rounds value into proper round entry
      accumulator[currentRound].push(currentVal);
      return accumulator;
    }, {});

    return roundsObj;
  };

  return useHistory(association, selector);
};

/**
 * Query selector hook to format comments with user/author information
 * @param {string} association reference for any resource: Ex: Workflow/111-222-333
 * @param {Object} userDict users object
 */
export const useWorkflowHistory = (association) => {
  const selector = (entries) => {
    // create an object that aggregates actions by round
    const roundsObj = entries.reduce((accumulator, currentVal) => {
      const currentRound = currentVal.context?.workflow?.round; // get round
      if (currentRound) {
        // if the accumulator doesnt have an entry for the currentRound, create an empty array to push values into
        if (!accumulator[currentRound]) {
          accumulator[currentRound] = [];
        }
        // push current rounds value into proper round entry
        accumulator[currentRound].push(currentVal);
      }

      return accumulator;
    }, {});
    return roundsObj;
  };

  return useHistory(association, selector);
};

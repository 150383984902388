import React, { useCallback, useEffect, useRef, useState } from "react";
import { useQueryClient } from "react-query";
import { isEqual } from "lodash";
import useSystemConfiguration from "../../../../hooks/useSystemConfiguration";
import useSopFormReducer from "../../../../hooks/useSopFormReducer";
import { useCreateSop } from "../../../../hooks/useSop";
import { SOP_EMPTY } from "../../../../constants";
import { uploadFileWithData } from "../../../../helpers/File";
import { filePaginatedKeys } from "../../../../config/reactQuery/queryKeyFactory";
import { sopInfoSchema } from "../../../../helpers/FormValidations";
import useCurrentUser from "../../../../hooks/useCurrentUser";

const useSopCreateModalData = ({ modalData }) => {
  const { data: currentUser } = useCurrentUser();
  const { data: systemConfiguration } = useSystemConfiguration();
  const [showConfirm, setShowConfirm] = useState(false);

  const { mutate: createSop, isLoading } = useCreateSop();
  const queryClient = useQueryClient();

  const [stepData, setStepData] = useState([]);

  const [catOptions, setCatOptions] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [isInputValid, setIsInputValid] = useState(false);
  const [filesToUpload, setFilesToUpload] = useState(
    modalData?.formData?.filesToUpload
  );

  const formattedTags =
    modalData?.formData?.currentTags?.map((tag) => tag.value) || [];

  // If creating a new Sop, format tags to just have reference.
  const dataForReducer = {
    ...modalData?.formData,
    tags: formattedTags,
  };

  delete dataForReducer?.filesToUpload;
  delete dataForReducer?.originalResource?.originalResource;

  const [sopState, sopDispatch] = useSopFormReducer(dataForReducer);

  useEffect(() => {
    const isSame = isEqual(SOP_EMPTY, sopState);
    setShowConfirm(!isSame);
  }, [setShowConfirm, sopState]);

  /**
   * Automatically moves cursor to next input field on pressing Enter
   */
  const inputRefs = useRef({ sopLinks: {} });
  const handleEnter = useCallback((event, idx) => {
    // checks if the Enter key was pressed
    if (event.keyCode === 13) {
      event.preventDefault();
      // Handles condition where cursor is inside the sop links category
      if (event.target.name === "name") {
        inputRefs.current.sopLinks[idx].url.focus();
      } else if (event.target.name === "url") {
        /* if the cursor is inside the url input field under the sop link category,
          move cursor to the next sop name field if it exists */
        if (Object.keys(inputRefs.current.sopLinks).length > idx + 1)
          inputRefs.current.sopLinks[(idx + 1).toString()].name.focus();
        // if another set of sop link name/url fields are not open, move to next sop input category (i.e location)
        else inputRefs.current.location.focus();
      } else {
        const inputCategories = Object.keys(inputRefs.current);
        const currentInputCategoryIdx = inputCategories.indexOf(
          event.target.name
        );
        const nextInputCategory =
          inputRefs.current[inputCategories[currentInputCategoryIdx + 1]];
        if (nextInputCategory) nextInputCategory.focus();
      }
    }
  }, []);

  const onAddFile = async (doc, data = {}, progressCallback) => {
    const fileResource = await uploadFileWithData(
      doc,
      data,
      progressCallback,
      undefined,
      true,
      undefined,
      true
    );
    return fileResource;
  };

  const onUpload = useCallback(async (files, progressCallback) => {
    const handleProgressCallback = (loaded, total, filename) => {
      progressCallback(loaded, total, filename);
    };

    const result = await Promise.all(
      files.map(async ({ name, docType, isFavorited, original }) => {
        const data = {
          name,
          docType,
          isFavorited,
          contentType: original.type,
          size: original.size,
        };
        const resource = await onAddFile(original, data, (loaded, total) =>
          handleProgressCallback(loaded, total, name)
        );

        return resource;
      })
    );

    return result;
  }, []);

  const handleFilesAdded = React.useCallback(
    (addedFiles) => {
      setFilesToUpload(addedFiles);
    },
    [setFilesToUpload]
  );
  const handleFilesUpdated = (updatedFiles) => {
    setFilesToUpload(updatedFiles);
  };

  const handleFilesUploaded = useCallback(async () => {
    const filteredFiles = filesToUpload.filter((file) => !file.isEditing);
    const res = await onUpload(filteredFiles, () => {});

    // update files in overview
    queryClient.invalidateQueries(filePaginatedKeys.allFiles);

    setFilesToUpload([]);
    return res;
  }, [filesToUpload, onUpload, queryClient]);

  useEffect(() => {
    if (systemConfiguration) {
      const categories = systemConfiguration.system?.sop?.category;
      setCatOptions(() => {
        const catList = categories.map((cat) => {
          return { label: cat.display, value: cat.id };
        });
        return catList;
      });
    }
  }, [systemConfiguration]);

  // eslint-disable-next-line no-shadow
  const checkValidation = useCallback(async (formData, validationSchema) => {
    const isValid = await validationSchema.isValid(formData);
    setIsInputValid(isValid);
  }, []);

  useEffect(() => {
    checkValidation(
      {
        category: sopState?.category,
        name: sopState?.name,
      },
      sopInfoSchema
    );
  }, [sopState, checkValidation]);

  const handleCreate = async () => {
    setIsSaving(true);
    try {
      // upload files
      const filesUploadedResults = await handleFilesUploaded();
      const uploadedDocs = filesUploadedResults?.map((f) => ({
        ref: f.reference,
        category: f.category,
      }));

      sopState.files = [...uploadedDocs];

      sopState.primaryImage = sopState.files.find(
        (file) => file.category === "Photos"
      )?.ref;
      sopState.name = sopState.name.trim();
      sopState.links = sopState.links.filter((l) => l.url?.length);
      sopState.dateCreated = Date.now();
      sopState.lastUpdatedBy = currentUser?.reference;
      sopState.tags = sopState?.currentTags?.map((tag) => tag?.value) || [];
      const cleanSop = {
        ...sopState,
        steps: sopState?.steps
          ?.filter((step) => step?.description !== "")
          ?.map((stp, idx) => ({ ...stp, position: idx + 1 })),
      };

      createSop({ sop: cleanSop });
    } catch (err) {
      console.warn("error posting SOP");
    } finally {
      setIsSaving(false);
    }
  };

  const removeAttachedFile = useCallback(
    (id) => {
      const remainingFiles = filesToUpload.filter(
        (_file, index) => index !== id
      );
      setFilesToUpload(remainingFiles);
    },
    [filesToUpload]
  );

  return {
    filesToUpload,
    removeAttachedFile,
    showConfirm,
    setShowConfirm,
    sopDispatch,
    isLoading,
    stepData,
    setStepData,
    catOptions,
    isSaving,
    handleEnter,
    handleFilesAdded,
    isInputValid,
    handleFilesUpdated,
    handleFilesUploaded,
    inputRefs,
    handleCreate,
    currentUser,
    sopState,
  };
};

export default useSopCreateModalData;

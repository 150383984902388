import { FinanceAPI } from "@griffingroupglobal/eslib-api";

const fetchFinanceHandler = async (association, signal) => {
  const { data } = await FinanceAPI.getWOP(`?association=${association}`, {
    signal,
  });

  return data?.entries[0]?.resource;
};

export default fetchFinanceHandler;

import spacesKeys from "../../hooks/api/spaces/spacesKeys";
import sortByProperty from "../Utilities/sortByProperty";

/**
 * Add new space to the association space list cache store
 * @param {Object} params
 * @param {QueryClient} params.queryClient - query client object
 * @param {Object} params.space - new space created
 */
const addToAssociationSpaceListStore = ({ queryClient, space }) => {
  const queryKey = spacesKeys.byAssociation(space.association);

  const associationSpacesCache = queryClient.getQueryData(queryKey);

  // Association spaces list cache hasn't been initialized, we don't need to create a cache manually
  if (!associationSpacesCache) {
    return;
  }

  // Check if Space already exists in the cache.
  const foundSpace = associationSpacesCache.find(
    (existing) => existing.id === space.id
  );

  if (foundSpace) {
    return;
  }

  queryClient.setQueryData(queryKey, (oldData) => {
    const newSpacesArr = [space, ...oldData];
    return sortByProperty(newSpacesArr, "name");
  });
};

export default addToAssociationSpaceListStore;

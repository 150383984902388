import { useCallback, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { useModalState } from "../../../../state/modalState";
import { useAppState } from "../../../../state/appState";
import {
  ADD_OPEN_MODAL,
  CREATE_TASK_MODAL,
  SET_TASK_FOR_SERVICE_REQUEST,
} from "../../../../constants";

const useTasksViewData = ({
  canAddTasks,
  handleAddTasks,
  associationInfo,
  setCurrentTask,
}) => {
  const [{ taskForRequest }, appStateDispatch] = useAppState();
  const [, modalDispatch] = useModalState();

  // This effect listen for new Tasks created from Task Modal
  useEffect(() => {
    if (taskForRequest) {
      // Add the task reference to the SR form
      handleAddTasks(taskForRequest);
    }

    return () => {
      // Reset state
      appStateDispatch({
        type: SET_TASK_FOR_SERVICE_REQUEST,
        taskForRequest: undefined,
      });
    };
  }, [taskForRequest, handleAddTasks, appStateDispatch]);

  const handleShowTaskModal = () => {
    if (canAddTasks) {
      modalDispatch({
        type: ADD_OPEN_MODAL,
        modalType: CREATE_TASK_MODAL,
        ref: { id: uuidv4() },
        modalData: {
          associationLock: associationInfo?.reference,
          // Lock task recurrence to 'Does not repeat' option
          recurrenceLock: true,
          createdWithinResource: true,
        },
      });
    }
  };

  const handleNavigateToTask = useCallback(
    (task) => {
      setCurrentTask(task);
    },
    [setCurrentTask]
  );

  return {
    handleShowTaskModal,
    handleNavigateToTask,
  };
};

export default useTasksViewData;

import cntl from "cntl";

import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import Dropdown from "../Dropdown/Dropdown";
import Input from "../Input/Input";
import Widget from "../Widget/Widget";
import TagsContainer from "../Tags/TagsContainer";
import AssociationLink from "../AssociationLink";

const onlyNumbersAndDecimals = /^-?(?:\d+|\d{1,3}(?:,\d{3})+)?(?:\.\d+)?$/;
const boldCN = (isFirst, className) => cntl`
  w-1/3
  text-gray-450
  font-bold
  items-center
  h-full
  ${!isFirst && "mt-1"}
  ${className}
`;

const textCN = (isFirst, className) => cntl`
  font-normal
  text-gray-450
  text-base
  w-2/3
  ${className}
  ${!isFirst && "mt-1"}
`;

const dddWrapperCN = (first, last) => cntl`
  flex
  w-full
  ${!first && "mt-4"}
  ${!last && "border-b"}
  border-gray-450
  border-opacity-50
  items-center
  pb-5
`;

const inputItemCN = cntl`
  bg-green-50
  w-full
  px-6
`;

const SpaceOverviewWidget = ({
  associationInfo,
  space,
  dispatch,
  disableEditing,
  configuration,
  isEditing,
  isLoading,
}) => {
  const spaceTypeOptions = useMemo(
    () =>
      Object.values(configuration?.spaces ?? {})?.map((item) => ({
        label: item?.display,
        value: item?.id,
      })),
    [configuration?.spaces]
  );

  const levelTypeOptions = useMemo(
    () =>
      Object.values(configuration?.levels ?? {})?.map((item) => ({
        label: item?.display,
        value: item?.id,
      })),
    [configuration?.levels]
  );

  const onTypeChange = (value) => {
    dispatch({
      type: "type",
      value,
    });
  };

  const onLevelChange = (value) => {
    dispatch({
      type: "level",
      value,
    });
  };

  const changeArea = useCallback(
    (value) => {
      if (onlyNumbersAndDecimals.test(String(value))) {
        dispatch({
          type: "area",
          value,
        });
      }
    },
    [dispatch]
  );

  return (
    <Widget
      title="Space Information"
      draggable={false}
      className="h-fit"
      overflow
      loading={isLoading}
    >
      {!isEditing ? (
        <div className="flex flex-col pt-6 pr-5">
          <div className="flex flex-col justify-center">
            <div className={dddWrapperCN(false, true)}>
              <h3 className={boldCN(true)}>Association</h3>
              <h3 className={textCN(true)}>
                <AssociationLink
                  associationName={associationInfo?.associationName}
                  associationRef={associationInfo?.associationRef}
                />
              </h3>
            </div>
            <div className={dddWrapperCN(false, true)}>
              <h3 className={boldCN(true)}>Type</h3>
              <h3 className={textCN(true)}>
                {configuration?.spaces?.[space?.type]?.display}
              </h3>
            </div>
            <div className={dddWrapperCN(false, true)}>
              <h3 className={boldCN(true)}>Level</h3>
              <h3 className={textCN(false)}>
                {configuration?.levels?.[space?.level]?.display}
              </h3>
            </div>
            <div className={dddWrapperCN(false, true)}>
              <h3 className={boldCN(true)}>Square Footage</h3>
              <h3 className={textCN(false)}>{space?.area?.value}</h3>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-col pt-6 pr-5">
          <div className="flex flex-col gap-4">
            <div className={dddWrapperCN(true, false)}>
              <h3 className={boldCN(true)}>Type</h3>
              <Dropdown
                className="ml-3 w-2/3 relative"
                options={[...spaceTypeOptions]}
                value={spaceTypeOptions?.find(
                  (item) => item?.value === space?.type
                )}
                onChange={(value) => onTypeChange(value?.value)}
                isDisabled={disableEditing}
              />
            </div>
            <div className={dddWrapperCN(true, false)}>
              <h3 className={boldCN(true)}>Level</h3>
              <Dropdown
                className="ml-3 w-2/3 relative"
                options={levelTypeOptions}
                value={levelTypeOptions?.find(
                  (item) => item?.value === space?.level
                )}
                onChange={(value) => onLevelChange(value?.value)}
                isDisabled={disableEditing}
              />
            </div>
            <div className={dddWrapperCN(true, false)}>
              <p className={boldCN(true)}>Square Footage</p>
              <div className="ml-4 w-2/3 relative">
                <Input
                  inputClassName={inputItemCN}
                  value={space?.area?.value}
                  onChange={(val) => changeArea(val)}
                  placeholder="Square Ft."
                  validation={yup.number()}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={dddWrapperCN(false, true)}>
        {isEditing ? null : <p className={boldCN(true)}>Tags</p>}
        <TagsContainer
          className="w-full"
          tagsWrapperClassName="w-2/3 pl-14"
          dropdownClassName="w-1/3"
          dispatch={dispatch}
          resource={space}
          isEditing={isEditing}
        />
      </div>
    </Widget>
  );
};

SpaceOverviewWidget.propTypes = {
  space: PropTypes.shape({
    name: PropTypes.string,
    level: PropTypes.string,
    type: PropTypes.string,
    area: PropTypes.shape({
      value: PropTypes.string,
    }),
  }),
  isLoading: PropTypes.bool,
  dispatch: PropTypes.func,
  disableEditing: PropTypes.bool,
  configuration: PropTypes.shape({
    levels: PropTypes.shape({}),
    spaces: PropTypes.shape({}),
  }),
  isEditing: PropTypes.bool,
  associationInfo: PropTypes.shape({
    associationRef: PropTypes.string,
    associationName: PropTypes.string,
  }),
};

SpaceOverviewWidget.defaultProps = {
  space: undefined,
  isLoading: undefined,
  dispatch: undefined,
  disableEditing: true,
  configuration: undefined,
  isEditing: false,
  associationInfo: {},
};

export default SpaceOverviewWidget;

import { useQueryClient } from "react-query";
import getTaskListReqParams from "../../../helpers/Task/getTaskListReqParams";
import useAuthenticatedQuery from "../../useAuthenticatedQuery";
import fetchTasksHandler from "./handlers/fetchTasksHandler";
import taskKeys from "./taskKeys";

const useTaskList = () => {
  const queryClient = useQueryClient();

  const authenticatedResponse = useAuthenticatedQuery({
    queryKey: taskKeys.taskList(getTaskListReqParams()),
    queryFn: ({ signal }) => fetchTasksHandler(getTaskListReqParams(), signal),
    staleTime: 0,
    onError: () => {
      console.error("useTaskList : there was a problem getting task list");
    },
  });

  const refetchTaskList = async () => {
    await queryClient.invalidateQueries(
      taskKeys.taskList(getTaskListReqParams())
    );
  };

  const {
    data: taskList,
    isLoading: taskLoading,
    isError: isTaskError,
    error: taskError,
  } = authenticatedResponse;

  return {
    refetchTaskList,
    taskList,
    taskLoading,
    isTaskError,
    taskError,
  };
};

export default useTaskList;

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import CreateContactForm from "../../stories/Components/ContactForm/CreateContactForm";
import useUsers from "../../hooks/useUsers";

const PageCN = cntl`
  lg:flex
  flex
  flex-initial
  flex-col
`;

const CreateContact = ({
  inviteNewContact,
  contactDispatch,
  contact,
  tmpAvatarImg,
  setTmpAvatarImg,
}) => {
  const [users] = useUsers();
  const [companyContacts, setCompanyContacts] = useState([]);

  useEffect(() => {
    if (users?.length) {
      const companies = users
        ?.filter((user) => user?.kind === "company")
        ?.map((companyContact) => ({
          label: companyContact?.company?.value,
          value: companyContact?.reference,
        }));
      setCompanyContacts(companies);
    }
  }, [users]);

  return (
    <>
      <div className={PageCN}>
        <CreateContactForm
          companyContacts={companyContacts}
          inviteInitialValue={inviteNewContact}
          contactDispatch={contactDispatch}
          contact={contact}
          tmpAvatarImg={tmpAvatarImg}
          setTmpAvatarImg={setTmpAvatarImg}
        />
      </div>
    </>
  );
};

CreateContact.propTypes = {
  inviteNewContact: PropTypes.bool,
  contactDispatch: PropTypes.func,
  contact: PropTypes.shape({}),
  tmpAvatarImg: PropTypes.shape({}),
  setTmpAvatarImg: PropTypes.func,
};

CreateContact.defaultProps = {
  inviteNewContact: false,
  contactDispatch: undefined,
  contact: undefined,
  tmpAvatarImg: { isLoading: false },
  setTmpAvatarImg: undefined,
};

export default CreateContact;

import { useCallback, useMemo } from "react";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useReportsTableFormat from "../../../hooks/useReportsTableFormat";
import { useModalState } from "../../../state/modalState";
import { ADD_OPEN_MODAL, REPORT_CREATE_MODAL } from "../../../constants";

const useReportListData = () => {
  const [{ modals }, modalDispatch] = useModalState();
  const { data: currentUser } = useCurrentUser();

  // Query selector to get all reports in table fomat
  const { data: reports, isLoading } = useReportsTableFormat();

  const canCreate = useMemo(
    () =>
      currentUser?.isAdmin ||
      currentUser?.hasPermission?.("report", "can_write"),
    [currentUser]
  );

  const isDisabledButton = modals?.find(
    (item) => item.modalType === REPORT_CREATE_MODAL
  );

  const handleOpenModal = useCallback(() => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: "new-report-modal" },
      modalData: {},
      modalType: REPORT_CREATE_MODAL,
    });
  }, [modalDispatch]);

  return {
    isDisabledButton,
    canCreate,
    reports,
    isLoading,
    handleOpenModal,
  };
};

export default useReportListData;

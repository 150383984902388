import React, { useRef, useCallback, useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import PropTypes from "prop-types";
import Input from "../Input/Input";
import crossIconWhite from "../../assets/images/crossIconWhite.svg";
import PlusCircleButton from "../Buttons/PlusCircleButton/PlusCircleButton";
import { BRAND_GREY, PRIMARY_DARK_GREEN } from "../../../constants";

const SopStepsContainer = ({
  stepData,
  sopDispatch,
  editing,
  setStepFocus,
  stepFocus,
}) => {
  const inputReference = useRef(null);

  const handleStepChange = useCallback(
    (stp) => {
      if (editing) {
        sopDispatch({
          type: "editStep",
          payload: stp,
        });
      }
    },
    [editing, sopDispatch]
  );

  const canAddStep = useMemo(() => {
    // only if no steps exist or last step is not new step
    return (
      stepData?.length === 0 || !!stepData?.[stepData?.length - 1]?.description
    );
  }, [stepData]);

  const handleAddStep = useCallback(() => {
    if (canAddStep) {
      // focus on the newly added step
      setStepFocus(true);

      sopDispatch({
        type: "addStep",
        payload: {
          id: uuidv4(),
          description: "",
          position: stepData?.length + 1,
          focus: true,
        },
      });
    }
  }, [canAddStep, sopDispatch, stepData, setStepFocus]);

  const handleDeleteStep = useCallback(
    (stp) => {
      const updatedSteps = stepData
        ?.filter((item) => stp.id !== item?.id)
        ?.map((item, index) => ({ ...item, position: index + 1 }));
      sopDispatch({
        type: "updateSteps",
        payload: updatedSteps,
      });
    },
    [sopDispatch, stepData]
  );

  const handleEnter = useCallback(
    (evt) => {
      evt.preventDefault();
      if (evt.key === "Enter") {
        handleAddStep();
      }
    },
    [handleAddStep]
  );

  return (
    <>
      {editing && (
        <div className={`flex flex-col ${stepData?.length > 0 && "gap-7"}`}>
          <div className="flex flex-col gap-6">
            {stepData?.map((stp, index) => (
              <div
                key={stp?.position}
                className="relative flex items-center border rounded-lg h-ful w-full p-4"
              >
                <p className="flex justify-center items-start font-semibold w-8">
                  {stp?.position}
                </p>
                <p className="flex h-full w-full">
                  <Input
                    autoFocus={stepFocus && index + 1 === stepData?.length}
                    ref={inputReference}
                    value={stp?.description}
                    onChange={(value) =>
                      handleStepChange({
                        ...stp,
                        description: value,
                      })
                    }
                    handleEnter={handleEnter}
                    className="ml-5"
                    mainWrapperClassName="w-full"
                    inputClassName="bg-tagGreen pl-4 w-full"
                    placeholder="Instructions"
                  />
                </p>
                <button
                  type="button"
                  className="absolute -top-2.5 -right-2.5"
                  style={{
                    width: "18px",
                    height: "18px",
                  }}
                  onClick={() => handleDeleteStep(stp)}
                >
                  <img
                    alt="delete tag"
                    className="rounded-xl p-1"
                    style={{ backgroundColor: "#027D61" }}
                    src={crossIconWhite}
                  />
                </button>
              </div>
            ))}
          </div>
          <div className="flex items-start">
            <PlusCircleButton
              title="Add Step"
              onClick={() => handleAddStep()}
              className="flex w-full items-center"
              titleClassName="ml-0 mr-2"
              style={{
                color: canAddStep ? PRIMARY_DARK_GREEN : BRAND_GREY,
              }}
              disabled={!canAddStep}
              noHover
            />
          </div>
        </div>
      )}

      {!editing && (
        <div key={stepData.id}>
          <div className="">
            {stepData?.map((stp) => (
              <div
                key={stp?.position}
                className="relative flex items-center w-full mb-3"
              >
                <p className="flex justify-center font-semibold text-gray-500 w-11">
                  {stp?.position}.
                </p>
                <p className="flex w-full">{stp?.description}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};
SopStepsContainer.propTypes = {
  stepData: PropTypes.arrayOf(
    PropTypes.shape({
      step: PropTypes.string,
      position: PropTypes.number,
      description: PropTypes.string,
    })
  ),
  step: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    position: PropTypes.string,
  }),
  sopDispatch: PropTypes.func,
  editing: PropTypes.bool,
  setStepFocus: PropTypes.func,
  stepFocus: PropTypes.bool,
};

SopStepsContainer.defaultProps = {
  stepData: [],
  step: {},
  sopDispatch: undefined,
  editing: false,
  setStepFocus: () => {},
  stepFocus: false,
};
export default SopStepsContainer;

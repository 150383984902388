import { useCallback, useState } from "react";
import { useHistory } from "react-router";
import { ACCOUNTANDADMIN_TABS, MEMBERS_INVITE_PATH } from "../../../constants";

export default function useAccountAndAdminViewData() {
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const [isEditing, setIsEditing] = useState();
  const [isCancellingSubscription, setIsCancellingSubscription] =
    useState(false);
  const [isExportingMemberList, setIsExportingMemberList] = useState(false);
  const [isInvitingMember, setIsInvitingMember] = useState(false);

  const onSave = useCallback(() => {
    setIsSaving(true);
  }, [setIsSaving]);

  const onCancel = () => {
    setIsCancel(true);
  };

  const dropDownItems = useCallback(
    (tab) => {
      switch (tab) {
        case ACCOUNTANDADMIN_TABS.SETTINGSANDCONFIG_ID:
          return [];

        case ACCOUNTANDADMIN_TABS.MEMBERS_ID:
          return [
            {
              title: "Invite Member",
              onClick: () => {
                history.push(MEMBERS_INVITE_PATH);
              },
            },
            {
              title: "Export Member List",
              onClick: () => {
                setIsExportingMemberList(true);
              },
            },
          ];
        case ACCOUNTANDADMIN_TABS.PERMISSIONS_ID:
          return [];
        case ACCOUNTANDADMIN_TABS.BILLING_ID: {
          return [];
        }
        default:
          return [];
      }
    },
    [history, setIsExportingMemberList]
  );

  return {
    isSaving,
    setIsSaving,
    isEditing,
    setIsEditing,
    isCancel,
    setIsCancel,
    onSave,
    onCancel,
    dropDownItems,
    isCancellingSubscription,
    setIsCancellingSubscription,
    isExportingMemberList,
    setIsExportingMemberList,
    isInvitingMember,
    setIsInvitingMember,
  };
}

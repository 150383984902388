import {
  AssetAPI,
  ProjectAPI,
  PropertyAPI,
} from "@griffingroupglobal/eslib-api";

const associationAPI = {
  Property: PropertyAPI,
  Project: ProjectAPI,
  Asset: AssetAPI,
};

/**
 * Fetch an association by reference
 * @param {String} reference -  The Association reference
 * @param {AbortController} [signal]
 * @returns - The association data
 */
const getAssociationByReference = async (reference, signal) => {
  if (!reference) {
    throw Error("Association reference was not provided");
  }

  const [resource] = reference.split("/");

  const { data } = await associationAPI[resource].getByRef(reference, {
    signal,
  });

  return data;
};

export default getAssociationByReference;

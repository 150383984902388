import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Widget from "../Widget/Widget";
import Table from "../Table/Table";
import TableActionsIconsGroup from "../Table/TableActionsIconsGroup";
import TagList from "../TagList";
import Modal from "../Modal/Modal";
import IconButton from "../Buttons/IconButton";
import PopoverComments from "../PopoverComments";
import useServiceRequestTableViewData from "./useServiceRequestTableViewData";
import commentIcon from "../../assets/images/comment_icon_updated.svg";
import { FILTER_TYPES } from "../../../constants";
import FormatedPriorityLevelLabel from "../PriorityLevelLabel";

const REQUEST_GROUPING = {
  hierarchicalOptions: [],
  nonHierarchicalOptions: [
    {
      label: "Association",
      value: "associationName",
    },
    {
      label: "Status",
      value: "status",
    },
    {
      label: "Created By",
      value: "createdBy",
    },
    {
      label: "Tags",
      value: "tags",
    },
    {
      label: "Priority",
      value: "priority",
    },
  ],
};

const priorityOrder = ["low", "medium", "high"];

const ServiceRequestsTable = ({
  serviceRequestTickets,
  association,
  isLoading,
  onRequestClicked,
}) => {
  // Presenter hook
  const {
    canDelete,
    hideSwitchView,
    totalSelectedRows,
    showDeleteDialog,
    commentsData,
    ticketTemplates,
    setShowDeleteDialog,
    handleSelectRows,
    handleDeleteTickets,
    handleOpenComments,
    handleCloseComments,
    handleTicketSelected,
    handleSaveTemplates,
    handleDeleteTemplate,
  } = useServiceRequestTableViewData(
    association,
    serviceRequestTickets,
    onRequestClicked
  );

  // Memoize columns and data
  const [columns, data] = useMemo(() => {
    const tableColumns = [
      {
        Header: "Comments",
        accessor: "comments",
        Cell: ({ row }) => {
          return (
            <IconButton
              icon={commentIcon}
              onClick={() => {
                handleOpenComments(row);
              }}
            />
          );
        },
        maxWidth: 150,
        disableSortBy: true,
        disableFilters: true,
        Aggregated: () => null,
      },
      {
        Header: "Request",
        accessor: "issue",
        minWidth: 400,
        maxWidth: 600,
        showPopover: true,
      },
      {
        Header: "Created by",
        accessor: "createdBy",
        minWidth: 200,
        filterOptions: {
          label: "Created by",
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "From",
        accessor: "onBehalf",
        minWidth: 200,
      },
      {
        Header: "Association",
        accessor: "associationName",
        minWidth: 200,
        filterOptions: {
          label: "Association",
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Priority",
        accessor: "priority",
        minWidth: 100,
        Cell: ({ row }) => {
          return (
            <FormatedPriorityLevelLabel
              priority={row?.original?.priority ?? "medium"}
              textCN="text-es-dark-grey"
            />
          );
        },
        sortType: (a, b) => {
          const indexA = priorityOrder.indexOf(a.values.priority);
          const indexB = priorityOrder.indexOf(b.values.priority);

          return (
            (indexA === -1 ? priorityOrder.length : indexA) -
            (indexB === -1 ? priorityOrder.length : indexB)
          );
        },
        Aggregated: () => null,
      },
      {
        Header: "Status",
        accessor: "status",
        minWidth: 100,
        filterOptions: {
          label: "Status",
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Date/Time",
        accessor: "createdAt",
        minWidth: 200,
      },
      {
        Header: "Tags",
        accessor: "tags",
        Cell: ({ row }) => {
          return (
            <TagList
              tags={row?.original?.tags}
              tagClassName="py-1 px-2"
              showCount
            />
          );
        },
        minWidth: 300,
        filterOptions: {
          label: "Tags",
          filterType: FILTER_TYPES.tags,
        },
      },
    ];

    return [tableColumns, serviceRequestTickets];
  }, [serviceRequestTickets, handleOpenComments]);

  return (
    <Widget loading={isLoading} title={null} draggable={false} backToTop>
      <Table
        data={data}
        groupOptions={REQUEST_GROUPING}
        columns={columns}
        cellTextStyling="text-es-medium-grey text-es-normal font-es-normal"
        allowSelection
        showConstantRowIndex
        templateSettings={ticketTemplates}
        updateUserTemplateSettings={handleSaveTemplates}
        deleteUserTemplateSettings={handleDeleteTemplate}
        hideSwitchView={hideSwitchView}
        showColumnSettingsLeft={false}
        resourceName="tickets"
        onSelectedRowChange={handleSelectRows}
        onRowClick={({ index }) => handleTicketSelected(index)}
        defaultSort={[{ id: "priority", desc: true }]}
        fileActionsIcons={
          <TableActionsIconsGroup
            style={{ height: "100%" }}
            handleShowDelete={() => setShowDeleteDialog(true)}
            showColumnSettingsRight
            canDelete={canDelete}
            disabled={!totalSelectedRows}
          />
        }
        hideGroupedFooter
      />

      {commentsData.isOpen && (
        <PopoverComments
          isOpen={commentsData.isOpen}
          resourceReference={commentsData.ticketReference}
          association={commentsData.association}
          onClose={handleCloseComments}
        />
      )}

      {showDeleteDialog && (
        <Modal
          alert
          title={`Delete ${totalSelectedRows > 1 ? "Requests" : "Request"}`}
          isOpen={showDeleteDialog}
          primaryButtonTitle="Yes, delete"
          primaryButtonOnClick={handleDeleteTickets}
          tertiaryButtonTitle="Cancel"
          onRequestModalClose={() => setShowDeleteDialog(false)}
          shouldCloseOnOverlayClick
          shouldCloseOnEsc
          hideFooter
        >
          <p className="text-base mb-2">
            {`Are you sure you want to delete ${
              totalSelectedRows > 1
                ? `these ${totalSelectedRows} requests`
                : "this request"
            }? Once deleted, it cannot be recovered.`}
          </p>
        </Modal>
      )}
    </Widget>
  );
};

ServiceRequestsTable.defaultProps = {
  serviceRequestTickets: [],
  association: undefined,
  isLoading: false,
  onRequestClicked: undefined,
};

ServiceRequestsTable.propTypes = {
  serviceRequestTickets: PropTypes.arrayOf(PropTypes.shape({})),
  association: PropTypes.string,
  isLoading: PropTypes.bool,
  onRequestClicked: PropTypes.func,
};

export default ServiceRequestsTable;

import { useMutation, useQueryClient } from "react-query";
import addPropertyHandler from "./handlers/addPropertyHandler";
import addPropertyResponder from "./responders/addPropertyResponder";
import addToPropertyListStore from "../../../helpers/Properties/addToPropertyListStore";
import addToPropertyOverviewStore from "../../../helpers/Properties/addToPropertyOverviewStore";

const useAddProperty = () => {
  const queryClient = useQueryClient();

  const createMutation = useMutation(
    addPropertyHandler,
    addPropertyResponder(queryClient)
  );

  /**
   * Adds property to list and overview, can specify whether to make a new request or not
   *
   * @param {Props} property
   * @param {boolean} newRequest optional defaults to true, if false is sent an api request will not be attempted
   */
  const addProperty = async (props, newRequest = true) => {
    if (newRequest === false) {
      addToPropertyListStore({ queryClient, property: props });
      return addToPropertyOverviewStore({ queryClient, property: props });
    }

    const response = await createMutation.mutateAsync(props);
    return response;
  };

  const {
    isLoading: isAddingProperty,
    isError: isAddingPropertyError,
    error: addingPropertyError,
  } = createMutation;

  return {
    isAddingProperty,
    isAddingPropertyError,
    addingPropertyError,
    addProperty,
  };
};

export default useAddProperty;

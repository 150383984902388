import moment from "moment-timezone";
import { rrulestr } from "rrule";
import { fromRRuleOutput, isValidRecurrence } from "../rRule";

const getEndDate = (event, newStartDate) => {
  let endDate = moment.utc(newStartDate);

  if (event.endDate) {
    // calculate duration
    const duration = moment
      .utc(event.endDate)
      .diff(moment.utc(event.startDate), "s");
    endDate = moment.utc(newStartDate).add(duration, "s");
  }

  return endDate.toISOString();
};

/**
 * Creates request items based on a given item and time limits.
 *
 * @param {object} item - The item to process, containing a recurrence string and other properties.
 * @param {object} limits - An object containing left and right time limits.
 * @returns {Array} - An array of new recurring items with adjusted start and end dates.
 */
const createReqItems = (item, limits) => {
  const recurrenceString = item?.recurrence;

  if (!recurrenceString) {
    return [
      {
        resource: item,
        uri: `/api/${item.resource}/${item.id}`,
        search: {
          mode: "match",
        },
      },
    ];
  }

  if (item.recurrence) {
    const isValid = isValidRecurrence({
      rRuleString: item.recurrence,
    });

    if (!isValid) {
      return console.error(
        "creating rec items: this is not a valid recurrence string"
      );
    }
  }

  const rrule = rrulestr(recurrenceString);
  const tzString = item.timezone;

  const leftMoment = moment.tz(limits.left, tzString).utc();
  const rightMoment = moment.tz(limits.right, tzString).utc();

  const leftDate = leftMoment.toDate();
  const rightDate = rightMoment.toDate();

  const occurrences = rrule.between(leftDate, rightDate);
  const formattedDates = occurrences.map((date) => fromRRuleOutput(date));

  const newRecurringItems = formattedDates.map((date) => {
    const newEndDate = getEndDate(item, date);

    const newFormattedEvent = {
      resource: {
        ...item,
        instanceStartDate: date,
        startDate: date,
        endDate: newEndDate,
      },
      uri: `/api/${item.resource}/${item.id}`,
      search: {
        mode: "match",
      },
    };

    return newFormattedEvent;
  });

  return newRecurringItems;
};

export default createReqItems;

import { EventAPI, TaskAPI } from "@griffingroupglobal/eslib-api";
import moment from "moment";
import { MOMENT_UTC_ES_FORMAT } from "../../../../constants";

const removeFromCalendarHandler = async ({ args, operation }) => {
  if (operation === "$non-recurring") {
    const API = args?.resource === "Task" ? TaskAPI : EventAPI;

    const response = await API.delete(args.id);
    return response;
  }

  if (operation === "$all") {
    const API = args?.resource === "Task" ? TaskAPI : EventAPI;

    const deleteAllString = `${args.id}/${operation}`;

    const { data } = await API.delete(deleteAllString);
    return data;
  }

  if (operation === "$single") {
    const API = args.item.resource === "Task" ? TaskAPI : EventAPI;

    const deleteString = `${args.item.id}/${operation}`;

    const deleteParams = {
      params: `instanceStartDate=${args.item.instanceStartDate}`,
    };

    const { data } = await API.delete(deleteString, deleteParams);

    return data;
  }

  if (operation === "$future") {
    const API = args.item.resource === "Task" ? TaskAPI : EventAPI;

    const deleteString = `${args.item.id}/${operation}`;
    const dateToParse = moment.parseZone(args.item.startDate);

    const dateFormattedToUTC = dateToParse.utc().format(MOMENT_UTC_ES_FORMAT);

    const deleteParams = {
      params: `instanceStartDate=${dateFormattedToUTC}`,
    };

    const { data } = await API.delete(deleteString, deleteParams);

    return data;
  }

  return null;
};

export default removeFromCalendarHandler;

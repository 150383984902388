import React from "react";
import Pill from "../../../stories/Components/Pill/Pill";
import PopoverComments from "../../../stories/Components/PopoverComments";
import useDetailsViewData from "./useDetailsViewData";
import Dropdown from "../../../stories/Components/Dropdown/Dropdown";
import Input from "../../../stories/Components/Input/Input";
import GridDetailRow from "../../../stories/Components/GridDetailRow";
import TagsSelector from "../../../stories/Components/TagsSelector";
import WidgetEditorView from "../../../stories/Components/WidgetEditorView";
import RoleMembersAvatarList from "../../Admin/RoleMembersAvatarList";
import { getFullName } from "../../../helpers/Formatters";
import AssociationLink from "../../../stories/Components/AssociationLink";

const textCN = "text-gray-450 text-sm";

const Details = ({
  canEdit,
  canDelete,
  serviceRequest,
  editableRequestForm,
  isEditing,
  ticketTypeOptions,
  handleChangeForm,
  handleTags,
  onBeginEditing,
  onCancelEditing,
  onFinishEditing,
  onDeletePressed,
  children,
  detailsRowCN,
  labelClassName,
}) => {
  const {
    priorityOptions,
    commentsData,
    inProgress,
    handleOpenComments,
    handleCloseComments,
  } = useDetailsViewData(serviceRequest);

  const getUserElement = (createdBy) => {
    return createdBy?.name ? (
      <div className="flex items-center">
        <RoleMembersAvatarList className="relative" members={[createdBy]} />
        <p className={textCN}>{getFullName(createdBy?.name)}</p>
      </div>
    ) : (
      "TBD"
    );
  };

  return (
    <WidgetEditorView
      title="Request Information"
      titleClassName="font-es-semibold text-es-lg"
      canEdit={canEdit}
      canDelete={canDelete}
      isEditing={isEditing}
      hideFooter={!isEditing}
      onBeginEditing={onBeginEditing}
      onCancelEditing={onCancelEditing}
      onFinishEditing={onFinishEditing}
      onDeletePressed={onDeletePressed}
      onOpenComments={handleOpenComments}
      childrenWrapperCN="mt-0"
      cancelClassName="w-28 h-10"
      saveClassName="h-10"
      commentClassName="h-10"
    >
      <div className="flex gap-x-2 items-center">
        <div className="w-36">
          <Pill
            value={serviceRequest?.readOnly.status}
            textSize="text-xs"
            background="bg-white"
            border={`border ${
              inProgress ? "border-darkenedGreen" : "border-gray-450"
            }`}
            textColor={inProgress ? "text-darkenedGreen" : "text-gray-450"}
          />
        </div>
      </div>

      <div className="flex w-full border-b border-gray-450 border-opacity-50 items-center pt-6 pb-4">
        <div className="w-1/3">
          <h3 className={labelClassName}>Type</h3>
        </div>
        <div className="w-full mr-12">
          <p className={detailsRowCN}>
            {!isEditing ? (
              ticketTypeOptions.find(
                ({ value }) => value === editableRequestForm.type
              )?.label ?? "Not specified"
            ) : (
              <div className="w-56">
                <Dropdown
                  options={ticketTypeOptions}
                  value={ticketTypeOptions.find(
                    ({ value }) => value === editableRequestForm.type
                  )}
                  placeholder="Select Type"
                  onChange={({ value }) => {
                    handleChangeForm("type", value);
                  }}
                />
              </div>
            )}
          </p>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4 mt-4">
        <GridDetailRow
          labelClassName={labelClassName}
          label="Association"
          className={detailsRowCN}
          isEditing={false}
          readOnlyValue={
            <AssociationLink
              associationName={serviceRequest?.readOnly.association}
              associationRef={serviceRequest?.original?.association}
            />
          }
        />

        {serviceRequest?.readOnly?.asset && (
          <GridDetailRow
            labelClassName={labelClassName}
            label="Asset"
            className={detailsRowCN}
            isEditing={false}
            readOnlyValue={
              <AssociationLink
                associationName={serviceRequest?.readOnly.asset}
                associationRef={serviceRequest?.original?.asset}
              />
            }
          />
        )}

        <GridDetailRow
          labelClassName={labelClassName}
          label="Created by"
          className={detailsRowCN}
          isEditing={isEditing}
          readOnlyValue={getUserElement(
            serviceRequest?.readOnly.createdByFullUser
          )}
          editableComponent={getUserElement(
            serviceRequest?.readOnly.createdByFullUser
          )}
        />

        <GridDetailRow
          labelClassName={labelClassName}
          label="Request For"
          className={detailsRowCN}
          isEditing={isEditing}
          readOnlyValue={serviceRequest?.readOnly.onBehalf}
          editableComponent={
            <Input
              value={editableRequestForm?.onBehalf}
              inputClassName="w-full bg-green-50 pl-4 pr-8 text-sm"
              inputContainerClassName="bg-green-50"
              onChange={(value) => handleChangeForm("onBehalf", value)}
              placeholder="(Optional)"
            />
          }
        />

        <GridDetailRow
          labelClassName={labelClassName}
          label="Duration"
          className={detailsRowCN}
          isEditing={false}
          readOnlyValue={serviceRequest?.readOnly.duration}
        />

        <GridDetailRow
          labelClassName={labelClassName}
          label="Completed by"
          className={detailsRowCN}
          isEditing={isEditing}
          readOnlyValue={getUserElement(
            serviceRequest?.readOnly.completedByFullUser
          )}
          editableComponent={getUserElement(
            serviceRequest?.readOnly.completedByFullUser
          )}
        />

        <GridDetailRow
          labelClassName={labelClassName}
          hideBorder={!!serviceRequest?.readOnly?.asset}
          label="Priority"
          className={detailsRowCN}
          isEditing={isEditing}
          readOnlyValue={
            priorityOptions?.find(
              ({ value }) => value === editableRequestForm?.priority
            )?.label
          }
          editableComponent={
            <div className="w-1/2">
              <Dropdown
                value={priorityOptions?.find(
                  ({ value }) => value === editableRequestForm?.priority
                )}
                options={priorityOptions}
                onChange={({ value }) => handleChangeForm("priority", value)}
                disableSort
                disableClear
              />
            </div>
          }
        />

        <GridDetailRow
          labelClassName={labelClassName}
          hasBorder={false}
          label="Tags"
          className={detailsRowCN}
          isEditing
          hideBorder
          editableComponent={
            <div className="w-1/2">
              <TagsSelector
                tags={editableRequestForm?.tags}
                isEditing={isEditing}
                isInline={!isEditing}
                onTagAdded={(tag) => handleTags("add", tag)}
                onTagRemoved={(tag) => handleTags("remove", tag)}
              />
            </div>
          }
        />
      </div>

      <div className="relative">{children}</div>

      {commentsData.isOpen && (
        <PopoverComments
          isOpen={commentsData.isOpen}
          resourceReference={commentsData.ticketReference}
          association={commentsData.association}
          onClose={handleCloseComments}
        />
      )}
    </WidgetEditorView>
  );
};

export default Details;

import { useMemo, useState } from "react";
import { moveUserEditingToFirstArrayPosition } from "@griffingroupglobal/eslib-ably/react";

const LIMIT = 5;

const useRealTimeDataBarData = ({ realtimeUsers = [], lockData }) => {
  const [isOpen, setOpen] = useState(false);

  const totalRealTimeUsers = realtimeUsers.length;

  /**
   * Moves the user who is editing to the first position of the list
   */
  const sortedRealTimeUsers = moveUserEditingToFirstArrayPosition(
    realtimeUsers,
    lockData
  );

  /**
   * Reduce the list of user to the current limit
   */
  const reducedRealTimeUsers = useMemo(() => {
    if (sortedRealTimeUsers.length > LIMIT) {
      return sortedRealTimeUsers.slice(0, LIMIT);
    }
    return sortedRealTimeUsers;
  }, [sortedRealTimeUsers]);

  /**
   * Handle open viewer
   */
  const handleOpenViewer = () => {
    setOpen(true);
  };

  /**
   * Handle close viewer
   */
  const handleCloseViewer = () => {
    setOpen(false);
  };

  return {
    isOpen,
    totalRealTimeUsers,
    reducedRealTimeUsers,
    sortedRealTimeUsers,
    lockData,
    LIMIT,
    handleOpenViewer,
    handleCloseViewer,
  };
};

export default useRealTimeDataBarData;

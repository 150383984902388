import editSingleFinanceStore from "../../../../helpers/Finances/editSingleFinanceStore";

const editFinanceResponder = (queryClient) => {
  return {
    onSuccess: (returnedData) => {
      editSingleFinanceStore({
        queryClient,
        updatedData: returnedData,
      });
    },
    onError: (err) => {
      console.error("Failed to edit finance", err);
    },
  };
};

export default editFinanceResponder;

/**
 *
 *
 * Checks if a user has administrative delete permissions for a specific resource.
 *
 * @param {string} resource - The resource for which delete permissions are being checked.
 * @param {Object} user - The user object, which should contain a 'permissions' property.
 * @returns {boolean} True if the user has delete permissions for the resource, false otherwise.
 */
const hasDeleteAdministrativePermission = (resource, user) => {
  const permissionResource = user?.permissions?.administrative;

  if (!permissionResource || !user) {
    return false;
  }

  let canDeleteCriteria = false;

  if (resource === "property") {
    canDeleteCriteria = permissionResource?.can_delete_property === true;
  } else if (resource === "project") {
    canDeleteCriteria = permissionResource?.can_delete_project === true;
  }

  return canDeleteCriteria;
};

export default hasDeleteAdministrativePermission;

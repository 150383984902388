import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import useGetHelpList from "../../hooks/help";
import useAppPersistence from "../../hooks/persistence/useAppPersistence";

const useHelpPageData = () => {
  const { pathname: currentRoute } = useLocation();
  const { getPageSettings, editPageSearchText } = useAppPersistence();

  const currentPage = currentRoute?.split("/")?.[1];

  const { helpData: data, videoData } = useGetHelpList();
  const [categoryView, setCategoryView] = useState(null);
  const { search: persistedSearch = "" } = getPageSettings(currentPage) || {};

  const [searchTerm, setSearchTerm] = useState(persistedSearch);
  const [filteredSearchData, setFilteredSearchData] = useState(data);

  const handleSearchChange = useCallback(
    (searchData) => {
      editPageSearchText(currentPage, searchData);
      setSearchTerm(searchData);
    },
    [currentPage, editPageSearchText]
  );

  useEffect(() => {
    // find search term matches in help category, question or answer
    if (data) {
      let filteredData = {};

      Object.keys(data)?.forEach((helpKey) => {
        const searchTermLc = searchTerm?.toLowerCase();
        const video = videoData?.[categoryView];
        const found = data[helpKey].helpData?.filter(
          (item) =>
            // search term matches help category
            item?.category?.toLowerCase()?.includes(searchTermLc) ||
            // search term matches help answer
            item?.question?.toLowerCase()?.includes(searchTermLc) ||
            // search term matches help question
            item?.answer?.toLowerCase()?.includes(searchTermLc) ||
            // search term matches video name
            video?.name?.toLowerCase()?.includes(searchTermLc)
        );
        if (found?.length)
          filteredData = {
            ...filteredData,
            [helpKey]: { ...data[helpKey], helpData: found },
          };
      });
      setFilteredSearchData(filteredData);
    }
  }, [searchTerm, data, videoData, categoryView]);

  const helpData = useMemo(() => {
    return categoryView ? data?.[categoryView]?.helpData : [];
  }, [data, categoryView]);

  return {
    helpGridItems: filteredSearchData,
    categoryView,
    setCategoryView,
    helpData,
    handleSearchChange,
    searchTerm,
    setSearchTerm,
    videoData,
  };
};

export default useHelpPageData;

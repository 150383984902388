import React from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import FormLabel from "../FormLabel";
import Input from "../Input/Input";
import Widget from "../Widget/Widget";
import DistroListSelector from "../DistroListSelector";
import RecurrenceDropdown from "../RecurrenceDropdown";
import PlusCircleButton from "../Buttons/PlusCircleButton/PlusCircleButton";
import TagsSelector from "../TagsSelector";
import { PRIMARY_DARK_GREEN } from "../../../constants";

const ReportEditForm = ({
  reportForm,
  members,
  isDistroSelectOpen,
  humanReadableRecurrence,
  setDistroSelectOpen,
  setHumanReadableRecurrence,
  handleChangeForm,
  handleDistroMembers,
  handleTags,
}) => {
  return (
    <Widget draggable={false} title={null} overflow>
      <div className="grid grid-cols-3 gap-4 items-center">
        <div className="col-span-1">
          <FormLabel label="Report Name" />
        </div>
        <div className="col-span-2">
          <Input
            placeholder="Name (Required)"
            onChange={(value) => handleChangeForm("title", value)}
            value={reportForm.title}
            inputClassName="w-full bg-green-50 pl-4 pr-8 text-sm"
            inputContainerClassName="bg-green-50"
            validation={yup.string().required()}
          />
        </div>
      </div>

      <div className="border-b border-gray-450 border-opacity-50 my-4" />

      <div>
        <div className="grid grid-cols-3 gap-4 items-center">
          <div className="col-span-1">
            <FormLabel label="Repeat" />
          </div>
          <div className="col-span-2">
            <RecurrenceDropdown
              form={reportForm}
              recurrence={reportForm.repeat}
              setHumanReadableCustomRecurrence={setHumanReadableRecurrence}
              setRecurrence={({ value }) => handleChangeForm("repeat", value)}
            />
          </div>
        </div>

        {humanReadableRecurrence && (
          <div className="pt-4">
            <div className="p-1 inline-block bg-tagGreen rounded">
              <span className="text-sm text-primaryGreen font-light text-rigth">
                {humanReadableRecurrence}
              </span>
            </div>
          </div>
        )}
      </div>

      <div className="border-b border-gray-450 border-opacity-50 my-4" />

      <div className="grid grid-cols-3 gap-4 pt-2 mb-6">
        <div className="col-span-1">
          <FormLabel label="Members" />
        </div>
        <div className="col-span-2">
          <div
            className={reportForm.distribution.length > 0 ? "-mt-2" : "mt-1"}
          >
            <DistroListSelector
              isDistroSelectOpen={isDistroSelectOpen}
              members={members}
              selectedMembersRef={reportForm.distribution}
              setDistroSelectOpen={setDistroSelectOpen}
              handleDistroMembers={handleDistroMembers}
            />

            <PlusCircleButton
              noHover
              title="Add"
              className="mb-1"
              onClick={() => setDistroSelectOpen((current) => !current)}
              style={{ color: PRIMARY_DARK_GREEN }}
            />
          </div>
        </div>
      </div>

      <div className="border-b border-gray-450 border-opacity-50 my-4" />

      <TagsSelector
        tags={reportForm.tags}
        isEditing
        isInline
        onTagAdded={(tag) => handleTags("add", tag)}
        onTagRemoved={(tag) => handleTags("remove", tag)}
      />
    </Widget>
  );
};

ReportEditForm.defaultProps = {
  reportForm: undefined,
  members: [],
  isDistroSelectOpen: false,
  humanReadableRecurrence: undefined,
  setDistroSelectOpen: () => {},
  setHumanReadableRecurrence: () => {},
  handleChangeForm: () => {},
  handleDistroMembers: () => {},
  handleTags: () => {},
};

ReportEditForm.propTypes = {
  reportForm: PropTypes.shape({
    title: PropTypes.string,
    repeat: PropTypes.string,
    distribution: PropTypes.arrayOf(PropTypes.string),
    tags: PropTypes.arrayOf(PropTypes.string),
  }),
  members: PropTypes.arrayOf(PropTypes.shape({})),
  isDistroSelectOpen: PropTypes.bool,
  humanReadableRecurrence: PropTypes.string,
  setDistroSelectOpen: PropTypes.func,
  setHumanReadableRecurrence: PropTypes.func,
  handleChangeForm: PropTypes.func,
  handleDistroMembers: PropTypes.func,
  handleTags: PropTypes.func,
};

export default ReportEditForm;

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import React from "react";
import PropTypes from "prop-types";
import { Document } from "react-pdf";
import { VariableSizeList as List } from "react-window";
import usePDFViewerData from "./usePDFViewerData";
import PDFLoadingIndicator from "./PDFLoadingIndicator";
import PDFRow from "./PDFRow";

const PDFViewer = ({ initialUrl }) => {
  const {
    pdf,
    height,
    width,
    pageViewports,
    getPageHeight,
    onLoadSuccess,
    onLoadError,
  } = usePDFViewerData();

  if (!initialUrl) {
    return null;
  }

  return (
    <div className="flex h-full overflow-y-auto overflow-x-hidden">
      <Document
        file={initialUrl}
        className="overflow-hidden"
        loading={<PDFLoadingIndicator />}
        onLoadSuccess={onLoadSuccess}
        onLoadError={onLoadError}
      >
        {pdf && pageViewports ? (
          <List
            width={width}
            height={height}
            estimatedItemSize={height}
            itemCount={pdf.numPages}
            itemSize={getPageHeight}
          >
            {PDFRow}
          </List>
        ) : null}
      </Document>
    </div>
  );
};

PDFViewer.propTypes = {
  /**
   * url of the pdf being shown
   */
  initialUrl: PropTypes.string,
};

PDFViewer.defaultProps = {
  initialUrl: undefined,
};

export default PDFViewer;

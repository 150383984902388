import React from "react";
import ModalWrapper from "../ModalWrapper";
import CreateEditTaskForm from "../../EsForm/CreateEditTaskForm";
import useCreateEditTaskModal from "./useCreateEditTaskModal";

const CreateEditTaskModal = ({ item: modal }) => {
  const {
    form,
    handleSubmit,
    valid,
    saving,
    confirmClose,
    changesMade,
    setAssociation,
    dispatch,
    setValid,
    setChangesMade,
    sopOptions,
    initialTask,
    existingTask,
  } = useCreateEditTaskModal({ modal });

  return (
    <ModalWrapper
      formData={form}
      modalData={modal.modalData}
      resourceName="Task"
      width="1100px"
      title="Add Task"
      primaryButtonOnClick={handleSubmit}
      disabled={!valid || saving}
      showConfirm={confirmClose() || changesMade}
    >
      <CreateEditTaskForm
        setAssociation={setAssociation}
        dispatch={dispatch}
        form={form}
        setInputValid={setValid}
        setChangesMade={setChangesMade}
        sopOptions={sopOptions}
        initialTask={initialTask}
        disableAssociation={!!existingTask || modal?.modalData?.associationLock}
        associationLock={modal?.modalData?.associationLock}
        assetLock={modal?.modalData?.assetLock}
        spaceLock={modal?.modalData?.spaceLock}
        recurrenceLock={modal?.modalData?.recurrenceLock}
      />
    </ModalWrapper>
  );
};

export default CreateEditTaskModal;

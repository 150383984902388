import { propertyKeys } from "../../hooks/properties";
import sortByProperty from "../Utilities/sortByProperty";

/**
 * Add new property to the properties list cache store
 * @param {Object} params
 * @param {QueryClient} params.queryClient - query client object
 * @param {Object} params.property - new property created
 */
const addToPropertyListStore = ({ queryClient, property }) => {
  const queryKey = propertyKeys.all;

  const propertiesCache = queryClient.getQueryData(queryKey);

  // Property list cache hasn't been initialized, we don't need to create a cache manually
  if (!propertiesCache) {
    return;
  }

  // Check if property already exists in the cache.
  const foundProperty = propertiesCache.find(
    (existing) => existing.id === property.id
  );

  if (foundProperty) {
    return;
  }

  queryClient.setQueryData(queryKey, (oldData) => {
    const newPropertyArr = [property, ...oldData];
    return sortByProperty(newPropertyArr, "title");
  });
};

export default addToPropertyListStore;

import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  ADD_OPEN_MODAL,
  ASSET,
  CREATE_ASSET,
  CREATE_EVENT_MODAL,
  CREATE_TASK_MODAL,
  SPACE_TABS,
} from "../../../constants";
import { onUpdateFile } from "../../../helpers/File";
import { getSpaceConfiguration } from "../../../helpers/Formatters";
import useAssociatedFiles from "../../../hooks/useAssociatedFiles";
import useEditModal from "../../../hooks/useEditModal";
import useManagementConfiguration from "../../../hooks/useManagementConfiguration";
import useRelativeAssociations from "../../../hooks/useRelativeAssociations";
import useSpaceReducer from "../../../hooks/useSpaceReducer";
import { useAppState } from "../../../state/appState";
import { useModalState } from "../../../state/modalState";
import FormAvatar from "../../../stories/Components/Avatar/FormAvatar";
import PrimaryButton from "../../../stories/Components/Buttons/PrimaryButton";
import DeleteModal from "../../../stories/Components/DeleteModal/DeleteModal";
import FilesTable from "../../../stories/Components/FilesTable/FilesTable";
import InlineInput from "../../../stories/Components/Input/InlineInput";
import ImagesAndVideosWidget from "../../../stories/Components/MediaWidget/ImagesAndVideosWidget";
import SiteHeader from "../../../stories/Components/SiteHeader/SiteHeader";
import {
  toastError,
  toastMessage,
} from "../../../stories/Components/Toast/Toast";
import UploadFile from "../../../stories/Components/UploadFile/UploadFile";
import WidgetContainer from "../../../stories/Components/Widget/WidgetContainer";
import CalendarView from "../../Calendar/CalendarView";
import ProjectSpaceDocuments from "../../Project/ProjectSpaceDocuments";
import Assets from "../Asset/Assets";
import PropertySpaceDocuments from "../Property/PropertySpaceDocuments";
import TaskList from "../Task/TaskList";
import SpaceDetailView from "./SpaceDetailView";
import { hasReadPermission } from "../../../helpers/Permissions";
import useEditingResourceState from "../../../hooks/useEditingResourceState";
import useFilesPost from "../../../hooks/useFilesPost";
import uploadAvatar from "../../../helpers/uploadAvatar";
import useSpaceViewData from "./useSpaceViewData";
import RealTimeInactivityAlert from "../../../components/RealTimeInactivityAlert";
import RealTimeDataBar from "../../../components/RealTimeDataBar";

const SpaceView = ({ propertyId, projectId, spaceId }) => {
  const { associationLock, spaceLock } = useRelativeAssociations();

  const {
    space,
    spaceLoading,
    parentAssociation,
    params,
    realtimeUsers,
    activeTab,
    editing,
    lockState,
    hideActionsDropdown,
    disableEditing,
    setActiveTab,
    getLockInfo,
    editSpace,
    handleDeleteSpace,
    handleEditClick,
    isTabLocked,
  } = useSpaceViewData({
    propertyId,
    projectId,
    spaceId,
  });

  // Mutation hook to post files
  const { mutateAsync: postFiles } = useFilesPost();

  // create an association info object
  const associationInfo = useMemo(() => {
    let associationRef;
    let associationName;
    let resourceName;

    if (parentAssociation?.resource === "Project") {
      resourceName = "project";
      associationRef = parentAssociation?.reference;
      associationName = parentAssociation?.name;
    } else {
      resourceName = "property";
      associationRef = parentAssociation?.reference;
      associationName = parentAssociation?.title;
    }

    return { resourceName, associationName, associationRef };
  }, [parentAssociation]);

  const {
    associatedFiles,
    addFiles,
    removeFilesAndUpdateApi,
    cloneFile,
    patchFile,
  } = useAssociatedFiles(params);

  const [, setIsEditModalOpen] = useEditModal();
  const [newAttributes, setNewAttributes] = useState([]);
  const [{ currentUser }, appDispatch] = useAppState();
  const [, modalDispatch] = useModalState();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [buttonActions, setButtonActions] = useState([]);
  const [tmpAvatarImg, setTmpAvatarImg] = useState({});
  const history = useHistory();

  // handle editing state of resource being edited
  useEditingResourceState({
    editing,
    resource: "Space",
  });

  const [editedSpace, dispatch] = useSpaceReducer(space);

  useEffect(() => {
    // initialize space state
    dispatch({
      type: "reset",
      space,
    });
  }, [space, dispatch]);

  useEffect(() => {
    // if clonedSpace is on route state, initialize space with clonedSpace
    if (history.location.state?.clonedSpace) {
      const clonedData = history.location.state?.clonedSpace;
      dispatch({
        type: "reset",
        space: clonedData,
      });
    }
  }, [history.location.state, dispatch]);

  const { data: managementConfiguration } = useManagementConfiguration();
  const spaceConfiguration = useMemo(
    () => getSpaceConfiguration(managementConfiguration),
    [managementConfiguration]
  );
  /**
   * Handlers
   */
  const resetEditedSpace = useCallback(() => {
    setTmpAvatarImg({});
    dispatch({
      type: "reset",
      space,
    });
  }, [dispatch, space]);

  const handleFinishEditing = useCallback(async () => {
    setIsSaving(true);

    const newEditedSpace = await uploadAvatar({
      tmpAvatarImg,
      resourceState: editedSpace,
      postFiles,
    });

    const value = Number(newEditedSpace?.area?.value);
    const area = Number.isNaN(value) ? { value: 0 } : { value };
    const updatedSpace = {
      ...newEditedSpace,
      tags:
        newEditedSpace?.currentTags?.map((tag) => tag?.value) ||
        newEditedSpace?.tags,
      area,
    };

    try {
      await editSpace({ originalItem: space, editedItem: updatedSpace });
    } catch (err) {
      console.error(err);
      toastError("Space could not be edited");
    } finally {
      setIsSaving(false);
    }
  }, [editedSpace, editSpace, postFiles, tmpAvatarImg, space]);

  const updateSpaceFiles = useCallback(
    async (fileRefs) => {
      const updatedFiles = space.files.filter(
        (file) => !fileRefs.includes(file.ref)
      );
      const primary = fileRefs.includes(space?.primaryImage)
        ? updatedFiles.find((file) => file.category === "Photos")?.ref
        : space.primaryImage;
      const updatedSpace = {
        ...space,
        files: updatedFiles,
        primaryImage: primary,
      };
      // patch resource
      try {
        await editSpace({ originalItem: space, editedItem: updatedSpace });
      } catch (err) {
        console.error(err);
      }
    },
    [editSpace, space]
  );

  const onAddFilesCallback = useCallback(
    async (filesUploaded) => {
      addFiles(filesUploaded);

      const updatedFiles = [
        ...space.files,
        ...filesUploaded.map((file) => ({
          ref: file.reference,
          category: file.category,
        })),
      ];

      const updatedSpace = {
        ...space,
        files: updatedFiles,
        primaryImage:
          space?.primaryImage ||
          updatedFiles.find((file) => file.category === "Photos")?.ref,
      };

      try {
        await editSpace({ originalItem: space, editedItem: updatedSpace });
      } catch (err) {
        console.error(err);
      }
    },
    [addFiles, editSpace, space]
  );

  const handleRemoveMedia = useCallback(
    async (imageRefs) => {
      const updatedFiles = space.files.filter(
        (file) => !imageRefs.includes(file.ref)
      );
      const primary = imageRefs.includes(space?.primaryImage)
        ? updatedFiles.find((file) => file.category === "Photos")?.ref
        : space.primaryImage;
      const updatedSpace = {
        ...space,
        files: updatedFiles,
        primaryImage: primary,
      };

      try {
        await editSpace({ originalItem: space, editedItem: updatedSpace });
        // update associated files state
        removeFilesAndUpdateApi(imageRefs);
      } catch (err) {
        console.error(err);
      }
    },
    [editSpace, removeFilesAndUpdateApi, space]
  );

  const handleSetPrimaryMedia = useCallback(
    async (imageRef) => {
      const updatedSpace = {
        ...space,
        primaryImage: imageRef,
      };

      try {
        await editSpace({ originalItem: space, editedItem: updatedSpace });
      } catch (err) {
        console.error(err);
      }
    },
    [editSpace, space]
  );

  const handleAddMedia = useCallback(
    async (imageResources) => {
      const updatedFiles = [
        ...space.files,
        ...imageResources?.map((imageResource) => ({
          ref: imageResource.reference,
          category: imageResource.category,
        })),
      ];

      const primary =
        space.primaryImage ||
        updatedFiles.find((file) => file.category === "Photos")?.ref;

      const updatedSpace = {
        ...space,
        files: updatedFiles,
        primaryImage: primary,
      };

      try {
        await editSpace({ originalItem: space, editedItem: updatedSpace });
        addFiles(imageResources);
      } catch (err) {
        console.error(err);
      }
    },
    [addFiles, editSpace, space]
  );

  const handleFileClone = useCallback(
    (fileId) => {
      cloneFile(fileId)
        .then((clonedFile) => {
          const updatedSpace = {
            ...space,
            files: [
              ...space?.files,
              { ref: clonedFile.reference, category: clonedFile.category },
            ],
          };

          return editSpace({ originalItem: space, editedItem: updatedSpace });
        })
        .then((resource) =>
          toastMessage(`Recent file successfully attached to ${resource?.name}`)
        )
        .catch(() => {
          toastError(`Error attaching recent file`);
          // remove created files if PATCH fails
          removeFilesAndUpdateApi([`File/${fileId}`]);
        });
    },
    [cloneFile, editSpace, removeFilesAndUpdateApi, space]
  );

  const handleChangeTitle = useCallback(
    (name) => {
      dispatch({
        type: "name",
        name,
      });
    },
    [dispatch]
  );

  const handleModalDelete = () => {
    handleDeleteSpace().then(() => history.goBack());
  };

  const hasWritePermission = useCallback(() => {
    if (propertyId) {
      return currentUser?.hasPermission?.(
        "administrative",
        "can_write_property"
      );
    }

    if (projectId) {
      return currentUser?.hasPermission?.(
        "administrative",
        "can_write_project"
      );
    }
    return false;
  }, [currentUser, projectId, propertyId]);

  const handleUpdateFile = useCallback(
    ({ originalResource, currentTags, name }) => {
      (async () => {
        await onUpdateFile({ originalResource, currentTags, name, patchFile });
      })();
    },
    [patchFile]
  );

  const tabs = useMemo(() => {
    const permissionedTabs = {
      tabs: [
        {
          id: SPACE_TABS.DETAILS_ID,
          title: SPACE_TABS.DETAILS_TITLE,
          content: (
            <SpaceDetailView
              associationInfo={associationInfo}
              space={editedSpace}
              dispatch={dispatch}
              spaceConfiguration={spaceConfiguration}
              parentId={parentAssociation?.id}
              setNewAttributes={setNewAttributes}
              newAttributes={newAttributes}
              editing={editing}
              isLoading={spaceLoading}
            />
          ),
        },
        {
          id: SPACE_TABS.MEDIA_ID,
          title: SPACE_TABS.MEDIA_TITLE,
          content: (
            <ImagesAndVideosWidget
              hasWritePermission={hasWritePermission()}
              hasDeletePermission={hasWritePermission()}
              disableEditing={!hasWritePermission()}
              border={false}
              dispatch={dispatch}
              resource={space}
              handleAddMedia={handleAddMedia}
              handleSetPrimaryMedia={handleSetPrimaryMedia}
              handleRemoveMedia={handleRemoveMedia}
            />
          ),
        },
        {
          id: SPACE_TABS.FILES_ID,
          title: SPACE_TABS.FILES_TITLE,
          content: (
            <FilesTable
              files={associatedFiles}
              resourceName="Space"
              association={
                projectId ? `Project/${projectId}` : `Property/${propertyId}`
              }
              setIsEditModalOpen={setIsEditModalOpen}
              onAddFilesCallback={onAddFilesCallback}
              removeFilesAndUpdateApi={removeFilesAndUpdateApi}
              onRemoveFilesCallback={updateSpaceFiles}
              hasDeletePermission={hasWritePermission()}
              hasWritePermission={hasWritePermission()}
              handleFileClone={handleFileClone}
              hasEditPermission
              handleUpdateFile={handleUpdateFile}
            />
          ),
        },
      ],
    };

    if (
      currentUser?.hasPermission?.("event", "can_read") ||
      currentUser?.hasPermission?.("task", "can_read") ||
      currentUser?.hasPermission?.("task", "can_only_read_assigned")
    ) {
      permissionedTabs.tabs.push({
        id: SPACE_TABS.CALENDAR_ID,
        title: SPACE_TABS.CALENDAR_TITLE,
        content: <CalendarView currentUser={currentUser} isTabView />,
      });
    }

    if (
      currentUser?.hasPermission?.("task", "can_read") ||
      currentUser?.hasPermission?.("task", "can_only_read_assigned")
    ) {
      permissionedTabs.tabs.push({
        id: SPACE_TABS.TASKS_ID,
        title: SPACE_TABS.TASKS_TITLE,
        content: <TaskList setButtonActions={setButtonActions} />,
      });
    }

    if (hasReadPermission(ASSET, currentUser)) {
      permissionedTabs.tabs.push({
        id: SPACE_TABS.ASSETS_ID,
        title: SPACE_TABS.ASSETS_TITLE,
        content: <Assets />,
      });
    }
    permissionedTabs.tabs.push({
      id: "documents",
      title: "Documents",
      content: propertyId ? (
        <PropertySpaceDocuments />
      ) : (
        <ProjectSpaceDocuments />
      ),
      isHidden: true,
    });

    return permissionedTabs;
  }, [
    associationInfo,
    editedSpace,
    dispatch,
    spaceConfiguration,
    parentAssociation?.id,
    newAttributes,
    editing,
    spaceLoading,
    hasWritePermission,
    space,
    handleAddMedia,
    handleSetPrimaryMedia,
    handleRemoveMedia,
    associatedFiles,
    projectId,
    propertyId,
    setIsEditModalOpen,
    onAddFilesCallback,
    removeFilesAndUpdateApi,
    updateSpaceFiles,
    handleFileClone,
    handleUpdateFile,
    currentUser,
  ]);

  const handleAddTask = useCallback(() => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: uuidv4() },
      modalData: { associationLock, spaceLock },
      modalType: CREATE_TASK_MODAL,
    });
  }, [associationLock, spaceLock, modalDispatch]);

  useEffect(() => {
    switch (activeTab) {
      case SPACE_TABS.DETAILS_ID: {
        const options = [];

        if (
          propertyId &&
          currentUser?.hasPermission?.("administrative", "can_write_property")
        )
          options.push({
            title: "Delete Space",
            tabAction: true,
            onClick: () => setShowDeleteModal(true),
          });
        if (
          projectId &&
          currentUser?.hasPermission?.("administrative", "can_write_project")
        )
          options.push({
            title: "Delete Space",
            tabAction: true,
            onClick: () => setShowDeleteModal(true),
          });

        if (options.length)
          setButtonActions([
            {
              title: "Actions",
              className: "dropdown-btn",
              large: true,
              dropdownItems: spaceLoading ? [] : options,
            },
          ]);
        break;
      }
      case SPACE_TABS.MEDIA_ID: {
        if (
          (propertyId &&
            currentUser?.hasPermission?.(
              "administrative",
              "can_write_property"
            )) ||
          (projectId &&
            currentUser?.hasPermission?.("administrative", "can_write_project"))
        ) {
          setButtonActions([
            {
              onClick: () =>
                document.getElementById("upload-form-input").click(),
              addButton: true,
              resourceName: "Media",
            },
          ]);
        }
        break;
      }
      case SPACE_TABS.FILES_ID: {
        setButtonActions([
          {
            ...(propertyId &&
            currentUser?.hasPermission?.("administrative", "can_write_property")
              ? {
                  onClick: () => {
                    document
                      .querySelector("div.space-details .upload_area_click")
                      ?.click();
                  },
                  addButton: true,
                  resourceName: "Files",
                }
              : {}),
            ...(projectId &&
            currentUser?.hasPermission?.("administrative", "can_write_project")
              ? {
                  onClick: () => {
                    document
                      .querySelector("div.space-details .upload_area_click")
                      ?.click();
                  },
                  addButton: true,
                  resourceName: "Files",
                }
              : {}),
          },
        ]);
        break;
      }
      case SPACE_TABS.CALENDAR_ID: {
        const options = [];
        if (propertyId) {
          if (currentUser?.permissions?.event?.can_write) {
            options.push({
              title: "Add Event",
              tabAction: true,
              onClick: () =>
                modalDispatch({
                  type: ADD_OPEN_MODAL,
                  ref: { id: uuidv4() },
                  modalData: { associationLock: false },
                  modalType: CREATE_EVENT_MODAL,
                }),
            });
          }
          if (currentUser?.permissions?.task?.can_create) {
            options.push({
              title: "Add Task",
              tabAction: true,
              onClick: () => handleAddTask(),
            });
          }
        }

        if (projectId) {
          if (currentUser?.permissions?.event?.can_write) {
            options.push({
              title: "Add Event",
              tabAction: true,
              onClick: () =>
                modalDispatch({
                  type: ADD_OPEN_MODAL,
                  ref: { id: uuidv4() },
                  modalData: { associationLock: false },
                  modalType: CREATE_EVENT_MODAL,
                }),
            });
          }
          if (currentUser?.permissions?.task?.can_create) {
            options.push({
              title: "Add Task",
              tabAction: true,
              onClick: () => handleAddTask(),
            });
          }
        }

        if (options.length)
          setButtonActions([
            {
              title: "Actions",
              className: "dropdown-btn",
              large: true,
              dropdownItems: options,
            },
          ]);
        break;
      }
      case SPACE_TABS.TASKS_ID: {
        if (currentUser?.permissions?.task?.can_create) {
          setButtonActions([
            {
              addButton: true,
              resourceName: "Task",
              onClick: () => handleAddTask(),
            },
          ]);
        }
        break;
      }
      case SPACE_TABS.ASSETS_ID: {
        if (hasWritePermission(ASSET, currentUser)) {
          setButtonActions([
            {
              onClick: () =>
                modalDispatch({
                  type: ADD_OPEN_MODAL,
                  allowAssocSelect: false,
                  modalData: {
                    item: {
                      spaceId,
                      disableAssociation: true,
                      association: propertyId
                        ? `Property/${propertyId}`
                        : `Project/${projectId}`,
                      subAssociation: spaceId,
                      associationType: propertyId ? "Property" : "Project",
                    },
                  },
                  ref: { id: uuidv4() },
                  modalType: CREATE_ASSET,
                }),
              addButton: true,
              resourceName: "Asset",
            },
          ]);
        }
        break;
      }
      default:
        break;
    }
  }, [
    space?.reference,
    activeTab,
    modalDispatch,
    appDispatch,
    currentUser,
    propertyId,
    projectId,
    spaceLoading,
    spaceId,
    handleAddTask,
    hasWritePermission,
  ]);

  useEffect(() => {
    if (space) {
      resetEditedSpace();
    }
  }, [resetEditedSpace, space]);

  const ctaButtonActions = useMemo(() => {
    if (hideActionsDropdown) return null;

    return buttonActions[0];
  }, [buttonActions, hideActionsDropdown]);

  return (
    <>
      <SiteHeader
        title={
          <div className="flex items-center">
            <FormAvatar
              isEditing={
                currentUser?.hasPermission?.(
                  "administrative",
                  "can_write_property"
                ) && editing
              }
              disabled={
                !currentUser?.hasPermission?.(
                  "administrative",
                  "can_write_property"
                ) || !editing
              }
              tmpAvatarImg={tmpAvatarImg}
              setTmpAvatarImg={setTmpAvatarImg}
              image={editedSpace?.primaryImage}
              resourceName="Space"
            />
            <InlineInput
              width="w-full"
              size="custom4xl"
              value={editedSpace?.name}
              editing={editing}
              loading={spaceLoading || isSaving}
              disabled={!editing}
              fontWeight="bold"
              color="gray-650"
              onConfirm={handleChangeTitle}
              onChangeCallback={handleChangeTitle}
              hidePencil
              isHeaderTitle
            />
          </div>
        }
        buttons={
          (ctaButtonActions?.dropdownItems?.length ||
            !!ctaButtonActions?.resourceName) && (
            <PrimaryButton {...ctaButtonActions} />
          )
        }
      />

      <RealTimeInactivityAlert
        isEditing={editing}
        inactivityTimeInSeconds={60}
        onInactivity={() => {
          resetEditedSpace();
          handleEditClick();
        }}
      />

      <RealTimeDataBar
        isEditing={editing}
        realtimeUsers={realtimeUsers}
        lockData={getLockInfo(activeTab)}
      />

      <WidgetContainer
        className="p-4 border-gray-200 shadow-lg border rounded-md"
        style={{ minWidth: "903px" }}
        isEditing={editing}
        handleEditClick={activeTab === SPACE_TABS.DETAILS_ID && handleEditClick}
        onFinishEditing={handleFinishEditing}
        tabs={tabs?.tabs}
        loading={spaceLoading || isSaving}
        activeTab={activeTab}
        onTabClick={(t) => setActiveTab(t)}
        disableEditing={disableEditing || isTabLocked(activeTab)}
        resetResourceState={resetEditedSpace}
        lockState={lockState}
      />
      <UploadFile
        id="space-details"
        association={
          projectId ? `Project/${projectId}` : `Property/${propertyId}`
        }
        onAddFilesCallback={onAddFilesCallback}
      />
      <DeleteModal
        isOpen={showDeleteModal}
        onDelete={handleModalDelete}
        onClose={() => setShowDeleteModal(false)}
        title="Delete Space?"
        text={`Are you sure that you want to delete ${editedSpace?.name}? Once deleted, the space cannot be recovered.`}
      />
    </>
  );
};

SpaceView.propTypes = {
  spaceId: PropTypes.string,
  propertyId: PropTypes.string,
  projectId: PropTypes.string,
};

SpaceView.defaultProps = {
  spaceId: undefined,
  propertyId: undefined,
  projectId: undefined,
};

export default SpaceView;

/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import { cloneDeep, isEqual, omit } from "lodash";
import { DocumentAPI, WorkflowAPI } from "@griffingroupglobal/eslib-api";

import Spinner from "../Spinner/Spinner";
import Avatar from "../Message/Avatar";
import Dropdown from "../Dropdown/Dropdown";
import StatusPill from "../Pill/StatusPill";
import DateAndName from "./DateAndName";
import PrimaryHeader from "../TextHeaders/PrimaryHeader";
import PrimaryButton from "../Buttons/PrimaryButton";
import TertiaryButton from "../Buttons/TertiaryButton";
import WorkflowDetailsStep from "../WorkflowCreation/WorkflowDetailsStep";
import WorkflowStepForm from "../WorkflowCreation/WorkflowStepForm";
import WorkflowData from "../../../helpers/Workflow";
import ModalConfirmAll from "../Modal/ModalConfirmAll";

import useUsers from "../../../hooks/useUsers";
import useCurrentUser from "../../../hooks/useCurrentUser";
import { useWorkflows } from "../../../hooks/useWorkflows";
import useWorkflowsConfiguration from "../../../hooks/useWorkflowsConfiguration";
import useDocuments from "../../../hooks/useDocuments";
import useDocumentFormReducer from "../../../hooks/useDocumentFormReducer";
import useWorkflowById from "../../../hooks/useWorkflowById";
import useProperties from "../../../hooks/useProperties";
import useProjects from "../../../hooks/useProjects";
import useFavorites from "../../../hooks/useFavorites";

import { formatSelectUser, getFullName } from "../../../helpers/Formatters";
import { toastMessage } from "../../../helpers/Toast";
import whiteCrossIcon from "../../assets/images/whiteCrossIcon.svg";
import whiteCircleCheckIcon from "../../assets/images/circleCheckIcon.svg";
import {
  GET_PROJECT_WORKFLOW_VIEW_PATH,
  GET_PROPERTY_WORKFLOW_VIEW_PATH,
  GET_WORKFLOW_VIEW_PATH,
  UPDATE_DOCUMENT_ATTACHMENTS,
  SIMPLE_INPUT,
  WORKFLOW_TABS,
} from "../../../constants";
import yellowExclamation from "../../assets/images/yellowExclamation.svg";

const toastIcon = <img src={whiteCircleCheckIcon} alt="Successful upload" />;
const toastCloseIcon = <img src={whiteCrossIcon} alt="Close notice" />;

const PRIMARY_HEADER = "Edit Workflow";
const associatedOptions = [
  { label: "Associated", value: "non-contingent" },
  { label: "Blocked By", value: "contingent" },
];

const EditWorkflowForm = () => {
  const history = useHistory();
  const [users] = useUsers();
  const getEmptySubStep = () => ({
    name: "",
    duration: null,
    description: "",
    members: [],
    needOnlyOneAdvancer: false,
    newStep: true,
    id: uuidv4(),
  });
  const getEmptyStep = () => ({
    name: "",
    duration: null,
    description: "",
    members: [],
    needOnlyOneAdvancer: false,
    newStep: true,
    id: uuidv4(),
    parallelSteps: [],
    totalDurration: 0,
  });
  const { workflowId } = useParams();
  const { data: currentUser } = useCurrentUser();
  const { data: workflowsConfiguration } = useWorkflowsConfiguration();
  const { workflow, reload, isLoading, saveWorkflow } =
    useWorkflowById(workflowId);
  const [resourceMembers, setResourceMembers] = useState([]);
  const [distroMembers, setDistroMembers] = useState([]);

  const [properties] = useProperties();
  const { projects } = useProjects();
  const [favorites] = useFavorites("Document");
  const [document, dispatch] = useDocumentFormReducer();
  const { documents: docResources } = useDocuments();
  const [workflowData, setWorkflowData] = useState({});
  const [initialWorkflowData, setInitialWorkflowData] = useState({});
  const [initalSteps, setInitalSteps] = useState([]);
  const [stepData, setStepData] = useState([]);
  const [members, setMembers] = useState([]);
  const [disableNext, setDisableNext] = useState(true);
  const [selectedDocumentsIds, setSelectedDocumentsIds] = useState([]);
  const [currentVersion, setCurrentVersion] = useState({});
  const [workflowCompletionTime, setWorkflowCompletionTime] = useState({});
  const [showConfirm, setShowConfirm] = useState(false);
  const [template, setTemplate] = useState(null);
  const [templateOptions, setTemplateOptions] = useState([]);
  const workflowParams = useMemo(() => ({ isTemplate: true }), []);
  const [workflowTemplates] = useWorkflows(workflowParams);
  const [docUploading, setDocUploading] = useState(false);
  const [docUploaded, setDocUploaded] = useState(false);
  const [disableDocs, setDisableDocs] = useState(true);

  useEffect(() => {
    if (
      workflowData?.project ||
      workflowData?.property ||
      document?.project ||
      document?.property
    ) {
      setDisableDocs(false);
    } else {
      setDisableDocs(true);
    }
  }, [
    document?.project,
    document?.property,
    workflowData?.project,
    workflowData?.property,
  ]);

  useEffect(() => {
    if (workflowTemplates?.length && !templateOptions?.length) {
      setTemplateOptions(
        workflowTemplates?.map((wf) => ({
          ...wf,
          label: wf.name,
          value: wf.id,
        }))
      );
    }
  }, [workflowTemplates, templateOptions]);

  useEffect(() => {
    if (
      (workflowData.project || workflowData.property) &&
      !(document.project || document.property)
    ) {
      dispatch({
        type: SIMPLE_INPUT,
        key: workflowData.project ? "project" : "property",
        value: workflowData.project ?? workflowData.property,
      });
    }
  }, [document, dispatch, workflowData.project, workflowData.property]);

  useEffect(() => {
    if (workflowsConfiguration?.workflows) {
      setWorkflowCompletionTime({
        timeOfDay: workflowsConfiguration.workflows.completion.timeOfDay,
        timezone: workflowsConfiguration.workflows.completion.timezone,
      });
    }
  }, [workflowsConfiguration]);

  useEffect(() => {
    if (users?.length) {
      let memberDD =
        users
          ?.filter((info) => info?.name?.firstName || info?.name?.lastName)
          ?.map((info) => ({
            label: getFullName(info?.name),
            value: info.reference,
            reference: info.reference,
            ...formatSelectUser({ ...info, role: info.roleName }),
          })) ?? [];
      let distroMem = memberDD;

      if (workflowData?.property || workflowData?.project) {
        const reference = workflowData?.property || workflowData?.project;
        // currently only properties can be associateed. Post 3.0 add projects and companies
        const resource = reference.split("/")[0];
        switch (resource) {
          case "Property": {
            const propertyMembers = properties.find(
              (prop) => prop.reference === reference
            )?.members;

            // only allow property members
            distroMem = memberDD.filter(
              (usr) =>
                usr.role === "Admin" ||
                propertyMembers.find((mem) => {
                  return mem.user === usr.reference;
                })
            );

            memberDD = memberDD.filter((usr) =>
              propertyMembers.find((mem) => {
                return mem.user === usr.reference;
              })
            );

            break;
          }
          case "Project": {
            const projectMembers =
              projects.find((proj) => proj.reference === reference)?.members ??
              [];

            // only allow property members
            distroMem = memberDD.filter(
              (usr) =>
                usr.role === "Admin" ||
                projectMembers.find((mem) => {
                  return mem.user === usr.reference;
                })
            );

            memberDD = memberDD.filter((usr) =>
              projectMembers.find((mem) => {
                return mem.user === usr.reference;
              })
            );

            break;
          }
          default:
            memberDD = [];
        }
      }

      setResourceMembers(memberDD);
      setDistroMembers(distroMem);
    }
  }, [properties, projects, users, workflowData]);

  const handleReinitiateWorkflow = useCallback(
    async (saveDraft) => {
      const tempWorkflow = cloneDeep(workflowData);
      tempWorkflow.members = members.map((info) => info?.reference);
      tempWorkflow.cost = tempWorkflow?.cost?.replace(/,/g, "");
      tempWorkflow.steps = stepData?.map((info) => {
        const newMembers = info?.members.map((member) => {
          // eslint-disable-next-line no-underscore-dangle
          return { reference: member?.reference };
        });
        const parallelSteps = info?.parallelSteps?.map((par) => {
          const parMembers = par?.members?.map((member) => {
            // eslint-disable-next-line no-underscore-dangle
            return { reference: member?.reference };
          });
          return { ...par, members: parMembers };
        });
        const filesData = info?.documents?.map((file) => {
          return `Document/${file.id}`;
        });

        return {
          ...info,
          members: newMembers,
          parallelSteps,
          documents: filesData,
        };
      });

      if (document?.attachments?.length) {
        tempWorkflow.documents = document?.attachments
          ?.map((info) => info?.reference)
          ?.filter((item) => !!item);
      }

      const tempObj = {
        ...tempWorkflow,
        documentTypes: document.attachments
          ?.filter((doc) => doc.docType?.split("-").length === 1)
          .map((doc) => doc.docType),
        status: "inProgress",
        steps: tempWorkflow?.steps?.map((step) => {
          // ES-6494: IDK why we were trying to treat sop as an array b/c
          // it's not.  It's a single Sop reference string.
          // return { ...step, sop: step?.sop?.[0]?.sop ?? "" };
          return { ...step };
        }),
      };

      let finishedObj;

      if (saveDraft) {
        toastMessage(
          `${workflowData?.name} has been saved as a draft.`,
          toastIcon,
          toastCloseIcon
        );
        finishedObj = await WorkflowAPI.patch(workflow.id, tempObj, workflow);
      } else if (workflowData?.isDraft) {
        toastMessage(
          `${workflowData?.name} has been saved and initiated.`,
          toastIcon,
          toastCloseIcon
        );
        tempObj.isDraft = false;
      } else {
        toastMessage(
          `A new version of ${workflowData?.name} has been saved and reinitiated.`,
          toastIcon,
          toastCloseIcon
        );

        finishedObj = await WorkflowAPI.patch(
          `${workflow.id}/$newversion`,
          tempObj,
          workflow
        );
        // handleUpdateHistory("Initiated", workflow.reference);
      }

      await saveWorkflow(finishedObj?.data);
      if (workflow?.property) {
        history.push(
          `${GET_PROPERTY_WORKFLOW_VIEW_PATH(
            workflow.property.split("/")[1],
            workflow.id
          )}?tab=${WORKFLOW_TABS.DETAILS_ID}`
        );
      } else if (workflow?.project) {
        history.push(
          `${GET_PROJECT_WORKFLOW_VIEW_PATH(
            workflow.project.split("/")[1],
            workflow.id
          )}?tab=${WORKFLOW_TABS.DETAILS_ID}`
        );
      } else {
        history.push(
          `${GET_WORKFLOW_VIEW_PATH(workflow.id)}?tab=${
            WORKFLOW_TABS.DETAILS_ID
          }`
        );
      }
    },
    [
      workflowData,
      members,
      stepData,
      document?.attachments,
      saveWorkflow,
      history,
      workflow,
      // handleUpdateHistory,
    ]
  );

  const updateTotal = (arr) => {
    let tempArr = arr;
    let maxDays = 0;
    tempArr = tempArr?.map((step) => {
      let localMax = step.duration;
      step.parallelSteps?.forEach((pstep) => {
        localMax = pstep.duration > localMax ? pstep.duration : localMax;
      });
      maxDays = localMax > maxDays ? localMax : maxDays;
      return { ...step, totalDurration: localMax };
    });

    return tempArr;
  };

  const handleAddMember = () => {
    setMembers([...members, null]);
  };
  const handleRemoveMember = (usr) => {
    setMembers(
      members.filter((item) => {
        return item.reference !== usr;
      })
    );
  };
  useEffect(() => {
    let tempWorkflow = {};

    if (workflow?.metadata) {
      tempWorkflow = cloneDeep(workflow);
      const stepTotal = tempWorkflow?.steps?.reduce(
        (acc, cur, currentIndex) => {
          let mostDays = cur?.duration;
          let resubmissionStep = false;
          const stepPassed = WorkflowData.checkStep(cur, true);
          if (!stepPassed) {
            resubmissionStep = true;
          }
          for (let i = 0; i < cur?.parallelSteps?.length; i += 1) {
            const parallelStep = cur?.parallelSteps[i];
            if (parallelStep?.duration > mostDays) {
              mostDays = parallelStep?.duration;
            }
            const parStepPassed = WorkflowData.checkStep(
              cur?.parallelSteps[i],
              true
            );
            if (!parStepPassed) {
              resubmissionStep = true;
            }
          }
          if (resubmissionStep) {
            tempWorkflow.steps[currentIndex].resubmission = true;
          }
          return acc + mostDays;
        },
        0
      );
      tempWorkflow.endDate = moment(tempWorkflow.startDate)
        .add(stepTotal, "day")
        .format();
      for (let i = 0; i < tempWorkflow.steps.length; i += 1) {
        const mainStep = tempWorkflow.steps[i];
        const mainPassed = WorkflowData.checkStep(mainStep);
        const failedParallelStep = [];
        for (
          let j = 0;
          j < tempWorkflow.steps[i]?.parallelSteps?.length;
          j += 1
        ) {
          const parallelStep = mainStep?.parallelSteps[j];
          const parPassed = WorkflowData.checkStep(parallelStep);
          if (!parPassed) {
            failedParallelStep.push(parallelStep);
          }
        }
        if (!mainPassed || failedParallelStep.length > 0) {
          tempWorkflow.currentStep = i;
          break;
        }
      }
      if (typeof tempWorkflow.currentStep !== "number") {
        tempWorkflow.currentStep = tempWorkflow?.steps?.length;
      }

      const tempUserInfoVersion = {};
      // eslint-disable-next-line array-callback-return
      tempWorkflow?.history?.map((info) => {
        const version = info?.context?.workflow?.version;

        if (info?.action === "Initiated") {
          tempUserInfoVersion[version] = {
            name: getFullName(info?.userData?.name),
            date: info.when,
          };
        }
      });
      const newVersions = tempWorkflow?.versions?.map((ver) => ({
        ...(ver ?? {}),
        user:
          tempUserInfoVersion[ver?.version]?.name ||
          getFullName(ver?.modifiedBy?.name),
        initiatedDate:
          tempUserInfoVersion[ver?.version]?.date || ver?.modifiedDate,
      }));
      if (newVersions?.length) {
        setCurrentVersion(newVersions[0]);
      }
    }
    const membersFormated = workflow?.members?.map((info) => ({
      value: info.id,
      label: getFullName(info.name),
      reference: info.reference,
      ...formatSelectUser({ ...info, role: info.roleName }),
    }));

    setMembers(membersFormated);
    setInitialWorkflowData(tempWorkflow);
    setWorkflowData(tempWorkflow);
    setInitalSteps(updateTotal(tempWorkflow.steps));
    setStepData(updateTotal(tempWorkflow.steps));

    if (tempWorkflow?.documents?.length) {
      dispatch({
        type: UPDATE_DOCUMENT_ATTACHMENTS,
        value: tempWorkflow?.documents?.filter((doc) => doc.reference),
      });

      setSelectedDocumentsIds(
        tempWorkflow?.documents?.map((info) => info?.reference?.split("/")[1])
      );
    }
  }, [dispatch, workflow, users]);

  const handleChangeMember = (val) => {
    setMembers(val);
  };

  const handleEditWorkflow = (key, value) => {
    const tempObj = { ...workflowData };
    switch (key) {
      case "property": {
        tempObj[key] = value;
        tempObj.project = undefined;
        tempObj.company = undefined;
        break;
      }
      case "project": {
        tempObj[key] = value;
        tempObj.property = undefined;
        tempObj.company = undefined;
        break;
      }
      case "company": {
        tempObj[key] = value;
        tempObj.project = undefined;
        tempObj.property = undefined;
        break;
      }
      default: {
        tempObj[key] = value;
      }
    }

    switch (key) {
      case "property":
      case "project":
      case "company": {
        dispatch({
          type: SIMPLE_INPUT,
          key,
          value,
        });
        break;
      }
      default: {
        break;
      }
    }
    setWorkflowData(tempObj);
  };
  const handleDeleteStep = (parentIndex, index, isParallel) => {
    const tempArr = cloneDeep(stepData);
    if (!isParallel && tempArr[parentIndex]?.parallelSteps?.length === 0) {
      // main step with no parallel
      tempArr.splice(parentIndex, 1);
    } else if (!isParallel) {
      // main step with parallel steps
      const nextMain = tempArr[parentIndex].parallelSteps.splice(0, 1)[0];
      nextMain.parallelSteps = tempArr[parentIndex].parallelSteps;
      tempArr[parentIndex] = nextMain;
    } else {
      // parallel step
      tempArr[parentIndex].parallelSteps.splice(index, 1);
    }
    const newTemp = updateTotal(tempArr);
    setStepData(newTemp);
  };
  const handleEditStep = (parentIndex, index, value, isParallel) => {
    const tempArr = [...stepData];
    if (isParallel) {
      tempArr[parentIndex].parallelSteps[index] = value;
    } else {
      tempArr[parentIndex] = value;
    }
    const newTemp = updateTotal(tempArr);
    setStepData(newTemp);
  };

  const handleDragInStep = (
    fromParent,
    fromIndex,
    fromIsParallel,
    toParent
  ) => {
    const tempArr = cloneDeep(stepData);

    let newStep;
    if (!fromIsParallel && tempArr[fromParent].parallelSteps.length === 0) {
      // main step no parallel
      if (fromParent === toParent + 1 || fromParent === toParent) {
        return;
      }
      const newMain = tempArr.splice(fromParent, 1)[0];
      newStep = newMain;
    } else if (!fromIsParallel) {
      const newMain = tempArr[fromParent];
      const nextMain = tempArr[fromParent].parallelSteps.splice(0, 1)[0];
      nextMain.parallelSteps = tempArr[fromParent].parallelSteps;
      tempArr[fromParent] = nextMain;
      newMain.parallelSteps = [];
      newStep = newMain;
    } else {
      const newMain = tempArr[fromParent].parallelSteps.splice(fromIndex, 1)[0];
      newMain.parallelSteps = [];
      newStep = newMain;
    }
    if (toParent === -1) {
      tempArr.unshift(newStep);
    } else {
      tempArr.splice(toParent, 0, newStep);
    }
    setStepData(tempArr);
  };

  const handleAddNewStep = () => {
    const tempArr = [...stepData];
    tempArr.push(getEmptyStep());
    setStepData(tempArr);
  };
  const handleAddParallelStep = (parentIndex) => {
    const tempArr = [...stepData];
    const emptyStep = getEmptySubStep();
    tempArr[parentIndex].parallelSteps.push(emptyStep);
    setStepData(tempArr);
  };
  const detailsSchema = yup.object().shape({
    name: yup.string().required(),
    startDate: yup.mixed().required(),
    members: yup.array().of(
      yup.object().shape({
        label: yup.string().required(),
        value: yup.string().required(),
      })
    ),
  });

  useEffect(() => {
    const hasMembersChanged = (oldWF, newWF) => {
      const oldMembers = oldWF?.members?.map((user) => user.reference) || [];
      const newMembers = newWF?.members?.map((user) => user.reference) || [];

      return !isEqual(oldMembers, newMembers);
    };

    const hasAssociation = () => {
      return (
        workflowData?.property || workflowData?.project || workflowData?.company
      );
    };

    const handleDisabledAdd = async () => {
      const tempObj = {
        name: workflowData?.name,
        startDate: workflowData?.startDate,
        members: members?.filter((info) => info?.value),
      };
      const isValid = await detailsSchema.isValid(tempObj);
      const initialWF = omit(initialWorkflowData, "steps");
      const currentWF = omit(workflowData, "steps");
      const currentSteps = stepData?.map((info) => {
        if (info.parallelSteps?.length > 0) {
          const newParallel = info?.parallelSteps?.map((par) =>
            omit(par, "newStep")
          );
          return { ...info, parallelSteps: newParallel };
        }
        return omit(info, "newStep");
      });

      const existingFiles = initialWorkflowData?.documents?.map(
        (info) => info?.reference
      );
      const selectedFiles = document?.attachments?.map(
        (info) => info?.reference
      );

      const canAdvance =
        stepData?.length &&
        stepData.every(
          (info) =>
            !info.newStep && info?.parallelSteps.every((par) => !par.newStep)
        );

      if (
        (isValid &&
          hasAssociation() &&
          (workflowData?.isDraft ||
            !isEqual(initialWF, currentWF) ||
            !isEqual(initalSteps, currentSteps) ||
            !isEqual(workflowData.documents, document.attachments) ||
            !isEqual(selectedFiles, existingFiles) ||
            hasMembersChanged(initialWorkflowData, tempObj))) ||
        docUploaded
      ) {
        if (canAdvance) {
          setDisableNext(false);
        } else {
          setDisableNext(true);
        }
      } else {
        setDisableNext(true);
      }
    };
    handleDisabledAdd();
  }, [
    detailsSchema,
    members,
    workflowData,
    initialWorkflowData,
    initalSteps,
    stepData,
    document?.attachments,
    docUploaded,
  ]);

  const handleCancel = () => {
    setShowConfirm(true);
  };

  const getCancelNavigationPath = () => {
    if (workflow?.property) {
      return GET_PROPERTY_WORKFLOW_VIEW_PATH(
        workflow.property.split("/")[1],
        workflow.id
      );
    }
    if (workflow?.project) {
      return GET_PROJECT_WORKFLOW_VIEW_PATH(
        workflow.project.split("/")[1],
        workflow.id
      );
    }
    return GET_WORKFLOW_VIEW_PATH(workflow.id);
  };

  const { stepName, notes } = WorkflowData.getWorkflowStepName(
    workflowData?.history?.[0]?.context?.workflow?.step?.position,
    workflowData?.history?.[0]?.context?.workflow?.step?.id,
    workflowData?.history?.[0]?.user,
    workflowData?.steps,
    true
  );

  const getStatus = () => {
    return workflowData?.isDraft ? "draft" : workflowData?.status;
  };

  const handleAddFiles = useCallback(
    async (
      documents,
      isParallel,
      parentIndex,
      index,
      id,
      documentsToUpload
    ) => {
      // create documents
      setDocUploading(true);
      Promise.all(
        documentsToUpload?.map(async (doc) => {
          const payload = {
            status: "open",
            createdBy: currentUser.reference,
            docType: doc.documentType.value,
            customName: doc.name,
            contentReference: doc.id,
            lineItems: [],
          };

          if (workflow?.project) {
            payload.project = workflow?.project;
          } else if (workflow?.property) {
            payload.property = workflow?.property;
          }

          const { data } = await DocumentAPI.post(payload);
          return data.reference;
        })
      ).then(async (docRefs) => {
        const tempObj = cloneDeep(workflow);
        if (isParallel) {
          const currentFiles =
            tempObj.steps[parentIndex].parallelSteps[index].documents;
          const newFiles = [...currentFiles, ...docRefs];
          tempObj.steps[parentIndex].parallelSteps[index].documents = newFiles;
        } else {
          const currentFiles = tempObj.steps[parentIndex].documents;
          const newFiles = [...currentFiles, ...docRefs];
          tempObj.steps[parentIndex].documents = newFiles;
        }

        await WorkflowAPI.patch(workflow?.id, tempObj, workflow);

        reload();
        setDocUploading(false);
        setDocUploaded(true);
      });
    },
    [currentUser, reload, workflow]
  );

  const WFHeader = () => {
    return (
      <div className="mt-6 mb-10 flex flex-col bg-gray-100 rounded-md p-6">
        <div className="flex flex-row mb-6">
          <h2 className="mr-6 font-bold text-gray-400 text-lg">
            Recent Activity
          </h2>
          <StatusPill value={getStatus()} />
        </div>
        <div className="flex flex-row pb-2">
          <div
            className={`flex flex-row pr-8 border-gray-200 ${
              workflowData?.history?.length && "border-r-2"
            }`}
          >
            <Avatar
              name={getFullName(workflowData?.metadata?.userData?.name)}
              className="w-12 h-12 rounded-full mr-4"
            />
            <DateAndName workflowData={currentVersion} />
          </div>
          {!!workflowData?.history?.length && (
            <div className="flex flex-row flex-1 pl-8">
              <div className="w-12 mr-4">
                <Avatar
                  className="w-12 h-12 rounded-full mr-4"
                  name={getFullName(workflowData?.history?.[0]?.userData?.name)}
                />
              </div>
              <div>
                <h3 className="text-lg font-medium text-gray-400">
                  {workflowData?.history?.[0]?.userData &&
                    `${
                      workflowData?.history?.[0]?.action || ""
                    } by ${getFullName(
                      workflowData?.history?.[0]?.userData?.name
                    )} ${stepName ? `on ${stepName}` : ""}`}
                </h3>
                <h4>
                  {moment(workflowData?.history?.[0]?.when).format("LLL")}
                </h4>
                <p className="pt-4 text-gray-200 flex pr-4">{notes}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const setAssociatedContingence = (value, index) => {
    const newAssociatedWorkflows = workflowData?.associatedWorkflows?.map(
      (info, idx) => {
        if (idx === index) {
          return {
            ...info,
            association: value,
          };
        }
        return info;
      }
    );
    setWorkflowData((prev) => ({
      ...prev,
      associatedWorkflows: newAssociatedWorkflows,
    }));
  };

  const removeAssociation = (index) => {
    const newAssociatedWorkflows = workflowData?.associatedWorkflows?.filter(
      (info, idx) => idx !== index
    );

    setWorkflowData((prev) => ({
      ...prev,
      associatedWorkflows: newAssociatedWorkflows,
    }));
  };

  const handleTemplate = (id) => {
    if (id) {
      setStepData(workflowTemplates[id]);
    } else {
      setStepData([getEmptyStep()]);
    }
  };

  const handleTemplateChange = (val) => {
    if (!val.value) {
      setStepData([getEmptyStep()]);
      setTemplate();
    } else {
      handleTemplate(val?.value);
      updateTotal(val?.steps);
      const cleanSteps = val?.steps?.map((step) => {
        step.members = [];
        step.parallelSteps = step?.parallelSteps?.map((parallelStep) => {
          parallelStep.members = [];
          return parallelStep;
        });
        return step;
      });
      setStepData(cleanSteps);
      setTemplate(val);
    }
  };

  return (
    <>
      <div className="mt-6">
        <div className="flex justify-between items-end">
          <PrimaryHeader className="pt-6">{PRIMARY_HEADER}</PrimaryHeader>
          <div className="flex flex-row h-10">
            <TertiaryButton
              title="Cancel"
              className="mr-6"
              onClick={handleCancel}
            />
            <PrimaryButton
              title={`${workflowData?.isDraft ? "Initiate" : "Reinitiate"}`}
              disabled={disableNext}
              onClick={() => handleReinitiateWorkflow(false)}
            />
          </div>
        </div>
        <WFHeader />
        <>
          {workflowData?.associatedWorkflows?.length > 0 && (
            <h2 className="text-xl font-semibold text-gray-400 mb-6">
              Associated Workflows
            </h2>
          )}
          {workflowData?.associatedWorkflows?.map((info, index) => (
            <div
              className="flex flex-row justify-between border-2 border-gray-150 p-6 rounded-md items-center mb-4"
              // eslint-disable-next-line react/no-array-index-key
              key={`${info?.workflowData?.name}-${index}`}
            >
              <div className="flex flex-row items-center">
                <h2 className="text-lg font-semibold">
                  {info?.workflowData?.name}
                </h2>
                {info.association === "contingent" && (
                  <div className="flex flex-row items-start ml-4">
                    <img
                      src={yellowExclamation}
                      alt="warning"
                      className="mr-2 w-4 h-4"
                    />{" "}
                    <p className="text-xs text-gray-300">
                      Blocked until this contingent
                      <br /> workflow is complete.
                    </p>
                  </div>
                )}
              </div>
              <div className="flex flex-row items-center">
                <h3 className="mr-4">Relationship:</h3>
                <Dropdown
                  options={associatedOptions}
                  className="w-72"
                  value={associatedOptions.find(
                    (opt) => opt.value === info?.association
                  )}
                  onChange={(val) => setAssociatedContingence(val.value, index)}
                />
                <TertiaryButton
                  title="Remove Association"
                  onClick={() => removeAssociation(index)}
                />
              </div>
            </div>
          ))}
        </>

        <h2 className="text-xl font-semibold text-gray-400 my-6">Details</h2>
        <WorkflowDetailsStep
          disableDocs={disableDocs}
          workflowData={workflowData}
          handleEditWorkflow={handleEditWorkflow}
          members={members}
          distroMembers={distroMembers}
          handleAddMember={handleAddMember}
          handleChangeMember={handleChangeMember}
          handleRemoveMember={handleRemoveMember}
          dispatch={dispatch}
          document={document}
          docResources={docResources}
          workflowCompletionTime={workflowCompletionTime}
          selectedDocumentsIds={selectedDocumentsIds}
          setSelectedDocumentsIds={setSelectedDocumentsIds}
          favorites={favorites}
          handleTemplateChange={handleTemplateChange}
          template={template}
          templateOptions={templateOptions}
          disableAssociation
        />

        {(isLoading || docUploading) && <Spinner />}

        <h2 className="text-xl font-semibold text-gray-400 mb-6">Workflow</h2>
        <WorkflowStepForm
          stepData={stepData}
          workflowData={workflowData}
          allMembers={resourceMembers}
          handleDragInStep={handleDragInStep}
          handleAddNewStep={handleAddNewStep}
          handleAddParallelStep={handleAddParallelStep}
          handleEditStep={handleEditStep}
          handleDeleteStep={handleDeleteStep}
          handleAddFiles={handleAddFiles}
          setStepData={setStepData}
          currentRef={currentUser?.reference}
          noTopStep
          canEdit
          currentUser={currentUser}
        />

        <ModalConfirmAll
          navigateToPath={getCancelNavigationPath()}
          showConfirmModal={showConfirm}
          setShowConfirmModal={setShowConfirm}
          modalAction={`Cancel ${PRIMARY_HEADER}`}
        />
      </div>
    </>
  );
};

export default EditWorkflowForm;

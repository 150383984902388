/* Framework Tools */
import React, { useMemo } from "react";
import PropTypes from "prop-types";

// Images & Icons
import commentIcon from "../../assets/images/comment_icon_updated.svg";

/* Constants */
import { FILTER_TYPES } from "../../../constants";

/* Helpers */
import { getFullName } from "../../../helpers/Formatters";

/* Components */
import Table from "../Table/Table";
import Avatar from "../Message/Avatar";
import IconButton from "../Buttons/IconButton";
import TableActionsIconsGroup from "../Table/TableActionsIconsGroup";
import { getInitials } from "../../../helpers/User";
import RoleMembersAvatarList from "../../../Pages/Admin/RoleMembersAvatarList";

const groupOptions = {
  // ordered list of grouped column options
  hierarchicalOptions: [
    // add more hierarchical groups
  ],
  // un-ordered list of grouped column options
  nonHierarchicalOptions: [
    {
      label: "Association",
      value: "associationName",
    },
    {
      label: "Status",
      value: "status",
    },
  ],
};

const PureWorkflowTable = ({
  tableData,
  resourceName,
  templateSettings,
  updateUserTemplateSettings,
  deleteUserTemplateSettings,
  setShowDeleteModal,
  selectedRows,
  handleRowChange,
  handleRowClick,
  onCommentsClick,
  allowDelete,
}) => {
  const tableColumns = React.useMemo(() => {
    const columns = [
      {
        Header: "Comments",
        accessor: "comment",
        Cell: ({ row }) => {
          return (
            <div className="flex justify-center">
              <IconButton
                icon={commentIcon}
                onClick={() => {
                  onCommentsClick({
                    resourceReference: `Workflow/${row.original.id}`,
                    association: row.original?.association,
                  });
                }}
              />
            </div>
          );
        },
        Aggregated: () => null,
        maxWidth: 130,
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Association",
        accessor: "associationName",
        minWidth: 200,
      },
      {
        Header: "Ball In Court", // person(s) who is on the current step
        accessor: "ballInCourt",
        Cell: ({ value, row }) => {
          if (value) {
            return (
              <RoleMembersAvatarList
                showBottomTooltip={row?.index === 0}
                members={value}
                className="pl-2"
              />
            );
          }
          return <></>;
        },
        minWidth: 300,
      },
      {
        Header: "Status",
        accessor: "status",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
        Cell: ({ value }) => {
          return <div className="flex capitalize">{value}</div>;
        },
        minWidth: 200,
      },
      {
        Header: "Free Flow",
        accessor: "isFreeFlow",
        width: 130,
        Cell: ({ value }) => {
          if (value) {
            return (
              <span
                className="rounded-full flex h-2 w-2 mr-1 mt-1 bg-brandGreen"
                aria-label="yes"
              />
            );
          }
          return <></>;
        },
      },
      {
        Header: "Description",
        accessor: "description",
      },
      {
        Header: "Initiated Date",
        accessor: "initiated",
        Cell: ({ value }) => {
          return <div className="flex items-center">{value}</div>;
        },
        filterOptions: {
          label: "Initiation Date",
          filterType: FILTER_TYPES.date,
        },
      },
      {
        Header: "Initiated by",
        accessor: "initiatedBy",
        Cell: ({ value }) => {
          const name = getFullName(value?.user?.name);
          return (
            <div className="flex flex-row ml-1 items-center w-full h-full">
              {value?.user && (
                <Avatar
                  className="text-es-sm flex"
                  name={getInitials(value?.user?.name)}
                  isExternalUser
                  id={value?.user?.id}
                  avatar={value?.user?.avatar}
                  kind={value?.user?.kind}
                />
              )}
              <p className="flex items-center">{name}</p>
            </div>
          );
        },
        minWidth: 250,
      },
      {
        Header: "Start Date",
        accessor: "startDate",
      },
      {
        Header: "Vendor",
        accessor: "vendor",
      },
      {
        Header: "Duration",
        accessor: "duration",
      },
      {
        Header: "Target Completion Date",
        accessor: "targetCompletion",
        minWidth: 250,
      },
      {
        Header: "Projected Completion Date",
        accessor: "projectedCompletion",
        minWidth: 250,
      },
      {
        Header: "Over/Under (days)",
        accessor: "overUnder",
        minWidth: 200,
      },
      {
        Header: "Schedule Ref",
        accessor: "scheduleRef",
      },
      {
        Header: "Budget Ref",
        accessor: "budgetRef",
      },
      {
        Header: "Blocked",
        accessor: "blocked",
      },
      {
        Header: "Cost Impact",
        accessor: "costImpact",
      },
      {
        Header: "Schedule Impact",
        accessor: "scheduleImpact",
        minWidth: 200,
      },
      {
        Header: "Completed",
        accessor: "completed",
        filterOptions: {
          label: "Completed",
          filterType: FILTER_TYPES.isOrNot,
        },
      },
    ];
    return columns;
  }, [onCommentsClick]);

  return (
    <Table
      showConstantRowIndex
      onSelectedRowChange={handleRowChange}
      onRowClick={handleRowClick}
      groupOptions={groupOptions}
      templateSettings={templateSettings}
      updateUserTemplateSettings={updateUserTemplateSettings}
      deleteUserTemplateSettings={deleteUserTemplateSettings}
      allowSelection
      columns={tableColumns}
      hideSiteHeaderActionButtons
      hideSiteHeaderTitle
      data={useMemo(() => tableData, [tableData])}
      showFilterButton
      showChangeView
      resourceName={resourceName}
      hideSwitchView
      showColumnSettingsLeft={false}
      fileActionsIcons={
        <TableActionsIconsGroup
          style={{ height: "100%" }}
          handleShowDelete={() => {
            setShowDeleteModal(true);
          }}
          canDelete
          disabled={!allowDelete || selectedRows?.length === 0}
          showColumnSettingsRight
          selectedRows={selectedRows}
        />
      }
    />
  );
};

PureWorkflowTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({})),
  handleRowChange: PropTypes.func,
  handleRowClick: PropTypes.func,
  resourceName: PropTypes.string,
  templateSettings: PropTypes.arrayOf(PropTypes.shape({})),
  updateUserTemplateSettings: PropTypes.func,
  deleteUserTemplateSettings: PropTypes.func,
  setShowDeleteModal: PropTypes.func,
  selectedRows: PropTypes.arrayOf(PropTypes.shape({})),
  onCommentsClick: PropTypes.func,
  allowDelete: PropTypes.bool,
};

PureWorkflowTable.defaultProps = {
  tableData: [],
  handleRowChange: undefined,
  handleRowClick: undefined,
  resourceName: undefined,
  templateSettings: [],
  updateUserTemplateSettings: undefined,
  deleteUserTemplateSettings: undefined,
  setShowDeleteModal: undefined,
  selectedRows: [],
  onCommentsClick: undefined,
  allowDelete: false,
};

export default PureWorkflowTable;

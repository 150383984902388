import React from "react";
import PropTypes from "prop-types";
import crossIconWhite from "../../../stories/assets/images/crossIconWhite.svg";
import CrossButton from "../../../stories/Components/CrossButton/CrossButton";
import Avatar from "../../../stories/Components/Message/Avatar";

const Tooltip = ({
  isTooltipOpen,
  userAvatar,
  userFullname,
  message,
  handleCloseTooltip,
}) => {
  return (
    <div
      className={`absolute z-50 flex flex-col h-fit ${
        isTooltipOpen ? "" : "hidden"
      }`}
      style={{ marginBottom: "90px", marginLeft: "39px" }}
    >
      <div
        className="flex flex-row items-center bg-es-green p-3 rounded-md gap-2"
        style={{ width: "338px", height: "58px" }}
      >
        <CrossButton
          className="font-semibold"
          icon={crossIconWhite}
          onClick={handleCloseTooltip}
          style={{ height: "14px", width: "14px" }}
        />

        <Avatar
          className="text-xs w-8 h-8 justify-center"
          name={userFullname}
          isExternalUser
          fontClassName="font-es-bold"
          avatar={userAvatar}
          disableTooltip
        />

        <p className="text-es-sm text-white font-es-semibold">{message}</p>
      </div>
    </div>
  );
};

Tooltip.defaultProps = {
  isTooltipOpen: true,
  userFullname: "",
  userAvatar: "",
  message: "",
  handleCloseTooltip: () => {},
};

Tooltip.propTypes = {
  isTooltipOpen: PropTypes.bool,
  userFullname: PropTypes.string,
  userAvatar: PropTypes.string,
  message: PropTypes.string,
  handleCloseTooltip: PropTypes.func,
};

export default Tooltip;

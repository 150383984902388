/**
 *
 *
 * Checks if a user has delete permissions for a specific resource.
 *
 * @param {string} resource - The resource for which delete permissions are being checked.
 * @param {Object} user - The user object, which should contain a 'permissions' property.
 * @returns {boolean} True if the user has delete permissions for the resource, false otherwise.
 */
const hasDeletePermission = (resource, user) => {
  const permissionResource = user?.permissions?.[resource];

  if (!permissionResource || !user) {
    return false;
  }

  const canDeleteCriteria = permissionResource?.can_delete === true;

  return canDeleteCriteria;
};

export default hasDeletePermission;

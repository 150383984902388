import { useCallback, useMemo, useState } from "react";
import { useRealTimeResource } from "@griffingroupglobal/eslib-ably/react";
import {
  useDeleteSpace,
  useEditSpace,
  useSingleSpace,
} from "../../../hooks/api/spaces";
import useQueryNotFoundNavigation from "../../../hooks/navigation/useQueryNotFoundNavigation";
import useAssociationsHelper from "../../../hooks/useAssociationsHelper";
import { useUsers } from "../../../hooks/useUsers.new";
import useWidgetTabNavigation from "../../../hooks/useWidgetTabNavigation";
import { hasWriteAdministrativePermission } from "../../../helpers/Permissions";
import { PROJECT, PROPERTY } from "../../../constants";
import useCurrentUser from "../../../hooks/useCurrentUser";

const useSpaceViewData = ({ propertyId, projectId, spaceId }) => {
  const reference = `Space/${spaceId}`;

  const { space, spaceError, spaceLoading } = useSingleSpace(spaceId);

  const { data: currentUser } = useCurrentUser();

  const { data: usersData } = useUsers();
  const userDict = usersData?.userDict;

  // Real time resource hook
  const {
    realtimeUsers,
    lockState,
    acquireLock,
    releaseLock,
    isTabLocked,
    getLockInfo,
  } = useRealTimeResource({ reference, isLoading: spaceLoading, userDict });

  const { activeTab, setActiveTab } = useWidgetTabNavigation({
    page: "spaces",
    resourceId: spaceId,
  });

  const { editSpace } = useEditSpace();
  const { deleteSpace } = useDeleteSpace();

  const { getAssociationInfo } = useAssociationsHelper();

  // Redirect to 404 page if resource is not found
  useQueryNotFoundNavigation({ error: spaceError });

  const [editing, setEditing] = useState(false);

  let association;
  if (propertyId) {
    association = `Property/${propertyId}`;
  } else if (projectId) {
    association = `Project/${projectId}`;
  }

  const params = useMemo(
    () => ({
      association,
      spaceId,
    }),
    [spaceId, association]
  );

  const resource = propertyId ? PROPERTY : PROJECT;

  const canWriteSpace = hasWriteAdministrativePermission(resource, currentUser);

  const disableEditing = !canWriteSpace;

  const isDetailsLocked = isTabLocked("details");

  // hides Actions dropdown in "Details" tab if is being edited in RTD for someone else or
  // if user doesn't have permissions
  const hideActionsDropdown = disableEditing || isDetailsLocked;

  // Parent Association
  const parentAssociation = useMemo(() => {
    if (!association) return null;

    return getAssociationInfo(association);
  }, [association, getAssociationInfo]);

  /**
   * Deletes single space
   */
  const handleDeleteSpace = useCallback(async () => {
    try {
      await deleteSpace(spaceId, params.association);
    } catch (error) {
      console.error("Error deleting this space", error);
    }
  }, [spaceId, params.association, deleteSpace]);

  /**
   * Handle edit, locking and releasing resource tab in real time
   */
  const handleEditClick = useCallback(async () => {
    setEditing((prev) => !prev);

    const isEditing = !editing;

    try {
      if (isEditing) {
        await acquireLock(activeTab);
      } else {
        await releaseLock(activeTab);
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  }, [acquireLock, releaseLock, editing, activeTab]);

  return {
    space,
    spaceLoading,
    parentAssociation,
    params,
    realtimeUsers,
    isDetailsLocked,
    activeTab,
    editing,
    lockState,
    hideActionsDropdown,
    disableEditing,
    setEditing,
    setActiveTab,
    editSpace,
    handleDeleteSpace,
    getLockInfo,
    handleEditClick,
    isTabLocked,
  };
};

export default useSpaceViewData;

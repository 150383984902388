import spacesKeys from "../../hooks/api/spaces/spacesKeys";

const deleteSingleSpaceStore = ({ queryClient, id }) => {
  const queryKey = spacesKeys.byId(id);
  const oldData = queryClient.getQueryData(queryKey);

  if (oldData) {
    queryClient.removeQueries(queryKey);
  }
};

export default deleteSingleSpaceStore;

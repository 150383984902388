import addToAssociationSpaceListStore from "../../../../helpers/Spaces/addToAssociationSpaceListStore";
import addToSpaceListStore from "../../../../helpers/Spaces/addToSpaceListStore";

const addSpaceResponder = (queryClient) => {
  return {
    onSuccess: (returnedData) => {
      // Add Space to Spaces left side menu
      addToSpaceListStore({
        queryClient,
        space: returnedData,
      });

      // Add Space to Assocaiton Spaces tab
      addToAssociationSpaceListStore({
        queryClient,
        space: returnedData,
      });
    },
    onError: (err) => {
      console.error("Failed to create space", err);
    },
  };
};

export default addSpaceResponder;

import React from "react";
import ErrorBanner from "../../ErrorBanner/ErrorBanner";
import ModalWrapper from "../ModalWrapper";
import ResourceInformationForm from "../../PropertyFormSteps/ResourceInformationForm";
import BillingAddress from "../../AddressInput/BillingAddress";
import Widget from "../../Widget/Widget";
import usePropertyCreateModal from "./useCreatePropertyModal";

const PropertyCreateModal = ({ modalData }) => {
  const {
    titleExists,
    property,
    propertyTypeOptions,
    isSaving,
    isLoading,
    handleSimpleInput,
    handleForwardRef,
    disableSave,
    handleCreate,
    timezoneOptions,
    tmpAvatarImg,
    setTmpAvatarImg,
    handleAddressInput,
    handleMailingSameAsPhysicalInput,
    inputRefs,
    errorMessage,
  } = usePropertyCreateModal({ modalData });

  return (
    <ModalWrapper
      modalData={modalData}
      formData={{ ...property, tmpAvatarImg }}
      primaryButtonOnClick={handleCreate}
      disabled={disableSave}
      resourceName="Property"
      title="Create Property"
      width="48rem"
      className="px-8"
      wrapperClassName={(isSaving || isLoading) && "loading"}
    >
      <div className="mb-4">
        <ResourceInformationForm
          isResourceNameUnique={!titleExists}
          resource={property}
          resourceInformation="Property Information"
          resourceTypes={propertyTypeOptions}
          handleInput={handleSimpleInput}
          onForwardRef={handleForwardRef}
          timezoneOptions={timezoneOptions}
          tmpAvatarImg={tmpAvatarImg}
          setTmpAvatarImg={setTmpAvatarImg}
        />
        <Widget draggable={false} title="Address" className="mt-2" overflow>
          <BillingAddress
            address={property?.address}
            mailingSameAsPhysical={property.mailingSameAsPhysical}
            onChange={handleAddressInput}
            handleMailingSameAsPhysicalInput={handleMailingSameAsPhysicalInput}
            onForwardRef={handleForwardRef}
            inputRefs={inputRefs}
            rowGap="gap-4"
          />
        </Widget>
        {!!errorMessage && <ErrorBanner text={errorMessage} />}
      </div>
    </ModalWrapper>
  );
};

export default PropertyCreateModal;

import { useRef, useState } from "react";
import useOutsideAlerter from "../../../../../hooks/useOutsideAlerter";

const useNotesCellData = ({ editedRow, setEditedRow }) => {
  const [showPopup, setShowPopup] = useState(false);
  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef, () => setShowPopup(false));

  const onChange = (newVal) => {
    setEditedRow({
      ...editedRow,
      notes: newVal,
    });
  };

  return {
    wrapperRef,
    showPopup,
    setShowPopup,
    onChange,
  };
};
export default useNotesCellData;

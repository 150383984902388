import React from "react";
import PropTypes from "prop-types";
import TableCellInput from "../../../Table/TableCellInput";
import useCalculationCellData from "./useCalculationCellData";

const CalculationCell = ({
  row,
  isEditingTable,
  setEditedRow,
  editedRow,
  isHighRangeField,
}) => {
  const { isHidden, value, onCalculationChange } = useCalculationCellData({
    isHighRangeField,
    editedRow,
    setEditedRow,
  });

  if (!isEditingTable) {
    return isHighRangeField
      ? row?.original?.highRange?.calculation
      : row?.original?.calculation;
  }

  return (
    <div className={`flex w-full ${isHidden && "invisible"}`}>
      <TableCellInput value={value} onChange={onCalculationChange} />
    </div>
  );
};

CalculationCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      calculation: PropTypes.string,
      highRange: PropTypes.shape({
        calculation: PropTypes.string,
      }),
    }),
  }),
  setEditedRow: PropTypes.func,
  editedRow: PropTypes.shape({
    calculation: PropTypes.string,
    costPerUnit: PropTypes.string,
  }),
  isEditingTable: PropTypes.bool,
  isHighRangeField: PropTypes.bool,
};

CalculationCell.defaultProps = {
  row: undefined,
  setEditedRow: () => {},
  editedRow: {},
  isEditingTable: false,
  isHighRangeField: false,
};

export default CalculationCell;

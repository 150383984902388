import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { SET_IS_EDITING_RESOURCE } from "../constants";
import { useAppState } from "../state/appState";

const useEditingResourceState = ({ resource, editing }) => {
  const history = useHistory();
  const [{ editingResource }, appDispatch] = useAppState();

  useEffect(() => {
    // if user cancels out from edit mode, then reset resource edit state to false
    if (!editing && editingResource?.editing) {
      appDispatch({
        type: SET_IS_EDITING_RESOURCE,
        resource: undefined,
        editing: false,
      });
    }
  }, [editing, appDispatch, editingResource?.editing]);

  useEffect(() => {
    const unsubscribe = () =>
      // reset editing resource state if user navigates away while in editing mode
      history.listen(() => {
        appDispatch({
          type: SET_IS_EDITING_RESOURCE,
          resource: undefined,
          editing: false,
        });
      });
    // cleanup
    return () => {
      unsubscribe();
    };
  }, [history, appDispatch]);

  useEffect(() => {
    // set global state for editing resource
    if (editing) {
      appDispatch({
        type: SET_IS_EDITING_RESOURCE,
        resource,
        editing,
      });
    }
  }, [appDispatch, editing, resource]);
};

export default useEditingResourceState;

import React from "react";
import { RealTimeTenantProvider } from "@griffingroupglobal/eslib-ably/react";
import ApiClient from "@griffingroupglobal/eslib-api";
import Spinner from "../../stories/Components/Spinner/Spinner";
import MainContainer from "../../Pages/MainContainer";
import ESChatContext from "../../context/ChatContext/ESChatContext";
import useAuthenticatedContainerData from "./useAuthenticatedContainerData";
import RealTimeConnectionStatus from "../../components/RealTimeConnectionStatus";

const AuthenticatedContainer = () => {
  const { isLoading, currentUser, tenant, isRegistered } =
    useAuthenticatedContainerData();

  if (isLoading || !currentUser?.id) {
    return (
      <div className="flex justify-center items-center w-full h-full">
        <Spinner notFullScreen />
      </div>
    );
  }

  return (
    <RealTimeTenantProvider
      tenantId={tenant}
      currentUser={currentUser}
      esLibApiClient={ApiClient}
      showDevLogs={process.env.NODE_ENV === "development"}
    >
      <ESChatContext>
        <MainContainer isLoading={isLoading} isRegistered={isRegistered} />
      </ESChatContext>

      <RealTimeConnectionStatus />
    </RealTimeTenantProvider>
  );
};

export default AuthenticatedContainer;

import React, { useRef } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import { useDragAndDrop } from "../../../hooks/useDragAndDrop";

const itemContainerCN = (isDragging, className) => cntl`
  DraggableItemContainer
  cursor-move
  ${isDragging ? "opacity-25" : "opacity-100"}
  ${className}
`;

const DraggableItem = ({
  itemIndex,
  id,
  item,
  itemType,
  className,
  onReorder,
  children,
  style,
}) => {
  const ref = useRef(null);

  const { isDragging } = useDragAndDrop(ref, itemType, {
    currentOrder: itemIndex,
    id,
    item,
    children,
    hover: (draggedItem) => {
      const dragIndex = draggedItem.currentOrder;
      const hoverIndex = itemIndex;

      if (dragIndex === hoverIndex) {
        return;
      }

      onReorder(dragIndex, hoverIndex);

      // mutates the monitor item here for performance
      // eslint-disable-next-line no-param-reassign
      draggedItem.currentOrder = itemIndex;
    },
  });

  return (
    <div
      ref={ref}
      className={itemContainerCN(isDragging, className)}
      style={style}
    >
      {children}
    </div>
  );
};

DraggableItem.propTypes = {
  id: PropTypes.string,
  item: PropTypes.shape({}),
  itemIndex: PropTypes.number,
  itemType: PropTypes.string,
  className: PropTypes.string,
  onReorder: PropTypes.func,
  children: PropTypes.element,
  style: PropTypes.shape({}),
};

DraggableItem.defaultProps = {
  id: undefined,
  item: undefined,
  itemIndex: undefined,
  itemType: undefined,
  className: undefined,
  onReorder: () => {},
  children: undefined,
  style: undefined,
};

export default DraggableItem;

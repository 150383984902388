import { useMutation, useQueryClient } from "react-query";
import addSpaceHandler from "./handlers/addSpaceHandler";
import addSpaceResponder from "./responders/addSpaceResponder";
import addToSpaceListStore from "../../../helpers/Spaces/addToSpaceListStore";
import addToAssociationSpaceListStore from "../../../helpers/Spaces/addToAssociationSpaceListStore";

const useAddSpace = () => {
  const queryClient = useQueryClient();

  const createMutation = useMutation(
    addSpaceHandler,
    addSpaceResponder(queryClient)
  );

  /**
   * Adds space to list, can specify whether to make a new request or not
   *
   * @param {Props} space
   * @param {boolean} newRequest optional defaults to true, if false is sent an api request will not be attempted
   */
  const addSpace = async (props, newRequest = true) => {
    if (newRequest === false) {
      addToSpaceListStore({ queryClient, space: props });
      return addToAssociationSpaceListStore({ queryClient, space: props });
    }

    const response = await createMutation.mutateAsync(props);
    return response;
  };

  const {
    isLoading: isAddingSpace,
    isError: isAddingSpaceError,
    error: addingSpaceError,
  } = createMutation;

  return {
    isAddingSpace,
    isAddingSpaceError,
    addingSpaceError,
    addSpace,
  };
};

export default useAddSpace;

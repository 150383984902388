import { TimesheetAPI } from "@griffingroupglobal/eslib-api";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import { timesheetKeys } from "../config/reactQuery/queryKeyFactory";
import useCurrentUser from "./useCurrentUser";

const getEntriesDict = (timesheet, entries) => {
  const entriesDict = {};
  entries?.map((entry) => {
    entriesDict[entry?.id] = { ...entry, userRef: timesheet.userRef };
    return entry;
  });

  return entriesDict;
};

const getTimesheets = async (params) => {
  const timesheetsDict = {};

  const { data } = await TimesheetAPI.get({
    params: {
      project: params?.project,
    },
  });

  const timesheets = data?.entries?.map(({ resource }) => {
    timesheetsDict[resource?.reference] = getEntriesDict(
      resource,
      resource.entries
    );
    return resource;
  });

  return { timesheets, timesheetsDict };
};

/**
 * Fetch all submittals or by projects
 * @param {Object} params object that holds the association reference Ex: { project: Project/1234-5678-0000}
 * @returns
 */
const useTimesheetsForBudget = (params) => {
  const { data: currentUser } = useCurrentUser();

  // Get simple or compund query key based on params
  const queryKey = timesheetKeys.timesheetsByAssociation(params?.project);

  // Enabled query if there is an user signed in
  const enabled =
    !!currentUser?.permissions?.timesheet?.can_read && !!params?.project;

  return useAuthenticatedQuery({
    queryKey,
    queryFn: () => getTimesheets(params),
    enabled,
    onError: (error) => {
      console.error("useTimesheetsForBudget", error);
    },
  });
};

export default useTimesheetsForBudget;

import Api from "@griffingroupglobal/eslib-api";

const initApiConfig = () => {
  const { API_HOST } = window.runConfig;
  Api.config.init({
    rootUrl: API_HOST || "https://api.es-feature.io",
    storage: {},
  });
};

export default initApiConfig;

import debounceAsync from "../debounceAsync";

/**
 * Modifies the search text of a specific tab on a page.
 *
 * @param {Object} params - The parameters.
 * @param {string} params.page - The page where the tab is located.
 * @param {string} params.tab - The tab to modify.
 * @param {string} params.text - The new search text.
 * @param {Object} params.userSettings - The current user settings.
 * @param {Function} params.editSettings - The function to edit the user settings.
 * @returns {Function} A debounced function that modifies the page search settings.
 */

const modifyPageSearchText = ({ page, text, userSettings, editSettings }) => {
  const newSettings = {
    ...userSettings,
    web: {
      ...userSettings?.web,
      [page]: {
        ...userSettings?.web?.[page],
        search: text,
      },
    },
  };

  return debounceAsync(editSettings(newSettings));
};

export default modifyPageSearchText;

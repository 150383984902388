import React from "react";
import HoursOfOperation from "../../../stories/Components/HoursOfOperation/HoursOfOperation";
import FinancesInformation from "../../../stories/Components/Finances/FinancesInformation";

const PropertyFinances = ({ financeState, dispatchFinance, editing }) => {
  return (
    <>
      <FinancesInformation
        editedObject={financeState}
        editing={editing}
        resourceName="Property"
        dispatchFinance={dispatchFinance}
      />

      <div className="flex flex-col">
        <div className="relative flex gap-4 w-full">
          <div className="w-1/2 pr-2">
            <HoursOfOperation
              resource={financeState}
              isEditing={editing}
              dispatchFinance={dispatchFinance}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PropertyFinances;

import React from "react";
import { useHistory } from "react-router";
import PropTypes from "prop-types";

import Modal from "../../../stories/Components/Modal/Modal";
import { PROPERTY_TABLE_PATH } from "../../../constants";
import { useDeleteProperty } from "../../../hooks/api/properties";
import {
  toastError,
  toastMessage,
} from "../../../stories/Components/Toast/Toast";

export default function PropertyDeleteModal({
  property,
  showDeleteModal,
  setShowDeleteModal,
}) {
  const history = useHistory();
  const { deleteProperty } = useDeleteProperty();

  const onDeleteClicked = () => {
    deleteProperty(property?.id)
      .then(() => {
        const display = "Property was deleted successfully";
        toastMessage(display);
        history.push(PROPERTY_TABLE_PATH);
      })
      .catch(() => {
        const display = "Property could not be deleted. Please try again";
        toastError(display);
      });
    setShowDeleteModal(false);
  };

  return (
    <Modal
      title="Delete Property?"
      isOpen={showDeleteModal}
      primaryButtonTitle="Yes, Delete"
      primaryButtonOnClick={onDeleteClicked}
      tertiaryButtonTitle="Cancel"
      onRequestModalClose={() => setShowDeleteModal(false)}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      hideFooter
      alert
    >
      <>
        <p className="text-base mb-2">
          Are you sure you want to delete {property?.title}? Once deleted, this
          property and its data cannot be recovered.
        </p>
      </>
    </Modal>
  );
}

PropertyDeleteModal.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  property: PropTypes.object,
  showDeleteModal: PropTypes.bool,
  setShowDeleteModal: PropTypes.func,
};

PropertyDeleteModal.defaultProps = {
  property: null,
  showDeleteModal: false,
  setShowDeleteModal: () => {},
};

import React, { useMemo } from "react";
import moment from "moment";
import { capitalize } from "lodash";
import Table from "../Table/Table";
import { DateIsAfter } from "../../../helpers/Dates";
import { useAppState } from "../../../state/appState";

const PureTimeOffHistoryTable = ({
  data,
  columns,
  year,
  type,
  payrollPeriod,
}) => {
  const [{ usersDict }] = useAppState();

  const TimeOffCurrentUser = data?.map((entry) => {
    // dateRange
    const datesLength = entry?.dates?.length;
    const firstDay = entry.dates?.[0]?.date?.split("T")?.[0];
    const lastDay =
      datesLength > 0
        ? entry.dates[datesLength - 1]?.date?.split("T")?.[0]
        : firstDay;
    const editable = DateIsAfter(lastDay, payrollPeriod?.periodStart);

    let dateRange = "";
    if (datesLength === 1) {
      dateRange = moment(firstDay).format("L");
    }
    if (datesLength > 1) {
      dateRange = `${moment(firstDay).format("L")} to ${moment(lastDay).format(
        "L"
      )}`;
    }

    // totalhours
    const totalHoursRequested = entry?.dates?.reduce(
      (acc, current) => acc + parseFloat(current?.numHours),
      0
    );

    // statusInformation
    let statusInformation = "Pending";
    if (entry.status !== "pending") {
      statusInformation = `${capitalize(entry.status)} on ${moment(
        entry.dateReviewed
      ).format("L")}`;
    }
    if (usersDict && entry.dateReviewed) {
      const reviewer = usersDict[entry.reviewedBy];
      statusInformation += ` by ${reviewer?.name?.firstName}`;
    }

    return {
      ...entry,
      dateRange,
      firstDay: moment(firstDay).dayOfYear(),
      totalHoursRequested,
      statusInformation,
      editable,
    };
  });
  const filteredData = useMemo(() => {
    return TimeOffCurrentUser?.filter((item) => {
      const startDate = item?.dates?.[0]?.date;
      const endDate = item?.dateRange?.includes("to")
        ? moment(item?.dateRange?.split(" to ")?.[1]).year()
        : moment(startDate).year();
      const startDateYear = moment(startDate).year();
      if (type) {
        if (type === "personal") {
          return (
            (startDateYear === parseInt(year, 10) ||
              endDate === parseInt(year, 10)) &&
            item.type !== "sick" &&
            item.type !== "vacation"
          );
        }
        return (
          (startDateYear === parseInt(year, 10) ||
            endDate === parseInt(year, 10)) &&
          item.type === type
        );
      }
      return (
        startDateYear === parseInt(year, 10) || endDate === parseInt(year, 10)
      );
    });
  }, [TimeOffCurrentUser, type, year]);

  return (
    <Table
      disableDragging
      resourceName="Timesheets"
      showConstantRowIndex
      data={filteredData}
      columns={columns}
      hideSiteHeader
      defaultSort={[{ id: "firstDay", desc: false }]}
      hiddenColumns={["firstDay"]}
    />
  );
};

PureTimeOffHistoryTable.defaultProps = {
  data: [],
};

export default PureTimeOffHistoryTable;

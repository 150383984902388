import { TicketAPI } from "@griffingroupglobal/eslib-api";
import useCurrentUser from "./useCurrentUser";
import useAuthenticatedQuery from "./useAuthenticatedQuery";
import { ticketsKeys } from "../config/reactQuery/queryKeyFactory";
import { toastError } from "../stories/Components/Toast/Toast";

/**
 * Fecth all tickets or by params
 * @param {Object} params - (?) custom params, it could fetch by association or by assets
 * @returns array of tickets
 */
const getTickets = async (params) => {
  const { data } = await TicketAPI.get({
    params,
  });

  return data?.entries?.map(({ resource }) => resource) ?? [];
};

/**
 * Query hook to fetch all tickets or by association
 * @param {string} [association] (?) Property/Project association reference
 * @param {string} [asset] (?) asset association reference
 * @param {Function} [select] (?) custom function to transform data
 * @param {Array} [tasks] (?) an array of task references
 * @returns tickets in backend format
 */
const useServiceRequests = ({ association, asset, select, tasks } = {}) => {
  const { data: currentUser } = useCurrentUser();

  const canReadTickets =
    currentUser?.isAdmin || currentUser?.permissions?.ticket?.can_read;

  let queryKey;
  let params;

  if (asset) {
    queryKey = ticketsKeys.byAssociation(asset);
    params = {
      asset,
    };
  } else if (association) {
    queryKey = ticketsKeys.byAssociation(association);
    params = {
      association,
    };
  } else if (tasks?.length) {
    queryKey = ticketsKeys.ticketsByTaskRef(tasks?.[0]);
    params = {
      tasks: tasks?.join(","),
    };
  } else {
    queryKey = ticketsKeys.tickets;
  }

  return useAuthenticatedQuery({
    queryKey,
    queryFn: () => getTickets(params),
    enabled: !!canReadTickets,
    select,
    onError: (error) => {
      toastError("There was an issue loading this page. Please try again");
      console.error("useServiceRequests", error);
    },
  });
};

export default useServiceRequests;

import { useHistory, useLocation } from "react-router";

/**
 * Hook that allows to navigate back in the history stack.
 */
const useGoBack = () => {
  const history = useHistory();
  const location = useLocation();

  /**
   * Navigate back inside history stack or a specific url parameter
   * @param {string} [route] specific url parameter, Ex: /properties
   */
  const navigateBack = (route) => {
    if (location.key) {
      history.goBack();
    } else if (route) {
      // If there is a specific route, navigate to it
      history.push(`/${route}`);
    } else {
      // gets to the full path and get the first url parameter
      const urlSplit = location.pathname
        .split("/")
        .filter((parameter) => parameter);

      const firstParameter = urlSplit[0];
      history.push(firstParameter ? `/${firstParameter}` : "/");
    }
  };

  return {
    navigateBack,
  };
};

export default useGoBack;

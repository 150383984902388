import { useCallback, useEffect, useMemo, useState } from "react";
import { UsertenantAPI } from "@griffingroupglobal/eslib-api";
import moment from "moment";
import { useHistory } from "react-router";
import { useQueryClient } from "react-query";
import useCurrentUser from "../../../../hooks/useCurrentUser";
import useUserPatch from "../../../../hooks/useUserPatch";
import Auth from "../../../../helpers/Auth";
import useGetCurrentTenant from "../../../../hooks/useGetCurrentTenant";
import { tenantKeys } from "../../../../config/reactQuery/queryKeyFactory";
import { useUsers } from "../../../../hooks/useUsers.new";
import useFilesPost from "../../../../hooks/useFilesPost";
import uploadAvatar from "../../../../helpers/uploadAvatar";

const emptySocial = {
  platform: "LinkedIn",
  value: "",
};

const emptyDates = [
  {
    occasion: "Start Date",
    value: moment(),
  },
  {
    occasion: "Birthday",
    value: moment(),
  },
];

const useUserOnboardingForm = ({ temporaryPassword, email }) => {
  const history = useHistory();
  const { data: tenant } = useGetCurrentTenant(email);
  const queryClient = useQueryClient();
  const { data: currentUser, isLoading } = useCurrentUser();
  const [isTNCChecked, setIsTNCCheck] = useState(false);
  const [showProfile, setShowProfile] = useState(tenant?.registered);
  const [editableUser, setEditableUser] = useState({});
  const [tmpAvatarImg, setTmpAvatarImg] = useState({});
  // Prefetch users
  useUsers();
  const { mutate: patchUser } = useUserPatch();
  const { mutateAsync: postFiles } = useFilesPost();

  const isFormValid = useMemo(() => {
    return editableUser?.name?.firstName?.length >= 3;
  }, [editableUser?.name?.firstName?.length]);

  // UseEffect to populate the editableUser with the correct data from currentUser
  useEffect(() => {
    if (currentUser?.id) {
      const updatedCurrentUser = {
        ...currentUser,
        social: [emptySocial],
        dates: [...emptyDates],
      };
      setEditableUser((current) => ({
        ...current,
        ...updatedCurrentUser,
      }));
    }
  }, [currentUser]);

  const saveButtonHandler = useCallback(async () => {
    // if only user patch call fails, we should let the user in
    try {
      const newPayload = await uploadAvatar({
        tmpAvatarImg,
        resourceState: { ...currentUser, ...editableUser },
        postFiles,
        addToFiles: false,
        primaryImageKey: "avatar",
      });

      // TODO: (Parker) When My Profile is refactored check for empty values and remove this code (currently it does not and will show empty value + link icon)
      // if linkedIn field was not used remove blank value
      if (editableUser?.social[0]?.value === "") {
        newPayload.social = [];
      }

      patchUser({
        oldUserInfo: currentUser,
        newUserInfo: newPayload,
      });
    } catch (err) {
      console.warn("Profile Error", err);
    }
    // if only preference patch call fails, we should let the user in
    try {
      await UsertenantAPI.patch(
        "$legalaccepted",
        {
          legalAccepted: true,
        },
        {},
        {
          params: { email },
          date: new Date(),
        }
      );
    } catch (e) {
      console.warn(e, "update user tenant");
    }

    queryClient.invalidateQueries(tenantKeys.tenants);
    Auth.setRegistered(email, true);
    history.push("/");
  }, [
    history,
    currentUser,
    editableUser,
    patchUser,
    email,
    queryClient,
    postFiles,
    tmpAvatarImg,
  ]);

  return {
    setIsTNCCheck,
    isTNCChecked,
    editableUser,
    setEditableUser,
    currentUser,
    saveButtonHandler,
    showProfile,
    setShowProfile,
    isLoading,
    temporaryPassword,
    email,
    isFormValid,
    tmpAvatarImg,
    setTmpAvatarImg,
  };
};

export default useUserOnboardingForm;

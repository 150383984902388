import { isEmpty, isEqual } from "lodash";
import * as yup from "yup";
import PropTypes from "prop-types";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from "react";
import { v4 as uuidv4 } from "uuid";
import {
  ADD_OPEN_MODAL,
  ASSET,
  CREATE_ASSET,
  CREATE_EVENT_MODAL,
  CREATE_SPACE_MODAL,
  CREATE_TASK_MODAL,
  EXPENSE_CREATE_MODAL,
  PROJECT_CREATE_MODAL,
  REPORT_CREATE_MODAL,
  SERVICE_REQUEST_CREATE_MODAL,
  WORKFLOW_CREATE_MODAL,
  PROPERTY_TABS,
  MARK_AS_COMPLETE_BUTTON_TITLE,
} from "../../../constants";
import hasWritePermission from "../../../helpers/Permissions/hasWritePermissions";
import { onUpdateFile } from "../../../helpers/File";
import { toastError, toastMessage } from "../../../helpers/Toast";
import useAssociatedFiles from "../../../hooks/useAssociatedFiles";
import useAttributes from "../../../hooks/useAttributes";
import useEditModal from "../../../hooks/useEditModal";
import useManagementConfiguration from "../../../hooks/useManagementConfiguration";
import useRelativeAssociations from "../../../hooks/useRelativeAssociations";
import { useUsers } from "../../../hooks/useUsers.new";
import { useModalState } from "../../../state/modalState";
import FormAvatar from "../../../stories/Components/Avatar/FormAvatar";
import BetaTag from "../../../stories/Components/BetaTag";
import PrimaryButton from "../../../stories/Components/Buttons/PrimaryButton";
import FilesTable from "../../../stories/Components/FilesTable/FilesTable";
import InlineInput from "../../../stories/Components/Input/InlineInput";
import ImagesAndVideosWidget from "../../../stories/Components/MediaWidget/ImagesAndVideosWidget";
import SiteHeader from "../../../stories/Components/SiteHeader/SiteHeader";
import SpacesList from "../../../stories/Components/SpacesList/index";
import Widget from "../../../stories/Components/Widget/Widget";
import WidgetContainer from "../../../stories/Components/Widget/WidgetContainer";
import WorkflowTable from "../../../stories/Components/WorkflowTableNew";
import whiteCircleCheckIcon from "../../../stories/assets/images/circleCheckIcon.svg";
import whiteCrossIcon from "../../../stories/assets/images/whiteCrossIcon.svg";
import whiteExlamationIcon from "../../../stories/assets/images/whiteExclamationIcon.svg";
import CalendarView from "../../Calendar/CalendarView";
import ProjectList from "../../Project/ProjectList";
import ReportsOverview from "../../Reports/ReportOverview";
import Assets from "../Asset/Assets";
import ServiceRequestsOverview from "../ServiceRequests";
import TaskList from "../Task/TaskList";
import PropertyDeleteModal from "./PropertyDeleteModal";
import PropertyDetailView from "./PropertyDetailView";
import PropertyDocuments from "./PropertyDocuments";
import PropertyExpenses from "./PropertyExpenses";
import PropertyFinances from "./PropertyFinances";
import PropertyMaintenance from "./PropertyMaintenance";
import usePropertyViewData from "./usePropertyViewData";
import { hasReadPermission } from "../../../helpers/Permissions";
import formatOwnedByForm from "../../../helpers/Format/formatOwnedByForm";
import useFilesPost from "../../../hooks/useFilesPost";
import uploadAvatar from "../../../helpers/uploadAvatar";
import useEditingResourceState from "../../../hooks/useEditingResourceState";
import RealTimeDataBar from "../../../components/RealTimeDataBar";
import RealTimeInactivityAlert from "../../../components/RealTimeInactivityAlert";

const toastIcon = <img src={whiteCircleCheckIcon} alt="Successful upload" />;
const toastCloseIcon = (
  <img className="mr-2" src={whiteCrossIcon} alt="Close notice" />
);
const toastErrorIcon = <img src={whiteExlamationIcon} alt="Error icon" />;

const PropertyView = ({ propertyId }) => {
  // Mutation hook to post files
  const { mutateAsync: postFiles } = useFilesPost();

  const {
    property,
    isLoading,
    currentUser,
    editing,
    disableEditing,
    realtimeUsers,
    lockState,
    hideActionsDropdown,
    financeState,
    editedProperty,
    isPropertyEdited,
    titleExists,
    activeTab,
    isDetailsTab,
    isFinanceTab,
    setActiveTab,
    dispatchFinance,
    handleEditClick,
    editProperty,
    isTabLocked,
    getLockInfo,
    handleEditFinance,
    resetFinanceState,
    fetchPropertyByTitle,
    dispatch,
  } = usePropertyViewData(propertyId);

  const [{ modals }, modalDispatch] = useModalState();
  const [isSaving, setIsSaving] = useState(false);

  const { data } = useUsers();
  const userDict = data?.userDict;

  const params = useMemo(
    () => ({ association: `Property/${propertyId}` }),
    [propertyId]
  );
  const {
    associatedFiles,
    addFiles,
    removeFilesAndUpdateApi,
    cloneFile,
    patchFile,
  } = useAssociatedFiles(params);
  const [, setIsEditModalOpen] = useEditModal();

  const { data: managementConfiguration } = useManagementConfiguration();
  const [measurements] = useAttributes();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [memberLoading, setMemberLoading] = useState(false);

  const [newAttributes, setNewAttributes] = useState([]);
  const [buttonActions, setButtonActions] = useState([]);
  const [tmpAvatarImg, setTmpAvatarImg] = useState({});

  const [isEditableTab, setIsEditableTab] = useState(false);

  // handle editing state of resource being edited
  useEditingResourceState({
    editing,
    resource: "Property",
  });

  const resetPropertyState = useCallback(() => {
    setTmpAvatarImg({});
    dispatch({
      type: "reset",
      property,
    });
  }, [property, dispatch]);

  useEffect(() => {
    if (property && !isEqual(property, editedProperty)) {
      resetPropertyState();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [property, resetPropertyState]);

  const handleAddMedia = useCallback(
    async (imageResources) => {
      const updatedFiles = [
        ...property.files,
        ...imageResources?.map((imageResource) => ({
          ref: imageResource.reference,
          category: imageResource.category,
        })),
      ];

      const primary =
        property.primaryImage ||
        updatedFiles.find((file) => file.category === "Photos")?.ref;

      const updatedProperty = {
        ...property,
        files: updatedFiles,
        primaryImage: primary,
      };

      // patch resource
      await editProperty({
        originalItem: property,
        editedItem: updatedProperty,
      });

      // update associated files state
      addFiles(imageResources);
    },
    [addFiles, editProperty, property]
  );

  const handleRemoveMedia = useCallback(
    async (imageRefs) => {
      const updatedFiles = property.files.filter(
        (file) => !imageRefs.includes(file.ref)
      );
      const primary = imageRefs.includes(property?.primaryImage)
        ? updatedFiles.find((file) => file.category === "Photos")?.ref
        : property.primaryImage;
      const updatedProperty = {
        ...property,
        files: updatedFiles,
        primaryImage: primary,
      };

      try {
        // patch resource
        await editProperty({
          originalItem: property,
          editedItem: updatedProperty,
        });
        // update associated files state
        removeFilesAndUpdateApi(imageRefs);
      } catch (error) {
        toastError("Error removing media", toastErrorIcon, toastCloseIcon);
      }
    },
    [editProperty, property, removeFilesAndUpdateApi]
  );

  const updatePropertyFiles = useCallback(
    async (fileRefs) => {
      const updatedFiles = property.files.filter(
        (file) => !fileRefs.includes(file.ref)
      );
      const primary = fileRefs.includes(property?.primaryImage)
        ? updatedFiles.find((file) => file.category === "Photos")?.ref
        : property.primaryImage;
      const updatedProperty = {
        ...property,
        files: updatedFiles,
        primaryImage: primary,
      };

      try {
        // patch resource
        await editProperty({
          originalItem: property,
          editedItem: updatedProperty,
        });
      } catch (error) {
        toastError("Error updating property", toastErrorIcon, toastCloseIcon);
      }
    },
    [editProperty, property]
  );

  const handleSetPrimaryMedia = useCallback(
    async (imageRef) => {
      const updatedProperty = {
        ...property,
        primaryImage: imageRef,
      };

      try {
        // patch resource
        await editProperty({
          originalItem: property,
          editedItem: updatedProperty,
        });
      } catch (error) {
        toastError("Error updating property", toastErrorIcon, toastCloseIcon);
      }
    },
    [editProperty, property]
  );

  const onAddFilesCallback = useCallback(
    async (filesUploaded) => {
      // update associated files state
      addFiles(filesUploaded);

      const updatedFiles = [
        ...property.files,
        ...filesUploaded.map((file) => ({
          ref: file.reference,
          category: file.category,
        })),
      ];

      const updatedProperty = {
        ...property,
        files: updatedFiles,
        primaryImage:
          property?.primaryImage ||
          updatedFiles.find((file) => file.category === "Photos")?.ref,
      };

      try {
        // patch resource
        await editProperty({
          originalItem: property,
          editedItem: updatedProperty,
        });
      } catch (error) {
        toastError("Error updating property", toastErrorIcon, toastCloseIcon);
      }
    },
    [addFiles, editProperty, property]
  );

  const handleEdit = useCallback(
    (key, value) => {
      dispatch({
        type: "edit",
        key,
        value,
      });
    },
    [dispatch]
  );

  const handleEditTwo = useCallback(
    (key1, key2, value) => {
      dispatch({
        type: "edit2",
        key1,
        key2,
        value,
      });
    },
    [dispatch]
  );

  const handleFileClone = useCallback(
    (fileId) => {
      cloneFile(fileId)
        .then((clonedFile) => {
          const updatedProperty = {
            ...property,
            files: [
              ...property?.files,
              { ref: clonedFile.reference, category: clonedFile.category },
            ],
          };

          return editProperty({
            originalItem: property,
            editedItem: updatedProperty,
          });
        })
        .then((resource) =>
          toastMessage(
            `Recent file successfully attached to ${resource?.title}`,
            toastIcon,
            toastCloseIcon
          )
        )
        .catch(() => {
          toastError(
            `Error attaching recent file`,
            toastErrorIcon,
            toastCloseIcon
          );
          // remove created files if PATCH fails
          removeFilesAndUpdateApi([`File/${fileId}`]);
        });
    },
    [cloneFile, editProperty, property, removeFilesAndUpdateApi]
  );

  const timeoutId = useRef(null);

  const handleChangeTitle = (title) => {
    dispatch({
      type: "title",
      title,
    });

    if (timeoutId.current) {
      clearTimeout(timeoutId.current);
    }

    // debounce check if property title is in use
    timeoutId.current = setTimeout(async () => {
      await fetchPropertyByTitle(title);
      clearTimeout(timeoutId.current);
    }, 200);
  };

  const handleUpdateFile = useCallback(
    ({ originalResource, currentTags, name }) => {
      onUpdateFile({ originalResource, currentTags, name, patchFile });
    },
    [patchFile]
  );

  // set property view tabs
  const tabs = useMemo(() => {
    const permissionedTabs = {
      tabs: [
        {
          id: PROPERTY_TABS.DETAILS_ID,
          title: PROPERTY_TABS.DETAILS_TITLE,
          content: (
            <PropertyDetailView
              editedProperty={editedProperty}
              dispatch={dispatch}
              onChange={handleEdit}
              onChangeAlt={handleEditTwo}
              config={managementConfiguration?.management}
              setNewAttributes={setNewAttributes}
              measurements={measurements}
              newAttributes={newAttributes}
              editing={editing}
              loading={isLoading}
              memberLoading={memberLoading}
              setMemberLoading={setMemberLoading}
              disableEditing={disableEditing}
            />
          ),
        },
      ],
    };

    if (hasReadPermission(ASSET, currentUser)) {
      permissionedTabs.tabs.push({
        id: PROPERTY_TABS.ASSETS_ID,
        title: PROPERTY_TABS.ASSETS_TITLE,
        content: <Assets />,
      });
    }

    if (currentUser?.hasPermission?.("project", "can_read")) {
      permissionedTabs.tabs.push({
        id: PROPERTY_TABS.PROJECTS_ID,
        title: PROPERTY_TABS.PROJECTS_TITLE,
        content: (
          <ProjectList property={property} setActions={setButtonActions} />
        ),
      });
    }

    permissionedTabs.tabs.push({
      id: "spaces",
      title: "Spaces",
      content: <SpacesList />,
    });

    permissionedTabs.tabs.push({
      id: PROPERTY_TABS.MEDIA_ID,
      title: PROPERTY_TABS.MEDIA_TITLE,
      content: (
        <ImagesAndVideosWidget
          resource={property}
          disableEditing={disableEditing}
          hasWritePermission={currentUser?.hasPermission?.(
            "administrative",
            "can_write_property"
          )}
          hasDeletePermission={currentUser?.hasPermission?.(
            "administrative",
            "can_write_property"
          )}
          handleAddMedia={handleAddMedia}
          handleSetPrimaryMedia={handleSetPrimaryMedia}
          handleRemoveMedia={handleRemoveMedia}
          setActions={setButtonActions}
        />
      ),
    });

    if (
      currentUser?.hasPermission?.("event", "can_read") ||
      currentUser?.hasPermission?.("task", "can_read") ||
      currentUser?.hasPermission?.("task", "can_only_read_assigned")
    ) {
      permissionedTabs.tabs.push({
        id: PROPERTY_TABS.CALENDAR_ID,
        title: PROPERTY_TABS.CALENDAR_TITLE,
        content: <CalendarView currentUser={currentUser} isTabView />,
      });
    }

    if (
      currentUser?.hasPermission?.("task", "can_read") ||
      currentUser?.hasPermission?.("task", "can_only_read_assigned")
    ) {
      permissionedTabs.tabs.push({
        id: PROPERTY_TABS.TASKS_ID,
        title: PROPERTY_TABS.TASKS_TITLE,
        content: <TaskList />,
      });
    }

    if (currentUser?.hasPermission?.("ticket", "can_read")) {
      permissionedTabs.tabs.push({
        id: PROPERTY_TABS.REQUESTS_ID,
        title: PROPERTY_TABS.REQUESTS_TITLE,
        content: (
          <ServiceRequestsOverview
            association={`Property/${propertyId}`}
            setButtonActions={setButtonActions}
          />
        ),
      });
    }

    permissionedTabs.tabs.push({
      id: PROPERTY_TABS.FILES_ID,
      title: PROPERTY_TABS.FILES_TITLE,
      content: (
        <Widget draggable={false} title={null} overflow backToTop>
          <FilesTable
            files={associatedFiles}
            onAddFilesCallback={onAddFilesCallback}
            removeFilesAndUpdateApi={removeFilesAndUpdateApi}
            onRemoveFilesCallback={updatePropertyFiles}
            setIsEditModalOpen={setIsEditModalOpen}
            resourceName="Property"
            association={`Property/${propertyId}`}
            hasDeletePermission={currentUser?.hasPermission?.(
              "administrative",
              "can_write_property"
            )}
            hasWritePermission={currentUser?.hasPermission?.(
              "administrative",
              "can_write_property"
            )}
            hasEditPermission={currentUser?.hasPermission?.(
              "administrative",
              "can_write_property"
            )}
            handleFileClone={handleFileClone}
            handleUpdateFile={handleUpdateFile}
          />
        </Widget>
      ),
    });

    permissionedTabs.filesIndex = permissionedTabs.tabs.length - 1;

    if (currentUser?.hasPermission?.("expense", "can_read")) {
      permissionedTabs.tabs.push({
        id: PROPERTY_TABS.EXPENSES_ID,
        title: PROPERTY_TABS.EXPENSES_TITLE,
        content: (
          <Widget draggable={false} title={null} overflow backToTop>
            <PropertyExpenses
              property={property}
              isTabView
              setButtonActions={setButtonActions}
            />
          </Widget>
        ),
      });
    }

    if (currentUser?.hasPermission?.("property", "can_read_financials")) {
      permissionedTabs.tabs.push({
        id: PROPERTY_TABS.FINANCES_ID,
        title: PROPERTY_TABS.FINANCES_TITLE,
        content: (
          <PropertyFinances
            financeState={financeState}
            dispatchFinance={dispatchFinance}
            editing={editing}
            setEditing={handleEditClick}
          />
        ),
      });
    }

    if (currentUser?.hasPermission?.("workflow", "can_read")) {
      permissionedTabs.tabs.push({
        id: PROPERTY_TABS.WORKFLOWS_ID,
        title: PROPERTY_TABS.WORKFLOWS_TITLE,
        content: (
          <Widget draggable={false} title={null} overflow backToTop>
            <WorkflowTable
              associatedResource={`Property/${propertyId}`}
              setButtonActions={setButtonActions}
              isTabView
            />
          </Widget>
        ),
      });
    }

    if (currentUser?.hasPermission?.("report", "can_read")) {
      permissionedTabs.tabs.push({
        id: PROPERTY_TABS.REPORTS_ID,
        title: (
          <div className="flex">
            <span>{PROPERTY_TABS.REPORTS_TITLE}</span>
            <BetaTag />
          </div>
        ),
        content: (
          <ReportsOverview
            association={`Property/${propertyId}`}
            setButtonActions={setButtonActions}
          />
        ),
      });
    }

    if (
      currentUser?.hasPermission?.("task", "can_read") ||
      currentUser?.hasPermission?.("task", "can_only_read_assigned")
    ) {
      permissionedTabs.tabs.push({
        id: "maintenance",
        title: "Maintenance",
        content: (
          <PropertyMaintenance currentUser={currentUser} userDict={userDict} />
        ),
        isHidden: true,
      });
    }

    permissionedTabs.tabs.push({
      id: "documents",
      title: "Documents",
      content: <PropertyDocuments />,
      isHidden: true,
    });

    return permissionedTabs;
  }, [
    associatedFiles,
    currentUser,
    dispatch,
    editedProperty,
    editing,
    handleAddMedia,
    handleEdit,
    handleEditTwo,
    handleFileClone,
    handleRemoveMedia,
    handleSetPrimaryMedia,
    handleUpdateFile,
    isLoading,
    managementConfiguration?.management,
    measurements,
    memberLoading,
    newAttributes,
    onAddFilesCallback,
    property,
    propertyId,
    removeFilesAndUpdateApi,
    setIsEditModalOpen,
    updatePropertyFiles,
    userDict,
    handleEditClick,
    disableEditing,
    financeState,
    dispatchFinance,
  ]);

  const { associationLock } = useRelativeAssociations();
  // Render Tab Specific CTA Button
  /**
   * @TODO - TechDebt: Convert All Create Modals legacy->ESModal(Can open modal from any component)
   * @summary - will remove the need for the button action pingpong
   * Parent (setter) -> Child (btnAction) -> Parent
   */

  const filterButtonActions = useCallback(
    (currentButtonActions) => {
      // Check if each modal is open and store the result
      const isEventModalOpen = modals?.some(
        (modal) => modal?.modalType === CREATE_EVENT_MODAL
      );
      const isTaskModalOpen = modals?.some(
        (modal) => modal?.modalType === CREATE_TASK_MODAL
      );

      // Filter out the button actions based on the modal states
      return currentButtonActions.filter((action) => {
        if (isEventModalOpen && action.title === "Add Event") return false;
        if (isTaskModalOpen && action.title === "Add Task") return false;

        return true;
      });
    },
    [modals]
  );

  const ActionButton = useMemo(() => {
    // By default, hide the edit pencil
    setIsEditableTab(false);
    const calendarButtonArray = [];
    // Spreadable Prop Object
    let buttonProps = {};
    switch (activeTab) {
      case PROPERTY_TABS.DETAILS_ID: {
        const options = [];
        if (
          currentUser?.hasPermission?.("administrative", "can_write_property")
        ) {
          setIsEditableTab(true);
        }

        if (
          currentUser?.hasPermission?.("administrative", "can_delete_property")
        ) {
          options.push({
            title: "Delete Property",
            onClick: () => setShowDeleteModal(true),
          });
        }
        buttonProps = {
          dropdownItems: options,
          title: "Actions",
          className: "dropdown-btn",
          large: true,
        };

        if (options.length === 0 || hideActionsDropdown) return null;
        break;
      }
      case PROPERTY_TABS.ASSETS_ID:
        buttonProps = {
          className: !hasWritePermission(ASSET, currentUser) && "hidden",
          onClick: () => {
            const id = uuidv4();
            modalDispatch({
              type: ADD_OPEN_MODAL,
              modalData: {
                item: {
                  propertyId,
                  association: property?.reference,
                  associationType: "Property",
                  disableAssociation: true,
                },
              },
              ref: { id },
              modalType: CREATE_ASSET,
            });
          },
          addButton: true,
          resourceName: "Asset",
          disabled: modals?.find((item) => item.modalType === CREATE_ASSET),
        };
        break;
      case PROPERTY_TABS.PROJECTS_ID:
        buttonProps = {
          className:
            !currentUser?.hasPermission(
              "administrative",
              "can_write_project"
            ) && "hidden",
          onClick: () => {
            modalDispatch({
              type: ADD_OPEN_MODAL,
              ref: { id: uuidv4() },
              modalData: { property },
              modalType: PROJECT_CREATE_MODAL,
            });
          },
          addButton: true,
          resourceName: "Project",
          disabled: modals.find(
            (item) => item.modalType === PROJECT_CREATE_MODAL
          ),
        };
        break;
      case PROPERTY_TABS.SPACES_ID:
        buttonProps = {
          className:
            !currentUser?.hasPermission?.(
              "administrative",
              "can_write_property"
            ) && "hidden",
          onClick: () => {
            modalDispatch({
              type: ADD_OPEN_MODAL,
              ref: { id: uuidv4() },
              modalData: {
                viewMode: "create",
                associationLock: `Property/${propertyId}`,
              },
              modalType: CREATE_SPACE_MODAL,
            });
          },
          addButton: true,
          resourceName: "Space",
          disabled: modals?.find(
            (item) => item.modalType === CREATE_SPACE_MODAL
          ),
        };
        break;
      case PROPERTY_TABS.MEDIA_ID:
        buttonProps = {
          className:
            !currentUser?.hasPermission?.(
              "administrative",
              "can_write_property"
            ) && "hidden",
          addButton: true,
          resourceName: "Media",
          onClick: () => document.getElementById("upload-form-input").click(),
        };
        break;
      case PROPERTY_TABS.CALENDAR_ID: {
        const disabledAddEventBtn = modals?.find(
          (item) => item.modalType === CREATE_EVENT_MODAL
        );
        const disabledAddTaskBtn = modals?.find(
          (item) => item.modalType === CREATE_TASK_MODAL
        );

        if (
          currentUser?.hasPermission?.("event", "can_write") &&
          !disabledAddEventBtn
        ) {
          calendarButtonArray.push({
            title: "Add Event",
            onClick: () =>
              modalDispatch({
                type: ADD_OPEN_MODAL,
                ref: { id: uuidv4() },
                modalData: { associationLock: false },
                modalType: CREATE_EVENT_MODAL,
              }),
          });
        }
        if (
          currentUser?.hasPermission?.("task", "can_create") &&
          !disabledAddTaskBtn
        ) {
          calendarButtonArray.push({
            title: "Add Task",
            onClick: () => {
              modalDispatch({
                type: ADD_OPEN_MODAL,
                ref: { id: uuidv4() },
                modalData: { associationLock },
                modalType: CREATE_TASK_MODAL,
              });
            },
          });
        }

        if (calendarButtonArray?.length) {
          buttonProps = {
            dropdownItems: filterButtonActions(calendarButtonArray),
            title: "Actions",
            className: "dropdown-btn",
            large: true,
          };
        }
        break;
      }
      case PROPERTY_TABS.TASKS_ID:
        buttonProps = {
          className:
            !currentUser?.hasPermission?.("task", "can_create") && "hidden",
          onClick: () => {
            modalDispatch({
              type: ADD_OPEN_MODAL,
              ref: { id: uuidv4() },
              modalData: { associationLock, to: "task_list" },
              modalType: CREATE_TASK_MODAL,
            });
          },
          addButton: true,
          resourceName: "Task",
          disabled: modals?.find(
            (item) => item.modalType === CREATE_TASK_MODAL
          ),
        };
        break;
      case PROPERTY_TABS.REQUESTS_ID: {
        const actionButton = buttonActions?.[0] || {};
        if (
          actionButton.title === MARK_AS_COMPLETE_BUTTON_TITLE &&
          currentUser?.hasPermission?.("ticket", "can_update")
        ) {
          // SR is rendered in Property association Tab
          buttonProps = {
            ...buttonActions?.[0],
            addButton: true,
            resourceName: "Ticket",
            buttonTitle: actionButton.title,
          };
        } else if (currentUser?.hasPermission?.("ticket", "can_create")) {
          // SR is rendered on its own page
          buttonProps = {
            onClick: () => {
              modalDispatch({
                type: ADD_OPEN_MODAL,
                ref: { id: uuidv4() },
                modalData: {
                  viewMode: "create",
                  associationLock: `Property/${propertyId}`,
                },
                modalType: SERVICE_REQUEST_CREATE_MODAL,
              });
            },
            addButton: true,
            resourceName: "Ticket",
            className:
              !currentUser?.hasPermission?.("ticket", "can_create") && "hidden",
            disabled: modals?.find(
              (item) => item.modalType === SERVICE_REQUEST_CREATE_MODAL
            ),
          };
        }
        break;
      }
      case PROPERTY_TABS.FILES_ID:
        buttonProps = {
          className:
            !currentUser?.hasPermission?.(
              "administrative",
              "can_write_property"
            ) && "hidden",
          addButton: true,
          resourceName: "File",
          onClick: () =>
            document
              .querySelector(`div.property-table-view .upload_area_click`)
              ?.click(),
        };
        break;
      case PROPERTY_TABS.EXPENSES_ID:
        buttonProps = {
          className:
            !currentUser?.hasPermission?.("expense", "can_write") && "hidden",
          onClick: () => {
            modalDispatch({
              type: ADD_OPEN_MODAL,
              ref: { id: uuidv4() },
              modalType: EXPENSE_CREATE_MODAL,
              modalData: {
                viewMode: "create",
                associationLock: `Property/${propertyId}`,
              },
            });
          },
          addButton: true,
          resourceName: "Expense",
          disabled: modals.find(
            (item) => item.modalType === EXPENSE_CREATE_MODAL
          ),
        };
        break;

      case PROPERTY_TABS.FINANCES_ID: {
        if (currentUser?.hasPermission?.("property", "can_update_financials")) {
          setIsEditableTab(true);
        }

        break;
      }
      case PROPERTY_TABS.WORKFLOWS_ID: {
        buttonProps = {
          className:
            !currentUser?.hasPermission?.("workflow", "can_create") && "hidden",
          onClick: () => {
            modalDispatch({
              type: ADD_OPEN_MODAL,
              ref: { id: uuidv4() },
              modalType: WORKFLOW_CREATE_MODAL,
              modalData: {
                viewMode: "create",
                associationLock: `Property/${propertyId}`,
              },
            });
          },
          addButton: true,
          resourceName: "Workflow",
          disabled: modals.find(
            (item) => item.modalType === WORKFLOW_CREATE_MODAL
          ),
        };
        break;
      }
      case PROPERTY_TABS.REPORTS_ID: {
        buttonProps = {
          onClick: () => {
            modalDispatch({
              type: ADD_OPEN_MODAL,
              ref: { id: uuidv4() },
              modalData: {
                viewMode: "create",
                association: `Property/${propertyId}`,
              },
              modalType: REPORT_CREATE_MODAL,
            });
          },
          className:
            !currentUser?.hasPermission?.("report", "can_write") && "hidden",
          resourceName: "Report",
          addButton: true,
          disabled: modals.find(
            (item) => item.modalType === REPORT_CREATE_MODAL
          ),
        };
        break;
      }
      default:
        buttonProps = {
          dropdownItems: [buttonActions.find((opt) => !opt.tabAction)],
          title: "Actions",
          className: "dropdown-btn",
          large: true,
        };
    }

    // buttonProps is {} if current user does not have permissions
    // for actions performed by the button. if so do not display button
    return !isEmpty(buttonProps) ? <PrimaryButton {...buttonProps} /> : null;
  }, [
    activeTab,
    associationLock,
    buttonActions,
    currentUser,
    filterButtonActions,
    modalDispatch,
    modals,
    property,
    propertyId,
    hideActionsDropdown,
  ]);

  const onFinishEditing = useCallback(async () => {
    setIsSaving(true);

    // Update Property Finances
    if (isFinanceTab) {
      try {
        await handleEditFinance();
      } catch (err) {
        toastError(
          "Failed to update property finances",
          toastErrorIcon,
          toastCloseIcon
        );
      } finally {
        setIsSaving(false);
      }
    }

    // Update Property Details
    else if (isDetailsTab) {
      const newEditedProperty = await uploadAvatar({
        tmpAvatarImg,
        resourceState: editedProperty,
        postFiles,
      });

      const finishedProperty = {
        ...newEditedProperty,
        ownedBy: formatOwnedByForm(newEditedProperty),
        tags:
          newEditedProperty?.currentTags?.map((tag) => tag?.value) ||
          newEditedProperty?.tags,
      };

      // remove unused properties
      delete finishedProperty.brokerRef;
      delete finishedProperty.inspectorRef;

      try {
        await editProperty({
          originalItem: property,
          editedItem: finishedProperty,
        });

        toastMessage(
          `Successfully updated Property`,
          toastIcon,
          toastCloseIcon
        );
      } catch (error) {
        toastError("Failed to update property", toastErrorIcon, toastCloseIcon);
      } finally {
        setIsSaving(false);
      }
    }
  }, [
    editedProperty,
    property,
    editProperty,
    postFiles,
    tmpAvatarImg,
    isFinanceTab,
    isDetailsTab,
    handleEditFinance,
  ]);

  /**
   * Reset state data
   */
  const resetFormStates = useCallback(() => {
    if (isFinanceTab) {
      resetFinanceState();
    } else {
      resetPropertyState();
    }
  }, [isFinanceTab, resetFinanceState, resetPropertyState]);

  // required fields validation
  const requiredFieldsFilled =
    Boolean(editedProperty?.title) &&
    Boolean(editedProperty?.timezone) &&
    Boolean(editedProperty?.propertyType);

  // validation for unique name
  const titleIsUnique =
    property?.title?.toLowerCase() === editedProperty?.title?.toLowerCase()
      ? true
      : !titleExists;

  const disableSaveBtn =
    !editing || titleIsUnique
      ? !isPropertyEdited || !requiredFieldsFilled
      : !titleIsUnique;

  return (
    <>
      <SiteHeader
        title={
          <div className="flex items-center">
            <FormAvatar
              isEditing={
                isDetailsTab &&
                currentUser?.hasPermission?.(
                  "administrative",
                  "can_write_property"
                ) &&
                editing
              }
              disabled={
                !currentUser?.hasPermission?.(
                  "administrative",
                  "can_write_property"
                ) || !editing
              }
              image={editedProperty?.primaryImage}
              tmpAvatarImg={tmpAvatarImg}
              setTmpAvatarImg={setTmpAvatarImg}
              resourceName="Property"
            />
            <InlineInput
              width="w-full"
              size="custom4xl"
              value={editedProperty?.title}
              editing={isDetailsTab && editing}
              loading={isLoading || isSaving}
              disabled={!editing}
              fontWeight="bold"
              color="gray-650"
              onConfirm={handleChangeTitle}
              onChangeCallback={handleChangeTitle}
              hidePencil
              isHeaderTitle
              autoFocus
              validation={
                editing &&
                yup
                  .string()
                  .trim()
                  .required("Title cannot be blank.")
                  .test(
                    "",
                    "Title must be unique",
                    () => !(editing && !titleIsUnique)
                  )
              }
            />
          </div>
        }
        buttons={ActionButton}
      />

      <RealTimeInactivityAlert
        isEditing={editing}
        inactivityTimeInSeconds={60}
        onInactivity={() => {
          resetFormStates();
          handleEditClick();
        }}
      />

      <RealTimeDataBar
        isEditing={editing}
        realtimeUsers={realtimeUsers}
        lockData={getLockInfo(activeTab)}
      />

      <WidgetContainer
        className="p-4 border-gray-200 shadow-lg border rounded-md"
        style={{ minWidth: "903px" }}
        isEditing={editing}
        handleEditClick={isEditableTab && handleEditClick}
        onFinishEditing={onFinishEditing}
        tabs={tabs?.tabs}
        loading={isLoading || isSaving}
        disableEditing={disableEditing || isTabLocked(activeTab)}
        resetResourceState={resetFormStates}
        activeTab={activeTab}
        onTabClick={(t) => setActiveTab(t)}
        disableSaveBtn={disableSaveBtn && isDetailsTab}
        hideBottomCancelSaveButtons
        lockState={lockState}
      />
      <PropertyDeleteModal
        property={property}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
      />
    </>
  );
};

PropertyView.propTypes = {
  propertyId: PropTypes.string,
};

PropertyView.defaultProps = {
  propertyId: undefined,
};

export default PropertyView;

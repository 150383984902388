import { SpaceAPI } from "@griffingroupglobal/eslib-api";

const editSpaceHandler = async (props) => {
  const { originalItem, editedItem } = props;
  const { id } = originalItem;

  const response = await SpaceAPI.patch(id, editedItem, originalItem);
  return response.data;
};

export default editSpaceHandler;

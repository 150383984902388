/**
 * @param {String} key of Address Object, `"attentionTo"` `"street1"` etc
 * @param {Object} address current Address{}
 * @returns Current Value to display in form
 * @ `attentionTo` field needs to stay a `String`
 * @ other fields rely on `Null` to check for validation purposes
 * @ Billing Addresses do not contain `attentionTo`
 */
const getAddressValueFromKey = ({ key, address }) => {
  if (key === "attentionTo") {
    return address?.[key] ? address?.[key] : "";
  }
  return address?.[key] ? address?.[key] : null;
};

export default getAddressValueFromKey;

import { useState, useMemo } from "react";
import { REQUEST_PRIORITIES } from "../../../../../constants";
import { getTimeZoneAbbreviation } from "../../../../../helpers/Time";
import { useAssetsOverview } from "../../../../../hooks/assets";
import { useProjectsOverview } from "../../../../../hooks/projects";
import { usePropertiesOverview } from "../../../../../hooks/properties";
import { useRsvp } from "../../../../../hooks/rsvp";
import FormatedPriorityLevel from "../../../DropDownWithPriorityLevel/FormatedPriorityLevel";
import { useSingleSpace } from "../../../../../hooks/api/spaces";

const useTaskGeneralInfoData = ({ currentTask, timeForm, spaceId }) => {
  const { data: rsvpData } = useRsvp(
    currentTask?.reference,
    currentTask?.instanceStartDate
  );
  const { assetsDict } = useAssetsOverview();
  const { propertiesDict } = usePropertiesOverview();
  const { projectDict } = useProjectsOverview();

  // state for disabling editing recurrence if task related to WF or SR
  const [disableEditRecurrence, setDisableEditRecurrence] = useState(false);

  const { space } = useSingleSpace(spaceId);

  // priority DD options
  const priorityOptions = REQUEST_PRIORITIES.map(({ value }) => ({
    value,
    label: FormatedPriorityLevel(value),
  }));

  // get abbreviated timezone i.e. CST, PST...
  const myTimeZone = getTimeZoneAbbreviation(timeForm);

  const durationValue = useMemo(() => {
    // if the task is not done, return TBD
    if (!currentTask.status !== "done") return "TBD";
    // if the task is done, return the duration
    return currentTask.duration?.value?.toString();
  }, [currentTask.duration?.value, currentTask.status]);

  const association = useMemo(() => {
    const currentAssociation = currentTask?.association;
    let name;
    let type;
    const [resource] = (currentAssociation ?? "")?.split("/");

    switch (resource) {
      case "Property":
        name = propertiesDict?.[currentAssociation]?.title;
        type = "Property";
        break;
      case "Project":
        name = projectDict?.[currentAssociation]?.name;
        type = "Project";
        break;
      case "Asset":
        name = assetsDict?.[currentAssociation]?.name;
        type = "Asset";
        break;
      default:
        return null;
    }
    if (spaceId) {
      name = space?.name;
    }
    return { association: name, type };
  }, [
    currentTask?.association,
    spaceId,
    propertiesDict,
    projectDict,
    assetsDict,
    space?.name,
  ]);

  return {
    setDisableEditRecurrence,
    disableEditRecurrence,
    association: association?.association,
    type: association?.type,
    priorityOptions,
    durationValue,
    myTimeZone,
    rsvpData,
  };
};

export default useTaskGeneralInfoData;

import { ReportAPI } from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import { reportsKeys } from "../config/reactQuery/queryKeyFactory";
import { useAppState } from "../state/appState";
import { toastMessage, toastError } from "../stories/Components/Toast/Toast";

/**
 * Post Report in backend
 * @returns report response from backend
 */
const postReport = async (report) => {
  const { data } = await ReportAPI.post(report);
  return data;
};

/**
 * Mutation hook to post new reports
 * @param {string} mutationKey (?) mutation key to track this task
 */
const useReportPost = (mutationKey) => {
  const [{ editingResource }] = useAppState();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey,
    mutationFn: postReport,
    onSuccess: (data) => {
      const messageAndLinkProps = {
        resource: data,
        editingResource,
      };
      toastMessage(undefined, messageAndLinkProps);
    },
    onError: (error) => {
      console.error("useReportPost", error);
      toastError("Report could not be created. Please try again");
    },
    onSettled: () => {
      queryClient.invalidateQueries(reportsKeys.reports);
    },
  });
};

export default useReportPost;

import React from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import Dropdown from "../Dropdown/Dropdown";
import useAssociationDropdownData from "./useAssociationDropdownData";

// Functional component that renders a dropdown with Assets/Properties/Projects
const AssociationDropdown = ({
  association,
  disabled,
  multiple,
  placeholder,
  onChangeAssociation,
  isRequired,
  disableClear,
  disabledOptions,
  initialValue,
  setInitialValue,
}) => {
  // Presenter hook
  const { options, value, handleChangeAssociation } =
    useAssociationDropdownData(
      association,
      onChangeAssociation,
      disabledOptions,
      setInitialValue
    );

  return (
    <Dropdown
      placeholder={placeholder}
      options={options}
      value={value ?? initialValue}
      onChange={handleChangeAssociation}
      noLabel
      disableErrorMessage
      isDisabled={disabled}
      isMulti={multiple}
      validation={isRequired && yup.mixed().required()}
      disableClear={disableClear}
    />
  );
};

AssociationDropdown.defaultProps = {
  association: undefined,
  placeholder: "Select",
  disabled: false,
  multiple: false,
  onChangeAssociation: undefined,
  isRequired: false,
  disableClear: false,
  disabledOptions: [],
  initialValue: {},
  setInitialValue: () => {},
};

AssociationDropdown.propTypes = {
  association: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  multiple: PropTypes.bool,
  onChangeAssociation: PropTypes.func,
  isRequired: PropTypes.bool,
  disableClear: PropTypes.bool,
  /**
   * This allows component to disable display of any combination of options
   * by passing an array with desired disabled values
   * i.e. ["Assets", "Properties"] will only display a dropdown with Projects
   */
  disabledOptions: PropTypes.arrayOf(PropTypes.string),
  initialValue: PropTypes.shape({}),
  setInitialValue: PropTypes.func,
};

export default AssociationDropdown;

import { RealTimeEvent } from "@griffingroupglobal/eslib-ably";
import {
  useAddProperty,
  useDeleteProperty,
  useEditProperty,
} from "../api/properties";

const useHandlePropertyRealTime = () => {
  const { addProperty } = useAddProperty();
  const { deleteProperty } = useDeleteProperty();
  const { editPropertyRtd } = useEditProperty();

  /**
   * Handles realtime property events from Ably and updates the property list accordingly
   * @param {object} message - the realtime message received from Ably
   * @param {string} message.name - the event name indicating the type of update
   * @param {object} message.data - the payload containing event-specific data
   * @param {object} message.data.changes - the added/updated property data
   * @param {string} message.data.target - the property's reference
   */
  const handleProperty = (message) => {
    const event = message.name;
    const { target, changes } = message.data;

    const id = target.split("/")[1];

    if (event === RealTimeEvent.ResourceAdded) {
      addProperty(changes, false);
      return;
    }

    if (event === RealTimeEvent.ResourceUpdated) {
      editPropertyRtd(id, changes);
      return;
    }

    if (event === RealTimeEvent.ResourceRemoved) {
      deleteProperty(id, false);
    }
  };

  return { handleProperty };
};

export default useHandlePropertyRealTime;

import { useHistory } from "react-router";
import { useSingleTask } from "../../../../hooks/api/tasks";

const useParentTask = ({ parentTaskRef }) => {
  const history = useHistory();
  const parentTaskId = parentTaskRef?.split?.("/")?.[1];

  const { singleTask } = useSingleTask(parentTaskId);

  const parentTaskName = singleTask?.name;

  const onClick = () => {
    const instanceStartDate = singleTask?.instanceStartDate;

    const taskPath = instanceStartDate
      ? `/tasks/${singleTask?.id}?instanceStartDate=${instanceStartDate}`
      : `/tasks/${singleTask?.id}`;

    history.push(taskPath);
  };

  return { parentTaskName, onClick };
};

export default useParentTask;

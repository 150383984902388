import React from "react";
import { useHistory } from "react-router";
import { ADMIN_PATH } from "../../../../constants";
import Warning from "../Warning";

const UserSeatsWarning = () => {
  const history = useHistory();

  return (
    <Warning
      prompt={
        <span
          style={{ width: "426px" }}
          className="flex flex-row text-es-dark-grey text-es-normal font-es-medium tracking-es-wide whitespace-pre pl-3 flex-wrap"
        >
          <p className="text-wrap">
            {`You reached the max amount of seats. Go to\n`}
            <button
              type="button"
              className="underline tracking-es-wide font-es-medium"
              onClick={() => history.push(`${ADMIN_PATH}?tab=billing`)}
            >
              Billing Management
            </button>
            {` to add more.`}
          </p>
        </span>
      }
    />
  );
};

UserSeatsWarning.propTypes = {};

export default UserSeatsWarning;

import { useParams } from "react-router";

const usePropertySinglePageData = () => {
  const { propertyId } = useParams();

  const reference = `Property/${propertyId}`;

  return { propertyId, reference };
};

export default usePropertySinglePageData;

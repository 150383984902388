import { useCallback } from "react";

const useRateCellData = ({
  row,
  unitsOfMeasure,
  rateSheetRates,
  editedRow,
  setEditedRow,
}) => {
  const displayValue = rateSheetRates?.find(
    (rate) => rate.value === row?.original?.rate
  )?.label;

  const getDropdownValue = useCallback(
    (val) => {
      let rv = {};
      rv = rateSheetRates.find((rate) => rate.value === val);

      return rv;
    },
    [rateSheetRates]
  );

  const onChange = (newVal) => {
    const uOM = unitsOfMeasure.find((u) => u.label === "Hour (HR)")?.value;

    setEditedRow({
      ...editedRow,
      rate: newVal?.value,
      unitOfMeasure: uOM,
      costPerUnit: newVal?.ratePerHr,
      highRange: {
        ...editedRow?.highRange,
        rate: editedRow?.isCostRange ? newVal?.value : undefined,
        unitOfMeasure: editedRow?.isCostRange ? uOM : undefined,
        costPerUnit: editedRow?.isCostRange ? newVal?.ratePerHr : undefined,
      },
      vendor: newVal?.value ? undefined : editedRow.vendor,
    });
  };

  return {
    displayValue,
    getDropdownValue,
    onChange,
  };
};

export default useRateCellData;

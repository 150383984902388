import spacesKeys from "../../hooks/api/spaces/spacesKeys";
import sortByProperty from "../Utilities/sortByProperty";

/**
 * Add new space to the space list cache store
 * @param {Object} params
 * @param {QueryClient} params.queryClient - query client object
 * @param {Object} params.space - new s[ace] created
 */
const addToSpaceListStore = ({ queryClient, space }) => {
  const queryKey = spacesKeys.all;

  const spacesCache = queryClient.getQueryData(queryKey);

  // Spaces list cache hasn't been initialized, we don't need to create a cache manually
  if (!spacesCache) {
    return;
  }

  // Check if Space already exists in the cache.
  const foundSpace = spacesCache.find((existing) => existing.id === space.id);

  if (foundSpace) {
    return;
  }

  queryClient.setQueryData(queryKey, (oldData) => {
    const newSpacesArr = [space, ...oldData];
    return sortByProperty(newSpacesArr, "name");
  });
};

export default addToSpaceListStore;

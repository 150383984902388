import React, { useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import {
  FILTER_TYPES,
  PROPERTY,
  SHOW_COLUMNS_SETTING_RIGHT,
  TABLE_COLUMN_WIDTH,
} from "../../../constants";
import TableActionsIconsGroup from "../Table/TableActionsIconsGroup";
import PropertyDeleteModal from "../../../Pages/Overviews/Property/PropertyDeleteModal";
import usePropertyTableData from "./usePropertyTableData";
import Table from "../Table/Table";
import aggregateTableRow from "../../../helpers/Table";
import TagList from "../Tags/TagList";
import FavoriteButton from "../Buttons/FavoriteButton";
import CardGrid from "../CardGrid/CardGrid";
import Widget from "../Widget/Widget";
import { hasDeleteAdministrativePermission } from "../../../helpers/Permissions";
import ImageCell from "../Table/ImageCell";

const PropertyTable = ({ onRowClick }) => {
  const {
    properties,
    templateSettings,
    isShowingPropertiesTable,
    selectedRows,
    showDeleteModal,
    isLoading,
    currentUser,
    handleFavoriteClick,
    handleRowSelect,
    updateUserTemplateSettings,
    deleteUserTemplateSettings,
    setShowDeleteModal,
  } = usePropertyTableData();

  const groupOptions = {
    // ordered list of grouped column options
    hierarchicalOptions: [],
    // un-ordered list of grouped column options
    nonHierarchicalOptions: [
      {
        label: "Type",
        value: "propertyType",
      },
      {
        label: "Tags",
        value: "tags",
      },
      {
        label: "Owned By",
        value: "ownedBy",
      },
    ],
  };

  const tableColumns = useMemo(() => {
    return [
      {
        id: "favorited",
        Header: "",
        editColumnModalHeader: "Favorite",
        isPrimary: true,
        accessor: "isFavorited",
        filterOptions: {
          filterType: FILTER_TYPES.boolean,
          label: "Favorites",
        },
        width: 20,
        minWidth: 90,
        maxWidth: 100,
        Cell: ({ row }) => {
          return (
            <FavoriteButton
              isSelected={row?.original?.isFavorited}
              onClick={() => {
                handleFavoriteClick(row?.original?.id);
              }}
            />
          );
        },
        Aggregated: () => null,
      },
      {
        Header: "Image",
        accessor: "image",
        Cell: ({ value }) => (
          <ImageCell src={value} circled resourceType="Property" />
        ),
        Edit: ({ value }) => (
          <ImageCell src={value} circled resourceType="Property" />
        ),
        width: TABLE_COLUMN_WIDTH,
        minWidth: 90,
        maxWidth: TABLE_COLUMN_WIDTH,
        isPrimary: true,
        disableSortBy: true,
        disableResizing: true,
        disableFilters: true,
        Aggregated: () => null,
      },
      {
        Header: "Name",
        accessor: "title",
        isPrimary: true,
      },
      {
        Header: "Address",
        accessor: "address",
        Edit: ({ value }) => value ?? "",
        Cell: ({ value }) => <p>{value?.[0]?.street}</p>,
      },
      {
        Header: "Type",
        accessor: "propertyType",
        Cell: ({ value }) => <p>{value}</p>,
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Owned By",
        accessor: "ownedBy",
        filterOptions: {
          filterType: FILTER_TYPES.isOrNot,
        },
      },
      {
        Header: "Acreage",
        accessor: "lotSize.value",
        aggregate: "sum",
        Aggregated: ({ row }) => {
          return <p>{aggregateTableRow(row, "lotSize.value").toFixed(2)}</p>;
        },
      },
      {
        Header: "SF",
        accessor: "sf",
        aggregate: "sum",
        Aggregated: ({ row }) => {
          return <p>{aggregateTableRow(row, "sf").toFixed(2)}</p>;
        },
      },
      {
        Header: "# of Bedrooms",
        accessor: "bedrooms",
        width: 200,
        aggregate: "sum",
      },
      {
        Header: "# of Bathrooms",
        accessor: "bathrooms",
        width: 200,
        aggregate: "sum",
      },
      {
        Header: "Investment Prop",
        accessor: "isInvestment",
        Cell: ({ value }) => <p>{value ? "Yes" : "No"}</p>,
        width: 200,
        Aggregated: () => null,
      },
      {
        Header: "Tags",
        accessor: "tags",
        Cell: ({ row }) => {
          return (
            <TagList
              tags={row?.original?.currentTags}
              tagClassName="py-1 px-2"
              className="gap-2"
              showCount
            />
          );
        },
        minWidth: 200,
        filterOptions: {
          label: "Tags",
          filterType: FILTER_TYPES.tags,
        },
      },
      {
        Header: "Date Modified",
        accessor: "lastUpdated",
        Cell: ({ value }) => (
          <div className="flex">
            <p>{moment(value).format("MMMM Do YYYY, h:mm a")}</p>
          </div>
        ),
        width: 350,
        Aggregated: () => null,
      },
      {
        Header: "Purchase Date",
        accessor: "purchase.date",
        Cell: ({ value }) => (
          <div className="flex">
            <p>{moment(value).format("MMMM Do YYYY, h:mm a")}</p>
          </div>
        ),
        width: 350,
        Aggregated: () => null,
        show: false,
      },
      {
        Header: "Purchase Price",
        accessor: "purchase.price",
        width: 200,
        show: false,
        aggregate: "sum",
        Aggregated: ({ row }) => {
          return <p>{aggregateTableRow(row, "purchase.price").toFixed(2)}</p>;
        },
      },
      {
        Header: "Interest Rate",
        accessor: "interestRate",
        show: false,
      },
      {
        Header: "Mortgage",
        accessor: "mortgage",
        show: false,
        aggregate: "sum",
        Aggregated: ({ row }) => {
          return <p>{aggregateTableRow(row, "mortgage").toFixed(2)}</p>;
        },
      },
    ];
  }, [handleFavoriteClick]);

  return (
    <Widget
      draggable={false}
      title={null}
      overflow
      loading={isLoading}
      backToTop
    >
      <Table
        handleRowSelect={handleRowSelect}
        allowSelection
        data={properties}
        columns={tableColumns}
        groupOptions={groupOptions}
        resourceName="property"
        onRowClick={({ index }) => onRowClick(properties[index]?.id)}
        templateSettings={templateSettings}
        updateUserTemplateSettings={updateUserTemplateSettings}
        deleteUserTemplateSettings={deleteUserTemplateSettings}
        showColumnSettingsLeft={!SHOW_COLUMNS_SETTING_RIGHT}
        onSelectedRowChange={handleRowSelect}
        hideSiteHeaderTitle
        showConstantRowIndex
        hideSiteHeaderActionButtons
        showReportButton
        cardGrid={!isShowingPropertiesTable}
        cardGridComponent={(rows) => {
          return (
            <CardGrid
              resourceName="Property"
              rows={rows}
              onClick={onRowClick}
            />
          );
        }}
        fileActionsIcons={
          <TableActionsIconsGroup
            style={{ height: "100%" }}
            handleShowDelete={() => {
              setShowDeleteModal(true);
            }}
            canDelete={
              isShowingPropertiesTable &&
              hasDeleteAdministrativePermission(PROPERTY, currentUser)
            }
            disabled={selectedRows?.length === 0}
            showColumnSettingsRight={
              SHOW_COLUMNS_SETTING_RIGHT && isShowingPropertiesTable
            }
            selectedRows={selectedRows}
            disabledColumnSettings={!isShowingPropertiesTable}
          />
        }
      />

      <PropertyDeleteModal
        property={selectedRows[0]}
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
      />
    </Widget>
  );
};

PropertyTable.propTypes = {
  /**
   * function called when a row is clicked
   */
  onRowClick: PropTypes.func,
};

PropertyTable.defaultProps = {
  onRowClick: undefined,
};

export default PropertyTable;

import deleteAssociationSpacesListStore from "../../../../helpers/Spaces/deleteAssociationSpacesListStore";
import deleteSingleSpaceStore from "../../../../helpers/Spaces/deleteSingleSpaceStore";
import deleteSpaceListStore from "../../../../helpers/Spaces/deleteSpaceListStore";

const deleteSpaceResponder = (queryClient) => {
  return {
    onSuccess: (returnedData, { id, association }) => {
      deleteSpaceListStore({ queryClient, id });
      deleteAssociationSpacesListStore({ queryClient, id, association });
      deleteSingleSpaceStore({ queryClient, id });
    },
    onError: (err) => {
      console.error("Failed to delete space", err);
    },
  };
};

export default deleteSpaceResponder;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import * as yup from "yup";
import { v4 as uuidv4 } from "uuid";
import Checkbox from "../Checkbox/Checkbox";
import CrossButton from "../CrossButton/CrossButton";
import Input from "../Input/Input";
import TableFilterComponent from "../Table/TableFilterComponent";
import TableGroupComponent from "../Table/TableGroupComponent";
import Modal from "../Modal/Modal";
import PrimaryButton from "../Buttons/PrimaryButton";
import TertiaryButton from "../Buttons/TertiaryButton";
import useAddViewAndFilterPopoverData from "./useAddViewAndFilterPopoverData";
import greyCrossIcon from "../../assets/images/crossDarkGrey.svg";

const container = (containerClassName) => cntl`
  flex
  absolute
  top-0
  flex-col
  w-fit
  border
  border-es-light-grey
  rounded-lg
  h-fit
  px-6
  pt-4
  pb-6
  z-20
  bg-es-white
  shadow-light-lift
  ${containerClassName}
`;

/**
 * Filter to show columns different than marked below
 */
const columnFilter = (column) =>
  column.id !== "selection" &&
  column.Header !== "#" &&
  column.Header !== "Flag" &&
  column.id !== "actions" &&
  column.id !== "favorited" &&
  column.accessor !== "_lineNumber";

/**
 * This component shows a modal with filter, group, and columns options to save custom templates
 */
const AddViewAndFilterPopover = ({
  isOpen,
  containerClassName,
  onRequestModalClose,
  allColumns,
  groupOptions,
  groups,
  filters,
  tableData,
  updateUserTemplateSettings,
  templateSettings,
  selectedTemplateSetting,
  applyTemplate,
  deleteTemplate,
  userSettings,
  updateSettings,
  resourceName,
  isQuickView,
}) => {
  const {
    selectedColumns,
    disableSaveButton,
    templateName,
    handleSelectedColumns,
    updateConfiguration,
    setTemplateName,
  } = useAddViewAndFilterPopoverData({
    isOpen,
    resourceName,
    userSettings,
    updateSettings,
    allColumns,
    isQuickView,
  });
  const [stagedFilters, setStagedFilters] = useState(filters);
  const [stagedGroups, setStagedGroups] = useState(groups);
  const [showDelete, setShowDelete] = useState(false);

  const originalColumnsState = useMemo(
    () => allColumns.map((a) => ({ ...a })),
    [allColumns]
  );

  /**
   * Apply filter for all possible columns
   */
  const columns = useMemo(() => allColumns.filter(columnFilter), [allColumns]);

  /**
   * Initialize template state variables if user is editing an existing template view
   */
  useEffect(() => {
    if (selectedTemplateSetting) {
      setStagedGroups(selectedTemplateSetting?.groups || []);
      setStagedFilters(selectedTemplateSetting?.filters || []);
      setTemplateName(selectedTemplateSetting?.name);
    }
  }, [selectedTemplateSetting, setTemplateName]);

  /**
   * On checkbox change toggle visibilty and
   * call handleStoreColumns to save checked columns
   * @param {Event} event
   * @param {Object} column
   * @param {Function} changeVisibilty
   */
  const handleColumnVisibilityChange = (event, column, changeVisibilty) => {
    changeVisibilty().onChange(event);
    handleSelectedColumns(event, column);
  };

  /**
   * Add or patch and apply templates
   */
  const saveTemplate = useCallback(async () => {
    const userTemplateSettings = templateSettings.filter(
      (temp) => !temp.isAdmin
    );
    const found = userTemplateSettings.find(
      (view) => view.id === selectedTemplateSetting?.id
    );
    let updatedViews = [];
    let newTemplate;

    // Filter out checked columns from `allColumns` and transformed the array to the template data structure
    const filteredColumns = selectedColumns.map((column) => ({
      label: column.Header,
      value: column.id,
    }));

    if (found) {
      updatedViews = userTemplateSettings.map((view) => {
        if (view.id === selectedTemplateSetting.id) {
          newTemplate = {
            ...view,
            name: templateName,
            filters: stagedFilters,
            groups: stagedGroups,
            columns: filteredColumns,
          };
          return newTemplate;
        }
        return view;
      });
    } else {
      newTemplate = {
        id: uuidv4(),
        name: templateName,
        filters: stagedFilters,
        groups: stagedGroups,
        columns: filteredColumns,
      };
      updatedViews = [...userTemplateSettings, newTemplate];
    }

    updateUserTemplateSettings(updatedViews);
    applyTemplate(newTemplate);
  }, [
    selectedColumns,
    selectedTemplateSetting,
    applyTemplate,
    stagedFilters,
    stagedGroups,
    templateName,
    templateSettings,
    updateUserTemplateSettings,
  ]);

  /**
   * Handle template view deletion and close modal
   */
  const onConfirmDelete = () => {
    setShowDelete(false);
    deleteTemplate(selectedTemplateSetting);
    onRequestModalClose();
  };

  const restoreTableState = (cols) => {
    originalColumnsState.forEach((oCol) => {
      cols.forEach((col) => {
        if (oCol.Header === col.Header && oCol.isVisible !== col.isVisible) {
          col.toggleHidden(!oCol.isVisible && col.isVisible);
        }
      });
    });
  };

  const handleSave = () => {
    if (isQuickView) {
      saveTemplate();
    } else {
      updateConfiguration();
    }

    // Close popup
    onRequestModalClose();
  };

  return (
    <>
      {isOpen && (
        <div
          className={container(containerClassName)}
          style={{
            width: "640px",
            maxHeight: isQuickView && "650px", // Use Max Height to allow the larger quick view popup room, and min h fit for smaller edit column popup
          }}
        >
          <div className="flex justify-end">
            <div className="flex">
              <CrossButton
                onClick={() => {
                  onRequestModalClose();
                  restoreTableState(allColumns);
                }}
                className="px-2 py-2"
                icon={greyCrossIcon}
                style={{ height: "0.75rem", width: "0.75rem" }}
              />
            </div>
          </div>
          <div
            style={{
              maxHeight: isQuickView && "600px",
              overflowY: "scroll",
            }}
          >
            <div className="flex flex-col">
              <div
                className={`flex flex-col gap-2 ${!isQuickView && "hidden"}`}
              >
                <div>
                  <p className="font-semibold text-black text-sm pb-2">
                    Filter Name
                  </p>

                  <Input
                    placeholder="Name"
                    onChange={setTemplateName}
                    value={templateName}
                    inputClassName="bg-green-50 pl-4 pr-8 overflow-hidden w-full"
                    inputContainerClassName="bg-green-50"
                    autoFocus
                    validation={yup.string().required()}
                    name="filterName"
                    showValidationErrorAtBottom
                  />
                </div>

                <div className="w-full pt-2">
                  <TableFilterComponent
                    allColumns={allColumns}
                    stagedFilters={stagedFilters}
                    setStagedFilters={setStagedFilters}
                    tableData={tableData}
                  />
                </div>

                <div className="w-full">
                  <TableGroupComponent
                    stagedGroups={stagedGroups}
                    setStagedGroups={setStagedGroups}
                    groupOptions={groupOptions}
                  />
                </div>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <p className="col-span-3 font-es-semibold text-es-dark-grey text-es-normal pt-2 pb-2">
                  Include Columns
                </p>

                {columns.map((column) => (
                  <div key={column.id} className="col-span-1 overflow-auto">
                    <Checkbox
                      label={column.Header}
                      labelClassName="text-gray-300"
                      onChangeSendEvent
                      onChange={(e) =>
                        handleColumnVisibilityChange(
                          e,
                          column,
                          column.getToggleHiddenProps
                        )
                      }
                      checked={column.getToggleHiddenProps().checked}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-row pt-6">
            <PrimaryButton
              saveButton
              disabled={disableSaveButton}
              onClick={handleSave}
            />
            {isQuickView && selectedTemplateSetting && (
              <TertiaryButton
                trashCan
                className="hover:bg-white"
                onClick={() => setShowDelete(true)}
              />
            )}
          </div>
        </div>
      )}

      {showDelete && (
        <Modal
          isOpen={showDelete}
          title="Delete?"
          hideFooter
          alert
          onRequestModalClose={() => setShowDelete(false)}
          primaryButtonTitle="Remove"
          primaryButtonOnClick={onConfirmDelete}
          tertiaryButtonTitle="Cancel"
        >
          <div className="flex flex-col">
            <p>Are you sure you want to delete this template?</p>
          </div>
        </Modal>
      )}
    </>
  );
};

AddViewAndFilterPopover.propTypes = {
  /**
   * if true, displays the modal
   */
  isOpen: PropTypes.bool,
  /**
   * all of the possible columns to select
   */
  allColumns: PropTypes.arrayOf(
    PropTypes.shape({
      /**
       * the id of the column, passed from react-table
       */
      id: PropTypes.string,
      /**
       * the name of the column, passed from react-table
       */
      Header: PropTypes.string,
      /**
       * function passed from react-table to allow the checkbox to toggle a single column's hidden state
       */
      getToggleHiddenProps: PropTypes.func.isRequired,
      /**
       * if true, the item is showing on the datatable
       */
      isVisible: PropTypes.bool,
      toggleHidden: PropTypes.func,
    })
  ),
  /**
   * function calls when the modal requests to close, function should toggle the isOpen prop to false
   */
  onRequestModalClose: PropTypes.func,
  templateSettings: PropTypes.arrayOf(PropTypes.shape({})),
  containerClassName: PropTypes.shape({}),
  isQuickView: PropTypes.bool,
  filters: PropTypes.shape({
    id: PropTypes.string,
    value: PropTypes.shape({
      isTrue: PropTypes.bool,
      value: PropTypes.string,
      type: PropTypes.string,
      from: PropTypes.string,
      to: PropTypes.string,
      values: PropTypes.arrayOf(PropTypes.string),
    }),
  }),
  selectedTemplateSetting: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    filters: PropTypes.shape({
      id: PropTypes.string,
      value: PropTypes.shape({
        isTrue: PropTypes.bool,
        value: PropTypes.string,
        type: PropTypes.string,
        from: PropTypes.string,
        to: PropTypes.string,
        values: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
    groups: PropTypes.arrayOf(PropTypes.string),
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  groupOptions: PropTypes.shape({
    hierarchicalOptions: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        })
      )
    ),
    nonHierarchicalOptions: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      })
    ),
  }),
  tableData: PropTypes.arrayOf(
    PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number])
    )
  ),
  updateUserTemplateSettings: PropTypes.func,
  applyTemplate: PropTypes.func,
  deleteTemplate: PropTypes.func,
  /**
   * Selected Template Option
   */
  selectedTemplateOption: PropTypes.shape({}),
  /**
   * Updates Template Configuration Settings
   */
  updateSettings: PropTypes.func,
  /**
   * User Column configuration
   */
  userSettings: PropTypes.shape({}),
  /**
   * Name of resource for template column configuration store.
   */
  resourceName: PropTypes.string,
};

AddViewAndFilterPopover.defaultProps = {
  isOpen: false,
  allColumns: [],
  onRequestModalClose: undefined,
  templateSettings: [],
  selectedTemplateSetting: undefined,
  tableData: [],
  filters: [],
  groups: [],
  groupOptions: undefined,
  updateUserTemplateSettings: undefined,
  applyTemplate: undefined,
  deleteTemplate: undefined,
  containerClassName: undefined,
  isQuickView: false,
  selectedTemplateOption: undefined,
  updateSettings: () => {},
  userSettings: undefined,
  resourceName: undefined,
};

export default AddViewAndFilterPopover;

import { useCallback } from "react";

const useCostPerCellData = ({ isHighRangeField, setEditedRow, editedRow }) => {
  const value = isHighRangeField
    ? editedRow?.highRange?.costPerUnit
    : editedRow?.costPerUnit;

  const onChange = useCallback(
    (newVal) => {
      if (isHighRangeField) {
        if (newVal) {
          const liveBudget = newVal * (editedRow.highRange.quantity || 0);
          setEditedRow({
            ...editedRow,
            highRange: {
              ...editedRow.highRange,
              costPerUnit: newVal,
              liveBudget,
            },
          });
        } else {
          setEditedRow({
            ...editedRow,
            highRange: {
              ...editedRow.highRange,
              costPerUnit: newVal,
            },
          });
        }
      } else if (newVal) {
        const liveBudget = newVal * (editedRow.quantity || 0);
        setEditedRow({
          ...editedRow,
          costPerUnit: newVal,
          liveBudget,
        });
      } else {
        setEditedRow({
          ...editedRow,
          costPerUnit: newVal,
        });
      }
    },
    [editedRow, isHighRangeField, setEditedRow]
  );

  return { value, onChange };
};

export default useCostPerCellData;

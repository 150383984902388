import React from "react";
import crossIcon from "../../../../assets/images/crossIconWhite.svg";
import listIcon from "../../../../assets/images/list.svg";

const closedSize = 44;

const MiniMapHeader = ({ isOpen, setIsOpen }) => {
  return (
    <div
      style={{ height: closedSize, width: isOpen ? 269 : 117 }}
      onClick={() => setIsOpen((prev) => !prev)}
      tabIndex={0}
      onKeyDown={() => {}}
      role="button"
    >
      {isOpen ? (
        <div className="flex justify-between items-center bg-es-dark-green rounded-tl-md w-full">
          <div className="flex py-2.5 px-4">
            <p className="font-es-semibold text-es-sm text-es-white tracking-es-normal">
              Jump To
            </p>
          </div>
          <div className="flex pr-4">
            <img
              src={crossIcon}
              alt="close"
              style={{ height: "0.625rem", width: "0.625rem" }}
            />
          </div>
        </div>
      ) : (
        <div className="flex flex-row items-center bg-es-dark-green rounded-l-md w-full">
          <div className="flex py-2.5 pl-4 pr-2">
            <p className="font-es-semibold text-es-sm text-es-white tracking-es-normal">
              Jump To
            </p>
          </div>
          <div className="flex">
            <img src={listIcon} alt="list" />
          </div>
        </div>
      )}
    </div>
  );
};

export default MiniMapHeader;

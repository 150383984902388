import Api from "@griffingroupglobal/eslib-api";
import { useAppState } from "../state/appState";
import useAuthenticatedInfiniteQuery from "./useAuthenticatedInfiniteQuery";
import taskKeys from "./api/tasks/taskKeys";

const fetchActivity = async (pageParam, association) => {
  const { data } = await Api.get(`/api/${association}/history`, {
    paging: false,
    params: {
      limit: 10,
      page: pageParam,
    },
  });

  return {
    data: data?.entries?.map(({ resource }) => resource) || [],
    page: data.page,
    pages: data.pages,
  };
};

/**
 * This hook fetch the HISTORY for a single task
 */
const useTaskActivity = (taskId) => {
  const [{ currentUser }] = useAppState();

  const queryKey = taskKeys.history(taskId);

  return useAuthenticatedInfiniteQuery({
    queryKey,
    enabled: !!currentUser && !!taskId,
    queryFn: ({ pageParam = 1 }) => fetchActivity(pageParam, `Task/${taskId}`),
    getNextPageParam: (lastPageData) => {
      const nextPage =
        lastPageData.page < lastPageData.pages
          ? lastPageData.page + 1
          : undefined;

      return nextPage;
    },
    onError: (error) => {
      console.error("useTaskActivity", error);
    },
  });
};

export default useTaskActivity;

import React from "react";
import ModalWrapper from "../EsModal/ModalWrapper";
import ServiceRequestForm from "./ServiceRequestForm";
import useServiceRequestModalData from "./useServiceRequestModalData";

const ServiceRequestsModal = ({ modalData }) => {
  const {
    requestForm,
    disableForm,
    disableAssociationDropdown,
    showConfirm,
    lockAssociation,
    parentResource,
    ticketTypeOptions,
    assetOptions,
    associationAsset,
    isAssetLocked,
    handleSelectAsset,
    handleCreateServiceRequest,
    handleChangeForm,
    handleCloseModal,
    handleTags,
    handleAddFiles,
    handleRemoveFile,
    handleChangeAssociation,
  } = useServiceRequestModalData({ modalData });

  return (
    <ModalWrapper
      id="modalRequestId"
      modalData={modalData}
      formData={requestForm}
      onRequestClose={handleCloseModal}
      width="630px"
      primaryButtonTitle="Save"
      title="Create Request"
      resourceName="Service Request"
      primaryButtonOnClick={handleCreateServiceRequest}
      tertiaryButtonTitle="Cancel"
      disabled={disableForm}
      showConfirm={showConfirm}
    >
      <ServiceRequestForm
        requestForm={requestForm}
        disableAssociationDropdown={disableAssociationDropdown}
        lockAssociation={lockAssociation}
        parentResource={parentResource}
        ticketTypeOptions={ticketTypeOptions}
        handleChangeForm={handleChangeForm}
        handleTags={handleTags}
        handleAddFiles={handleAddFiles}
        handleRemoveFile={handleRemoveFile}
        handleChangeAssociation={handleChangeAssociation}
        assetOptions={assetOptions}
        isDisabledAssetDD={isAssetLocked}
        associationAsset={associationAsset}
        handleSelectAsset={handleSelectAsset}
      />
    </ModalWrapper>
  );
};

export default ServiceRequestsModal;

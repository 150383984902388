import { propertyKeys } from "../../hooks/properties";
import sortByProperty from "../Utilities/sortByProperty";

const editPropertyListStore = ({ queryClient, updatedData }) => {
  const queryKey = propertyKeys.all;
  const propertiesArr = queryClient.getQueryData(queryKey) ?? [];

  const index = propertiesArr.findIndex(
    (property) => property.id === updatedData.id
  );

  let newPropertiesArr;

  if (index !== -1) {
    const oldProperty = propertiesArr[index];
    const propertyUpdated = { ...oldProperty, ...updatedData };

    newPropertiesArr = [
      ...propertiesArr.slice(0, index),
      propertyUpdated,
      ...propertiesArr.slice(index + 1),
    ];

    const sortedProperties = sortByProperty(newPropertiesArr, "title");

    queryClient.setQueryData(queryKey, sortedProperties);
  }
};

export default editPropertyListStore;

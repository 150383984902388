/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import addPhotoIcon from "../../assets/images/magnoliaEditIcon.svg";
import assetDefault from "../../assets/images/diamond.svg";
import userDefault from "../../assets/images/userIcon.svg";
import companyDefault from "../../assets/images/companyWhiteIcon.svg";
import projectDefault from "../../assets/images/projectIcon.svg";
import propertyDefault from "../../assets/images/propertyIcon.svg";

import "./Avatar.css";
import { useGetFile } from "../../../hooks/useGetFile";
import { ACCEPTED_PRIMARY_IMAGE_TYPES } from "../../../constants";

/**
 * @param {object} tmpAvatarImg
 * @param {function} setTmpAvatarImg
 * @param {boolean} isEditing
 * @param {string} image
 * @param {string} resourceName
 * @param {boolean} user
 * @param {boolean} company
 * @param {boolean} disabled
 * @param {string} width
 * @param {string} boarderWidth
 */
const FormAvatar = ({
  tmpAvatarImg,
  setTmpAvatarImg,
  image,
  isEditing,
  resourceName,
  user = false,
  company = false,
  disabled,
  width = "85px",
  borderWidth = "0.5",
}) => {
  const { data } = useGetFile(image);
  const avatarInput = useRef();
  const id = uuidv4();

  const placeholder = useMemo(() => {
    if (user) {
      return userDefault;
    }
    if (company) {
      return companyDefault;
    }
    if (resourceName === "Property") {
      return propertyDefault;
    }
    if (resourceName === "Space") {
      return propertyDefault;
    }
    if (resourceName === "Project") {
      return projectDefault;
    }
    if (resourceName === "Asset") {
      return assetDefault;
    }
    return null;
  }, [company, user, resourceName]);

  const addPhoto = useCallback(() => {
    avatarInput.current.click();
  }, []);

  const handleChange = useCallback(
    async (e) => {
      setTmpAvatarImg((prev) => ({ ...prev, isLoading: true }));

      const tempImageFile = e.target.files[0];
      const tempImageURL = URL.createObjectURL(tempImageFile);
      setTmpAvatarImg((prev) => ({
        ...prev,
        image: tempImageURL,
        tempImageFile,
      }));

      setTmpAvatarImg((prev) => ({ ...prev, isLoading: false }));

      // Reset file input to allow the same file to be uploaded again
      e.target.value = "";
    },
    [setTmpAvatarImg]
  );

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      addPhoto();
    }
  };
  return (
    <>
      <svg
        viewBox="0 0 10 10"
        width={width}
        preserveAspectRatio="xMidYMid meet"
        aria-label="avatar-image"
        className={`${disabled ? "cursor-default" : "cursor-pointer"} ${
          !tmpAvatarImg.isLoading ? "avatar-container" : ""
        } rounded-full`}
        style={{ minWidth: "85px" }}
        role="button"
        onClick={() => (disabled ? {} : addPhoto())}
        onKeyDown={(e) => (disabled ? {} : handleKeyPress(e))}
        tabIndex={0}
      >
        {/* Green border circle */}
        <circle
          r="4.5"
          cx="5"
          cy="5"
          fill="none"
          stroke="#1CC39D"
          strokeWidth={borderWidth}
        />
        <clipPath id={`clipCircle-${id}`}>
          <circle r="4.7" cx="5" cy="5" />
        </clipPath>
        <defs>
          <linearGradient id={`myGradient-${id}`}>
            <stop offset="0%" stopColor="rgba(0,0,0,0)" />
            <stop offset="20%" stopColor="#1DC29D" />
            <stop offset="50%" stopColor="#90D8C7" />
            <stop offset="80%" stopColor="#1DC29D" />
            <stop offset="100%" stopColor="rgba(0,0,0,0)" />
          </linearGradient>
        </defs>
        {(user || company) && (
          <g id="user-avi-form-background">
            {/* Green border circle */}
            <circle
              r="4.5"
              cx="5"
              cy="5"
              fill="none"
              stroke="#1CC39D"
              strokeWidth="0.5"
            />

            {/* Inner filled circle */}
            <circle r="4.7" cx="5" cy="5" fill="#585858" />
          </g>
        )}
        <circle r="4.5" cx="5" cy="5" fill="#585858" strokeWidth="1" />
        {/* Avatar Image */}
        <image
          href={tmpAvatarImg.image || data?.contentsUrl || placeholder}
          alt="avatar-image"
          x="0"
          y="0"
          height="10"
          width="10"
          clipPath={`url(#clipCircle-${id})`}
          preserveAspectRatio="xMaxYMax slice"
        />
        {/* Avatar Image */}

        {/* Add Photo Button */}
        {isEditing && (
          <image
            href={addPhotoIcon}
            alt="add-icon"
            className={!isEditing && "addIcon"}
            x="3.75"
            y="3.75"
            height="2.5"
            width="2.5"
          />
        )}
        {/* Add Photo Button */}

        {/* Loading Circle */}
        {tmpAvatarImg.isLoading ? (
          <path
            d="
    M 5.5, 5
    m 4.425, 0
    a 4.425,4.425 0 1,0 -9.85,0
    "
            fill="none"
            strokeWidth="0.15"
            stroke={`url('#myGradient-${id}')`}
            strokeLinecap="round"
          >
            <animateTransform
              attributeName="transform"
              attributeType="XML"
              type="rotate"
              dur="1s"
              from="0 5 5"
              to="360 5 5"
              repeatCount="indefinite"
            />
          </path>
        ) : null}
        {/* Loading Circle */}
      </svg>
      {/* Input */}
      <input
        type="file"
        style={{ display: "none" }}
        onChange={handleChange}
        ref={avatarInput}
        accept={ACCEPTED_PRIMARY_IMAGE_TYPES}
      />
      {/* Input */}
    </>
  );
};

FormAvatar.propTypes = {
  tmpAvatarImg: PropTypes.shape({
    isLoading: PropTypes.bool,
    image: PropTypes.string,
  }),
  setTmpAvatarImg: PropTypes.func,
  isEditing: PropTypes.bool,
  image: PropTypes.string,
  resourceName: PropTypes.string,
  user: PropTypes.bool,
  company: PropTypes.bool,
  disabled: PropTypes.bool,
  width: PropTypes.string,
  borderWidth: PropTypes.string,
};

FormAvatar.defaultProps = {
  tmpAvatarImg: { isLoading: false },
  setTmpAvatarImg: () => {},
  isEditing: false,
  disabled: false,
  image: undefined,
  resourceName: undefined,
  user: false,
  company: false,
  width: "85px",
  borderWidth: "0.5",
};

export default FormAvatar;

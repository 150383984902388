import React from "react";
import PropTypes from "prop-types";
import TableCellDropdown from "../../../Table/TableCellDropdown";
import useCsiCodeCellData from "./useCsiCodeCellData";

const CsiCodeCell = ({
  row,
  isEditingTable,
  setEditedRow,
  editedRow,
  csiCodes,
  groupBy,
}) => {
  const { originalFilteredCsiCodes, getDropdownValue, onChange } =
    useCsiCodeCellData({ csiCodes, groupBy, row, editedRow, setEditedRow });
  // View mode, if not editing
  if (!isEditingTable) {
    return row?.original?.csiCode;
  }

  return (
    <TableCellDropdown
      value={getDropdownValue(editedRow?.financialCode)}
      options={originalFilteredCsiCodes}
      onChange={onChange}
    />
  );
};

CsiCodeCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      csiCode: PropTypes.string,
    }),
  }),
  setEditedRow: PropTypes.func,
  editedRow: PropTypes.shape({
    financialCode: PropTypes.string,
  }),
  isEditingTable: PropTypes.bool,
  csiCodes: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  groupBy: PropTypes.arrayOf(PropTypes.string),
};

CsiCodeCell.defaultProps = {
  row: undefined,
  setEditedRow: () => {},
  editedRow: {},
  isEditingTable: false,
  csiCodes: [],
  groupBy: [],
};

export default CsiCodeCell;

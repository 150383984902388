import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import assetDefault from "../../assets/images/placeholder_asset_small.svg";
import spaceDefault from "../../assets/images/placeholder_space_small.svg";
import projectDefault from "../../assets/images/placeholder_project_small.svg";
import propertyDefault from "../../assets/images/placeholder_property_small.svg";
import placeholderImageSmall from "../../assets/images/placeholderImageSmall.png";
import { useGetFile } from "../../../hooks/useGetFile";

const getPlaceholder = (resourceType) => {
  switch (resourceType) {
    case "Asset":
      return assetDefault;
    case "Property":
      return propertyDefault;
    case "Space":
      return spaceDefault;
    case "Project":
      return projectDefault;
    default:
      return placeholderImageSmall;
  }
};

const getUrl = (data, avoidThumbnail) => {
  if (avoidThumbnail) {
    return data?.contentsUrl;
  }

  // thumbnails only generated for these file types in s3.
  const supportedContentTypes = ["image/png", "image/jpeg", "image/tiff"];

  if (supportedContentTypes.includes(data?.contentType)) {
    return data?.thumbnailUrl;
  }

  return data?.contentsUrl;
};

const getClassName = ({ className, circled }) => {
  const circledClassName = "w-8 h-8 rounded-full";
  const defaultClassName = "w-10 h-10 rounded";

  if (className) {
    return className;
  }

  if (circled) {
    return circledClassName;
  }
  return defaultClassName;
};

const ImageCell = ({ src, circled, className, style, title, resourceType }) => {
  const [imgSrc, setImgSrc] = useState();
  const { data } = useGetFile(src);

  useEffect(() => {
    if (data?.id) {
      setImgSrc(getUrl(data));
    } else {
      setImgSrc(null);
    }
  }, [data]);

  const onThumbnailError = () => {
    // if s3 thumbnail url is broken,
    // do not display a broken image but use contentsUrl instead
    setImgSrc(getUrl(data, true));
  };

  return (
    <img
      className={getClassName({ className, circled })}
      src={imgSrc || getPlaceholder(resourceType)}
      style={style}
      title={title}
      alt=""
      onError={() => onThumbnailError()}
    />
  );
};

ImageCell.propTypes = {
  /**
   * the source for the image
   */
  src: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.string,
  title: PropTypes.string,
  circled: PropTypes.bool,
  resourceType: PropTypes.string,
};

ImageCell.defaultProps = {
  src: undefined,
  title: undefined,
  style: undefined,
  className: undefined,
  circled: false,
  resourceType: undefined,
};

export default ImageCell;

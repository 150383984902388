import React, { useMemo } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import Widget from "../Widget/Widget";
import Address from "../AddressInput/Address";
import BillingAddress from "../AddressInput/BillingAddress";
import Checkbox from "../Checkbox/Checkbox";

const textCN = cntl`
  text-gray-450 
  text-base
  font-light
  flex
`;

const AddressWidget = ({
  resource,
  loading,
  isEditing,
  handleMailingSameAsPhysicalInput,
  handleAddressInput,
}) => {
  const addresses = useMemo(() => {
    if (isEditing) {
      return (
        <BillingAddress
          address={resource?.address}
          mailingSameAsPhysical={resource?.mailingSameAsPhysical}
          onChange={handleAddressInput}
          handleMailingSameAsPhysicalInput={handleMailingSameAsPhysicalInput}
        />
      );
    }
    if (!resource?.mailingSameAsPhysical) {
      const firstPhysical = resource?.address?.find(
        (item) => item?.use === "Physical"
      );
      const firstMailing = resource?.address?.find(
        (item) => item?.use === "Mailing"
      );
      return (
        <>
          <div
            className={`flex w-full ${"border-b"} border-gray-450 border-opacity-50 pb-4`}
            key={`Address-${firstPhysical?.street ?? firstPhysical?.zipCode}`}
          >
            <h3 className="font-bold text-gray-450 text-md w-1/4">
              Physical Address
            </h3>
            <Address address={firstPhysical} />
          </div>
          <div className="flex w-full border-gray-450 border-opacity-50 pt-4">
            <h3 className="font-bold text-gray-450 text-md w-1/4">
              Mailing Address
            </h3>
            {firstMailing ? (
              <Address address={firstMailing} />
            ) : (
              <p className="text-gray-450 text-md font-normal not-italic hover:underline cursor-pointer">
                {" "}
                No mailing address
              </p>
            )}
          </div>
        </>
      );
    }

    return (
      <>
        <div className="flex w-full border-b border-gray-450 border-opacity-50 pb-4">
          <h3 className="font-bold text-gray-450 text-md w-1/4">
            {`${resource?.address?.[0]?.use ?? "Physical"} Address`}
          </h3>
          <Address address={resource?.address?.[0]} />
        </div>
        <div className="flex w-full pt-4">
          <h3 className="font-bold text-gray-450 text-md w-1/4">
            Mailing Address
          </h3>
          <Checkbox disabled checked className="pr-2" />
          <p className={textCN}>Same As Physical Address</p>
        </div>
      </>
    );
  }, [
    isEditing,
    handleAddressInput,
    handleMailingSameAsPhysicalInput,
    resource?.address,
    resource?.mailingSameAsPhysical,
  ]);

  return (
    <Widget draggable={false} title="Address" className="mb-4">
      <div className={`relative pt-4 ${loading && "loading"}`}>{addresses}</div>
    </Widget>
  );
};

AddressWidget.propTypes = {
  resource: PropTypes.shape({
    address: PropTypes.arrayOf(
      PropTypes.shape({
        use: PropTypes.bool,
      })
    ),
    propertyType: PropTypes.string,
    mailingSameAsPhysical: PropTypes.bool,
    description: PropTypes.string,
  }),
  loading: PropTypes.bool,
  isEditing: PropTypes.bool,
  handleMailingSameAsPhysicalInput: PropTypes.func,
  handleAddressInput: PropTypes.func,
};

AddressWidget.defaultProps = {
  resource: undefined,
  loading: true,
  isEditing: false,
  handleMailingSameAsPhysicalInput: () => {},
  handleAddressInput: () => {},
};

export default AddressWidget;

import { useCallback } from "react";

const arithmeticToggleUnits = {
  add: "+",
  subtract: "-",
};

const useLiveBudgetCellData = ({ isHighRangeField, editedRow }) => {
  const computeAdjustedTotal = useCallback(
    (liveBudget) => {
      if (isHighRangeField) {
        const total = parseFloat(liveBudget || 0);
        if (
          editedRow?.highRange?.adjustment?.arithmeticUnit &&
          editedRow?.highRange?.adjustment?.adjustmentUnit &&
          editedRow?.highRange?.adjustment?.amount
        ) {
          let difference = 0;
          const adjustment = parseFloat(
            editedRow?.highRange?.adjustment?.amount
          );

          switch (editedRow?.highRange?.adjustment?.adjustmentUnit) {
            case "%": {
              difference = (adjustment / 100) * total;
              break;
            }
            default: {
              difference = adjustment;
            }
          }

          return editedRow?.highRange?.adjustment?.arithmeticUnit ===
            arithmeticToggleUnits.add
            ? total + difference
            : total - difference;
        }

        return total;
      }
      const total = parseFloat(liveBudget || 0);
      if (
        editedRow?.adjustment?.arithmeticUnit &&
        editedRow?.adjustment?.adjustmentUnit &&
        editedRow?.adjustment?.amount
      ) {
        let difference = 0;
        const adjustment = parseFloat(editedRow?.adjustment?.amount);

        switch (editedRow?.adjustment?.adjustmentUnit) {
          case "%": {
            difference = (adjustment / 100) * total;
            break;
          }
          default: {
            difference = adjustment;
          }
        }

        return editedRow?.adjustment?.arithmeticUnit ===
          arithmeticToggleUnits.add
          ? total + difference
          : total - difference;
      }

      return total;
    },
    [
      editedRow?.adjustment?.adjustmentUnit,
      editedRow?.adjustment?.amount,
      editedRow?.adjustment?.arithmeticUnit,
      editedRow?.highRange?.adjustment?.adjustmentUnit,
      editedRow?.highRange?.adjustment?.amount,
      editedRow?.highRange?.adjustment?.arithmeticUnit,
      isHighRangeField,
    ]
  );

  const inputValue = computeAdjustedTotal(
    isHighRangeField ? editedRow?.highRange?.liveBudget : editedRow?.liveBudget
  );

  const isHidden = isHighRangeField && !editedRow?.isCostRange;

  return { inputValue, isHidden };
};

export default useLiveBudgetCellData;

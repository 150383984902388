import editSingleSpaceStore from "../../../../helpers/Spaces/editSingleSpaceStore";
import editSpaceAssociationListStore from "../../../../helpers/Spaces/editSpaceAssociationListStore";
import editSpaceListStore from "../../../../helpers/Spaces/editSpaceListStore";

const editSpaceResponder = (queryClient) => {
  return {
    onSuccess: (returnedData) => {
      // Edit space in Left list if available
      editSpaceListStore({
        queryClient,
        updatedData: returnedData,
      });

      // Edit space in Association list if available
      editSpaceAssociationListStore({
        queryClient,
        updatedData: returnedData,
      });

      // Edit single Space
      editSingleSpaceStore({ queryClient, updatedData: returnedData });
    },
    onError: (err) => {
      console.error("Failed to edit space", err);
    },
  };
};

export default editSpaceResponder;

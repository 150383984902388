import financeKeys from "../../hooks/api/finances/financeKeys";

const editSingleFinanceStore = ({ queryClient, updatedData }) => {
  const queryKey = financeKeys.singleFinance(updatedData.association);
  const oldData = queryClient.getQueryData(queryKey);

  // Note: Important, property cache probably hasn't been initiated yet it will be undefined
  if (oldData) {
    queryClient.setQueryData(queryKey, { ...oldData, ...updatedData });
  }
};

export default editSingleFinanceStore;

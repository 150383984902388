import { useMutation, useQueryClient } from "react-query";
import editSpaceResponder from "./responders/editSpaceResponder";
import editSpaceHandler from "./handlers/editSpaceHandler";
import editSpaceListStore from "../../../helpers/Spaces/editSpaceListStore";
import editSpaceAssociationListStore from "../../../helpers/Spaces/editSpaceAssociationListStore";
import editSingleSpaceStore from "../../../helpers/Spaces/editSingleSpaceStore";

const useEditSpace = () => {
  const queryClient = useQueryClient();

  const editMutation = useMutation(
    editSpaceHandler,
    editSpaceResponder(queryClient)
  );

  /**
   * Edit space to list, association list, and single cache. It can specify whether to make a new request or not
   *
   * @param {Object} props
   * @param {boolean} newRequest optional defaults to true, if false is sent an api request will not be attempted
   */
  const editSpace = async (props, newRequest = true) => {
    if (!props.originalItem) {
      return console.error(
        "editSpace | props.originalItem is required: populate with item you are updating"
      );
    }

    if (!props.editedItem) {
      return console.error(
        "editSpace | props.editedItem is required: populate with the original version of the item you are updating"
      );
    }

    const { originalItem, editedItem } = props;

    if (newRequest === false) {
      const updatedData = { ...originalItem, ...editedItem };

      editSpaceListStore({ queryClient, updatedData });
      editSpaceAssociationListStore({ queryClient, updatedData });
      return editSingleSpaceStore({ queryClient, updatedData });
    }

    const response = await editMutation.mutateAsync(props);
    return response;
  };

  /**
   * Edit space list, associationlist and single cache from real time events
   * @param {Object} changes - changes made to the space
   */
  const editSpaceRtd = (changes) => {
    const updatedData = { ...changes };

    editSpaceListStore({ queryClient, updatedData });
    editSpaceAssociationListStore({ queryClient, updatedData });
    editSingleSpaceStore({ queryClient, updatedData });
  };

  const {
    isLoading: isEdtingPropertyLoading,
    isError: isEditingPropertyError,
    error: editingPropertyError,
  } = editMutation;

  return {
    isEdtingPropertyLoading,
    isEditingPropertyError,
    editingPropertyError,
    editSpace,
    editSpaceRtd,
  };
};

export default useEditSpace;

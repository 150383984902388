import React from "react";
import cntl from "cntl";
import PropTypes from "prop-types";

import ShareIcon from "../../../assets/images/shareIcon.svg";
import deleteGreenIcon from "../../../assets/images/deleteGreenIcon.svg";
import PlusCircleButton from "../../Buttons/PlusCircleButton/PlusCircleButton";

import IconButton from "../../Buttons/IconButton";
import { handleLinkClick } from "../../../../helpers/Utilities";
import useLinksRow from "./useLinksRow";
import LinkInput from "../LinkInput";

const labelWidth = { minWidth: "12.5rem", width: "10.5vw" };
const linkCN = () => cntl`
  flex 
  justify-between
  items-center 
  flex-row
  w-fit
  h-8
  pr-2
  gap-4
  mr-2
  rounded-md
  hover:shadow-light-lift
`;

const LinksRow = ({
  isEditing,
  form,
  currentTask,
  handleAddLink,
  handleRemoveLink,
  noTitle,
}) => {
  const {
    addLinksIsOpen,
    setAddLinksIsOpen,
    confirmEdit,
    handleChange,
    handleDeleteClick,
    setLinkUrl,
    linkName,
    linkUrl,
    setLinkName,
  } = useLinksRow({ isEditing, form, handleAddLink, handleRemoveLink });

  return (
    <>
      <div className="flex flex-row w-full items-start">
        {!noTitle && (
          <p style={labelWidth} className="font-semibold text-gray-450 text-md">
            Links
          </p>
        )}

        {addLinksIsOpen && isEditing && (
          <LinkInput
            onSave={confirmEdit}
            onClose={() => setAddLinksIsOpen(false)}
            linkName={linkName}
            setLinkName={setLinkName}
            linkUrl={linkUrl}
            setLinkUrl={setLinkUrl}
            handleChange={handleChange}
          />
        )}
        <div className="flex flex-wrap">
          {(isEditing || !currentTask) &&
            form?.links?.length > 0 &&
            form?.links?.reduce((linkList, link) => {
              if (link?.url) {
                linkList.push(
                  <div key={link?.id} className={linkCN()}>
                    <button
                      type="button"
                      className="flex w-full items-center cursor-pointer select-none"
                      onClick={() => handleLinkClick(link)}
                    >
                      <div className="flex py-4 pl-2 items-center w-full">
                        <img
                          src={ShareIcon}
                          alt="link open icon"
                          height={15}
                          width={15}
                        />
                        <p className="text-gray-300 underline font-regular ml-2">
                          {link?.name || link?.url}
                        </p>
                      </div>
                    </button>
                    <IconButton
                      wrapperClassName={isEditing ? "flex" : "hidden pr-2"}
                      onClick={() => handleDeleteClick(link)}
                      icon={deleteGreenIcon}
                      disabled={!isEditing}
                    />
                  </div>
                );
              }
              return linkList;
            }, [])}
          {isEditing && !addLinksIsOpen ? (
            <div className="flex flex-row w-full justify-start">
              <PlusCircleButton
                title="Add Link"
                onClick={() => setAddLinksIsOpen(!addLinksIsOpen)}
                disabled={!isEditing}
                className="flex items-center h-16 text-darkenedGreen ml-2"
                style={{ fontSize: 16 }}
              />
            </div>
          ) : null}
        </div>

        <div className="flex flex-wrap">
          {!isEditing &&
            currentTask?.links?.length > 0 &&
            currentTask?.links?.reduce((linkList, link) => {
              if (link?.url) {
                linkList.push(
                  <div key={link?.id} className={linkCN()}>
                    <button
                      type="button"
                      className="flex w-full items-center cursor-pointer select-none"
                      onClick={() => handleLinkClick(link)}
                    >
                      <div className="flex py-4 pl-2 items-center w-full">
                        <img
                          src={ShareIcon}
                          alt="link open icon"
                          height={15}
                          width={15}
                        />
                        <p className="text-gray-300 underline font-regular ml-2">
                          {link?.name || link?.url}
                        </p>
                      </div>
                    </button>
                    <IconButton
                      wrapperClassName={isEditing ? "flex" : "hidden pr-2"}
                      onClick={() => handleDeleteClick(link)}
                      icon={deleteGreenIcon}
                      disabled={!isEditing}
                    />
                  </div>
                );
              }
              return linkList;
            }, [])}
        </div>
      </div>
    </>
  );
};

LinksRow.propTypes = {
  isEditing: PropTypes.bool,
  form: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    additionalInfo: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  currentTask: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      })
    ),
  }),
  handleAddLink: PropTypes.func,
  handleRemoveLink: PropTypes.func,
  noTitle: PropTypes.bool,
};

LinksRow.defaultProps = {
  isEditing: false,
  form: undefined,
  currentTask: undefined,
  handleAddLink: () => {},
  handleRemoveLink: () => {},
  noTitle: false,
};

export default LinksRow;

import { propertyKeys } from "../../hooks/properties";

const editSinglePropertyStore = ({ queryClient, updatedData }) => {
  const queryKey = propertyKeys.byId(updatedData.id);
  const oldData = queryClient.getQueryData(queryKey);

  // Note: Important, property cache probably hasn't been initiated yet it will be undefined
  if (oldData) {
    queryClient.setQueryData(queryKey, { ...oldData, ...updatedData });
  }
};

export default editSinglePropertyStore;

import useAuthenticatedQuery from "../../useAuthenticatedQuery";
import financeKeys from "./financeKeys";
import fetchFinanceHandler from "./handler/fetchFinanceHandler";

const useFinance = ({ association }) => {
  const authenticatedResponse = useAuthenticatedQuery({
    queryKey: financeKeys.singleFinance(association),
    queryFn: ({ signal }) => fetchFinanceHandler(association, signal),
    enabled: !!association,
    onError: () => {
      console.error(
        "useFinance : there was a problem getting the single finance"
      );
    },
  });

  const {
    data: finance,
    isLoading: financeLoading,
    isError: isFinanceError,
    error: financeError,
  } = authenticatedResponse;

  return {
    finance,
    financeLoading,
    isFinanceError,
    financeError,
  };
};

export default useFinance;

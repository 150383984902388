import { useMemo, useRef } from "react";
import { useESChatContext } from "../../../../context/ChatContext/ESChatContext";
import useNotifications from "../../../../hooks/useNotifications";
import useOutsideAlerter from "../../../../hooks/useOutsideAlerter";
import useSystemConfiguration from "../../../../hooks/useSystemConfiguration";
import useTodos from "../../../../hooks/useTodos";
import { useAppState } from "../../../../state/appState";
import { useModalState } from "../../../../state/modalState";
import {
  ADD_OPEN_MODAL,
  SERVICE_REQUEST_CREATE_MODAL,
} from "../../../../constants";

const useTopNavBar = ({ onLogoClick, closeMenu }) => {
  const { clientChat: client, unread } = useESChatContext();
  const { data: todos } = useTodos();
  const { data: notifications = [] } = useNotifications();

  const { data: systemConfiguration } = useSystemConfiguration();
  const [, dispatch] = useAppState();
  const [{ modals }, modalDispatch] = useModalState();
  const popupRef = useRef(null);
  // Detect click outside of the Tooltip popup to close
  useOutsideAlerter(popupRef, () => closeMenu());
  const todosCount = useMemo(() => todos?.length ?? 0, [todos?.length]);

  const notificationCount = useMemo(() => {
    return notifications.reduce((count, item) => {
      const addAmount = item.read ? 0 : 1;
      return count + addAmount;
    }, 0);
  }, [notifications]);

  const onLogoKeyDown = (event) => {
    if (event.key === "Enter") {
      onLogoClick();
    }
  };

  const showCreateTicketBell = !modals?.find(
    (item) => item.modalType === SERVICE_REQUEST_CREATE_MODAL
  );

  const openTicketRequest = () => {
    modalDispatch({
      type: ADD_OPEN_MODAL,
      ref: { id: "top_navbar_bell_ticket_modal" },
      modalType: SERVICE_REQUEST_CREATE_MODAL,
      modalData: { isQuickAdd: false },
    });
  };

  return {
    onLogoKeyDown,
    openTicketRequest,
    systemConfiguration,
    dispatch,
    todosCount,
    notificationCount,
    unread,
    client,
    popupRef,
    showCreateTicketBell,
  };
};

export default useTopNavBar;

import React from "react";
import PropTypes from "prop-types";
import Checkbox from "../../../Checkbox/Checkbox";
import BooleanBullet from "../../../BooleanBullet/BooleanBullet";

const AllowanceCell = ({ row, isEditingTable, editedRow, setEditedRow }) => {
  const onChange = (newVal) => {
    setEditedRow({
      ...editedRow,
      allowance: newVal,
    });
  };
  if (!isEditingTable) {
    return <BooleanBullet isTrue={row?.original?.allowance} />;
  }

  return (
    <div className="flex w-full">
      <Checkbox
        className="flex items-center"
        checked={editedRow?.allowance}
        onChange={onChange}
        onFocusClassName="border-brandGreen"
        name="14.allowanc?e"
      />
    </div>
  );
};

AllowanceCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      allowance: PropTypes.bool,
    }),
  }),
  editedRow: PropTypes.shape({
    allowance: PropTypes.bool,
  }),
  isEditingTable: PropTypes.bool,
  setEditedRow: PropTypes.func,
};

AllowanceCell.defaultProps = {
  row: undefined,
  editedRow: {},
  isEditingTable: false,
  setEditedRow: undefined,
};

export default AllowanceCell;

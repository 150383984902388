import cntl from "cntl";
import PropTypes from "prop-types";
import React, { useState } from "react";
import PlusCirlceIconDarkGreen from "../../../assets/images/plusCircleIconDarkGreen.svg";
import PlusCirlceIconBrandGrey from "../../../assets/images/plusCircleIconBrandGrey.svg";
import { PILL_GRAY } from "../../../../constants";

const containerCN = (classes) => cntl`
  flex
  items-center
  transition-all
  ${classes}
`;
export default function PlusCircleButton({
  title,
  onClick,
  className,
  titleClassName,
  style,
  noHover,
  disabled,
}) {
  const [hover, setHover] = useState(false);

  return (
    <button
      className={
        className
          ? containerCN(className)
          : "flex items-center mt-6 transition-all"
      }
      style={{
        transform:
          hover && !noHover
            ? "scale(1.05) translateX(2%)"
            : "scale(1) translateX(0%)",
      }}
      type="button"
      onFocus={() => setHover(true)}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => onClick()}
      disabled={disabled}
    >
      <p
        className={`font-semibold mr-2 ${titleClassName}`}
        style={
          style || {
            color: PILL_GRAY,
          }
        }
      >
        {title}
      </p>
      <img
        className="h-4 w-4"
        src={disabled ? PlusCirlceIconBrandGrey : PlusCirlceIconDarkGreen}
        alt={disabled ? "plus circle icon disabled" : "plus circle icon green"}
      />
    </button>
  );
}

PlusCircleButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  noHover: PropTypes.bool,
  titleClassName: PropTypes.string,
  style: PropTypes.shape({}),
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

PlusCircleButton.defaultProps = {
  title: undefined,
  onClick: undefined,
  noHover: false,
  titleClassName: "",
  style: undefined,
  className: undefined,
  disabled: false,
};

/**
 * This file will have all query keys for queryHooks and mutations.
 *
 * This is how query keys are going to be handle:
 *
 * ["properties"]                                             --> it will hold all properties
 * ["properties", "Property/1111-2222-3333-4444"]             --> it will hold details for single property
 * ["properties", "Property/1111-2222-3333-4444", "files"]    --> it will hold files for single property
 *
 * ["users", "current", "detail", "avatar"]                   --> it will hold the S3 links for current user avatar
 */

export const configurationKeys = (type = "system") => ["configuration", type];

export const tenantKeys = {
  tenants: ["tenants"],
  currentTenant: ["tenants", "currentTenant"],
};

export const userKeys = {
  users: ["users"],
  currentUser: () => [...userKeys.users, "current"],
  currentUserDetail: (id) => [...userKeys.currentUser(), "detail", id],
  currentUserAvatar: (fileRef) => [
    ...userKeys.currentUser(),
    "avatar",
    fileRef,
  ],
  byId: (id) => [...userKeys.users, id],
  preferences: (id) => [...userKeys.users, id, "preferences"],
  assigness: () => [...userKeys.users, "assigness"],
  withPermissions: (permissions = []) => [
    ...userKeys.users,
    "permissions",
    permissions,
  ],
};

export const preferenceKeys = {
  preferences: ["preferences"],
};

// TODO (Migrate to new folder architecture)
export const sopKeys = {
  sops: ["sops"],
  sopDetail: (id) => [...sopKeys.sops, id, "detail"],
  history: (id) => [...sopKeys.sops, id, "history"],
  activity: (id) => [...sopKeys.sops, id, "activity"],
  byVersion: (id, version) => [...sopKeys.sops, id, "version", version],
};

export const historyKeys = {
  history: ["history"],
  historyByAssociation: (association) => [...historyKeys.history, association],
};

export const tagKeys = {
  tags: ["tags"],
};

export const expenseKeys = {
  expenses: ["expenses"],
  expensesByAssociation: (association) => [
    ...expenseKeys.expenses,
    association,
  ],
  mergedExpensesByAssociation: (association) => [
    ...expenseKeys.expenses,
    "merged",
    association,
  ],
};

export const vendorinvoiceKeys = {
  vendorinvoices: ["vendorinvoices"],
  vendorinvoicesByAssociation: (association) => [
    ...vendorinvoiceKeys.vendorinvoices,
    association,
  ],
  mergedVendorinvoicesByAssociation: (association) => [
    ...vendorinvoiceKeys.vendorinvoices,
    "merged",
    association,
  ],
};

export const budgetLineitemKeys = {
  budgetLineitems: ["budget-lineitems"],
  budgetLineitemsByBudgetId: (budgetId) => [
    ...budgetLineitemKeys.budgetLineitems,
    budgetId,
  ],
  budgetLineitemsByInvoice: (budgetId, invoiceRef) => [
    ...budgetLineitemKeys.budgetLineitems,
    budgetId,
    invoiceRef,
  ],
};

/**
 * @deprecated
 */
export const taskKeys = {
  tasks: ["tasks"],
  byId: (id, instanceStartDate) =>
    [...taskKeys.tasks, id, instanceStartDate].filter(Boolean),
  taskHistory: (association) => {
    const id = association.split("/")[1];
    return [...taskKeys.tasks, id, "history"];
  },
  taskActivity: (association) => {
    const id = association.split("/")[1];
    return [...taskKeys.tasks, id, "activity"];
  },
};

export const rfiKeys = {
  rfis: ["rfis"],
};

export const submittalKeys = {
  submittals: ["submittals"],
  submittalsByAssociation: (association) => [
    ...submittalKeys.submittals,
    association,
  ],
  submittalById: (id) => [...submittalKeys.submittals, id],
  submittalHistory: (association) => {
    const id = association.split("/")[1];
    return [...submittalKeys.submittals, id, "history"];
  },
};
export const timesheetKeys = {
  timesheets: ["timesheets"],
  timesheetsByAssociation: (association) => [
    ...timesheetKeys.timesheets,
    association,
  ],
  mergedTimesheetsByAssociationAndInvoice: (association, invoiceRef) => [
    ...timesheetKeys.timesheets,
    "merged",
    association,
    invoiceRef,
  ],
};

export const subscriptionKeys = {
  subscription: ["subscription"],
};

export const workflowKeys = {
  workflows: ["workflows"],
  workflowsByAssociation: (association) => [
    ...workflowKeys.workflows,
    association,
  ],
  workflowById: (id) => [...workflowKeys.workflows, id],
  workflowHistory: (association) => {
    const id = association.split("/")[1];
    return [...workflowKeys.workflows, id, "history"];
  },
  workflowByTaskRef: (tasks) => [...workflowKeys.workflows, tasks],
};

export const commentsKeys = {
  comments: ["comments"],
  commentsByAssociation: (association) => [
    ...commentsKeys.comments,
    association,
  ],
};

export const ticketsKeys = {
  tickets: ["tickets"],
  byId: (id) => [...ticketsKeys.tickets, id],
  byAssociation: (association) => [...ticketsKeys.tickets, association],
  tasks: () => [...ticketsKeys.tickets, "tasks"],
  tasksByTicketRef: (ticketRef) => [...ticketsKeys.tasks(), ticketRef],
  ticketsByTaskRef: (taskRef) => [...ticketsKeys.tickets, taskRef],
};

/**
 * Reports query keys
 */
export const reportsKeys = {
  reports: ["reports"],
  templates: () => [...reportsKeys.reports, "templates"],
  byAssociation: (association) => [...reportsKeys.reports, association],
  byId: (id) => [...reportsKeys.reports, id],
};

export const filePaginatedKeys = {
  allFiles: ["paginated-files"],
  page: (page = 1) => [...filePaginatedKeys.allFiles, page],
};
/**
 * Create query key for associations, to have granularity based on parent keys
 * Ex: [properties, Property/1111-3333-4444-555, members]
 *
 * @param {string} association Association reference Ex: Property/1112-3334-5556
 * @param {string} param aditional query parameter: Ex: files, spaces, members, vendors
 * @returns {Array} query key
 */
export const associationKeyGenerator = (association = "", param) => {
  const [ref] = association.split("/");
  const associationPlural = {
    Property: "properties",
    Project: "projects",
    Asset: "assets",
  };

  return [associationPlural[ref], association, param];
};

/**
 * Create query key for single files
 * @param {string} fileReference file reference Ex: File/1111-2222-3333
 * @returns query key [File/1111-2222-3333]
 */
export const fileKeys = (fileReference) => [fileReference];

/**
 * Project Code Keys
 */
export const projectCodeKeys = ["project", "codes"];

/**
 * Notification Code Keys
 */
export const notificationCodeKeys = ["notifications"];

export const chatCodeKeys = ["streamChat"];

export const todosCodeKeys = ["todos"];

export const rsvpKeys = {
  all: ["rsvp"],
  byRef: (ref) => [...rsvpKeys.all, ref],
  byRefAndInstanceStartDate: (ref, instanceStartDate) =>
    instanceStartDate
      ? [...rsvpKeys.all, ref, instanceStartDate]
      : [...rsvpKeys.all, ref],
};

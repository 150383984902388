/* eslint-disable prefer-destructuring */
import React from "react";
import PropTypes from "prop-types";
import useContactCardImageData from "./useContactCardImageData";

/**
 * Component to render a circular image
 * @param {string | undefined} imageRef Deprecated: S3 url
 * @param {string | undefined} imageReference File reference for the image to render.
 * This prop allow to display primary image using react-query.
 */
const ContactCardImage = ({
  imageReference,
  title,
  resourceName,
  isInactive,
}) => {
  const { image, placeholder } = useContactCardImageData({
    resourceName,
    imageReference,
  });

  return (
    <svg
      viewBox="0 0 10 10"
      width="86px"
      preserveAspectRatio="xMidYMid meet"
      aria-label="avatar-image"
      className="rounded-full self-center"
      style={{ minWidth: "86px" }}
      role="presentation"
    >
      <clipPath id={`clipCircle-${title}`}>
        <circle r="5" cx="5" cy="5" />
      </clipPath>

      <image
        href={image || placeholder()}
        alt="avatar-image"
        x="0"
        y="0"
        height="10"
        width="10"
        clipPath={`url(#clipCircle-${title})`}
        preserveAspectRatio="xMaxYMax slice"
        className={`${isInactive && "opacity-60"}`}
      />
    </svg>
  );
};

ContactCardImage.propTypes = {
  imageReference: PropTypes.string,
  title: PropTypes.string,
  resourceName: PropTypes.string,
  isInactive: PropTypes.bool,
};

ContactCardImage.defaultProps = {
  imageReference: undefined,
  title: undefined,
  resourceName: undefined,
  isInactive: false,
};

export default ContactCardImage;

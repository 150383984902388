/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { TimesheetUtil } from "@griffingroupglobal/eslib-util";
import { isEqual } from "lodash";
import PureApprovalTable from "./PureApprovalTable";
import {
  getIsoDate,
  MinutesToHours,
  rangeToMoments,
  weeksFollowingDay,
} from "../../../helpers/TimeSheet";
import {
  CUMULATIVE_VIEW,
  EMPLOYEE_VIEW,
  PROJECT_VIEW,
} from "../../../constants";
import usePagePersistence from "../../../hooks/usePagePersistence";

const { TimeSheets } = TimesheetUtil;

const ApprovalTable = ({
  dateQuery,
  timesheets,
  ptoSheets,
  historyData,
  loading,
  financials,
  projectCodes,
  projects,
  defaultCsiDict,
  defaultRates,
  reload,
  warnings,
  alerts,
  selectedRows,
  setSelectedRows,
  handleApproveRow,
  handleRejectRow,
  handleApprovalEntries,
  submitCumulativeRow,
  customSiteHeader,
  setCumulativeView,
  setActiveView,
  activeView,
}) => {
  /**
   * Time Navigation Functions/Vars
   */

  const { pageState, setPersistentPageItem } = usePagePersistence();

  const getWeek = useCallback(
    (d) => {
      const currentFrame = TimeSheets.getPeriodFrame(
        financials?.period,
        financials?.start,
        getIsoDate(d)
      );

      return rangeToMoments(currentFrame?.periodStart, currentFrame?.periodEnd);
    },
    [financials?.period, financials?.start]
  );

  const currentWeek = useMemo(() => {
    return getWeek(moment.utc().format("MM/DD/YYYY"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateQuery]);

  const [dates, setDates] = useState();

  useEffect(() => {
    setDates(getWeek(dateQuery));
  }, [dateQuery, getWeek]);

  const templateOptions = useMemo(
    () => [
      {
        custom: false,
        id: "7dad139a-3264-4958-930a-ad9c354533d5",
        filters: [],
        groups: [
          {
            label: "Employee",
            value: "employee.reference",
          },
        ],
        hideGroupedFooter: true,
        hideGroupedHeader: true,

        /**
         *
         * @param {Array} rows - Timesheets roll-up for cumulative view
         * @summary - combines users pto & time worked into one line for cumulative view
         * - sets cumulativeView state to true to accomadate for patch processing
         */
        groupBy: (rows) => {
          setCumulativeView(true);
          setActiveView(CUMULATIVE_VIEW);
          return Object.entries(rows)?.reduce((list, [key, item]) => {
            let cumulative = 0;
            let ptoTime = 0;
            const statusRollUp = [];
            const userRows = [];
            item.forEach(({ original, values: { Total } }) => {
              const { timeOff = false } = original;
              if (!timeOff) {
                userRows.push(original);
              }

              if (timeOff) {
                Object.values(original?.entries || {})?.forEach((day) => {
                  cumulative += parseFloat(day.value);
                  ptoTime += parseFloat(day.value);
                  statusRollUp.push("approved");
                });
              }
              Object.values(Total).forEach((day) => {
                if (!timeOff) cumulative += parseFloat(day.value);
                statusRollUp.push(day.status);
              });
            });
            list[key] = [
              {
                ...item[0],
                values: {
                  ...item[0].values,
                  ptoTime: MinutesToHours(ptoTime),
                  worked: cumulative - ptoTime,
                  cumulative: MinutesToHours(cumulative),
                  statusRollUp,
                  userRows,
                },
              },
            ];
            return list;
          }, {});
        },
        columns: [
          {
            value: "selection",
          },
          {
            label: "Employee",
            value: "employee",
          },
          {
            label: "Status",
            value: "b",
          },
          {
            label: "Exempt",
            value: "c",
          },
          {
            label: "Under",
            value: "d",
          },
          {
            label: "Over",
            value: "e",
          },
          {
            label: "Time Off",
            value: "f",
          },
          {
            label: "Time Worked",
            value: "g",
          },
          {
            label: "Cumulative",
            value: "h",
          },
          {
            label: "Approver",
            value: "i",
          },
          {
            label: "Submitted",
            value: "j",
          },
        ],
        name: "Cumulative",
        isAdmin: true,
      },
      {
        custom: false,
        id: "7dad139a-3264-4958-930a-ad9c354533c3",
        filters: [],
        groups: [
          {
            label: "Project",
            value: "project",
          },
        ],

        /**
         * - Sets cumulativeView state to false form normal patch processing
         */
        groupBy: (rows) => {
          setCumulativeView(false);
          setActiveView(PROJECT_VIEW);
          return rows;
        },
        columns: [
          {
            value: "selection",
          },
          {
            label: "Employee",
            value: "employee",
          },
          {
            label: "Status",
            value: "b",
          },
          {
            label: "Exempt",
            value: "c",
          },
          {
            label: "Under",
            value: "d",
          },
          {
            label: "Over",
            value: "e",
          },
          {
            label: "Time Off",
            value: "f",
          },
          {
            label: "Time Worked",
            value: "g",
          },
          {
            label: "Cumulative",
            value: "h",
          },
          {
            label: "Approver",
            value: "i",
          },
          {
            label: "Submitted",
            value: "j",
          },
        ],
        name: "Project",
        isAdmin: true,
      },
      {
        custom: false,
        id: "7aad139a-3264-4958-930a-ad9c354533c4",
        filters: [],
        groups: [
          {
            label: "Employee",
            value: "employee",
          },
        ],

        /**
         * - Sets cumulativeView state to false form normal patch processing
         */
        groupBy: (rows) => {
          setCumulativeView(false);
          setActiveView(EMPLOYEE_VIEW);
          return rows;
        },
        columns: [
          {
            value: "selection",
          },
          {
            label: "Employee",
            value: "employee",
          },
          {
            label: "Status",
            value: "b",
          },
          {
            label: "Exempt",
            value: "c",
          },
          {
            label: "Under",
            value: "d",
          },
          {
            label: "Over",
            value: "e",
          },
          {
            label: "Time Off",
            value: "f",
          },
          {
            label: "Time Worked",
            value: "g",
          },
          {
            label: "Cumulative",
            value: "h",
          },
          {
            label: "Approver",
            value: "i",
          },
          {
            label: "Submitted",
            value: "j",
          },
        ],
        name: "Employee",
        isAdmin: true,
      },
    ],
    [setActiveView, setCumulativeView]
  );

  /**
   * @function showNextWeek
   * @summary - disables next week navigation if the 1st day of
   * next week is outside the pay period
   */

  const forwardButtonDisabled = useMemo(() => {
    return moment(pageState?.timesheet?.periodEnd).isAfter(moment(), "day");
  }, [pageState?.timesheet?.periodEnd]);

  const showNextWeek = useMemo(() => {
    /**
     * Does not advance navigation past end of current payroll
     * Length of dates array is initialized by payroll period
     * Current Options: 7 || 14
     */

    return forwardButtonDisabled
      ? undefined
      : () => {
          const newStartDate = moment(pageState?.timesheet?.periodStart)
            .add(7, "days")
            .format("YYYY-MM-DD");

          const newEndDate = moment(pageState?.timesheet?.periodEnd)
            .add(7, "days")
            .format("YYYY-MM-DD");

          setPersistentPageItem("timesheet", {
            ...pageState?.timesheet,
            periodStart: newStartDate,
            periodEnd: newEndDate,
          });

          const nextWeek = weeksFollowingDay(
            financials?.start,
            dates?.[financials?.period === "everyotherweek" ? 13 : 6]
              .add(1, "days")
              .format(),
            financials?.period === "everyotherweek" ? 2 : 1
          );

          setDates(nextWeek);
          reload(
            nextWeek[0].format().split("T")[0],
            nextWeek[financials?.period === "everyotherweek" ? 13 : 6]
              .format()
              .split("T")[0]
          );
        };
  }, [
    dates,
    financials?.period,
    financials?.start,
    forwardButtonDisabled,
    pageState?.timesheet,
    reload,
    setPersistentPageItem,
  ]);

  const showPrevWeek = useCallback(() => {
    const newStartWeek = moment(pageState?.timesheet?.periodStart)
      .subtract("7", "days")
      .format("YYYY-MM-DD");

    const newEndWeek = moment(pageState?.timesheet?.periodEnd)
      .subtract("7", "days")
      .format("YYYY-MM-DD");

    setPersistentPageItem("timesheet", {
      ...pageState?.timesheet,
      periodStart: newStartWeek,
      periodEnd: newEndWeek,
    });

    const previousWeek = weeksFollowingDay(
      financials?.start,
      moment(dates?.[0]?.format())
        .subtract(financials?.period === "everyotherweek" ? 14 : 7, "days")
        .format(),
      financials?.period === "everyotherweek" ? 2 : 1
    );
    setDates(previousWeek);

    reload(
      moment(previousWeek[0]).format("YYYY-MM-DD"),
      moment(
        previousWeek[financials?.period === "everyotherweek" ? 13 : 6]
      ).format("YYYY-MM-DD")
    );
  }, [
    dates,
    financials?.period,
    financials?.start,
    pageState?.timesheet,
    reload,
    setPersistentPageItem,
  ]);

  const showThisWeek = useCallback(() => {
    setPersistentPageItem("timesheet", {
      ...pageState?.timesheet,
      periodStart: moment(currentWeek[0]).format("YYYY-MM-DD"),
      periodEnd: moment(
        currentWeek[financials?.period === "everyotherweek" ? 13 : 6]
      ).format("YYYY-MM-DD"),
    });

    // remanence of old system, changing this wil likely break the list
    setDates(currentWeek);
    reload(
      currentWeek[0].format().split("T")[0],
      currentWeek[financials?.period === "everyotherweek" ? 13 : 6]
        .format()
        .split("T")[0]
    );
  }, [
    currentWeek,
    financials?.period,
    pageState?.timesheet,
    reload,
    setPersistentPageItem,
  ]);
  /**
   * Time Navigation Func/Var End
   */

  /**
   * Footer Funcs start
   */
  const footerData = useMemo(() => {
    const entries = {};
    [...timesheets, ...(ptoSheets ?? [])]?.forEach((item, index) => {
      entries[index] = { ...item?.entries };
    });
    return { entries };
  }, [ptoSheets, timesheets]);

  const [columns, setColumns] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
  useEffect(() => {
    if (dates) {
      const getTime = (column) => {
        const totalTime = Object.keys(footerData?.entries)?.reduce((a, key) => {
          const value = footerData?.entries[key]?.[column]?.value;
          if (value) return a + parseFloat(value);
          return a;
        }, 0);
        return totalTime;
      };
      const getTotalTime = (week) => {
        const totalMins = week?.reduce((a, b) => a + b, 0);
        return totalMins;
      };
      const tempArr = dates?.map((date) => getTime(date.format("MM/DD/YYYY")));
      tempArr[7] = getTotalTime(tempArr?.slice(0, 7));
      if (!isEqual(tempArr, columns)) {
        setColumns(tempArr);
      }
    }
  }, [columns, dates, footerData?.entries]);
  /**
   * Footer Funcs End
   */

  return (
    <PureApprovalTable
      data={timesheets}
      ptodata={ptoSheets}
      historyData={historyData}
      dates={dates}
      dateQuery={dateQuery}
      loading={loading}
      showNextWeek={showNextWeek}
      showPrevWeek={showPrevWeek}
      showThisWeek={showThisWeek}
      warnings={warnings}
      columns={columns}
      alerts={alerts}
      projects={projects}
      defaultCsiDict={defaultCsiDict}
      defaultRates={defaultRates}
      financials={financials}
      projectCodes={projectCodes}
      selectedRows={selectedRows}
      setSelectedRows={setSelectedRows}
      handleApproveRow={handleApproveRow}
      handleRejectRow={handleRejectRow}
      handleApprovalEntries={handleApprovalEntries}
      submitCumulativeRow={submitCumulativeRow}
      customSiteHeader={customSiteHeader}
      templateOptions={templateOptions}
      activeView={activeView}
    />
  );
};

ApprovalTable.defaultProps = {
  ptoSheets: [],
  userName: "",
  timesheets: [],
  setCumulativeView: () => {},
};

export default ApprovalTable;

import { RealTimeEvent } from "@griffingroupglobal/eslib-ably";
import useEditFinance from "../api/finances/useEditFinance";

const useHandleFinanceRealTime = () => {
  const { editFinanceRtd } = useEditFinance();

  /**
   * Handles realtime finances events from Ably and updates the finance cache accordingly
   * @param {object} message - the realtime message received from Ably
   * @param {string} message.name - the event name indicating the type of update
   * @param {object} message.data - the payload containing event-specific data
   * @param {object} message.data.changes - the updated finance data
   */
  const handleFinance = (message) => {
    const event = message.name;
    const { changes } = message.data;

    if (event === RealTimeEvent.ResourceUpdated) {
      editFinanceRtd(changes);
    }
  };

  return { handleFinance };
};

export default useHandleFinanceRealTime;

import { clone, cloneDeep, findIndex, uniqBy } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { AttributeAPI } from "@griffingroupglobal/eslib-api";
import { v4 as uuidv4 } from "uuid";
import * as yup from "yup";
import PrimaryButton from "../../../stories/Components/Buttons/PrimaryButton";
import TertiaryButton from "../../../stories/Components/Buttons/TertiaryButton";
import Checkbox from "../../../stories/Components/Checkbox/Checkbox";
import DatePicker from "../../../stories/Components/DatePicker/DatePicker";
import Dropdown from "../../../stories/Components/Dropdown/Dropdown";
import Input from "../../../stories/Components/Input/Input";

import { ATTRIBUTE_TYPES } from "../../../constants";
import { getNumericOnly } from "../../../helpers/Formatters";
import Modal from "../../../stories/Components/Modal/Modal";
import collapseIcon from "../../../stories/assets/images/collapseIcon.svg";
import { handleFormatAttributePostPayload } from "../../../helpers/Asset";
import { toastError } from "../../../stories/Components/Toast/Toast";
import isLastErrorMessageFound from "../../../helpers/Error/isLastErrorMessageFound";
import newIdAttributesGenerator from "../../../helpers/newIdAttributesGenerator";

const customStyle = {
  valueContainer: {
    maxHeight: "80px",
    overflow: "scroll",
  },
};

const modalStyles = {
  content: {
    inset: "0",
    width: "40%",
    height: "min-content",
    maxHeight: "100%",
    padding: "0",
    marginLeft: "auto",
    marginRight: "auto",
    overflowY: "scroll",
    top: "0",
    left: "0",
  },
  overlay: {
    backgroundColor: "rgba(25, 25, 25, 0.8)",
    zIndex: "50",
  },
};

const formattedAttributes = (list) => {
  const cloneList = cloneDeep(list);
  return cloneList
    ?.filter((attr) => attr.saved)
    ?.map((attr) => {
      const rv = attr;
      rv.fieldType = rv?.fieldType?.value;

      if (rv.fieldType === "number-entry-with-unit") {
        rv.fieldType = "number-entry";
      }
      if (rv?.options?.length) {
        rv.options = rv?.options?.map(({ name }) => name);
      }

      if (rv?.unit?.options?.length) {
        rv.unit.options = rv?.unit?.options?.map(({ name }) => name);
      }

      Object.keys(rv).forEach((key) => {
        if (rv[key] === null) {
          delete rv[key];
        }
      });

      return rv;
    });
};

/**
 * @deprecated
 */

const AssetAttributesModal = ({
  asset,
  showModal,
  setShowModal,
  onAddElements,
  onCancel,
  measurements,
  setAttributes,
  newAttributes,
  setNewAttributes,
  handleEditClick,
  reloadAttributes,
}) => {
  const [hovering, setHovering] = useState();
  const [elementOptions, setElementOptions] = useState([]);
  const [selectedElements, setSelectedElements] = useState([]);
  const [windowWidth, setWindowWidth] = useState(0);
  const modalRef = useRef(null);
  const currentWindowWidth = modalRef?.current?.getBoundingClientRect().width;

  const tooltipData = [
    {
      label: "Attributes",
      content:
        "Add attributes by selecting options from dropdown list or create your own custom attributes by clicking on + Add New Attributes",
    },
  ];

  const canAddAttributes = useCallback(() => {
    const allowAdd = selectedElements?.every((s) => {
      if (s.fieldType === "text-entry") {
        if (s?.options?.length > 0) return !!s.data.unit && !!s.data.value;
        return !!s.data.value;
      }
      if (s.fieldType === "number-entry") {
        if (s?.unit?.options?.length > 0)
          return !!s.data.unit && !!s.data.value;
        return !!s.data.value && !Number.isNaN(Number(s.data.value));
      }
      if (s.fieldType === "number-entry-with-unit") {
        return !!s.data.unit && !Number.isNaN(Number(s.data.value));
      }
      if (s.fieldType === "check-box") return !!s.data.value;
      if (s.fieldType === "date-picker") return !!s.data.value;
      if (s.fieldType === "ddl")
        return !!s.data.value && !!s?.options?.length > 0;

      return false;
    });

    return selectedElements?.length > 0 ? allowAdd : false;
  }, [selectedElements]);

  useEffect(() => {
    setWindowWidth(currentWindowWidth);
  }, [currentWindowWidth]);

  useEffect(() => {
    if (setAttributes) {
      const attributes = formattedAttributes(newAttributes);
      setAttributes((prev) => uniqBy([...prev, ...attributes], "detail"));
    }
  }, [newAttributes, setAttributes]);

  /**
   * @TODO - Convert useAttribute Hook to React-Query and remove this logic
   */
  const loadAttributes = useCallback(() => {
    const newOptions = [];
    if (measurements?.length) {
      measurements?.forEach((item) => {
        const existing = asset.additionalInfo?.some(
          (info) => info.optionId === item.detail
        );
        const adding = selectedElements?.some(
          (info) => info.detail === item.detail
        );

        if (!existing && !adding) {
          newOptions.push({
            label: item.detail,
            value: item.detail,
          });
        }
      });
    }
    if (newAttributes?.length) {
      newAttributes?.forEach((attribute) => {
        const foundSelected = selectedElements?.some(
          (info) => info.detail === attribute.detail
        );

        if (!foundSelected) {
          if (attribute.saved) {
            newOptions.push({
              label: attribute.detail,
              value: attribute.detail,
              isNew: true,
            });
          }
        }
      });
    }

    setElementOptions(newOptions);
  }, [asset?.additionalInfo, measurements, newAttributes, selectedElements]);

  useEffect(() => {
    loadAttributes();
  }, [loadAttributes]);

  const handleHover = (id) => {
    setHovering(id);
  };

  const handleChange = (detail, value, unit) => {
    const elementIndex = selectedElements.findIndex(
      (item) => item.detail === detail
    );
    if (elementIndex > -1) {
      setSelectedElements((prev) => {
        const result = prev.map((item, idx) => {
          if (idx === elementIndex) {
            return {
              ...item,
              data: {
                value: value ?? item.data?.value,
                unit: unit ?? item.data?.unit,
              },
            };
          }
          return item;
        });

        return result;
      });
    }
  };

  const handleSelectElement = (element) => {
    let elmt;
    if (element?.isNew) {
      const foundAttribute = newAttributes?.find(
        (item) => item.detail === element.value
      );
      elmt = clone(foundAttribute);
      elmt.isNew = true;
      elmt.fieldType = elmt.fieldType.value;
    } else {
      const foundMeasurement = measurements?.find(
        (item) => item.detail === element.value
      );
      elmt = clone(foundMeasurement);
      elmt.id = uuidv4();
    }

    elmt.data = {};
    if (elmt.fieldType === "check-box") {
      elmt.data.value = "No";
    }
    setSelectedElements((prev) => [...prev, elmt]);
  };

  const handleCancel = () => {
    onCancel();
    setSelectedElements([]);
    setNewAttributes([]);
    setShowModal(false);
  };

  const handleAddElements = () => {
    const attributes = formattedAttributes(newAttributes);

    onAddElements(selectedElements, attributes);
    handleEditClick(true);
    selectedElements?.map((element) => {
      asset?.additionalInfo?.push({
        optionId: element?.detail,
        value: element?.data?.value,
      });
      return asset;
    });

    setSelectedElements([]);
    setShowModal(false);
  };

  const handleRemovElement = (detail) => {
    setSelectedElements((prev) =>
      prev.filter((item) => item.detail !== detail)
    );
  };

  const onAddAttribute = () => {
    setNewAttributes((prev) => [
      ...prev,
      {
        detail: "",
        id: uuidv4(),
        fieldType: {
          label: "Text",
          value: "text-entry",
        },
        group: "Custom",
      },
    ]);
  };

  const onChangeAttribute = (field, value, index) => {
    setNewAttributes((prev) =>
      prev.map((attribute, idx) => {
        if (idx === index) {
          if (field === "options") {
            return {
              ...attribute,
              options: value?.map((opt) => ({
                label: opt?.label,
                value: newIdAttributesGenerator(opt),
              })),
            };
          }
          if (field === "fieldType" && value?.hasUnits) {
            return {
              ...attribute,
              fieldType: value,
              unit: {
                fieldType: "ddl",
                options: [],
              },
              options: [],
            };
          }
          if (field === "unit") {
            return {
              ...attribute,
              unit: {
                ...attribute?.unit,
                options: value?.map((opt) => ({
                  label: opt?.label,
                  value: newIdAttributesGenerator(opt),
                })),
              },
            };
          }
          if (field === "detail") {
            return {
              ...attribute,
              detail: value,
            };
          }
          return {
            ...attribute,
            [`${field}`]: value,
            unit: null,
            options: [],
          };
        }
        return attribute;
      })
    );
  };

  const [saving, setSaving] = useState(false);
  const onSaveAttribute = async (index, att) => {
    setSaving(true);
    AttributeAPI.post(handleFormatAttributePostPayload(att))
      .then(() => {
        setNewAttributes((prev) =>
          prev.map((attribute, idx) => {
            if (idx === index) {
              return {
                ...attribute,
                saved: true,
              };
            }
            return attribute;
          })
        );

        const newAttribute = newAttributes?.find(
          (_attribute, idx) => idx === index
        );

        handleSelectElement({
          label: newAttribute?.detail,
          value: newAttribute?.detail,
          isNew: true,
        });

        setElementOptions((prev) => {
          const customGroupIndex = findIndex(prev, { label: "Custom" });
          if (customGroupIndex !== -1) {
            prev.splice(customGroupIndex, 1, {
              ...prev[customGroupIndex],
              options: [
                ...prev[customGroupIndex]?.options,
                {
                  label: newAttribute?.detail,
                  value: newAttribute?.detail,
                  isNew: true,
                },
              ],
            });
          } else {
            prev.push({
              label: "Custom",
              options: [
                {
                  label: newAttribute?.detail,
                  value: newAttribute?.detail,
                  isNew: true,
                },
              ],
            });
          }
          return prev;
        });
        loadAttributes();
        reloadAttributes();
        setSaving(false);
      })
      .catch((error) => {
        let errorMessage = "There was an error creating this attribute.";

        const isDulicatedAttribute = isLastErrorMessageFound(
          error,
          "expected `detail` to be unique"
        );

        if (isDulicatedAttribute) {
          errorMessage =
            "Attribute with this name already exists. Please use an unique name.";
        }

        toastError(errorMessage);

        setSaving(false);
      });
  };

  const onRemovAttribute = (index) => {
    setNewAttributes((prev) =>
      prev.filter((_attribute, idx) => {
        if (idx === index) {
          return false;
        }
        return true;
      })
    );

    const removedAttribute = newAttributes?.find(
      (_attribute, idx) => idx === index
    );

    setElementOptions((prev) =>
      prev.map((elemOption) => {
        if (elemOption?.label === "Misc") {
          // eslint-disable-next-line no-param-reassign
          elemOption.options = elemOption?.options?.filter(
            (option) => option?.label !== removedAttribute?.detail
          );
        }
        return elemOption;
      })
    );

    setSelectedElements((prev) =>
      prev.filter((item) => item.detail !== removedAttribute?.detail)
    );
  };

  const isValidNewAttribute = (attribute) => {
    let isValid = true;
    if (!attribute?.detail || attribute?.detail === "") {
      isValid = false;
    }

    if (!attribute?.fieldType?.value) {
      isValid = isValid && false;
    }

    if (attribute?.fieldType?.value === "ddl") {
      isValid = isValid && !!attribute?.options?.length;
    }

    if (attribute?.fieldType?.value === "number-entry-with-unit") {
      isValid = !!attribute?.unit?.options?.length;
    }

    return isValid;
  };

  return (
    <Modal
      id="modalId"
      title="Add Attributes"
      style={modalStyles}
      isOpen={showModal}
      onRequestModalClose={handleCancel}
      shouldCloseOnOverlayClick
      shouldCloseOnEsc
      alert
      hideFooter
    >
      <div
        ref={modalRef}
        className="flex flex-col justify-between w-192 overflow-y-visible"
        style={{ minHeight: "450px" }}
      >
        <div className="flex flex-col justify-between h-full">
          <div className="flex flex-col items-center pb-5">
            <Dropdown
              key="Add Existing Attribute"
              className="w-full relative"
              label="Existing Attribute"
              labelClassName="items-center mb-2"
              options={elementOptions}
              value=""
              onChange={handleSelectElement}
              disableClear
              tooltipData={tooltipData}
              tooltipDataWrapperClassName="mb-2"
              tootipCustomWidth={Math.round(windowWidth / 2)}
            />
          </div>
          <div className="flex flex-col justify-center items-start mb-2">
            {newAttributes?.map((attribute, idx) => (
              <div
                className="flex justify-between items-center mt-2"
                key={attribute?.id}
              >
                <div className="flex flex-row items-start w-full border">
                  <div className="w-64">
                    {!attribute?.saved && (
                      <Input
                        label="Name"
                        placeholder="Name"
                        onChange={(val) =>
                          onChangeAttribute("detail", val, idx)
                        }
                        value={attribute?.detail}
                        className="pr-2"
                        disabled={attribute?.saved}
                        validation={yup.string().required()}
                      />
                    )}
                  </div>
                  <div className="w-56">
                    {!attribute?.saved && (
                      <Dropdown
                        labelClassName=""
                        options={ATTRIBUTE_TYPES}
                        value={attribute?.fieldType}
                        onChange={(val) =>
                          onChangeAttribute("fieldType", val, idx)
                        }
                        className="pr-2"
                        isDisabled={attribute?.saved}
                        label="Type"
                        validation={yup.mixed().required()}
                        disableClear
                      />
                    )}
                  </div>
                  {attribute?.fieldType?.value === "ddl" &&
                    !attribute?.saved && (
                      <div className="w-56">
                        <Dropdown
                          isMulti
                          isCreatable
                          options={attribute?.options}
                          onChange={(values) =>
                            onChangeAttribute("options", values, idx)
                          }
                          value={attribute?.options}
                          disableClear
                          customStyle={customStyle}
                          placeholder="Select"
                          label="Options"
                          validation={yup.mixed().required()}
                        />
                      </div>
                    )}
                  {attribute?.unit && !attribute?.saved && (
                    <div className="w-56">
                      <Dropdown
                        isMulti
                        isCreatable
                        options={attribute?.unit?.options}
                        onChange={(values) =>
                          onChangeAttribute("unit", values, idx, "options")
                        }
                        value={attribute?.unit?.options}
                        disableClear
                        customStyle={customStyle}
                        placeholder="Select"
                        label="Options"
                        validation={yup.mixed().required()}
                      />
                    </div>
                  )}
                </div>
                {!attribute?.saved && (
                  <>
                    {!saving ? (
                      <div className="flex flex-row w-1/4 mt-5">
                        <button
                          type="button"
                          className={`text-brandGreen font-medium text-sm px-2 ${
                            !attribute.saved && isValidNewAttribute(attribute)
                              ? ""
                              : "invisible"
                          }`}
                          onClick={() => onSaveAttribute(idx, attribute)}
                        >
                          Save
                        </button>
                        <button
                          type="button"
                          className=""
                          onClick={() => onRemovAttribute(idx)}
                        >
                          <img
                            className="h-5 w-5"
                            src={collapseIcon}
                            alt="remove element"
                          />
                        </button>
                      </div>
                    ) : (
                      <div className="loading-circle-small absolute top-2 right-0" />
                    )}
                  </>
                )}
              </div>
            ))}
          </div>

          <div
            className={`flex flex-col mb-2 ${
              !!selectedElements.length && "border-b border-gray-150"
            }`}
          >
            <TertiaryButton
              title="+ New Attribute"
              onClick={() => onAddAttribute()}
              className="mb-2"
              disabled={saving}
            />
          </div>

          {!!selectedElements.length && (
            <div className="flex flex-col mb-8">
              {selectedElements.map(
                ({
                  id,
                  detail,
                  fieldType,
                  unit: units,
                  data,
                  options,
                  isNew,
                }) => {
                  const { value = "", unit: valueUnit = "" } = data;
                  const unitOptions = [];
                  let resultElmt;

                  if (units?.options?.length) {
                    units?.options?.forEach(({ name }) => {
                      if (isNew) {
                        unitOptions.push(name);
                      } else {
                        unitOptions.push({ label: name, value: name });
                      }
                    });
                  } else if (options?.length) {
                    options?.forEach(({ name }) => {
                      if (isNew) {
                        unitOptions.push(name);
                      } else {
                        unitOptions.push({ label: name, value: name });
                      }
                    });
                  }

                  switch (fieldType) {
                    case "number-entry":
                      resultElmt = (
                        <div
                          key={detail}
                          className="flex w-full justify-between"
                        >
                          <Input
                            className="flex-1"
                            label={detail}
                            placeholder="Number"
                            onChange={(val) =>
                              handleChange(detail, val, valueUnit, id)
                            }
                            // eslint-disable-next-line consistent-return
                            onKeyDown={(e) => {
                              if (getNumericOnly(e)) return e;
                            }}
                            value={value}
                            validation={yup
                              .number()
                              .transform((v, o) => (o === "" ? undefined : v))}
                            disableClear
                          />
                          {!!unitOptions?.length && (
                            <div className="flex w-full ml-4">
                              <Dropdown
                                className="w-64"
                                label="Unit"
                                placeholder="Select"
                                options={unitOptions}
                                onChange={({ value: changeValue }) =>
                                  handleChange(detail, value, changeValue, id)
                                }
                                value={unitOptions?.find(
                                  (opt) => opt.value === valueUnit
                                )}
                                disableClear
                              />
                            </div>
                          )}
                        </div>
                      );
                      break;
                    case "number-entry-with-unit":
                      resultElmt = (
                        <div
                          key={detail}
                          className="flex w-full justify-between"
                        >
                          <Input
                            className="flex-1"
                            label={detail}
                            placeholder="Number"
                            onChange={(val) =>
                              handleChange(detail, val, valueUnit, id)
                            }
                            value={value}
                            validation={yup
                              .number()
                              .transform((v, o) => (o === "" ? undefined : v))}
                            disableClear
                          />
                          {!!unitOptions?.length && (
                            <div className="flex w-full ml-4">
                              <Dropdown
                                className="w-64"
                                label="Unit"
                                placeholder="Select"
                                options={unitOptions}
                                onChange={({ value: changeValue, label }) =>
                                  handleChange(
                                    detail,
                                    value,
                                    isNew ? label : changeValue,
                                    id
                                  )
                                }
                                value={unitOptions?.find(
                                  (opt) => opt.label === valueUnit
                                )}
                                disableClear
                              />
                            </div>
                          )}
                        </div>
                      );
                      break;
                    case "date-picker":
                      resultElmt = (
                        <DatePicker
                          className="w-full"
                          key={detail}
                          value={value}
                          onChange={(val) =>
                            handleChange(detail, val, null, id)
                          }
                          label={detail}
                          validation={yup.date()}
                        />
                      );
                      break;
                    case "ddl":
                      {
                        const optionsDDL = isNew
                          ? options
                          : options?.map(({ name }) => ({
                              label: name,
                              value: name,
                            }));
                        resultElmt = (
                          <div className="flex-1">
                            <Dropdown
                              key={detail}
                              className="w-full"
                              label={detail}
                              placeholder="Select"
                              options={optionsDDL}
                              onChange={({ value: changeValue, label }) =>
                                handleChange(
                                  detail,
                                  isNew ? label : changeValue,
                                  null,
                                  id
                                )
                              }
                              value={optionsDDL?.find(
                                (opt) => opt.label === value
                              )}
                            />
                          </div>
                        );
                      }
                      break;
                    case "check-box":
                      resultElmt = (
                        <Checkbox
                          key={detail}
                          className="flex items-center text-gray-200 font-normal"
                          label={detail}
                          labelClassName="text-gray-200 font-normal"
                          checked={value === "Yes"}
                          onChange={(isChecked) =>
                            handleChange(
                              detail,
                              isChecked ? "Yes" : "No",
                              null,
                              id
                            )
                          }
                        />
                      );
                      break;
                    case "text-entry":
                    default:
                      resultElmt = (
                        <div
                          key={detail}
                          className="flex w-full justify-between"
                        >
                          <Input
                            className="flex-1"
                            label={detail}
                            placeholder="Text"
                            onChange={(val) =>
                              handleChange(detail, val, valueUnit, id)
                            }
                            value={value}
                            validation={yup.string()}
                            disableClear
                          />
                          {!!unitOptions?.length && (
                            <Dropdown
                              className="w-56 ml-4"
                              label="Unit"
                              placeholder="Select"
                              options={unitOptions}
                              onChange={({ value: changeValue }) =>
                                handleChange(detail, value, changeValue, id)
                              }
                              value={unitOptions?.find(
                                (opt) => opt.value === valueUnit
                              )}
                              disableClear
                            />
                          )}
                        </div>
                      );
                  }
                  return (
                    <div
                      className="flex items-end mt-2"
                      onMouseOver={() => handleHover(id)}
                      onMouseOut={() => handleHover()}
                      onFocus={() => handleHover(id)}
                      onBlur={() => handleHover()}
                      key={id}
                    >
                      {resultElmt}
                      <div
                        className={`flex justify-center items-center ml-4 ${
                          fieldType !== "check-box" ? "mb-2" : "-mb-1"
                        } ${hovering === id ? "" : "invisible"}`}
                      >
                        <button
                          type="button"
                          className="w-5 h-5 ml-8"
                          onClick={() => handleRemovElement(detail)}
                        >
                          <img src={collapseIcon} alt="remove element" />
                        </button>
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          )}
        </div>
        <div className="flex justify-end items-center mt-auto">
          <div className="flex">
            <TertiaryButton
              className="mr-2"
              title="Cancel"
              onClick={handleCancel}
            />
            <PrimaryButton
              className=""
              title="Add"
              disabled={!canAddAttributes()}
              onClick={handleAddElements}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

AssetAttributesModal.propTypes = {
  /**
   * create asset form state
   */
  // eslint-disable-next-line react/forbid-prop-types
  asset: PropTypes.object,
  /**
   * controls modal display
   */
  showModal: PropTypes.bool,
  /**
   * function that controls modal display
   */
  setShowModal: PropTypes.func,
  /**
   * function run when new elements are added
   */
  onAddElements: PropTypes.func,
  /**
   * function run on cancel button click
   */
  onCancel: PropTypes.func,
  newAttributes: PropTypes.arrayOf(PropTypes.shape({})),
  measurements: PropTypes.arrayOf(PropTypes.shape({})),
  setAttributes: PropTypes.func,
  setNewAttributes: PropTypes.func,
  handleEditClick: PropTypes.func,
  reloadAttributes: PropTypes.func,
};

AssetAttributesModal.defaultProps = {
  asset: undefined,
  showModal: false,
  setShowModal: undefined,
  onAddElements: undefined,
  onCancel: undefined,
  measurements: [],
  setAttributes: undefined,
  newAttributes: [],
  setNewAttributes: undefined,
  handleEditClick: () => {},
  reloadAttributes: () => {},
};

export default AssetAttributesModal;

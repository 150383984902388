import { TicketAPI } from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import { ticketsKeys } from "../config/reactQuery/queryKeyFactory";
import { toastMessage, toastError } from "../stories/Components/Toast/Toast";

/**
 * Delete single or multiple tickets
 * @param {string[]} ids ticket(s) id(s) to remove
 */
const deleteTickets = async (ids) => {
  if (!ids) return null;

  return TicketAPI.postWOP("$bulkdelete", { tickets: ids });
};

/**
 * Mutation hook to remove single/multiple tickets
 * @param {string} mutationKey (?) mutation key to track api call
 */
const useServiceRequestDelete = (mutationKey) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey,
    mutationFn: deleteTickets,
    onMutate: async (ids) => {
      await queryClient.cancelQueries(ticketsKeys.tickets);

      // Save reference of all query data that matches the query key ["tickets"]
      // This is going to be use in case of a possible error.
      const previousAllRequest = queryClient.getQueriesData(
        ticketsKeys.tickets
      );

      const idsToRemove = new Set(ids);

      if (previousAllRequest?.length) {
        /**
         * Remove ticket(s) from all posible queries
         * ["tickets"]                                  ===> Holds an array of tickets
         * ["tickets", "Property/1111-2222-3333-4444"]  ===> Holds an array of tickets
         * ["tickets", "id", 3333-6666-4444-8888]       ===> Holds an object (single ticket)
         */
        queryClient.setQueriesData(ticketsKeys.tickets, (current) => {
          if (Array.isArray(current)) {
            return current.filter((ticket) => !idsToRemove.has(ticket.id));
          }

          if (typeof current === "object" && idsToRemove.has(current.id)) {
            return undefined;
          }

          return current;
        });
      }

      // Returning context in case of any error
      return { previousAllRequest };
    },
    onSuccess: (_, ids) => {
      toastMessage(
        `${
          ids.length > 1 ? "Requests were" : "Request was"
        } deleted successfully`
      );
    },
    onError: (error, ids, context) => {
      const { previousAllRequest } = context ?? {};

      // Rollback ticket to previous state
      previousAllRequest.forEach(([key, value]) => {
        queryClient.setQueryData(key, value);
      });

      toastError(
        `${
          ids.length > 1 ? "Requests" : "Request"
        } could not be deleted. Please try again`
      );
      console.error("useServiceRequestDelete", error);
    },
    onSettled: () => {
      // Invalidate matching queries
      queryClient.invalidateQueries(ticketsKeys.tickets);
    },
  });
};

export default useServiceRequestDelete;

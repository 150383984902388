import React, { Fragment, useCallback, useState } from "react";
import cntl from "cntl";
import PropTypes from "prop-types";
import { HashLink } from "react-router-hash-link";
import Sidebar from "../../Sidebar/Sidebar";
import MiniMapHeader from "./MiniMapHeader";

const miniViewHeaderCN = () => cntl`
  text-es-sm
  truncate
  font-es-semibold
  text-es-medium-grey
  tracking-es-normal
`;

const closedSize = 44;

const TableMiniMapView = ({ miniMapContainerId, rows }) => {
  const [isOpen, setIsOpen] = useState(false);

  const groupedMiniViewHeader = useCallback(
    (row, step) => {
      const currentSize = 16;
      return (
        <div
          className="h-9 hover:bg-es-light-green pt-2"
          style={{
            paddingLeft: currentSize,
          }}
        >
          <HashLink
            className={miniViewHeaderCN(step)}
            to={`#${row.id}`}
            smooth
            replace
            duration={100}
            scroll={(el) => {
              // get the main scroll view
              // TODO: this won't work anywhere but tables directly nested in the MainContainer
              // for example, a model won't handle this scrolling properly right now
              const con = document.getElementById(
                miniMapContainerId || "mainContainerScroll"
              );
              const eleRect = el.getBoundingClientRect();
              const targetRect = con.getBoundingClientRect();
              const top = eleRect.top - targetRect.top;
              // need to apply an offset to put content under sticky header
              const yOffset = -40;
              con.scrollTo({
                top: con.scrollTop + (top + yOffset),
                behavior: "smooth",
              });
            }}
          >
            {row.groupByVal === "undefined" || !row.groupByVal
              ? "Not Specified"
              : row.groupByVal}
          </HashLink>
        </div>
      );
    },
    [miniMapContainerId]
  );

  const recursiveMiniMap = (tableRows, step = 0) => {
    return tableRows.map((row) => {
      if (row.isGrouped) {
        return (
          <Fragment key={row?.id}>
            {groupedMiniViewHeader(row, step)}
            {recursiveMiniMap(row.subRows, step + 1)}
          </Fragment>
        );
      }
      return <Fragment key={row?.id} />;
    });
  };

  return (
    <aside className="fixed z-10 top-1/4 flex flex-wrap right-0">
      <Sidebar
        isOpen={isOpen}
        header={<MiniMapHeader setIsOpen={setIsOpen} isOpen={isOpen} />}
        closedSize={closedSize}
        isSwitchHeader
        openSize="400"
        useAutoHeight
        width={isOpen ? 269 : 117}
        animationDimension="height"
      >
        <div className="absolute top-9 max-h-96 w-full overflow-y-scroll overflow-x-hidden bg-es-white border-es-light-grey border">
          <span>{recursiveMiniMap(rows)}</span>
        </div>
      </Sidebar>
    </aside>
  );
};

TableMiniMapView.propTypes = {
  miniMapContainerId: PropTypes.string,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      isGrouped: PropTypes.shape,
      subRows: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          isGrouped: PropTypes.shape,
          subRows: PropTypes.arrayOf(),
        })
      ),
    })
  ),
};

TableMiniMapView.defaultProps = {
  rows: [],
  miniMapContainerId: undefined,
};

export default TableMiniMapView;

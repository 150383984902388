import { RealTimeEvent } from "@griffingroupglobal/eslib-ably";
import useAddSpace from "../api/spaces/useAddSpace";
import useDeleteSpace from "../api/spaces/useDeleteSpace";
import useEditSpace from "../api/spaces/useEditSpace";

const useHandleSpaceRealTime = () => {
  const { addSpace } = useAddSpace();
  const { deleteSpace } = useDeleteSpace();
  const { editSpaceRtd } = useEditSpace();

  /**
   * Handles realtime space events from Ably and updates the space list accordingly
   * @param {object} message - the realtime message received from Ably
   * @param {string} message.name - the event name indicating the type of update
   * @param {object} message.data - the payload containing event-specific data
   * @param {object} message.data.changes - the added/updated space data
   * @param {string} message.data.target - the space's reference
   */
  const handleSpace = (message) => {
    const event = message.name;
    const { changes } = message.data;

    if (event === RealTimeEvent.ResourceAdded) {
      addSpace(changes, false);
      return;
    }

    if (event === RealTimeEvent.ResourceUpdated) {
      editSpaceRtd(changes);
      return;
    }

    if (event === RealTimeEvent.ResourceRemoved) {
      const { id, association } = changes;
      deleteSpace(id, association, false);
    }
  };

  return { handleSpace };
};

export default useHandleSpaceRealTime;

/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import PrimaryButton from "../Buttons/PrimaryButton";
import useProfileImageDropZone from "./useProfileImageDropZone";
import FormAvatar from "../Avatar/FormAvatar";

const profilePictureStyles = {
  minHeight: "435px",
  width: "1204px",
  paddingBottom: "66px",
  backgroundImage: `url(
      "data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23CACACAFF' stroke-width='2' stroke-dasharray='10%2c 20' stroke-dashoffset='53' stroke-linecap='square'/%3e%3c/svg%3e"
    )`,
  borderRadius: "10px",
};
const headerTextCN = "text-black text-es-lg font-es-semibold tracking-es-wide";

const ProfileDropZone = ({
  contact,
  onChange,
  tmpAvatarImg,
  setTmpAvatarImg,
}) => {
  const { getRootProps, getInputProps, onClick } = useProfileImageDropZone({
    onChange,
  });
  return (
    <div className="cursor-pointer" {...getRootProps()}>
      <input {...getInputProps()} />
      <div className="flex flex-col p-8" style={profilePictureStyles}>
        <div className="flex flex-col h-full w-full justify-between gap-8">
          <div className="w-full flex flex-row justify-start items-center">
            <p className={headerTextCN}>Profile Picture</p>
          </div>
          <div className="flex flex-col w-full self-center items-center gap-8">
            <div className="w-fit h-fit flex">
              <FormAvatar
                isEditing
                image={contact?.avatar}
                tmpAvatarImg={tmpAvatarImg}
                setTmpAvatarImg={setTmpAvatarImg}
                user
                width="120px"
                borderWidth="2px"
              />
            </div>
            <p className="text-es-lg text-black tracking-es-wide font-es-normal">
              Drop image to upload
            </p>

            <PrimaryButton
              style={{ width: "140px", height: "44px" }}
              className="flex self-center"
              title="Add Image"
              onClick={() => onClick()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDropZone;

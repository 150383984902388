import { useMemo, useState } from "react";
import { useAppState } from "../../../state/appState";
import useReportPatch from "../../../hooks/useReportPatch";
import useReportDispatcher from "../../../hooks/useReportDispatcher";
import useReportById from "../../../hooks/useReportById";
import { taskRecurrenceOptions } from "../../../helpers/Time";
import capitalizeFirstLetter from "../../../helpers/Utilities/capitalizeFirstLetter";

const useReportsEditModalData = ({ modalData }) => {
  const [{ users }] = useAppState();

  // Get a single report based on id
  const { data: currentReport = {} } = useReportById(modalData.reportId);

  // Report dispatcher hook
  const { reportForm, handleChangeForm, handleDistroMembers, handleTags } =
    useReportDispatcher(currentReport);

  // Mutation hook to patch Report on backend
  const { mutate: patchReport, isLoading: isPatching } = useReportPatch();

  const [isDistroSelectOpen, setDistroSelectOpen] = useState(false);
  const [humanReadableRecurrence, setHumanReadableRecurrence] = useState();

  // Get only member type users
  const members = useMemo(
    () => users?.filter((user) => user.kind === "member"),
    [users]
  );

  // Disable form if required fields are not populated or if mutation indicators are `true` (when patching Report)
  const disableForm = !reportForm.title.trim() || isPatching;

  /**
   * Update the current report
   */
  const handleUpdateReport = () => {
    // get the repeat RRule.
    const taskRecurrence = reportForm?.repeat?.includes("DTSTART:")
      ? // it's a custom repetition
        reportForm?.repeat
      : // it's not a custom repetition
        taskRecurrenceOptions?.find(
          ({ label }) => capitalizeFirstLetter(reportForm?.repeat) === label
        )?.value;

    const reportUpdated = { ...reportForm, repeat: taskRecurrence };

    patchReport({
      id: currentReport.id,
      report: currentReport,
      reportUpdated,
    });
  };

  return {
    reportForm,
    disableForm,
    members,
    isDistroSelectOpen,
    humanReadableRecurrence,
    setDistroSelectOpen,
    setHumanReadableRecurrence,
    handleChangeForm,
    handleDistroMembers,
    handleUpdateReport,
    handleTags,
  };
};

export default useReportsEditModalData;

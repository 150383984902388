import moment from "moment";
import { WF_STATUS_TYPES } from "../constants";
import { formatRequestStatus } from "./Formatters";

const handleWFCompDays = (start, end) => {
  const compDue = moment(end);
  const compStart = moment(start);
  const duration = compDue.diff(compStart, "days");
  const newDueDate = moment().add(duration, "days").format();
  return newDueDate;
};

export const isWorkflowStartDateInPast = (workflow) => {
  // if start date is in the past
  return moment(workflow?.startDate?.actual).isBefore(moment(), "day");
};

export const sortActivity = (a, b) => {
  return moment
    .utc(a.metadata.lastUpdated)
    .diff(moment.utc(b.metadata.lastUpdated));
};

export const formatWorkflowHistoryCardData = (
  roundData,
  index,
  workflow,
  workflowHistory,
  userDict
) => {
  // workflow round
  const roundNum = roundData?.round;

  // if the round matches index plus 1 it is the current round and has not been posted to workflow history yet
  const isCurrentRound = workflow?.rounds?.length === index + 1;

  // snapshot of workflow during that round
  const stepRequestWorkflow = isCurrentRound
    ? workflow?.requestWorkflow?.[0]
    : roundData?.requestWorkflow[0];

  // all attachments present during that round, cumulative
  const roundAttachments = [
    ...(stepRequestWorkflow?.steps?.map((step) => [
      ...(step?.attachments || []),
    ]) || []),
  ]
    .flat(Infinity)
    ?.filter((ref) => !!ref);

  // all actions taken during that round, from history api
  const roundActions = workflowHistory?.[roundData?.round];

  // first action that was taken on the round
  const durationStart = roundData?.startDate;

  // last action that was taken on the round
  const durationEnd = roundData?.endDate;

  const durationDays =
    durationEnd && moment(durationEnd).diff(moment(durationStart), "days") + 1;

  // all users that took action on the round by reference
  const roundMembers = [
    ...(stepRequestWorkflow?.steps?.map((step) => [
      ...(step?.users?.map((user) => user?.reference) || []),
    ]) || []),
  ]
    .flat(Infinity)
    ?.filter((ref) => !!ref);
  // only users, no duplicates
  const roundUsers = [...new Set(roundMembers)]?.map(
    (user) => userDict?.[user]
  );

  const initiatedBy = userDict?.[roundData?.initiatedBy];

  // maps step ids in action to the steps of that round by id, so all step data for the round is present
  const withSteps = roundActions?.reduce((acc, action) => {
    const step = stepRequestWorkflow?.steps?.find(
      (item) => item.id === action?.context?.workflow?.step?.id
    );
    // only show history items with step i.e. within rounds
    if (step) {
      // eslint-disable-next-line no-param-reassign
      acc = [...acc, { ...action, step }];
    }
    return acc;
  }, []);

  // sorts the activity in order of action taken, first by initiated then by created at
  const sortedActivity = withSteps?.sort(sortActivity);
  const actionsByStepMap =
    sortedActivity?.reduce((acc, curr) => {
      const key = curr.step?.position || 1;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(curr);
      return acc;
    }, {}) || {};

  return {
    roundNum,
    roundAttachments,
    durationStart,
    durationEnd,
    durationDays,
    roundUsers,
    isCurrentRound,
    actionsByStepMap,
    initiatedBy,
  };
};

const getBicUsers = (resource, userDict) => {
  if (
    resource?.status !== WF_STATUS_TYPES.DRAFT &&
    resource?.status !== WF_STATUS_TYPES.COMPLETED_RESOLVED &&
    resource?.status !== WF_STATUS_TYPES.WF_COMPLETED_OPEN
  ) {
    return resource?.ballInCourt?.map(
      ({ user }) => userDict?.[user] || undefined
    );
  }
  return [];
};

// Return Array of tasks associated to the wf
const getRelatedTasks = (requestWorkflow) => {
  const tasksRefArray = [];
  requestWorkflow.forEach((wf) => {
    // check for steps inside the wf
    if (wf?.steps?.length) {
      wf?.steps?.forEach(({ tasks }) => {
        // check for an empty array of tasks refs
        if (!tasks?.length) return;
        // removing from cache only requires id
        tasksRefArray.push(...tasks.map((item) => item.split("/")[1]));
      });
    }
  });
  return tasksRefArray;
};

export const formatTableData = (
  requestArray,
  userDict,
  projectDict,
  propertiesDict
) => {
  const formattedTableData = requestArray?.map((item) => {
    const isFreeFlow = item?.requestWorkflow?.[0]?.isFreeFlow;
    const bicUsers = getBicUsers(item, userDict);
    const initDate = moment(item?.initiated?.date).format("DD MMMM, YYYY");
    const initUser = userDict?.[item?.initiated?.user];
    const start = moment(item?.startDate?.actual).format("DD MMMM, YYYY");
    const targetComp = moment(item?.completion?.target).format("DD MMMM, YYYY");
    const projectedComp = isFreeFlow
      ? "N/A"
      : moment(item?.completion?.projected)
          .endOf("day")
          .format("DD MMMM, YYYY");
    const today = moment().endOf("day").format();
    const overOrUnder = moment(today).diff(projectedComp, "days");
    const budgetImpact = item?.impacts?.budget?.status || "unspecified";
    const scheduleImpact = item?.impacts?.schedule?.status || "unspecified";
    const cost = item?.impacts?.budget?.value;
    const days = item?.impacts?.schedule?.value;
    const status = formatRequestStatus(item);

    const resource = item?.association?.split("/")[0];
    let associationName;
    if (resource === "Project") {
      associationName = projectDict?.[item?.association]?.name;
    } else if (resource === "Property") {
      associationName = propertiesDict?.[item?.association]?.title;
    }

    return {
      id: item?.id,
      title: item?.customName,
      association: item?.association,
      associationName,
      ballInCourt: bicUsers,
      description: item?.description,
      status,
      isFreeFlow,
      initiated: item?.status !== WF_STATUS_TYPES.DRAFT ? initDate : "",
      initiatedBy:
        item?.status !== WF_STATUS_TYPES.DRAFT ? { user: initUser } : {},
      startDate: start,
      vendor: initUser?.companyName,
      duration: `${item?.duration?.numDays} Days`,
      targetCompletion: targetComp,
      projectedCompletion: projectedComp,
      blocked: "n/a",
      overUnder: `${
        // eslint-disable-next-line no-nested-ternary
        isFreeFlow
          ? "N/A"
          : overOrUnder <= 0
          ? `${Math.abs(overOrUnder)} days left`
          : `${overOrUnder} days overdue`
      }`,
      scheduleRef: scheduleImpact,
      budgetRef: budgetImpact,
      costImpact: cost ? `$ ${cost}` : "unspecified",
      scheduleImpact: days > 1 ? `${days || 0} Days` : `${days || 0} day`,
      completed: `${item?.status?.includes("completed") ? "yes" : "no"}`,
      tasks: getRelatedTasks(item?.requestWorkflow),
    };
  });
  return formattedTableData;
};

export default handleWFCompDays;

import React from "react";
import PropTypes from "prop-types";
import notesIcon from "../../../../assets/images/notes_icon.svg";
import notesIconGreen from "../../../../assets/images/notes-icon-dark-green.svg";
import IconButton from "../../../Buttons/IconButton";
import BaseButton from "../../../Buttons/BaseButton";
import crossIcon from "../../../../assets/images/crossIconGreen.svg";
import TableCellInput from "../../../Table/TableCellInput";
import useNotesCellData from "./useNotesCellData";

const NotesCell = ({ row, editedRow, setEditedRow, isEditingTable }) => {
  const { showPopup, wrapperRef, setShowPopup, onChange } = useNotesCellData({
    editedRow,
    setEditedRow,
  });

  if (!isEditingTable) {
    return (
      <div className="flex flex-row items-center" ref={wrapperRef}>
        <IconButton
          onClick={() => {
            if (row?.original?.notes?.length) {
              setShowPopup((prev) => !prev);
            }
          }}
          icon={row?.original?.notes?.length ? notesIconGreen : notesIcon}
        />
        {showPopup && (
          <div className="flex flex-col rounded-lg shadow-medium-lift border z-20 bg-white absolute p-2 w-72 top-8">
            <div className="flex flex-row w-full items-center">
              <div className="flex justify-center w-full pb-2">
                <p className="flex font-semibold text-gray-450 text-sm">
                  Notes
                </p>
              </div>
              <BaseButton
                onClick={() => {
                  setShowPopup(false);
                }}
                className="flex justify-end mr-1 mt-1 self-start"
                iconLeft={
                  <img
                    src={crossIcon}
                    alt="crossIcon"
                    style={{ height: 12, width: 12 }}
                  />
                }
              />
            </div>
            <div
              className="border-b w-full self-center"
              style={{ width: "92%" }}
            />
            <p className="break-words whitespace-normal text-gray-450 font-normal text-sm mt-3 mx-3 mb-3">
              {row?.original?.notes}
            </p>
          </div>
        )}
      </div>
    );
  }
  return (
    <div className="flex w-full">
      <TableCellInput value={editedRow?.notes} onChange={onChange} />
    </div>
  );
};

NotesCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      notes: PropTypes.string,
    }),
  }),
  editedRow: PropTypes.shape({
    notes: PropTypes.string,
  }),
  setEditedRow: PropTypes.func,
  isEditingTable: PropTypes.bool,
};

NotesCell.defaultProps = {
  row: undefined,
  setEditedRow: undefined,
  isEditingTable: false,
  editedRow: undefined,
};

export default NotesCell;

import React from "react";
import PropTypes from "prop-types";
import TableCellDropdown from "../../../Table/TableCellDropdown";
import useRateCellData from "./useRateCellData";

const RateCell = ({
  isEditingTable,
  rateSheetRates,
  unitsOfMeasure,
  setEditedRow,
  editedRow,
  row,
}) => {
  const { displayValue, getDropdownValue, onChange } = useRateCellData({
    unitsOfMeasure,
    rateSheetRates,
    editedRow,
    row,
    setEditedRow,
  });

  // View mode, if not editing
  if (!isEditingTable) {
    return (
      <div className="flex">
        <p>{displayValue}</p>
      </div>
    );
  }

  return (
    <>
      <div className="flex w-full">
        <TableCellDropdown
          value={getDropdownValue(editedRow?.rate)}
          options={rateSheetRates}
          onChange={onChange}
        />
      </div>
    </>
  );
};

RateCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      notes: PropTypes.string,
    }),
  }),
  editedRow: PropTypes.shape({
    rate: PropTypes.string,
  }),
  setEditedRow: PropTypes.func,
  isEditingTable: PropTypes.bool,
  rateSheetRates: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  unitsOfMeasure: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
};

RateCell.defaultProps = {
  row: undefined,
  setEditedRow: undefined,
  isEditingTable: false,
  editedRow: undefined,
  rateSheetRates: [],
  unitsOfMeasure: [],
};

export default RateCell;

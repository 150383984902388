import { RealTimeEvent } from "@griffingroupglobal/eslib-ably";
import { useQueryClient } from "react-query";
import { userKeys } from "../../config/reactQuery/queryKeyFactory";

const useHandleCurrentUserRealTime = () => {
  const queryClient = useQueryClient();

  /**
   * Handles realtime data changes in current user object from Ably and updates the current user accordingly
   * @param {object} message - the realtime message received from Ably
   * @param {string} message.name - the event name indicating the type of update
   */
  const handleCurrentUser = async (message) => {
    const event = message.name;

    if (event === RealTimeEvent.PermissionsUpdated) {
      await queryClient.invalidateQueries(userKeys.currentUser());
    }
  };

  return { handleCurrentUser };
};

export default useHandleCurrentUserRealTime;

import moment from "moment";
import calendarKeys from "../../hooks/api/calendar/calendarKeys";
import getReqParams from "./getReqParms";

const removeFromCalendarStore = ({
  queryClient,
  calendarData,
  variables,
  data,
}) => {
  queryClient.setQueryData(
    calendarKeys.calendarList(getReqParams(calendarData)),
    (oldData) => {
      // Note: if the calendar cache hasn't loaded yet initiate with array
      // Note this isn't likely because the calendar is the first page, but if someone followed a link that could create an issue
      if (!oldData) return [];

      if (variables.operation === "$non-recurring") {
        const { id: itemId } = variables?.args;
        if (!itemId) {
          return console.error(
            "ID doesnt exist in args. This was mistake in that non-recurring items make you use removeFromCalendarStore differently"
          );
        }

        const newEntriesArray = oldData.filter(
          (event) => event.resource.id !== itemId
        );

        return newEntriesArray;
      }

      if (variables.operation === "$all") {
        const { id: itemId } = variables?.args?.item || {};

        if (!itemId) return console.error("Required id was not present");

        const newEntriesArray = oldData.filter((event) => {
          return event.resource.id !== itemId;
        });

        return newEntriesArray;
      }

      if (variables.operation === "$single") {
        const { metadata } = data;
        const { id: itemId, instanceStartDate: itemDate } =
          variables?.args?.item || {};

        if (!itemId || !itemDate || !metadata) {
          return console.error("required items were not present");
        }

        /**
         * If the id matches then we should update metadata (needs lastUpdated property),
         * if the id and instantStartDate match - remove from items (matches criteria to remove)
         * if no matches add to list unedited (is not part of the recurrence chain)
         */
        const newEntryArray = oldData.reduce((acc, item) => {
          if (
            item.resource.id === itemId &&
            item.resource.instanceStartDate !== itemDate
          ) {
            acc.push({
              ...item,
              resource: {
                ...item.resource,
                metadata,
              },
            });
          }

          if (item.resource.id !== itemId) acc.push(item);

          return acc;
        }, []);

        return newEntryArray;
      }

      if (variables.operation === "$future") {
        const { metadata } = data;
        const { id: itemId, instanceStartDate: itemDate } =
          variables?.args?.item || {};

        if (!itemId || !itemDate || !metadata) {
          return console.error("required items were not present");
        }

        const newEntryArray = oldData.reduce((acc, item) => {
          /**
           * if the item matches the id and is before the delete date, update metadata
           * if the ids match and is || is after deleteDate remove
           * if the items id doesn't match, leave unchanged
           */
          if (
            item.resource.id === itemId &&
            !moment(item.resource.startDate).isSameOrAfter(moment(itemDate))
          ) {
            acc.push({
              ...item,
              resource: {
                ...item.resource,
                metadata,
              },
            });
          }

          if (item.resource.id !== itemId) {
            acc.push(item);
          }

          return acc;
        }, []);

        return newEntryArray;
      }
      return oldData;
    }
  );

  return data;
};

export default removeFromCalendarStore;

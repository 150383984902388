import getSortCategory from "./getSortCategory";

/**
 * Sort an array of objects based on a specified property.
 * @param {Object[]} arr - The array of objects to be sorted.
 * @param {string} property - The given property of the objects used for sorting.
 * @returns {Object[]} The sorted array of objects.
 */
const sortByProperty = (arr = [], property = "") => {
  return arr.sort((a, b) => {
    const valueA = a[property] || "";
    const valueB = b[property] || "";

    const categoryA = getSortCategory(valueA);
    const categoryB = getSortCategory(valueB);

    if (categoryA === categoryB) {
      const normalizedValueA = valueA.toLowerCase();
      const normalizedValueB = valueB.toLowerCase();

      if (normalizedValueA < normalizedValueB) return -1;
      if (normalizedValueA > normalizedValueB) return 1;
      return 0;
    }

    return categoryA - categoryB;
  });
};

export default sortByProperty;

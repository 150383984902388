import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import AttributeWidget from "../../../stories/Components/Attributes/AttributeWidget";
import LinksView from "../../../stories/Components/Links/LinksView";
import AddressWidget from "../../../stories/Components/Widgets/AddressWidget";
import PropertyOverview from "../../../stories/Components/PropertyOverview/PropertyOverview";
import MembersView from "../../../stories/Components/Members/MembersView";
import VendorView from "./VendorView";

const PropertyDetailView = ({
  editedProperty: property,
  newAttributes,
  setNewAttributes,
  measurements,
  dispatch,
  editing,
  loading,
  onChange,
  config,
  disableEditing,
  memberLoading,
  setMemberLoading,
}) => {
  const [propertyTypes, setPropertyTypes] = useState([]);

  useEffect(() => {
    if (config) {
      setPropertyTypes(
        config.property.types
          .filter((type) => type.selected)
          .map((type) => ({
            label: type.display,
            value: type.id,
          }))
      );
    }
  }, [config]);

  const handleAddressInput = (val) => {
    dispatch({
      type: "address",
      address: val,
    });
  };

  const handleMailingSameAsPhysicalInput = (val) => {
    dispatch({
      type: "mailingSameAsPhysical",
      mailingSameAsPhysical: val,
    });
  };

  const attributeInformation = useMemo(() => {
    return (
      <AttributeWidget
        resource={property}
        dispatch={dispatch}
        editing={editing}
        loading={loading}
        disableEditing={disableEditing}
        setNewAttributes={setNewAttributes}
        measurements={measurements}
        newAttributes={newAttributes}
        style={{ height: "275px" }}
      />
    );
  }, [
    disableEditing,
    dispatch,
    editing,
    loading,
    measurements,
    newAttributes,
    property,
    setNewAttributes,
  ]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col w-full h-min" id="about-property-row-1">
        <PropertyOverview
          property={property}
          propertyTypes={propertyTypes}
          loading={loading}
          editing={editing}
          disableEditing={disableEditing}
          onChange={(field, val) => {
            onChange(field, val);
          }}
          dispatch={dispatch}
        />
        <AddressWidget
          resource={property}
          loading={loading}
          isEditing={editing}
          handleMailingSameAsPhysicalInput={handleMailingSameAsPhysicalInput}
          handleAddressInput={handleAddressInput}
          dispatch={dispatch}
        />
        <div className="flex w-full min-h-full mb-4 gap-4">
          <MembersView
            resource={property}
            dispatch={dispatch}
            editing={editing}
            isObjectLoading={loading}
            setMemberLoading={setMemberLoading}
            memberLoading={memberLoading}
          />
          <VendorView
            resource={property}
            dispatch={dispatch}
            editing={editing}
            isPropertyLoading={loading}
            setMemberLoading={setMemberLoading}
            memberLoading={memberLoading}
          />
          <LinksView
            resource={property}
            disableEditing={disableEditing}
            dispatch={dispatch}
            editing={editing}
            loading={loading}
            widgetWidth="1/3"
          />
        </div>
      </div>
      <div className="grid grid-cols-2 w-full gap-4">
        {attributeInformation}
      </div>
    </div>
  );
};

PropertyDetailView.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  editedProperty: PropTypes.object,
  measurements: PropTypes.arrayOf(PropTypes.shape({})),
  setNewAttributes: PropTypes.func,
  newAttributes: PropTypes.arrayOf(PropTypes.shape({})),
  dispatch: PropTypes.func,
  editing: PropTypes.bool,
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  config: PropTypes.objectOf({
    property: PropTypes.objectOf({
      types: PropTypes.arrayOf({
        selected: PropTypes.bool,
        display: PropTypes.string,
        id: PropTypes.string,
      }),
    }),
  }),
  disableEditing: PropTypes.bool,
  memberLoading: PropTypes.bool,
  setMemberLoading: PropTypes.func,
};

PropertyDetailView.defaultProps = {
  editedProperty: undefined,
  measurements: [],
  setNewAttributes: undefined,
  newAttributes: [],
  dispatch: undefined,
  onChange: undefined,
  editing: false,
  loading: true,
  config: undefined,
  disableEditing: false,
  memberLoading: true,
  setMemberLoading: () => {},
};

export default PropertyDetailView;

import { propertyKeys } from "../../../properties";
import deletePropertyListStore from "../../../../helpers/Properties/deletePropertyListStore";
import deletePropertyOverviewStore from "../../../../helpers/Properties/deletePropertyOverviewStore";

const deletePropertyResponder = (queryClient) => {
  return {
    onSuccess: (returnedData, id) => {
      deletePropertyListStore({ queryClient, id });
      deletePropertyOverviewStore({ queryClient, id });
      queryClient.removeQueries({ queryKey: propertyKeys.byId(id) });
    },
    onError: (err) => {
      console.error("Failed to delete property", err);
    },
  };
};

export default deletePropertyResponder;

import { useCallback } from "react";
import { evaluate } from "mathjs";

const useCalculationCellData = ({
  isHighRangeField,
  editedRow,
  setEditedRow,
}) => {
  const isHidden = isHighRangeField && !editedRow?.isCostRange;

  const value = isHighRangeField
    ? editedRow?.highRange?.calculation
    : editedRow?.calculation;
  const onCalculationChange = useCallback(
    (newVal) => {
      let quantity = 0;
      try {
        quantity = evaluate(newVal);
        quantity = quantity > 0 ? quantity : 0;
      } catch (err) {
        console.warn(err);
      }

      if (isHighRangeField) {
        if (editedRow?.highRange?.costPerUnit) {
          const liveBudget = editedRow.highRange.costPerUnit * (quantity || 0);
          setEditedRow({
            ...editedRow,
            highRange: {
              ...editedRow.highRange,
              calculation: newVal,
              quantity,
              liveBudget,
            },
          });
        } else {
          setEditedRow({
            ...editedRow,
            highRange: {
              ...editedRow.highRange,
              calculation: newVal,
              quantity,
            },
          });
        }
      } else if (editedRow.costPerUnit) {
        const liveBudget = editedRow.costPerUnit * (quantity || 0);
        setEditedRow({
          ...editedRow,
          calculation: newVal,
          quantity,
          liveBudget,
        });
      } else {
        setEditedRow({
          ...editedRow,
          calculation: newVal,
          quantity,
        });
      }
    },
    [editedRow, isHighRangeField, setEditedRow]
  );

  return {
    isHidden,
    value,
    onCalculationChange,
  };
};

export default useCalculationCellData;

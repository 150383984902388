import React from "react";
import PropTypes from "prop-types";
import Avatar from "../../Message/Avatar";
import { getUserFirstAndLast } from "../../../../helpers/Utilities";
import Checkbox from "../../Checkbox/Checkbox";
import ModalWrapper from "../../EsModal/ModalWrapper";
import useRemoveUserModal from "./useRemoveUserModal";

const rowItemCN =
  "flex flex-row max-w-full items-center gap-2 border px-2 py-2 rounded-xl hover:shadow-light-lift";
const rowCN =
  "flex flex-row flex-wrap justify-center gap-4 pt-8 pl-4 pb-2 overflow-y-scroll";
const container = "flex flex-col justify-center w-full overflow-visible";
const textCN =
  "text-es-normal text-es-medium-grey font-es-normal tracking-es-normal";

const RemoveUserModal = ({ modalData }) => {
  const {
    selectedUsers,
    data,
    onSave,
    listToChange,
    isChecked,
    handleCheckbox,
    headerText,
    modalTitle,
  } = useRemoveUserModal({ modalData });

  return (
    <ModalWrapper
      width="40vw"
      resourceName={modalTitle}
      modalData={data}
      primaryButtonTitle="Delete"
      primaryButtonOnClick={() => onSave(listToChange)}
      tertiaryButtonTitle="Cancel"
      disabled={listToChange.length === 0}
      alert
    >
      <div className={container}>
        <p className={`${textCN} self-center mb-2`}>{headerText}</p>
        <div style={{ maxHeight: "40vh" }} className={rowCN}>
          {selectedUsers?.map((item) => (
            <div key={item.id} className={rowItemCN}>
              <Checkbox
                size={6}
                checked={isChecked(item)}
                onChange={(val) => handleCheckbox(val, item)}
              />
              <Avatar
                name={getUserFirstAndLast(item)}
                alt="user-avatar"
                avatar={item?.avatar}
                isExternalUser
                kind={item.kind}
                className="h-10 w-10"
              />
              <p className={`${textCN} truncate`}>
                {getUserFirstAndLast(item)}
              </p>
            </div>
          ))}
        </div>
      </div>
    </ModalWrapper>
  );
};

RemoveUserModal.propTypes = {
  modalData: PropTypes.shape({
    item: PropTypes.shape({}),
  }),
};

RemoveUserModal.defaultProps = {
  modalData: undefined,
};

export default RemoveUserModal;

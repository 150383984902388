import cntl from "cntl";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";

import moment from "moment";
import useReactRouterBreadcrumbs from "use-react-router-breadcrumbs";
import { MiniChat } from "@griffingroupglobal/lumina-react";
import PropTypes from "prop-types";
import LilyProvider from "../context/Lily/LilyProvider";
import useIsSignedIn from "../hooks/useIsSignedIn";

import ExpenseCreateModal from "../stories/Components/ExpenseForm/ExpenseCreateModal";
import TopNavBar from "../stories/Components/NavBar/TopNavBar";
import RouteSidebar from "../stories/Components/RouteSidebar/RouteSidebar";

import { routeConfig } from "../routes";
import useCurrentUser from "../hooks/useCurrentUser";
import PopoverList from "../stories/Components/PopoverList/PopoverList";

import {
  CALENDAR_PATH,
  MIN_PAGE_WIDTH,
  MY_PROFILE,
  RESET_TIMESHEET_STATE,
  SET_MAIN_REF,
  SET_SCREEN_SIZE,
  ADMIN_PATH,
  ACCOUNTANDADMIN_TABS,
  MYPROFILE_TABS,
} from "../constants";
import { useAssetsOverviewPrefetch } from "../hooks/assets";
import { usePropertiesOverviewPrefetch } from "../hooks/properties";
import useDocumentsConfiguration from "../hooks/useDocumentsConfiguration";
import useEvents from "../hooks/useEvents";
import useFinancialsConfiguration from "../hooks/useFinancialsConfiguration";
import useManagementConfiguration from "../hooks/useManagementConfiguration";
import useRoles from "../hooks/useRoles";
import useRolesWOP from "../hooks/useRolesWOP";
import { useSubmittals } from "../hooks/useSubmittal.new";
import useSubscription from "../hooks/useSubscription";
import useSystemConfiguration from "../hooks/useSystemConfiguration";
import { useTagsPrefetch } from "../hooks/useTags";
import { useUsers } from "../hooks/useUsers.new";
import useUsersStreamInfo from "../hooks/useUsersStreamInfo";
import { useAppState } from "../state/appState";
import { useModalState } from "../state/modalState";
import MaintenanceScheduleModal from "../stories/Components/AssetForms/MaintenanceScheduleModal";
import Chevron from "../stories/Components/Buttons/Chevron";
import EsModal from "../stories/Components/EsModal";
import EsPopup from "../stories/Components/EsPopup";
import Tenants from "../stories/Components/NavBar/Tenants";
import NotificationTray from "../stories/Components/NotificationSlider/NotificationTray";
import RfiCreateModal from "../stories/Components/RfiForm/RfiCreateModal";
import ServiceRequestsModal from "../stories/Components/ServiceRequestsModal";
import SubmittalsCreateModal from "../stories/Components/Submittals/SubmittalsModal/SubmittalsCreateModal";
import WorkflowCreateModal from "../stories/Components/WorkflowModal/WorkflowCreateModal";
import MessagingTray from "./Home/Message/MessagingTray";
import TodoTray from "./Home/TodoTray/TodoTray";
import PropertyCreateModal from "../stories/Components/EsModal/CreatePropertyModal";
import CreateContactModal from "../stories/Components/EsModal/CreateContactModal";
import useRealtimeQueryUpdater from "../hooks/realtime/useRealtimeQueryUpdater";
import usePageBreakpoints from "../hooks/usePageBreakpoints";
import useAppPersistence from "../hooks/persistence/useAppPersistence";

const userAvatarDropdownMenuItemCN = cntl`
  w-full
  text-gray-450
  font-semibold
  text-sm
  tracking-wider
  -mt-1.5
  min-w-full
`;

const headerDropdownMenuItemCN = cntl`
  w-full
  text-gray-450
  font-medium
  text-xs
  tracking-wider
  min-w-full
`;

const MainContainer = ({ isLoading, isRegistered }) => {
  const history = useHistory();
  const location = useLocation();
  const { data: currentUser } = useCurrentUser();
  const { getActiveTab } = useAppPersistence();

  const [, setIsSignedIn] = useIsSignedIn();

  const { prefetchPropertiesOverview } = usePropertiesOverviewPrefetch();
  const { prefetchAssetsOverview } = useAssetsOverviewPrefetch();

  // Subscribed to tenant changes
  useRealtimeQueryUpdater();

  useUsers();
  // Prefetch overviews
  prefetchPropertiesOverview();
  prefetchAssetsOverview();
  useTagsPrefetch();

  const [showHeaderMenu, setShowHeaderMenu] = useState(false);
  const [usersStreamInfo] = useUsersStreamInfo();
  const [channels, setChannels] = useState([]);
  const { data: systemConfiguration } = useSystemConfiguration();
  const { data: originalSubscription } = useSubscription();
  const [allRoles] = useRoles();
  const [rolesWOP] = useRolesWOP();
  const { breakpoint } = usePageBreakpoints();

  const [
    {
      contactModalStatus,
      propertyModalStatus,
      submittalModalStatus,
      requestsModalStatus,
      workflowModalStatus,
    },
    dispatch,
  ] = useAppState();

  useEffect(() => {
    dispatch({
      type: SET_SCREEN_SIZE,
      screenSize: breakpoint,
    });
  }, [dispatch, breakpoint]);

  /**
   * Main Container Element Refs
   */
  const mainContainerScroll = useRef();

  const handleMainContainerRef = useCallback(
    (el) => {
      mainContainerScroll.current = el;
      dispatch({
        type: SET_MAIN_REF,
        ref: mainContainerScroll.current,
      });
    },
    [dispatch]
  );

  useDocumentsConfiguration();
  const [, reloadEvents] = useEvents({
    startDate: moment()
      .startOf("month")
      .startOf("day")
      .subtract(3, "months")
      .format(),
    endDate: moment().endOf("month").endOf("day").add(3, "months").format(),
    user: currentUser?.reference,
  });

  const { data: managementConfiguration } = useManagementConfiguration();
  const { data: financialsConfiguration } = useFinancialsConfiguration();

  const { data } = useSubmittals();

  const submittals = data?.submittals ?? [];

  const breadcrumbs = useReactRouterBreadcrumbs(routeConfig(), {
    excludePaths: [
      "/",
      "/properties/:propertyId/asset",
      "/projects/:projectId/asset",
      "/properties/:propertyId/calendar",
      "/projects/:projectId/calendar",
      "*/profile/:currentTabIndex",
      "/sops/:sopId",
      "/profile/contacts/:companyId",
      "/approve_time/:userId",
      "/approve_time/card/:userId/:date",
      "/approve_time/card/:userId",
      "/timecard_history/:timesheetId",
      "/timeoff",
      "/myprofile",
      "/myprofile/:userId",
    ],
  });
  const [showMessages, setShowMessages] = useState(false);
  const [showNotifications, setShowNotifications] = useState();
  const [showTodo, setShowTodo] = useState(false);

  const [openNewExpenseModal, setOpenNewExpenseModal] = useState(false);
  const [openNewMaintenanceModal, setOpenNewMaintenanceModal] = useState(false);
  const [messageCount, setMessageCount] = useState(0);

  /**
   * Reset Context on logout
   * Timecards, Modals
   */
  const [, modalStateDispatch] = useModalState();

  const onLogout = useCallback(async () => {
    setIsSignedIn(false);
    /**
     * Reset Modal State
     * - Removes open modals from session
     */
    modalStateDispatch({ type: "reset" });
    /**
     * Reset Timesheet State
     * - Returns timesheet overview to initial state
     */
    dispatch({ type: RESET_TIMESHEET_STATE });
  }, [dispatch, modalStateDispatch, setIsSignedIn]);

  const onLogoClick = () => {
    history.push(CALENDAR_PATH);
  };

  const closeHeaderMenu = useCallback(() => {
    setShowHeaderMenu(false);
    document.removeEventListener("click", closeHeaderMenu);
  }, []);

  // ref to hold the function that will close the header menu
  const onShowHeaderRef = useRef(null);

  useEffect(() => {
    return () => {
      // clear timer on unmount
      if (onShowHeaderRef.current) clearTimeout(onShowHeaderRef.current);
    };
  }, []);

  const onShowHeaderMenuClick = () => {
    if (showHeaderMenu) {
      closeHeaderMenu();
    } else {
      setShowHeaderMenu(true);
      // need to delay setting the listener because the click that called this function will
      // also trigger the listener if its added without a delay
      onShowHeaderRef.current = setTimeout(
        () => document.addEventListener("click", closeHeaderMenu),
        10
      );
    }
  };

  const selectDropdownItem = useCallback(
    (onClick) => () => {
      closeHeaderMenu();
      onClick();
    },
    [closeHeaderMenu]
  );

  const profileDropdownItems = useCallback(() => {
    const items = [];

    if ((currentUser?.isAdmin || currentUser?.isSuperAdmin) && isRegistered) {
      items.push({
        title: "Account & Admin",
        className: userAvatarDropdownMenuItemCN,
        hoverColorCN: "text-green-400",
        onClick: selectDropdownItem(() =>
          history.push(
            `${ADMIN_PATH}?tab=${
              getActiveTab({
                page: "account-admin",
              }) || ACCOUNTANDADMIN_TABS.SETTINGSANDCONFIG_ID
            }`
          )
        ),
      });
    }

    if (isRegistered) {
      items.push(
        {
          title: "My Profile",
          className: userAvatarDropdownMenuItemCN,
          hoverColorCN: "text-green-400",
          onClick: selectDropdownItem(() =>
            history.push(`${MY_PROFILE}?tab=${MYPROFILE_TABS.MYDETAILS_ID}`)
          ),
        },
        {
          title: "divider1",
          type: "divider",
          className: "border-gray-200",
        },
        {
          title: "TENANT",
          type: "header",
          className: `${headerDropdownMenuItemCN}`,
          wrapperClassName: "bg-backgroundGrey",
          onClick: () => {},
          options: <Tenants />,
        },
        {
          title: "divider2",
          type: "divider",
          className: "border-gray-200 mb-2",
        }
      );
    }

    items.push({
      title: "Log Out",
      className: userAvatarDropdownMenuItemCN,
      hoverColorCN: "text-green-400",
      onClick: onLogout,
    });

    return items;
  }, [
    currentUser?.isAdmin,
    currentUser?.isSuperAdmin,
    isRegistered,
    onLogout,
    selectDropdownItem,
    history,
    getActiveTab,
  ]);

  /**
   * Open/Close modal messages and remove selected channel
   * if any active in the ESChatContext
   * @param {Boolean} state open/close
   */
  const handleSetShowMessages = (state) => {
    setShowMessages(state);
  };

  useEffect(() => {
    return () => {
      window.removeEventListener("click", closeHeaderMenu);
    };
  }, [closeHeaderMenu]);

  /**
   * @TODO - Password Modal outside provider
   */
  return (
    <div className="flex flex-col h-screen relative">
      <TopNavBar
        onLogoClick={onLogoClick}
        initialChannels={channels}
        setInitialChannels={setChannels}
        currentUser={currentUser}
        showUserMenu={showHeaderMenu}
        closeMenu={closeHeaderMenu}
        onShowMenuClick={onShowHeaderMenuClick}
        headerMenuItems={<PopoverList items={profileDropdownItems()} />}
        hideGlobalSearch={!isRegistered}
        hideMessages={!isRegistered}
        hideNotifications={
          !isRegistered && currentUser?.permissions?.ticket?.can_create
        }
        setShowNotifications={setShowNotifications}
        showNotifications={showNotifications}
        hideTodos={!isRegistered}
        hideServiceTicket={!isRegistered}
        showMessages={showMessages}
        setShowMessages={handleSetShowMessages}
        showTodo={showTodo}
        setShowTodo={setShowTodo}
        messageCount={messageCount}
        setMessageCount={setMessageCount}
      />

      {!isLoading && isRegistered && (
        <div className="flex h-full relative overflow-auto">
          {isRegistered && (
            <RouteSidebar
              activeRoute={location.pathname}
              setOpenNewExpenseModal={setOpenNewExpenseModal}
              setOpenNewMaintenanceModal={setOpenNewMaintenanceModal}
            />
          )}
          <div
            id="mainContainerScroll"
            className="h-full w-full overflow-y-scroll overflow-x-auto bg-white py-6 px-8"
            ref={handleMainContainerRef}
          >
            <div className="h-5 text-sm text-gray-200 mb-6">
              {breadcrumbs.length > 1 && (
                <div className="flex">
                  {breadcrumbs
                    .slice(0, breadcrumbs.length - 1)
                    .map(({ breadcrumb, match }, index) => {
                      return (
                        <NavLink key={match.path} to={match.url}>
                          <div
                            className={`flex items-center ${
                              index !== 0 ? "ml-2" : ""
                            }`}
                          >
                            <Chevron rotate="-180" color="#757575" />
                            <p
                              style={{ fontSize: "16px" }}
                              className="text-gray-650 font-medium ml-2"
                            >
                              {breadcrumb}
                            </p>
                          </div>
                        </NavLink>
                      );
                    })}
                </div>
              )}
            </div>

            <Switch>
              {/** Must be placed at the top */}
              <Route exact path="/">
                <Redirect to={CALENDAR_PATH} />
              </Route>

              {routeConfig(
                currentUser,
                financialsConfiguration?.financials
              ).map(({ path, Component, exact }) => (
                <Route key={path} path={path} exact={exact}>
                  <div style={{ minWidth: MIN_PAGE_WIDTH }}>
                    <Component
                      rolesWOP={rolesWOP}
                      setShowMessages={setShowMessages}
                      currentUser={currentUser}
                      systemConfiguration={systemConfiguration}
                      usersStreamInfo={usersStreamInfo}
                      reloadEvents={reloadEvents}
                      openNewExpenseModal={openNewExpenseModal}
                      setOpenNewExpenseModal={setOpenNewExpenseModal}
                      allRoles={allRoles}
                      submittals={submittals}
                      originalSubscription={originalSubscription}
                      setOpenNewMaintenanceModal={setOpenNewMaintenanceModal}
                      openNewMaintenanceModal={openNewMaintenanceModal}
                      managementConfiguration={managementConfiguration}
                    />
                  </div>
                </Route>
              ))}
            </Switch>
          </div>
        </div>
      )}
      {openNewExpenseModal && (
        <ExpenseCreateModal
          onCloseModal={() => setOpenNewExpenseModal(false)}
          navigateTo={history?.location?.pathname}
        />
      )}
      {openNewMaintenanceModal && (
        <MaintenanceScheduleModal
          onCloseModal={() => setOpenNewMaintenanceModal(false)}
        />
      )}
      {(contactModalStatus?.open || contactModalStatus?.invite) && (
        <CreateContactModal />
      )}
      {propertyModalStatus.open && <PropertyCreateModal />}
      {submittalModalStatus?.open &&
        !submittalModalStatus?.edit &&
        !submittalModalStatus?.complete && (
          <SubmittalsCreateModal
            disableAssociation={
              location.pathname.split("/")[1] === "projects" &&
              typeof location.pathname.split("/")[2] === "string"
            }
          />
        )}
      {workflowModalStatus?.open &&
        !workflowModalStatus?.edit &&
        !workflowModalStatus?.complete && (
          <WorkflowCreateModal
            disableAssociation={
              workflowModalStatus?.isQuickAdd
                ? false
                : (location.pathname.split("/")[1] === "projects" ||
                    location.pathname.split("/")[1] === "properties") &&
                  typeof location.pathname.split("/")[2] === "string"
            }
          />
        )}
      <RfiCreateModal />
      <EsPopup reloadEvents={reloadEvents} />
      <EsModal reloadEvents={reloadEvents} />

      {requestsModalStatus.open && <ServiceRequestsModal />}
      {/* RightSliderItems */}

      <TodoTray open={showTodo} close={() => setShowTodo(false)} />

      <NotificationTray
        open={showNotifications}
        close={() => setShowNotifications(false)}
      />

      <MessagingTray open={showMessages} close={() => setShowMessages(false)} />

      {managementConfiguration?.management?.lily?.isEnabled && (
        <LilyProvider>
          <MiniChat />
        </LilyProvider>
      )}
    </div>
  );
};

MainContainer.propTypes = {
  isLoading: PropTypes.bool,
  isRegistered: PropTypes.bool,
};

MainContainer.defaultProps = {
  isLoading: true,
  isRegistered: false,
};

export default MainContainer;

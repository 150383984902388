import React from "react";
import PropTypes from "prop-types";

import IconButton from "../Buttons/IconButton";
import Widget from "../Widget/Widget";
import trashDarkenedGreenIcon from "../../assets/images/trashDarkenedGreenIcon.svg";
import Tasks from "./Tasks";
import useWorkflowStepTasksData from "./useWorkflowStepTasksData";
import Media from "./Media";
import Attachments from "./Attachments";
import {
  TASK,
  WF_STATUS_TYPES,
  WF_STEP_ACTION_STATUS,
} from "../../../constants";
import BackButton from "../WidgetEditorView/Header/BackButton";
import hasUpdatePermission from "../../../helpers/Permissions/hasUpdatePermission";
import SingleTaskView from "../TaskTable/SingleTaskView";
import Pill from "../Pill/Pill";
import { statusPillCN } from "../../../helpers/ServiceRequest/formatTasks";

export default function WorkflowStepTasks({
  stepTasksViewData,
  setStepTasksViewData,
  association,
  workflow,
  requestFormDispatch,
  onPatch,
  editedWorkflow,
  workflowForm,
  setDisableEditWF,
  onStepAction,
  setShowStepDetails,
  getStepStatus,
  editing,
  actionTaken,
  refetchWorkFlow,
}) {
  const {
    assignee,
    handleAddTasks,
    usedSopDict,
    currentTask,
    value,
    label,
    reference,
    currentUser,
    filesState,
    editableWFState,
    formattedTasks,
    setCurrentTask,
    handleFilesToAdd,
    handleFilesToRemove,
    handleVoidClick,
    openConfirmDeleteStepModal,
    openConfirmDeleteTaskModal,
    showVoidButton,
    handleBackToWorkflow,
  } = useWorkflowStepTasksData({
    requestFormDispatch,
    stepTasksViewData,
    association,
    workflow,
    onPatch,
    editedWorkflow,
    workflowForm,
    setStepTasksViewData,
    onStepAction,
    setShowStepDetails,
    getStepStatus,
    refetchWorkFlow,
  });
  return (
    <>
      <BackButton
        onBackPressed={() => {
          // restore WF tab editing state
          setDisableEditWF(editableWFState);
          setStepTasksViewData((prev) => ({ ...prev, show: false }));
        }}
      />
      <p className="text-lg font-bold my-4">Step {stepTasksViewData?.index}</p>
      {!currentTask && (
        <Widget title="" draggable={false}>
          <div className="flex justify-between mb-8 w-full">
            <Pill
              value={
                stepTasksViewData?.status === "Completed"
                  ? "Complete"
                  : stepTasksViewData?.status
              }
              background="" // TODO: (Parker) Pill component sets bg to bg-grand if no prop is found
              className={`w-36 capitalize ${statusPillCN(
                stepTasksViewData?.status
              )}`}
            />
            <div className="flex items-center">
              {showVoidButton && (
                <button
                  type="button"
                  className="border-2 rounded-lg border-primaryGreen bg-primaryGreen text-white text-sm h-8 flex items-center relative px-5 mr-4"
                  onClick={() => {
                    handleVoidClick();
                  }}
                >
                  Void
                </button>
              )}
              {!editableWFState &&
                workflowForm?.status !== WF_STATUS_TYPES.IN_PROGRESS &&
                stepTasksViewData?.index !== 1 && (
                  <IconButton
                    imgClassName="w-4 h-5"
                    iconClassName="w-4 h-5"
                    icon={trashDarkenedGreenIcon}
                    onClick={() => openConfirmDeleteStepModal()}
                  />
                )}
            </div>
          </div>
          <div className="flex gap-4 pb-4 w-full">
            <div className="flex border-b pb-4 w-1/2">
              <p className="font-semibold text-lg w-48">Due on</p>
              <p>{stepTasksViewData.dueDate}</p>
            </div>
            <div className="flex border-b pb-4 w-1/2">
              <p className="font-semibold text-lg w-48">
                {stepTasksViewData?.role}
              </p>
              <p className="mp-1">{assignee}</p>
            </div>
          </div>

          <div className="flex gap-4 border-b pb-4 mb-6 w-full">
            <div className="flex w-1/2 mr-2">
              <p className="text-left font-semibold text-lg w-48">
                Description
              </p>
              <p className="flex flex-1 text-gray-450 text-base font-normal">
                {stepTasksViewData?.description}
              </p>
            </div>
            <div className="flex w-1/2">
              <p className="font-semibold text-lg w-48">Action Taken</p>
              <p>{actionTaken}</p>
            </div>
          </div>

          <Tasks
            showAddAndDeleteTasks={
              !editedWorkflow?.status?.includes("completed") &&
              editedWorkflow?.status !== WF_STATUS_TYPES.VOID &&
              (editedWorkflow?.status === WF_STATUS_TYPES.DRAFT ||
                (workflow?.isFreeFlow &&
                  stepTasksViewData?.status !==
                    WF_STEP_ACTION_STATUS.COMPLETED &&
                  stepTasksViewData?.status !== WF_STEP_ACTION_STATUS.VOID)) &&
              editing
            }
            canAddTasks={hasUpdatePermission(TASK, currentUser)}
            tasks={formattedTasks}
            handleAddTasks={handleAddTasks}
            handleRemoveTasks={openConfirmDeleteTaskModal}
            associationInfo={{
              value,
              label,
              reference,
              resourceFrom: "Workflow",
              navigateBackInfo: {
                id: stepTasksViewData?.id,
                tasks: stepTasksViewData?.tasks,
                index: stepTasksViewData?.index,
                status: stepTasksViewData?.status,
                role: stepTasksViewData?.role,
                dueDate: stepTasksViewData?.dueDate,
                description: stepTasksViewData?.description,
                users: stepTasksViewData?.users,
                attachments: stepTasksViewData?.attachments,
                show: true,
                backFromTasksView: true,
              },
            }}
            setCurrentTask={setCurrentTask}
          />

          <div className="my-4" />

          <Media
            // allow delete media
            isEditing={
              editedWorkflow?.status === WF_STATUS_TYPES.DRAFT && editing
            }
            canAddMedia={
              !editedWorkflow?.status?.includes("completed") &&
              editedWorkflow?.status !== WF_STATUS_TYPES.VOID &&
              (stepTasksViewData?.status === WF_STEP_ACTION_STATUS.PENDING ||
                editedWorkflow?.status === WF_STATUS_TYPES.DRAFT) &&
              editing
            }
            stepAttachments={
              workflow?.steps?.[stepTasksViewData?.index - 1]?.attachments
            }
            filesState={filesState}
            handleFilesToAdd={handleFilesToAdd}
            handleFilesToRemove={handleFilesToRemove}
          />

          <div className="my-4" />

          <Attachments
            // allow delete attachment
            isEditing={
              editedWorkflow?.status === WF_STATUS_TYPES.DRAFT && editing
            }
            canAddAttachments={
              !editedWorkflow?.status?.includes("completed") &&
              editedWorkflow?.status !== WF_STATUS_TYPES.VOID &&
              (stepTasksViewData?.status === WF_STEP_ACTION_STATUS.PENDING ||
                editedWorkflow?.status === WF_STATUS_TYPES.DRAFT) &&
              editing
            }
            stepAttachments={
              workflow?.steps[stepTasksViewData?.index - 1]?.attachments
            }
            filesState={filesState}
            handleFilesToAdd={handleFilesToAdd}
            handleFilesToRemove={handleFilesToRemove}
          />
        </Widget>
      )}
      {currentTask && (
        <SingleTaskView
          showBackButton={false}
          currentTask={currentTask}
          clearCurrentTask={handleBackToWorkflow}
          setCurrentTask={setCurrentTask}
          onBackToWfPressed={handleBackToWorkflow}
          usedSopDict={usedSopDict}
        />
      )}
    </>
  );
}

WorkflowStepTasks.propTypes = {
  stepTasksViewData: PropTypes.shape({
    id: PropTypes.string,
    index: PropTypes.number,
    status: PropTypes.string,
    role: PropTypes.string,
    dueDate: PropTypes.string,
    description: PropTypes.string,
    tasks: PropTypes.arrayOf(PropTypes.string),
    attachments: PropTypes.arrayOf(PropTypes.shape({})),
    users: PropTypes.arrayOf(PropTypes.shape({ reference: PropTypes.string })),
  }),
  setStepTasksViewData: PropTypes.func,
  association: PropTypes.string,
  workflow: PropTypes.shape({
    isFreeFlow: PropTypes.bool,
    steps: PropTypes.arrayOf(
      PropTypes.shape({
        attachments: PropTypes.shape({}),
        comments: PropTypes.arrayOf(PropTypes.shape({})),
        tasks: PropTypes.arrayOf(PropTypes.string),
      })
    ),
  }),
  requestFormDispatch: PropTypes.func,
  onPatch: PropTypes.func,
  editedWorkflow: PropTypes.shape({ status: PropTypes.string }),
  workflowForm: PropTypes.shape({ status: PropTypes.string }),
  setDisableEditWF: PropTypes.func,
  onStepAction: PropTypes.func,
  setShowStepDetails: PropTypes.func,
  getStepStatus: PropTypes.func,
  editing: PropTypes.bool,
  actionTaken: PropTypes.string,
  refetchWorkFlow: PropTypes.func,
};

WorkflowStepTasks.defaultProps = {
  stepTasksViewData: {},
  setStepTasksViewData: undefined,
  association: undefined,
  workflow: {},
  requestFormDispatch: undefined,
  onPatch: undefined,
  editedWorkflow: {},
  workflowForm: {},
  setDisableEditWF: undefined,
  onStepAction: PropTypes.func,
  setShowStepDetails: undefined,
  getStepStatus: undefined,
  editing: undefined,
  actionTaken: "",
  refetchWorkFlow: () => {},
};

import React from "react";
import PropTypes from "prop-types";
import cardViewGreenIcon from "../../assets/images/cardViewGreen.svg";
import cardViewGreyIcon from "../../assets/images/cardViewGrey.svg";
import tableViewGreenIcon from "../../assets/images/tableViewIcon.svg";
import tableViewGreyIcon from "../../assets/images/tableViewGreyIcon.svg";
import IconLabel from "../IconLabel/IconLabel";

const SwitcherButton = ({ isShowingTable, setIsShowingTable }) => {
  return (
    <div className="flex flex-row">
      <IconLabel
        icon={isShowingTable ? cardViewGreyIcon : cardViewGreenIcon}
        iconStyle={{ width: "18px", height: "18px" }}
        onClick={() => {
          if (isShowingTable) {
            setIsShowingTable(false);
          }
        }}
      />
      <div className="mr-2.5 border-l border-es-light-grey h-6" />
      <IconLabel
        icon={isShowingTable ? tableViewGreenIcon : tableViewGreyIcon}
        iconStyle={{ width: "18px", height: "18px" }}
        onClick={() => {
          if (!isShowingTable) {
            setIsShowingTable(true);
          }
        }}
      />
    </div>
  );
};

SwitcherButton.propTypes = {
  isShowingTable: PropTypes.bool,
  setIsShowingTable: PropTypes.func,
};

SwitcherButton.defaultProps = {
  isShowingTable: false,
  setIsShowingTable: undefined,
};

export default SwitcherButton;

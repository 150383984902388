import PropTypes from "prop-types";
import React, { useCallback, useMemo, useRef } from "react";
import {
  ADD_ATTRIBUTE_POPUP,
  DEFAULT_ATTRIBUTES,
  TOGGLE_POSITIONED_POPUP,
} from "../../../constants";
import trashDarkenedGreenIcon from "../../assets/images/trashDarkenedGreenIcon.svg";
import IconButton from "../Buttons/IconButton";
import PlusCircleButton from "../Buttons/PlusCircleButton/PlusCircleButton";
import EditItemAsset from "../DetailListCard/EditItemAsset";
import Widget from "../Widget/Widget";
import { useAppState } from "../../../state/appState";

const PureAttributeWidget = ({
  resource,
  values,
  loading,
  onAdd,
  editing,
  onDelete,
  disableEditing,
  measurements,
  reloadAttributes,
}) => {
  const wrapperRef = useRef(null);
  const handleAddElements = (elements, newAttr) => {
    onAdd(elements, newAttr);
  };
  const [, appStateDispatch] = useAppState();

  const handleDeleteClick = useCallback(
    (id) => {
      onDelete(id);
    },
    [onDelete]
  );

  const handleAddClick = () => {
    appStateDispatch({
      type: TOGGLE_POSITIONED_POPUP,
      position: {
        centered: true,
      },
      popupData: {
        item: {
          resource,
          reloadAttributes,
          onAddElements: handleAddElements,
          measurements,
        },
        popupWidth: 984,
      },
      popupType: ADD_ATTRIBUTE_POPUP,
    });
  };

  const AttributeValues = useMemo(() => {
    return values.map(({ id, input, display, defaultAttribute }, index) => {
      const iconWrapper = onDelete && editing ? "" : "invisible";
      const isLastElement =
        index === values.length - 1 ||
        (index === values.length - 2 && !(values.length % 2));
      const iconSpacing = "p-2 flex";

      return (
        <div
          key={display?.id}
          className={`flex col-span-1 h-full
            ${index % 2 === 0 ? "" : "ml-6"}
            ${!isLastElement && "border-b"}`}
        >
          <EditItemAsset
            index={index}
            input={input}
            display={display}
            isEditing={editing}
            defaultAttribute={DEFAULT_ATTRIBUTES.includes(display?.title)}
          />
          {!defaultAttribute && editing && (
            <div className="flex">
              <IconButton
                wrapperClassName={`${iconWrapper} ${iconSpacing}`}
                imgClassName="w-4 h-4 flex"
                iconClassName="w-4 h-4 flex"
                onClick={() => handleDeleteClick(id)}
                icon={trashDarkenedGreenIcon}
              />
            </div>
          )}
          <div
            className={`${
              index % 2 === 0 ? "border-r h-full relative left-10" : ""
            }`}
          />
        </div>
      );
    });
  }, [editing, handleDeleteClick, onDelete, values]);

  return (
    <div className="col-span-2">
      <Widget
        title="Attributes"
        draggable={false}
        isEditing={editing}
        disableEditing={disableEditing}
        loading={loading}
      >
        <div
          className={`flex-1 overflow-y-scroll grid relative${
            loading && "loading"
          }`}
          style={{
            gridTemplateColumns: "repeat(2, 1fr)",
            gridTemplateRows: "repeat(3, 1fr)",
            columnGap: "3.5rem",
          }}
          ref={wrapperRef}
        >
          {AttributeValues}
          {editing && (
            <div className="col-span-2 flex items-start">
              <PlusCircleButton
                title="Add Attribute"
                onClick={() => handleAddClick()}
                className="flex w-full items-center h-16"
                style={{ color: "#027D61", fontSize: 16 }}
                noHover
              />
            </div>
          )}
        </div>
      </Widget>
    </div>
  );
};

PureAttributeWidget.propTypes = {
  resource: PropTypes.shape({}),
  editing: PropTypes.bool,
  loading: PropTypes.bool,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      input: PropTypes.element,
      display: PropTypes.shape({}),
    })
  ),
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  disableEditing: PropTypes.bool,
  measurements: PropTypes.arrayOf(PropTypes.shape({})),
  reloadAttributes: PropTypes.func,
};

PureAttributeWidget.defaultProps = {
  resource: undefined,
  editing: false,
  loading: true,
  values: [],
  onAdd: undefined,
  onDelete: undefined,
  disableEditing: false,
  measurements: [],
  reloadAttributes: () => {},
};

export default PureAttributeWidget;

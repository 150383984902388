import { useCallback } from "react";
import useRemoveFromCalendar from "../../hooks/api/calendar/useRemoveFromCalendar";
import { useRemoveFromTaskList } from "../../hooks/api/tasks";

const useRemoveTaskFromCache = () => {
  const { deleteFromTaskList } = useRemoveFromTaskList();
  const { removeFromCalendar } = useRemoveFromCalendar();

  const removeTasksFromCache = useCallback(
    (taskIds) => {
      taskIds?.forEach((id) => {
        if (!id) return;

        const paramsForRemoval = { args: { id }, operation: "$all" };
        // False - declares no post to BE, only mutate cache
        deleteFromTaskList(paramsForRemoval, false);
        removeFromCalendar(paramsForRemoval, false);
      });
    },
    [deleteFromTaskList, removeFromCalendar]
  );

  return { removeTasksFromCache };
};

export default useRemoveTaskFromCache;

import React from "react";
import PrimaryButton from "../../../stories/Components/Buttons/PrimaryButton";
import PrimaryHeaderItems from "../../../stories/Components/Primary/PrimaryHeaderItems";
import ReportsTable from "../../../stories/Components/ReportTable";
import useReportListData from "./useReportListData";

const ReportList = () => {
  const { canCreate, isLoading, reports, isDisabledButton, handleOpenModal } =
    useReportListData();

  return (
    <>
      <PrimaryHeaderItems
        headerTitle="Reports"
        buttonTitle="Reports"
        customButton={
          canCreate && (
            <PrimaryButton
              addButton
              resourceName="Report"
              onClick={handleOpenModal}
              disabled={isDisabledButton}
            />
          )
        }
      />

      <ReportsTable isLoading={isLoading} reports={reports} />
    </>
  );
};

export default ReportList;

import React from "react";
import PropTypes from "prop-types";
import greenLockIcon from "../../stories/assets/images/greenLockIcon.svg";
import arrowGreen from "../../stories/assets/images/ArrowGreen.svg";
import Tooltip from "./Tooltip";
import useRealTimeLockStatusData from "./useRealTimeLockStatusData";
import IconButton from "../../stories/Components/Buttons/IconButton";

const RealTimeLockStatus = ({ activeTab, lockState }) => {
  const {
    isTooltipOpen,
    showLockIcon,
    userFullname,
    userAvatar,
    message,
    handleOpenTooltip,
    handleCloseTooltip,
  } = useRealTimeLockStatusData({ activeTab, lockState });

  return (
    <div className={showLockIcon ? "flex flex-col" : "hidden"}>
      <div className="flex flex-col items-center">
        <IconButton
          onClick={handleOpenTooltip}
          iconLeft={
            <img
              className="inline-block mt-1"
              src={greenLockIcon}
              alt="locked-editing"
            />
          }
        />

        <img
          className={`${isTooltipOpen ? "" : "hidden"}`}
          style={{ width: "12px", transform: "rotate(180deg)" }}
          src={arrowGreen}
          alt=""
        />

        <div
          className="flex z-50 absolute"
          style={{
            top: "62px",
            right: "20px",
            minWidth: "338px",
          }}
        >
          <Tooltip
            isTooltipOpen={isTooltipOpen}
            userFullname={userFullname}
            userAvatar={userAvatar}
            message={message}
            handleCloseTooltip={handleCloseTooltip}
          />
        </div>
      </div>
    </div>
  );
};

// Fix Proptypes
RealTimeLockStatus.defaultProps = {
  activeTab: "details",
  lockState: {},
};

RealTimeLockStatus.propTypes = {
  activeTab: PropTypes.string,
  lockState: PropTypes.objectOf(
    PropTypes.shape({
      userRef: PropTypes.string,
      user: PropTypes.shape({
        id: PropTypes.string,
        reference: PropTypes.string,
      }),
      isCurrentUserLocking: PropTypes.bool,
    })
  ),
};

export default RealTimeLockStatus;

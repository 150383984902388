import { useCallback, useEffect, useState } from "react";
import { NON_SUPPORTED_BLOB_IMAGE_TYPES } from "../../../../constants";
import { useGetFile } from "../../../../hooks/useGetFile";

/**
 * Generates a URL for accessing the contents of a local file.
 * @param {File} fileObject - The file object for which to generate the URL.
 * @returns {string} A URL that can be used to access the file's contents.
 */
const getFileUrl = (fileObject) => {
  if (NON_SUPPORTED_BLOB_IMAGE_TYPES.includes(fileObject?.type)) {
    return null;
  }
  return URL.createObjectURL(fileObject);
};

/**
 * Check if file is video category
 */
const isVideoCategory = (file) => {
  return file?.category === "Videos" || file?.type?.includes("video");
};

const useThumbnailViewData = ({ file, fileObject, isEditing }) => {
  const { data, isLoading } = useGetFile(file?.ref);
  const [fileUrl, setFileUrl] = useState();
  const [isVideoType, setIsVideoType] = useState(false);

  useEffect(() => {
    // Set url for local media file
    if (fileObject) {
      const url = getFileUrl(fileObject);
      setFileUrl(url);
      setIsVideoType(isVideoCategory(fileObject));
    }

    // Set url for remote media file
    if (data) {
      setFileUrl(data?.contentsUrl);
      setIsVideoType(isVideoCategory(data));
    }
  }, [data, fileObject]);

  // Open media file in a new tab
  const handleOpenFile = useCallback(() => {
    if (!isEditing) {
      window.open(fileUrl, "_blank");
    }
  }, [fileUrl, isEditing]);

  return {
    isLoading,
    fileUrl,
    isVideoType,
    handleOpenFile,
  };
};

export default useThumbnailViewData;

import cntl from "cntl";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { components } from "react-select";
import {
  BRAND_GREEN,
  BRAND_RED,
  DEFAULT_NO_OPTION_MSG,
  DROPDOWN_STYLE,
} from "../../../constants";
import { subsetSort } from "../../../helpers/Formatters";
import { inputWidth } from "../../../helpers/StyleFunctions";
import Chevron from "../Chevron/Chevron";
import CrossButton from "../CrossButton/CrossButton";
import InfoTooltip from "../InfoTooltip/InfoTooltip";
import ReactSelect from "./ReactSelect";
import ReactSelectAsyncPaginate from "./ReactSelectAsyncPaginate";

const BORDER_GRAY = (disabled) => (disabled ? "#d0d0d000" : "#CACACA");

const dropdownIconCN = (props, size) => cntl`
  transform
  ${props?.selectProps?.menuIsOpen ? "-rotate-90" : "rotate-90"}
  ${size ? "p-2 h-full w-full" : "h-3 w-3"}
`;

const DDWrapperCN = (className) => cntl`
  flex-1
  relative
  ${className}
`;

const errorMessageCN = (className) => cntl`
capitalize-first
text-xs
text-brandRed
italic
${className}
`;

const customTheme = (base) => ({
  ...base,
  colors: {
    ...base.colors,
    primary: BRAND_GREEN,
    primary25: "#e8f9f5", // tailwind class bg-backgroundGreen for onHover of items in dropdown list
    primary50: BRAND_GREEN.concat("80"),
    primary75: BRAND_GREEN.concat("c0"),
    neutral0: "white",
    neutral5: "#dddddd",
    neutral10: "#d5d5d5",
    neutral20: "#cacaca",
    neutral30: "#A6A6A6",
    neutral40: "#acacac",
    neutral50: "#979797",
    neutral60: "#7d7d7d",
    neutral70: "#5c5c5c",
    neutral80: "#333333",
    neutral90: "black",
  },
});

const Dropdown = ({
  containerRef,
  noOptionsMessage,
  className,
  label,
  inlineLabel,
  formatOptionLabel,
  options,
  optionsSortRange,
  disableSort,
  placeholder,
  defaultValue,
  value,
  disableClear,
  disableSearch,
  disableErrorMessage,
  onChange,
  isDisabled,
  validation,
  isMulti,
  disableOptionsClear,
  ValueContainer,
  hideIndicator,
  customStyle,
  defaultMenuIsOpen,
  hideDropdownIndicator,
  subText,
  isCreatable,
  forwardedRef,
  handleEnter,
  onDropdownClick,
  maxMenuHeight,
  onKeyPress,
  onKeyDown,
  onKeyUp,
  onRequestDropdownClear,
  openMenuOnFocus,
  bottomMessage,
  labelClassName,
  tooltipData,
  tooltipDataWrapperClassName,
  tootipCustomWidth,
  toolTipStyle,
  toolTipClassName,
  name,
  autoComplete,
  size,
  isAsyncPaginate,
  menuIsAlwaysOpen,
  loadOptions,
  menuPlacement,
  onMenuOpen,
  onMenuClose,
  menuShouldScrollIntoView,
  customErrorMessage,
  errorMsgCN,
  showValidationErrorAtBottom,
}) => {
  const [errorMessage, setErrorMessage] = useState();
  const [wasFocused, setWasFocused] = useState(false);
  const showTopError =
    (!errorMessage || customErrorMessage) &&
    wasFocused &&
    !label &&
    !inlineLabel;

  const customStyles = (
    height = size ? "28px" : "40px",
    fontSize = "16px"
  ) => ({
    container: (base) => {
      const containerStyles = {
        ...base,
        backgroundColor: isDisabled ? "#eeeeee" : "#F9F9F9",
        borderColor: BORDER_GRAY(isDisabled),
        borderRadius: 6,
        ...customStyle?.container,
        fontSize,
      };

      if (size) {
        containerStyles.width = inputWidth(size);
        containerStyles.display = "inline-block";
      }
      return containerStyles;
    },
    singleValue: (base) => ({
      ...base,
      ...DROPDOWN_STYLE.singleValue,
      ...customStyle?.singleValue,
      fontSize,
    }),
    option: (base) => ({
      ...base,
      ...DROPDOWN_STYLE.option,
      fontSize,
    }),
    valueContainer: (base) => {
      const valueContainerStyles = {
        ...base,
        ...DROPDOWN_STYLE.valueContainer,
        ...customStyle?.valueContainer,
        fontSize,
      };
      if (size) {
        valueContainerStyles.padding = "0px 8px";
      }
      return valueContainerStyles;
    },
    control: (base) => {
      const controlStyles = {
        ...base,
        ...DROPDOWN_STYLE.control,
        minHeight: height,
        backgroundColor: "transparent",
        borderColor:
          (errorMessage || customErrorMessage) && wasFocused ? BRAND_RED : "",
        ...customStyle?.control,
        fontSize,
      };

      if (size) {
        controlStyles.overflow = "hidden";
      }
      return controlStyles;
    },
    placeholder: (base) => ({
      ...base,
      ...DROPDOWN_STYLE.placeholder,
      color: "#585858",
      ...customStyle?.placeholder,
      fontSize,
    }),
    menu: (base) => ({
      ...base,
      borderColor: BORDER_GRAY(isDisabled),
      borderWidth: "1px",
      zIndex: "50",
      fontSize,
      ...customStyle?.menu,
    }),
    dropdownIndicator: (base) => {
      const dropdownIndicatorStyles = {
        ...base,
        color: BRAND_GREEN,
        ...customStyle?.dropdownIndicator,
      };

      if (size) {
        dropdownIndicatorStyles.width = height;
      }
      return dropdownIndicatorStyles;
    },
    indicatorSeparator: (base) => ({
      ...base,
      backgroundColor: BORDER_GRAY(isDisabled),
      height: "100%",
      alignSelf: "center",
      ...customStyle?.indicatorSeparator,
    }),
    group: (base) => ({
      ...base,
      borderBottom: `2px solid ${BORDER_GRAY(isDisabled)}`,
      fontSize,
    }),
    input: (base) => ({
      ...base,
      fontSize,
    }),
    indicatorContainer: (base) => ({
      ...base,
      background: "blue",
    }),
  });

  useEffect(() => {
    const validate = async () => {
      if (validation) {
        try {
          await validation.validate(value);
          // validation passed
          setErrorMessage(undefined);
        } catch (err) {
          setErrorMessage(err.message);
        }
      }
    };
    validate();
  }, [validation, value]);

  const handleBlur = () => {
    if (!wasFocused) {
      setWasFocused(true);
    }
  };

  const handleDropdownClick = (props) => {
    onDropdownClick();
    // eslint-disable-next-line react/prop-types
    if (props.onClick) {
      // eslint-disable-next-line react/prop-types
      props.onClick();
    }
  };

  const Input = (props) => {
    // eslint-disable-next-line react/destructuring-assignment
    const { autoCompleteProp = props.autoComplete } = props.selectProps;
    return <components.Input {...props} autoComplete={autoCompleteProp} />;
  };

  return (
    <div
      ref={containerRef}
      className={DDWrapperCN(className, showTopError)}
      title={value?.label}
      style={{
        width: "100%",
      }}
    >
      {showTopError && (
        <p className={errorMessageCN()}>
          {customErrorMessage} {errorMessage}
        </p>
      )}
      {(label || errorMessage) && !bottomMessage && inlineLabel ? (
        <div className="">
          {label && (
            <div className="flex">
              <p className={labelClassName}>{label}</p>
              <p
                className={`text-brandGreen text-sm ${
                  !validation?.exclusiveTests?.required && "invisible"
                }`}
              >
                *
              </p>
            </div>
          )}
          {!!errorMessage && !disableErrorMessage && wasFocused && (
            <p className="text-brandRed italic ml-2">{errorMessage}</p>
          )}
        </div>
      ) : null}
      <div className="flex">
        {(label || errorMessage) && !bottomMessage && !inlineLabel ? (
          <div>
            {label && (
              <div className={`${labelClassName || "ESInputLabel flex-row"}`}>
                <p>{label}</p>
                <p
                  className={`text-brandGreen text-sm ${
                    !validation?.exclusiveTests?.required && "invisible"
                  }`}
                >
                  *
                </p>
              </div>
            )}
            {!!errorMessage &&
              !disableErrorMessage &&
              wasFocused &&
              !showValidationErrorAtBottom && (
                <p className={`text-brandRed italic ml-2 ${errorMsgCN}`}>
                  {errorMessage}
                </p>
              )}
          </div>
        ) : null}
        {tooltipData && (
          <div className={`ml-1.5 ${tooltipDataWrapperClassName}`}>
            <InfoTooltip
              infoData={tooltipData}
              customWidth={tootipCustomWidth || "430px"}
              toolTipStyle={toolTipStyle}
              toolTipClassName={toolTipClassName}
            />
          </div>
        )}
      </div>
      <div
        onBlur={handleBlur}
        onKeyPress={onKeyPress}
        role="button"
        id="react-select-open"
        tabIndex={0}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
      >
        {!isAsyncPaginate && (
          <ReactSelect
            onBlur={handleBlur}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            menuShouldScrollIntoView={menuShouldScrollIntoView}
            menuPlacement={menuPlacement ?? "bottom"}
            noOptionsMessage={() => noOptionsMessage}
            inputId={name}
            ref={forwardedRef}
            onKeyDown={handleEnter}
            isCreatable={isCreatable}
            theme={customTheme}
            styles={customStyles()}
            classNames={
              size && {
                control: () => `ESInputDropdown${size ? `-${size}` : ""}`,
                container: () =>
                  `ESInputDropdown${size ? `-${size}` : " flex-1"} border-none`,
              }
            }
            isClearable={!disableClear}
            isSearchable={!disableSearch}
            options={
              disableSort
                ? options
                : subsetSort(options, optionsSortRange[0], optionsSortRange[1])
            }
            formatOptionLabel={formatOptionLabel}
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={value}
            openMenuOnFocus={openMenuOnFocus}
            isMulti={isMulti}
            defaultMenuIsOpen={defaultMenuIsOpen}
            onChange={(val, action) => {
              if (disableOptionsClear && action.action === "remove-value") {
                return null;
              }

              if (action?.action === "clear" && onRequestDropdownClear) {
                onRequestDropdownClear();
              }
              if (isMulti) {
                return onChange(val);
              }
              return onChange({ ...val, label: val?.label, value: val?.value });
            }}
            isDisabled={isDisabled}
            autoComplete={autoComplete ?? "none"}
            maxMenuHeight={maxMenuHeight}
            components={{
              indicatorContainer: (props) => {
                return (
                  <components.indicatorContainer
                    {...props}
                    style={{ background: "blue" }}
                  />
                );
              },
              ClearIndicator: (props) => {
                // eslint-disable-next-line react/destructuring-assignment
                const style = props.getStyles("clearIndicator", props);
                return (
                  <components.DropdownIndicator
                    {...props}
                    getStyles={() => ({ ...style, padding: "0px 8px" })}
                  >
                    <CrossButton
                      className={`h-full m-auto ${!size && "w-2 px-2 py-1"}`}
                      onClick={() => {}}
                    />
                  </components.DropdownIndicator>
                );
              },
              ValueContainer: (props) => {
                if (ValueContainer) {
                  return <ValueContainer {...props} />;
                }

                return <components.ValueContainer {...props} />;
              },
              Input,
              IndicatorSeparator: (props) => {
                if (hideIndicator) {
                  return null;
                }

                return <components.IndicatorSeparator {...props} />;
              },
              DropdownIndicator: (props) => {
                if (hideDropdownIndicator) {
                  return null;
                }
                return (
                  <div
                    onClick={() => handleDropdownClick(props)}
                    onKeyDown={() => handleDropdownClick(props)}
                    role="button"
                    id="react-select-dropdown"
                    style={{
                      width: size ? "28px" : "38px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    tabIndex="0"
                  >
                    <Chevron
                      color="#101010"
                      strokeWidth="2px"
                      className={dropdownIconCN(props, size)}
                      {...props}
                    />
                  </div>
                );
              },
            }}
          />
        )}
        {isAsyncPaginate && (
          <ReactSelectAsyncPaginate
            menuShouldScrollIntoView={menuShouldScrollIntoView}
            menuPlacement={menuPlacement}
            noOptionsMessage={() => noOptionsMessage}
            inputId={name}
            ref={forwardedRef}
            handleEnter={handleEnter}
            isCreatable={isCreatable}
            theme={customTheme}
            styles={customStyles()}
            classNames={
              size && {
                control: () => `ESInputContainer${size ? `-${size}` : ""}`,
                container: () =>
                  `ESInputContainer${
                    size ? `-${size}` : " flex-1"
                  } border-none`,
              }
            }
            isClearable={!disableClear}
            isSearchable={!disableSearch}
            loadOptions={loadOptions}
            additional={{
              page: 1,
            }}
            debounceTimeout={500}
            formatOptionLabel={formatOptionLabel}
            placeholder={placeholder}
            defaultValue={defaultValue}
            value={value}
            openMenuOnFocus={openMenuOnFocus}
            isMulti={isMulti}
            menuIsOpen={menuIsAlwaysOpen}
            defaultMenuIsOpen={defaultMenuIsOpen}
            onChange={(val, action) => {
              if (disableOptionsClear && action.action === "remove-value") {
                return null;
              }
              if (action?.action === "clear" && onRequestDropdownClear) {
                onRequestDropdownClear();
              }
              if (isMulti) {
                return onChange(val);
              }
              return onChange({ ...val, label: val?.label, value: val?.value });
            }}
            isDisabled={isDisabled}
            autoComplete={autoComplete ?? "none"}
            maxMenuHeight={maxMenuHeight}
            components={{
              indicatorContainer: (props) => {
                return (
                  <components.indicatorContainer
                    {...props}
                    style={{ background: "blue" }}
                  />
                );
              },
              ClearIndicator: (props) => {
                // eslint-disable-next-line react/destructuring-assignment
                const style = props.getStyles("clearIndicator", props);
                return (
                  <components.DropdownIndicator
                    {...props}
                    getStyles={() => ({ ...style, padding: "0px 8px" })}
                  >
                    <CrossButton
                      className={`h-full m-auto ${!size && "w-2 px-2 py-1"}`}
                      onClick={() => {}}
                    />
                  </components.DropdownIndicator>
                );
              },
              ValueContainer: (props) => {
                if (ValueContainer) {
                  return <ValueContainer {...props} />;
                }

                return <components.ValueContainer {...props} />;
              },
              Input,
              IndicatorSeparator: (props) => {
                if (hideIndicator) {
                  return null;
                }

                return <components.IndicatorSeparator {...props} />;
              },
              DropdownIndicator: (props) => {
                if (hideDropdownIndicator) {
                  return null;
                }
                return (
                  <div
                    onClick={() => handleDropdownClick(props)}
                    onKeyDown={() => handleDropdownClick(props)}
                    role="button"
                    id="react-select-dropdown"
                    style={{
                      width: size ? "28px" : "38px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                    tabIndex="0"
                  >
                    <Chevron
                      color="#101010"
                      strokeWidth="2px"
                      className={dropdownIconCN(props, size)}
                      {...props}
                    />
                  </div>
                );
              },
            }}
          />
        )}
      </div>
      {(label || errorMessage) && bottomMessage && (
        <div className="ESInputLabel">
          {label && (
            <div className="flex">
              <p>{label}</p>
              {validation?.exclusiveTests?.required && (
                <p className="text-brandGreen text-sm">*</p>
              )}
            </div>
          )}
          {!!errorMessage &&
            !disableErrorMessage &&
            wasFocused &&
            !showValidationErrorAtBottom && (
              <p className="text-brandRed italic ml-2">{errorMessage}</p>
            )}
        </div>
      )}
      {showValidationErrorAtBottom &&
        !!errorMessage &&
        !disableErrorMessage &&
        wasFocused && (
          <p className="text-brandRed italic ml-2 text-xs">{errorMessage}</p>
        )}
      {subText && <p className="text-xs py-3 text-gray-300">{subText}</p>}
    </div>
  );
};

const optionPropType = PropTypes.shape({
  label: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
});
Dropdown.propTypes = {
  /**
   * the name of the input
   * @ignore
   * @ignore
   */

  containerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  noOptionsMessage: PropTypes.string,
  inlineLabel: PropTypes.bool,
  customErrorMessage: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  /**
   * classes to apply to the container div
   */
  className: PropTypes.string,
  /**
   * the options that will be displayed in the dropdown (label, value pairs)
   */
  options: PropTypes.arrayOf(optionPropType),
  /**
   * the string to display above the dropdown
   */
  label: PropTypes.string,
  /**
   * string that acts as the placeholder and the "null" option
   */
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /**
   * value to display on the initial load
   */
  defaultValue: PropTypes.shape(optionPropType),
  /**
   * controls the value in the dropdown
   */
  value: PropTypes.oneOfType([
    optionPropType,
    PropTypes.arrayOf(optionPropType),
  ]),
  /**
   * if true, hides clear X
   */
  disableClear: PropTypes.bool,
  /**
   * if true, disables search
   */
  disableSearch: PropTypes.bool,
  /**
   * hides the error message (i.e. "Required")
   */
  disableErrorMessage: PropTypes.bool,
  /**
   * function called when a selection is changed, provides the selection
   */
  onChange: PropTypes.func,
  /**
   * disables the dropdown
   */
  isDisabled: PropTypes.bool,
  /**
   * validation schema using yup
   */
  // eslint-disable-next-line react/forbid-prop-types
  validation: PropTypes.object,
  /**
   * allows multiple selections from dropdown
   */
  isMulti: PropTypes.bool,
  /**
   * react select value container
   */
  ValueContainer: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  /**
   * Array of startIndex and endIndex of items to be sorted in the options list
   */
  optionsSortRange: PropTypes.arrayOf(PropTypes.number),
  disableSort: PropTypes.bool,
  formatOptionLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  hideIndicator: PropTypes.bool,
  hideDropdownIndicator: PropTypes.bool,
  customStyle: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    container: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    singleValue: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    valueContainer: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    control: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    dropdownIndicator: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    indicatorSeparator: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    placeholder: PropTypes.object,
    // eslint-disable-next-line react/forbid-prop-types
    menu: PropTypes.object,
  }),
  defaultMenuIsOpen: PropTypes.bool,
  subText: PropTypes.string,
  isCreatable: PropTypes.bool,
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    // eslint-disable-next-line react/forbid-prop-types
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  handleEnter: PropTypes.func,
  onDropdownClick: PropTypes.func,
  maxMenuHeight: PropTypes.number,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  onRequestDropdownClear: PropTypes.func,
  openMenuOnFocus: PropTypes.bool,
  /**
   * Errors & Labels Displayed At Bottom Of Container
   */
  bottomMessage: PropTypes.bool,
  labelClassName: PropTypes.string,
  name: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  tooltipData: PropTypes.object,
  /**
   * Input AutoComplete Tag
   */
  autoComplete: PropTypes.string,
  /**
   * Size
   * @summary input characted lengths are defined in stylesheet (see ESInputContainer & styleFunctions.js)
   */
  size: PropTypes.string,
  tootipCustomWidth: PropTypes.number,
  tooltipDataWrapperClassName: PropTypes.string,
  toolTipStyle: PropTypes.shape({}),
  toolTipClassName: PropTypes.string,
  menuIsAlwaysOpen: PropTypes.bool,
  loadOptions: PropTypes.func,
  isAsyncPaginate: PropTypes.bool,
  menuPlacement: PropTypes.oneOf(["top", "bottom", "auto"]),
  onMenuClose: PropTypes.func,
  onMenuOpen: PropTypes.func,
  menuShouldScrollIntoView: PropTypes.bool,
  disableOptionsClear: PropTypes.bool,
  showValidationErrorAtBottom: PropTypes.bool,
  errorMsgCN: PropTypes.string,
};

Dropdown.defaultProps = {
  customErrorMessage: undefined,
  onMenuClose: undefined,
  onMenuOpen: undefined,
  containerRef: undefined,
  noOptionsMessage: DEFAULT_NO_OPTION_MSG,
  inlineLabel: false,
  className: null,
  options: [],
  label: null,
  placeholder: "Select",
  defaultValue: null,
  value: null,
  disableClear: false,
  disableSearch: false,
  disableErrorMessage: false,
  onChange: null,
  isDisabled: false,
  validation: undefined,
  isMulti: false,
  ValueContainer: undefined,
  disableSort: false,
  optionsSortRange: [],
  formatOptionLabel: undefined,
  hideIndicator: false,
  customStyle: undefined,
  defaultMenuIsOpen: false,
  hideDropdownIndicator: false,
  subText: undefined,
  isCreatable: false,
  forwardedRef: undefined,
  handleEnter: undefined,
  onDropdownClick: () => {},
  maxMenuHeight: 250,
  onKeyPress: () => {},
  onKeyDown: () => {},
  onKeyUp: () => {},
  onRequestDropdownClear: undefined,
  openMenuOnFocus: false,
  bottomMessage: false,
  tooltipData: undefined,
  labelClassName: "",
  name: undefined,
  autoComplete: undefined,
  size: undefined,
  tootipCustomWidth: undefined,
  tooltipDataWrapperClassName: undefined,
  toolTipClassName: undefined,
  toolTipStyle: undefined,
  menuIsAlwaysOpen: false,
  loadOptions: undefined,
  isAsyncPaginate: false,
  menuPlacement: undefined,
  menuShouldScrollIntoView: false,
  disableOptionsClear: false,
  showValidationErrorAtBottom: false,
  errorMsgCN: undefined,
};

export default Dropdown;

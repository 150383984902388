import { useState, useEffect, useCallback, useRef } from "react";
import formatCountdown from "../../helpers/Format/formatCountdown";

const useRealTimeInactivityAlertData = ({
  isEditing,
  inactivityTimeInSeconds,
  countdownInSeconds,
  onInactivity,
}) => {
  const [countdown, setCountdown] = useState(countdownInSeconds);

  const timeoutRef = useRef(null);
  const countdownRef = useRef(null);
  const showAlertRef = useRef(false);
  const onActivityFuncRef = useRef(null);

  const message = `You have been inactive for a while. Your session will expire in ${formatCountdown(
    countdown
  )} minutes`;

  // Using a `ref` because onInactivity can change in every re-render
  // and if it is added as a dependency of `resetTimer` it will affect
  // the behavior of the Alert
  onActivityFuncRef.current = onInactivity;

  /**
   * Close alert and execute the calbacck function `onInactivity`
   */
  const handleExitSesion = useCallback(() => {
    onActivityFuncRef.current();
    showAlertRef.current = false;
  }, []);

  /**
   * Reset the inactivity timer. after inactivity time, the
   * alert is displayed and the countdown starts
   */
  const resetTimer = useCallback(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (countdownRef.current) clearInterval(countdownRef.current);
    setCountdown(countdownInSeconds);

    timeoutRef.current = setTimeout(() => {
      showAlertRef.current = true;
      countdownRef.current = setInterval(() => {
        setCountdown((prev) => {
          if (prev <= 1) {
            clearInterval(countdownRef.current);
            handleExitSesion();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }, inactivityTimeInSeconds * 1000);
  }, [inactivityTimeInSeconds, countdownInSeconds, handleExitSesion]);

  /**
   * Close alter and reset timer
   */
  const handleContinue = () => {
    resetTimer();
    showAlertRef.current = false;
  };

  useEffect(() => {
    const handleUserActivity = () => {
      if (!showAlertRef.current) resetTimer();
    };

    if (isEditing) {
      showAlertRef.current = false;
      resetTimer();

      window.addEventListener("mousemove", handleUserActivity);
      window.addEventListener("keydown", handleUserActivity);
    }

    return () => {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
      if (countdownRef.current) clearInterval(countdownRef.current);

      if (isEditing) {
        window.removeEventListener("mousemove", handleUserActivity);
        window.removeEventListener("keydown", handleUserActivity);
      }
    };
  }, [isEditing, resetTimer]);

  return {
    showAlert: showAlertRef.current,
    message,
    handleContinue,
    handleExitSesion,
  };
};

export default useRealTimeInactivityAlertData;

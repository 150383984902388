import React from "react";
import { Page } from "react-pdf";

const PDFRow = ({ style, index }) => {
  return (
    <div style={style}>
      <Page pageIndex={index} width={750} />
    </div>
  );
};

export default PDFRow;

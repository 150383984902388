import React from "react";
import PropTypes from "prop-types";

import useTaskRelatedTo from "./useTaskRelatedTo";
import ParentTask from "../ParentTask";
import ChildrenTask from "../ChildrenTask";

const TaskRelatedTo = ({
  taskRef,
  childrenTaskRefs,
  parentTaskRef,
  setDisableEditRecurrence,
}) => {
  const { data, onPress, getIcon, getName } = useTaskRelatedTo({
    taskRef,
    setDisableEditRecurrence,
  });
  const renderItem = ({ item }) => {
    return (
      <button
        key={item?.id}
        type="button"
        className="flex flex-row justify-start w-full items-center"
        onClick={() => onPress(item)}
      >
        <div className="flex flex-row gap-2">
          <img src={getIcon(item)} alt={`Navigate to ${getName(item)}`} />
          <p className="text-es-normal text-es-medium-grey">{getName(item)}</p>
        </div>
      </button>
    );
  };

  return (
    <div className="flex flex-col gap-2">
      {parentTaskRef?.length > 0 && (
        <ParentTask parentTaskRef={parentTaskRef} />
      )}
      {!!data?.length && data?.map((item) => renderItem({ item }))}
      {childrenTaskRefs?.length > 0 && (
        <ChildrenTask childrenTaskRefs={childrenTaskRefs} />
      )}
    </div>
  );
};

TaskRelatedTo.propTypes = {
  taskRef: PropTypes.string,
  setDisableEditRecurrence: PropTypes.func,

  parentTaskRef: PropTypes.string,
  childrenTaskRefs: PropTypes.arrayOf({ type: PropTypes.string }),
};
TaskRelatedTo.defaultProps = {
  taskRef: undefined,
  setDisableEditRecurrence: () => {},
  parentTaskRef: undefined,
  childrenTaskRefs: [],
};

export default TaskRelatedTo;

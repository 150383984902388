import { TaskAPI } from "@griffingroupglobal/eslib-api";

const fetchTaskHandler = async (id, instanceStartDate, signal) => {
  const query = instanceStartDate
    ? `?instanceStartDate=${encodeURIComponent(instanceStartDate)}`
    : "";

  const { data } = await TaskAPI.getByIdWOP(id, query, { signal });

  return data;
};

export default fetchTaskHandler;

import React from "react";
import PropTypes from "prop-types";
import cntl from "cntl";

const pillLeft = (isActive, borderClassName, textClassName, padding) => cntl`
${borderClassName || "border border-gray-450 rounded-l-full"}
  flex
  items-center
  justify-center
  ${padding || "py-1 px-2"}
  ${textClassName || "text-xs"}
  ${
    isActive
      ? "bg-inputBgGreen text-gray-450 font-medium"
      : "bg-white text-gray-450"
  }
`;
const pillRight = (isActive, borderClassName, textClassName, padding) => cntl`
  ${borderClassName || "border border-gray-450 rounded-r-full"}
  flex
  items-center
  justify-center
  ${padding || "py-1 px-2"}
  ${textClassName || "text-xs"}
  ${
    isActive
      ? "bg-inputBgGreen text-gray-450 font-medium"
      : "bg-white text-gray-450"
  }
`;

const ToggleTextButton = ({
  leftText,
  rightText,
  isLeftActive,
  setIsLeftActive,
  isRightActive,
  setIsRightActive,
  borderLeftPill,
  borderRightPill,
  textClassName,
  padding,
}) => {
  const toggle = ({ left }) => {
    if (left) {
      setIsLeftActive(true);
      setIsRightActive(false);
    } else {
      setIsLeftActive(false);
      setIsRightActive(true);
    }
  };

  return (
    <div className="flex flex-row">
      <button
        type="button"
        style={{
          padding: 0,
        }}
        onClick={() => toggle({ left: true })}
      >
        <p
          className={pillLeft(
            isLeftActive,
            borderLeftPill,
            textClassName,
            padding
          )}
        >
          {leftText}
        </p>
      </button>
      <button
        type="button"
        style={{
          padding: 0,
        }}
        onClick={() => toggle({ left: false })}
      >
        <p
          className={pillRight(
            isRightActive,
            borderRightPill,
            textClassName,
            padding
          )}
        >
          {rightText}
        </p>
      </button>
    </div>
  );
};

ToggleTextButton.propTypes = {
  leftText: PropTypes.string,
  rightText: PropTypes.string,
  isLeftActive: PropTypes.bool,
  setIsLeftActive: PropTypes.func,
  isRightActive: PropTypes.bool,
  setIsRightActive: PropTypes.func,
  borderLeftPill: PropTypes.string,
  borderRightPill: PropTypes.string,
  textClassName: PropTypes.string,
  padding: PropTypes.string,
};

ToggleTextButton.defaultProps = {
  leftText: undefined,
  rightText: undefined,
  isLeftActive: false,
  setIsLeftActive: undefined,
  isRightActive: false,
  setIsRightActive: undefined,
  borderLeftPill: undefined,
  borderRightPill: undefined,
  textClassName: undefined,
  padding: undefined,
};

export default ToggleTextButton;

import { useQueryClient } from "react-query";
import settingsKeys from "./settingsKeys";
import useAuthenticatedQuery from "../../useAuthenticatedQuery";
import fetchSettingsHandler from "./handlers/fetchSettingsHandler";

const useSettings = () => {
  const queryClient = useQueryClient();

  const authenticatedResponse = useAuthenticatedQuery({
    queryKey: settingsKeys.userSettings,
    queryFn: ({ signal }) => fetchSettingsHandler(signal),
    staleTime: 0,
    onError: () => {
      console.error("Failed To Fetch Events");
    },
  });

  const refetchUsersSettings = async () => {
    await queryClient.invalidateQueries(settingsKeys.userSettings);
  };

  const {
    data: userSettings,
    isLoading: settingsLoading,
    isError: isSettingsError,
    error: settingsError,
  } = authenticatedResponse;

  return {
    userSettings,
    settingsLoading,
    isSettingsError,
    settingsError,
    refetchUsersSettings,
  };
};

export default useSettings;

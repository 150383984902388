import { useRealTimeTenantListener } from "@griffingroupglobal/eslib-ably/react";
import Auth from "../../helpers/Auth";
import useCurrentUser from "../useCurrentUser";
import useHandlePropertyRealTime from "./useHandlePropertyRealTime";
import useHandleCurrentUserRealTime from "./useHandleCurrentUserRealTime";
import useHandleFinanceRealTime from "./useHandleFinanceRealTime";
import useHandleSpaceRealTime from "./useHandleSpaceRealTime";

/**
 * This hook is listening for realtime data changes from the backend based on user's ACL
 * and dispatch to corresponding Resource handler
 */
const useRealtimeQueryUpdater = () => {
  const tenant = Auth.getTenant();

  const { data: currentUser } = useCurrentUser();

  const { handleCurrentUser } = useHandleCurrentUserRealTime();
  const { handleProperty } = useHandlePropertyRealTime();
  const { handleFinance } = useHandleFinanceRealTime();
  const { handleSpace } = useHandleSpaceRealTime();

  useRealTimeTenantListener(tenant, currentUser, async (message) => {
    // TODO (Josymar) this log is temporal, while working on RTD
    // eslint-disable-next-line no-console
    console.log("@@@🔶", message);

    const [resource] = message.data.target.split("/");

    switch (resource) {
      case "Property":
        handleProperty(message);
        break;
      case "Role":
        handleCurrentUser(message);
        break;
      case "Finance":
        handleFinance(message);
        break;
      case "Space":
        handleSpace(message);
        break;
      default:
        break;
    }
  });
};

export default useRealtimeQueryUpdater;

import { useState } from "react";
import { getFullName } from "../../helpers/Formatters";

const useRealTimeLockStatusData = ({ activeTab, lockState }) => {
  const lockFound = lockState?.[activeTab];

  const userFullname = getFullName(lockFound?.user?.name);
  const userAvatar = lockFound?.user?.avatar;

  const showLockIcon = !!lockFound && !lockFound.isCurrentUserLocking;

  const message = `Editing will remain locked until ${userFullname} is finished.`;

  const [isTooltipOpen, setTooltipOpen] = useState(true);

  const handleOpenTooltip = () => {
    setTooltipOpen(true);
  };

  const handleCloseTooltip = () => {
    setTooltipOpen(false);
  };

  return {
    showLockIcon,
    isTooltipOpen,
    userFullname,
    userAvatar,
    message,
    handleOpenTooltip,
    handleCloseTooltip,
  };
};

export default useRealTimeLockStatusData;

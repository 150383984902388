import { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import useCurrentUser from "../../../hooks/useCurrentUser";
import useServiceRequestTableFormat from "../../../hooks/useServiceRequestTableFormat";
import {
  ADD_OPEN_MODAL,
  SERVICE_REQUEST_CREATE_MODAL,
} from "../../../constants";
import useAssociationsHelper from "../../../hooks/useAssociationsHelper";
import { useModalState } from "../../../state/modalState";

const useServiceRequestOverview = (association, asset, setButtonActions) => {
  const [, modalDispatch] = useModalState();
  const { data: currentUser } = useCurrentUser();
  const { getParentAssociation } = useAssociationsHelper();

  // Query selector to get requests by association
  const { data: serviceRequestTickets, isLoading } =
    useServiceRequestTableFormat({
      association,
      asset,
    });

  // State to hold service request id selected
  const [requestId, setRequestId] = useState();

  const canCreate = useMemo(
    () =>
      currentUser?.isAdmin ||
      currentUser?.hasPermission?.("ticket", "can_create"),
    [currentUser]
  );

  const handleOpenModal = useCallback(() => {
    let parentAssociationReference = association;

    if (asset) {
      parentAssociationReference = getParentAssociation(asset)?.reference;
    }

    modalDispatch({
      type: ADD_OPEN_MODAL,
      modalType: SERVICE_REQUEST_CREATE_MODAL,
      ref: { id: uuidv4() },
      modalData: {
        associationLock: parentAssociationReference,
        assetLock: asset,
      },
    });
  }, [modalDispatch, getParentAssociation, association, asset]);

  // Show "Add Request" button in top header, if SR list is rendered inside association page (P/P/A)
  useEffect(() => {
    if (requestId || !setButtonActions) return;

    if (canCreate) {
      setButtonActions([
        {
          title: "Add Request",
          onClick: handleOpenModal,
          tabAction: true,
        },
      ]);
    }
  }, [requestId, canCreate, setButtonActions, handleOpenModal]);

  return {
    canCreate,
    serviceRequestTickets,
    isLoading,
    requestId,
    setRequestId,
    handleOpenModal,
  };
};

export default useServiceRequestOverview;

import React from "react";
import PropTypes from "prop-types";
import Checkbox from "../../../Checkbox/Checkbox";
import BooleanBullet from "../../../BooleanBullet/BooleanBullet";

const BillableCell = ({ row, isEditingTable, editedRow, setEditedRow }) => {
  const onChange = (newVal) => {
    setEditedRow({
      ...editedRow,
      isBillable: newVal,
    });
  };
  if (!isEditingTable) {
    return <BooleanBullet isTrue={row?.original?.isBillable} />;
  }

  return (
    <div className="flex w-full">
      <Checkbox
        className="flex items-center"
        checked={editedRow?.isBillable}
        onChange={onChange}
        onFocusClassName="border-brandGreen"
      />
    </div>
  );
};

BillableCell.propTypes = {
  row: PropTypes.shape({
    original: PropTypes.shape({
      isBillable: PropTypes.bool,
    }),
  }),
  editedRow: PropTypes.shape({
    isBillable: PropTypes.bool,
  }),
  isEditingTable: PropTypes.bool,
  setEditedRow: PropTypes.func,
};

BillableCell.defaultProps = {
  row: undefined,
  editedRow: {},
  isEditingTable: false,
  setEditedRow: undefined,
};

export default BillableCell;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import Checkbox from "../Checkbox/Checkbox";
import BaseAddressInput from "./BaseAddressInput";

const BillingAddress = ({
  mailingSameAsPhysical,
  address,
  onChange,
  handleMailingSameAsPhysicalInput,
  onKeyPress,
  onForwardRef,
  setEdited,
  setMailingSameAsPhysical,
  isDisabled,
  rowGap,
}) => {
  const [sameAsPhysical, setSameAsPhysical] = useState(mailingSameAsPhysical);
  const [addr, setAddr] = useState(address);

  useEffect(() => {
    if (address?.length) {
      setAddr(address);
    }
  }, [address]);

  if (addr?.length === 0) {
    setAddr([
      {
        use: "Physical",
        street: "",
        street2: "",
        city: "",
        state: "",
        country: "",
        zipCode: "",
      },
      {
        use: "Mailing",
        street: "",
        street2: "",
        city: "",
        state: "",
        country: "",
        zipCode: "",
      },
    ]);
  }

  const changeMailingSameAsPhysical = useCallback(
    (val) => {
      if (setEdited) {
        setEdited(true);
      }
      setSameAsPhysical(val);
      handleMailingSameAsPhysicalInput(val);
      if (setMailingSameAsPhysical) setMailingSameAsPhysical(val);
    },
    [handleMailingSameAsPhysicalInput, setEdited, setMailingSameAsPhysical]
  );

  /**
   * New <Methods
   */

  const physicalAddress = useMemo(() => {
    if (addr?.length > 0) {
      const index = addr?.findIndex((object) => {
        return object.use === "Physical";
      });
      if (index !== -1) {
        return addr[index];
      }
    }
    return undefined;
  }, [addr]);

  const changePhysicalAddress = useCallback(
    (val) => {
      if (addr?.length > 0) {
        const index = addr?.findIndex((object) => {
          return object.use === "Physical";
        });
        if (setEdited) {
          setEdited(true);
        }
        if (index === -1) {
          const newAddr = addr;
          newAddr.push({ ...val, use: "Physical" });
          setAddr(newAddr);
          onChange(newAddr);
        } else {
          const updatedAddr = addr;
          updatedAddr[index] = { ...val, use: "Physical" };
          setAddr(updatedAddr);
          onChange(updatedAddr);
        }
      }
    },
    [addr, onChange, setEdited]
  );

  const mailingAddress = useMemo(() => {
    if (addr?.length > 0) {
      const index = addr?.findIndex((object) => {
        return object.use === "Mailing";
      });
      if (index !== -1) {
        return addr[index];
      }
    }
    return undefined;
  }, [addr]);

  const changeMailingAddress = useCallback(
    (val) => {
      if (addr?.length > 0) {
        const index = addr?.findIndex((object) => {
          return object.use === "Mailing";
        });
        if (setEdited) {
          setEdited(true);
        }
        if (index === -1) {
          const newAddr = addr;
          newAddr.push({ ...val, use: "Mailing" });
          setAddr(newAddr);
          onChange(newAddr);
        } else {
          const updatedAddr = addr;
          updatedAddr[index] = { ...val, use: "Mailing" };
          setAddr(updatedAddr);
          onChange(updatedAddr);
        }
      }
    },
    [addr, onChange, setEdited]
  );

  /**
   * New <Methods
   */
  return (
    <div className="flex flex-col w-full mb-4">
      <div className="flex flex-col">
        <div className="flex flex-col gap-5">
          <BaseAddressInput
            address={physicalAddress}
            onChange={changePhysicalAddress}
            onForwardRef={onForwardRef}
            onKeyPress={onKeyPress}
            isDisabled={isDisabled}
            rowGap={rowGap}
          />
          <div className="my-4">
            <Checkbox
              size={6}
              label="Mailing Address same as Physical Address"
              onChange={changeMailingSameAsPhysical}
              labelClassName="text-base text-gray-450"
              checked={mailingSameAsPhysical ?? sameAsPhysical}
              ref={onForwardRef}
              name="sameAddress"
              id="checked"
              onKeyUp={onKeyPress}
              disabled={isDisabled}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full">
        {!mailingSameAsPhysical && (
          <>
            <div className="min-w-full border-b my-2" />
            <div className="col-span-3 mt-2">
              <div className="flex w-full">
                <p className="ESTitle mb-4">Mailing Address</p>
              </div>
              <div
                onKeyUp={onKeyPress}
                id="react-select-open"
                tabIndex={0}
                role="button"
                aria-label="select open"
              >
                <BaseAddressInput
                  address={mailingAddress}
                  onChange={changeMailingAddress}
                  onForwardRef={onForwardRef}
                  onKeyPress={onKeyPress}
                  isDisabled={isDisabled}
                  rowGap={rowGap}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

BillingAddress.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  address: PropTypes.arrayOf(PropTypes.object),
  mailingSameAsPhysical: PropTypes.bool,
  onChange: PropTypes.func,
  handleMailingSameAsPhysicalInput: PropTypes.func,
  onKeyPress: PropTypes.func,
  onForwardRef: PropTypes.func,
  setEdited: PropTypes.func,
  setMailingSameAsPhysical: PropTypes.func,
  isDisabled: PropTypes.bool,
  rowGap: PropTypes.string,
};

BillingAddress.defaultProps = {
  address: undefined,
  mailingSameAsPhysical: false,
  onChange: () => {},
  handleMailingSameAsPhysicalInput: () => {},
  onKeyPress: () => {},
  onForwardRef: () => {},
  setEdited: undefined,
  setMailingSameAsPhysical: undefined,
  isDisabled: undefined,
  rowGap: "gap-12",
};

export default BillingAddress;

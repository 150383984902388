import { TicketAPI } from "@griffingroupglobal/eslib-api";
import { useMutation, useQueryClient } from "react-query";
import { ticketsKeys } from "../config/reactQuery/queryKeyFactory";
import { useAppState } from "../state/appState";
import { toastMessage, toastError } from "../stories/Components/Toast/Toast";

/**
 * Post service request in backend
 * @param {Object} request service request object
 * @returns service request response from backend
 */
const postServiceRequest = async (request) => {
  const { data } = await TicketAPI.post(request);
  return data;
};

/**
 * Mutation hook to post new service requests
 * @param {string} mutationKey (?) mutation key to track this task
 */
const useServiceRequestPost = (mutationKey) => {
  const [{ editingResource }] = useAppState();
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey,
    mutationFn: postServiceRequest,
    onSuccess: (data) => {
      const messageAndLinkProps = {
        resource: data,
        editingResource,
      };
      toastMessage(undefined, messageAndLinkProps);
    },
    onError: (error) => {
      console.error("useServiceRequestPost", error);
      toastError("Service Request could not be created. Please try again");
    },
    onSettled: () => {
      queryClient.invalidateQueries(ticketsKeys.tickets);
    },
  });
};

export default useServiceRequestPost;

import { userKeys } from "../../../../config/reactQuery/queryKeyFactory";
import editPropertyListStore from "../../../../helpers/Properties/editPropertyListStore";
import editPropertyOverviewStore from "../../../../helpers/Properties/editPropertyOverviewStore";
import editSinglePropertyStore from "../../../../helpers/Properties/editSinglePropertyStore";

const editPropertyResponder = (queryClient) => {
  return {
    onSuccess: (returnedData, variables) => {
      editPropertyListStore({
        queryClient,
        updatedData: returnedData,
      });

      editPropertyOverviewStore({
        queryClient,
        updatedData: returnedData,
      });

      editSinglePropertyStore({
        queryClient,
        updatedData: returnedData,
      });

      const { originalItem, editedItem } = variables;

      if (
        JSON.stringify(editedItem?.ownedBy) !==
        JSON.stringify(originalItem?.ownedBy)
      ) {
        // invalidating as new users may have been created in the owned by field
        // TODO (add user manually to cache when working on RTD Users)
        queryClient.invalidateQueries(userKeys.users);
      }
    },
    onError: (err) => {
      console.error("Failed to edit property", err);
    },
  };
};

export default editPropertyResponder;

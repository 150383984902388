/**
 * Filter an array of objects on the key provided and returns an array containing unique elements with the provided key
 * @param {array} - array of objects.
 * @param {key} - object key to filter by.
 * @returns {array} - Returns an array of object where each array element is unique with the key param.
 *
 * @example
 * const testArr = [
 *  {a: 1, b: 2, c: 3 },
 *  {a: 1, b: 5, c: "a" },
 *  {a: 5, b: 0, c: 10 },
 *  {a: 3, b: 2, c: 3 },
 * ]
 *
 * const results = esUniqBy({key: a, array: testArr});
 *
 * console.log(results) =>
 * [
 *  {a: 1, b: 2, c: 3 },
 *  {a: 5, b: 0, c: 10 },
 *  {a: 3, b: 2, c: 3 },
 * ]
 */
const esUniqBy = ({ key, array }) => {
  const map = new Map();

  array.forEach((item) => {
    if (!map.has(item[key])) map.set(item[key], item);
  });
  return Array.from(map.values());
};

export default esUniqBy;

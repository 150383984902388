import React from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router";
import InnerPageSwitcher from "../InnerPageSwitcher/InnerPageSwitcher";
import PrimaryHeader from "../TextHeaders/PrimaryHeader";
import CreateReportButton from "../CreateReportButton";
import IconButton from "../Buttons/IconButton";
import EditIcon from "../EditIcon/EditIcon";
import TertiaryButton from "../Buttons/TertiaryButton";
import PrimaryButton from "../Buttons/PrimaryButton";

const SiteWidgetHeader = ({
  fileActionsIcons,
  title,
  dropdownRoutes,
  buttons,
  viewOptions,
  alerts,
  search,
  filter,
  tabs,
  alphabetIndex,
  switchView,
  tempNav,
  customComponent,
  timeOffDisableSort,
  hideDropdownContainer,
  hideSiteHeaderActionButtons,
  hideSiteHeaderTitle,
  showColumnSettingsLeft,
  showReportButton,
  setIsEditingTable,
  isEditingTable,
  onSaveTableEdits,
  onCancelTableEdits,
  disableTableEditing,
  ...props
}) => {
  const { pathname: currentRoute } = useLocation();
  const history = useHistory();

  return (
    <>
      <div className="flex flex-row items-between justify-between gap-3">
        {!fileActionsIcons && !hideSiteHeaderTitle && (
          <div className={`${"flex-initial w-1/2 mb-4"}`}>
            <div className="flex flex-col justify-end">
              {tempNav}
              {typeof title === "string" ? (
                <PrimaryHeader className="ml-0.5">{title}</PrimaryHeader>
              ) : (
                title
              )}

              {!hideDropdownContainer && (
                <div className="h-8 mt-2">
                  {dropdownRoutes && (
                    <InnerPageSwitcher
                      innerRoutes={dropdownRoutes}
                      currentPath={currentRoute}
                      history={history}
                      timeOffDisableSort={timeOffDisableSort}
                      {...props}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        {!fileActionsIcons && !hideSiteHeaderTitle && (
          <div className="flex-initial w-1/2 mb-4">
            <div className="flex justify-end h-12">{buttons}</div>
          </div>
        )}
        {fileActionsIcons || showColumnSettingsLeft ? (
          <div
            style={{ height: "84px" }}
            className="flex flex-col items-start justify-between gap-3"
          >
            <div className="flex flex-row items-center justify-start gap-3 h-10">
              <div>{search}</div>
              {viewOptions}
              <div>{switchView}</div>
            </div>
            <div className="flex flex-row justify-start items-center">
              <div className="flex-none">{alerts}</div>
              <div className="flex">{filter}</div>
            </div>
          </div>
        ) : (
          <div className="flex items-center w-full justify-between">
            <div className="flex flex-row items-center gap-3">
              {viewOptions}
              {switchView}
            </div>
            <div
              className="flex justify-start"
              style={{
                minHeight: alerts || search || filter ? "2.5rem" : "0rem",
              }}
            >
              {alerts}
              {search}
              {filter}
            </div>
          </div>
        )}

        <div className="flex items-start flex-wrap">
          {showReportButton && (
            <div className="mr-7 pt-1">
              <CreateReportButton showReportButton={showReportButton} />
            </div>
          )}

          {fileActionsIcons}
        </div>

        {onSaveTableEdits && (
          <div className="flex justify-end">
            <div className="">
              {!isEditingTable && (
                <IconButton
                  wrapperClassName="icon-transition pt-2"
                  imgClassName="w-6 h-6"
                  iconClassName="w-6 h-6"
                  onClick={() => {
                    if (!disableTableEditing) {
                      setIsEditingTable(true);
                    }
                  }}
                  iconLeft={<EditIcon disabled={disableTableEditing} />}
                />
              )}
              {isEditingTable && (
                <div className="flex">
                  <TertiaryButton
                    title="Cancel"
                    className="mr-2 rounded-lg"
                    cancelButton
                    onClick={() => {
                      setIsEditingTable(false);
                      onCancelTableEdits();
                    }}
                  />
                  <PrimaryButton
                    saveButton
                    onClick={() => {
                      onSaveTableEdits();
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div
        className={`flex justify-between items-center my-2 z-10 ${
          tabs && "border-b-2 border-gray-150"
        }`}
      >
        {customComponent && customComponent}
        {tabs && tabs}
        {alphabetIndex && <>{alphabetIndex}</>}
      </div>
    </>
  );
};

SiteWidgetHeader.propTypes = {
  title: PropTypes.string,
  dropdownRoutes: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    })
  ),
  buttons: PropTypes.element,
  viewOptions: PropTypes.element,
  alerts: PropTypes.element,
  search: PropTypes.element,
  filter: PropTypes.element,
  switchView: PropTypes.element,
  tempNav: PropTypes.element,
  alphabetIndex: PropTypes.element,
  /**
   * Tabs for siteHeader in Tabbed Container
   */
  tabs: PropTypes.arrayOf(PropTypes.node),
  customComponent: PropTypes.element,
  timeOffDisableSort: PropTypes.bool,
  hideDropdownContainer: PropTypes.bool,
  fileActionsIcons: PropTypes.element,
  hideSiteHeaderActionButtons: PropTypes.bool,
  hideSiteHeaderTitle: PropTypes.bool,
  showColumnSettingsLeft: PropTypes.bool,
  /**
   * Show create report button in table header
   */
  showReportButton: PropTypes.bool,
  setIsEditingTable: PropTypes.func,
  isEditingTable: PropTypes.bool,
  onSaveTableEdits: PropTypes.func,
  onCancelTableEdits: PropTypes.func,
  disableTableEditing: PropTypes.bool,
};

SiteWidgetHeader.defaultProps = {
  title: "",
  dropdownRoutes: undefined,
  buttons: undefined,
  viewOptions: undefined,
  alerts: undefined,
  search: undefined,
  filter: undefined,
  switchView: undefined,
  tempNav: undefined,
  alphabetIndex: undefined,
  tabs: undefined,
  customComponent: undefined,
  timeOffDisableSort: false,
  hideDropdownContainer: false,
  fileActionsIcons: undefined,
  hideSiteHeaderActionButtons: false,
  hideSiteHeaderTitle: false,
  showColumnSettingsLeft: false,
  showReportButton: false,
  setIsEditingTable: undefined,
  isEditingTable: false,
  onSaveTableEdits: undefined,
  onCancelTableEdits: undefined,
  disableTableEditing: false,
};

export default SiteWidgetHeader;

import React from "react";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router";
import {
  ADD_OPEN_MODAL,
  PROPERTY,
  PROPERTY_CREATE_MODAL,
  PROPERTY_TABS,
} from "../../../constants";
import PropertyTable from "../../../stories/Components/PropertyTable/PropertyTable";
import useCurrentUser from "../../../hooks/useCurrentUser";
import PrimaryButton from "../../../stories/Components/Buttons/PrimaryButton";
import PrimaryHeaderItems from "../../../stories/Components/Primary/PrimaryHeaderItems";
import { useModalState } from "../../../state/modalState";
import { getSingleResourcePath } from "../../../helpers/Navigation";
import { hasWriteAdministrativePermission } from "../../../helpers/Permissions";
import useAppPersistence from "../../../hooks/persistence/useAppPersistence";

const PropertyList = () => {
  const history = useHistory();
  const { data: currentUser } = useCurrentUser();
  const [{ modals }, modalDispatch] = useModalState();
  const { getActiveTab, getPageSettings } = useAppPersistence();
  const pageSettings = getPageSettings("properties");

  const onPropertyRowClick = (id) => {
    const activeTab = getActiveTab({ page: "properties", resourceId: id });
    const isCurrentPersistedResource = pageSettings?.resource?.id === id;
    const activeTabOrDefault = isCurrentPersistedResource
      ? activeTab
      : PROPERTY_TABS.DETAILS_ID;
    history.push(
      getSingleResourcePath(`Property/${id}?tab=${activeTabOrDefault}`)
    );
  };

  return (
    <>
      <PrimaryHeaderItems
        headerTitle="Properties"
        buttonTitle="Property"
        customButton={
          hasWriteAdministrativePermission(PROPERTY, currentUser) && (
            <PrimaryButton
              resourceName="Property"
              onClick={() =>
                modalDispatch({
                  type: ADD_OPEN_MODAL,
                  ref: { id: uuidv4() },
                  modalType: PROPERTY_CREATE_MODAL,
                })
              }
              disabled={modals.find(
                (item) => item.modalType === PROPERTY_CREATE_MODAL
              )}
              addButton
            />
          )
        }
      />

      <PropertyTable onRowClick={onPropertyRowClick} />
    </>
  );
};

PropertyList.propTypes = {};

PropertyList.defaultProps = {};

export default PropertyList;

import React from "react";
import PropTypes from "prop-types";
import eyeIcon from "../../stories/assets/images/eyeIcon.svg";
import editIcon from "../../stories/assets/images/editDarkenedGreenIcon.svg";
import ExpandedView from "./ExpandedView";
import useRealTimeDataBarData from "./useRealTimeDataBarData";
import IconButton from "../../stories/Components/Buttons/IconButton";
import RealTimeAvatar from "./RealTimeAvatar";

const RealTimeDataBar = ({ isEditing, realtimeUsers = [], lockData }) => {
  const {
    isOpen,
    LIMIT,
    totalRealTimeUsers,
    reducedRealTimeUsers,
    sortedRealTimeUsers,
    handleOpenViewer,
    handleCloseViewer,
  } = useRealTimeDataBarData({ realtimeUsers, lockData });

  if (totalRealTimeUsers === 0) {
    return null;
  }

  return (
    <div>
      {/** Real Time Bar */}
      <div
        className="relative flex flex-col justify-self-end items-end justify-center gap-2 mb-6"
        style={{
          height: "46px",
          minWidth: "387px",
        }}
      >
        <div className="flex flex-row border-es-light-grey border rounded-lg bg-es-extra-light-grey gap-2">
          <div
            className={`flex justify-center ${
              isEditing ? "bg-es-extra-light-grey" : "bg-green-50"
            } rounded-l-lg border-r border-r-es-light-grey center p-2`}
          >
            {isEditing ? (
              <IconButton
                onClick={handleOpenViewer}
                iconLeft={
                  <img className="inline-block" src={editIcon} alt="edit" />
                }
              />
            ) : (
              <IconButton
                onClick={handleOpenViewer}
                iconLeft={
                  <img className="inline-block" src={eyeIcon} alt="viewing" />
                }
              />
            )}
          </div>

          <div className="flex flex-row gap-2 items-center flex-grow mr-2">
            {isEditing ? (
              <span className="text-es-normal font-semibold text-es-dark-green">
                You are now editing
              </span>
            ) : (
              <>
                {reducedRealTimeUsers.map((user) => (
                  <RealTimeAvatar
                    key={user.id}
                    user={user}
                    lockData={lockData}
                  />
                ))}

                {totalRealTimeUsers > LIMIT && (
                  <div className="rounded-full bg-white items-center justify-center flex h-8 w-8 border border-white mr-2">
                    <span className="text-es-dark-green text-es-sm text-center tracking-es-wide font-normal">
                      {`+${totalRealTimeUsers - LIMIT}`}
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        <div className="flex flex-row z-100 absolute" style={{ top: "52px" }}>
          {isOpen && (
            <ExpandedView
              realtimeUsers={sortedRealTimeUsers}
              lockData={lockData}
              handleCloseViewer={handleCloseViewer}
            />
          )}
        </div>
      </div>
    </div>
  );
};

RealTimeDataBar.propTypes = {
  isEditing: PropTypes.bool,
  realtimeUsers: PropTypes.arrayOf(
    PropTypes.shape({ id: PropTypes.string, reference: PropTypes.string })
  ),
  lockData: PropTypes.shape({
    userRef: PropTypes.string,
    user: PropTypes.shape({
      id: PropTypes.string,
      reference: PropTypes.string,
    }),
  }),
};

RealTimeDataBar.defaultProps = {
  isEditing: false,
  realtimeUsers: [],
  lockData: undefined,
};

export default RealTimeDataBar;

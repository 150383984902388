import { useMutation, useQueryClient } from "react-query";
import deletePropertyHandler from "./handlers/deletePropertyHandler";
import deletePropertyResponder from "./responders/deletePropertyResponder";
import deletePropertyListStore from "../../../helpers/Properties/deletePropertyListStore";
import deletePropertyOverviewStore from "../../../helpers/Properties/deletePropertyOverviewStore";
import { propertyKeys } from "../../properties";

const useDeleteProperty = () => {
  const queryClient = useQueryClient();

  const deleteMutation = useMutation(
    deletePropertyHandler,
    deletePropertyResponder(queryClient)
  );

  /**
   * Delete property from a single property cache, property list and property overview store
   * @param {string}
   */
  const deleteProperty = async (id, newRequest = true) => {
    if (!id) {
      console.error("id is required: pass a property id");
    }

    if (newRequest === false) {
      deletePropertyListStore({ queryClient, id });
      deletePropertyOverviewStore({ queryClient, id });
      return queryClient.removeQueries({ queryKey: propertyKeys.byId(id) });
    }

    const response = await deleteMutation.mutateAsync(id);
    return response;
  };

  const { isLoading: isDeletePropertyLoading, isError: isDeletePropertyError } =
    deleteMutation;

  return {
    isDeletePropertyError,
    isDeletePropertyLoading,
    deleteProperty,
  };
};

export default useDeleteProperty;

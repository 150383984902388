import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import cntl from "cntl";
import CreateContactForm from "../../stories/Components/ContactForm/CreateContactForm";
import useUsers from "../../hooks/useUsers";

const PageCN = cntl`
  lg:flex
  flex
  flex-initial
  flex-col
  w-full
`;

const ContactFormContainer = ({
  contact,
  contactDispatch,
  memberEmails,
  contactType,
  handleInviteButton,
  modalRef,
  inviteStatus,
  tmpAvatarImg,
  setTmpAvatarImg,
}) => {
  const [users] = useUsers();
  const [companyContacts, setCompanyContacts] = useState([]);

  useEffect(() => {
    if (users?.length) {
      const companies = users
        ?.filter((user) => user?.kind === "company")
        ?.map((companyContact) => ({
          label: companyContact?.company?.value,
          value: companyContact?.reference,
        }));
      setCompanyContacts(companies);
    }
  }, [users]);

  return (
    <>
      <div className={PageCN}>
        <CreateContactForm
          companyContacts={companyContacts}
          memberEmails={memberEmails}
          contact={contact}
          contactDispatch={contactDispatch}
          contactType={contactType}
          handleInviteButton={handleInviteButton}
          modalRef={modalRef}
          inviteStatus={inviteStatus}
          tmpAvatarImg={tmpAvatarImg}
          setTmpAvatarImg={setTmpAvatarImg}
        />
      </div>
    </>
  );
};

ContactFormContainer.propTypes = {
  memberEmails: PropTypes.arrayOf(PropTypes.shape({})),
  contact: PropTypes.shape({}),
  contactDispatch: PropTypes.func,
  contactType: PropTypes.string,
  handleInviteButton: PropTypes.func,
  inviteStatus: PropTypes.string,
  modalRef: PropTypes.shape({}),
  tmpAvatarImg: PropTypes.shape({
    image: PropTypes.string,
    isLoading: PropTypes.bool,
  }),
  setTmpAvatarImg: PropTypes.func,
};

ContactFormContainer.defaultProps = {
  memberEmails: [],
  contact: undefined,
  contactDispatch: undefined,
  contactType: "contact",
  handleInviteButton: undefined,
  inviteStatus: undefined,
  modalRef: undefined,
  tmpAvatarImg: { isLoading: false },
  setTmpAvatarImg: undefined,
};

export default ContactFormContainer;

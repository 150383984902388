import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import * as cityTimezones from "city-timezones";
import { BRAND_GREEN_LIGHT, DROPDOWN_STYLE } from "../../../constants";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    width: "100%",
  }),
  option: (provided, { isFocused }) => ({
    ...provided,
    ...DROPDOWN_STYLE.option,
    backgroundColor: isFocused && BRAND_GREEN_LIGHT,
  }),
  singleValue: (provided) => ({
    ...provided,
    ...DROPDOWN_STYLE.singleValue,
  }),
  control: (provided) => ({
    ...provided,
    ...DROPDOWN_STYLE.control,
  }),
};

const WorldClockSelect = ({
  handleCustomCityChange,
  idx,
  city,
  cities,
  handleBlur,
  handleEnter,
  autofocus,
  isAdding,
}) => {
  const [searchTerm, setSearchTerm] = React.useState(city || "");
  const [searchResults, setSearchResults] = React.useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  // track duplicated state of added city
  const [duplicatedCity, setDuplicatedCity] = useState({
    isDuplicated: false,
    cityName: "",
    timezone: "",
  });

  const handleChange = (value) => {
    setSelectedOption(searchTerm);

    // search for duplicated city in the list of cities from reducer
    const foundDuplicatedCity = cities.some(
      (c) =>
        c.city === value?.city && c.cityCode === value?.timezone && isAdding
    );

    // update state of duplicated city
    setDuplicatedCity({
      isDuplicated: foundDuplicatedCity,
      cityName: value?.city,
      timezone: value?.timezone,
    });

    // skip adding city to cities list if city/timzone already exists
    if (!foundDuplicatedCity)
      handleCustomCityChange(value?.city, idx, value?.timezone);
  };
  const handleInputChange = (inputValue) => {
    setSearchTerm(inputValue);
  };

  useEffect(() => {
    setSearchResults(() => {
      const results = cityTimezones.findFromCityStateProvince(searchTerm);
      const options = results.slice(0, 20).map((result) => {
        const country =
          result.country === "United States of America" ? result.iso2 : null;

        let timezone;
        switch (result?.country) {
          case "Antarctica":
            // According to google New Zealand is used for Antarctica timezone
            timezone = "Pacific/Auckland";
            break;
          case "Arctic":
            // According to google CET is used for the Arctic timezone
            timezone = "Europe/Brussels";
            break;
          default:
            timezone = result?.timezone;
        }
        return {
          city: result.city,
          label: `${result.city}, ${country || result.country}`,
          timezone: timezone || "",
        };
      });
      return options;
    });
  }, [searchTerm]);

  const ErrorMessage = ({ cityName, timezone }) => (
    <p className="capitalize-first text-xs text-brandRed italic">
      Duplicated {cityName} - {timezone} not allowed
    </p>
  );

  return (
    <>
      {/* show error message if user adds duplicated city */}
      {duplicatedCity.isDuplicated && (
        <ErrorMessage
          cityName={duplicatedCity.cityName}
          timezone={duplicatedCity.timezone}
        />
      )}
      <Select
        autoFocus={autofocus}
        className="w-full"
        styles={customStyles}
        value={selectedOption}
        defaultValue={city}
        onChange={(value) => handleChange(value)}
        inputValue={searchTerm}
        defaultInputValue={city}
        onInputChange={handleInputChange}
        isSearchable
        options={searchResults}
        onBlur={() => handleBlur(city, idx)}
        handleEnter={(event) => handleEnter(event, city, idx)}
      />
    </>
  );
};

WorldClockSelect.propTypes = {
  handleCustomCityChange: PropTypes.func,
  idx: PropTypes.number,
  city: PropTypes.string,
  handleBlur: PropTypes.func,
  handleEnter: PropTypes.func,
  autofocus: PropTypes.bool,
  cities: PropTypes.arrayOf(PropTypes.shape({})),
  isAdding: PropTypes.bool,
};

WorldClockSelect.defaultProps = {
  handleCustomCityChange: undefined,
  idx: undefined,
  city: undefined,
  handleBlur: undefined,
  handleEnter: undefined,
  autofocus: false,
  cities: [],
  isAdding: false,
};

export default WorldClockSelect;

import { useQueryClient } from "react-query";
import useAuthenticatedQuery from "../../useAuthenticatedQuery";
import fetchTaskHandler from "./handlers/fetchTaskHandler";
import taskKeys from "./taskKeys";

/**
 * Query hook to fetch a single task.
 * @param {string} taskId - The unique identifier for the task.
 * @param {string} instanceStartDate - The start date of the task instance if available
 */

const useSingleTask = (taskId, instanceStartDate) => {
  const queryClient = useQueryClient();

  const authenticatedResponse = useAuthenticatedQuery({
    queryKey: taskKeys.singleTask(taskId, instanceStartDate),
    queryFn: ({ signal }) =>
      fetchTaskHandler(taskId, instanceStartDate, signal),
    enabled: !!taskId,
    staleTime: 0,
    onError: () => {
      console.error("useSingleTask : there was a problem getting single task");
    },
  });

  const {
    data: singleTask,
    isLoading: singleTaskLoading,
    isError: isSingleTaskError,
    error: singleTaskError,
  } = authenticatedResponse;

  const refetchTask = async () => {
    await queryClient.invalidateQueries(
      ["singleTask", taskId, instanceStartDate].filter(Boolean)
    );
  };

  return {
    singleTask,
    refetchTask,
    singleTaskLoading,
    isSingleTaskError,
    singleTaskError,
  };
};

export default useSingleTask;

import React from "react";
import PropTypes from "prop-types";
import useTableCellInputData from "./useTableCellInputData";

const TableCellInput = ({ value, onChange, isDisabled, className }) => {
  const { input, handleInput } = useTableCellInputData({
    value,
    onChange,
  });

  return (
    <div className="w-full flex mr-4">
      <input
        className={`${className} flex tracking-es-normal ${
          isDisabled ? "text-es-light-grey" : "text-es-dark-grey"
        } font-es-semibold text-es-normal w-full`}
        style={{ background: "transparent" }}
        value={input}
        onChange={(e) => handleInput(e.target.value)}
        onClick={(e) => {
          e?.stopPropagation();
        }}
        disabled={isDisabled}
      />
    </div>
  );
};

TableCellInput.propTypes = {
  value: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  className: PropTypes.string,
};

TableCellInput.defaultProps = {
  value: undefined,
  onChange: undefined,
  isDisabled: false,
  className: undefined,
};

export default TableCellInput;

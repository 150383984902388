import { AssetAPI } from "@griffingroupglobal/eslib-api";

/**
 * Fetch all assets
 * @param {String} [reference]
 * @param {AbortController} [signal]
 * @returns {Promise<Object[]>}
 */
const fetchAssets = async (reference, signal) => {
  const referenceKey = reference?.split("/")?.[0]?.toLowerCase();

  /**
   * Example fetch-by-association params
   * {
   *  project: "Project/1111-2222-3333-4444",
   * }
   */
  const { data } = await AssetAPI.get({
    params: { [referenceKey]: reference },
    signal,
  });

  let assets = [];

  if (data?.entries) {
    assets = data.entries.map((entry) => {
      const resource = entry?.resource;

      return resource;
    });
  }

  return assets;
};

export default fetchAssets;

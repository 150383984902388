/**
 * Format time in secondss into a mm:ss string
 * @param {number} time - time in seconds
 * @returns {string} formatted time (mm:ss)
 */
const formatCountdown = (time = 0) => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes}:${seconds.toString().padStart(2, "0")}`;
};

export default formatCountdown;

import { propertyKeys } from "../../hooks/properties";

const deletePropertyOverviewStore = ({ queryClient, id }) => {
  const queryKey = propertyKeys.overview();
  const oldData = queryClient.getQueryData(queryKey);

  // Note: Important, propertyOverview cache probably hasn't been initiated yet it will be an empty object
  const propertiesDict = oldData?.propertiesDict ?? {};
  const propertiesDD = oldData?.propertiesDD ?? [];

  const newPropertiesDict = { ...propertiesDict };

  const reference = `Property/${id}`;

  // remove from `newPropertiesDict` if the property exists
  if (propertiesDict[reference]) {
    delete newPropertiesDict[reference];
  }

  // remove from `propertiesDic` if the property exists
  let newPropertiesDD = propertiesDD;

  const index = propertiesDD.findIndex((property) => property.id === id);

  if (index !== -1) {
    newPropertiesDD = [
      ...propertiesDD.slice(0, index),
      ...propertiesDD.slice(index + 1),
    ];
  }

  queryClient.setQueryData(queryKey, {
    propertiesDict: newPropertiesDict,
    propertiesDD: newPropertiesDD,
  });
};

export default deletePropertyOverviewStore;

import * as yup from "yup";

// Function to validate and return failed keys
const validateAndGetFailedColumns = (schema, form) => {
  let errorPaths;
  try {
    schema.validateSync(form, { abortEarly: false });
    errorPaths = []; // No validation errors
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      errorPaths = error.inner.map((err) => err.path);
    }
  }
  return errorPaths;
};

export default validateAndGetFailedColumns;

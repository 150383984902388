import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";

const CardSection = ({ children, setIsActive, contact }) => {
  const { ref, entry } = useInView({
    rootMargin: "-10% 0px -80% 0px",
    root: document.querySelector(".cardGridContainer"),
    trackVisibility: true,
    delay: 100,
  });

  useEffect(() => {
    const activity = () => {
      if (entry) {
        setIsActive(entry);
      }
    };

    return () => activity();
  }, [entry, setIsActive]);

  return (
    <div
      className="flex flex-col h-fit"
      key={`container-${contact}`}
      id={`content-${contact}`}
      ref={ref}
    >
      {children}
    </div>
  );
};

export default CardSection;

import React from "react";
import PropTypes from "prop-types";
import Avatar from "../../../stories/Components/Message/Avatar";
import { getFullName } from "../../../helpers/Formatters";
import editIconWhite from "../../../stories/assets/images/editIconWhite.svg";

const RealTimeAvatar = ({ user, lockData }) => {
  return (
    <div className="relative w-8 h-8">
      <Avatar
        className={`text-xs w-8 h-8 justify-center ${
          lockData &&
          lockData.userRef === user.reference &&
          "rounded-full border border-es-mint bg-es-black bg-opacity-50"
        }`}
        name={getFullName(user.name)}
        isExternalUser
        id={user.id}
        fontClassName="font-es-bold"
        avatar={user.avatar}
      />

      {lockData && lockData.userRef === user.reference && (
        <img
          className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-2 rounded-full"
          src={editIconWhite}
          alt="edit"
        />
      )}
    </div>
  );
};

RealTimeAvatar.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
    reference: PropTypes.string,
    avatar: PropTypes.string,
    name: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
    }),
  }),
  lockData: PropTypes.shape({
    userRef: PropTypes.string,
    user: PropTypes.shape({
      reference: PropTypes.string,
    }),
  }),
};

RealTimeAvatar.defaultProps = {
  user: {
    id: "",
    reference: "",
    avatar: "",
    name: {
      firstName: "",
      lastName: "",
    },
  },
  lockData: {
    userRef: undefined,
    user: {
      reference: undefined,
    },
  },
};

export default RealTimeAvatar;

/**
 * Returns the settings for a specific page.
 *
 * @param {Object} params - The parameters.
 * @param {string} params.page - The page for which to return the settings.
 * @param {string} params.resourceId - The resource id
 * @param {Object} params.userSettings - The user's settings.
 * @returns {string} The current active tab for that page tab
 */

const returnActiveTab = ({ page, resourceId, userSettings }) => {
  if (resourceId) {
    if (resourceId !== userSettings?.web?.[page]?.resource?.id) {
      return null;
    }

    return userSettings?.web?.[page]?.resource?.tab?.activeTab;
  }
  return userSettings?.web?.[page]?.tab?.activeTab;
};

export default returnActiveTab;
